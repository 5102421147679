import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import { Select2 } from "select2";
import Footer from "../Footer";
import NavBar from "../MainDashboard/NavBar";
import SideUl from "../MainDashboard/SideUl";
import MainSidebar from "../MainDashboard/MainSidebar";
import {
  AddInvoiceApi,
  GetAllDiscountApi,
  GetAllTaxApi,
  GetInvoices,
  GetProductDeviceApi,
  GetRecieptApi,
  GetSubUserProfileApi,
  GettAllPaymentApi,
  baseurl,
  customerAddApi,
  customerGetApi,
  getProfileApi,
  uploadImageApi,
} from "../Utility/Api";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Swal from "sweetalert2";
import ProductModal from "../MainDashboard/ProductModal";
import DeviceModal from "../MainDashboard/DeviceModal";
import moment from "moment";
import Ticket from "../MainDashboard/TicketModal";
import { flatMap } from "lodash";
import FreeTrail from "./FreeTrail";

const Refund = () => {
  const [loading, setLoading] = useState(false);
  const [invoiceId, setInvoiceId] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [selectProduct, setSelectProduct] = useState([]);
  const [discounts, setDiscounts] = useState(
    Array(selectProduct.length).fill(10)
  );
  const [unitCosts, setUnitCosts] = useState("");
  const [receivedQuantities, setReceivedQuantities] = useState("");
  const [discription, setDiscription] = useState("");
  const [discounts1, setDiscounts1] = useState();
  const [unitCosts1, setUnitCosts1] = useState([]);
  const [receivedQuantities1, setReceivedQuantities1] = useState([]);
  const [discription1, setDiscription1] = useState("");
  const [editedProductIndex, setEditedProductIndex] = useState(null);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal9, setShowModal9] = useState(false);
  const [grandTotal, setGrandTotal] = useState("");
  const [subTotal, setSubTotal] = useState("");
  const [grandTotal1, setGrandTotal1] = useState("");
  const [subTotal1, setSubTotal1] = useState("");
  const [discount, setDiscount] = useState("");
  const [tax, setTax] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [payment, setPayment] = useState([]);
  const [Items, setItems] = useState([]);
  const [itemid, setItemId] = useState([]);
  const [paymentType1, setPaymentType1] = useState("");
  const [payment1, setPayment1] = useState([]);
  const [items1, setItems1] = useState([]);
  const [itemid1, setItemId1] = useState([]);
  const [discount1, setDiscount1] = useState("");
  const [tax1, setTax1] = useState("");
  const [ticketid, setTicketId] = useState("");
  const [paymentMode, setPaymentMode] = useState([]);
  const [selectedCategory2, setSelectedCategory2] = useState([]);
  const [selectedPaymentTypeId, setSelectedPaymentTypeId] = useState("");
  const [allPaymentOptions, setAllPaymentOptions] = useState([]);
  const [isPaymentOptionSelected, setIsPaymentOptionSelected] = useState(false);
  const [showlist, setShowlist] = useState(false);
  const [selectedPaymentType, setSelectedPaymentType] = useState("Cash");
  const [alldiscount, setAllDiscount] = useState([]);
  const [alltax, setAllTax] = useState([]);
  const [inputValue, setInputValue] = useState(0);
  const [totalPayment, setTotalPayment] = useState(0);
  const [customerData, setCustomerData] = useState({});
  const [returnData, setReturnData] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const [refundQty, setRefundQty] = useState([]);
  const [paymentTotal, setPaymentTotal] = useState();
  const [changeDue, setChangeDue] = useState("");
  const [paymentState, setPaymnetState] = useState(false);
  const [ErrorAmount, setErrorAmount] = useState(false);
  const [refundState, setRefundState] = useState(false);
  const [taxAmounts, setTaxAmount] = useState("");
  const [taotalSaleQuantity, setTotalSaleQuantity] = useState("");
  const [totalCost, setTotalCost] = useState("");
  const [totalAveraSale, setTotalAveraSale] = useState("");
  const [salesTotalCost, setTotalSalesCost1] = useState("");
  const [averageSaleCost, setAverageSalesCost] = useState("");
  const [discountAmount, setDiscountAmount] = useState("");
  const [reciept, setReciept] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    prodname: 0,
    prodname_sku: 0,
    sku: 0,
  });
  const [customerDetails, setCustomerDetails] = useState({
    fullname: 0,
    email: 0,
    phone: 0,
    address: 0,
  });

  const [gstNumber, setSGTNumber] = useState("");
  const [leftMargin, setLeftMargin] = useState("10mm");
  const [rightMargin, setRightMargin] = useState("10mm");
  const [recieptId, setRecieptId] = useState("");
  const [printType, setPrintType] = useState();
  const [title, setTitle] = useState("");
  const [logo, setLogo] = useState("");
  const [isLogo, setIsLogo] = useState(true);
  const [logoSize, setLogoSize] = useState("");
  const [logoPosition, setLogoPosition] = useState("");
  const [header, setHeader] = useState("");
  const [displySales, setDisplaySales] = useState(false);
  const [footer, setFooter] = useState("");
  const [note, setNote] = useState(false);
  const [displayBarcode, setDisplayBarcode] = useState(false);
  const [displayNote, setDisplayNote] = useState(false);
  const [states, setStates] = useState(false);
  const [data1, setData1] = useState([]);
  const [dataUser, setDataUser] = useState([]);
  const [adminState, setAdminState] = useState("");
  const [permission, setPermission] = useState([]);
  const [InvoiceData1, setInVoiceData1] = useState({});
  const [error, setError] = useState({});
  const [ItemData, setItemData] = useState([]);
  const [showModal11, setShowModal11] = useState(false);
  const [invoiceId1, setInvoiceID1] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [invoiceData, setInvoiceData] = useState({});
  const [gstShow, setGstShow] = useState();
  const [originalUnitCosts, setOriginalUnitCosts] = useState([]);
  const [originalReceivedQuantities, setOriginalReceivedQuantities] = useState(
    []
  );
  const [originalNotes, setOriginalNotes] = useState([]);
  const [originalDiscounts, setOriginalDiscounts] = useState([]);

  let location = useLocation();

  const resetFormValues = () => {
    setUnitCosts([...originalUnitCosts]); // Restore original unit costs
    setReceivedQuantities([...originalReceivedQuantities]); // Restore original received quantities
    setNote([...originalNotes]); // Restore original notes
    setDiscounts([...originalDiscounts]); // Restore original discounts
  };

  // useEffect(() => {
  //   if (location?.state && location?.state?.data) {
  //     const { itemIds, items, payment } = location?.state?.data;

  //     // Create an array of combined objects
  //     const productData = itemIds?.map((itemId, index) => ({
  //       itemId: itemId,
  //       item: items[index],
  //       payment: payment,
  //     }));

  //     // Set selectProduct state to the array of combined objects
  //     setSelectProduct(productData);
  //   }
  // }, [location?.state]);
  useEffect(() => {
    if (location?.state && location?.state?.data) {
      const { itemIds, items, payment } = location?.state?.data;

      // Create an array of combined objects
      const productData = itemIds?.map((itemId) => {
        // Find the corresponding item in items array based on itemId
        const matchingItem = items.find((item) => item.itemId === itemId.id);
        console.log("maitchhh", matchingItem);

        return {
          itemId: itemId,
          item: matchingItem || {}, // Provide a default value if no match found
          payment: payment,
        };
      });

      // Set selectProduct state to the array of combined objects
      setSelectProduct(productData);
    }
  }, [location?.state]);

  const GetProfile = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(getProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setData1(result?.data);
        } else {
          //console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetSubUserProfile = () => {
    let local = localStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetSubUserProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setDataUser(result?.data);
        } else {
          //console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    let localAdmin = localStorage.getItem("admin");
    let localPerminssion = JSON.parse(localStorage.getItem("permission"));
    setAdminState(localAdmin);
    setPermission(localPerminssion);
    if (localAdmin === "0" && localPerminssion.length) {
      // setAdminState(localAdmin);
      // setPermission(localPerminssion);
      GetSubUserProfile();
    }
    GetProfile();

    let details = location?.state?.data;
    console.log("return", details);
    //setSelectProduct(details);
    setCustomerData(details?.customer);
    setEmail(details?.customer?.email);
    setName(details?.customer?.firstName);
    setInvoiceId(details?.id);
    setItems(details?.items);
    setGrandTotal(details?.grandTotal);
    setSubTotal(details?.subTotal);
    setPayment(details?.payment);
    setPaymentType(details?.payment?.paymentType);
    setDiscount(details?.discount);
    setTax(details?.tax);
    const unitCostsArray = details?.items.map((product) => product?.unitCost);
    const receivedQuantitiesArray = details?.items.map(
      (product) => product?.quantity
    );
    setUnitCosts(unitCostsArray);
    setReceivedQuantities(receivedQuantitiesArray);
    setReceivedQuantities1(receivedQuantitiesArray);
    setUnitCosts1(unitCostsArray);
    setItemId(details?.itemIds);
    setDiscount1(details?.discount);
    setTax1(details?.tax);
    setTicketId(details?.ticketId);
    setReturnData(details?.isReturn);
    setPaymentId(details?.paymentType);
    setDiscounts1(details?.items?.map((product) => product?.discount));
    setRefundQty(details?.items?.map((product) => product?.isRefundQuantity));
    setTotalCost(details?.totalCost);
    setTotalAveraSale(details?.totalSalesCost);
    setTaxAmount(details?.taxAmount);
    setTotalSaleQuantity(details?.totalSalesQuantity);
    setAverageSalesCost(details?.averageSalesCost);
    setDiscountAmount(details?.discountAmount);
    setCustomerName(details?.customer?.firstName);

    setSelectedCategory2(
      details?.payment?.map((item, index) => item?.paymentType)
    );
    // console.log(
    //   "payment",
    //   details?.payment?.map((item, index) => item?.paymentType)
    // );
  }, []);

  const handleEditItem = (item, index) => {
    setOriginalUnitCosts([...unitCosts]);
    setOriginalReceivedQuantities([...receivedQuantities]);
    // setOriginalNotes([...note]);
    setOriginalDiscounts([...discounts]);
    setEditedProductIndex(index);
    setUnitCosts((prevUnitCosts) => [...prevUnitCosts, index?.unitCost || 0]);
    setReceivedQuantities((prevReceivedQuantities) => [
      ...prevReceivedQuantities,
      index?.quantity || 0,
    ]);
    setShowModal1(true);
  };

  const handleUpdate = (selectedArray) => {
    if (editedProductIndex !== null) {
      if (Array.isArray(selectProduct) && selectProduct.length > 0) {
        const updatedProducts = [...selectProduct];
        updatedProducts[editedProductIndex] = {
          ...updatedProducts[editedProductIndex],
          unitCost: unitCosts[editedProductIndex],
          receivedQuantity: receivedQuantities[editedProductIndex],
          note: discription[editedProductIndex],
          discounts: discounts[editedProductIndex],
        };

        setSelectProduct(updatedProducts);
        setEditedProductIndex(null);
      }
    }
  };

  // const handleDelete = (index) => {
  //   setSelectProduct((prevProducts) => {
  //     const updatedProducts = [...prevProducts];
  //     updatedProducts.splice(index, 1);

  //     return updatedProducts;
  //   });
  // };
  const handleDelete = (index) => {
    setSelectProduct((prevProducts) => {
      const updatedProducts = [...prevProducts];
      updatedProducts.splice(index, 1);
      return updatedProducts;
    });

    setUnitCosts((prevUnitCosts) => {
      const updatedCosts = [...prevUnitCosts];
      updatedCosts.splice(index, 1);
      return updatedCosts;
    });

    setReceivedQuantities((prevQuantities) => {
      const updatedQuantities = [...prevQuantities];
      updatedQuantities.splice(index, 1);
      return updatedQuantities;
    });

    setPaymnetState(false);
    setPaymentMode([]);
    // GetAllProducts();
  };
  const [quantities, setQuantities] = useState(
    location?.state?.data?.items.map((item) => item.quantity) || []
  );

  const handleDecreaseQuantity = (index) => {
    if (receivedQuantities[index] > 0) {
      const updatedQuantities = [...receivedQuantities];
      updatedQuantities[index] -= 1;
      setReceivedQuantities(updatedQuantities);
    }
  };

  const handleIncreaseQuantity = (index) => {
    const currentQuantity = receivedQuantities[index];
    const prevQuantity = receivedQuantities1[index]; // Assuming receivedQuantities1 is an array of maximum quantities

    // Check if the current quantity is less than the maximum quantity
    if (currentQuantity < prevQuantity) {
      const updatedQuantities = [...receivedQuantities];
      updatedQuantities[index] = currentQuantity + 1;
      setReceivedQuantities(updatedQuantities);
      setEditedProductIndex(index); // Optionally set the edited index
    } else {
      Swal.fire("Cannot increase quantity beyond received quantity");
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    GetReciept();
  }, []);

  const Refund = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    let totalCosts = 0; // Initialize totalCosts
    let TotalQty = 0;
    const Data = selectProduct?.map((item, index) => {
      // console.log("items1 data", item);
      const Unitcost = Number(unitCosts[index]);
      totalCosts += Unitcost;

      const TQty = Number(receivedQuantities[index]);
      TotalQty += TQty;
      return {
        discount: Number(discounts[index]),
        quantity: Number(receivedQuantities[index]),
        unitCost: Number(unitCosts[index]),
        itemId: item?.item.itemId,
        note: discription[index] ? discription[index] : "",
        total: Number(
          calculateDiscountedTotal(
            unitCosts[index],
            receivedQuantities[index],
            discounts[index] || 0
          )
        ),
        imeiNumber: item?.item?.imeiNumber ? item?.item?.imeiNumber : null,
        imeiNumberArr: item?.item?.imeiNumberArr || null,
        isRefundQuantity: Number(receivedQuantities[index]),
        isRefundPrice: Number(unitCosts[index]),
        isRefundDiscount: Number(discounts[index]),
        averageCost: item.averageCost,
        // totalRefundQuantity:
        //   refundQty[index] + Number(receivedQuantities[index]),
      };
    });
    console.log("items1 data", Data);
    const Data1 = location?.state?.data?.items.map((item, index) => {
      // Ensure location and location.state.data are not null
      return {
        discount: Number(discounts1[index]),
        quantity: Number(receivedQuantities1[index]),
        unitCost: Number(unitCosts1[index]),
        itemId: item.itemId,
        note: discription[index] ? discription[index] : "",
        total: Number(
          calculateDiscountedTotal(
            unitCosts1[index],
            receivedQuantities1[index],
            discounts[index] || 0
          )
        ),
        imeiNumber: item?.imeiNumber ? item?.imeiNumber : null,
        imeiNumberArr: item?.imeiNumberArr,
        isRefundQuantity: Number(receivedQuantities[index]),
        isRefundPrice: Number(unitCosts[index]),
        isRefundDiscount: Number(discounts[index]),
        averageCost: item.averageCost,
      };
    });

    const paymentData = location?.state?.data?.payment?.map((item) => {
      return {
        itemId: item.itemId,
        paymentType: item.paymentType,
        date: item?.date, //moment(new Date()).format("YYYY-MM-DD"),
        time: item?.time, // moment(new Date()).format("HH:mm:ss"),
        payAmount: item.payAmount || 0,
        isRefundDate: moment(new Date()).format("YYYY-MM-DD"),
        isRefundTime: moment(new Date()).format("HH:mm:ss"),
        isRefundAmount: Number(overallTotal1) || 0,
        isRefundPaymentType: item.paymentType,
      };
    });

    const paymentData1 = location?.state?.data?.payment?.map((item) => {
      return {
        itemId: item?.itemId,
        paymentType: item.paymentType,
        date: moment(new Date()).format("YYYY-MM-DD"),
        time: moment(new Date()).format("HH:mm:ss"),
        payAmount: Number(overallTotal1) || 0,
        isRefundDate: moment(new Date()).format("YYYY-MM-DD"),
        isRefundTime: moment(new Date()).format("HH:mm:ss"),
        isRefundAmount: Number(overallTotal1) || 0,
      };
    });

    const raw = JSON.stringify({
      itemIds: location?.state?.data?.items.map((item) => item?.itemId),
      items: Data1,
      customerId: customerData?.id ? customerData?.id : null,
      subTotal: subTotal,
      discount: discount,
      tax: tax,
      grandTotal: grandTotal,
      paymentType: paymentId,
      isCompleted: 1,
      note: "",
      due: 0,
      parkNote: "",
      payment: paymentData,
      ticketId: ticketid,
      totalCost1: totalCosts.toFixed(2), //totalCost1,
      totalSalesCost1: overallTotal, //totalSalesCost.toFixed(2),
      taxAmount1: taxAmount.toFixed(2),
      totalSalesQuantity1: TotalQty, //totalSalesQuantity1,

      itemIds1: selectProduct?.map((item) => item?.itemId.id),
      items1: Data,
      subTotal1: overallTotal,
      discount1: discount,
      tax1: tax,
      grandTotal1: overallTotal1.toFixed(2),
      paymentType1: paymentId ? paymentId : selectedPaymentTypeId,
      isCompleted1: 1,
      note1: "",
      due1: 0,
      parkNote1: "",
      payment1: paymentData1,
      totalCost: totalCost,
      totalSalesCost: totalAveraSale,
      taxAmount: taxAmounts,
      totalSalesQuantity: taotalSaleQuantity,
      averageSalesCost: totalAverageSalesCost,
      discountAmount: discountAmounts,
      isAdmin: adminState,
      invoiceStatus: 1,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("refundddata", raw);

    fetch(`${baseurl}/returnSales/${invoiceId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("refunfresponse", result);
        if (result?.status === true) {
          setLoading(false);
          // Swal.fire(result?.message);
          //setShowModal(false);
          // setEmail("");
          // navigate("/invoicelist");
          setInvoiceData(result?.invoiceData);
          GetInvoiceId(result?.invoiceData?.id);
          setInvoiceID1(result?.data?.id);
          setShowModal9(true);
        } else {
          setLoading(false);
          Swal.fire(result?.message);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const [errormsg, setErrormsg] = useState("");
  const maxDescriptionLength = 300;

  const handleDescriptionChangeNote = (e) => {
    const newDescription = e.target.value;

    if (newDescription.length <= maxDescriptionLength) {
      setDiscription((prevUnitCosts) => {
        const newUnitCosts = [...prevUnitCosts];
        newUnitCosts[editedProductIndex] = newDescription;
        return newUnitCosts;
      });
      // setDescriptionNote(newDescription);
      setErrormsg("");
    } else if (newDescription.length > maxDescriptionLength) {
      setTimeout(() => {
        setErrormsg("Allow Maximum 300 Characters");
      }, 2000);
    }
  };

  const calculateDiscountedTotal = (unitCost, receivedQty, discount) => {
    const total = unitCost * receivedQty;
    if (!discount) {
      return total.toFixed(2);
    }
    const discountedTotal = total * (1 - discount / 100);
    return discountedTotal >= 0 ? `${discountedTotal.toFixed(2)}` : "$0";
  };

  const calculateOverallTotal = () => {
    let overallTotal = 0;

    selectProduct.forEach((_, index) => {
      const discountedTotal = calculateDiscountedTotal(
        unitCosts[index],
        receivedQuantities[index],
        discounts1[index]
      );
      overallTotal += parseFloat(discountedTotal);
    });

    return overallTotal >= 0 ? overallTotal.toFixed(2) : "0.00";
  };

  const overallTotal = calculateOverallTotal();

  const discountedSubtotal = overallTotal * (1 - discount / 100);

  const taxAmount = discountedSubtotal * (tax / 100);
  const discountAmounts = parseFloat(overallTotal - discountedSubtotal).toFixed(
    2
  );
  // console.log("discount", discountAmounts);

  const overallTotal1 = discountedSubtotal + taxAmount;
  const handleUnitCostChange = (e) => {
    const newUnitCost = e.target.value;
    if (newUnitCost.trim().length > 0) {
      const prevUnitCost = unitCosts1[editedProductIndex];
      const newUnitCostFloat = parseFloat(newUnitCost);

      if (isNaN(newUnitCostFloat) || newUnitCostFloat > prevUnitCost) {
        setTimeout(() => {
          alert("New value should be less than or equal to previous unit cost");
        }, 1000);
      } else {
        setUnitCosts((prevUnitCosts) => {
          const newUnitCosts = [...prevUnitCosts];
          newUnitCosts[editedProductIndex] = newUnitCostFloat;
          return newUnitCosts;
        });
        setErrormsg("");
        // Update the totals
        setTotalCost(calculateTotalCost());
        setTotalSalesCost1(calculateTotalSalesCost());
      }
    } else {
      setUnitCosts((prevUnitCosts) => {
        const newUnitCosts = [...prevUnitCosts];
        newUnitCosts[editedProductIndex] = 0;
        return newUnitCosts;
      });
      setErrormsg("");
      // Update the totals
      setTotalCost(calculateTotalCost());
      setTotalSalesCost1(calculateTotalSalesCost());
    }
  };

  const handleQuantityChange = (e) => {
    const newQuantity = e.target.value;
    if (newQuantity.trim().length > 0) {
      const prevQuantity = receivedQuantities1[editedProductIndex];
      const newQuantityFloat = parseFloat(newQuantity);

      if (isNaN(newQuantityFloat) || newQuantityFloat > prevQuantity) {
        setTimeout(() => {
          alert("New value should be less than or equal to previous quantity");
        }, 1000);
      } else {
        setReceivedQuantities((prevQuantities) => {
          const newQuantities = [...prevQuantities];
          newQuantities[editedProductIndex] = newQuantityFloat;
          return newQuantities;
        });
        setErrormsg("");
        // Update the totals
        setTotalCost(calculateTotalCost());
        setTotalSalesCost1(calculateTotalSalesCost());
      }
    } else {
      setReceivedQuantities((prevQuantities) => {
        const newQuantities = [...prevQuantities];
        newQuantities[editedProductIndex] = 0;
        return newQuantities;
      });
      setErrormsg("");
      // Update the totals
      setTotalCost(calculateTotalCost());
      setTotalSalesCost1(calculateTotalSalesCost());
    }
  };

  const calculateTotalCost = () => {
    return selectProduct
      .reduce((total, product, index) => {
        return total + unitCosts[index];
      }, 0)
      .toFixed(2);
  };

  const calculateTotalSalesCost = () => {
    return selectProduct?.reduce((total, product, index) => {
      console.log("product", product);
      const productAverageCost = product?.itemId?.sellingPrice;
      const unitCost = Number(unitCosts[index]) || 0;
      const priceToUse =
        unitCost !== undefined && unitCost !== null
          ? unitCost
          : productAverageCost;
      console.log("productAverageCost", productAverageCost);
      const productQuantity = receivedQuantities[index] || 1;
      console.log("productQuantity", productQuantity);
      console.log("productAverageCost", productAverageCost);
      return total + priceToUse * productQuantity;
    }, 0);
  };

  const calculateTotalSalesQuantity = () => {
    return selectProduct.reduce((total, product, index) => {
      return total + receivedQuantities[index];
    }, 0);
  };

  const totalCost1 = calculateTotalCost();
  const totalSalesCost = calculateTotalSalesCost();
  console.log("totalCost1", totalCost1);
  console.log("totalSalesCost", totalSalesCost);
  const totalSalesQuantity1 = calculateTotalSalesQuantity();
  console.log("totalSalesQuantity1", totalSalesQuantity1);

  const calculateTotalSalesAverageCost = () => {
    return selectProduct?.reduce((total, product, index) => {
      // console.log("aveproduct", product);
      const isDevice = product?.itemId?.productCategoryId === 2;
      const productCost = isDevice
        ? product?.itemId?.unitCost
        : product?.itemId?.averageCost || 0;
      const productQuantity = receivedQuantities[index] || 1;

      return total + productCost * productQuantity;
    }, 0);
  };

  const totalAverageSalesCost = calculateTotalSalesAverageCost();
  console.log("totalAverageSalesCost", totalAverageSalesCost);

  useEffect(() => {
    GetPaymentOptions();
  }, []);

  const GetPaymentOptions = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GettAllPaymentApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllPaymentOptions(result?.data.reverse());

        const filteredOptions = result?.data.filter(
          (option) => option.defaultPaymentOption === 1
        );
        // setSelectedCategory2(filteredOptions);
        setSelectedPaymentTypeId(filteredOptions.map((option) => option?.id));
        setSelectedPaymentType(filteredOptions.map((option) => option?.name));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleBrandChange = (value) => {
    setSelectedCategory2([value]);
    setSelectedPaymentTypeId(value?.id);
    setSelectedPaymentType(value?.name);
    setIsPaymentOptionSelected(true);
  };

  useEffect(() => {
    if (overallTotal1 !== null) {
      setInputValue(overallTotal1.toFixed(2));
    }
  }, [overallTotal1]);
  const [remainingAmount, setRemainingAmount] = useState(overallTotal1);
  useEffect(() => {
    const totalPayment = paymentMode.reduce(
      (total, payment) => total + payment.payAmount,
      0
    );
    setTotalPayment(totalPayment);
  }, [paymentMode]);

  const handlePay = () => {
    if (inputValue > overallTotal1) {
      const changes = inputValue - overallTotal1;

      setChangeDue(changes.toFixed(2));
    }

    // if (selectedPaymentType === "Payment Options") {
    //   Swal.fire("Please select a payment option.");
    //   return;
    // }

    const payAmount = Number(inputValue) || 0;

    if (payAmount === 0) {
      return;
    }
    setPaymentTotal(payAmount);

    const paymentData = {
      itemId: selectProduct.map((item) => item.id),
      paymentType: selectedCategory2,
      date: moment(new Date()).format("YYYY-MM-DD"),
      time: moment(new Date()).format("HH:mm:ss"),
      payAmount: payAmount,
    };

    setPaymentMode((prevPaymentMode) => [...prevPaymentMode, paymentData]);
    setTotalPayment((prevTotalPayment) => prevTotalPayment + payAmount);
    const newRemainingAmount = overallTotal1 - totalPayment - payAmount;
    setRemainingAmount(newRemainingAmount);
    setInputValue(
      newRemainingAmount !== undefined ? newRemainingAmount.toFixed(2) : ""
    );

    if (totalPayment + payAmount === overallTotal1) {
      setErrorAmount(true);
    }

    setPaymnetState(true);
  };

  // const handlePay = () => {
  //   // if (selectedProduct.length === 0) {
  //   //   Swal.fire("Please select a product first.");
  //   //   return;
  //   // }

  //   // if (overallTotal1 <= 0) {
  //   //   Swal.fire("Please select a product with a valid price.");
  //   //   return;
  //   // }

  //   if (inputValue > overallTotal1) {
  //     // Calculate change due
  //     const changes = inputValue - overallTotal1;
  //     console.log("changedddd", changes);
  //     setChangeDue(changes.toFixed(2));
  //   }

  //   // if (totalPayment + Number(inputValue) < overallTotal1) {
  //   //   Swal.fire(
  //   //     "Overall total has been reached or exceeded, no more items can be added."
  //   //   );
  //   //   return;
  //   // }

  //   if (selectedPaymentType === "Payment Options") {
  //     Swal.fire("Please select a payment option.");
  //     return;
  //   }

  //   const payAmount = Number(inputValue) || 0;

  //   if (payAmount === 0) {
  //     // Swal.fire("Your payment is complete.");
  //     return;
  //   }
  //   setPaymentTotal(payAmount);

  //   const paymentData = {
  //     itemId: selectProduct.map((item) => item.id),
  //     paymentType: selectedPaymentType,
  //     date: moment(new Date()).format("YYYY-MM-DD"),
  //     time: moment(new Date()).format("HH:mm:ss"),
  //     payAmount: payAmount,
  //   };

  //   console.log("Payment Data:", paymentData);

  //   // Update payment mode
  //   setPaymentMode((prevPaymentMode) => [...prevPaymentMode, paymentData]);

  //   // Update total payment
  //   setTotalPayment((prevTotalPayment) => prevTotalPayment + payAmount);

  //   // Update remaining amount and input value
  //   const newRemainingAmount = overallTotal1 - totalPayment - payAmount;
  //   setRemainingAmount(newRemainingAmount);
  //   setInputValue(
  //     newRemainingAmount !== undefined ? newRemainingAmount.toFixed(2) : ""
  //   );

  //   // Check if the total payment equals the overall total
  //   if (totalPayment + payAmount === overallTotal1) {
  //     setErrorAmount(true);
  //     console.log("Payment complete. Please finalize your invoice.");
  //   }

  //   // Optionally set payment state
  //   setPaymnetState(true);
  // };

  // const handlePay = () => {
  //   if (inputValue <= 0) {
  //     Swal.fire("Please enter a valid payment amount.");
  //     return;
  //   }

  //   if (overallTotal1 <= 0) {
  //     Swal.fire("Please select a product with a valid price.");
  //     return;
  //   }

  //   if (inputValue > overallTotal1) {
  //     Swal.fire("Please add less amount than the overall total.");
  //     return;
  //   }

  //   if (totalPayment + Number(inputValue) > overallTotal1) {
  //     Swal.fire(
  //       "Overall total has been reached or exceeded, no more items can be added."
  //     );
  //     return;
  //   }
  //   if (!selectedPaymentType) {
  //     Swal.fire("Please select a payment option.");
  //     return;
  //   }

  //   const paymentData = selectProduct?.map((item) => ({
  //     itemId: item?.itemId,
  //     paymentType: selectedPaymentType,
  //     date: moment(new Date()).format("YYYY-MM-DD"),
  //     time: moment(new Date()).format("HH:mm:ss"),
  //     payAmount: Number(inputValue) || 0,
  //   }));

  //   console.log("ghsghs", paymentData);

  //   // Update paymentMode state by appending new payment data array to the existing array
  //   setPaymentMode([...paymentData]);

  //   setTotalPayment(
  //     (prevTotalPayment) =>
  //       prevTotalPayment + Number(inputValue) * selectProduct.length
  //   );

  //   // Update totalPayment by calculating the sum of payAmounts
  //   setTotalPayment(
  //     (prevTotalPayment) => prevTotalPayment + Number(inputValue)
  //   );

  //   // Update remainingAmount and inputValue
  //   const newRemainingAmount =
  //     overallTotal1 - totalPayment - Number(inputValue);
  //   setRemainingAmount(newRemainingAmount);
  //   setInputValue(
  //     newRemainingAmount !== undefined ? newRemainingAmount.toFixed(2) : ""
  //   );

  //   // setPaymnetState(true);
  // };

  const handleDeletePayment = (index) => {
    setPaymentMode((prevPaymentMode) => {
      const updatedPaymentMode = [...prevPaymentMode];
      const [removedPayment] = updatedPaymentMode.splice(index, 1);
      updatePaymentTotals(removedPayment.payAmount);
      return updatedPaymentMode;
    });
  };

  const updatePaymentTotals = (amount) => {
    setTotalPayment((prevTotalPayment) => prevTotalPayment - amount);
    setRemainingAmount((prevRemainingAmount) => {
      const newRemainingAmount = prevRemainingAmount + amount;
      setInputValue(newRemainingAmount.toFixed(2));
      return newRemainingAmount;
    });
  };

  const [isFreeTrial, setIsFreeTrial] = useState(false);

  useEffect(() => {
    const substatus = localStorage.getItem("substatus");
    const planDate = localStorage.getItem("planDate");

    if (
      substatus === "null" ||
      substatus === "canceled" ||
      (substatus === "expired" &&
        new Date(localStorage.getItem("planDate")) > new Date())
    ) {
      setIsFreeTrial(true);
    }
  }, []);

  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
    localStorage.setItem("sidebar", !isSidebarVisible);
  };

  useEffect(() => {
    const savedSidebarState = localStorage.getItem("sidebar");
    if (savedSidebarState) {
      setSidebarVisible(JSON.parse(savedSidebarState));
    }
  }, []);

  const [isFreeTrialVisible, setFreeTrialVisible] = useState(true);

  const toggleFreeTrial = () => {
    setFreeTrialVisible(!isFreeTrialVisible);
  };

  const sidebarStyleone = {
    overflow: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    // position: 'sticky',

    // marginTop: isFreeTrialVisible ? "0px" : "0px",
  };

  useEffect(() => {
    if (isFreeTrial) {
      // toggleSidebar(false);
      toggleFreeTrial(false);
    } else {
      // toggleSidebar(true);
      toggleFreeTrial(true);
    }
  }, [isFreeTrial]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      const sidebarElement = document.querySelector(".main-sidebar");
      const toggleButtonElement = document.querySelector(".sidebar-arrow a");

      if (
        sidebarElement &&
        !sidebarElement.contains(event.target) &&
        toggleButtonElement &&
        !toggleButtonElement.contains(event.target)
      ) {
        const mediaQuery = window.matchMedia("(max-width: 1024px)");
        if (mediaQuery.matches) {
          setSidebarVisible(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const GetReciept = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetRecieptApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          if (!result.data) {
            setReciept(true);
          } else {
            setStates(true);
            const data = result?.data;
            setRecieptId(data?.id);
            setPrintType(data.type);
            setTitle(data.receiptTitle);
            setLogo(data.logo);
            setIsLogo(data.isLogo === 1);
            setLogoSize(data.logoSize);
            setLogoPosition(data.logoPosition);
            setHeader(data.headerInfo);
            setDisplaySales(data.displaySalesPersonName === 1);
            setFooter(data.footerInfo);
            setNote(data.displayPublicNote === 1);
            setDisplayBarcode(data.displayBarcode === 1);
            setDisplayNote(data.note === 1);
            setSGTNumber(data?.gst);
            setLeftMargin(data?.leftMargin || "10mm");
            setRightMargin(data?.rightMargin || "10mm");
            setGstShow(data?.gstButton);
            setSelectedOption({
              prodname: data.items.name === 1 ? 1 : 0,
              prodname_sku:
                data.items.name === 1 && data.items.sku === 1 ? 1 : 0,
              sku: data.items.sku === 1 ? 1 : 0,
            });

            setCustomerDetails({
              fullname: data.customer.name === 1 ? 1 : 0,
              email: data.customer.email === 1 ? 1 : 0,
              phone: data.customer.phoneNumber === 1 ? 1 : 0,
              address: data.customer.address === 1 ? 1 : 0,
            });
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const printSetup = () => {
    Swal.fire("You have not  Setup Receipt Configuration ");
  };

  const printTable = () => {
    const printContents = document.querySelector(".reciepttable").innerHTML;
    const originalContents = document.body.innerHTML;
    // const printWindow = window.open("", "_blank");
    const width = 800;
    const height = 600;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    // Open a new window with centered position
    const printWindow = window.open(
      "",
      "_blank",
      `width=${width},height=${height},top=${top},left=${left}`
    );
    // if (!printWindow) {
    //   Swal.fire(
    //     "Unable to open print window. Please check your pop-up settings."
    //   );
    //   return;
    // }
    printWindow.document.open();
    printWindow.document.write(`
    <html>
      <head>
     <style>
     @media print {
  @page {
    margin: 0; /* Remove default margins to ensure there's no space at the top and bottom */
  }
  body {
    /* Remove margins on body as well */
  margin-left: ${leftMargin}px;
    margin-right: ${rightMargin}px;
     height:auto
  }
  /* Hide browser print headers and footers */
  header,
  footer {
    display: none;
  }
}
     </style>

     </head>
      <body>${printContents}</body>
    </html>
  `);
    printWindow.document.close();
    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 200); // Adjust the delay as needed
    // printWindow.print();
    // printWindow.close();
    setShowModal9(false);

    navigate("/invoicelist");
    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
  };

  const GetInvoiceId = (id) => {
    setLoading(true);
    let local = localStorage.getItem("token");
    //console.log(local);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getInvoiceById/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          // console.log("invoiceDataa", result?.data[0]);
          setInVoiceData1(result?.data[0]);

          setPayment(result?.data[0]?.payment);
          setItems(result?.data[0]?.items);
          setItemData(result?.data[0]?.itemIds);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const EmailSend1 = () => {
    setLoading(true);
    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));

    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: customerName ? customerName : "user",
      email: email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseurl}sendInvoiceMail/${invoiceId1}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setLoading(false);
          // Swal.fire("Email Receipt Successfully! ");
          Swal.fire({
            title: "Receipt sent to email successfully!!",
            customClass: {
              container: "custom-swal-container",
              confirmButton: "custom-swal-confirm-button",
            },
          });
          setShowModal11(false);

          setPaymentMode([]);
          setPaymnetState(false);

          setShowModal9(false);

          document
            .querySelectorAll(".modal-backdrop")
            .forEach((el) => el.classList.remove("modal-backdrop"));
          setEmail("");
        } else {
          setLoading(false);
          Swal.fire({
            title: result.message,
            customClass: {
              container: "custom-swal-container",
              confirmButton: "custom-swal-confirm-button",
            },
          });
          setEmail("");
          setShowModal11(false);
          document
            .querySelectorAll(".modal-backdrop")
            .forEach((el) => el.classList.remove("modal-backdrop"));
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const OnSubmit = () => {
    setError(ValidationEmail());
  };

  const isDisposableEmail = (email) => {
    const domain = email.split("@")[1];
    return disposableEmailDomains.includes(domain);
  };

  const disposableEmailDomains = [
    // "gmail.com",
    // "yahoo.com",
    // "outlook.com",
    // "hotmail.com",
    // "aol.com",
    // "icloud.com",
    // "mail.com",
    // "yandex.com",
    // "protonmail.com",
    // "zoho.com",
    // "gmx.com",
    // "ymail.com",
    // "comcast.net",
    // "me.com",
    // "msn.com",
    // "live.com",
    // "sbcglobal.net",
    // "verizon.net",
    // "att.net",
    // "cox.net",
    // "smartitventures.com",
    // Add more domains as needed
    "mailinator.com",
    "guerrillamail.com",
    "10minutemail.com",
    "temp-mail.org",
    "yopmail.com",
    "getnada.com",
    "trashmail.com",
    "fakeinbox.com",
    "throwawaymail.com",
    "maildrop.cc",
    "mytemp.email",
    "tempmailo.com",
    "dispostable.com",
    "mintemail.com",
    "airmail.com",
  ];

  const ValidationEmail = () => {
    let error = {};
    let reg = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    // let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (!email) {
      error.email = "Email is required.";
    } else if (!reg.test(email)) {
      error.email = "Invalid email .";
    } else if (email && isDisposableEmail(email)) {
      error.email =
        "Please use a valid email address. Temporary email addresses are not allowed.";
    }
    if (Object.keys(error).length === 0) {
      EmailSend1();
    }
    return error;
  };

  return (
    <>
      <div className={isSidebarVisible ? "sidebar-mini" : ""}>
        <div
          className={`${
            isFreeTrial
              ? "main-wrapper devicelistcstmtableres customclswrapper"
              : "main-wrapper devicelistcstmtableres "
          } `}
        >
          {loading ? (
            <div className="landing_loader">
              <div className="innerloader">
                <img src="img/spin-1s-156px.gif" alt="" />
              </div>
            </div>
          ) : null}
          {isFreeTrial ? (
            <>
              <div className="app-container-trial">
                <FreeTrail />
              </div>
            </>
          ) : null}

          <div
            // className={`${isSidebarVisible ? "sidebar-mini" : ""} ${
            //   !isFreeTrialVisible ? "no-free-trial" : ""
            // }`}
            style={sidebarStyleone}
          >
            <nav className="navbar navbar-expand-lg main-navbar sticky">
              <div className="sidebar-arrow">
                <a
                  href="javascript:;"
                  data-toggle="sidebar"
                  onClick={toggleSidebar}
                >
                  <img src="assets/img/sidebar-toggle.webp" alt="" />
                </a>
              </div>
              <div className="header-title mr-auto">
                <h4>
                  Refund{" "}
                  <a
                    className="notes_icon"
                    href="#"
                    data-toggle="modal"
                    data-target="#noteinfoModal"
                  >
                    <img src="assets/img/notes-icon.svg" alt="" />
                  </a>
                </h4>
              </div>
              <SideUl />
            </nav>
            <MainSidebar />
            <div className="main-content tableback">
              <div className="row">
                <div className="col-lg-5 col-md-12"></div>
                <div className="col-lg-7 col-md-12">
                  <div className="cus_table cash_register_table">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th colSpan={2}>ITEM(s)</th>
                            <th>In stock</th>
                            <th>Qty.</th>
                            <th>Unit Price</th>
                            <th>Total Price</th>
                            {returnData === 1 ? <th></th> : <th>Action</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {selectProduct?.map((item, index) => {
                            const correspondingItem = item;
                            const receivedQuantity = receivedQuantities1[index];
                            const refundQuantity = refundQty[index];
                            const isQuantityEqualToRefund =
                              receivedQuantity === refundQuantity;
                            console.log("ittr", item);
                            return (
                              <tr key={index}>
                                <td>
                                  <span className="item_no">#{index + 1}</span>
                                </td>
                                <td>
                                  <a>
                                    {item?.itemId.title}{" "}
                                    <span>({item?.itemId.skuCode})</span>
                                  </a>
                                </td>
                                <td>{item?.itemId.inventory}</td>
                                <td>
                                  {returnData === 1 ||
                                  isQuantityEqualToRefund ? (
                                    <>
                                      <div className="quantity_btn">
                                        {/* <a
                                      // href="#"
                                      className="qty_minus"
                                      // onClick={() =>
                                      //   handleDecreaseQuantity(index)
                                      // }
                                    >
                                      <img
                                        src="assets/img/minus-icon.webp"
                                        alt=""
                                      />
                                    </a> */}

                                        <input
                                          type="text"
                                          className="qty_count"
                                          value={receivedQuantities[index]}
                                          readOnly
                                        />
                                        {/* <a
                                      className="qty_plus"
                                      // onClick={() => {
                                      //   handleIncreaseQuantity(index);
                                      // }}
                                      // disabled={
                                      //   receivedQuantities[index] >=
                                      //   receivedQuantities[index]
                                      // }
                                    >
                                      <img
                                        src="assets/img/plus-icon.webp"
                                        alt=""
                                      />
                                    </a> */}
                                        {refundQty ? (
                                          <span>- {refundQty[index]}</span>
                                        ) : null}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <div className="quantity_btn">
                                        <a
                                          // href="#"
                                          className="qty_minus"
                                          onClick={() =>
                                            handleDecreaseQuantity(index)
                                          }
                                        >
                                          <img
                                            src="assets/img/minus-icon.webp"
                                            alt=""
                                          />
                                        </a>
                                        <input
                                          type="text"
                                          className="qty_count"
                                          value={receivedQuantities[index]}
                                          readOnly
                                        />
                                        <a
                                          className="qty_plus"
                                          onClick={() => {
                                            handleIncreaseQuantity(index);
                                          }}
                                          disabled={
                                            receivedQuantities[index] >=
                                            receivedQuantities[index]
                                          }
                                        >
                                          <img
                                            src="assets/img/plus-icon.webp"
                                            alt=""
                                          />
                                        </a>{" "}
                                        {refundQty ? (
                                          <span>- {refundQty[index]}</span>
                                        ) : null}
                                      </div>
                                    </>
                                  )}
                                </td>

                                {/* {refundQuantity ? <td>-{refundQuantity}</td> : null} */}
                                <td>$ {unitCosts[index]}</td>
                                {returnData === 1 ||
                                selectProduct.some(
                                  (item, index) =>
                                    receivedQuantities1[index] ===
                                    refundQty[index]
                                ) ? (
                                  <td>
                                    -
                                    {calculateDiscountedTotal(
                                      unitCosts[index],
                                      receivedQuantities[index],
                                      discounts[index] || 0
                                    )}
                                  </td>
                                ) : (
                                  <td>
                                    {calculateDiscountedTotal(
                                      unitCosts[index],
                                      receivedQuantities[index],
                                      discounts[index] || 0
                                    )}
                                  </td>
                                )}
                                <td>
                                  {returnData === 1 ||
                                  isQuantityEqualToRefund ? (
                                    <>
                                      {/* <div className="action_btn">
                                    <a
                                      href="#"
                                      data-toggle="modal"
                                      data-target="#editinfoModal"
                                      className="edit_btn"
                                      //onClick={() => handleEditItem(item, index)}
                                    >
                                      <img
                                        src="assets/img/edit-icon.webp"
                                        alt=""
                                      />
                                    </a>
                                    <a
                                      style={{ cursor: "pointer" }}
                                      className="delete_btn"
                                      //onClick={() => handleDelete(index)}
                                    >
                                      <img
                                        src="assets/img/delete-icon.webp"
                                        alt=""
                                      />
                                    </a>
                                  </div> */}
                                    </>
                                  ) : (
                                    <>
                                      <div className="action_btn">
                                        <a
                                          href="#"
                                          data-toggle="modal"
                                          data-target="#editinfoModal"
                                          className="edit_btn"
                                          onClick={() =>
                                            handleEditItem(item, index)
                                          }
                                        >
                                          <img
                                            src="assets/img/edit-icon.webp"
                                            alt=""
                                          />
                                        </a>
                                        <a
                                          style={{ cursor: "pointer" }}
                                          className="delete_btn"
                                          onClick={() => handleDelete(index)}
                                        >
                                          <img
                                            src="assets/img/delete-icon.webp"
                                            alt=""
                                          />
                                        </a>
                                      </div>
                                    </>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                        <tfoot>
                          <tr className="price_tr">
                            <td colSpan={3}>Sub Total</td>
                            <td />
                            {returnData === 1 ||
                            selectProduct.some(
                              (item, index) =>
                                receivedQuantities1[index] === refundQty[index]
                            ) ? (
                              <td colSpan={3}>-${overallTotal}</td>
                            ) : (
                              <td colSpan={3}>${overallTotal}</td>
                            )}
                          </tr>
                          <tr className="price_tr">
                            <td colSpan={3}>
                              <span>Discount:</span>
                              <select className="tble_select">
                                <option value={discount}>{discount}%</option>
                              </select>
                            </td>
                            <td />
                            <td colSpan={3}>
                              $
                              {(
                                overallTotal - parseFloat(discountedSubtotal)
                              ).toFixed(2)}
                              {/* $
                                {discountedSubtotal
                                  ? discountedSubtotal.toFixed(2)
                                  : 0} */}
                            </td>
                          </tr>
                          <tr className="price_tr">
                            <td colSpan={3}>
                              <span>Tax:</span>
                              <select className="tble_select">
                                <option value={tax}>{tax}%</option>
                              </select>
                            </td>
                            <td />
                            <td colSpan={3}>
                              ${taxAmount ? taxAmount.toFixed(2) : 0}
                            </td>
                          </tr>
                          <tr className="grand_total">
                            <td colSpan={3}>Grand Total</td>
                            <td />
                            {returnData === 1 ||
                            selectProduct.some(
                              (item, index) =>
                                receivedQuantities1[index] === refundQty[index]
                            ) ? (
                              <td colSpan={3}>-${overallTotal1.toFixed(2)}</td>
                            ) : (
                              <td colSpan={3}>${overallTotal1.toFixed(2)}</td>
                            )}
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div className="payment_method">
                      <div className="payment_mode">
                        <div className="inner_payment_mode">
                          <div className="mode_title">
                            {refundState === true ? (
                              <span className="refund">Refund Made</span>
                            ) : (
                              <span>Payment Made</span>
                            )}
                          </div>
                          <div className="mode_table">
                            <table>
                              <thead>
                                <tr>
                                  <th>Time</th>
                                  <th>Type</th>
                                  <th>Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* {location?.state?.data?.payment.map(
                              (itm, index) => (
                                <tr key={`original-${index}`}>
                                  <td>
                                    {itm.date},{" "}
                                    {moment(itm?.time, "HH:mm:ss").format(
                                      "hh:mm A"
                                    )}{" "}
                                  </td>
                                  <td>
                                    <b>{itm?.paymentType}</b>
                                  </td>
                                  <td>
                                    <b>${itm.payAmount}</b>
                                  </td>
                                </tr>
                              )
                            )} */}
                                {refundState === true
                                  ? paymentMode.map((payment, index) => (
                                      <tr key={`added-${index}`}>
                                        <td>
                                          {moment(
                                            payment?.date + " " + payment?.time
                                          ).format("YYYY-MM-DD hh:mm A")}
                                        </td>
                                        <td>
                                          <b>{payment.paymentType}</b>
                                        </td>
                                        <td>
                                          <b>${payment.payAmount}</b>
                                        </td>
                                        <td>
                                          <a
                                            href="#"
                                            className="delete_btn"
                                            onClick={() =>
                                              handleDeletePayment(index)
                                            }
                                          >
                                            <img
                                              src="assets/img/delete-icon.webp"
                                              alt=""
                                            />
                                          </a>
                                        </td>
                                      </tr>
                                    ))
                                  : location?.state?.data?.payment.map(
                                      (itm, index) => (
                                        <tr key={`original-${index}`}>
                                          <td>
                                            {itm.date},{" "}
                                            {moment(
                                              itm?.time,
                                              "HH:mm:ss"
                                            ).format("hh:mm A")}{" "}
                                          </td>
                                          <td>
                                            <b>{itm?.paymentType}</b>
                                          </td>
                                          <td>
                                            <b>${itm.payAmount}</b>
                                          </td>
                                        </tr>
                                      )
                                    )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      {Array.isArray(receivedQuantities1) &&
                      receivedQuantities1.length > 0 &&
                      receivedQuantities1.every(
                        (qty, index) => qty === refundQty[index]
                      ) ? null : (
                        <>
                          <div className="inner_payment_method return_payment_method">
                            <div className="cash_dropdown payment_col">
                              <div className="form-control paymenttypecustmcashregister">
                                <div
                                  className="dropdown customtabledropdownbox1 customtabledropdownbox2  customcarditcardbtndivsetting"
                                  // onClick={() => {
                                  //   if (allPaymentOptions?.length > 0) {
                                  //     setShowlist(!showlist);
                                  //   } else {
                                  //     Swal.fire("Please Set Payment Options First");
                                  //   }
                                  // }}
                                >
                                  <button
                                    type="button"
                                    className="btn btn-secondary dropdown-toggle paymentoptiomstypecustmcash  customcreditcardbtnsetting"
                                    // role="button"
                                    // id="dropdownMenuLink"
                                    // data-bs-toggle="dropdown"
                                    // aria-expanded="false"
                                  >
                                    <div>{selectedCategory2}</div>
                                    {/* {selectedCategory2?.length > 0 ? (
                                      <>
                                        {selectedCategory2.map((item) => (
                                          <div key={item.id}>{item?.name}</div>
                                        ))}
                                      </>
                                    ) : (
                                      <div>Payment Options</div>
                                    )} */}
                                  </button>
                                  {/* <ul
                            className="dropdown-menu customdropdownulboxsetting  customcarditcardulbottombarsetting"
                            aria-labelledby="dropdownMenuLink"
                          >
                            {showlist === true &&
                            allPaymentOptions.length > 0 ? (
                              <>
                                {allPaymentOptions.map(
                                  (item) =>
                                    item.activeInactive === 1 && (
                                      <li key={item.id}>
                                        <a
                                          className="dropdown-item  customcarditcardlialinkbtnsetting"
                                          href="#"
                                          onClick={() => {
                                            handleBrandChange(item);
                                            setShowlist(false);
                                          }}
                                        >
                                          {item?.name}
                                        </a>
                                      </li>
                                    )
                                )}
                              </>
                            ) : null}
                          </ul>
                          <img
                            onClick={() => setShowlist(!showlist)}
                            src="assets/img/select-arrow.webp"
                            class="customcarditcardarrowbtnsetting"
                            alt=""
                          /> */}
                                </div>
                              </div>
                            </div>

                            <input
                              type="number"
                              value={inputValue}
                              className="price_value payment_col no-arrow-input"
                              style={{
                                fontWeight: 700,
                                color: "black",
                                fontSize: 18,
                              }}
                              onChange={(e) => {
                                setInputValue(e.target.value);
                              }}
                            />

                            <div className="pay_btn payment_col paydisable">
                              <a
                                onClick={() => {
                                  setRefundState(true);
                                  handlePay();
                                }}
                                disabled={totalPayment === overallTotal1}
                              >
                                Return ${inputValue}
                              </a>

                              {/* <a
                        className="paydisable"
                        href="#"
                        data-toggle="modal"
                        data-target="#paymentModal"
                      >
                        Return $0.00
                      </a> */}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div style={{ display: "none" }}>
                      <div className="reciepttable">
                        <div
                          style={{
                            width: "80mm",
                            // margin: "auto",
                            // padding: "auto",
                            fontFamily: "Arial, sans-serif",
                            height: "auto",
                            fontSize: 12,
                            // leftMargin: `${leftMargin}px`,
                            // rightMargin: `${rightMargin}px`,
                          }}
                          border={0}
                        >
                          <div>
                            <div>
                              <div>
                                <div
                                  style={{ width: "100%", textAlign: "center" }}
                                  border={0}
                                >
                                  <div>
                                    <div>
                                      {isLogo && logo ? (
                                        <div
                                          style={{
                                            textAlign:
                                              logoPosition === 0
                                                ? "left"
                                                : "center",
                                            marginBottom: 25,
                                          }}
                                        >
                                          <img
                                            src={logo}
                                            alt="Logo"
                                            style={{
                                              width: logoSize === 1 ? 300 : 50,
                                              height: logoSize === 1 ? 110 : 55,
                                              // objectFit: "cover",
                                              // display: "block",
                                              margin: "0 auto",
                                            }}
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                    <div>
                                      <div
                                        style={{
                                          fontSize: 12,
                                          fontWeight: 700,

                                          color: "#000",
                                        }}
                                      >
                                        {title ? title : "--"}
                                      </div>
                                    </div>
                                    <div>
                                      <div
                                        style={{
                                          fontSize: 12,
                                          fontWeight: 500,
                                          marginTop: 20,
                                          color: "#000",
                                        }}
                                      >
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: header
                                              ? header
                                                  .trim()
                                                  .replace(/\n/g, "<br>")
                                              : "--",
                                          }}
                                        ></div>
                                        {/* <pre
                                          style={{
                                            fontSize: 12,
                                            fontWeight: 500,
                                            color: "#000",
                                            //marginTop: 20,
                                          }}
                                        >
                                          {header ? header : "--"}
                                        </pre> */}
                                      </div>
                                    </div>
                                    <div>
                                      <div style={{ padding: "8px 0px" }} />
                                    </div>
                                    <div></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div>
                                <div style={{ width: "100%" }} border={0}>
                                  <div>
                                    <div>
                                      <div
                                        style={{
                                          fontSize: 12,
                                          fontWeight: 400,

                                          color: "#000",
                                        }}
                                      >
                                        <span style={{ fontWeight: 700 }}>
                                          Sales Ref:
                                        </span>{" "}
                                        #{InvoiceData1?.id}
                                      </div>
                                    </div>
                                    <div>
                                      <div
                                        style={{
                                          fontSize: 12,
                                          fontWeight: 400,
                                          //lineHeight: 20,
                                          color: "#000",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontWeight: 700,
                                          }}
                                        >
                                          Customer:
                                        </span>{" "}
                                        {customerDetails.fullname === 1 &&
                                        InvoiceData1?.customer?.firstName
                                          ? `${InvoiceData1?.customer?.firstName} 
                              }`
                                          : "unassigned"}
                                        {customerDetails.email === 1 &&
                                        InvoiceData1?.customer?.email ? (
                                          <>
                                            {" "}
                                            <br />
                                            <span
                                              style={{
                                                fontWeight: 700,
                                                marginTop: 28,
                                              }}
                                            >
                                              Email:
                                            </span>
                                            {InvoiceData1?.customer?.email}
                                          </>
                                        ) : null}
                                        {customerDetails.phone === 1 &&
                                        InvoiceData1?.customer?.phoneNumber ? (
                                          <>
                                            {" "}
                                            <br />
                                            <span
                                              style={{
                                                fontWeight: 700,
                                                marginTop: 28,
                                              }}
                                            >
                                              Phone No.:
                                            </span>
                                            {
                                              InvoiceData1?.customer
                                                ?.phoneNumber
                                            }
                                          </>
                                        ) : null}
                                        {customerDetails.address === 1 &&
                                        InvoiceData1?.customer?.streetAdress ? (
                                          <>
                                            {" "}
                                            <br />
                                            <span
                                              style={{
                                                fontWeight: 700,
                                                marginTop: 28,
                                              }}
                                            >
                                              Address:
                                            </span>
                                            {
                                              InvoiceData1?.customer
                                                ?.streetAdress
                                            }
                                          </>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div>
                                      <div
                                        style={{
                                          fontSize: 12,
                                          fontWeight: 400,
                                          color: "#000",
                                          marginTop: 0,
                                        }}
                                      >
                                        {moment(
                                          InvoiceData1.createdAt,
                                          "YYYY/MM/DD"
                                        ).format("DD/MM/YYYY")}
                                        {"  "}
                                        {moment(InvoiceData1?.createdAt).format(
                                          "LT"
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div style={{ padding: "4px 0px" }} />
                            </div>
                            <div>
                              <div>
                                <table
                                  className="tabledata"
                                  style={{ width: "100%" }}
                                  border={0}
                                >
                                  <thead>
                                    <tr>
                                      <th
                                        style={{
                                          fontSize: 12,
                                          fontWeight: 700,
                                          marginTop: 20,
                                          color: "#000",
                                          textAlign: "center",
                                          borderBottom: "2px dashed #000",
                                          padding: 4,
                                        }}
                                      >
                                        QTY
                                      </th>
                                      <th
                                        style={{
                                          fontSize: 12,
                                          fontWeight: 700,
                                          marginTop: 20,
                                          color: "#000",
                                          textAlign: "left",
                                          borderBottom: "2px dashed #000",
                                          padding: 4,
                                        }}
                                      >
                                        Item Name
                                      </th>
                                      <th
                                        style={{
                                          fontSize: 12,
                                          fontWeight: 700,
                                          marginTop: 20,
                                          color: "#000",
                                          textAlign: "right",
                                          borderBottom: "2px dashed #000",
                                          padding: 4,
                                        }}
                                      >
                                        Discount
                                      </th>
                                      <th
                                        style={{
                                          fontSize: 12,
                                          fontWeight: 700,
                                          marginTop: 20,
                                          color: "#000",
                                          textAlign: "right",
                                          borderBottom: "2px dashed #000",
                                          padding: 4,
                                        }}
                                      >
                                        Amount
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {Items.map((item, index) => {
                                      const correspondingItem = ItemData?.find(
                                        (itemId) => itemId?.id === item?.itemId
                                      );
                                      const subtotal = parseFloat(
                                        InvoiceData1.subTotal
                                      );
                                      const discountPercentage = parseFloat(
                                        InvoiceData1.discount
                                      );
                                      const taxRate = parseFloat(
                                        InvoiceData1.tax
                                      );

                                      const discountedSubtotal =
                                        subtotal *
                                        (1 - discountPercentage / 100);
                                      const taxAmount =
                                        discountedSubtotal * (taxRate / 100);
                                      const overallTotal1 =
                                        discountedSubtotal + taxAmount;
                                      return (
                                        <tr>
                                          <td
                                            style={{
                                              fontSize: 12,
                                              fontWeight: 500,
                                              marginTop: 20,
                                              color: "#000",
                                              textAlign: "center",
                                              padding: 4,
                                            }}
                                          >
                                            [-{item?.quantity}@{item?.unitCost}]
                                          </td>
                                          <td
                                            style={{
                                              fontSize: 12,
                                              fontWeight: 500,
                                              marginTop: 20,
                                              color: "#000",
                                              textAlign: "left",
                                              padding: 4,
                                            }}
                                          >
                                            {selectedOption?.prodname === 1 &&
                                            selectedOption?.sku === 0 &&
                                            selectedOption?.prodname_sku === 0
                                              ? correspondingItem?.title
                                              : ""}
                                            {selectedOption?.sku === 1 &&
                                            selectedOption?.prodname === 0 &&
                                            selectedOption?.prodname_sku === 0
                                              ? correspondingItem?.skuCode
                                              : ""}
                                            {selectedOption?.prodname_sku ===
                                              1 &&
                                            selectedOption?.prodname === 1 &&
                                            selectedOption?.sku === 1
                                              ? `${correspondingItem?.title}(${correspondingItem?.skuCode})`
                                              : ""}
                                            {/* {selectedOption.prodname === 1
                                              ? correspondingItem?.title
                                              : "--"} */}
                                          </td>
                                          <td
                                            style={{
                                              fontSize: 12,
                                              fontWeight: 500,
                                              marginTop: 20,
                                              color: "#000",
                                              textAlign: "right",
                                              padding: 4,
                                            }}
                                          >
                                            {item?.discount}
                                          </td>
                                          <td
                                            style={{
                                              fontSize: 12,
                                              fontWeight: 500,
                                              marginTop: 20,
                                              color: "#000",
                                              textAlign: "right",
                                              padding: 4,
                                            }}
                                          >
                                            {item?.total}
                                          </td>
                                        </tr>
                                      );
                                    })}

                                    <tr>
                                      <td
                                        style={{
                                          fontSize: 12,
                                          fontWeight: 500,
                                          // lineHeight: 20,
                                          color: "#000",
                                          textAlign: "center",
                                          padding: 4,
                                          marginTop: 20,
                                          borderBottom: "2px dashed #000",
                                        }}
                                      ></td>
                                      <td
                                        style={{
                                          fontSize: 12,
                                          fontWeight: 500,
                                          //lineHeight: 20,
                                          color: "#000",
                                          textAlign: "left",
                                          padding: 4,
                                          marginTop: 20,
                                          borderBottom: "2px dashed #000",
                                        }}
                                      ></td>
                                      <td
                                        style={{
                                          fontSize: 12,
                                          fontWeight: 500,
                                          //lineHeight: 20,
                                          color: "#000",
                                          textAlign: "right",
                                          padding: 4,
                                          marginTop: 20,
                                          borderBottom: "2px dashed #000",
                                        }}
                                      ></td>
                                      <td
                                        style={{
                                          fontSize: 12,
                                          fontWeight: 500,
                                          //lineHeight: 20,
                                          color: "#000",
                                          textAlign: "right",
                                          padding: 4,
                                          marginTop: 20,
                                          borderBottom: "2px dashed #000",
                                        }}
                                      ></td>
                                    </tr>
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <td
                                        style={{
                                          fontSize: 12,
                                          fontWeight: 500,
                                          marginTop: 20,
                                          color: "#000",
                                          textAlign: "left",
                                          padding: 4,
                                        }}
                                        colSpan={2}
                                      >
                                        Subtotal
                                      </td>
                                      <td
                                        style={{
                                          fontSize: 12,
                                          fontWeight: 500,
                                          marginTop: 20,
                                          color: "#000",
                                          textAlign: "right",
                                          padding: 4,
                                        }}
                                        colSpan={2}
                                      >
                                        ${InvoiceData1?.subTotal}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          fontSize: 12,
                                          fontWeight: 400,
                                          marginTop: 20,
                                          color: "#000",
                                          padding: 4,
                                        }}
                                        colSpan={2}
                                      >
                                        Discounts ({InvoiceData1.discount || 0}
                                        %)
                                      </td>
                                      <td
                                        style={{
                                          fontSize: 12,
                                          fontWeight: 500,
                                          marginTop: 20,
                                          color: "#000",
                                          textAlign: "right",
                                          padding: 4,
                                        }}
                                        colSpan={2}
                                      >
                                        $
                                        {parseFloat(InvoiceData1?.subTotal) -
                                          parseFloat(
                                            discountedSubtotal
                                          ).toFixed(2) || 0}
                                        {/* {discountedSubtotal.toFixed(2)} */}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          fontSize: 12,
                                          fontWeight: 500,
                                          marginTop: 20,
                                          color: "#000",
                                          textAlign: "left",
                                          padding: 4,
                                        }}
                                        colSpan={2}
                                      >
                                        Tax ({InvoiceData1.tax || 0}%)
                                      </td>
                                      <td
                                        style={{
                                          fontSize: 12,
                                          fontWeight: 500,
                                          marginTop: 20,
                                          color: "#000",
                                          textAlign: "right",
                                          padding: 4,
                                        }}
                                        colSpan={2}
                                      >
                                        ${taxAmount.toFixed(2) || 0}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          fontSize: 12,
                                          fontWeight: 700,
                                          marginTop: 20,
                                          color: "#000",
                                          textAlign: "left",
                                          borderBottom: "2px dashed #000",
                                          padding: 4,
                                        }}
                                        colSpan={2}
                                      >
                                        Grand Total
                                      </td>
                                      <td
                                        style={{
                                          fontSize: 12,
                                          fontWeight: 700,
                                          marginTop: 20,
                                          color: "#000",
                                          textAlign: "right",
                                          borderBottom: "2px dashed #000",
                                          padding: 4,
                                        }}
                                        colSpan={2}
                                      >
                                        ${overallTotal1.toFixed(2)}
                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                              </div>
                            </div>
                            <div>
                              <div style={{ padding: "8px 0px" }} />
                            </div>
                            <div>
                              <div>
                                <table style={{ width: "100%" }} border={0}>
                                  <tbody>
                                    {payment.map((item) => (
                                      <>
                                        <tr>
                                          <td
                                            style={{
                                              fontSize: 12,
                                              fontWeight: 400,
                                              //lineHeight: 20,
                                              color: "#000",
                                              marginTop: 20,
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontWeight: 600,
                                              }}
                                            >
                                              Received:
                                            </span>{" "}
                                            ${item.payAmount}
                                          </td>
                                          <td
                                            style={{
                                              fontSize: 12,
                                              fontWeight: 400,
                                              //lineHeight: 20,
                                              color: "#000",
                                              marginTop: 20,
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontWeight: 700,
                                              }}
                                            >
                                              {item.paymentType}
                                              {"  "}
                                            </span>
                                            {item.date}{" "}
                                            {moment(
                                              item.time,
                                              "HH:mm:ss"
                                            ).format("hh:mm A")}{" "}
                                          </td>
                                        </tr>{" "}
                                      </>
                                    ))}

                                    <tr>
                                      <td
                                        style={{
                                          fontSize: 12,
                                          fontWeight: 400,
                                          marginTop: 20,
                                          color: "#000",
                                        }}
                                        colSpan={2}
                                      >
                                        Change Given:{" "}
                                        <span style={{ fontWeight: 700 }}>
                                          $
                                          {InvoiceData1?.due
                                            ? InvoiceData1?.due
                                            : 0}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          fontSize: 12,
                                          fontWeight: 400,
                                          marginTop: 20,
                                          color: "#000",
                                        }}
                                        colSpan={2}
                                      >
                                        <span style={{ fontWeight: 700 }}>
                                          Cashier:
                                        </span>{" "}
                                        {(adminState === 0 ||
                                          permission?.length > 0) &&
                                          dataUser?.userDetails?.name}
                                        {(adminState === 1 ||
                                          permission?.length === 0) &&
                                          data1?.userDetails?.userName}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div>
                              <div style={{ padding: "12px 0px" }} />
                            </div>
                            <div>
                              <div>
                                <div
                                  style={{ width: "100%", textAlign: "center" }}
                                  border={0}
                                >
                                  <div>
                                    <div>
                                      <div
                                        style={{
                                          textAlign: "justify",
                                          fontSize: "12px",
                                          fontWeight: "400",
                                          color: "#000",
                                          whiteSpace: "pre-wrap",
                                          // Preserve whitespace and allow wrapping
                                          wordWrap: "break-word", // Allow long words to break
                                          maxWidth: "100%",
                                        }}
                                        // style={{
                                        //   textAlign: "center",
                                        //   fontSize: "12px",
                                        //   fontWeight: "400",
                                        //   color: "#000",
                                        // }}
                                      >
                                        <pre
                                          style={{
                                            textAlign: "justify",
                                            fontSize: "12px",
                                            fontWeight: 400,
                                            color: " #000",
                                            whiteSpace: "pre-wrap",
                                            wordWrap: "break-word",
                                            maxWidth: "100%",
                                          }}
                                        >
                                          {footer ? footer : "--"}
                                        </pre>
                                        {/* <div
                                        style={{
                                          fontSize: 12,
                                          fontWeight: 400,
                                          marginTop: 10,
                                          color: "#000",
                                        }}
                                      >
                                        <pre> {footer ? footer : "--"}</pre> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div>
                              <div>
                                <div
                                  style={{ width: "100%", textAlign: "center" }}
                                  border={0}
                                >
                                  <div>
                                    {gstShow === 1 ? (
                                      <div>
                                        <div
                                          style={{
                                            fontSize: 12,
                                            fontWeight: 400,
                                            marginTop: 10,
                                            color: "#000",
                                          }}
                                        >
                                          Hst #: {gstNumber ? gstNumber : "--"}
                                        </div>
                                      </div>
                                    ) : null}
                                    {/* <div>
                        <div>
                          <img src="img/receipt-barcode.jpg" alt="" />
                        </div>
                      </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {Array.isArray(receivedQuantities1) &&
                    receivedQuantities1.length > 0 &&
                    receivedQuantities1.every(
                      (qty, index) => qty === refundQty[index]
                    ) ? null : (
                      <>
                        <div className="complete_pross_btn return_process_btn">
                          <a
                            href="#"
                            data-toggle="modal"
                            data-target="#paymentModal"
                            className="complete_btn return_items_btn"
                            onClick={(e) => {
                              setErrorAmount(false);
                              e.preventDefault();
                              Refund();
                              setShowModal9(true);
                            }}
                          >
                            Return Items
                          </a>
                        </div>
                      </>
                    )}
                    {/* {Array.isArray(receivedQuantities) &&
                    receivedQuantities.length > 0 &&
                    receivedQuantities.every(
                      (qty, index) => qty === refundQty[index]
                    ) ? null : (
                      <>
                        <div
                          className="complete_pross_btn return_process_btn"
                          onClick={Refund}
                        >
                          <a href="#" className="complete_btn return_items_btn">
                            Return Items
                          </a>
                        </div>
                      </>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
        {showModal9 === true ? (
          <>
            <div
              className={`modal common_modal payment_modal ${
                showModal9 ? "active" : ""
              }`}
              id="paymentModal"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="paymentModalLabel"
              aria-hidden="true"
              data-backdrop="static" // Prevent closing when clicking outside
              data-keyboard="false" // Prevent closing when pressing escape
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setPaymentMode([]);
                        setPaymnetState(false);

                        setShowModal9(false);
                        navigate("/invoicelist");
                        setDiscounts([]);
                        document
                          .querySelectorAll(".modal-backdrop")
                          .forEach((el) =>
                            el.classList.remove("modal-backdrop")
                          );
                      }}
                    >
                      <span className="close_icon" />
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="payment_receipt_btns">
                      <div className="receipt_btn">
                        <div className="receipt_col colhalf">
                          <a
                            href="#"
                            data-toggle="modal"
                            data-target="#parksaleModal"
                            className="recept_btn recpt_email_btn"
                            onClick={() => {
                              setShowModal11(true);
                              //  EmailSend();
                            }}
                          >
                            Email
                          </a>
                        </div>
                        <div className="receipt_col colhalf">
                          <a
                            href="#"
                            data-dismiss="modal"
                            aria-label="Close"
                            className="recept_btn recpt_noreceipt_btn"
                            onClick={() => {
                              // setSelectedProduct([]);
                              // setSelectedRetrieveProduct([]);
                              // setPaymentMode([]);
                              setPaymnetState(false);
                              // setSelectedCustomer({});
                              setShowModal9(false);
                              // setSelectedState(false);
                              navigate("/invoicelist");
                              setDiscounts([]);
                              document
                                .querySelectorAll(".modal-backdrop")
                                .forEach((el) =>
                                  el.classList.remove("modal-backdrop")
                                );
                            }}
                          >
                            No Receipt
                          </a>
                        </div>

                        <div className="receipt_col colfull">
                          {printType === 2 || reciept === true ? (
                            <a
                              href="#"
                              className="recept_btn recpt_print_btn"
                              onClick={() => {
                                printSetup();
                                setShowModal9(false);
                                document
                                  .querySelectorAll(".modal-backdrop")
                                  .forEach((el) =>
                                    el.classList.remove("modal-backdrop")
                                  );
                                navigate("/invoicelist");
                              }}
                            >
                              Print Receipt
                            </a>
                          ) : (
                            <a
                              href="#"
                              className="recept_btn recpt_print_btn"
                              onClick={printTable}
                            >
                              Print Receipt
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
        {showModal1 === true ? (
          <>
            <div
              className="modal common_modal editinfo_modal"
              id="editinfoModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="editinfoModalLabel"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="editinfoModalLabel">
                      Edit Information
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        resetFormValues(); // Reset form values
                        setShowModal1(false); // Close the modal
                      }}
                      //onClick={() => setShowModal1(false)}
                    >
                      <span className="close_icon"></span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="custom_form editinfo_form">
                      <form action="" method="" className="cus_form">
                        <div className="form_row">
                          <div className="form_column col_three">
                            <div className="form_field price_field">
                              <div className="form-group">
                                <label>Unit Price</label>
                                <input
                                  type="number"
                                  className="form-control no-arrow-input"
                                  name="unit_price"
                                  placeholder="Enter Price"
                                  // onMouseLeave={handleChange}
                                  value={
                                    unitCosts[editedProductIndex] === 0
                                      ? ""
                                      : unitCosts[editedProductIndex]
                                  }
                                  onChange={handleUnitCostChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form_column col_three">
                            <div className="form_field quantity_field">
                              <div className="form-group">
                                <label>Sales Quantity</label>
                                <input
                                  type="number"
                                  className="form-control no-arrow-input"
                                  name="quantity"
                                  placeholder="Enter Quantity"
                                  value={
                                    receivedQuantities[editedProductIndex] === 0
                                      ? receivedQuantities
                                      : receivedQuantities[editedProductIndex]
                                  }
                                  onChange={handleQuantityChange}
                                  // onChange={(e) => {
                                  //   setReceivedQuantities(
                                  //     (prevReceivedQuantities) => {
                                  //       const newReceivedQuantities = [
                                  //         ...prevReceivedQuantities,
                                  //       ];
                                  //       newReceivedQuantities[
                                  //         editedProductIndex
                                  //       ] = e.target.value;
                                  //       return newReceivedQuantities;
                                  //     }
                                  //   );

                                  //   setReceivedQuantities1(
                                  //     (prevReceivedQuantities) => {
                                  //       const newReceivedQuantities = [
                                  //         ...prevReceivedQuantities,
                                  //       ];
                                  //       newReceivedQuantities[
                                  //         editedProductIndex
                                  //       ] = e.target.value;
                                  //       return newReceivedQuantities;
                                  //     }
                                  //   );
                                  // }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form_column col_three">
                            <div className="form_field discount_field">
                              <div className="form-group">
                                <label>Discount</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="discount"
                                  placeholder="Enter Discount"
                                  value={discounts[editedProductIndex]}
                                  onChange={(e) => {
                                    const newDiscounts = [...discounts];
                                    newDiscounts[editedProductIndex] =
                                      e.target.value;
                                    setDiscounts(newDiscounts);
                                    setDiscounts1(newDiscounts);
                                  }}
                                />
                                <div className="price_type">
                                  <h5>%</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form_row">
                          <div className="form_column col_full">
                            <div className="form_field note_field">
                              <div className="form-group">
                                <textarea
                                  className="form-control"
                                  name="note"
                                  placeholder="Enter your note"
                                  value={
                                    discription[editedProductIndex] === 0
                                      ? ""
                                      : discription[editedProductIndex]
                                  }
                                  onChange={handleDescriptionChangeNote}
                                ></textarea>
                                <span className="span_note">
                                  Max Characters 300
                                </span>
                                {errormsg && (
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      color: "red",
                                    }}
                                  >
                                    {errormsg}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary default_btn"
                      data-dismiss="modal"
                      onClick={() => {
                        resetFormValues(); // Reset form values
                        setShowModal1(false); // Close the modal
                      }}
                      //onClick={() => setShowModal1(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary theme_btn"
                      data-dismiss="modal"
                      onClick={() => handleUpdate(setSelectProduct)}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
        {showModal11 === true ? (
          <>
            <div
              className="modal common_modal parksale_modal"
              id="parksaleModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="parksaleModalLabel"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="parksaleModalLabel">
                      Email
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setShowModal11(false);
                        setEmail("");

                        document
                          .querySelectorAll(".modal-backdrop")
                          .forEach((el) =>
                            el.classList.remove("modal-backdrop")
                          );
                      }}
                    >
                      <span className="close_icon"></span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="custom_form parksale_form">
                      <form className="cus_form">
                        <div className="form_row">
                          <div className="form_column col_full">
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control"
                                name="address"
                                placeholder="Enter Email"
                                value={email}
                                onChange={(e) => {
                                  setEmail(e.target.value.trim());
                                  if (error.email) {
                                    setError({ ...error, email: "" });
                                  }
                                }}
                              />
                              {error.email && (
                                <span className="customvalidationdesignlogin">
                                  {error.email}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary theme_btn"
                      onClick={() => {
                        OnSubmit();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
      <div
        className="modal common_modal noteinfo_modal"
        id="noteinfoModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="noteinfoModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="noteclose"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src="assets/img/notes-close-icon.svg" alt="" />
              </button>
            </div>
            <div className="modal-body">
              <div className="notes_tabs">
                <ul
                  className="nav nav-pills notetabs"
                  id="notetabs"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="video_demo-tab"
                      data-toggle="tab"
                      href="#video_demo"
                      role="tab"
                      aria-controls="video_demo"
                      aria-selected="true"
                    >
                      Video Demo
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="page_info-tab"
                      data-toggle="tab"
                      href="#page_info"
                      role="tab"
                      aria-controls="page_info"
                      aria-selected="false"
                    >
                      Page Information
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="settingContent">
                  <div
                    className="tab-pane fade active show"
                    id="video_demo"
                    role="tabpanel"
                    aria-labelledby="video_demo-tab"
                  >
                    <div className="notetabs_content">
                      <h4>Video Demo</h4>
                      <p>
                        In this short tutorial, we’ll walk you through the key
                        features and functions of the Dashboard page.
                      </p>
                      <div className="notes_videos">
                        <img
                          src="assets/img/notes-demo-videos-img.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="page_info"
                    role="tabpanel"
                    aria-labelledby="page_info-tab"
                  >
                    <div className="notetabs_content">
                      <h4>Page Information</h4>
                      <p>
                        This page provides a comprehensive view of all the tools
                        and features available to you. Each section is designed
                        to help you manage your business more efficiently,
                        whether you’re tracking sales, monitoring inventory, or
                        analyzing reports.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Refund;
