import React from "react";

const OfflinePopup = ({ isVisible }) => {
  return (
    isVisible && (
      <div className="container offline-popup ">
        <div className="row align-items-center">
          <div className="text-center">
            <img src="assets/img/wireless.png" className="  offlineimgcstm" />
          </div>
          <p className="text-center">
            OPPS! LOOK LIKE YOUR CONNECTION IS GONE.
          </p>
        </div>
      </div>
    )
  );
};

export default OfflinePopup;
