import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Protected(props) {
  let Cmp = props.Cmp;
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let local = localStorage.getItem("token");
    let sub = localStorage.getItem("substatus");

    if (!local) {
      navigate("/Login");
    }

    if (
      (sub === "null" || sub === "canceled" || sub === "expired") &&
      new Date() > new Date(localStorage.getItem("planDate"))
    ) {
      navigate("/settings", { state: { activeState: 1 } });
    } else {
      setLoading(false); // Set loading to false if conditions are not met
    }
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Cmp />
    </div>
  );
}
export default Protected;
