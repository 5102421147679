import React, { useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { baseurl } from "../Utility/Api";
const Success = () => {
  useEffect(() => {
    GetPaymentStatus();
  }, []);

  const GetPaymentStatus = () => {
    let local = localStorage.getItem("token");
    // console.log("token", local);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3A8zFJIp9l67A7RYb9n8QktakW6MX32BoP.yp7Ffp065PepxXqgOF0UqXoXq9xuJ%2FtLX0jL%2FVFrfSg"
    );

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}updatePaymentStatus/1`, requestOptions)
      .then((response) => response.json())
      .then((result) => console.log("status", result))
      .catch((error) => console.error(error));
  };
  return (
    <>
      <header className="header" style={{ backgroundColor: "#84E9FF" }}>
        <div className="container">
          <div className="header_inner">
            <div className="logo">
              <NavLink>
                <img alt="Toolbox Logo" src="img/toolbox-logo-txt.webp" />
              </NavLink>
            </div>
            <nav className="navbar navbar-expand-lg custom_navbar backbtncancel">
              <NavLink className="navbar-brand">
                <img alt="Toolbox Logo" src="img/toolbox-logo-txt.webp" />
              </NavLink>

              <div className="account_btns">
                <Link
                  to="/cash-register"
                  state={{ activeState: 1 }}
                  className="accnt_btn"
                  style={{ backgroundColor: "#000" }}
                >
                  Back
                </Link>
              </div>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav customulresponsivecss"></ul>
              </div>
            </nav>
          </div>
        </div>
      </header>
      {/* <header className="header" style={{ backgroundColor: "#84E9FF" }}>
        <div className="container">
          <div className="header_inner">
            <div className="logo">
              <NavLink>
                <img alt="Toolbox Logo" src="img/toolbox-logo-txt.webp" />
              </NavLink>
            </div>
            <nav className="navbar navbar-expand-lg custom_navbar cstmnavbarresponscss">
              <NavLink className="navbar-brand">
                <img alt="Toolbox Logo" src="img/toolbox-logo-txt.webp" />
              </NavLink>

              <div className="account_btns">
                <Link
                  to="/cash-register"
                  className="accnt_btn"
                  style={{ backgroundColor: "#000" }}
                >
                  Back
                </Link>
              </div>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav customulresponsivecss"></ul>
              </div>
            </nav>
          </div>
        </div>
      </header> */}
      <div className="box">
        <div className="success alert ">
          <div className="alert-body">Payment Submitted Successfully!</div>
          {/* <span className=" message mt-lg-5">
            Your request has been submitted successfully, and your account will
            be activate shortly.
          </span> */}
        </div>
      </div>
    </>
  );
};
export default Success;
