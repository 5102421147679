import React, { useEffect, useState } from "react";
import MainSidebar from "./MainSidebar";
import SideUl from "./SideUl";
import { Helmet } from "react-helmet";
import Footer from "../Footer";
import moment from "moment";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import FreeTrail from "./FreeTrail";
import {
  GetMessagesApi,
  getProfileApi,
  GetRecieptApi,
  GetSubUserProfileApi,
  OpenCloseAddApi,
  OpenCloseGetApi,
  SwitchstoreApi,
} from "../Utility/Api";
import CashManagmentOpen from "./CashManagmentOpen";
import { useMemo } from "react";
import BannerMessage from "./BannerMessage";

const Password = () => {
  let navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [subdomain, setSubdomain] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [type, setType] = useState("password");
  const [message1, setMessage1] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Show the modal when the component mounts
    setShowModal(true);
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const emailParam = urlParams.get("email"); // Get email from params
    setEmail(emailParam);

    // Extract Subdomain
    let hostParts = window.location.host.split(".");
    console.log("susuus", hostParts[0]);

    setSubdomain(hostParts[0]); // First part of the domain is the subdomain

    // SwitchAccount(emailParam, hostParts[0]);
  }, []);

  const SwitchAccount = () => {
    let mail = localStorage.getItem("email");

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      subDomain: subdomain,
      email: email,
      password: password,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    console.log("Switch raw", raw);

    fetch(SwitchstoreApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("Switch result", result);

        if (result.status === true) {
          localStorage.setItem("token", result?.token);

          const newToken = localStorage.getItem("token");
          console.log("newToken===", newToken);

          localStorage.setItem("email", result?.data?.email);
          localStorage.setItem("subscribed", result?.comapany?.isSubscribed);
          localStorage.setItem("planDate", result?.comapany?.planDate);
          localStorage.setItem("admin", result?.admin);
          localStorage.setItem("userId", result?.data?.userId);
          localStorage.setItem("subuserId", result?.data?.id);
          localStorage.setItem("userRole", result?.data?.userRole?.name);
          localStorage.setItem(
            "substatus",
            result?.comapany?.subscriptionStatus
          );
          localStorage.setItem(
            "permission",
            JSON.stringify(result?.permission)
          );
          navigate("/dashboard");
        } else {
          console.error("Switch account failed:", result.message);
          setMessage1(result.message);
          setTimeout(() => {
            setMessage1("");
          }, 2500);
        }
      })
      .catch((error) => {
        console.error("Error switching account:", error);
      });
  };
  const handleSubmit = () => {
    if (!password.trim()) {
      setError("Password cannot be empty");
      setTimeout(() => {
        setError("");
      }, 2500);
      return;
    }
    setError(""); // Agar valid hai to error clear karega
    SwitchAccount(); // API call karega
  };

  return (
    <>
      <div
        className={`main-wrapper sublogincstm ${showModal ? "modal-open" : ""}`}
      >
        <div className="login_page">
          <div className="regi_step_form">
            <form className="stepform">
              <div className="login_inner">
                <div className="login_left">
                  <div className="topheader">
                    <a href="#">
                      <img src="img/toolbox-logo-txt.webp" alt="" />
                    </a>
                  </div>
                  <div className="logo">
                    <a href="#">
                      <img src="img/landing-logo-icon.webp" alt="" />
                    </a>
                  </div>
                </div>
                <div className="login_right">
                  <div className="form_area">
                    <div className="form_title"></div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        {/* Custom Modal */}
        {showModal && (
          <div
            className="modal common_modal parksale_modal show"
            id="parksaleModal1"
            role="dialog"
            aria-labelledby="parksaleModalLabel"
            aria-hidden="true"
            style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="parksaleModalLabel">
                    Enter a Password
                  </h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShowModal(false)}
                    aria-label="Close"
                  >
                    <span className="close_icon"></span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="custom_form parksale_form">
                    <form className="cus_form">
                      <div className="form_row">
                        <div className="form_column col_full">
                          <div className="form-group">
                            <p>
                              <strong> Email:</strong>
                              {email}
                            </p>
                            <div className="form-group">
                              <div className="input_field">
                                <input
                                  type={showPassword ? "text" : "password"}
                                  className="form-control"
                                  value={password}
                                  onChange={(e) => {
                                    setPassword(e.target.value.trim());
                                  }}
                                  // onKeyDown={handleKeyPress}
                                  placeholder="Password"
                                />{" "}
                                {/* <img
                                  src={
                                    showPassword
                                      ? "assets/img/eyeclose.webp"
                                      : "assets/img/eyeup.webp"
                                  }
                                  className="customupdatepasswordformeyeiconsettingreset"
                                  onClick={togglePasswordVisibility}
                                  alt={
                                    showPassword
                                      ? "Hide password"
                                      : "Show password"
                                  }
                                /> */}
                              </div>
                              {error && (
                                <span
                                  className="customvalidationdesignlogin"
                                  style={{ color: "red" }}
                                >
                                  {error}
                                </span>
                              )}
                              {message1 && (
                                <span
                                  className="customvalidationdesignlogin"
                                  style={{ color: "red" }}
                                >
                                  {message1}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary theme_btn"
                    onClick={handleSubmit}
                  >
                    Switch User
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* <div
        className={`main-wrapper sublogincstm ${showModal ? "modal-open" : ""}`}
      >
        <div className="login_page">
          <div className="regi_step_form">
            <form className="stepform">
              <div className="login_inner">
                <div className="login_left">
                  <div className="topheader">
                    <a href="#">
                      <img src="img/toolbox-logo-txt.webp" alt="" />
                    </a>
                  </div>
                  <div className="logo">
                    <a href="#">
                      <img src="img/landing-logo-icon.webp" alt="" />
                    </a>
                  </div>
                </div>
                <div className="login_right">
                  <div className="form_area">
                    <div className="form_title"></div>

                  
                    {showModal && (
                      <div className="modal-overlay">
                        <div className="modal-content">
                          <h4>Switch User</h4>
                          <p>
                            Email: <strong>{email}</strong>
                          </p>
                          <div className="form-group">
                            <label className="cus_label">Enter Password</label>
                            <div className="input_field">
                              <img
                                src={
                                  showPassword
                                    ? "assets/img/eyeclose.webp"
                                    : "assets/img/eyeup.webp"
                                }
                                className="customupdatepasswordformeyeiconsettingreset"
                                onClick={togglePasswordVisibility}
                                alt={
                                  showPassword
                                    ? "Hide password"
                                    : "Show password"
                                }
                              />
                              <input
                                type={showPassword ? "text" : "password"}
                                className="form-control"
                                value={password}
                                onChange={(e) => {
                                  setPassword(e.target.value.trim());
                                }}
                                // onKeyDown={handleKeyPress}
                                placeholder="Password"
                              />
                            </div>
                            {error.password && (
                              <span className="customvalidationdesignlogin">
                                {error.password}
                              </span>
                            )}
                            {message1 && (
                              <span style={{ color: "red" }}>{message1}</span>
                            )}
                          </div>
                          <div className="modal-buttons">
                            <button
                              className="cancel_btn"
                              onClick={() => setShowModal(false)}
                            >
                              Cancel
                            </button>
                            <button
                              className="switch_btn"
                              onClick={SwitchAccount}
                            >
                              Switch User
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div> */}
    </>
  );
};
export default Password;
