import React, { useState } from "react";
import { baseurl } from "../Utility/Api";
import Swal from "sweetalert2";

const InventoryModal = (props) => {
  const [title, setTitle] = useState("");
  const [error, setError] = useState({});
  const [imeiNumber, setIMEINumber] = useState("");
  const [brand, setBrand] = useState("");
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);

  useState(() => {
    // console.log("inventory props", props?.data);
    setTitle(props?.data?.title);
    setBrand(props?.data?.brand?.name);
    setIMEINumber(props?.data?.imeiNumber);
    setId(props?.data?.id);
  }, []);

  const EditInventory = () => {
    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      OldImeiNumber: props?.data?.imeiNumber,
      NewImeiNumber: imeiNumber,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // console.log("editimei", raw);

    fetch(`${baseurl}/editItemImei/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result?.status === true) {
          setLoading(false);
          props.onpress(false);
          props?.onload();
          Swal.fire(result?.message);
        } else {
          setLoading(false);
          props.onpress(false);
          props?.onload();
          Swal.fire(result?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const Validation = () => {
    let error = {};
    if (!imeiNumber) {
      error.imeiNumber = "IMEI Number Is Required";
    }
    if (Object.keys(error).length === 0) {
      // console.log(Object.keys(error).length);
      EditInventory();
    }
    return error;
  };

  const Edit = () => {
    setError(Validation());
  };

  return (
    <React.Fragment>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="createproductModalLabel1">
              Device Inventory Information
            </h5>

            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                document
                  .querySelectorAll(".modal-backdrop")
                  .forEach((el) => el.classList.remove("modal-backdrop"));
              }}
            >
              <span className="close_icon"></span>
            </button>
          </div>
          <div className="modal-body">
            <div className="custom_form createproduct_form">
              <form action="" method="" className="cus_form">
                <div className="form_row product_radio_row">
                  <div className="form_column col_full">
                    <div className="form-group"></div>
                  </div>
                </div>

                <div className="product_row">
                  <div className="product_col70 cus_prod_col">
                    <h4>
                      General information<span>*</span>
                    </h4>
                    <p>To start selling, all you need is a name and a price.</p>
                    <div className="form_row">
                      <div className="form_column col_full">
                        <div className="form-group">
                          <label className="cus_label">Brand</label>
                          <div className="formfield field_wd100 selectcategoryformbox">
                            <div className="tagsscustomdiv">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  // ref={inputRef5}
                                  type="text"
                                  className="customsearchinputboxsetting inputdeviceformcustmbox form-control"
                                  placeholder={`Select Brand`}
                                  value={brand}
                                  readOnly
                                  //   onChange={handleSearchChange1}
                                  //   onClick={() => setShowList1(!showList1)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form_column col_full">
                        <div className="form-group">
                          <label className="cus_label">Model Name</label>
                          <div className="formfield field_wd100">
                            <input
                              type="text"
                              className="form-control"
                              name="title"
                              placeholder="Enter Model Name"
                              value={title}
                              onChange={(e) => {
                                setTitle(e.target.value);
                                // if (error.title) {
                                //   setError({ ...error, title: "" });
                                // }
                              }}
                              readOnly
                            />{" "}
                          </div>{" "}
                        </div>
                      </div>

                      <div className="form_column col_full">
                        <div className="form-group">
                          <label className="cus_label">IMEI/Serial</label>
                          <div className="formfield field_wd100">
                            <input
                              type="text"
                              className="form-control"
                              name="serialno"
                              placeholder="Enter IMEI/Serial"
                              value={imeiNumber}
                              onChange={(e) =>
                                setIMEINumber(e.target.value.trim())
                              }
                            />
                          </div>
                          {error?.imeiNumber && (
                            <span
                              style={{
                                fontSize: "12px",
                                color: "red",
                                marginLeft: "105px",
                              }}
                            >
                              {error?.imeiNumber}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clearfix"></div>
              </form>
            </div>
          </div>

          <div className="modal-footer">
            {/* <button
                type="button"
                className="btn btn-secondary default_btn"
                //data-dismiss="modal"
                // onClick={() => {
                //   SubmitDevice1();
                // }}
              >
                Save as draft
              </button> */}
            <button
              type="button"
              className="btn btn-primary theme_btn"
              onClick={() => Edit()}
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default InventoryModal;
