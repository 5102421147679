import React, { useEffect, useState } from "react";
import $ from "jquery";

import { Link, NavLink } from "react-router-dom";
import {
  GetDemoStoreDetailsApi,
  getProfileApi,
  GetSubUserProfileApi,
  UpdateSubscriptionApi,
} from "../Utility/Api";
import moment from "moment";
import Swal from "sweetalert2";

const MainSidebar = (props, sidebar) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [adminState, setAdminState] = useState("");
  const [permission, setPermission] = useState([]);
  const [listState, setListState] = useState(false);
  const [days, setDays] = useState("");
  const [sub, setSub] = useState();
  const [status, setStatus] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [CompanyDate, setCompanyDate] = useState("");
  const currentYear = new Date().getFullYear();
  const [companyImage, setCompanyImage] = useState("");
  const [active, setActive] = useState(0);
  const [loading, setLoading] = useState(false);
  const [timezone, setTimezone] = useState("");
  const [currentTime, setCurrentTime] = useState(
    moment().format("ddd, DD MMM YYYY HH:mm:ss A")
  );

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     // console.log("timezonearea", timezone);
  //     const time = timezone
  //       ? moment().tz(timezone).format("ddd, DD MMM YYYY hh:mm:ss A")
  //       : moment().format("ddd, DD MMM YYYY hh:mm:ss A");
  //     setCurrentTime(time);
  //     // setLoading(false);
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, [timezone]);

  useEffect(() => {
    const interval = setInterval(() => {
      const time = timezone
        ? moment.tz.zone(timezone)
          ? moment().tz(timezone).format("ddd, DD MMM YYYY HH:mm:ss A")
          : moment().format("ddd, DD MMM YYYY HH:mm:ss A")
        : moment().format("ddd, DD MMM YYYY HH:mm:ss A");
      setCurrentTime(time);
    }, 1000);

    return () => clearInterval(interval);
  }, [timezone]);

  useEffect(() => {
    console.log("prpsss", props);
    GetProfile();
    GetSubUserProfile();
    let subscribed = localStorage.getItem("subscribed");

    if (subscribed === "1") {
      setSub(1);
    } else {
      setSub(2);
    }
    let localAdmin = localStorage.getItem("admin");
    let localPerminssion = JSON.parse(localStorage.getItem("permission"));

    setAdminState(localAdmin);
    setPermission(localPerminssion);

    let plan = localStorage.getItem("planDate");

    if (plan === "null" || new Date(plan) > new Date()) {
      const date1 = new Date(plan);
      const date2 = new Date();

      const timeDifference = date1.getTime() - date2.getTime();
      const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      setDays(daysDifference);
    } else {
      setDays(0);
    }
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const GetSubscription = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(UpdateSubscriptionApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {})
      .catch((error) => console.log("error", error));
  };

  const GetProfile = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(getProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          // console.log("getprofile", result);
          setLoading(false);
          // setData(result?.data);
          setStatus(
            result?.data?.companyDetails?.subscriptionStatus === "canceled" ||
              result?.data?.companyDetails?.subscriptionStatus === "null" ||
              result?.data?.companyDetails?.subscriptionStatus === "expired"
          );
          setCompanyName(result?.data?.companyDetails?.companyName);
          setCompanyDate(result?.data?.companyDetails?.createdAt);
          // console.log("createdAt", result?.data?.companyDetails?.createdAt);
          const fetchedTimezone = result?.timezone;
          // console.log("Timezone fetched:", fetchedTimezone);
          setTimezone(fetchedTimezone);
          setCompanyImage(result?.data?.companyDetails?.image);
          localStorage.setItem(
            "substatus",
            result?.data?.companyDetails?.subscriptionStatus
          );
          localStorage.setItem(
            "planDate",
            result?.data?.companyDetails?.planDate
          );
          //   if (
          //     result?.comapany?.subscriptionStatus === "canceled" ||
          //     result?.comapany?.subscriptionStatus === null
          //   ) {
          //     navigate("/settings", { state: { activeState: 1 } });
          //   } else if (result?.comapany?.subscriptionStatus === "complete") {
          //     setSub(2);
          //   } else {
          //     setSub(1);
          //   }
          // }
          // else {
          //   console.log(result.message);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
        Swal.fire(error.message);
      });
  };

  const GetSubUserProfile = () => {
    let local = localStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetSubUserProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("subuser profile", result);
        if (result.status === true) {
          setCompanyName(result?.data?.companyDetails?.companyName);
          setCompanyDate(result?.data?.companyDetails?.createdAt);
          setCompanyImage(result?.data?.companyDetails?.image);
          setTimezone(result?.timezone);
          localStorage.setItem(
            "substatus",
            result?.data?.companyDetails?.subscriptionStatus
          );
          localStorage.setItem(
            "planDate",
            result?.data?.companyDetails?.planDate
          );
          // setDataUser(result?.data);
        } else {
          // console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const [isFreeTrialVisible, setFreeTrialVisible] = useState(true);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const toggleFreeTrial = () => {
    setFreeTrialVisible(!isFreeTrialVisible);
  };
  const sidebarStyle = {
    overflow: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    // marginTop: isFreeTrialVisible ? "122px" : "75px",
  };
  const [isFreeTrial, setIsFreeTrial] = useState(false);

  useEffect(() => {
    GetDemoDetails();
    const substatus = localStorage.getItem("substatus");
    const planDate = localStorage.getItem("planDate");

    if (
      substatus === "null" ||
      substatus === "canceled" ||
      substatus === "expired"
    ) {
      setIsFreeTrial(true);
    }
  }, []);

  useEffect(() => {
    if (isFreeTrial) {
      toggleSidebar(false);
      toggleFreeTrial(false);
    } else {
      toggleSidebar(true);
      toggleFreeTrial(true);
    }
  }, [isFreeTrial]);

  const hasPermission = (permissionList, permissionName) =>
    permissionList?.some((permission) => permission?.name === permissionName);

  const defaultList = [
    // {
    //   name: "Cash Register",
    //   path: "/cash-register",
    //   icon: "plus-sign.webp",
    // },
    { name: "Dashboard", path: "/dashboard", icon: "dashbaord-icon.webp" },
    {
      name: "Sales Dashboard",
      path: "/saledashboard",
      icon: "dashbaord-icon.webp",
    },
    { name: "Reports", path: "/reports", icon: "dashbaord-icon.webp" },
    { name: "Repairs", path: "/ticketlist", icon: "repair-icon.webp" },
    { name: "Products", path: "/productlist", icon: "product-icon.webp" },
    { name: "Devices", path: "/devicelist", icon: "devices-icon.webp" },
    { name: "Customers", path: "/customerList", icon: "customer-icon.webp" },
    {
      name: "Purchase Order",
      path: "/purchaseorderlist",
      icon: "purchaseorder-icon.webp",
    },
    { name: "Invoices", path: "/invoicelist", icon: "invoices-icon.webp" },
    { name: "Settings", path: "/settings", icon: "setting-icon.webp" },
  ];
  const matchedPermissions = defaultList.filter((item) =>
    permission.some((perm) => perm.name === item.name)
  );

  const renderDashboardDropdown = (permissionList) => {
    const dashboardList = ["Sales Dashboard", "Reports", "Dashboard"];

    // Filter the permissions to match those in the dashboardList
    const dashboardPermissions = permissionList
      ?.filter((permission) => dashboardList.includes(permission?.name))
      ?.sort(
        (a, b) =>
          dashboardList.indexOf(a?.name) - dashboardList.indexOf(b?.name) // Sort by the index in dashboardList
      );
    // const dashboardPermissions = permissionList?.filter(
    //   (permission) =>
    //     permission?.name === "Sales Dashboard" ||
    //     permission?.name === "Reports" ||
    //     permission?.name === "Dashboard"
    // );

    if (dashboardPermissions?.length === 0) {
      return null;
    }

    return (
      <li
        className={`dropdown nav_item ${active === 8 ? "active" : ""}`}
        onClick={() => setActive(8)}
      >
        <a
          onClick={toggleDropdown}
          className={`menu-toggle nav-link has-dropdown ${
            isDropdownOpen ? "toggled" : ""
          }`}
        >
          <img
            className="deflt_menu_icon"
            src="assets/img/dashbaord-icon.webp"
            alt=""
          />
          <img
            className="hover_menu_icon"
            src="assets/img/dashboard-icon-blue.svg"
            alt=""
          />
          <span>Dashboard</span>
          <i className="fa fa-angle-down"></i>
        </a>
        <ul className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}>
          {dashboardPermissions?.map((permission) => (
            <li key={permission?.id} onClick={() => setActive(8)}>
              {permission?.name === "Sales Dashboard" && (
                <NavLink className="nav-link" to="/saledashboard">
                  Sale Dashboard
                </NavLink>
              )}
              {permission?.name === "Reports" && (
                <NavLink className="nav-link" to="/reports">
                  Reports
                </NavLink>
              )}
              {permission?.name === "Dashboard" && (
                <NavLink className="nav-link" to="/dashboard">
                  Dashboard
                </NavLink>
              )}
            </li>
          ))}
        </ul>
      </li>
    );
  };

  const renderOtherPermissions = (permissionList) => {
    const otherPermissions = permissionList?.filter(
      (permission) =>
        permission?.name !== "Sales Dashboard" &&
        permission?.name !== "Reports" &&
        permission?.name !== "Dashboard"
    );

    return otherPermissions?.map((permission) => (
      <li key={permission?.id} className="nav_item">
        {permission?.name === "Repairs" && (
          <NavLink to="/ticketlist" className="nav-link">
            <img
              className="deflt_menu_icon"
              src="assets/img/repair-icon.webp"
              alt=""
            />
            <img
              className="hover_menu_icon"
              src="assets/img/repair-icon-blue.svg"
              alt=""
            />
            <span>Repair</span>
          </NavLink>
        )}
        {permission?.name === "Products" && (
          <NavLink to="/productlist" className="nav-link">
            <img
              className="deflt_menu_icon"
              src="assets/img/product-icon.webp"
              alt=""
            />
            <img
              className="hover_menu_icon"
              src="assets/img/product-icon-blue.svg"
              alt=""
            />
            <span>Products</span>
          </NavLink>
        )}
        {permission?.name === "Devices" && (
          <NavLink to="/devicelist" className="nav-link">
            <img
              className="deflt_menu_icon"
              src="assets/img/devices-icon.webp"
              alt=""
            />
            <img
              className="hover_menu_icon"
              src="assets/img/devices-icon-blue.svg"
              alt=""
            />
            <span>Devices Inventory</span>
          </NavLink>
        )}
        {permission?.name === "Customers" && (
          <NavLink to="/customerList" className="nav-link">
            <img
              className="deflt_menu_icon"
              src="assets/img/customer-icon.webp"
              alt=""
            />
            <img
              className="hover_menu_icon"
              src="assets/img/customer-icon-blue.svg"
              alt=""
            />
            <span>Customer</span>
          </NavLink>
        )}
        {permission?.name === "Purchase Order" && (
          <NavLink to="/purchaseorderlist" className="nav-link">
            <img
              className="deflt_menu_icon"
              src="assets/img/purchaseorder-icon.webp"
              alt=""
            />
            <img
              className="hover_menu_icon"
              src="assets/img/purchaseorder-icon-blue.svg"
              alt=""
            />
            <span>Purchase Order</span>
          </NavLink>
        )}
        {permission?.name === "Invoices" && (
          <NavLink to="/invoicelist" className="nav-link">
            <img
              className="deflt_menu_icon"
              src="assets/img/invoices-icon.webp"
              alt=""
            />
            <img
              className="hover_menu_icon"
              src="assets/img/invoices-icon-blue.svg"
              alt=""
            />
            <span>Invoices</span>
          </NavLink>
        )}
        {permission?.name === "Settings" && (
          <NavLink to="/settings" className="nav-link">
            <img
              className="deflt_menu_icon"
              src="assets/img/setting-icon.webp"
              alt=""
            />
            <img
              className="hover_menu_icon"
              src="assets/img/setting-icon-blue.svg"
              alt=""
            />
            <span>Settings</span>
          </NavLink>
        )}
      </li>
    ));
  };

  const [showDemoStore, setShowDemoStore] = useState(false);
  const [dataDemo, setDataDemo] = useState();
  const [demoStoreData, setDemoStoreData] = useState();

  const GetDemoDetails = () => {
    let token = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetDemoStoreDetailsApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result?.status && result?.data) {
          setShowDemoStore(false);
          setDemoStoreData(result?.data);
          setDataDemo(result?.data?.demoStore);
        }
        // else if(result?.status && result?.data&&result?.data?.demoStore==="1"){

        // }
        else {
          setShowDemoStore(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div className="main-sidebar" style={sidebarStyle}>
        {loading ? (
          <div className="landing_loader">
            <div className="innerloader">
              <img src="img/spin-1s-156px.gif" alt="" />
            </div>
          </div>
        ) : null}
        <aside id="sidebar-wrapper">
          <div className="store_info">
            <div className="store_icon">
              {loading ? (
                <h6>Loading...</h6>
              ) : (
                <img
                  src={
                    props?.image
                      ? props.image
                      : companyImage
                      ? companyImage
                      : "assets/img/store-img.webp"
                  }
                  className="companyimgcustmcss"
                  alt="Company Logo"
                />
              )}
            </div>
            <div className="store_name storenamecustmcss">
              {/* <h4>{loading ? "Loading..." : CompanyName}</h4> */}
              <h4>
                {loading
                  ? "Loading..."
                  : props.companyname
                  ? props.companyname
                  : CompanyName}
              </h4>

              <span>{loading ? "Loading date..." : currentTime}</span>
            </div>
          </div>

          <div className="sidebar-line"></div>
          {new Date() > new Date(localStorage.getItem("planDate")) &&
          (localStorage.getItem("substatus") === "canceled" ||
            localStorage.getItem("substatus") === "null" ||
            localStorage.getItem("substatus") === "expired") ? null : (
            <>
              {adminState === 1 || permission?.length === 0 ? (
                <div className="cash_register_btn">
                  <NavLink to="/cash-register">
                    <img src="assets/img/plus-sign.webp" alt="" />{" "}
                    <span>Cash Register</span>
                  </NavLink>
                </div>
              ) : (
                permission?.map((permission) => (
                  <>
                    {permission?.name === "Cash Register" && (
                      <div className="cash_register_btn">
                        <NavLink to="/cash-register">
                          <img src="assets/img/plus-sign.webp" alt="" />{" "}
                          <span>Cash Register</span>
                        </NavLink>
                      </div>
                    )}
                  </>
                ))
              )}
              <ul
                className="sidebar-menu"
                style={
                  dataDemo === "1" || demoStoreData?.trainingMode === 1
                    ? { pointerEvents: "none", opacity: 0.5 }
                    : {}
                }
              >
                {adminState === 1 || permission?.length === 0 ? (
                  <>
                    <li
                      // className="dropdown nav_item"
                      className={`dropdown nav_item ${
                        active === 8 ? "active" : ""
                      }`}
                      onClick={() => setActive(8)}
                    >
                      <a
                        onClick={toggleDropdown}
                        className={`menu-toggle nav-link has-dropdown ${
                          isDropdownOpen ? "toggled" : ""
                        }`}
                      >
                        <img
                          className="deflt_menu_icon"
                          src="assets/img/dashbaord-icon.webp"
                          alt=""
                        />
                        <img
                          className="hover_menu_icon"
                          src="assets/img/dashboard-icon-blue.svg"
                          alt=""
                        />
                        <span>Dashboard</span>
                        <i className="fa fa-angle-down"></i>
                      </a>
                      <ul
                        className={`dropdown-menu ${
                          isDropdownOpen ? "show" : ""
                        }`}
                      >
                        <li onClick={() => setActive(8)}>
                          <NavLink className="nav-link" to="/saledashboard">
                            Sale Dashboard
                          </NavLink>
                        </li>
                        <li onClick={() => setActive(8)}>
                          <NavLink className="nav-link" to="/reports">
                            Reports
                          </NavLink>
                        </li>
                        <li onClick={() => setActive(8)}>
                          <NavLink className="nav-link" to="/dashboard">
                            Dashboard
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li
                      className={`nav_item ${active === 1 ? "active" : ""}`}
                      onClick={() => setActive(1)}
                    >
                      <NavLink to="/ticketlist" className="nav-link">
                        <img
                          className="deflt_menu_icon"
                          src="assets/img/repair-icon.webp"
                          alt=""
                        />
                        <img
                          className="hover_menu_icon"
                          src="assets/img/repair-icon-blue.svg"
                          alt=""
                        />
                        <span>Repair</span>
                      </NavLink>
                    </li>

                    <li
                      className={`nav_item ${active === 2 ? "active" : ""}`}
                      onClick={() => setActive(2)}
                    >
                      <NavLink to="/productlist" className="nav-link">
                        <img
                          className="deflt_menu_icon"
                          src="assets/img/product-icon.webp"
                          alt=""
                        />
                        <img
                          className="hover_menu_icon"
                          src="assets/img/product-icon-blue.svg"
                          alt=""
                        />
                        <span>Products</span>
                      </NavLink>
                    </li>
                    <li
                      className={`nav_item ${active === 3 ? "active" : ""}`}
                      onClick={() => setActive(3)}
                    >
                      <NavLink to="/devicelist" className="nav-link">
                        <img
                          className="deflt_menu_icon"
                          src="assets/img/devices-icon.webp"
                          alt=""
                        />
                        <img
                          className="hover_menu_icon"
                          src="assets/img/devices-icon-blue.svg"
                          alt=""
                        />
                        <span>Devices Inventory</span>
                      </NavLink>
                    </li>
                    <li
                      className={`nav_item ${active === 4 ? "active" : ""}`}
                      onClick={() => setActive(4)}
                    >
                      <NavLink to="/customerList" className="nav-link">
                        <img
                          className="deflt_menu_icon"
                          src="assets/img/customer-icon.webp"
                          alt=""
                        />
                        <img
                          className="hover_menu_icon"
                          src="assets/img/customer-icon-blue.svg"
                          alt=""
                        />
                        <span>Customer</span>
                      </NavLink>
                    </li>
                    <li
                      className={`nav_item ${active === 5 ? "active" : ""}`}
                      onClick={() => setActive(5)}
                    >
                      <NavLink to="/purchaseorderlist" className="nav-link">
                        <img
                          className="deflt_menu_icon"
                          src="assets/img/purchaseorder-icon.webp"
                          alt=""
                        />
                        <img
                          className="hover_menu_icon"
                          src="assets/img/purchaseorder-icon-blue.svg"
                          alt=""
                        />
                        <span>Purchase Order</span>
                      </NavLink>
                    </li>
                    <li
                      className={`nav_item ${active === 6 ? "active" : ""}`}
                      onClick={() => setActive(6)}
                    >
                      <NavLink to="/invoicelist" className="nav-link">
                        <img
                          className="deflt_menu_icon"
                          src="assets/img/invoices-icon.webp"
                          alt=""
                        />
                        <img
                          className="hover_menu_icon"
                          src="assets/img/invoices-icon-blue.svg"
                          alt=""
                        />
                        <span>Invoices</span>
                      </NavLink>
                    </li>

                    {/* <li
                      className={`nav_item ${active === 9 ? "active" : ""}`}
                      onClick={() => setActive(9)}
                    >
                      <NavLink to="/notes" className="nav-link">
                        <img
                          className="deflt_menu_icon"
                          src="assets/img/invoices-icon.webp"
                          alt=""
                        />
                        <img
                          className="hover_menu_icon"
                          src="assets/img/invoices-icon-blue.svg"
                          alt=""
                        />
                        <span>Notes</span>
                      </NavLink>
                    </li> */}

                    <li
                      className={`nav_item ${active === 7 ? "active" : ""}`}
                      onClick={() => setActive(7)}
                    >
                      <NavLink to="/settings" className="nav-link">
                        <img
                          className="deflt_menu_icon"
                          src="assets/img/setting-icon.webp"
                          alt=""
                        />
                        <img
                          className="hover_menu_icon"
                          src="assets/img/setting-icon-blue.svg"
                          alt=""
                        />
                        <span>Settings</span>
                      </NavLink>
                    </li>
                  </>
                ) : (
                  <>
                    {" "}
                    {renderDashboardDropdown(matchedPermissions)}
                    {renderOtherPermissions(matchedPermissions)}
                  </>
                )}
              </ul>{" "}
            </>
          )}

          {(localStorage.getItem("substatus") === "canceled" ||
            localStorage.getItem("substatus") === "null" ||
            localStorage.getItem("substatus") === "expired") &&
          new Date(localStorage.getItem("planDate")) > new Date() ? (
            <>
              <div className="updgrade_plan">
                <h4>Upgrade Plan</h4>
                <p>You have {days} days left of your trial!</p>

                <Link to="/settings" state={{ activeState: 1 }}>
                  Upgrade Now!
                </Link>
              </div>
            </>
          ) : null}

          <div className="copyright_p">
            <p>
              <a href="#">ToolboxPos</a>{" "}
              <span>© {currentYear} All Rights Reserved</span>
            </p>
          </div>
        </aside>
      </div>
    </>
  );
};
export default MainSidebar;
