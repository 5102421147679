import React, { useEffect, useState, useRef } from "react";
import $ from "jquery";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Footer from "../Footer";
import SideUl from "../MainDashboard/SideUl";
import MainSidebar from "../MainDashboard/MainSidebar";
import {
  AddBrandApi,
  AddItemApi,
  AddPurchaseOrderApi,
  AddShipToApi,
  AddSupplierApi,
  AddTagsApi,
  GetAllDiscountApi,
  GetAllShipApi,
  GetAllTaxApi,
  GetMessagesApi,
  GetTermsConditionPurchaseorderApi,
  SendEmailPurchaseApi,
  baseurl,
  customerEditApi,
  uploadImageApi,
} from "../Utility/Api";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ProductModalTwo from "./ProductModelTwo";
import FreeTrail from "./FreeTrail";
import BannerMessage from "./BannerMessage";

const PurchaseOrder = () => {
  const [loading, setLoading] = useState(false);
  const [showList, setShowList] = useState(false);
  const [showListShip, setShowListShip] = useState(false);
  const [typedText, setTypedText] = useState("");
  const [selectedShipTo, setSelectedShipTo] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedShipId, setSelectedShipId] = useState("");
  const [email, setEmail] = useState("");
  const [mailingAddress, setMailingAddress] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [AllShip, setAllShip] = useState([]);
  const [trackingNo, setTrackingNo] = useState("");
  const [value, onChange] = useState(new Date());
  const [value1, onChange1] = useState(new Date());
  const [AllSupplier, setAllSupplier] = useState([]);
  const [selectedSuppiler, setSelectedSuppiler] = useState(null);
  const [showListSuppiler, setShowListSuppiler] = useState(false);
  const [selectedSupplierId, setSelectedSuppierId] = useState(null);
  const [searchTermSuppiler, setsearchTermSuppiler] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState({});
  const [allProduct, setAllProducts] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [searchTermProduct, setSearchTermProduct] = useState("");
  const [showModal1, setShowModal1] = useState(false);
  const [matched, setMatched] = useState([]);
  const [itemState, setItemState] = useState(false);
  const [shippingCost, setShippingCost] = useState(0);
  const [grandTotal, setGrandTotal] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [editedProductIndex, setEditedProductIndex] = useState(null);
  const [termscondition, setTermsCondition] = useState("");
  const [productshowModal, setProductShowModal] = useState(false);

  const [unitCosts, setUnitCosts] = useState(selectedProduct.map(() => 0));
  const [receivedQuantities, setReceivedQuantities] = useState(
    selectedProduct.map(() => 0)
  );
  const [purchaseQuantities, setPurchaseQuantities] = useState(
    selectedProduct.map(() => 0)
  );

  const inputRef = useRef(null);
  const inputRef1 = useRef(null);
  const dropdownRef = useRef(null);
  const dropdownRef1 = useRef(null);
  const [ids, setIds] = useState();
  const [productCategoryIds, setProductCategoryIds] = useState();

  const [imeiList, setImeiList] = useState([]);

  const [imeiLists, setImeiLists] = useState({});
  const [quantities, setQuantities] = useState({});
  const [purCquantities, setPurCQuantities] = useState({});
  const [messageData, setMessageData] = useState();

  const handleSerialNumberChange = (productId, e) => {
    const { value } = e.target;
    setImeiLists((prevImeiLists) => ({
      ...prevImeiLists,
      [productId]: value.trim(),
    }));
  };

  const handleKeyPress = (productId, e) => {
    if (e.key === "Enter") {
      const imei = imeiLists[productId];
      if (!imei || imei.trim() === "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please enter a valid IMEI Number",
        });
        return;
      }

      // Update imeiList for the specific product
      const newImeiList = [...(imeiList[productId] || []), imei.trim()];
      setImeiList((prevImeiList) => ({
        ...prevImeiList,
        [productId]: newImeiList,
      }));

      // Increase the quantity for the specific product
      setQuantities((prevQuantities) => ({
        ...prevQuantities,
        [productId]: (prevQuantities[productId] || 0) + 1,
      }));

      setPurCQuantities((prevCQuantities) => ({
        ...prevCQuantities,
        [productId]: (prevCQuantities[productId] || 0) + 1,
      }));

      // Reset the input field
      setImeiLists((prevImeiLists) => ({
        ...prevImeiLists,
        [productId]: "",
      }));
    }
  };

  // const handleKeyPress = (productId, e) => {
  //   if (e.key === "Enter") {
  //     const imei = imeiLists[productId];
  //     if (!imei || imei.trim() === "") {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops...",
  //         text: "Please enter a valid IMEI Number",
  //       });
  //       return;
  //     }

  //     // Reset the input field
  //     setImeiLists((prevImeiLists) => ({
  //       ...prevImeiLists,
  //       [productId]: "",
  //     }));

  //     // Update imeiList for the specific product
  //     const newImeiList = [imei, ...(imeiList[productId] || [])];
  //     setImeiList((prevImeiList) => ({
  //       ...prevImeiList,
  //       [productId]: newImeiList,
  //     }));

  //     // Increase the quantity for the specific product
  //     setQuantities((prevQuantities) => ({
  //       ...prevQuantities,
  //       [productId]: (prevQuantities[productId] || 0) + 1,
  //     }));
  //     setPurCQuantities((prevCQuantities) => ({
  //       ...prevCQuantities,
  //       [productId]: (prevCQuantities[productId] || 0) + 1,
  //     }));
  //     // setPurchaseQuantities((prevPurchaseQuantities) => ({
  //     //   ...prevPurchaseQuantities,
  //     //   [productId]: (prevPurchaseQuantities[productId] || 0) + 1,
  //     // }));
  //   }
  // };
  const handleRemoveImei = (e, productId, imeiIndex) => {
    e.preventDefault();

    // Update imeiList for the specific product
    const updatedImeiList = [...imeiList[productId]];
    updatedImeiList.splice(imeiIndex, 1);
    setImeiList((prevImeiList) => ({
      ...prevImeiList,
      [productId]: updatedImeiList,
    }));

    // Update quantity for the specific product
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: (prevQuantities[productId] || 0) - 1,
    }));

    // Update receivedQuantities if needed
    setReceivedQuantities((prevReceivedQuantities) =>
      prevReceivedQuantities.map((qty, index) =>
        index === productId ? qty - 1 : qty
      )
    );
  };

  const handleEdit = (index) => {
    setEditedProductIndex(index);
    setUnitCosts((prevUnitCosts) => {
      const newUnitCosts = [...prevUnitCosts];
      //newUnitCosts[index] = selectedProduct[index]?.unitCost || 0;
      return newUnitCosts;
    });
    setReceivedQuantities((prevReceivedQuantities) => {
      const newReceivedQuantities = [...prevReceivedQuantities];
      // newReceivedQuantities[index] =
      //   selectedProduct[index]?.receivedQuantity || 0;
      return newReceivedQuantities;
    });
    setPurchaseQuantities((prevPurchaseQuantities) => {
      const newPurchaseQuantities = [...prevPurchaseQuantities];
      // newPurchaseQuantities[index] =
      //   selectedProduct[index]?.purchaseQuantity || 0;
      return newPurchaseQuantities;
    });

    setProductCategoryIds(selectedProduct[index]?.productCategoryId);
    setIds(selectedProduct[index]?.id);
    setShowModal(true);
  };

  const handleUpdate = () => {
    if (editedProductIndex !== null) {
      setSelectedProduct((prevProducts) => {
        const updatedProducts = [...prevProducts];
        updatedProducts[editedProductIndex] = {
          ...updatedProducts[editedProductIndex],
          unitCost: unitCosts[editedProductIndex],
          receivedQuantity:
            receivedQuantities[editedProductIndex] ||
            quantities[editedProductIndex],
          purchaseQuantity: purchaseQuantities[editedProductIndex],
        };
        return updatedProducts;
      });
      setEditedProductIndex(null);
      setShowModal(false);
    }
  };
  const TotalP = receivedQuantities * unitCosts;

  const calculateOverallTotal = () => {
    let overallTotal = 0;
    selectedProduct.forEach((_, index) => {
      overallTotal += calculateTotalForProduct(index);
    });
    return overallTotal;
  };

  const calculateTotalForProduct = (index) => {
    // return (
    //   unitCosts[index] * purchaseQuantities[index] ||
    //   unitCosts[index] * purCquantities[ids] ||
    //   0
    // );
    return (
      unitCosts[index] * (purchaseQuantities[index] || purCquantities[ids] || 0)
    );
  };
  const overallTotal = calculateOverallTotal();

  const navigate = useNavigate();

  const handleSearchChangeSupplier = (e) => {
    setsearchTermSuppiler(e.target.value);
    setTypedText(e.target.value);
    setSelectedSuppiler(null);
  };

  const handleAddNewClickSupplier = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Supplier name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    // e.preventDefault();
    setSelectedSuppiler(typedText);
    setShowListSuppiler(false);
    setsearchTermSuppiler("");
    setTypedText("");
    AddSuppier();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowListShip(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef1.current &&
        !dropdownRef1.current.contains(event.target)
      ) {
        setShowListSuppiler(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef1]);

  const GetTermsConditions = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetTermsConditionPurchaseorderApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setTermsCondition(result?.data?.termCondition);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const Validation = () => {
    let error = {};
    if (!selectedSuppiler) {
      error.selectedSuppiler = "Supplier Name is Required";
    }
    if (Object.keys(error).length === 0) {
      // console.log(Object.keys(error).length);
      AddPurchaseOrder();
    }
    return error;
  };

  const Validation1 = () => {
    let error = {};
    if (!selectedSuppiler) {
      error.selectedSuppiler = "Supplier Name is Required";
    }
    if (Object.keys(error).length === 0) {
      // console.log(Object.keys(error).length);
      AddPurchaseOrder1();
    }
    return error;
  };

  const Validation2 = () => {
    let error = {};
    if (!selectedSuppiler) {
      error.selectedSuppiler = "Supplier Name is Required";
    }
    if (Object.keys(error).length === 0) {
      // console.log(Object.keys(error).length);
      AddPurchaseOrder2();
    }
    return error;
  };

  const OnSubmit = () => {
    setError(Validation());
  };
  const OnSubmit1 = () => {
    setError(Validation1());
  };

  const OnSubmit2 = () => {
    setError(Validation2());
  };
  const ShipTo = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    var raw = JSON.stringify({
      name: typedText,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddShipToApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        GetAllShip();
        setSelectedShipId(result?.data?.id);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllShip = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllShipApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllShip(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllSupplier = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllSuppiler?productCategoryId=1`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllSupplier(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const AddSuppier = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    var raw = JSON.stringify({
      name: typedText,
      productCategoryId: 1,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddSupplierApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllSupplier();
          setSelectedSuppierId(result?.data?.id);
          setsearchTermSuppiler("");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleButtonClick = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowList(!showList);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setTypedText(e.target.value);
    setSelectedShipTo(null);
  };

  const handleBrandSelect = (brand) => {
    setSelectedShipTo(brand.name);
    setSelectedShipId(brand.id);
    setShowListShip(false);
  };

  const filteredBrands = AllShip.filter((brand) => {
    const name = brand.name;
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTerm.toLowerCase());
    }

    return false;
  });

  const filteredSuppiler = AllSupplier.filter((brand) => {
    const name = brand.name;

    // Check if 'name' is not null before calling toLowerCase
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermSuppiler.toLowerCase());
    }

    return false;
  });

  const handleAddNewClick = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Ship name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    // e.preventDefault();
    setSelectedShipTo(typedText);
    setShowListShip(false);
    setSearchTerm("");
    setTypedText("");
    ShipTo();
  };

  const HandleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete data",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c76cff",
      cancelButtonColor: "#efefef",
      confirmButtonText: "Yes, delete it!",
      confirmButtonText: "<span style='color: white;'>Yes, delete it!</span>",
      cancelButtonText: "<span style='color: black;'>cancel!</span>",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "sweet-alert2-container-zindex",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
          confirmButtonColor: "#c76cff",
          customClass: {
            container: "sweet-alert2-container-zindex",
          },
        }).then(() => {
          //DeletePurchaseOrder(id);
        });
      }
    });
  };

  const AddPurchaseOrder2 = () => {
    let local = localStorage.getItem("token");
    let Price = parseFloat(overallTotal || 0); // Initialize Price with overallTotal or 0 if overallTotal is null

    // Add shippingCost if it's a valid number
    if (!isNaN(parseFloat(shippingCost))) {
      Price += parseFloat(shippingCost);
    }

    // Add discountedTotal if it's a valid number
    if (!isNaN(parseFloat(discountedTotal))) {
      Price -= parseFloat(discountedTotal);
    }

    // Add totalWithTax if it's a valid number
    if (!isNaN(parseFloat(totalWithTax))) {
      Price += parseFloat(totalWithTax);
    }
    // let Price = parseFloat(shippingCost) + parseFloat(overallTotal);

    var myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    const itemsData = selectedProduct.map((item, index) => {
      const productId = item.id || ids; // Assuming itemId is used as productId
      const purchaseQuantity = Number(
        purchaseQuantities[selectedProduct.indexOf(item)] || 0
      );
      const receivedQuantity = Number(
        receivedQuantities[selectedProduct.indexOf(item)] || 0
      );
      // const purchaseQuantity = Number(purchaseQuantities[productId] || 0);
      // const receivedQuantity = Number(receivedQuantities[productId] || 0);
      const pQuantities = Number(purCquantities[productId] || 0);
      const receivedQuantitiess = Number(quantities[productId] || 0);
      const po = purchaseQuantity - receivedQuantity;
      const po1 = pQuantities - receivedQuantitiess;
      const unitCost = Number(unitCosts[selectedProduct.indexOf(item)] || 0);

      let imeiNumberArr = [];
      if (item.productCategoryId === 2) {
        imeiNumberArr = imeiList[productId] || [];
      }

      return {
        inventory: receivedQuantity || receivedQuantitiess,
        po: po1 ? po1 : po,
        // po: po >= 0 ? po : 0,
        unitCost: unitCost,
        itemId: productId,
        imeiNumberArr: imeiNumberArr,
        discount: discountedTotal,
        tax: totalWithTax,
      };
    });

    // const itemsData = selectedProduct.map((item, index) => {
    //   const purchaseQuantity = Number(purchaseQuantities[index]);
    //   const receivedQuantity = Number(receivedQuantities[index]);
    //   const po = purchaseQuantity - receivedQuantity;
    //   const unitCost = Number(unitCosts[index]);

    //   return {
    //     inventory: receivedQuantity,
    //     po: po >= 0 ? po : 0,
    //     unitCost: unitCost,
    //     itemId: item.id,
    //     imeiNumberArr: [],
    //     discount: discountedTotal,
    //     tax: totalWithTax,
    //   };
    // });
    // console.log("pooo", itemsData);
    // const itemsData = selectedProduct.map((item) => {
    //   return {
    //     inventory: Number(receivedQuantities[selectedProduct.indexOf(item)]),
    //     po: Number(receivedQuantities[selectedProduct.indexOf(item)]),
    //     unitCost: Number(unitCosts[selectedProduct.indexOf(item)]),
    //     itemId: item.id,
    //     imeiNumberArr: [],
    //   };
    // });
    var raw = JSON.stringify({
      itemIds: selectedProduct.map((item) => item.id),
      items: itemsData,
      supplierId: selectedSupplierId,
      email: email,
      shipToId: selectedShipId ? selectedShipId : 0,
      trackingNumber: trackingNo,
      shippingAddress: shippingAddress,
      mailingAddress: mailingAddress,
      deliveryDate: value1,
      description: description,
      totalCost: Price,
      activeInactive: 1,
      shippingCost: shippingCost ? Number(shippingCost) : 0,
      puchaseOrderDate: value,
      termCondition: termscondition,
      discountAmount: discount,
      taxAmount: tax,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // console.log("purchase order raw", raw);
    fetch(AddPurchaseOrderApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("purchase result", result);
        if (result?.status === true) {
          // navigate("/purchaseorderlist");
          PurchaseOrderEmail(result?.data?.id);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const AddPurchaseOrder1 = () => {
    let local = localStorage.getItem("token");
    let Price = parseFloat(overallTotal || 0); // Initialize Price with overallTotal or 0 if overallTotal is null

    // Add shippingCost if it's a valid number
    if (!isNaN(parseFloat(shippingCost))) {
      Price += parseFloat(shippingCost);
    }

    // Add discountedTotal if it's a valid number
    if (!isNaN(parseFloat(discountedTotal))) {
      Price -= parseFloat(discountedTotal);
    }

    // Add totalWithTax if it's a valid number
    if (!isNaN(parseFloat(totalWithTax))) {
      Price += parseFloat(totalWithTax);
    }

    // let Price =
    //   parseFloat(shippingCost) +
    //   parseFloat(overallTotal) +
    //   parseFloat(discountedTotal) +
    //   parseFloat(totalWithTax);

    var myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    const itemsData = selectedProduct.map((item, index) => {
      const productId = item.id || ids; // Assuming itemId is used as productId
      const purchaseQuantity = Number(
        purchaseQuantities[selectedProduct.indexOf(item)] || 0
      );
      const receivedQuantity = Number(
        receivedQuantities[selectedProduct.indexOf(item)] || 0
      );
      // const purchaseQuantity = Number(purchaseQuantities[productId] || 0);
      // const receivedQuantity = Number(receivedQuantities[productId] || 0);
      const pQuantities = Number(purCquantities[productId] || 0);
      const receivedQuantitiess = Number(quantities[productId] || 0);
      const po = purchaseQuantity - receivedQuantity;
      const po1 = pQuantities - receivedQuantitiess;
      const unitCost = Number(unitCosts[selectedProduct.indexOf(item)] || 0);

      let imeiNumberArr = [];
      if (item.productCategoryId === 2) {
        imeiNumberArr = imeiList[productId] || [];
      }

      return {
        inventory: receivedQuantity || receivedQuantitiess,
        po: po1 ? po1 : po,
        // po: po >= 0 ? po : 0,
        unitCost: unitCost,
        itemId: productId,
        imeiNumberArr: imeiNumberArr,
        discount: discountedTotal, // Assuming discountedTotal and totalWithTax are defined
        tax: totalWithTax,
      };
    });

    // const itemsData = selectedProduct.map((item, index) => {
    //   const purchaseQuantity = Number(
    //     purchaseQuantities[selectedProduct.indexOf(item)]
    //   );

    //   const receivedQuantity = Number(
    //     receivedQuantities[selectedProduct.indexOf(item)]
    //   );
    //   const pQuantities = Number(purCquantities[item.id]);
    //   const receivedQuantitiess = Number(quantities[item.id]);
    //   const po = purchaseQuantity - receivedQuantity;
    //   const po1 = pQuantities - receivedQuantitiess;
    //   const unitCost = Number(unitCosts[selectedProduct.indexOf(item)]);

    //   return {
    //     inventory: receivedQuantity || receivedQuantitiess,
    //     po: po || po1 >= 0 ? po || po1 : 0,
    //     unitCost: unitCost,
    //     itemId: item.id || ids,
    //     imeiNumberArr: item.productCategoryId === 2 ? imeiList : null,
    //     discount: discountedTotal,
    //     tax: totalWithTax,
    //   };
    // });
    // console.log("pooo", itemsData);
    // const itemsData = selectedProduct.map((item) => {
    //   return {
    //     inventory: Number(receivedQuantities[selectedProduct.indexOf(item)]),
    //     po: Number(receivedQuantities[selectedProduct.indexOf(item)]),
    //     unitCost: Number(unitCosts[selectedProduct.indexOf(item)]),
    //     itemId: item.id,
    //     imeiNumberArr: [],
    //   };
    // });
    var raw = JSON.stringify({
      itemIds: selectedProduct.map((item) => item.id),
      items: itemsData,
      supplierId: selectedSupplierId,
      email: email,
      shipToId: selectedShipId ? selectedShipId : 0,
      trackingNumber: trackingNo,
      shippingAddress: shippingAddress,
      mailingAddress: mailingAddress,
      deliveryDate: value1,
      description: description,
      totalCost: Price,
      activeInactive: 0,
      shippingCost: shippingCost ? Number(shippingCost) : 0,
      puchaseOrderDate: value,
      termCondition: termscondition,
      discountAmount: discount,
      taxAmount: tax,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // console.log("purchase order raw", raw);

    fetch(AddPurchaseOrderApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("purchase result", result);
        if (result?.status === true) {
          navigate("/purchaseorderlist");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const AddPurchaseOrder = () => {
    let local = localStorage.getItem("token");

    let Price = parseFloat(overallTotal || 0); // Initialize Price with overallTotal or 0 if overallTotal is null

    // Add shippingCost if it's a valid number
    if (!isNaN(parseFloat(shippingCost))) {
      Price += parseFloat(shippingCost);
    }

    // Add discountedTotal if it's a valid number
    if (!isNaN(parseFloat(discountedTotal))) {
      Price -= parseFloat(discountedTotal);
    }

    // Add totalWithTax if it's a valid number
    if (!isNaN(parseFloat(totalWithTax))) {
      Price += parseFloat(totalWithTax);
    }
    var myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    const itemsData = selectedProduct.map((item, index) => {
      const productId = item.id || ids; // Assuming itemId is used as productId
      const purchaseQuantity = Number(
        purchaseQuantities[selectedProduct.indexOf(item)] || 0
      );
      const receivedQuantity = Number(
        receivedQuantities[selectedProduct.indexOf(item)] || 0
      );
      const pQuantities = Number(purCquantities[productId] || 0);
      const receivedQuantitiess = Number(quantities[productId] || 0);
      const po = purchaseQuantity - receivedQuantity;
      const po1 = pQuantities - receivedQuantitiess;
      const unitCost = Number(unitCosts[selectedProduct.indexOf(item)] || 0);

      let imeiNumberArr = [];
      if (item.productCategoryId === 2) {
        imeiNumberArr = imeiList[productId] || [];
      }

      return {
        inventory: receivedQuantity || receivedQuantitiess,
        po: po1 ? po1 : po,
        unitCost: unitCost,
        itemId: productId,
        imeiNumberArr: imeiNumberArr,
        discount: discountedTotal, // Assuming discountedTotal and totalWithTax are defined
        tax: totalWithTax,
      };
    });

    // const itemsData = selectedProduct.map((item) => {
    //   return {
    //     inventory: Number(receivedQuantities[selectedProduct.indexOf(item)]),
    //     po: Number(receivedQuantities[selectedProduct.indexOf(item)]),
    //     unitCost: Number(unitCosts[selectedProduct.indexOf(item)]),
    //     itemId: item.id,
    //     imeiNumberArr: [],
    //   };
    // });
    var raw = JSON.stringify({
      itemIds: selectedProduct.map((item) => item.id),
      items: itemsData,
      supplierId: selectedSupplierId,
      email: email,
      shipToId: selectedShipId ? selectedShipId : 0,
      trackingNumber: trackingNo,
      shippingAddress: shippingAddress,
      mailingAddress: mailingAddress,
      deliveryDate: value1,
      description: description,
      totalCost: Price,
      activeInactive: 1,
      shippingCost: shippingCost ? Number(shippingCost) : 0,
      puchaseOrderDate: value,
      termCondition: termscondition,
      discountAmount: discount,
      taxAmount: tax,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    // console.log("add purchase order", raw);

    fetch(AddPurchaseOrderApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("purchase result", result);
        if (result?.status === true) {
          navigate("/purchaseorderlist");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleSupplierSelect = (brand) => {
    setSelectedSuppiler(brand.name);
    setSelectedSuppierId(brand.id);
    setShowListSuppiler(false);
    setError("");
  };

  useEffect(() => {
    GetMessages();
    GetAllShip();
    GetAllSupplier();
    GetAllProducts();
    GetTermsConditions();
    GetAllDiscount();
    GetAllTax();
  }, []);

  const customStyles = {
    placeholder: {
      color: "#6b6b6b",
    },
    value1: {
      color: "#6b6b6b",
    },
    calendarIcon: {
      cursor: "pointer",
    },
  };

  const GetAllProducts = () => {
    let local = localStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AJ0pT5xJJsG8rHqot1A6txMupaTPvbUM2.LHRBUtee7s0DQmEebl5p1hig5dbcABIB0m4VWSzru%2Fo"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllItems`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setAllProducts(result?.data);
          setSelectedCustomer(result?.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const filteredData = allProduct?.filter((item) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return item?.title.toLowerCase().includes(lowerCaseSearchTerm);
  });
  const handleCustomerSelection = (customer) => {
    const existingProductIndex = selectedProduct.findIndex(
      (product) => product.id === customer.id
    );

    if (existingProductIndex === -1) {
      setSelectedCustomer(customer);
      setSelectedProduct((prevSelectedProducts) => [
        ...prevSelectedProducts,
        customer,
      ]);

      setShowModal1(false);
    } else {
      //console.log("Product already exists in the list.");
    }
  };
  // const handleCustomerSelection = (customer) => {
  //   setSearchTermProduct(`${customer.title}`);
  //   setSelectedCustomer(customer);
  //   setSelectedProduct((prevSelectedProducts) => [
  //     ...prevSelectedProducts,
  //     customer,
  //   ]);

  //   setShowModal1(false);
  // };

  const handleSearchTermChange = (event) => {
    const term = event.target.value;
    setSearchTermProduct(term);
    const lowerCaseSearchTerm = term.toLowerCase();
    setShowModal1(true);

    const matchedCustomer = allProduct.find((customer) =>
      customer.title.toLowerCase().includes(lowerCaseSearchTerm)
    );
    setMatched(matchedCustomer);
    setSelectedCustomer(matchedCustomer);
  };

  //const PO = purchaseQuantity - receivedQuantity;

  const handleDelete = (index) => {
    setSelectedProduct((prevProducts) => {
      const updatedProducts = [...prevProducts];
      updatedProducts.splice(index, 1);
      return updatedProducts;
    });
  };
  const PurchaseOrderEmail = (id) => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      email: email,
      purchaseOrderId: id,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(SendEmailPurchaseApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          navigate("/purchaseorderlist");
          Swal.fire("Email Send Successfully!");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [alldiscount, setAllDiscount] = useState([]);
  const [alltax, setAllTax] = useState([]);

  const GetAllDiscount = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllDiscountApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllDiscount(result?.data?.reverse());
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetAllTax = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllTaxApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllTax(result?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const [discount, setDiscount] = useState(0);
  const [tax, setTax] = useState(0);

  const handleDiscountChange = (event) => {
    const selectedDiscount = parseFloat(event.target.value, 10);
    setDiscount(selectedDiscount);
  };

  const handleTaxChange = (event) => {
    const selectedTax = parseFloat(event.target.value, 10);
    setTax(selectedTax);
  };

  const discountedTotal = overallTotal * (discount / 100);

  const totalWithTax = overallTotal * (tax / 100);

  const totalIncludingShipping = totalWithTax + shippingCost;
  function calculateTotal(
    shippingCost,
    overallTotal,
    totalWithTax,
    discountedTotal
  ) {
    const shipping = parseFloat(shippingCost) || 0;
    const overall = parseFloat(overallTotal) || 0;
    const tax = parseFloat(totalWithTax) || 0;
    const discount = parseFloat(discountedTotal) || 0;

    return `$${(shipping + overall + tax - discount).toFixed(2)}`;
  }
  const onpress = () => {
    setProductShowModal(false);
  };

  const [isFreeTrial, setIsFreeTrial] = useState(false);

  useEffect(() => {
    const substatus = localStorage.getItem("substatus");
    const planDate = localStorage.getItem("planDate");

    if (
      (substatus === "null" ||
        substatus === "canceled" ||
        substatus === "expired") &&
      new Date(localStorage.getItem("planDate")) > new Date()
    ) {
      setIsFreeTrial(true);
    }
  }, []);

  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
    localStorage.setItem("sidebar", !isSidebarVisible);
  };

  useEffect(() => {
    const savedSidebarState = localStorage.getItem("sidebar");
    if (savedSidebarState) {
      setSidebarVisible(JSON.parse(savedSidebarState));
    }
  }, []);

  const [isFreeTrialVisible, setFreeTrialVisible] = useState(true);

  const toggleFreeTrial = () => {
    setFreeTrialVisible(!isFreeTrialVisible);
  };

  const sidebarStyleone = {
    overflow: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    // position: 'sticky',

    // marginTop: isFreeTrialVisible ? "0px" : "0px",
  };

  const navbarStyle = {
    // top: isFreeTrialVisible ? "-4px" : "-50px",
  };
  const mainContentStyle = {
    // marginTop: isFreeTrialVisible ? "132px" : "92px",
  };
  const mainwrapper = {
    // marginTop: isSidebarVisible ? "0" : "0",
  };
  useEffect(() => {
    if (isFreeTrial) {
      // toggleSidebar(false);
      toggleFreeTrial(false);
    } else {
      // toggleSidebar(true);
      toggleFreeTrial(true);
    }
  }, [isFreeTrial]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      const sidebarElement = document.querySelector(".main-sidebar");
      const toggleButtonElement = document.querySelector(".sidebar-arrow a");

      if (
        sidebarElement &&
        !sidebarElement.contains(event.target) &&
        toggleButtonElement &&
        !toggleButtonElement.contains(event.target)
      ) {
        const mediaQuery = window.matchMedia("(max-width: 1024px)");
        if (mediaQuery.matches) {
          setSidebarVisible(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const GetMessages = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(GetMessagesApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        if (result?.status === true) {
          const bannerData = result?.data?.bannerText;
          setMessageData(bannerData);
        } else {
          // Swal.fire(
          //   "To ensure you see the most recent changes, please clear your browser's cache"
          // );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div className={isSidebarVisible ? "sidebar-mini" : ""}>
        {loading ? (
          <div className="landing_loader">
            <div className="innerloader">
              <img src="img/spin-1s-156px.gif" alt="" />
            </div>
          </div>
        ) : null}
        <div
          className={`${
            isFreeTrial || messageData
              ? "main-wrapper devicelistcstmtableres customclswrapper"
              : "main-wrapper devicelistcstmtableres "
          } `}
        >
          {isFreeTrial ? (
            <div className="app-container-trial">
              <FreeTrail />
            </div>
          ) : messageData ? (
            <div className="app-container-trial bannercssmsg">
              <BannerMessage />
            </div>
          ) : null}
          <div
            // className={`${isSidebarVisible ? "sidebar-mini" : ""} ${
            //   !isFreeTrialVisible ? "no-free-trial" : ""
            // }`}
            style={sidebarStyleone}
          >
            <nav
              className="navbar navbar-expand-lg main-navbar sticky"
              style={navbarStyle}
            >
              <div className="sidebar-arrow">
                <a
                  href="#"
                  data-toggle="sidebar"
                  onClick={(e) => {
                    toggleSidebar();

                    e.preventDefault();
                  }}
                >
                  <img src="assets/img/sidebar-toggle.webp" alt="" />
                </a>
              </div>
              <div className="header-title mr-auto purchseorderstatuscustm">
                <h4>
                  Purchase Order{" "}
                  <a
                    className="notes_icon"
                    href="#"
                    data-toggle="modal"
                    data-target="#noteinfoModal"
                  >
                    <img src="assets/img/notes-icon.svg" alt="" />
                  </a>
                </h4>
              </div>
              <SideUl />
            </nav>
            <MainSidebar />
            <div className="main-content" style={mainContentStyle}>
              <div className="purchase_order">
                <form action="" method="" className="">
                  <div className="inner_purchaseorder">
                    <div className="purchase_order_form">
                      <div className="purorder_left">
                        <div className="purchase_row">
                          <div className="purchase_col purchaseordercustmformfields">
                            <div className="form_column col_full cus_searchdropdown">
                              <div className="form-group">
                                <label className="cus_label">
                                  Select Supplier
                                </label>
                                <div
                                  className=" formfield field_wd100 selectcategoryformbox editbrandcustmcls purchseordercustminputdis"
                                  ref={dropdownRef1}
                                >
                                  <div
                                    class="form-control"
                                    bis_skin_checked="1"
                                    onClick={() => setShowListSuppiler(true)}
                                  >
                                    {selectedSuppiler || "Select Supplier"}
                                    <img
                                      src="assets/img/select-arrow.webp"
                                      alt="Handy Icon"
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                  {showListSuppiler && (
                                    <>
                                      <div className="tagsscustomdiv">
                                        <input
                                          ref={inputRef1}
                                          type="text"
                                          className="customsearchinputboxsetting inputeditcstmprd puchaseorderinputcstm inputdeviceformcustmbox form-control"
                                          placeholder={`Enter a Supplier`}
                                          value={
                                            selectedSuppiler ||
                                            searchTermSuppiler
                                          }
                                          onChange={handleSearchChangeSupplier}
                                        />
                                        <div className="addselectbox addbtneditformcstm">
                                          <ul>
                                            {filteredSuppiler.length > 0 ? (
                                              filteredSuppiler.map((brand) => (
                                                <li
                                                  key={brand.id}
                                                  onClick={() => {
                                                    handleSupplierSelect(brand);
                                                  }}
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  {brand.name}
                                                </li>
                                              ))
                                            ) : (
                                              <div
                                                style={{
                                                  padding: "8px",
                                                  color: "#999",
                                                  textAlign: "center",
                                                }}
                                              >
                                                No results matching "
                                                {searchTermSuppiler}"
                                              </div>
                                            )}
                                          </ul>
                                          {typedText &&
                                            !filteredSuppiler.includes(
                                              typedText
                                            ) && (
                                              <button
                                                onClick={() => {
                                                  handleAddNewClickSupplier();
                                                  if (inputRef1.current) {
                                                    inputRef1.current.focus();
                                                  }
                                                }}
                                                style={{
                                                  backgroundColor: "#9d3dd3",
                                                }}
                                                type="button"
                                                className="btn cus_addnew_btn   supllierbtnpurchaseorder"
                                              >
                                                <span>+</span> Add "{typedText}"
                                                as a new supplier
                                              </button>
                                            )}
                                        </div>{" "}
                                      </div>
                                    </>
                                  )}

                                  {error.selectedSuppiler && (
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        color: "red",
                                      }}
                                    >
                                      {error.selectedSuppiler}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="purchase_row">
                          <div className="purchase_col purchaseordercustmformfields">
                            <div className="form-group">
                              <label>
                                Email <span>CC/Bcc</span>
                              </label>
                              <div className="formfield">
                                <input
                                  type="email"
                                  className="form-control"
                                  name="email"
                                  placeholder="Enter Email"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="purchase_col purchaseordercustmformfields">
                            <div className="form_column col_full cus_searchdropdown">
                              <div className="form-group">
                                <label className="cus_label">
                                  Select Ship To
                                </label>
                                <div
                                  className=" formfield field_wd100 selectcategoryformbox editbrandcustmcls purchseordercustminputdis"
                                  ref={dropdownRef}
                                >
                                  <div
                                    className="form-control"
                                    onClick={() =>
                                      setShowListShip(!showListShip)
                                    }
                                  >
                                    {selectedShipTo || "Select Ship To"}
                                    <img
                                      src="assets/img/select-arrow.webp"
                                      alt="Handy Icon"
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                  {showListShip && (
                                    <>
                                      <div className="tagsscustomdiv">
                                        <input
                                          ref={inputRef}
                                          type="text"
                                          className="customsearchinputboxsetting inputeditcstmprd puchaseorderinputcstm inputdeviceformcustmbox form-control"
                                          placeholder={`Enter Ship To`}
                                          value={selectedShipTo || searchTerm}
                                          onChange={handleSearchChange}
                                        />
                                        <div className="addselectbox addbtneditformcstm">
                                          <ul>
                                            {filteredBrands.length > 0 ? (
                                              filteredBrands.map((brand) => (
                                                <li
                                                  key={brand.id}
                                                  onClick={() =>
                                                    handleBrandSelect(brand)
                                                  }
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  {brand.name}
                                                </li>
                                              ))
                                            ) : (
                                              <div
                                                style={{
                                                  padding: "8px",
                                                  color: "#999",
                                                  textAlign: "center",
                                                }}
                                              >
                                                No results matching "
                                                {searchTerm}"
                                              </div>
                                            )}
                                          </ul>
                                          {typedText &&
                                            !filteredBrands.includes(
                                              typedText
                                            ) && (
                                              <button
                                                onClick={() => {
                                                  handleAddNewClick();
                                                  if (inputRef.current) {
                                                    inputRef.current.focus();
                                                  }
                                                }}
                                                style={{
                                                  backgroundColor: "#9d3dd3",
                                                }}
                                                type="button"
                                                className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                              >
                                                <span>+</span> Add "{typedText}"
                                                as a new ship
                                              </button>
                                            )}
                                        </div>{" "}
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="purchase_col">
                            <div className="form-group">
                              <label>Purchase Order Date</label>

                              <div className="formfield datefield editbrandcustmcls purchseordercustminputdis ">
                                <img
                                  className="imagepurchaseordercstm"
                                  src="assets/img/calendar-icon.webp"
                                  alt="Calendar Icon"
                                  style={customStyles.calendarIcon}
                                />
                                <div className="datepicker-col-6 ">
                                  <div className="datepickerstudioend inputpurchasedate">
                                    <DatePicker
                                      selected={value}
                                      onChange={onChange}
                                      calendarClassName="date-picker-calendar form-control"
                                      dateFormat="yyyy-MM-dd"
                                      placeholderText="Select a date"
                                      style={customStyles.placeholder}
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="purchase_col purchaseordercustmformfields">
                            <div className="form-group">
                              <label>Tracking Number</label>
                              <div className="formfield">
                                <input
                                  type="text"
                                  className="form-control "
                                  name="tracknumber"
                                  placeholder="00000000"
                                  value={trackingNo}
                                  onChange={(e) =>
                                    setTrackingNo(e.target.value.trim())
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="purchase_row">
                          <div className="purchase_col purchaseordercustmformfields">
                            <div className="form-group">
                              <label>Mailing Address</label>
                              <div className="formfield">
                                <textarea
                                  className="form-control"
                                  name="mailingaddress"
                                  value={mailingAddress}
                                  onChange={(e) =>
                                    setMailingAddress(e.target.value)
                                  }
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          <div className="purchase_col purchaseordercustmformfields">
                            <div className="form-group">
                              <label>Shipping Adress</label>
                              <div className="formfield">
                                <textarea
                                  className="form-control"
                                  name="shippingaddress"
                                  value={shippingAddress}
                                  onChange={(e) =>
                                    setShippingAddress(e.target.value)
                                  }
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          <div className="purchase_col ">
                            <div className="form-group">
                              <label>Delivery Date</label>
                              <div className="formfield datefield  editbrandcustmcls purchseordercustminputdis inputpurchasedate ">
                                <img
                                  className="imagepurchaseordercstm"
                                  src="assets/img/calendar-icon.webp"
                                  alt="Calendar Icon"
                                  style={customStyles.calendarIcon}
                                />
                                <DatePicker
                                  selected={value1}
                                  onChange={onChange1}
                                  calendarClassName="date-picker-calendar form-control"
                                  dateFormat="yyyy-MM-dd"
                                  placeholderText="Select a date"
                                  style={customStyles.placeholder}
                                  minDate={value}
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="purchase_col purchaseordercustmformfields">
                            <div className="form-group">
                              <label>Shipping Cost</label>
                              <div className="formfield">
                                <input
                                  type="number"
                                  className="form-control no-arrow-input"
                                  name="tracknumber"
                                  placeholder="00000000"
                                  value={shippingCost}
                                  onChange={(e) =>
                                    setShippingCost(e.target.value.trim())
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          {/* <div className="purchase_col">
                        <div className="form-group">
                          <label>Shipping Cost</label>
                          <div className="formfield">
                            <input
                              type="text"
                              className="form-control"
                              name="tracknumber"
                              placeholder="00000000"
                            />
                          </div>
                        </div>
                      </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="purchase_order_table">
                      <div className="selectPlusnew">
                        <div className="dropdown divslectproductdisplyuicustom me-3">
                          <a
                            className="btn btn-secondary dropdown-toggle selectproductcustmdisplyedit"
                            href="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Select Product
                          </a>
                          <div className="uldivprucahseodrcustomdis">
                            <ul className="dropdown-menu ulselecteditpruchse">
                              {allProduct.map((item) => (
                                <li
                                  onClick={() => {
                                    handleCustomerSelection(item);
                                    //setSelectedState(true);
                                    setItemState(true);
                                  }}
                                >
                                  <div className="purchaseordernameimgcstm">
                                    {item?.image ? (
                                      <img
                                        className="purchaseorderproductshow"
                                        src={item?.image}
                                        alt=""
                                      />
                                    ) : (
                                      <img
                                        src="assets/img/defaultimg.webp"
                                        className="purchaseorderproductshow"
                                        alt=""
                                      />
                                    )}{" "}
                                    <span>
                                      {item?.title}
                                      <small> {item?.skuCode}</small>
                                      <small
                                        style={{
                                          color: "#0000ff",
                                        }}
                                      >
                                        {item?.imeiNumber}
                                      </small>
                                    </span>
                                    {/* <span className="dropdown-item">
                                                {item?.skuCode}
                                              </span>{" "} */}
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                        <div className="new_btnn cus_btn">
                          <a
                            href="#"
                            data-toggle="modal"
                            data-target="#createproductModal"
                            onClick={() => setProductShowModal(true)}
                          >
                            <i
                              className="fa fa-plus"
                              style={{ textAlign: "center" }}
                            ></i>{" "}
                            New
                          </a>
                        </div>
                      </div>

                      {itemState === true ? (
                        <>
                          <h4>Items:</h4>

                          <div className="tabledata">
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Product</th>
                                    <th>SKU</th>
                                    <th>QTY</th>
                                    <th>RQ/PQ</th>
                                    <th>Unit Cost</th>
                                    {/* <th>Tax</th> */}
                                    <th>Total</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {selectedProduct.map((item, index) => (
                                    <tr key={item.id}>
                                      <td>{item?.id}</td>
                                      <td>
                                        {item?.title}
                                        <br />
                                        {item.productCategoryId === 1 ? null : (
                                          <>
                                            <input
                                              type="text"
                                              className="form-control textarea_cls"
                                              placeholder="Type IMEI and hit Enter"
                                              value={imeiLists[item.id] || ""}
                                              onChange={(e) =>
                                                handleSerialNumberChange(
                                                  item.id,
                                                  e
                                                )
                                              }
                                              onKeyPress={(e) =>
                                                handleKeyPress(item.id, e)
                                              }
                                              style={{
                                                width: "40%",
                                              }}
                                            />
                                            <div
                                              className="selected-tagss"
                                              style={{
                                                border: "1px solid #ccc",
                                                borderRadius: "5px",
                                                maxHeight: "90px",
                                                width: "40%",
                                                overflowY: "auto",
                                                backgroundColor: "#f9fafb",
                                              }}
                                            >
                                              {imeiList[item.id]?.map(
                                                (imei, i) => (
                                                  <div key={i}>
                                                    <span
                                                      style={{
                                                        marginRight: "5px",
                                                      }}
                                                    >
                                                      {imei}
                                                    </span>
                                                    <a
                                                      className="delete-tag-buttons"
                                                      style={{
                                                        position: "relative",
                                                        top: "0",
                                                        right: "20",
                                                        padding: "2px 5px",
                                                        background: "none",
                                                        border: "none",
                                                        backgroundColor: "gray",
                                                        borderRadius: "50px",
                                                        height: "20px",
                                                        width: "20px",
                                                        textAlign: "center",
                                                        fontSize: "10px",
                                                        color: "white",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={(e) =>
                                                        handleRemoveImei(
                                                          e,
                                                          item.id,
                                                          i
                                                        )
                                                      }
                                                    >
                                                      &#x2715;
                                                    </a>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          </>
                                        )}
                                      </td>
                                      <td>{item?.skuCode}</td>
                                      {item?.productCategoryId === 1 ? (
                                        <>
                                          <td>
                                            {receivedQuantities[index] || 0}
                                          </td>
                                          <td>
                                            {receivedQuantities[index] || 0}/
                                            {purchaseQuantities[index] || 0}
                                          </td>
                                        </>
                                      ) : (
                                        <>
                                          <td>{quantities[item.id] || 0}</td>
                                          <td>
                                            {quantities[item.id] || 0}/
                                            {purCquantities[item.id] || 0}
                                          </td>
                                        </>
                                      )}
                                      <td>${unitCosts[index] || 0}</td>
                                      {/* <td>
                                    <div className="select_field cus_field">
                                      <select name="tax">
                                        <option value="Tax 1">Tax 1</option>
                                        <option value="Tax 2">Tax 2</option>
                                        <option value="Tax 3">Tax 3</option>
                                      </select>
                                    </div>
                                  </td> */}

                                      {item.productCategoryId === 1 ? (
                                        <td>
                                          $
                                          {unitCosts[index] *
                                            purchaseQuantities[index] || 0}
                                        </td>
                                      ) : (
                                        <td>
                                          $
                                          {unitCosts[index] *
                                            purCquantities[item.id] || 0}
                                        </td>
                                      )}
                                      <td>
                                        <div className="action_btn">
                                          <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#editinfoModal"
                                            className="edit_btn"
                                            onClick={() => handleEdit(index)}
                                          >
                                            <img
                                              src="assets/img/edit-icon.webp"
                                              alt=""
                                            />
                                          </a>
                                          <a
                                            href="#"
                                            className="delete_btn"
                                            onClick={() => handleDelete(index)}
                                          >
                                            <img
                                              src="assets/img/delete-icon.webp"
                                              alt=""
                                            />
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </>
                      ) : null}

                      {productshowModal === true ? (
                        <>
                          <div
                            className="modal common_modal createproduct_modal"
                            id="createproductModal"
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="createproductModalLabel"
                            aria-hidden="true"
                          >
                            <ProductModalTwo
                              onpress={onpress}
                              onload={GetAllProducts}
                            />
                          </div>
                        </>
                      ) : null}
                      <div className="clearalldata">
                        <a href="#" onClick={() => setSelectedProduct([])}>
                          Clear all lines
                        </a>
                      </div>
                    </div>
                    <div className="summary_table">
                      <div className="summary_note">
                        <div className="summery_field">
                          <label>Note:</label>
                          <textarea
                            className="form-control"
                            name="summarynote"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          ></textarea>
                        </div>
                      </div>

                      <div className="summary_data">
                        <table>
                          <tbody>
                            <tr className="txt_black">
                              <td>Subtotal</td>
                              <td></td>
                              {overallTotal ? (
                                <td>${overallTotal.toFixed(2)}</td>
                              ) : (
                                <td>$0.00</td>
                              )}
                            </tr>
                            <tr className="txt_grey discount">
                              <td>Discounts</td>
                              <td>
                                <div className="select_field cus_field divpurchaseorderadddiscount">
                                  <select
                                    // className="tax"
                                    onChange={handleDiscountChange}
                                    value={discount}
                                  >
                                    <option value={0}>0%</option>
                                    {alldiscount?.map((item) => (
                                      <>
                                        {" "}
                                        <option
                                          key={item?.id}
                                          value={item?.rate}
                                        >
                                          {item?.rate}%
                                        </option>
                                      </>
                                    ))}
                                  </select>
                                </div>
                              </td>
                              {discountedTotal ? (
                                <td>{discountedTotal.toFixed(2)}</td>
                              ) : (
                                <td>$0</td>
                              )}
                            </tr>
                            <tr className="txt_black">
                              <td>Tax</td>
                              <td>
                                <div className="select_field cus_field divpurchaseorderadddiscounttax">
                                  <select
                                    //className="tble_select"
                                    onChange={handleTaxChange}
                                    value={tax}
                                  >
                                    <option value={0}>Tax 0%</option>
                                    {alltax?.map((item) => (
                                      <>
                                        {" "}
                                        <option value={item?.rate}>
                                          {item?.name} {item?.rate}%
                                        </option>
                                      </>
                                    ))}
                                  </select>
                                </div>
                              </td>
                              {totalWithTax ? (
                                <td>{totalWithTax.toFixed(2)}</td>
                              ) : (
                                <td>$0</td>
                              )}
                            </tr>
                            <tr className="txt_grey">
                              <td>Shipping</td>
                              <td></td>
                              {shippingCost ? (
                                <td>${Number(shippingCost)}</td>
                              ) : (
                                <td>$0.00</td>
                              )}
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td>Total</td>
                              <td></td>
                              <td>
                                {calculateTotal(
                                  shippingCost,
                                  overallTotal,
                                  totalWithTax,
                                  discountedTotal
                                )}
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                    <div className="summary_table termsconditioncustpurchaseorder">
                      <div className="summary_note">
                        <div className="summery_field">
                          <label>Terms & Conditions:</label>
                          <textarea
                            className="form-control"
                            name="summarynote"
                            value={termscondition}
                            onChange={(e) => setTermsCondition(e.target.value)}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="purchaseorder_btn">
                      <div className="dropdown">
                        <button
                          className="btn orderbtn dropdown-toggle"
                          type="button"
                          data-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Save
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => {
                                OnSubmit();
                                // AddInventory();
                              }}
                            >
                              Save and New
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => {
                                OnSubmit1();
                                // AddInventory();
                              }}
                            >
                              Save and Close
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={() => {
                                OnSubmit2();
                                // AddInventory();
                              }}
                            >
                              Save & Send
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <Footer />
          {showModal === true ? (
            <>
              <div
                className="modal common_modal editinfo_modal"
                id="editinfoModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="editinfoModalLabel"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="editinfoModalLabel">
                        Edit Information
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          setUnitCosts([]);
                          setPurchaseQuantities([]);
                          setReceivedQuantities([]);
                          setQuantities([]);
                          setPurCQuantities([]);
                          setImeiList([]);
                          setShowModal(false);
                        }}
                      >
                        <span className="close_icon"></span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="custom_form editinfo_form">
                        <form action="" method="" className="cus_form">
                          <div className="form_row">
                            <div className="form_column col_three">
                              <div className="form_field price_field">
                                <div className="form-group">
                                  <label>Unit Price</label>
                                  <input
                                    type="number"
                                    className="form-control no-arrow-input"
                                    name="unit_price"
                                    placeholder="Enter Price"
                                    value={
                                      unitCosts[editedProductIndex] === 0
                                        ? ""
                                        : unitCosts[editedProductIndex]
                                    }
                                    onChange={(e) =>
                                      setUnitCosts((prevUnitCosts) => {
                                        const newUnitCosts = [...prevUnitCosts];
                                        newUnitCosts[editedProductIndex] =
                                          e.target.value;
                                        return newUnitCosts;
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form_column col_three">
                              <div className="form_field quantity_field">
                                <div className="form-group">
                                  <label>Purchase Quantity</label>
                                  {productCategoryIds === 2 ? (
                                    <input
                                      type="number"
                                      className="form-control no-arrow-input"
                                      name="quantity"
                                      placeholder="Enter Quantity"
                                      value={purCquantities[ids] || 0}
                                      readOnly
                                    />
                                  ) : (
                                    <input
                                      type="number"
                                      className="form-control no-arrow-input"
                                      name="purchase_quantity"
                                      placeholder="Enter Purchase Quantity"
                                      value={
                                        purchaseQuantities[
                                          editedProductIndex
                                        ] === 0
                                          ? ""
                                          : purchaseQuantities[
                                              editedProductIndex
                                            ]
                                      }
                                      // value={
                                      //   purchaseQuantities[editedProductIndex] || 0
                                      // }
                                      onChange={(e) =>
                                        setPurchaseQuantities(
                                          (prevPurchaseQuantities) => {
                                            const newPurchaseQuantities = [
                                              ...prevPurchaseQuantities,
                                            ];
                                            newPurchaseQuantities[
                                              editedProductIndex
                                            ] = e.target.value;
                                            return newPurchaseQuantities;
                                          }
                                        )
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                            {productCategoryIds === 2 ? (
                              <div className="form_column col_three">
                                <div className="form_field quantity_field">
                                  <div className="form-group">
                                    <label> Received Quantity</label>

                                    <input
                                      type="number"
                                      className="form-control no-arrow-input"
                                      name="quantity"
                                      placeholder="Enter Quantities"
                                      value={quantities[ids] || ""}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        const newQuantity = parseInt(value);

                                        // Handle case when input is empty or NaN
                                        if (
                                          value === "" ||
                                          isNaN(newQuantity)
                                        ) {
                                          setQuantities((prevQuantities) => ({
                                            ...prevQuantities,
                                            [ids]: 0,
                                          }));
                                        } else if (
                                          newQuantity <= purCquantities[ids]
                                        ) {
                                          setQuantities((prevQuantities) => ({
                                            ...prevQuantities,
                                            [ids]: newQuantity,
                                          }));
                                        } else {
                                          // Display an error message using SweetAlert
                                          alert(
                                            "Received Quantity cannot be more than Purchase Quantity"
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <>
                                <div className="form_column col_three">
                                  <div className="form_field quantity_field">
                                    <div className="form-group">
                                      <label> Received Quantity</label>
                                      <input
                                        type="number"
                                        className="form-control no-arrow-input"
                                        name="quantity"
                                        placeholder="Enter Quantity"
                                        value={
                                          receivedQuantities[
                                            editedProductIndex
                                          ] === 0
                                            ? ""
                                            : receivedQuantities[
                                                editedProductIndex
                                              ]
                                        }
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          const newReceivedQuantity =
                                            parseInt(value);

                                          // Handle case when input is empty or NaN
                                          if (
                                            value === "" ||
                                            isNaN(newReceivedQuantity)
                                          ) {
                                            setReceivedQuantities(
                                              (prevReceivedQuantities) => {
                                                const newReceivedQuantities = [
                                                  ...prevReceivedQuantities,
                                                ];
                                                newReceivedQuantities[
                                                  editedProductIndex
                                                ] = 0;
                                                return newReceivedQuantities;
                                              }
                                            );
                                          } else if (
                                            newReceivedQuantity <=
                                            purchaseQuantities[
                                              editedProductIndex
                                            ]
                                          ) {
                                            setReceivedQuantities(
                                              (prevReceivedQuantities) => {
                                                const newReceivedQuantities = [
                                                  ...prevReceivedQuantities,
                                                ];
                                                newReceivedQuantities[
                                                  editedProductIndex
                                                ] = newReceivedQuantity;
                                                return newReceivedQuantities;
                                              }
                                            );
                                          } else {
                                            alert(
                                              "Received Quantity cannot be more than Purchase Quantity"
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary default_btn"
                        data-dismiss="modal"
                        onClick={() => {
                          setShowModal(false);
                          setUnitCosts([]);
                          setPurchaseQuantities([]);
                          setReceivedQuantities([]);
                          setQuantities([]);
                          setPurCQuantities([]);
                          setImeiList([]);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary theme_btn"
                        data-dismiss="modal"
                        onClick={handleUpdate}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <div
        className="modal common_modal noteinfo_modal"
        id="noteinfoModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="noteinfoModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="noteclose"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src="assets/img/notes-close-icon.svg" alt="" />
              </button>
            </div>
            <div className="modal-body">
              <div className="notes_tabs">
                <ul
                  className="nav nav-pills notetabs"
                  id="notetabs"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="video_demo-tab"
                      data-toggle="tab"
                      href="#video_demo"
                      role="tab"
                      aria-controls="video_demo"
                      aria-selected="true"
                    >
                      Video Demo
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="page_info-tab"
                      data-toggle="tab"
                      href="#page_info"
                      role="tab"
                      aria-controls="page_info"
                      aria-selected="false"
                    >
                      Page Information
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="settingContent">
                  <div
                    className="tab-pane fade active show"
                    id="video_demo"
                    role="tabpanel"
                    aria-labelledby="video_demo-tab"
                  >
                    <div className="notetabs_content">
                      <h4>Video Demo</h4>
                      <p>
                        In this short tutorial, we’ll walk you through the key
                        features and functions of the Dashboard page.
                      </p>
                      <div className="notes_videos">
                        <img
                          src="assets/img/notes-demo-videos-img.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="page_info"
                    role="tabpanel"
                    aria-labelledby="page_info-tab"
                  >
                    <div className="notetabs_content">
                      <h4>Page Information</h4>
                      <p>
                        This page provides a comprehensive view of all the tools
                        and features available to you. Each section is designed
                        to help you manage your business more efficiently,
                        whether you’re tracking sales, monitoring inventory, or
                        analyzing reports.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PurchaseOrder;
