import React, { useEffect, useState } from "react";
import { AddLabelRecieptApi, GetLabelRecieptApi } from "../Utility/Api";
const RecieptLabel = () => {
  const [loading, setLoading] = useState("");

  const [selectedOption, setSelectedOption] = useState({
    customerPhone: 0,
    customerEmail: 0,
    none: 0,
  });
  const [customerDetails, setCustomerDetails] = useState({
    imei: 0,
    dueDate: 0,
    service: 0,
    brand: 0,
    barcode: 0,
    model: 0,
    password: 0,
  });

  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const [width1, setWidth1] = useState();
  const [height1, setHeight1] = useState();
  const [unit, setUnit] = useState("");
  const [customizeEnabled, setCustomizeEnabled] = useState(false);
  const [orientation, setOrientation] = useState("Portrait");
  const [FontSize, setFontSize] = useState("Small");

  const handleWidthChange = (event) => {
    const { value } = event.target;

    if (unit === "mm") {
      if (parseFloat(value) > 100) {
        alert("Maximum width allowed in mm is 100mm");

        setWidth("100");
        return;
      }
    } else if (unit === "Inches") {
      if (parseFloat(value) > 4) {
        alert("Maximum width allowed in inches is 4 inches");

        setWidth("4");
        return;
      }
    }

    setWidth(value);
  };

  const handleHeightChange = (event) => {
    const { value } = event.target;

    if (unit === "mm") {
      if (parseFloat(value) > 40) {
        alert("Maximum height allowed in mm is 40mm");

        setHeight("40");
        return;
      }
    } else if (unit === "Inches") {
      if (parseFloat(value) > 2) {
        alert("Maximum height allowed in inches is 2 inches");

        setHeight("2");
        return;
      }
    }

    setHeight(value);
  };

  // const handleWidthChange = (e) => {
  //   setWidth(e.target.value);
  // };

  // const handleHeightChange = (e) => {
  //   setHeight(e.target.value);
  // };
  const handleWidthChange1 = (e) => {
    setWidth1(e.target.value);
  };

  const handleHeightChange1 = (e) => {
    setHeight1(e.target.value);
  };

  const handleUnitChange = (e) => {
    setUnit(e.target.value);
  };

  const handleCustomizeChange = () => {
    setCustomizeEnabled(!customizeEnabled);
  };

  const handleOrientationChange = (e) => {
    setOrientation(e.target.value);
  };

  const handleFontsizeChange = (e) => {
    setFontSize(e.target.value);
  };

  useEffect(() => {
    GetReciept();
  }, []);

  const Reciept = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      orientation: orientation,
      fontSize: FontSize,
      width: width,
      height: height,
      unit: unit,
      customer: {
        phone: selectedOption.customerPhone,
        email: selectedOption.customerEmail,
        none: selectedOption.none,
      },
      items: {
        imei: customerDetails.imei,
        dueDate: customerDetails.dueDate,
        service: customerDetails.service,
        brand: customerDetails.brand,
        barcode: customerDetails.barcode,
        model: customerDetails.model,
        password: customerDetails.password,
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("reciept lable", raw);
    fetch(AddLabelRecieptApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          // Swal.fire(result?.message);
          GetReciept();
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const GetReciept = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetLabelRecieptApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          console.log("ggh", result);
          if (!result?.data) {
            //setStates(true);
          } else {
            const data = result?.data;

            const parsedItems = JSON.parse(data.items);
            const parsedCustomer = JSON.parse(data.customer);
            setWidth(result?.data?.width);
            setHeight(result?.data?.height);
            setUnit(result?.data?.unit);
            setCustomizeEnabled(!!result?.data?.unit);
            setSelectedOption({
              customerPhone: parsedCustomer.phone === 1 ? 1 : 0,
              customerEmail: parsedCustomer.email === 1 ? 1 : 0,
              none: parsedCustomer.none === 1 ? 1 : 0,
            });
            setFontSize(data?.fontSize);
            setOrientation(data?.orientation);
            setCustomerDetails({
              imei: parsedItems.imei,
              dueDate: parsedItems.dueDate,
              service: parsedItems.service,
              brand: parsedItems.brand,
              barcode: parsedItems.barcode,
              model: parsedItems.model,
              password: parsedItems.password,
            });
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCheckboxChangeName = (e) => {
    const { name, checked } = e.target;
    setCustomerDetails((prevDetails) => ({
      ...prevDetails,
      [name]: checked ? 1 : 0,
    }));
  };

  const handleOptionChange = (e) => {
    const { id } = e.target;
    let updatedOptions = {};

    if (id === "phone") {
      updatedOptions = {
        customerPhone: 1,
        customerEmail: 0,
        none: 0,
      };
    } else if (id === "email") {
      updatedOptions = {
        customerPhone: 0,
        customerEmail: 1,
        none: 0,
      };
    } else if (id === "none") {
      updatedOptions = {
        customerPhone: 0,
        customerEmail: 0,
        none: 1,
      };
    }

    setSelectedOption(updatedOptions);
  };

  const printTable = () => {
    const printContents =
      document.querySelector(".recieptlabelprint").innerHTML;
    const originalContents = document.body.innerHTML;
    const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write(`
    <html>
      <head>
     <style>
     @media print {
  @page {
    margin: 0; /* Remove default margins to ensure there's no space at the top and bottom */
  }
  body {
    margin: 0; /* Remove margins on body as well */
  }
  /* Hide browser print headers and footers */
  header,
  footer {
    display: none;
  }
}
     </style>

     </head>
      <body>${printContents}</body>
    </html>
  `);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };

  return (
    <>
      {" "}
      {loading ? (
        <div className="landing_loader">
          <div className="innerloader">
            <img src="img/spin-1s-156px.gif" alt="" />
          </div>
        </div>
      ) : null}
      <div className="setting_content receipt_config_setup">
        <h4>Receipt Configuration &amp; Setup</h4>
        <div className="inner_block plan_billing_setting">
          <form className="setting_form">
            <div className="block_column wd100">
              <h5>Service Label Configuration &amp; Setup</h5>
              <p>
                Here you can manage and configure the label for your services.
              </p>
              <div className="inner_field">
                <div className="tax_label_block">
                  <div className="tax_label">
                    <h6>Label Template</h6>
                    <span>Choose a template for your service label</span>
                  </div>
                  <div className="custom_radio">
                    <div className="cusradio">
                      {/* <label htmlFor="template1">
                        <input
                          type="radio"
                          name="receipt_template"
                          id="template1"
                          defaultChecked=""
                        />
                        <span className="radio_check" />
                      </label> */}
                      <div className="cus_recpt_temp receipt_templ">
                        <span className="recpt_title">Standard</span>
                        <div className="template_block">
                          <img src="assets/img/recipt-img.webp" alt="" />
                        </div>
                        <span className="receipt_note">
                          This template is recconmended
                        </span>
                      </div>
                    </div>
                    {/* <div className="cusradio">
                      <label htmlFor="template2">
                        <input
                          type="radio"
                          name="receipt_template"
                          id="template2"
                        />
                        <span className="radio_check" />
                      </label>
                      <div className="cus_recpt_temp receipt_templ">
                        <span className="recpt_title">Standard</span>
                        <div className="template_block"></div>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="receipt_other_info">
                  <div className="reciept_blockbox header_receipt_block">
                    <div className="receipt_left">
                      <label>
                        Size Configuration{" "}
                        <span>
                          Customize the line items displayed on your Label
                        </span>
                      </label>
                    </div>
                    <div className="receipt_right">
                      <div className="receipt_size_field">
                        <div className="form-group">
                          <label className="cus_label">Label Size/Width:</label>
                          <div className="size_field">
                            <select
                              className="form-control"
                              value={width}
                              onChange={handleWidthChange}
                              disabled={customizeEnabled}
                            >
                              <option value={88}>88mm (3.4")</option>
                              <option value={57}>57mm (2.25")</option>
                              <option value={54}>54mm (2.12")</option>
                              <option value={62}>62mm (2.4")</option>
                            </select>
                            {/* <input
                              type="number"
                              name="logo_size"
                              className="form-control no-arrow-input"
                              id="logo_size"
                              placeholder="Enter Label Width"
                              value={width} // Bind value to state
                              onChange={handleWidthChange} // Bind onChange to event handler
                            /> */}
                            <span className="receipt_note">
                              Defualt 88mm (3.4")
                            </span>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="cus_label">
                            Label Size/Height:
                          </label>
                          <div className="size_field">
                            <select
                              className="form-control"
                              value={height}
                              onChange={handleHeightChange}
                              disabled={customizeEnabled}
                            >
                              <option value={28}>28mm (1.1")</option>
                              <option value={25}>25mm (1")</option>
                              <option value={32}>32mm (1.25")</option>
                              <option value={38}>38mm (1.4")</option>
                            </select>
                            {/* <input
                              type="number"
                              name="logo_position"
                              className="form-control no-arrow-input"
                              id="logo_position"
                              placeholder="Enter Label Height"
                              value={height} // Bind value to state
                              onChange={handleHeightChange} // Bind onChange to event handler
                            /> */}
                            <span className="receipt_note">
                              Defualt 28mm (1.1")
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="reciept_blockbox header_receipt_block">
                    <div className="receipt_left">
                      <label>
                        Customize Size Configuration{" "}
                        <span>
                          Customize the line items displayed on your Label
                        </span>
                      </label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={customizeEnabled}
                        onChange={handleCustomizeChange}
                      />
                    </div>
                    {customizeEnabled && (
                      <div className="receipt_right">
                        <div className="receipt_size_field">
                          <div className="form-group">
                            <label className="cus_label">Label Unit:</label>
                            <div className="size_field">
                              <select
                                className="form-control"
                                value={unit}
                                onChange={handleUnitChange}
                                disabled={!customizeEnabled}
                              >
                                <option value="">Select Units</option>
                                <option value={"mm"}>mm</option>
                                <option value={"Inches"}>Inches</option>
                              </select>
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="cus_label">
                              Label Size/Width:
                            </label>
                            <div className="size_field">
                              <input
                                type="number"
                                name="logo_size"
                                className="form-control no-arrow-input"
                                id="logo_size"
                                placeholder="Enter Label Width"
                                value={width}
                                onChange={handleWidthChange}
                                disabled={!customizeEnabled}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="cus_label">
                              Label Size/Height:
                            </label>
                            <div className="size_field">
                              <input
                                type="number"
                                name="logo_position"
                                className="form-control no-arrow-input"
                                id="logo_position"
                                placeholder="Enter Label Height"
                                value={height}
                                onChange={handleHeightChange}
                                disabled={!customizeEnabled}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="clearfix" />
                  <div className="reciept_blockbox label_receipt_box">
                    <div className="receipt_left">
                      <label>
                        Line Items{" "}
                        <span>
                          Customize the line items displayed on your Label
                        </span>
                      </label>
                    </div>
                    <div className="receipt_right">
                      <div className="eachline_receipt">
                        <div className="custom_checkbox">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              name="imei"
                              type="checkbox"
                              id="imei"
                              // defaultValue="Display IMEI/ Serial #"
                              checked={customerDetails.imei === 1}
                              onChange={handleCheckboxChangeName}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="display_IMEI"
                            >
                              Display IMEI/ Serial #
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              name="dueDate"
                              type="checkbox"
                              id="dueDate"
                              //defaultValue="Display Due Date"
                              checked={customerDetails.dueDate === 1}
                              onChange={handleCheckboxChangeName}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="display_duedate"
                            >
                              Display Due Date
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              name="service"
                              type="checkbox"
                              id="service"
                              //defaultValue="Display Service"
                              checked={customerDetails.service === 1}
                              onChange={handleCheckboxChangeName}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="display_service"
                            >
                              Display Service
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="eachline_receipt">
                        <div className="custom_checkbox">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              name="barcode"
                              type="checkbox"
                              id="barcode"
                              // defaultValue="Display Barcode"
                              checked={customerDetails.barcode === 1}
                              onChange={handleCheckboxChangeName}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="display_barcode"
                            >
                              Display Barcode
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              name="brand"
                              type="checkbox"
                              id="brand"
                              // defaultValue="Display Brand"
                              checked={customerDetails.brand === 1}
                              onChange={handleCheckboxChangeName}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="display_brand"
                            >
                              Display Brand
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              name="model"
                              type="checkbox"
                              id="model"
                              // defaultValue="Display Model"
                              checked={customerDetails.model === 1}
                              onChange={handleCheckboxChangeName}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="display_model"
                            >
                              Display Model
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              name="password"
                              type="checkbox"
                              id="password"
                              // defaultValue="Display Password"
                              checked={customerDetails.password === 1}
                              onChange={handleCheckboxChangeName}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="display_password"
                            >
                              Display Password
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="eachline_receipt">
                        <label className="cus_label">Second Line:</label>
                        <div className="custom_radio">
                          <label htmlFor="phone">
                            <input
                              type="radio"
                              name="customer_detail"
                              id="phone"
                              checked={selectedOption.customerPhone === 1}
                              onChange={handleOptionChange}
                            />
                            <span className="radio_check" /> Customer Phone
                            Number
                          </label>
                          <label htmlFor="email">
                            <input
                              type="radio"
                              name="customer_detail"
                              id="email"
                              checked={selectedOption.customerEmail === 1}
                              onChange={handleOptionChange}
                            />
                            <span className="radio_check" /> Customer Email
                          </label>
                          <label htmlFor="none">
                            <input
                              type="radio"
                              name="customer_detail"
                              id="none"
                              checked={selectedOption.none === 1}
                              onChange={handleOptionChange}
                            />
                            <span className="radio_check" /> None
                          </label>
                        </div>
                      </div>
                      <div className="recipt_box"></div>
                    </div>
                  </div>
                  <div className="clearfix" />
                  <div className="reciept_blockbox label_receipt_box">
                    <div className="receipt_left">
                      <label>
                        Orientation{" "}
                        <span>
                          Select the layout direction for how items will appear
                          on your printed label
                        </span>
                      </label>
                    </div>
                    <div className="receipt_right">
                      <div className="receipt_size_field">
                        <div className="form-group">
                          <div className="size_field">
                            <select
                              className="form-control"
                              value={orientation}
                              onChange={handleOrientationChange}
                              // disabled={!customizeEnabled}
                            >
                              <option value={"Portrait"}>Portrait</option>
                              <option value={"Landscape"}>Landscape</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="clearfix" />
                  <div className="reciept_blockbox label_receipt_box">
                    <div className="receipt_left">
                      <label>
                        Font Size{" "}
                        <span>
                          Choose the font size for text on your printed label
                        </span>
                      </label>
                    </div>
                    <div className="receipt_right">
                      <div className="receipt_size_field">
                        <div className="form-group">
                          <div className="size_field">
                            <select
                              className="form-control"
                              value={FontSize}
                              onChange={handleFontsizeChange}
                              // disabled={!customizeEnabled}
                            >
                              <option value={"Small"}>Small</option>
                              <option value={"Regular"}>Regular</option>
                              <option value={"Large"}>Large</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="receipt_setup_btn">
                  <button
                    type="button"
                    className="receipt_preview_btn"
                    onClick={printTable}
                  >
                    Preview
                  </button>
                  <button
                    type="button"
                    className="receipt_save_btn"
                    onClick={Reciept}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
          <div className="clearfix" />
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div className="recieptlabelprint">
          <div
            style={{
              width: `${width}mm`,
              height: `${height}mm`,
              textAlign: "left",
              margin: 0,
              padding: 0,
            }}
          >
            <div style={{ width: "100%", textAlign: "left", border: 0 }}>
              <div
                style={{
                  //padding: "5px 0px",
                  fontSize:
                    FontSize === "Small"
                      ? "10px"
                      : FontSize === "Regular"
                      ? "14px"
                      : "18px",
                  fontWeight: 600,
                  //marginTop: 20,
                  color: "#000",
                }}
              >
                John Doe
              </div>
              <div
                style={{
                  // padding: "5px 0px",
                  fontSize:
                    FontSize === "Small"
                      ? "10px"
                      : FontSize === "Regular"
                      ? "14px"
                      : "18px",
                  fontWeight: 400,
                  marginTop: 5,
                  color: "#000",
                }}
              >
                {selectedOption.customerPhone === 1 ? " 416-045-8040" : "--"}
                <br />

                {selectedOption.customerEmail === 1
                  ? " customer@gmail.com"
                  : "--"}
              </div>
              <div
                style={{
                  //padding: "5px 0px",
                  fontSize:
                    FontSize === "Small"
                      ? "10px"
                      : FontSize === "Regular"
                      ? "14px"
                      : "18px",
                  fontWeight: 800,
                  marginTop: 5,
                  color: "#000",
                }}
              >
                {customerDetails.brand === 1 ? "Apple" : "--"}
                {customerDetails.model === 1 ? "iPhone 11" : "--"}
                <br />
                {customerDetails.service === 1 ? "Screen Replacement" : "--"}
                <br />
                {customerDetails.imei === 1 ? "21323213" : "--"}
                <br />
                {customerDetails.password === 1 ? "1234567" : "--"}
              </div>
            </div>
            <div style={{ width: "100%", textAlign: "left", border: 0 }}>
              <div>
                <span
                  style={{
                    // padding: "3px 0px 0px",
                    fontSize:
                      FontSize === "Small"
                        ? "10px"
                        : FontSize === "Regular"
                        ? "14px"
                        : "18px",
                    fontWeight: 400,
                    marginTop: 5,
                    color: "#000",
                    display: "block",
                  }}
                >
                  Due:
                  {customerDetails.dueDate === 1 ? " 21 Feb 11:00 am" : "--"}
                </span>
                {/* <img src="assets/img/barcode-img.png" alt="" /> */}
                <span
                  style={{
                    padding: 0,
                    fontSize:
                      FontSize === "Small"
                        ? "10px"
                        : FontSize === "Regular"
                        ? "14px"
                        : "18px",
                    fontWeight: 400,
                    marginTop: 10,
                    color: "#000",
                    display: "block",
                  }}
                >
                  Ticket
                  <span style={{ fontWeight: 600 }}>#0121</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecieptLabel;
