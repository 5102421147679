import React from "react";

const ResetPass = () => {
  const BackLogin = () => {
    window.location.href = "https://app.toolboxpos.com/Login";
  };
  return (
    <>
      <div className="main-wrapper custom-mainwrapper">
        <div className="register_page">
          <div className="regi_step_form">
            <form action="" className="stepform">
              <div className="cus_step" id="step3">
                <div className="regis_header reg_whtheader">
                  <div className="topheader">
                    <div className="logo">
                      <a href="#">
                        <img src="img/Logomark.webp" alt="" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="congrates_msg">
                  <h3>Password has been reset.</h3>
                  <div className="congrat_icon">
                    <img src="img/Icon-checkmark.webp" alt="" />
                  </div>
                  <div className="proceed_btn">
                    <a href="#" className="cus_btn" onClick={BackLogin}>
                      Sign in <img src="img/angle-right-icon.webp" alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default ResetPass;
