import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import moment from "moment";
import { resendVerifyApi, updateVerifyEmailStatusApi } from "../Utility/Api";

const VerifyEmail = () => {
  const [stoken, setToken] = useState("");
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState(null);
  const [message1, setMessage1] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState({});
  let navigate = useNavigate();

  const urlSearchParams = new URLSearchParams(window.location.search);
  var id = urlSearchParams.get("token");

  const Validation = () => {
    let error = {};
    let reg = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    // let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=])(?=.*\d).{6,}$/;
    if (!email) {
      error.email = "Email is required.";
    } else if (!reg.test(email)) {
      error.email = "Invalid email .";
    }

    if (Object.keys(error).length === 0) {
      ResendEmail();
    }
    return error;
  };

  const Register = () => {
    setError(Validation());
  };
  const location = useLocation();

  const Verifymail = async () => {
    var myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      token: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // console.log("verify raw", raw);

    await fetch(updateVerifyEmailStatusApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("updateVerifyEmailStatusApi", result);
        if (result?.status === true) {
          // console.log("verifydata", result);
          setMessage("Email Verified Successfully");
          localStorage.setItem("token", result?.token);

          //setMessageState(3);
          navigate("/Signup", { state: { state: 3 } });
          //  setTimeout(() => {
          //    navigate("/Signup", { state: { state: 3 } });
          //  }, 500);
        }
        // if (
        //   result.status === true &&
        //   result.message === "Email verified successfully!"
        // ) {
        //   setMessage("Email Verified Successfully");
        //   //setMessageState(3);
        //   //navigate("/Signup", { state: { state: 3 } });
        //   setTimeout(() => {
        //     navigate("/Signup", { state: { state: 3 } });
        //   }, 500);
        // }
        else if (
          result.status === false &&
          result.message === "Token has expired"
        ) {
          setMessage("Email Verification Link has expired");
          setMessageState(0);
        } else if (
          result.status === false &&
          result.message === "Email Already Verified"
        ) {
          setMessage("Email Already Verified");
          setMessageState(1);
          setTimeout(() => {
            navigate("/Signup", { state: { state: 3 } });
          }, 2000);
        } else if (
          result.status === false &&
          result.message === "email link has expired"
        ) {
          setMessage("Email link has expired");
          setMessageState(4);
        } else if (
          result.status === false &&
          result.message === "Invalid token"
        ) {
          setMessage("Invalid Email Verification Link");
          setMessageState(2);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const ResendEmail = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    // console.log("resend mail", raw);
    fetch(resendVerifyApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("resend mail result", result);
        if (result?.status === true) {
          setTimeout(() => {
            navigate("/Signup", { state: { state: 3 } });
          }, 2000);
          setMessage1(result?.message);
          setEmail("");
        } else {
          setMessage1(result?.message);
          setEmail("");
          // setTimeout(() => {
          //   navigate("/Signup", { state: { stat: 2 } });
          // }, 2000);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (location?.state?.messageState === 4) {
      setMessageState(4);
    }
    async function fetchData() {
      // console.log("para", id);
      // setToken(id);
      // if(id){}
      if (id) {
        const checkVerify = await Verifymail();
      }
    }
    fetchData();
    // if (location?.state?.messageState === 4) {
    //   setMessageState(4);
    // }
  }, []);
  return (
    <>
      <div className="main-wrapper custom-mainwrapper">
        <div className="register_page">
          <div className="regis_header reg_whtheader">
            <div className="topheader">
              <div className="logo">
                <Link to="/">
                  <img src="img/Logomark.webp" alt="" />
                </Link>
              </div>
            </div>
          </div>
          <div className="regi_step_form">
            {/* <form action="" className="stepform"> */}
            <div className="holding_msg custom_msg">
              <p>Hold on....we are creating your store...</p>
              <span>Your login details are being emailed...</span>
            </div>
            {messageState === 0 ? (
              <div className="holding_msg custom_msg">
                <div className="customform">
                  <span style={{ color: "red" }}> {message}</span>
                  <h5 className="mt-5">
                    Please Enter Your Email For verification Again
                  </h5>
                </div>

                <div className="row mt-5">
                  <div className="col-md-4 ">
                    <div className="lablecustver">
                      <label>Email Address</label>
                    </div>
                  </div>

                  <div className="col-md-8">
                    <input
                      type="email"
                      name="email"
                      className="inutfieldcust"
                      value={email}
                      placeholder="Email Address"
                      onChange={(e) => {
                        setEmail(e.target.value.trim());
                        setError("");
                      }}
                    />
                  </div>

                  {error.email && (
                    <span style={{ color: "red" }}>{error.email}</span>
                  )}
                </div>
                <div className="resendbuttoncustver">
                  <button
                    onClick={() => Register()}
                    type="button"
                    className="buttonresendver"
                  >
                    Resend
                  </button>
                </div>
                <div className="text-center">
                  {message1 && <span style={{ color: "red" }}>{message1}</span>}
                </div>

                <div className="progress">
                  <div
                    className="progress_bar"
                    role="progressbar"
                    aria-valuenow="55"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: "55%" }}
                  ></div>
                </div>
              </div>
            ) : null}
            {messageState === 1 ? (
              <div className="holding_msg custom_msg">
                <span style={{ color: "red" }}> {message}</span>

                <div className="progress">
                  <div
                    className="progress_bar"
                    role="progressbar"
                    aria-valuenow="55"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: "100%" }}
                  ></div>
                </div>
              </div>
            ) : null}
            {messageState === 3 ? (
              <div className="holding_msg custom_msg">
                <span style={{ color: "red" }}> {message}</span>

                <div className="progress">
                  <div
                    className="progress_bar"
                    role="progressbar"
                    aria-valuenow="55"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: "100%" }}
                  ></div>
                </div>
              </div>
            ) : null}
            {messageState === 2 ? (
              <div className="holding_msg custom_msg">
                <div className="customform">
                  <span style={{ color: "red" }}> {message}</span>
                </div>

                <div className="progress">
                  <div
                    className="progress_bar"
                    role="progressbar"
                    aria-valuenow="55"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: "55%" }}
                  ></div>
                </div>
              </div>
            ) : null}
            {messageState === 4 ? (
              <div className="holding_msg custom_msg">
                <div className="customform">
                  <span style={{ color: "red" }}> {message}</span>
                  <h5 className="mt-5">
                    Please Enter Your Email For verification Again
                  </h5>
                </div>

                <div className="row mt-5">
                  <div className="col-md-4 ">
                    <div className="lablecustver">
                      <label>Email Address</label>
                    </div>
                  </div>

                  <div className="col-md-8">
                    <input
                      type="email"
                      name="email"
                      className="inutfieldcust"
                      value={email}
                      placeholder="Email Address"
                      onChange={(e) => {
                        setEmail(e.target.value.trim());
                        setError("");
                      }}
                    />
                  </div>

                  {error.email && (
                    <span style={{ color: "red" }}>{error.email}</span>
                  )}
                </div>
                <div className="resendbuttoncustver">
                  <button
                    onClick={() => Register()}
                    type="button"
                    className="buttonresendver"
                  >
                    Resend
                  </button>
                </div>
                <div className="text-center">
                  {message1 && <span style={{ color: "red" }}>{message1}</span>}
                </div>

                <div className="progress">
                  <div
                    className="progress_bar"
                    role="progressbar"
                    aria-valuenow="55"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: "55%" }}
                  ></div>
                </div>
              </div>
            ) : null}
            {/* {messageState === 4 ? (
                <div className="holding_msg custom_msg">
                  <div className="customform">
                    <span style={{ color: "red" }}> {message}</span>
                    <h5 className="mt-5">
                      Please Enter Your Email For verification Again
                    </h5>
                  </div>
                  <form>
                    <div className="row mt-5">
                      <div className="col-md-4 ">
                        <div className="lablecustver">
                          <label>Email Address</label>
                        </div>
                      </div>

                      <div className="col-md-8">
                        <input
                          type="email"
                          name="email"
                          className="inutfieldcust"
                          value={email}
                          placeholder="Email Address"
                          onChange={(e) => {
                            setEmail(e.target.value.trim());
                            setError("");
                          }}
                        />
                      </div>

                      {error.email && (
                        <span style={{ color: "red" }}>{error.email}</span>
                      )}
                    </div>
                    <div className="col-md-12">
                      <div className="resendbuttoncustver">
                        <button
                          onClick={() => Register()}
                          type="button"
                          className="buttonresendver"
                        >
                          Resend
                        </button>
                      </div>
                    </div>
                  </form>

                  <div className="text-center">
                    {message1 && (
                      <span style={{ color: "red" }}>{message1}</span>
                    )}
                  </div>
                  <div className="progress">
                    <div
                      className="progress_bar"
                      role="progressbar"
                      aria-valuenow="55"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: "55%" }}
                    ></div>
                  </div>
                </div>
              ) : null} */}
            {/* </form> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default VerifyEmail;
