import React, { useEffect } from "react";
import { NavLink, Link } from "react-router-dom";

const UserBlank = () => {
  return (
    <>
      <header className="header" style={{ backgroundColor: "#84E9FF" }}>
        <div className="container">
          <div className="headerstrip">
            <div className="headerlogo">
              <a aria-current="page" className="active" href="/succeslink">
                <img alt="Toolbox Logo" src="img/toolbox-logo-txt.webp" />
              </a>
            </div>
            <div className="back_btn">
              <Link
                className="backlink"
                to="/"
                // href="/succeslink"
                style={{ backgroundColor: "#000" }}
              >
                Back
              </Link>
            </div>
          </div>
        </div>
      </header>

      <div className="main-wrapper custom-mainwrapper">
        <div className="register_page">
          <div className="regi_step_form">
            <form action="" className="stepform">
              <div className="cus_step" id="step3">
                {/* <div className="regis_header reg_whtheader">
                  <div className="topheader">
                    <div className="logo">
                      <a href="#">
                        <img src="img/Logomark.webp" alt="" />
                      </a>
                    </div>
                  </div>
                </div> */}
                <div className="container">
                  <div className="cus_blank_msg">
                    <h3>Please Contact your Admin.</h3>
                    {/* <div className="proceed_btn">
                      <a href="#" className="cus_btn">
                        Sign in <img src="img/angle-right-icon.svg" alt="" />
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* <header className="header" style={{ backgroundColor: "#84E9FF" }}>
        <div className="container">
          <div className="header_inner">
            <div className="logo">
              <NavLink>
                <img alt="Toolbox Logo" src="img/toolbox-logo-txt.webp" />
              </NavLink>
            </div>
            <nav className="navbar navbar-expand-lg custom_navbar cstmnavbarresponscss">
              <NavLink className="navbar-brand">
                <img alt="Toolbox Logo" src="img/toolbox-logo-txt.webp" />
              </NavLink>

              <div className="account_btns">
                <Link
                  to="/"
                  className="accnt_btn"
                  style={{ backgroundColor: "#000" }}
                >
                  Back
                </Link>
              </div>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav customulresponsivecss"></ul>
              </div>
            </nav>
          </div>
        </div>
      </header> */}
      {/* <div className="container">
        <div className="cus_blank_msg">
          <h3>Please Contact your Admin.</h3>
          <div className="proceed_btn">
            <a href="#" className="cus_btn">
              Sign in <img src="img/angle-right-icon.svg" alt="" />
            </a>
          </div>
        </div>
      </div> */}
      {/* <div className="box">
        <h1>Please Contact your Admin</h1>
      </div> */}
    </>
  );
};
export default UserBlank;
