import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import {
  AddCashLayoutApi,
  AddTaxApi,
  baseurl,
  GetAllTaxApi,
  getProfileApi,
} from "../Utility/Api";
import Swal from "sweetalert2";

const TaxOptions = () => {
  const [tax, setTax] = useState("");
  const [taxName, setTaxName] = useState("");
  const [alltax, setAllTax] = useState([]);
  const [id, setId] = useState("");
  const [error, setError] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [defaultTaxId, setDefaultTaxId] = useState("");
  const [defaultTaxName, setDefaultTaxName] = useState("");
  const [selectedOptionId, setSelectedOptionId] = useState("");
  useEffect(() => {
    GetAllTax();
  }, []);

  const GetAllTax = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllTaxApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("object", result.data);
        setAllTax(result?.data);
        const defaultTaxOption = result?.data?.find(
          (item) => item.defaultTax === 1
        );
        if (defaultTaxOption) {
          setDefaultTaxId(defaultTaxOption.id);
          setDefaultTaxName(defaultTaxOption?.name);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const Validation = () => {
    let error = {};
    if (!taxName) {
      error.taxName = "Tax Name is required.";
    }
    if (!tax) {
      error.tax = "Tax Percentage is required.";
    }

    if (Object.keys(error).length === 0) {
      AddTax();
    }
    return error;
  };

  const SubmitTax = () => {
    setError(Validation());
  };

  const AddTax = () => {
    let local = localStorage.getItem("token");
    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      name: taxName,
      rate: tax,
      defaultTax: 0,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddTaxApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllTax();
          setShowModal(false);
          setTax("");
          setTaxName("");
          Swal.fire(result?.message);
        } else {
          Swal.fire(result?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const Delete = (id) => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}deleteTax/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllTax();
          Swal.fire(result?.message);
        } else {
          Swal.fire(result?.message);
        }
      })
      .catch((error) => console.error(error));
  };

  const HandleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete tax option",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c76cff",
      cancelButtonColor: "#efefef",
      confirmButtonText: "Yes, delete it!",
      confirmButtonText: "<span style='color: white;'>Yes, delete it!</span>",
      cancelButtonText: "<span style='color: black;'>cancel!</span>",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "sweet-alert2-container-zindex",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your data has been deleted.",
          icon: "success",
          confirmButtonColor: "#c76cff",
          customClass: {
            container: "sweet-alert2-container-zindex",
          },
        }).then(() => {
          Delete(id);
        });
      }
    });
  };

  const HandleEdit = (item) => {
    setShowModal1(true);
    setTax(item?.rate);
    setTaxName(item?.name);
    setId(item?.id);
  };

  const UpdateValidation = () => {
    let error = {};
    if (!taxName) {
      error.taxName = "Tax Name is required.";
    }
    if (!tax) {
      error.tax = "Tax Percentage is required.";
    }
    if (Object.keys(error).length === 0) {
      UpdateTax();
    }
    return error;
  };

  const Update = () => {
    setError(UpdateValidation());
  };

  const UpdateTax = () => {
    let local = localStorage.getItem("token");
    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      name: taxName,
      rate: tax,
      defaultTax: 0,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseurl}updateTax/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllTax();
          setShowModal1(false);
          Swal.fire(result?.message);
        } else {
          Swal.fire(result?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const UpdateOption = (option, id) => {
    let local = localStorage.getItem("token");
    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      name: option?.name,
      rate: option?.rate,
      defaultTax: 1,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseurl}updateTax/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllTax();
          setShowModal1(false);
          Swal.fire(result?.message);
        } else {
          Swal.fire(result?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div className="setting_content">
        <h4>Tax Option</h4>
        <div className="inner_block plan_billing_setting">
          <form className="setting_form" action="" method="post">
            <div className="block_column wd100">
              <h5>Accepted Tax Options</h5>
              <p>
                Manage the tax that you will accept within your cash register.
              </p>
              <div className="inner_field">
                <div className="tax_label_block">
                  <div className="tax_label">
                    <h6>Default Tax option</h6>
                    <span>
                      This is the default tax method shown in in cash register.
                    </span>
                  </div>
                  <div className="tax_field">
                    <select
                      className="form-control"
                      name="payment_option"
                      value={selectedOptionId || defaultTaxId} // Ensure the select shows the correct default or selected value
                      onChange={(e) => {
                        const selectedOption = alltax.find(
                          (item) => item.id === parseInt(e.target.value)
                        );
                        setSelectedOptionId(e.target.value); // Update selected option state
                        UpdateOption(selectedOption, e.target.value);
                      }}
                      // onChange={(e) => {
                      //   UpdateOption(
                      //     e.target.value,
                      //     e.target.options[e.target.selectedIndex].id
                      //   );
                      // }}
                    >
                      {" "}
                      <option value="">
                        {defaultTaxName ? defaultTaxName : "Select Tax Option"}
                      </option>
                      {alltax
                        ?.filter((item) => item.defaultTax !== 1)
                        .map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        ))}
                    </select>

                    <img src="assets/img/select-arrow.webp" alt="" />
                  </div>
                </div>
                <div className="tax_label_block">
                  <div className="tax_label">
                    <h6>Tax list</h6>
                    <span>
                      All the tax options available in your cash register.
                    </span>
                  </div>
                </div>
                <div className="status_table">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Name</th>
                          <th>Percentage</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {alltax?.map((item, index) => (
                          <>
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item?.name}</td>
                              <td>{item?.rate}</td>
                              <td>
                                <div className="status_edit_btn">
                                  <a
                                    className="editbtn"
                                    data-toggle="modal"
                                    data-target="#edittaxoptionModal"
                                    href="#"
                                    onClick={() => HandleEdit(item)}
                                  >
                                    Edit
                                  </a>
                                  <a
                                    className="deletebtn"
                                    href="#"
                                    onClick={() => HandleDelete(item?.id)}
                                  >
                                    Delete
                                  </a>
                                </div>
                              </td>
                            </tr>
                          </>
                        ))}

                        <tr className="add_row">
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className="add_btn">
                            <a
                              data-toggle="modal"
                              data-target="#addtaxoptionModal"
                              href="javascript:;"
                              onClick={() => setShowModal(true)}
                            >
                              + <span>Add New</span>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className="clearfix"></div>
        </div>
      </div>
      {showModal === true ? (
        <>
          <div
            className="modal common_modal status_option_modal"
            id="addtaxoptionModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="addtaxoptionModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="addtaxoptionModalLabel">
                    Add Tax Option
                  </h5>
                  <p>Add Tax options to your register</p>
                </div>
                <div className="modal-body">
                  <div className="prod_status_sec">
                    <form method="" action="">
                      <div className="field_row">
                        <div className="field_col col_half">
                          <div className="status_name">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Tax Name"
                              style={{ textTransform: "capitalize" }}
                              name="taxName"
                              value={taxName}
                              onChange={(e) => {
                                setTaxName(e.target.value);

                                if (error.taxName) {
                                  setError({ ...error, taxName: "" });
                                }
                              }}
                            />
                            {error.taxName && (
                              <span className="customvalidationdesignlogin">
                                {error.taxName}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="field_col col_half">
                          <div className="status_name">
                            <input
                              type="number"
                              className="form-control no-arrow-input"
                              placeholder="Enter Tax Percentage"
                              value={tax}
                              onChange={(e) => {
                                setTax(e.target.value);
                                if (error.tax) {
                                  setError({ ...error, tax: "" });
                                }
                              }}
                            />
                            {error.tax && (
                              <span className="customvalidationdesignlogin">
                                {error.tax}
                              </span>
                            )}
                            <span className="percent_sign custmtaxpercnt">
                              %
                            </span>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary default_btn"
                    data-dismiss="modal"
                    onClick={() => {
                      setShowModal(false);
                      setTax("");
                      setTaxName("");
                      setError("");
                      document
                        .querySelectorAll(".modal-backdrop")
                        .forEach((el) => el.classList.remove("modal-backdrop"));
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary theme_btn"
                    onClick={SubmitTax}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {showModal1 === true ? (
        <>
          <div
            className="modal common_modal status_option_modal"
            id="edittaxoptionModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="edittaxoptionModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="edittaxoptionModalLabel">
                    Edit Tax Option
                  </h5>
                  <p>Edit Tax options to your register</p>
                </div>
                <div className="modal-body">
                  <div className="prod_status_sec">
                    <form method="" action="">
                      <div className="field_row">
                        <div className="field_col col_half">
                          <div className="status_name">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Tax Name"
                              style={{ textTransform: "capitalize" }}
                              name="taxName"
                              value={taxName}
                              onChange={(e) => {
                                setTaxName(e.target.value);

                                if (error.taxName) {
                                  setError({ ...error, taxName: "" });
                                }
                              }}
                            />
                            {error.taxName && (
                              <span className="customvalidationdesignlogin">
                                {error.taxName}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="field_col col_half">
                          <div className="status_name">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Tax Percentage"
                              value={tax}
                              onChange={(e) => {
                                setTax(e.target.value);
                                if (error.tax) {
                                  setError({ ...error, tax: "" });
                                }
                              }}
                            />
                            {error.tax && (
                              <span className="customvalidationdesignlogin">
                                {error.tax}
                              </span>
                            )}
                            <span className="percent_sign custmtaxpercnt">
                              %
                            </span>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary default_btn"
                    data-dismiss="modal"
                    onClick={() => {
                      setShowModal1(false);

                      document
                        .querySelectorAll(".modal-backdrop")
                        .forEach((el) => el.classList.remove("modal-backdrop"));
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary theme_btn"
                    onClick={Update}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
export default TaxOptions;
