import React from "react";
import { Link } from "react-router-dom";

const FooterTwo = () => {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="inner_footer">
            <div className="footer_col footer_info">
              <div className="footer_logo">
                <Link to="/">
                  <img src="img/footer-logo.webp" alt="" />
                </Link>
              </div>
              <p>Work at the speed of thought.</p>
              <div className="social_link">
                <ul>
                  <li>
                    <a href="#">
                      <img src="img/facebook-icon.webp" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="img/twitter-icon.webp" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="img/instagram-icon.webp" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="img/youtube-icon.webp" alt="" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="social_link socialiconcstm">
                <ul>
                  <li>
                    <Link to="https://www.linkedin.com/company/toolboxpos">
                      <img src="img/linkedin.webp" alt="" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer_col footer_link">
              <h4>Company</h4>
              <div className="custom_link">
                <ul>
                  <li>
                    <a href="#">About Us</a>
                  </li>
                  <li>
                    <a href="#">Blog</a>
                  </li>
                  <li>
                    <a href="#">Pricing</a>
                  </li>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="#">Terms & Conditions</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer_col footer_link">
              <h4>Features</h4>
              <div className="custom_link">
                <ul>
                  <li>
                    <a href="#">Cash Register</a>
                  </li>
                  <li>
                    <a href="#">Repair Tracking</a>
                  </li>
                  <li>
                    <a href="#">Searlized Products</a>
                  </li>
                  <li>
                    <a href="#">iOS</a>
                  </li>
                  <li>
                    <a href="#">Android</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer_col footer_link">
              <h4>Useful Links</h4>
              <div className="custom_link">
                <ul>
                  <li>
                    <a href="#">Contact Us</a>
                  </li>
                  <li>
                    <a href="#">Affilaite program</a>
                  </li>
                  <li>
                    <a href="#">Reviews</a>
                  </li>
                  <li>
                    <a href="#">Partners</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer_col footer_address">
              <h4>Contact Us</h4>
              <div className="contact_info">
                <p>
                  <img src="img/foot-map-icon.webp" alt="" />
                  Yonge St, Toronto, ON M5E 1E5
                </p>
                <p>
                  <img src="img/foot-phone-icon.webp" alt="" />
                  <a href="tel:+1-416-771-8675">+1-416-771-8675</a>
                </p>
                <p>
                  <img src="img/foot-email-icon.webp" alt="" />
                  <a href="mailto:info@toolboxpos.com">info@toolboxpos.com</a>
                </p>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
          <div className="newsletter">
            <h4>Subscribe to Our News Letter</h4>
            <p>Freakishly simple. Downright useful. Ready to make the jump?</p>
            <div className="newsletter_form">
              <form className="custom_form">
                <div className="form_field">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Email"
                  />
                  <button type="button" className="form-btn">
                    Sign me Up
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="copyright">
            <p>Copyright @ 2024 Toolbox. All Rights Reserved.</p>
          </div>
        </div>
      </footer>
    </>
  );
};
export default FooterTwo;
