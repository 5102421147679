import React, { useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { baseurl } from "../Utility/Api";
const Cancel = () => {
  useEffect(() => {
    GetPaymentStatus();
  }, []);

  const GetPaymentStatus = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEGg1BXYjAF2QwUxV9f0l5jR3JRoKj8e3.VgzYVsFHBVWij%2Fqxs0OsCCXoqh%2BBRBfCpqOI3duvIg0"
    );

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}updatePaymentStatus/0`, requestOptions)
      .then((response) => response.json())
      .then((result) => {})
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <>
      <header className="header" style={{ backgroundColor: "#84E9FF" }}>
        <div className="container">
          <div className="header_inner">
            <div className="logo">
              <NavLink>
                <img alt="Toolbox Logo" src="img/toolbox-logo-txt.webp" />
              </NavLink>
            </div>
            <nav className="navbar navbar-expand-lg custom_navbar backbtncancel">
              <NavLink className="navbar-brand">
                <img alt="Toolbox Logo" src="img/toolbox-logo-txt.webp" />
              </NavLink>

              <div className="account_btns">
                <Link
                  to="/settings"
                  state={{ activeState: 1 }}
                  className="accnt_btn"
                  style={{ backgroundColor: "#000" }}
                >
                  Back
                </Link>
              </div>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav customulresponsivecss"></ul>
              </div>
            </nav>
          </div>
        </div>
      </header>
      <div className="box">
        <div className="error alert">
          <div className="alert-body">
            <h4>Oops! Payment Unsuccessful !</h4>
          </div>
        </div>
      </div>
    </>
  );
};
export default Cancel;
