import React, { useEffect, useState } from "react";
import $ from "jquery";
import { Select2 } from "select2";
import { Link, useNavigate } from "react-router-dom";
import FooterTwo from "../Footer/FooterTwo";
import { Helmet } from "react-helmet";
import Header from "../Header.js";

const Home = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [navState, setNavState] = useState(0);

  const handleToggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const [accordionStates, setAccordionStates] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const handleAccordionToggle = (index) => {
    setAccordionStates((prevState) =>
      prevState.map((state, i) => (i === index ? !state : false))
    );
  };
  const navigate = useNavigate();

  useEffect(() => {
    const protocol = window.location.protocol;
    const host = window.location.host;

    const home = "/Account";
    const newUrl = `${protocol}//${host}${home}`;
    const targetUrl = `${protocol}//${host}/`;

    // Check if the host is localhost or an IP address
    const isLocalhost =
      host === "toolboxpos.com" ||
      host === "localhost" ||
      host === "127.0.0.1" ||
      host === "0.0.0.0" ||
      /^(\d{1,3}\.){3}\d{1,3}$/.test(host);

    // Check if the host has a subdomain
    const hostParts = host.split(".");
    const hasSubdomain =
      !isLocalhost && hostParts.length > 1 && hostParts[0] !== "toolboxpos.com";

    if (window.location.href === targetUrl && hasSubdomain) {
      window.location.href = newUrl; // Navigate to the new URL
    } else {
      //console.log("Condition not met: No navigation");
    }

    const counters = document.querySelectorAll(".counter");

    counters.forEach((counter) => {
      counter.innerText = "0";
      const updateCounter = () => {
        const target = +counter.getAttribute("data-target");
        const count = +counter.innerText;
        const increment = target / 200;
        if (count < target) {
          counter.innerText = `${Math.ceil(count + increment)}`;
          setTimeout(updateCounter, 1);
        } else counter.innerText = target;
      };
      updateCounter();
    });

    // window.location.href = "https://app.toolboxpos.com/";
    // const protocol = window.location.protocol;
    // const host = window.location.host;
    // const subdomain = host.split(".")[0];

    // setSub(subdomain);
    // checkLoginSubdomain(subdomain);
    const disableBack = () => {
      window.history.forward();
    };

    disableBack();

    const handlePopstate = () => {
      disableBack();
    };

    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <>
      <div className="main-wrapper custom-mainwrapper">
        <Helmet>
          <title>Powerful POS Software for Repair Shops | ToolboxPOS</title>
          <meta
            name="description"
            content="Transform your retail management with ToolboxPOS – a comprehensive point-of-sale solution tailored for repair shops and technicians. Boost efficiency and sales today."
          />
        </Helmet>
        {/* <Header /> */}
        <header className="header">
          <div className="container">
            <div className="header_inner">
              <div className="logo">
                <Link to="/">
                  <img alt="Toolbox Logo" src="img/toolbox-logo.webp" />
                </Link>
              </div>
              <nav className="navbar navbar-expand-lg custom_navbar cstmnavbarresponscss navcustomcssnew">
                <Link className="navbar-brand" to="/">
                  <img alt="Toolbox Logo" src="img/toolbox-logo.webp" />
                </Link>
                <button
                  className="navbar-toggler"
                  type="button"
                  aria-expanded={!isNavOpen}
                  onClick={() => handleToggleNav()}
                >
                  <span className="navbar-toggler-icon" />
                </button>
                <div className="account_btns">
                  <Link to="/Login" className="cus_link">
                    Login
                  </Link>
                  <Link to="/Signup" className="accnt_btn">
                    Open Account
                  </Link>
                </div>
                <div
                  className={`collapse navbar-collapse ${
                    isNavOpen ? "show" : "collapse navbar-collapse"
                  }`}
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav customulresponsivecss">
                    {navState === 0 ? (
                      <li className="nav-item" onClick={() => setNavState(0)}>
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/"
                          style={{ color: "#000" }}
                        >
                          Home
                        </Link>
                      </li>
                    ) : (
                      <li className="nav-item" onClick={() => setNavState(0)}>
                        <Link
                          className="nav-link "
                          aria-current="page"
                          to="/"
                          style={{ color: "#000" }}
                        >
                          Home
                        </Link>
                      </li>
                    )}
                    {navState === 1 ? (
                      <li className="nav-item" onClick={() => setNavState(1)}>
                        <Link
                          className="nav-link active"
                          style={{ color: "#000" }}
                        >
                          Features
                        </Link>
                      </li>
                    ) : (
                      <li className="nav-item" onClick={() => setNavState(1)}>
                        <Link className="nav-link " style={{ color: "#000" }}>
                          Features
                        </Link>
                      </li>
                    )}
                    {navState === 2 ? (
                      <li className="nav-item" onClick={() => setNavState(2)}>
                        <Link
                          className="nav-link active"
                          style={{ color: "#000" }}
                        >
                          Industries
                        </Link>
                      </li>
                    ) : (
                      <li className="nav-item" onClick={() => setNavState(2)}>
                        <Link className="nav-link" style={{ color: "#000" }}>
                          Industries
                        </Link>
                      </li>
                    )}
                    {navState === 3 ? (
                      <li className="nav-item" onClick={() => setNavState(3)}>
                        <Link
                          className="nav-link active"
                          style={{ color: "#000" }}
                        >
                          Pricing
                        </Link>
                      </li>
                    ) : (
                      <li className="nav-item" onClick={() => setNavState(3)}>
                        <Link className="nav-link" style={{ color: "#000" }}>
                          Pricing
                        </Link>
                      </li>
                    )}
                    {navState === 4 ? (
                      <li className="nav-item" onClick={() => setNavState(4)}>
                        <Link
                          className="nav-link active"
                          style={{ color: "#000" }}
                        >
                          Blog
                        </Link>
                      </li>
                    ) : (
                      <li className="nav-item" onClick={() => setNavState(4)}>
                        <Link className="nav-link" style={{ color: "#000" }}>
                          Blog
                        </Link>
                      </li>
                    )}
                    {navState === 5 ? (
                      <li className="nav-item" onClick={() => setNavState(5)}>
                        <Link
                          className="nav-link active"
                          style={{ color: "#000" }}
                        >
                          FAQ
                        </Link>
                      </li>
                    ) : (
                      <li className="nav-item" onClick={() => setNavState(5)}>
                        <Link className="nav-link" style={{ color: "#000" }}>
                          FAQ
                        </Link>
                      </li>
                    )}
                    {navState === 6 ? (
                      <li className="nav-item" onClick={() => setNavState(6)}>
                        <Link
                          className="nav-link active"
                          to="/Contact"
                          style={{ color: "#000" }}
                        >
                          Contact Us
                        </Link>
                      </li>
                    ) : (
                      <li className="nav-item" onClick={() => setNavState(6)}>
                        <Link
                          className="nav-link"
                          to="/Contact"
                          style={{ color: "#000" }}
                        >
                          Contact Us
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </header>
        <div className="banner_sec">
          <div className="banner_star1">
            <img src="img/repo_skyblue_icon.webp" alt="" />
          </div>
          <div className="banner_star2">
            <img src="img/repo_skyblue_icon.webp" alt="" />
          </div>
          <div className="container">
            <div className="inner_banner">
              <div className="banner_content">
                <div className="demo_link">
                  <a href="#">
                    View Demo <img src="img/demo-arrow.webp" alt="" />
                  </a>
                </div>
                <h2>
                  All in one platform for your <span>repair business</span>.
                </h2>
                <p>
                  One platform to replace them all, significantly improve your
                  repair shop.
                </p>
                <div className="get_started_btn">
                  <Link to="/Signup">
                    Get Started. It’s FREE{" "}
                    <img src="img/get-started-arrow.webp" alt="" />
                  </Link>
                </div>
                <div className="site_reviews">
                  <div className="stars">
                    <img src="img/star.webp" alt="" />
                    <img src="img/star.webp" alt="" />
                    <img src="img/star.webp" alt="" />
                    <img src="img/star.webp" alt="" />
                    <img src="img/star.webp" alt="" />
                  </div>
                  <span>Based on 100+ reviews from</span>
                  <div className="star_g2icons">
                    <img src="img/green-star.webp" alt="" />
                    <img src="img/g2-icon.webp" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard_sec">
          <div className="dash_shape_sm">
            <img src="img/shape-sm.webp" alt="" />
          </div>
          <div className="dash_repoicon">
            <img src="img/repo_wht_icon.webp" alt="" />
          </div>
          <div className="dash_shape_lg">
            <img src="img/shape-lg.webp" alt="" />
          </div>
          <div className="container-fluid">
            <img className="dash_img" src="img/dashboard-img.webp" alt="" />
          </div>
        </div>
        <div className="trail_sec">
          <div className="container">
            <div className="inner_trail">
              <div className="trail_heading">
                <h2>
                  Toolbox is a powerful <span>all-in-one</span> retail
                  Management &amp; Point-of-sale Software designed for repair
                  shops and techs.{" "}
                  <img
                    className="trail_repo_icon"
                    src="img/repo_skyblue_icon.webp"
                    alt=""
                  />
                </h2>
                <div className="trail_link">
                  <Link to="/Signup">Try toolbox free for 14 days</Link>{" "}
                  <img src="img/trail-arrow.webp" alt="" />
                </div>
              </div>
              <div className="trail_image">
                <img src="img/trail-machine.webp" alt="" />
              </div>
            </div>
            <div className="counters">
              <div className="counter_col">
                <div className="counter_text">
                  <strong className="counter" data-target={700}>
                    +
                  </strong>
                  <span className="spantext">Business who use toolbox</span>
                </div>
              </div>
              <div className="counter_col">
                <div className="counter_text">
                  <strong className="counter" data-target={2400}>
                    +
                  </strong>
                  <span className="spantext">
                    Different business locations using toolbox
                  </span>
                </div>
              </div>
              <div className="counter_col">
                <div className="counter_text">
                  <strong className="counter" data-target={48}>
                    +
                  </strong>
                  <span className="spantext">Transactions done on toolbox</span>
                </div>
              </div>
              <div className="counter_col">
                <div className="counter_text">
                  <strong className="counter" data-target={25}>
                    +
                  </strong>
                  <span className="spantext">Countries with customers</span>
                </div>
              </div>
            </div>
            <div className="reviews_btns">
              <img
                className="review_img"
                src="img/google-review-img.webp"
                alt=""
              />
              <img
                className="trustpilot_img"
                src="img/trustpilot-review-img.webp"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="our_feature_sec">
          <div className="container">
            <div className="inner_feature">
              <div className="feature_heading">
                <h2>
                  Take A Look At Our Main Set Of <span>Features</span>.
                </h2>
                <div className="browse_feature_btn">
                  <a href="#">
                    Browse Features <img src="img/browse-arrow.webp" alt="" />
                  </a>
                </div>
              </div>
              <div className="feature_list">
                <div className="feature_column">
                  <div className="feature_icon">
                    <img src="img/feat-pos-icon.webp" alt="" />
                  </div>
                  <div className="feature_arrow">
                    <img src="img/feature-list-arrow.webp" alt="" />
                  </div>
                  <div className="feature_content">
                    <h4>Point-of-sale</h4>
                    <p>
                      Effortless transactions, inventory management, and
                      customer interactions for a seamless retail experience.
                    </p>
                  </div>
                </div>
                <div className="feature_column">
                  <div className="feature_icon">
                    <img src="img/feat-inventory-icon.webp" alt="" />
                  </div>
                  <div className="feature_arrow">
                    <img src="img/feature-list-arrow.webp" alt="" />
                  </div>
                  <div className="feature_content">
                    <h4>Inventory Control</h4>
                    <p>
                      Efficient tracking, control, and optimization of inventory
                      for streamlined operations and inventory accuracy.
                    </p>
                  </div>
                </div>
                <div className="feature_column">
                  <div className="feature_icon">
                    <img src="img/feat-customer-icon.webp" alt="" />
                  </div>
                  <div className="feature_arrow">
                    <img src="img/feature-list-arrow.webp" alt="" />
                  </div>
                  <div className="feature_content">
                    <h4>Customer management</h4>
                    <p>
                      Centralized data for personalized service, enhancing
                      relationships through streamlined interactions.
                    </p>
                  </div>
                </div>
                <div className="feature_column">
                  <div className="feature_icon">
                    <img src="img/feat-repairtkt-icon.webp" alt="" />
                  </div>
                  <div className="feature_arrow">
                    <img src="img/feature-list-arrow.webp" alt="" />
                  </div>
                  <div className="feature_content">
                    <h4>Repair Ticketing System</h4>
                    <p>
                      Simplify repair requests with easy ticket creation,
                      management, and tracking for efficient customer solutions.
                    </p>
                  </div>
                </div>
                <div className="feature_column">
                  <div className="feature_icon">
                    <img src="img/feat-repairtrack-icon.webp" alt="" />
                  </div>
                  <div className="feature_arrow">
                    <img src="img/feature-list-arrow.webp" alt="" />
                  </div>
                  <div className="feature_content">
                    <h4>Repair Tracking</h4>
                    <p>
                      Real-time monitoring of repair statuses, ensuring
                      transparency and optimizing workflow.
                    </p>
                  </div>
                </div>
                <div className="feature_column">
                  <div className="feature_icon">
                    <img src="img/feat-analytic-icon.webp" alt="" />
                  </div>
                  <div className="feature_arrow">
                    <img src="img/feature-list-arrow.webp" alt="" />
                  </div>
                  <div className="feature_content">
                    <h4>Analytics &amp; Reports</h4>
                    <p>
                      Access insights, analyze trends, and make informed
                      decisions for improved business performance.
                    </p>
                  </div>
                </div>
                <div className="feature_column">
                  <div className="feature_icon">
                    <img src="img/feat-emailsms-icon.webp" alt="" />
                  </div>
                  <div className="feature_arrow">
                    <img src="img/feature-list-arrow.webp" alt="" />
                  </div>
                  <div className="feature_content">
                    <h4>Email &amp; SMS</h4>
                    <p>
                      Effortless email and SMS communication for seamless and
                      efficient customer interaction and engagement.
                    </p>
                  </div>
                </div>
                <div className="feature_column">
                  <div className="feature_icon">
                    <img src="img/feat-imeiserial-icon.webp" alt="" />
                  </div>
                  <div className="feature_arrow">
                    <img src="img/feature-list-arrow.webp" alt="" />
                  </div>
                  <div className="feature_content">
                    <h4>IMEI/Serial Management</h4>
                    <p>
                      Efficient IMEI/serial management for tracking,
                      organization, and streamlined handling of device
                      information and specifications.
                    </p>
                  </div>
                </div>
                <div className="feature_column">
                  <div className="feature_icon">
                    <img src="img/feat-employmngt-icon.webp" alt="" />
                  </div>
                  <div className="feature_arrow">
                    <img src="img/feature-list-arrow.webp" alt="" />
                  </div>
                  <div className="feature_content">
                    <h4>Employee Management</h4>
                    <p>
                      Efficient employee management, with shift planning and
                      performance monitoring for enhanced productivity.
                    </p>
                  </div>
                </div>
                <div className="feature_column">
                  <div className="feature_icon">
                    <img src="img/feat-appointment.webp" alt="" />
                  </div>
                  <div className="feature_arrow">
                    <img src="img/feature-list-arrow.webp" alt="" />
                  </div>
                  <div className="feature_content">
                    <h4>Appointments</h4>
                    <p>
                      Streamlined bookings, reminders, and staff optimization
                      for organized and efficient appointment management.
                    </p>
                  </div>
                </div>
              </div>
              <div className="clearfix" />
            </div>
          </div>
        </div>
        <div className="user_interface_sec">
          <div className="container">
            <div className="inner_interface">
              <div className="row">
                <div className="col-lg-5 col-md-12">
                  <div className="interface_left">
                    <h3>
                      Easy-to-use User <span>Interface</span> &amp; Design
                    </h3>
                    <p>
                      Toolbox was purposefully crafted to address and improve
                      upon the common flaws and impractical designs found in 99%
                      of platforms. It enhances overall functionality for a more
                      comprehensive and effective user experience.
                    </p>
                    <div className="interface_list">
                      <div className="interface_col">
                        <div className="int_icon">
                          <img src="img/diamond-icon.webp" alt="" />
                        </div>
                        <div className="int_content">
                          <h5>Monthly updates</h5>
                          <span>
                            We always are upgrading and adding more features!
                          </span>
                        </div>
                      </div>
                      <div className="interface_col">
                        <div className="int_icon">
                          <img src="img/video-icon.webp" alt="" />
                        </div>
                        <div className="int_content">
                          <h5>Add products easily</h5>
                          <span>See our simple tutorial</span>
                        </div>
                      </div>
                      <div className="interface_col">
                        <div className="int_icon">
                          <img src="img/video-icon.webp" alt="" />
                        </div>
                        <div className="int_content">
                          <h5>Super customizable</h5>
                          <span>See our simple tutorial</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-md-12">
                  <div className="interface_right">
                    <img src="img/user-interface-img.webp" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="industry_serve_sec">
          <div className="inner_indstry_serve">
            <div className="indstry_serve_left">
              <div className="industry_repowht">
                <img src="img/repo_wht_icon.webp" alt="" />
              </div>
              <h3>
                Industries we <span>Serve</span>
              </h3>
              <p>
                Lorem Ipsum is simply dummy text of the typesetting industry
              </p>
              <div className="industry_arrow">
                <img src="img/industry-arrow-img.webp" alt="" />
              </div>
              <div className="industry_reposkyblue">
                <img src="img/repo_skyblue_icon.webp" alt="" />
              </div>
            </div>
            <div className="indstry_serve_right">
              <div className="serve_list">
                <div className="serve_col">
                  <h5>Cell phone &amp; Repair shops</h5>
                  <p>
                    Effortless email and SMS communication for seamless and
                    efficient customer
                  </p>
                  <div className="learnmore">
                    <a href="#">
                      Learn More <img src="img/industry-arrow.webp" alt="" />
                    </a>
                  </div>
                </div>
                <div className="serve_col">
                  <h5>Computer &amp; Repair shops</h5>
                  <p>
                    Effortless email and SMS communication for seamless and
                    efficient customer
                  </p>
                  <div className="learnmore">
                    <a href="#">
                      Learn More <img src="img/industry-arrow.webp" alt="" />
                    </a>
                  </div>
                </div>
                <div className="serve_col">
                  <h5>Electronics &amp; Repair shops</h5>
                  <p>
                    Effortless email and SMS communication for seamless and
                    efficient customer
                  </p>
                  <div className="learnmore">
                    <a href="#">
                      Learn More <img src="img/industry-arrow.webp" alt="" />
                    </a>
                  </div>
                </div>
                <div className="serve_col">
                  <h5>Jewellery Repair</h5>
                  <p>
                    Effortless email and SMS communication for seamless and
                    efficient customer
                  </p>
                  <div className="learnmore">
                    <a href="#">
                      Learn More <img src="img/industry-arrow.webp" alt="" />
                    </a>
                  </div>
                </div>
                <div className="serve_col">
                  <h5>Watch Repair</h5>
                  <p>
                    Effortless email and SMS communication for seamless and
                    efficient customer
                  </p>
                  <div className="learnmore">
                    <a href="#">
                      Learn More <img src="img/industry-arrow.webp" alt="" />
                    </a>
                  </div>
                </div>
                <div className="serve_col">
                  <h5>Drone Repair</h5>
                  <p>
                    Effortless email and SMS communication for seamless and
                    efficient customer
                  </p>
                  <div className="learnmore">
                    <a href="#">
                      Learn More <img src="img/industry-arrow.webp" alt="" />
                    </a>
                  </div>
                </div>
                <div className="serve_col">
                  <h5>Retail Bussines</h5>
                  <p>
                    Effortless email and SMS communication for seamless and
                    efficient customer
                  </p>
                  <div className="learnmore">
                    <a href="#">
                      Learn More <img src="img/industry-arrow.webp" alt="" />
                    </a>
                  </div>
                </div>
                <div className="serve_col">
                  <h5>Whole Sale Bussines</h5>
                  <p>
                    Effortless email and SMS communication for seamless and
                    efficient customer
                  </p>
                  <div className="learnmore">
                    <a href="#">
                      Learn More <img src="img/industry-arrow.webp" alt="" />
                    </a>
                  </div>
                </div>
                <div className="serve_col">
                  <h5>Independent</h5>
                  <p>
                    Effortless email and SMS communication for seamless and
                    efficient customer
                  </p>
                  <div className="learnmore">
                    <a href="#">
                      Learn More <img src="img/industry-arrow.webp" alt="" />
                    </a>
                  </div>
                </div>
                <div className="serve_col">
                  <h5>Cell phone &amp; Repair shops</h5>
                  <p>
                    Effortless email and SMS communication for seamless and
                    efficient customer
                  </p>
                  <div className="learnmore">
                    <a href="#">
                      Learn More <img src="img/industry-arrow.webp" alt="" />
                    </a>
                  </div>
                </div>
                <div className="serve_col">
                  <h5>Cell phone &amp; Repair shops</h5>
                  <p>
                    Effortless email and SMS communication for seamless and
                    efficient customer
                  </p>
                  <div className="learnmore">
                    <a href="#">
                      Learn More <img src="img/industry-arrow.webp" alt="" />
                    </a>
                  </div>
                </div>
                <div className="serve_col">
                  <h5>Cell phone &amp; Repair shops</h5>
                  <p>
                    Effortless email and SMS communication for seamless and
                    efficient customer
                  </p>
                  <div className="learnmore">
                    <a href="#">
                      Learn More <img src="img/industry-arrow.webp" alt="" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="clearfix" />
            </div>
          </div>
        </div>
        <div className="workseamless_sec">
          <div className="container">
            <div className="inner_seamless">
              <div className="seamless_top">
                <div className="seamless_left">
                  <h4>
                    Work <span>Seamlessly</span> with Integrations
                  </h4>
                  <p>
                    Lorem Ipsum is simply dummy text of the typesetting
                    industry. Lorem Ipsum has been the industry's standard text
                    ever since when an unknown printer.
                  </p>
                </div>
                <div className="seamless_right">
                  <div className="quickbook_txt">
                    <span>Keep your data synced with QuickBooks </span>
                  </div>
                  <div className="quickbook_img">
                    <img src="img/quickbook-img.webp" alt="" />
                  </div>
                </div>
              </div>
              <div className="seamless_middle">
                <div className="google_calendar">
                  <img src="img/google-calendar-img.webp" alt="" />
                  <img src="img/google-calendar.webp" alt="" />
                </div>
                <div className="support_hardware">
                  <div className="support_hardware_txt">
                    <span>Support for all hardare</span>
                    <a href="#">
                      Get Started{" "}
                      <img src="img/seam-started-arrow.webp" alt="" />
                    </a>
                  </div>
                  <div className="support_hardware_image">
                    <img src="img/support-hrdware1.webp" alt="" />
                    <img src="img/support-hrdware2.webp" alt="" />
                  </div>
                </div>
                <div className="square_box">
                  <img src="img/square-box.webp" alt="" />
                  <img src="img/square-text.webp" alt="" />
                </div>
              </div>
              <div className="seamless_bottom">
                <div className="seam_mailchimp">
                  <div className="mailchimp_img">
                    <img src="img/mailchimp.webp" alt="" />
                  </div>
                  <div className="mailchimp_text">
                    <span>Keep your mail list Updated</span>
                    <a href="#">Join Toolbox</a>
                  </div>
                </div>
                <div className="seam_imgformat">
                  <span>More Coming Soon...</span>
                  <ul>
                    <li>
                      <img src="img/image-icon.webp" alt="" />
                    </li>
                    <li>
                      <img src="img/video-icon.webp" alt="" />
                    </li>
                    <li>
                      <img src="img/audio-icon.webp" alt="" />
                    </li>
                    <li>
                      <img src="img/gif-icon.webp" alt="" />
                    </li>
                  </ul>
                  <div className="view_all">
                    <a href="#">
                      View All <img src="img/seam-started-arrow.webp" alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pricing_plan_sec">
          <div className="container">
            <div className="inner_pricingplan">
              <div className="price_title">
                <h5>Pricing</h5>
                <h3>
                  Our <span>transparent</span> pricing plans
                </h3>
                <p>
                  Lowest pricing in the industry! No gimmicks. NO credit card
                  required for trial.
                  <br /> NO committment. Cancel Anytime.
                </p>
                <div className="currency_choose">
                  <span className="cur_txt">Choose your currency </span>
                  <div className="dropdown">
                    <a
                      // href="javascript:;"
                      data-bs-toggle="dropdown"
                      className="dropdown-toggle"
                      href="#"
                      onClick={(e) => e.preventDefault()}
                    >
                      <img src="img/usd-flag.webp" alt="" /> <span>USD</span>{" "}
                      <img src="img/currency-arrow.webp" alt="" />
                    </a>
                    <div className="dropdown-menu">
                      <a href="#" onClick={(e) => e.preventDefault()}>
                        <img src="img/usd-flag.webp" alt="" /> USD
                      </a>
                      <a href="#" onClick={(e) => e.preventDefault()}>
                        <img src="img/eur-flag.webp" alt="" /> EUR
                      </a>
                      <a href="#" onClick={(e) => e.preventDefault()}>
                        <img src="img/gbp-flag.webp" alt="" /> GBP
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="price_planbox">
                <div className="plan_trail common_plan">
                  <div className="plan_title">
                    <div className="plan_logo">
                      <img src="img/trail-plan-icon.webp" alt="" />
                    </div>
                    <div className="plan_ttl">
                      <h4>
                        <span>ToolKit</span> 14 Day Trial
                      </h4>
                    </div>
                  </div>
                  <div className="plan_desc">
                    <p>
                      Lorem ipsum dolor sit amet doloroli sitiol conse ctetur
                      adipiscing elit.{" "}
                    </p>
                  </div>
                  <div className="plan_price">
                    <span>
                      $0 <small>/monthly</small>
                    </span>
                  </div>
                  <div className="plan_feature">
                    <p>What’s included</p>
                    <ul>
                      <li>
                        <img src="img/trail-check-circle.webp" alt="" /> All
                        analytics features
                      </li>
                      <li>
                        <img src="img/trail-check-circle.webp" alt="" />
                        Up to 250,000 tracked visits
                      </li>
                      <li>
                        <img src="img/trail-check-circle.webp" alt="" />
                        Normal support
                      </li>
                      <li>
                        <img src="img/trail-check-circle.webp" alt="" />
                        Up to 3 team members
                      </li>
                    </ul>
                  </div>
                  <div className="plan_started_btn">
                    <button type="button" className="cus_btn">
                      Get Started
                    </button>
                  </div>
                </div>
                <div className="plan_pro common_plan">
                  <div className="plan_title">
                    <div className="plan_logo">
                      <img src="img/pro-plan-icon.webp" alt="" />
                    </div>
                    <div className="plan_ttl">
                      <h4>
                        <span>ToolChest</span> Pro
                      </h4>
                    </div>
                  </div>
                  <div className="popular_btn">
                    <button type="button" className="cus_btn">
                      Popular
                    </button>
                  </div>
                  <div className="plan_desc">
                    <p>
                      Lorem ipsum dolor sit amet doloroli sitiol conse ctetur
                      adipiscing elit.{" "}
                    </p>
                  </div>
                  <div className="plan_price">
                    <span>
                      $39 <small>/monthly/Per Store</small>
                    </span>
                  </div>
                  <div className="plan_feature">
                    <p>What’s included</p>
                    <ul>
                      <li>
                        <img src="img/pro-check-circle.webp" alt="" /> All
                        analytics features
                      </li>
                      <li>
                        <img src="img/pro-check-circle.webp" alt="" /> Up to
                        1,000,000 tracked visits
                      </li>
                      <li>
                        <img src="img/pro-check-circle.webp" alt="" /> Premium
                        support
                      </li>
                      <li>
                        <img src="img/pro-check-circle.webp" alt="" /> Up to 10
                        team members
                      </li>
                      <li>
                        <img src="img/pro-check-circle.webp" alt="" /> All
                        analytics features
                      </li>
                      <li>
                        <img src="img/pro-check-circle.webp" alt="" /> Up to
                        1,000,000 tracked visits
                      </li>
                      <li>
                        <img src="img/pro-check-circle.webp" alt="" /> Premium
                        support
                      </li>
                      <li>
                        <img src="img/pro-check-circle.webp" alt="" /> Up to 10
                        team members
                      </li>
                    </ul>
                  </div>
                  <div className="plan_started_btn">
                    <button type="button" className="cus_btn">
                      Get Started
                    </button>
                  </div>
                </div>
              </div>
              <div className="clearfix" />
              <div className="pricing_notes">
                <ul>
                  <li>
                    <img src="img/no-commitment-icon.webp" alt="" />{" "}
                    <span>No Commitment</span>
                  </li>
                  <li>
                    <img src="img/cancel-anytime-icon.webp" alt="" />{" "}
                    <span>Cancel anytime</span>
                  </li>
                  <li>
                    <img src="img/no-hiddenfile-icon.webp" alt="" />{" "}
                    <span>No hidden fees.</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="latestnews_sec">
          <div className="container">
            <div className="inner_newsstory">
              <div className="title">
                <h3>
                  Latest <span>News &amp; Stories</span>
                </h3>
                <a href="#">
                  All Article <img src="img/article-arrow.webp" alt="" />
                </a>
              </div>
              <div className="newstory_list">
                <div className="story_column">
                  <div className="story_img">
                    <img src="img/news-img1.webp" alt="" />
                  </div>
                  <div className="story_content">
                    <h4>How To Start Using Banko For Your Startup</h4>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Dui accumsan sit amet nulla facilisi morbi.
                    </p>
                    <div className="story_category">
                      <ul>
                        <li>
                          <a href="#">Product</a>
                        </li>
                        <li>
                          <a href="#">Technology</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="story_column">
                  <div className="story_img">
                    <img src="img/news-img2.webp" alt="" />
                  </div>
                  <div className="story_content">
                    <h4>10 Things Nobody Told You About Banko</h4>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Dui accumsan sit amet nulla facilisi morbi.
                    </p>
                    <div className="story_category">
                      <ul>
                        <li>
                          <a href="#">Product</a>
                        </li>
                        <li>
                          <a href="#">Technology</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="story_column">
                  <div className="story_img">
                    <img src="img/news-img3.webp" alt="" />
                  </div>
                  <div className="story_content">
                    <h4>7 Ways To Improve You Saving Habits</h4>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Dui accumsan sit amet nulla facilisi morbi.
                    </p>
                    <div className="story_category">
                      <ul>
                        <li>
                          <a href="#">Product</a>
                        </li>
                        <li>
                          <a href="#">Technology</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="story_column">
                  <div className="story_img">
                    <img src="img/news-img3.webp" alt="" />
                  </div>
                  <div className="story_content">
                    <h4>7 Ways To Improve You Saving Habits</h4>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Dui accumsan sit amet nulla facilisi morbi.
                    </p>
                    <div className="story_category">
                      <ul>
                        <li>
                          <a href="#">Product</a>
                        </li>
                        <li>
                          <a href="#">Technology</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix" />
            </div>
          </div>
        </div>
        <div className="freq_ques_sec">
          <div className="container">
            <div className="inner_freq_ques">
              <div className="freq_heading">
                <h3>
                  Frequently asked <span>questions</span>
                </h3>
                <p>
                  if you cannot find answer to your question in our FAQ, you can
                  always <b>contact us</b>. We will answer to you shortly!
                </p>
              </div>
              <div className="freq_ques_list">
                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="general-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#general"
                      type="button"
                      role="tab"
                      aria-controls="general"
                      aria-selected="true"
                    >
                      General
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="hardware-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#hardware"
                      type="button"
                      role="tab"
                      aria-controls="hardware"
                      aria-selected="false"
                    >
                      Hardware
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="payments-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#payments"
                      type="button"
                      role="tab"
                      aria-controls="payments"
                      aria-selected="false"
                    >
                      Payments
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="general"
                    role="tabpanel"
                    aria-labelledby="general-tab"
                    tabIndex={0}
                  >
                    <div className="freq_ques_column">
                      <div className="freq_left">
                        <img src="img/faq_image.webp" alt="" />
                      </div>
                      <div className="freq_right">
                        <div className="freq_ques_collapse">
                          <div className="collapse_col">
                            <a
                              className={`btn collpse_btn${
                                accordionStates[0] ? "" : "collapsed"
                              }btn collpse_btn`}
                              role="button"
                              aria-controls="collapse1"
                              onClick={() => handleAccordionToggle(0)}
                              aria-expanded={accordionStates[0]}
                            >
                              <span>How do I cancel my plan?</span>{" "}
                              <img src="img/plus-sign.webp" alt="" />
                            </a>
                            <div
                              className={`collapse multi-collapse ${
                                accordionStates[0] ? "show" : ""
                              }`}
                              id="collapse1"
                            >
                              <div className="content_body">
                                <p>
                                  Some placeholder content for the first
                                  collapse component of this multi-collapse
                                  example. This panel is hidden by default but
                                  revealed when the user activates the relevant
                                  trigger.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="collapse_col">
                            <a
                              className={`btn collpse_btn${
                                accordionStates[1] ? "" : "collapsed"
                              }btn collpse_btn`}
                              role="button"
                              aria-controls="collapse2"
                              onClick={() => handleAccordionToggle(1)}
                              aria-expanded={accordionStates[1]}
                            >
                              <span>
                                Will Toolbox work with my existing hardware?
                              </span>{" "}
                              <img src="img/plus-sign.webp" alt="" />
                            </a>
                            <div
                              className={`collapse multi-collapse ${
                                accordionStates[1] ? "show" : ""
                              }`}
                              id="collapse2"
                            >
                              <div className="content_body">
                                <p>
                                  Some placeholder content for the first
                                  collapse component of this multi-collapse
                                  example. This panel is hidden by default but
                                  revealed when the user activates the relevant
                                  trigger.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="collapse_col">
                            <a
                              className={`btn collpse_btn${
                                accordionStates[2] ? "" : "collapsed"
                              }btn collpse_btn`}
                              role="button"
                              aria-controls="collapse3"
                              onClick={() => handleAccordionToggle(2)}
                              aria-expanded={accordionStates[2]}
                            >
                              <span>Who can use Toolbox?</span>{" "}
                              <img src="img/plus-sign.webp" alt="" />
                            </a>
                            <div
                              className={`collapse multi-collapse ${
                                accordionStates[2] ? "show" : ""
                              }`}
                              id="collapse3"
                            >
                              <div className="content_body">
                                <p>
                                  Some placeholder content for the first
                                  collapse component of this multi-collapse
                                  example. This panel is hidden by default but
                                  revealed when the user activates the relevant
                                  trigger.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="collapse_col">
                            <a
                              className={`btn collpse_btn${
                                accordionStates[3] ? "" : "collapsed"
                              }btn collpse_btn`}
                              role="button"
                              onClick={() => handleAccordionToggle(3)}
                              aria-expanded={accordionStates[3]}
                              aria-controls="collapse4"
                            >
                              <span>How does the Point of sale work?</span>{" "}
                              <img src="img/plus-sign.webp" alt="" />
                            </a>
                            <div
                              className={`collapse multi-collapse ${
                                accordionStates[3] ? "show" : ""
                              }`}
                              id="collapse4"
                            >
                              <div className="content_body">
                                <p>
                                  Some placeholder content for the first
                                  collapse component of this multi-collapse
                                  example. This panel is hidden by default but
                                  revealed when the user activates the relevant
                                  trigger.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="collapse_col">
                            <a
                              className={`btn collpse_btn${
                                accordionStates[4] ? "" : "collapsed"
                              }btn collpse_btn`}
                              role="button"
                              onClick={() => handleAccordionToggle(4)}
                              aria-expanded={accordionStates[4]}
                              aria-controls="collapse4"
                            >
                              <span>How does the Point of sale work?</span>{" "}
                              <img src="img/plus-sign.webp" alt="" />
                            </a>
                            <div
                              className={`collapse multi-collapse ${
                                accordionStates[4] ? "show" : ""
                              }`}
                              id="collapse4"
                            >
                              <div className="content_body">
                                <p>
                                  Some placeholder content for the first
                                  collapse component of this multi-collapse
                                  example. This panel is hidden by default but
                                  revealed when the user activates the relevant
                                  trigger.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="clearfix" />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="hardware"
                    role="tabpanel"
                    aria-labelledby="hardware-tab"
                    tabIndex={0}
                  >
                    <div className="freq_ques_column">
                      <div className="freq_left">
                        <img src="img/faq_image.webp" alt="" />
                      </div>
                      <div className="freq_right">
                        <div className="freq_ques_collapse">
                          <div className="collapse_col">
                            <a
                              // className="btn collpse_btn"
                              className={`btn collpse_btn${
                                accordionStates[5] ? "" : "collapsed"
                              }btn collpse_btn`}
                              // data-bs-toggle="collapse"
                              // href="#collapse1"
                              role="button"
                              // aria-expanded="false"
                              aria-controls="collapse1"
                              onClick={() => handleAccordionToggle(5)}
                              aria-expanded={accordionStates[5]}
                            >
                              <span>How do I cancel my plan?</span>{" "}
                              <img src="img/plus-sign.webp" alt="" />
                            </a>
                            <div
                              className={`collapse multi-collapse ${
                                accordionStates[5] ? "show" : ""
                              }`}
                              id="collapse1"
                            >
                              <div className="content_body">
                                <p>
                                  Some placeholder content for the first
                                  collapse component of this multi-collapse
                                  example. This panel is hidden by default but
                                  revealed when the user activates the relevant
                                  trigger.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="collapse_col">
                            <a
                              className={`btn collpse_btn${
                                accordionStates[6] ? "" : "collapsed"
                              }btn collpse_btn`}
                              onClick={() => handleAccordionToggle(6)}
                              aria-expanded={accordionStates[6]}
                              role="button"
                              aria-controls="collapse2"
                            >
                              <span>
                                Will Toolbox work with my existing hardware?
                              </span>{" "}
                              <img src="img/plus-sign.webp" alt="" />
                            </a>
                            <div
                              className={`collapse multi-collapse ${
                                accordionStates[6] ? "show" : ""
                              }`}
                              id="collapse2"
                            >
                              <div className="content_body">
                                <p>
                                  Some placeholder content for the first
                                  collapse component of this multi-collapse
                                  example. This panel is hidden by default but
                                  revealed when the user activates the relevant
                                  trigger.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="collapse_col">
                            <a
                              className={`btn collpse_btn${
                                accordionStates[7] ? "" : "collapsed"
                              }btn collpse_btn`}
                              onClick={() => handleAccordionToggle(7)}
                              aria-expanded={accordionStates[7]}
                              role="button"
                              aria-controls="collapse3"
                            >
                              <span>Who can use Toolbox?</span>{" "}
                              <img src="img/plus-sign.webp" alt="" />
                            </a>
                            <div
                              className={`collapse multi-collapse ${
                                accordionStates[7] ? "show" : ""
                              }`}
                              id="collapse3"
                            >
                              <div className="content_body">
                                <p>
                                  Some placeholder content for the first
                                  collapse component of this multi-collapse
                                  example. This panel is hidden by default but
                                  revealed when the user activates the relevant
                                  trigger.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="collapse_col">
                            <a
                              className={`btn collpse_btn${
                                accordionStates[8] ? "" : "collapsed"
                              }btn collpse_btn`}
                              role="button"
                              onClick={() => handleAccordionToggle(8)}
                              aria-expanded={accordionStates[8]}
                              aria-controls="collapse4"
                            >
                              <span>How does the Point of sale work?</span>{" "}
                              <img src="img/plus-sign.webp" alt="" />
                            </a>
                            <div
                              className={`collapse multi-collapse ${
                                accordionStates[8] ? "show" : ""
                              }`}
                              id="collapse4"
                            >
                              <div className="content_body">
                                <p>
                                  Some placeholder content for the first
                                  collapse component of this multi-collapse
                                  example. This panel is hidden by default but
                                  revealed when the user activates the relevant
                                  trigger.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="collapse_col">
                            <a
                              className={`btn collpse_btn${
                                accordionStates[9] ? "" : "collapsed"
                              }btn collpse_btn`}
                              onClick={() => handleAccordionToggle(9)}
                              aria-expanded={accordionStates[9]}
                              role="button"
                              aria-controls="collapse4"
                            >
                              <span>How does the Point of sale work?</span>{" "}
                              <img src="img/plus-sign.webp" alt="" />
                            </a>
                            <div
                              className={`collapse multi-collapse ${
                                accordionStates[9] ? "show" : ""
                              }`}
                              id="collapse4"
                            >
                              <div className="content_body">
                                <p>
                                  Some placeholder content for the first
                                  collapse component of this multi-collapse
                                  example. This panel is hidden by default but
                                  revealed when the user activates the relevant
                                  trigger.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="clearfix" />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="payments"
                    role="tabpanel"
                    aria-labelledby="payments-tab"
                    tabIndex={0}
                  >
                    <div className="freq_ques_column">
                      <div className="freq_left">
                        <img src="img/faq_image.webp" alt="" />
                      </div>
                      <div className="freq_right">
                        <div className="freq_ques_collapse">
                          <div className="collapse_col">
                            <a
                              className={`btn collpse_btn${
                                accordionStates[10] ? "" : "collapsed"
                              }btn collpse_btn`}
                              role="button"
                              onClick={() => handleAccordionToggle(10)}
                              aria-expanded={accordionStates[10]}
                              aria-controls="collapse1"
                            >
                              <span>How do I cancel my plan?</span>{" "}
                              <img src="img/plus-sign.webp" alt="" />
                            </a>
                            <div
                              className={`collapse multi-collapse ${
                                accordionStates[10] ? "show" : ""
                              }`}
                              id="collapse1"
                            >
                              <div className="content_body">
                                <p>
                                  Some placeholder content for the first
                                  collapse component of this multi-collapse
                                  example. This panel is hidden by default but
                                  revealed when the user activates the relevant
                                  trigger.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="collapse_col">
                            <a
                              className={`btn collpse_btn${
                                accordionStates[11] ? "" : "collapsed"
                              }btn collpse_btn`}
                              role="button"
                              onClick={() => handleAccordionToggle(11)}
                              aria-expanded={accordionStates[11]}
                              aria-controls="collapse2"
                            >
                              <span>
                                Will Toolbox work with my existing hardware?
                              </span>{" "}
                              <img src="img/plus-sign.webp" alt="" />
                            </a>
                            <div
                              className={`collapse multi-collapse ${
                                accordionStates[11] ? "show" : ""
                              }`}
                              id="collapse2"
                            >
                              <div className="content_body">
                                <p>
                                  Some placeholder content for the first
                                  collapse component of this multi-collapse
                                  example. This panel is hidden by default but
                                  revealed when the user activates the relevant
                                  trigger.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="collapse_col">
                            <a
                              className={`btn collpse_btn${
                                accordionStates[12] ? "" : "collapsed"
                              }btn collpse_btn`}
                              role="button"
                              onClick={() => handleAccordionToggle(12)}
                              aria-expanded={accordionStates[12]}
                              aria-controls="collapse3"
                            >
                              <span>Who can use Toolbox?</span>{" "}
                              <img src="img/plus-sign.webp" alt="" />
                            </a>
                            <div
                              className={`collapse multi-collapse ${
                                accordionStates[12] ? "show" : ""
                              }`}
                              id="collapse3"
                            >
                              <div className="content_body">
                                <p>
                                  Some placeholder content for the first
                                  collapse component of this multi-collapse
                                  example. This panel is hidden by default but
                                  revealed when the user activates the relevant
                                  trigger.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="collapse_col">
                            <a
                              className={`btn collpse_btn${
                                accordionStates[13] ? "" : "collapsed"
                              }btn collpse_btn`}
                              role="button"
                              onClick={() => handleAccordionToggle(13)}
                              aria-expanded={accordionStates[13]}
                              aria-controls="collapse4"
                            >
                              <span>How does the Point of sale work?</span>{" "}
                              <img src="img/plus-sign.webp" alt="" />
                            </a>
                            <div
                              className={`collapse multi-collapse ${
                                accordionStates[13] ? "show" : ""
                              }`}
                              id="collapse4"
                            >
                              <div className="content_body">
                                <p>
                                  Some placeholder content for the first
                                  collapse component of this multi-collapse
                                  example. This panel is hidden by default but
                                  revealed when the user activates the relevant
                                  trigger.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="collapse_col">
                            <a
                              className={`btn collpse_btn${
                                accordionStates[14] ? "" : "collapsed"
                              }btn collpse_btn`}
                              role="button"
                              onClick={() => handleAccordionToggle(14)}
                              aria-expanded={accordionStates[14]}
                              aria-controls="collapse4"
                            >
                              <span>How does the Point of sale work?</span>{" "}
                              <img src="img/plus-sign.webp" alt="" />
                            </a>
                            <div
                              className={`collapse multi-collapse ${
                                accordionStates[14] ? "show" : ""
                              }`}
                              id="collapse4"
                            >
                              <div className="content_body">
                                <p>
                                  Some placeholder content for the first
                                  collapse component of this multi-collapse
                                  example. This panel is hidden by default but
                                  revealed when the user activates the relevant
                                  trigger.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="clearfix" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ourappdemo_sec">
          <div className="container">
            <div className="inner_appdemo">
              <div className="app_content">
                <h4>
                  One app.
                  <br />
                  One bussiness.
                </h4>
                <p>
                  Freakishly simple. Downright useful. Ready to make the jump?
                </p>
                <ul>
                  <li>
                    <span>
                      <img src="img/ri_check-fill.webp" alt="" />
                    </span>{" "}
                    Instant transactions
                  </li>
                  <li>
                    <span>
                      <img src="img/ri_check-fill.webp" alt="" />
                    </span>{" "}
                    Payments worldwide
                  </li>
                  <li>
                    <span>
                      <img src="img/ri_check-fill.webp" alt="" />
                    </span>{" "}
                    Track &amp; Manage Finances
                  </li>
                  <li>
                    <span>
                      <img src="img/ri_check-fill.webp" alt="" />
                    </span>{" "}
                    100% mobile banking
                  </li>
                </ul>
                <div className="demo_btn">
                  <a href="#">View Demo</a>
                </div>
              </div>
              <div className="app_image">
                <img src="img/apple-macbook-img.webp" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterTwo />
    </>
  );
};
export default Home;
