import React, { useEffect, useState, useRef } from "react";
import $ from "jquery";
import {
  AddBrandApi,
  AddCarrierApi,
  AddColorApi,
  AddConditionApi,
  AddItemApi,
  AddManufactureApi,
  AddProductApi,
  AddStorageApi,
  AddSupplierApi,
  AddTagsApi,
  GetAllBrandApi,
  GetAllColorApi,
  GetAllConditionApi,
  GetAllItemApi,
  GetAllProductApi,
  GetAllStorageApi,
  GetAllSupplierApi,
  GetManufactureApi,
  GetTagsApi,
  baseurl,
  customerAddApi,
  customerGetApi,
  uploadImageApi,
} from "../Utility/Api";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const DeviceModalList = (props) => {
  const [images, setImages] = useState("");
  const [error, setError] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermStorage, setSearchTermStorage] = useState("");
  const [searchTermColor, setsearchTermColor] = useState("");
  const [searchTermTags, setsearchTermTags] = useState("");
  const [searchTermCondition, setSearchTermCondition] = useState("");
  const [searchTermCarrier, setSearchTermCarrier] = useState("");
  const [imageMsg, setImageMsg] = useState("");
  const [showModal4, setShowModal4] = useState(false);
  const [brand, setBrand] = useState("");
  const [AllBrand, setAllBrand] = useState([]);
  const [allColor, setAllColor] = useState([]);
  const [allStorage, setAllStorage] = useState([]);
  const [allCondition, setAllCondition] = useState([]);
  const [allCarrier, setAllCarrier] = useState([]);
  const [supplier, setSupplier] = useState("");
  const [AllSupplier, setAllSupplier] = useState([]);
  const [SKU, setSKU] = useState("");
  const [imeiNumber, setIMEINumber] = useState("");
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [unitCost, setUnitCost] = useState("");
  const [minimumPrice, setMinimunPrice] = useState("");
  const [averageCost, setAverageCost] = useState("");
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [selectedStorageId, setSelectedStorageId] = useState(null);
  const [selectedColorId, setSelectedColorId] = useState(null);
  const [selectedConditionId, setSelectedConditionId] = useState(null);
  const [selectedCarrierId, setSelectedCarrierId] = useState(null);
  const [selectedSupplierId, setSelectedSuppierId] = useState(null);
  const [selectedCondition, setSelectedCondition] = useState(null);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [draftSave, setDraftSave] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errormsg, setErrormsg] = useState("");
  const [searchTermSuppiler, setsearchTermSuppiler] = useState("");
  const [showList, setShowList] = useState(false);
  const [showListStorage, setShowListStorage] = useState(false);
  const [showListColor, setShowListColor] = useState(false);
  const [showListCondition, setShowListCondition] = useState(false);
  const [showListCarrier, setShowListCarrier] = useState(false);
  const [showListAuto, setShowListAuto] = useState(false);
  const [showListTags, setShowListTags] = useState(false);
  //const [searchTerm, setSearchTerm] = useState('');
  const [typedText, setTypedText] = useState("");
  const [selectedStorage, setSelectedStorage] = useState(null);
  const [selectedSuppiler, setSelectedSuppiler] = useState(null);
  const [showListSuppiler, setShowListSuppiler] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [skuFill, setSKUFill] = useState("");
  const [autogenrate, setAutoGenrate] = useState(0);

  const [autogenText, setAutoGenTex] = useState("Select SKU Code");

  const inputRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const dropdownRef = useRef(null);
  const dropdownRef1 = useRef(null);
  const dropdownRef2 = useRef(null);
  const dropdownRef3 = useRef(null);
  const dropdownRef4 = useRef(null);
  const dropdownRef11 = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef11.current &&
        !dropdownRef11.current.contains(event.target)
      ) {
        setShowListAuto(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef11]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowList(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef1.current &&
        !dropdownRef1.current.contains(event.target)
      ) {
        setShowListStorage(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef1]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef2.current &&
        !dropdownRef2.current.contains(event.target)
      ) {
        setShowListColor(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef2]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef3.current &&
        !dropdownRef3.current.contains(event.target)
      ) {
        setShowListCondition(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef3]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef4.current &&
        !dropdownRef4.current.contains(event.target)
      ) {
        setShowListCarrier(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef4]);

  const filteredBrands = AllBrand.filter((brand) => {
    const name = brand.name;

    // Check if 'name' is not null before calling toLowerCase
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTerm.toLowerCase());
    }

    return false; // Or handle null or non-string values as needed
  });

  const handleButtonClick = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowList(!showList);
  };
  const handleButtonClickStorage = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowListStorage(!showListStorage);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setTypedText(e.target.value);
    setSelectedBrand(null);
  };

  const filteredSuppiler = AllSupplier.filter((brand) => {
    const name = brand.name;

    // Check if 'name' is not null before calling toLowerCase
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermSuppiler.toLowerCase());
    }

    return false;
  });

  const handleSupplierSelect = (brand) => {
    setSelectedSuppiler(brand.name);
    setSelectedSuppierId(brand.id);
    setShowListSuppiler(false);
  };

  const handleAddNewClickSupplier = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Manufacturer name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    // e.preventDefault();
    setSelectedSuppiler(typedText);
    setShowListSuppiler(false);
    setsearchTermSuppiler("");
    setTypedText("");
    AddSuppier();
  };

  const handleBrandSelect = (brand) => {
    setSelectedBrand(brand.name);
    setSelectedBrandId(brand.id);
    setShowList(false);
  };

  // const handleInputKeyDown = (e) => {
  //   if (e.key === 'Backspace') {
  //     setShowList(true);
  //   }
  // };
  const handleAddNewClick = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Brand name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    // e.preventDefault();
    setSelectedBrand(typedText);
    setShowList(false);
    setSearchTerm("");
    setTypedText("");
    AddBrand();
  };

  const filteredStorage = allStorage.filter((brand) => {
    const name = brand.size;
    // Check if 'name' is not null before calling toLowerCase
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermStorage.toLowerCase());
    }

    return false; // Or handle null or non-string values as needed
  });

  const handleSearchChangeStorage = (e) => {
    setSearchTermStorage(e.target.value);
    setTypedText(e.target.value);
    setSelectedStorage(null); // Reset selected brand when typing in the input field
  };

  const handleStorageSelect = (storage) => {
    setSelectedStorage(storage.size);
    setSelectedStorageId(storage.id);
    setShowListStorage(false);
  };

  const handleAddNewClickStorage = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Category name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    //e.preventDefault();
    setSelectedStorage(typedText);
    setShowListStorage(false);
    setSearchTermStorage("");
    setTypedText("");
    AddStorage();
  };

  const filteredCondition = allCondition.filter((item) => {
    const name = item.condition;

    // Check if 'name' is not null before calling toLowerCase
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermCondition.toLowerCase());
    }

    return false;
  });
  const filteredCarrier = allCarrier.filter((item) => {
    const name = item.name;

    // Check if 'name' is not null before calling toLowerCase
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermCarrier.toLowerCase());
    }

    return false;
  });
  const filteredColor = allColor.filter((item) => {
    const name = item.name;

    // Check if 'name' is not null before calling toLowerCase
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermColor.toLowerCase());
    }

    return false;
  });

  const handleSearchChangeColor = (e) => {
    setsearchTermColor(e.target.value);
    setTypedText(e.target.value);
    setSelectedColor(null);
  };
  const handleSearchChangeCondition = (e) => {
    setSearchTermCondition(e.target.value);
    setTypedText(e.target.value);
    setSelectedCondition(null);
  };
  const handleSearchChangeCarrier = (e) => {
    setSearchTermCarrier(e.target.value);
    setTypedText(e.target.value);
    setSelectedCarrier(null);
  };
  const handleColorSelect = (item) => {
    setSelectedColor(item?.name);
    setSelectedColorId(item?.id);
    setShowListColor(false);
  };

  const handleConditionSelect = (item) => {
    setSelectedCondition(item.condition);
    setSelectedConditionId(item.id);
    setShowListCondition(false);
  };

  const handleCarrierSelect = (brand) => {
    setSelectedCarrier(brand.name);
    setSelectedCarrierId(brand.id);
    setShowListCarrier(false);
  };

  const handleSearchChangeSupplier = (e) => {
    setsearchTermSuppiler(e.target.value);
    setTypedText(e.target.value);
    setSelectedSuppiler(null);
  };
  const handleButtonClickSupplier = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowListSuppiler(!showListSuppiler);
  };

  const handleAddNewClickColor = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Color name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    //  e.preventDefault();
    setSelectedColor(typedText);
    setShowListColor(false);
    setsearchTermColor("");
    setTypedText("");
    AddColorDevice();
  };

  const handleAddNewClickCondition = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Condition!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    // e.preventDefault();
    // setSelectedSuppiler(typedText);
    setSelectedCondition(typedText);
    setShowListCondition(false);
    setSearchTermCondition("");
    setTypedText("");
    AddCondition();
  };

  const handleAddNewClickCarrier = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Carrier name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    // e.preventDefault();
    // setSelectedSuppiler(typedText);
    setSelectedCarrier(typedText);
    setShowListCarrier(false);
    setSearchTermCarrier("");
    setTypedText("");
    AddCarrier();
  };

  const handleInputClick = () => {
    setShowListTags(!showListTags); // Show the list of tags when the input is clicked
  };

  const handleButtonClickColor = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowListColor(!showListColor);
  };

  const handleButtonClickCondition = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowListCondition(!showListColor);
  };

  const handleButtonClickCarrier = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowListCarrier(!showListColor);
  };

  const handleButtonClickAuto = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowListAuto(!showListAuto);
  };
  const handleButtonClickTags = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowListTags(!showListTags);
  };

  const createOption = (label, id) => ({
    label,
    value: id,
  });

  const Validation = () => {
    let error = {};
    const numericRegex = /^[0-9]+$/;
    const priceRegex = /^\d+(\.\d{1,2})?$/;
    if (!title) {
      error.title = "Title Is Required";
    }
    if (!price) {
      error.price = "Selling Price Is Required";
    } else if (!priceRegex.test(price)) {
      error.price = "Please enter a valid price with up to two decimal points.";
    }
    // else if (!numericRegex.test(price)) {
    //   error.price = "Invalid Contains non-numeric characters";
    // }
    if (!SKU) {
      error.SKU = "SKU Code Is Required";
    }
    // if (!images) {
    //   error.images = "Image Is Required";
    // }

    if (Object.keys(error).length === 0) {
      // console.log(Object.keys(error).length);
      AddProduct();
    }
    return error;
  };

  const Validation1 = () => {
    let error = {};
    const numericRegex = /^[0-9]+$/;
    const priceRegex = /^\d+(\.\d{1,2})?$/;
    if (!title) {
      error.title = "Title Is Required";
    }
    if (!price) {
      error.price = "Selling Price Is Required";
    } else if (!priceRegex.test(price)) {
      error.price = "Please enter a valid price with up to two decimal points.";
    }
    // else if (!numericRegex.test(price)) {
    //   error.price = "Invalid Contains non-numeric characters";
    // }
    if (!SKU) {
      error.SKU = "SKU Code Is Required";
    }
    // if (!images) {
    //   error.images = "Image Is Required";
    // }

    if (Object.keys(error).length === 0) {
      // console.log(Object.keys(error).length);
      AddProduct1();
    }
    return error;
  };

  const navigate = useNavigate();

  const AddProduct = () => {
    let local = localStorage.getItem("token");
    const tagIdsArray = selectedTags.map((tag) => tag.id.toString());

    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var raw = JSON.stringify({
      productCategoryId: 2,
      brandId: selectedBrandId,
      manufacturerId: selectedSupplierId,
      title: title,
      image: images,
      activeInactive: 1,
      draft: 0,
      skuCode: SKU,
      description: description,

      sellingPrice: price ? price : "0",
      unitCost: 0,
      minimumPrice: minimumPrice ? minimumPrice : "0",
      averageCost: 0,
      storageId: selectedStorageId,
      colorId: selectedColorId,
      conditionId: selectedConditionId,
      imeiNumber: imeiNumber,
      carrierId: selectedCarrierId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddProductApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          navigate("/productlist");
          // props.onpress(false);
          // props.onload();
          setTitle("");
          setImages("");
          setDescription("");
          setPrice("");
          setUnitCost("");
          setAverageCost("");
          setMinimunPrice("");
          setSKUFill("");
          setSelectedBrand("");
          //setSelectedCategory("");
          //setSelectedTags([]);
          // setSelectedSuppiler("");
          Swal.fire(result?.message);
        } else {
          Swal.fire(result?.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const AddProduct1 = () => {
    let local = localStorage.getItem("token");
    const tagIdsArray = selectedTags.map((tag) => tag.id.toString());

    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var raw = JSON.stringify({
      productCategoryId: 2,
      brandId: selectedBrandId,
      manufacturerId: selectedSupplierId,
      title: title,
      image: images,
      activeInactive: 1,
      draft: 1,
      skuCode: SKU,
      description: description,
      sellingPrice: price ? price : "0",
      unitCost: 0,
      minimumPrice: minimumPrice ? minimumPrice : "0",
      averageCost: 0,
      storageId: selectedStorageId,
      colorId: selectedColorId,
      conditionId: selectedConditionId,
      imeiNumber: imeiNumber,
      carrierId: selectedCarrierId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddProductApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          navigate("/productlist");
          // props.onpress(false);
          // props.onload();
          setTitle("");
          setImages("");
          setDescription("");
          setPrice("");
          setUnitCost("");
          setAverageCost("");
          setMinimunPrice("");
          setSKUFill("");
          setSelectedBrand("");
          //setSelectedCategory("");
          //setSelectedTags([]);
          // setSelectedSuppiler("");
          Swal.fire(result?.message);
        } else {
          Swal.fire(result?.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const AddBrand = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var raw = JSON.stringify({
      name: typedText,
      productCategoryId: 2,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddBrandApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllBrand();
          setSelectedBrandId(result?.data?.id);
          setSearchTerm("");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllBrand = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllBrand?productCategoryId=2`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllBrand(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const SubmitProduct = () => {
    setError(Validation());
  };
  const SubmitProduct1 = () => {
    setError(Validation1());
  };

  const HandleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete image",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c76cff",
      cancelButtonColor: "#efefef",
      confirmButtonText: "Yes, delete it!",
      confirmButtonText: "<span style='color: white;'>Yes, delete it!</span>",
      cancelButtonText: "<span style='color: black;'>cancel!</span>",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "sweet-alert2-container-zindex",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
          confirmButtonColor: "#c76cff",
          customClass: {
            container: "sweet-alert2-container-zindex",
          },
        }).then(() => {
          setImages("");
        });
      }
    });
  };
  const generateSKU = () => {
    // Assuming you have a specific format for SKU, you can customize this logic
    const sku = Math.floor(100000 + Math.random() * 900000)
      .toString()
      .substring(0, 6);

    setSKU(sku);
    return sku;
  };

  useEffect(() => {
    GetAllBrand();
    GetAllStorage();
    GetAllColor();
    GetAllCondition();
    GettAllCarrier();
    GetAllSupplier();
  }, []);

  const [selectedBrand, setSelectedBrand] = useState(null);

  const AddSuppier = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    var raw = JSON.stringify({
      name: typedText,
      productCategoryId: 2,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddManufactureApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllSupplier();
          setSelectedSuppierId(result?.data?.id);
          setsearchTermSuppiler("");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllSupplier = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetManufactureApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllSupplier(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleImages = (e) => {
    var file = e.target.files[0];

    if (file) {
      const minSizeInBytes = 5000; // 5 KB
      const maxSizeInBytes = 1024 * 1024; // 1 MB

      if (file.size < minSizeInBytes) {
        setImageMsg("Image size is below the minimum allowed size (5 KB)");
        e.target.value = null;
        setTimeout(() => {
          setImageMsg("");
        }, 2000);

        return;
      }
      const fileName = file.name;
      const lastDotIndex = fileName.lastIndexOf(".");
      const baseName = fileName.substring(0, lastDotIndex);
      const extension = fileName.substring(lastDotIndex + 1);

      // Only allow files with a single dot in the base name
      if (baseName.includes(".")) {
        setImageMsg(
          "File name is not valid. Only one dot is allowed before the extension."
        );
        e.target.value = null;
        setTimeout(() => {
          setImageMsg("");
        }, 2000);

        return;
      }
      let size = file.type.replace("/", ".");
      var blob = file.slice(0, file.size, file.type);
      const newName = new Date().getTime() + size;
      var newFile = new File([blob], newName, { type: file.type });

      UploadImage(newFile);
    }
  };
  const UploadImage = async (newFile) => {
    setLoading(true);
    var formdata = new FormData();
    formdata.append("file", newFile);
    formdata.append("type", "device-images");

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(uploadImageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setImages(result?.data);
        setImageMsg("");
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };
  const maxDescriptionLength = 200;
  const handleDescriptionChange = (e) => {
    const newDescription = e.target.value;

    if (newDescription.length <= maxDescriptionLength) {
      setDescription(newDescription);
      setErrormsg("");
    } else if (newDescription.length > maxDescriptionLength) {
      setTimeout(() => {
        setErrormsg("Allow Maximum 200 Characters");
      }, 2000);
    }
  };

  const GetAllStorage = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllStorageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllStorage(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllColor = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllColorApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllColor(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllCondition = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllConditionApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllCondition(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const AddStorage = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    var raw = JSON.stringify({
      size: typedText,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddStorageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllStorage();
          setSelectedStorageId(result?.data?.id);
          setSearchTermStorage("");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const AddColorDevice = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      name: typedText,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseurl}addColor`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllColor();
          setSelectedColorId(result?.data?.id);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const AddCondition = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3A-_3JwaXdjnqK5jnz_MupfyYwBOQ-xwpN.F15%2BK8LY79Ye8glfaA8Fk2LX1rgoPDz%2BrF7ZAbnGd58"
    );

    var raw = JSON.stringify({
      condition: typedText,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddConditionApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllCondition();
          setSelectedConditionId(result?.data?.id);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GettAllCarrier = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3A-_3JwaXdjnqK5jnz_MupfyYwBOQ-xwpN.F15%2BK8LY79Ye8glfaA8Fk2LX1rgoPDz%2BrF7ZAbnGd58"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllCarrier`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllCarrier(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const AddCarrier = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    var raw = JSON.stringify({
      name: typedText,
      productCategoryId: 2,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddCarrierApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GettAllCarrier();
          setSelectedCarrierId(result?.data?.id);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <>
      {loading ? (
        <div className="landing_loader">
          <div className="innerloader">
            <img src="img/spin-1s-156px.gif" alt="" />
          </div>
        </div>
      ) : null}
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="createproductModalLabel">
              Create New Device
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setShowModal4(false);
                document
                  .querySelectorAll(".modal-backdrop")
                  .forEach((el) => el.classList.remove("modal-backdrop"));
                setTitle("");
                setImages("");
                setDescription("");
                setPrice("");
                setUnitCost("");
                setAverageCost("");
                setMinimunPrice("");
                setSKUFill("");
                setSelectedBrand("");
                setSelectedStorage("");
                //setSelectedTags([]);
                setSelectedSuppiler("");
              }}
            >
              <span className="close_icon"></span>
            </button>
          </div>
          <div className="modal-body">
            <div className="custom_form createproduct_form">
              <form action="" method="" className="cus_form">
                <div className="form_row product_radio_row">
                  <div className="form_column col_full">
                    <div className="form-group"></div>
                  </div>
                </div>

                <div className="product_row">
                  <div className="product_col70 cus_prod_col">
                    <h4>
                      General information<span>*</span>
                    </h4>
                    <p>To start selling, all you need is a name and a price.</p>
                    <div className="form_row">
                      <div className="form_column col_full">
                        <div className="form-group">
                          <label className="cus_label">Brand</label>
                          <div
                            className="formfield field_wd100 selectcategoryformbox"
                            ref={dropdownRef}
                          >
                            <div className="tagsscustomdiv">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onClick={() => setShowList(!showList)}
                              >
                                <input
                                  ref={inputRef}
                                  type="text"
                                  className="customsearchinputboxsetting inputdeviceformcustmbox form-control"
                                  placeholder={`Select Brand`}
                                  value={selectedBrand || searchTerm}
                                  onChange={handleSearchChange}
                                  onClick={() => setShowList(!showList)}
                                />

                                <img
                                  src="assets/img/select-arrow.webp"
                                  alt="Handy Icon"
                                  onClick={handleButtonClick}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>

                              {showList && (
                                <div className="addselectbox">
                                  <ul>
                                    {filteredBrands.map((brand) => (
                                      <li
                                        key={brand.id}
                                        onClick={() => handleBrandSelect(brand)}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        {brand.name}
                                      </li>
                                    ))}
                                  </ul>

                                  <button
                                    onClick={() => {
                                      handleAddNewClick();
                                      if (inputRef.current) {
                                        inputRef.current.focus();
                                      }
                                    }}
                                    style={{ backgroundColor: "#9d3dd3" }}
                                    type="button"
                                    className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                  >
                                    <span>+</span> Add New Brand
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form_column col_full">
                        <div className="form-group">
                          <label className="cus_label label_red">
                            Model Name<span>*</span>
                          </label>
                          <div className="formfield field_wd100">
                            <input
                              type="text"
                              className="form-control"
                              name="title"
                              placeholder="Enter Model Name"
                              value={title}
                              onChange={(e) => {
                                setTitle(e.target.value);
                                if (error.title) {
                                  setError({ ...error, title: "" });
                                }
                              }}
                            />{" "}
                          </div>{" "}
                          {error.title && (
                            <span
                              style={{
                                fontSize: "12px",
                                color: "red",
                                marginLeft: "110px",
                              }}
                            >
                              {error.title}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="form_column col_full">
                        <div className="form-group">
                          <label className="cus_label">Storage</label>
                          <div
                            className="formfield field_wd100  selectcategoryformbox"
                            ref={dropdownRef1}
                          >
                            <div className="tagsscustomdiv">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onClick={() =>
                                  setShowListStorage(!showListStorage)
                                }
                              >
                                <input
                                  ref={inputRef1}
                                  type="text"
                                  className="customsearchinputboxsetting inputdeviceformcustmbox form-control"
                                  placeholder={`Select Storage`}
                                  value={selectedStorage || searchTermStorage}
                                  onChange={handleSearchChangeStorage}
                                  onClick={() =>
                                    setShowListStorage(!showListStorage)
                                  }
                                />

                                <img
                                  src="assets/img/select-arrow.webp"
                                  alt="Handy Icon"
                                  onClick={handleButtonClickStorage}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>

                              {showListStorage && (
                                <div className="addselectbox">
                                  <ul>
                                    {filteredStorage.map((item) => (
                                      <li
                                        key={item.id}
                                        onClick={() =>
                                          handleStorageSelect(item)
                                        }
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        aria-selected="true"
                                      >
                                        {item.size}
                                      </li>
                                    ))}
                                  </ul>

                                  <button
                                    onClick={() => {
                                      handleAddNewClickStorage();
                                      if (inputRef1.current) {
                                        inputRef1.current.focus();
                                      }
                                    }}
                                    style={{ backgroundColor: "#9d3dd3" }}
                                    type="button"
                                    className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                  >
                                    <span>+</span> Add New Storage
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form_column col_full">
                        <div className="form-group">
                          <label className="cus_label">Colour</label>
                          <div
                            className="formfield field_wd100 selectcategoryformbox"
                            ref={dropdownRef2}
                          >
                            <div className="tagsscustomdiv">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onClick={() => setShowListColor(!showListColor)}
                              >
                                <input
                                  ref={inputRef2}
                                  type="text"
                                  className="customsearchinputboxsetting inputdeviceformcustmbox form-control"
                                  placeholder={`Select Colour`}
                                  value={selectedColor || searchTermColor}
                                  onChange={handleSearchChangeColor}
                                  onClick={() =>
                                    setShowListColor(!showListColor)
                                  }
                                />

                                <img
                                  src="assets/img/select-arrow.webp"
                                  alt="Handy Icon"
                                  onClick={handleButtonClickColor}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>

                              {showListColor && (
                                <div className="addselectbox">
                                  <ul>
                                    {filteredColor.map((item) => (
                                      <li
                                        key={item.id}
                                        onClick={() => {
                                          handleColorSelect(item);
                                        }}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        {item.name}
                                      </li>
                                    ))}
                                  </ul>

                                  <button
                                    onClick={() => {
                                      handleAddNewClickColor();
                                      if (inputRef2.current) {
                                        inputRef2.current.focus();
                                      }
                                    }}
                                    style={{ backgroundColor: "#9d3dd3" }}
                                    type="button"
                                    className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                  >
                                    <span>+</span> Add New Colour
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form_column col_full">
                        <div className="form-group">
                          <label className="cus_label">Condition</label>
                          <div
                            className="formfield field_wd100 selectcategoryformbox"
                            ref={dropdownRef3}
                          >
                            <div className="tagsscustomdiv">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onClick={() =>
                                  setShowListCondition(!showListCondition)
                                }
                              >
                                <input
                                  ref={inputRef3}
                                  type="text"
                                  className="customsearchinputboxsetting inputdeviceformcustmbox form-control"
                                  placeholder={`Select Condition`}
                                  value={
                                    selectedCondition || searchTermCondition
                                  }
                                  onChange={handleSearchChangeCondition}
                                  onClick={() =>
                                    setShowListCondition(!showListCondition)
                                  }
                                />

                                <img
                                  src="assets/img/select-arrow.webp"
                                  alt="Handy Icon"
                                  onClick={handleButtonClickCondition}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>

                              {showListCondition && (
                                <div className="addselectbox">
                                  <ul>
                                    {filteredCondition.map((item) => (
                                      <li
                                        key={item.id}
                                        onClick={() => {
                                          handleConditionSelect(item);
                                        }}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        {item.condition}
                                      </li>
                                    ))}
                                  </ul>

                                  <button
                                    onClick={() => {
                                      handleAddNewClickCondition();
                                      if (inputRef3.current) {
                                        inputRef3.current.focus();
                                      }
                                    }}
                                    style={{ backgroundColor: "#9d3dd3" }}
                                    type="button"
                                    className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                  >
                                    <span>+</span> Add New Condition
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form_column col_full">
                        <div className="form-group">
                          <label className="cus_label">Carrier</label>
                          <div
                            className="formfield field_wd100 selectcategoryformbox"
                            ref={dropdownRef4}
                          >
                            <div className="tagsscustomdiv">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onClick={() =>
                                  setShowListCarrier(!showListCarrier)
                                }
                              >
                                <input
                                  ref={inputRef4}
                                  type="text"
                                  className="customsearchinputboxsetting inputdeviceformcustmbox form-control"
                                  placeholder={`Select Carrier`}
                                  value={selectedCarrier || searchTermCarrier}
                                  onChange={handleSearchChangeCarrier}
                                  onClick={() =>
                                    setShowListCarrier(!showListCarrier)
                                  }
                                />

                                <img
                                  src="assets/img/select-arrow.webp"
                                  alt="Handy Icon"
                                  onClick={handleButtonClickCarrier}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>

                              {showListCarrier && (
                                <div className="addselectbox">
                                  <ul>
                                    {filteredCarrier.map((item) => (
                                      <li
                                        key={item.id}
                                        onClick={() => {
                                          handleCarrierSelect(item);
                                        }}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        {item.name}
                                      </li>
                                    ))}
                                  </ul>

                                  <button
                                    onClick={() => {
                                      handleAddNewClickCarrier();
                                      if (inputRef4.current) {
                                        inputRef4.current.focus();
                                      }
                                    }}
                                    style={{ backgroundColor: "#9d3dd3" }}
                                    type="button"
                                    className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                  >
                                    <span>+</span> Add New Carrier
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form_column col_full">
                        <div className="form-group">
                          <label className="cus_label">Manufacturer</label>
                          <div
                            className="formfield field_wd100 selectcategoryformbox"
                            ref={dropdownRef2}
                          >
                            <div className="tagsscustomdiv">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onClick={() =>
                                  setShowListSuppiler(!showListSuppiler)
                                }
                              >
                                <input
                                  ref={inputRef2}
                                  type="text"
                                  className="customsearchinputboxsetting inputdeviceformcustmbox form-control"
                                  placeholder={`Select Manufacturer`}
                                  value={selectedSuppiler || searchTermSuppiler}
                                  onChange={handleSearchChangeSupplier}
                                  onClick={() => setShowListSuppiler(true)}
                                />

                                <img
                                  src="assets/img/select-arrow.webp"
                                  alt="Handy Icon"
                                  onClick={handleButtonClickSupplier}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>

                              {showListSuppiler && (
                                <div className="addselectbox">
                                  <ul>
                                    {filteredSuppiler.map((brand) => (
                                      <li
                                        key={brand.id}
                                        onClick={() => {
                                          handleSupplierSelect(brand);
                                        }}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        {brand.name}
                                      </li>
                                    ))}
                                  </ul>

                                  <button
                                    onClick={() => {
                                      handleAddNewClickSupplier();
                                      if (inputRef2.current) {
                                        inputRef2.current.focus();
                                      }
                                    }}
                                    style={{ backgroundColor: "#9d3dd3" }}
                                    type="button"
                                    className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                  >
                                    <span>+</span> Add New Manufacturer
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="form_column col_full">
                        <div className="form-group">
                          <label className="cus_label">IMEI/Serial</label>
                          <div className="formfield field_wd100">
                            <input
                              type="text"
                              className="form-control"
                              name="serialno"
                              placeholder="Enter IMEI/Serial"
                              value={imeiNumber}
                              onChange={(e) =>
                                setIMEINumber(e.target.value.trim())
                              }
                            />
                          </div>
                        </div>
                      </div> */}
                      <div className="form_column col_full">
                        <div className="form-group">
                          <label className="cus_label">
                            SKU Code type:{" "}
                            <img src="assets/img/info-icon.webp" alt="" />
                          </label>
                          <div
                            className="formfield field_wd100 formfield_col2"
                            ref={dropdownRef11}
                          >
                            <div
                              className="field_wd50"
                              onClick={() => setShowListAuto(!showListAuto)}
                            >
                              <div className="selectcategoryformbox1">
                                <div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() =>
                                      setShowListAuto(!showListAuto)
                                    }
                                  >
                                    <span className="customsearchinputboxsetting">
                                      {autogenText
                                        ? autogenText
                                        : "Select SKU Code"}
                                    </span>
                                    <button
                                      onClick={handleButtonClickAuto}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img
                                        src="assets/img/select-arrow.webp"
                                        alt="Handy Icon"
                                      />
                                    </button>
                                  </div>
                                  {showListAuto && (
                                    <div className="addselectbox">
                                      <ul>
                                        <li
                                          onClick={() => {
                                            setShowListAuto(false);
                                            setAutoGenrate(0);
                                            generateSKU();
                                            setAutoGenTex("Auto Generated");
                                          }}
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          Auto Generated
                                        </li>
                                        <li
                                          onClick={() => {
                                            setShowListAuto(false);
                                            setAutoGenrate(1);
                                            setAutoGenTex("Manually Filled");
                                          }}
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          Manually Filled
                                        </li>
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            {autogenrate === 1 ? (
                              <div className="field_wd50">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="sku_code"
                                  value={SKU}
                                  placeholder="Enter SKU Code"
                                  onChange={(e) => {
                                    setSKU(e.target.value);
                                    if (error.SKU) {
                                      setError({ ...error, SKU: "" });
                                    }
                                  }}
                                />
                                {error.SKU && (
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      color: "red",
                                    }}
                                  >
                                    {error.SKU}
                                  </span>
                                )}
                              </div>
                            ) : null}

                            {autogenrate === 0 ? (
                              <div className="field_wd50">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="sku_code"
                                  placeholder={SKU}
                                  readOnly
                                />
                                {error.SKU && (
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      color: "red",
                                    }}
                                  >
                                    {error.SKU}
                                  </span>
                                )}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="product_col30 cus_prod_col">
                    <h4>Thumbnail</h4>
                    <p>Upload image of product</p>

                    <div className="form_row">
                      <div className="form_column col_full">
                        <div className="form-group">
                          <div className="thumbnail_img">
                            {images ? (
                              <i
                                className="fa-regular fa-circle-xmark deletimgcustm1"
                                onClick={() => HandleDelete()}
                              ></i>
                            ) : null}
                            {images ? (
                              <>
                                <img src={images} className="imgescstmdisply" />
                              </>
                            ) : (
                              <>
                                <input
                                  type="file"
                                  className="form-control"
                                  name="prod_thumnail"
                                  accept="image/jpeg, image/jpg, image/png"
                                  onChange={handleImages}
                                />
                                <div className="file_upload">
                                  <img
                                    src="assets/img/thumbnail_img.webp"
                                    alt=""
                                  />
                                  <div className="file_txt">
                                    Drop your file here, or{" "}
                                    <span>click to browse</span>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          {imageMsg && (
                            <span
                              style={{
                                fontSize: "12px",
                                color: "red",
                              }}
                            >
                              {imageMsg}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clearfix"></div>
                <div className="product_row">
                  <div className="product_full cus_prod_col">
                    <div className="form_row">
                      <div className="form_column col_full">
                        <div className="form-group tsgddisplycstmmm1">
                          <label className="cus_label label_red">
                            Pricing<span>*</span>{" "}
                            <img src="assets/img/info-icon.webp" alt="" />
                          </label>
                          <div className="formfield pricefield field_wd100">
                            <div className="price_col">
                              <label className="cus_label label_red">
                                Selling Price *
                              </label>
                              <input
                                type="number"
                                className="form-control no-arrow-input"
                                name="selling_price"
                                value={price}
                                onChange={(e) => {
                                  setPrice(e.target.value);

                                  if (error.price) {
                                    setError({ ...error, price: "" });
                                  }
                                }}
                              />
                              {error.price && (
                                <span
                                  style={{ fontSize: "12px", color: "red" }}
                                >
                                  {error.price}
                                </span>
                              )}
                              <div className="doller_sign">
                                <span>$</span>
                              </div>
                            </div>
                            {/* <div className="price_col">
                              <label className="cus_label">Unit Cost</label>
                              <input
                                type="number"
                                className="form-control no-arrow-input"
                                name="unit_cost"
                                value={unitCost}
                                onChange={(e) => setUnitCost(e.target.value)}
                              />
                              <div className="doller_sign">
                                <span>$</span>
                              </div>
                            </div> */}
                            <div className="price_col">
                              <label className="cus_label">Minimum Price</label>
                              <input
                                type="number"
                                className="form-control no-arrow-input"
                                name="minimum_price"
                                value={minimumPrice}
                                onChange={(e) =>
                                  setMinimunPrice(e.target.value)
                                }
                              />
                              <div className="doller_sign">
                                <span>$</span>
                              </div>
                            </div>
                            {/* <div className="price_col">
                              <label className="cus_label">Average Cost</label>
                              <input
                                type="number"
                                className="form-control no-arrow-input"
                                name="average_cost"
                                value={averageCost}
                                onChange={(e) => setAverageCost(e.target.value)}
                              />
                              <div className="doller_sign">
                                <span>$</span>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form_column col_full">
                    <div className="form-group">
                      <label className="cus_label">
                        Description (optional)
                      </label>
                      <div className="formfield field_wd100">
                        <textarea
                          className="form-control"
                          name="description"
                          placeholder="Enter Description"
                          value={description}
                          onChange={handleDescriptionChange}
                        ></textarea>
                      </div>{" "}
                      {errormsg && (
                        <span
                          style={{
                            fontSize: "12px",
                            color: "red",
                            marginLeft: "110px",
                          }}
                        >
                          {errormsg}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form_column col_full">
                    <div className="form-group">
                      <div className="taxfield switch_field">
                        <label className="custom-switch">
                          <span className="switch_txt">Default Tax</span>
                          <input
                            type="checkbox"
                            name="custom-switch-checkbox"
                            className="custom-switch-input"
                            checked
                          />
                          <span className="custom-switch-indicator"></span>
                        </label>
                        <label className="custom-switch">
                          <span className="switch_txt">Tax Inclusive</span>
                          <input
                            type="checkbox"
                            name="custom-switch-checkbox"
                            className="custom-switch-input"
                            checked
                          />
                          <span className="custom-switch-indicator"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="clearfix"></div>
              </form>
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary default_btn"
              data-dismiss="modal"
              onClick={() => {
                SubmitProduct1();
              }}
            >
              Save as draft
            </button>
            <button
              type="button"
              className="btn btn-primary theme_btn"
              onClick={() => {
                SubmitProduct();
              }}
            >
              Publish Product
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default DeviceModalList;
