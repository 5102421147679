import React, { useEffect, useState } from "react";
import $ from "jquery";

import { Link, useNavigate } from "react-router-dom";
import { GetSubUserProfileApi, baseurl, getProfileApi } from "../Utility/Api";
import FreeTrail from "./FreeTrail";
import MainSidebar from "./MainSidebar";

const NavBar = (props) => {
  const [data, setData] = useState([]);
  const [dataUser, setDataUser] = useState([]);
  const [adminState, setAdminState] = useState("");
  const [permission, setPermission] = useState([]);
  const [sub, setSub] = useState();
  const [status, setStatus] = useState("");
  const [sub1, setSub1] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [data1, setData1] = useState([]);
  const [showModal1, setShowModal1] = useState(false);
  const [selectedState, setSelectedState] = useState(false);

  useEffect(() => {
    let localAdmin = localStorage.getItem("admin");
    let localPerminssion = JSON.parse(localStorage.getItem("permission"));

    if (localAdmin && localPerminssion.length) {
      setAdminState(localAdmin);
      setPermission(localPerminssion);

      GetSubUserProfile();
    }
    GetProfile();
    GetCustomer();
  }, []);

  useEffect(() => {
    const protocol = window.location.protocol;
    const host = window.location.host;
    const subdomain = host.split(".")[0];

    setSub1(subdomain);

    let subscribed = localStorage.getItem("subscribed");

    if (subscribed === "1") {
      setSub(1);
    } else {
      setSub(2);
    }
  }, []);

  const navigate = useNavigate();

  const logOutt = () => {
    localStorage.clear();

    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith("token")) {
        localStorage.removeItem(key);
        localStorage.clear();
      }
    });
    window.location.href = "https://app.toolboxpos.com/";
    // navigate("/");
  };

  const GetProfile = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(getProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setData(result?.data);
          setStatus(
            result?.data?.comapany?.subscriptionStatus === "canceled" ||
              result?.data?.comapany?.subscriptionStatus === null ||
              result?.data?.comapany?.subscriptionStatus === "expired"
          );
          // if (
          //   result?.comapany?.subscriptionStatus === "canceled" ||
          //   result?.comapany?.subscriptionStatus === null
          // ) {
          //   navigate("/settings", { state: { activeState: 1 } });
          // }
        } else {
          //console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetSubUserProfile = () => {
    let local = localStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetSubUserProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setDataUser(result?.data);
        } else {
          //console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const Profile = () => {
    navigate("/settings");
  };

  const Setting = () => {
    navigate("/settings");
  };

  const GetCustomer = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllCustomer?pageSize=100&page=1`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setData1(result?.data);
          setSelectedCustomer(result?.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleSearchTermChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    const lowerCaseSearchTerm = term.toLowerCase();
    setShowModal1(true);
    //setCustomerState(true);

    const matchedCustomer = data1.find(
      (customer) =>
        customer?.firstName.toLowerCase().includes(lowerCaseSearchTerm) ||
        customer?.email.toLowerCase().includes(lowerCaseSearchTerm) ||
        customer?.lastName.toLowerCase().includes(lowerCaseSearchTerm)
    );
    //setMatched(matchedCustomer);
    setSelectedCustomer(matchedCustomer);
  };

  const filteredData = data1?.filter((item) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return (
      item.firstName.toLowerCase().includes(lowerCaseSearchTerm) ||
      item.email.toLowerCase().includes(lowerCaseSearchTerm) ||
      item.phoneNumber.toLowerCase().includes(lowerCaseSearchTerm)
    );
  });

  const handlePopupCloseCustomer = () => {
    setShowModal1(false);
  };
  const handleCustomerSelection = (customer) => {
    setSearchTerm(`${customer.firstName}`);
    setSelectedCustomer(customer);
    setShowModal1(false);
  };
  const [isFreeTrial, setIsFreeTrial] = useState(false);

  useEffect(() => {
    const substatus = localStorage.getItem("substatus");
    const planDate = localStorage.getItem("planDate");

    if (
      substatus === "null" ||
      substatus === "canceled" ||
      substatus === "expired"
    ) {
      setIsFreeTrial(true);
    }
  }, []);
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };
  return (
    // <>
    //   {localStorage.getItem("substatus") === "null" ||
    //     localStorage.getItem("substatus") === "canceled" ||
    //     localStorage.getItem("substatus") === "expired" ? (
    //       <>
    //          <div className="app-container">
    //   <FreeTrail />
    //         </div>{" "}
    //       </>
    //     ) : null}
    // </>
    <>
      {localStorage.getItem("substatus") === "null" ||
      localStorage.getItem("substatus") === "canceled" ||
      localStorage.getItem("substatus") === "expired" ? (
        <>
          <div className="app-container-trial">
            <FreeTrail />
          </div>{" "}
        </>
      ) : null}
      {isFreeTrial ? (
        <>
          <div className={`${isSidebarVisible ? "sidebar-mini" : ""}`}>
            <nav className="navbar navbar-expand-lg main-navbar sticky freetrial">
              {(localStorage.getItem("substatus") === "null" ||
                localStorage.getItem("substatus") === "canceled" ||
                localStorage.getItem("substatus") === "expired") &&
              new Date(localStorage.getItem("planDate")) < new Date() ? null : (
                <div className="sidebar-arrow">
                  <a
                    href="#"
                    data-toggle="sidebar"
                    onClick={(e) => {
                      toggleSidebar();

                      e.preventDefault();
                    }}
                  >
                    <img src="assets/img/sidebar-toggle.webp" alt="" />
                  </a>
                </div>
              )}{" "}
              <div className="header-title mr-auto">
                <h4>Dashboard</h4>
              </div>
              <div className="common_search">
                <div className="search_input">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Customer"
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                  />
                  <button type="button" className="search_btn">
                    <img src="assets/img/search-icon.webp" alt="" />
                  </button>
                </div>
                <div
                  className={`searchcustomer_list ${
                    showModal1 ? "active" : ""
                  }`}
                >
                  {adminState === "0" ? null : (
                    <>
                      <div className="srchcustmer_title common_title">
                        <h4>Search Customer </h4>
                        <a
                          className="popup_close"
                          onClick={handlePopupCloseCustomer}
                        >
                          <span className="close_icon"></span>
                        </a>
                      </div>
                      <div className="customer_list">
                        <ul>
                          {filteredData.length ? (
                            <>
                              {filteredData?.map((item) => (
                                <li key={item.id}>
                                  <Link
                                    to="/CustomerDetail"
                                    state={{ data: item }}
                                    // onClick={() => {
                                    //   handleCustomerSelection(item);
                                    //   setSelectedState(true);
                                    // }}
                                    key={item.id}
                                    className="linkcustm"
                                  >
                                    <div key={item.id} className="custmer_img">
                                      {item?.profileImage ? (
                                        <img src={item?.profileImage} alt="" />
                                      ) : (
                                        <img
                                          src="assets/img/customer-info-img.webp"
                                          alt=""
                                        />
                                      )}
                                    </div>
                                    <span>
                                      {item?.firstName} {item?.lastName}{" "}
                                      <small>{item?.email}</small>
                                    </span>

                                    <img
                                      className="customeraddeditcstmimg"
                                      src="assets/img/arrow-right-icon.svg"
                                      alt=""
                                    />
                                  </Link>
                                </li>
                              ))}
                            </>
                          ) : (
                            <div className="text-center">
                              <h5>No data available</h5>
                            </div>
                          )}
                        </ul>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <ul className="navbar-nav navbar-right">
                {adminState === 1 || permission.length === 0 ? (
                  <li className="setting_li custom_li">
                    <Link to="/settings">
                      <img src="assets/img/settings-icon.webp" alt="" />
                    </Link>
                  </li>
                ) : (
                  permission.map((permission) => (
                    <>
                      {permission.name === "Settings" && (
                        <li className="setting_li custom_li">
                          <Link to="/settings">
                            <img src="assets/img/settings-icon.webp" alt="" />
                          </Link>
                        </li>
                      )}
                    </>
                  ))
                )}
                {/* <li className="setting_li custom_li">
            <Link to="/settings">
              <img src="assets/img/settings-icon.webp" alt="" />
            </Link>
          </li> */}
                <li className="timer_li custom_li">
                  {/* <a href="#">
              <img src="assets/img/clock-icon.webp" alt="" />
            </a> */}
                  <div className="timer_shift_popup">
                    <div className="timer_title">
                      <span>Shift Manager</span>
                    </div>
                    <form action="" method="" className="cus_form">
                      <div className="shift_timer_column">
                        <div className="timer_col_left">
                          <div className="form_row">
                            <div className="form_column formcol_half">
                              <div className="form-group">
                                <label>Employee name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="fullname"
                                  placeholder="Enter Full Name"
                                />
                              </div>
                            </div>
                            <div className="form_column formcol_half">
                              <div className="form-group">
                                <label>Location</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="location"
                                  placeholder="Enter Location"
                                />
                              </div>
                            </div>
                            <div className="form_column formcol_half">
                              <div className="form-group">
                                <label>Enter Password</label>
                                <input
                                  type="password"
                                  className="form-control"
                                  name="password"
                                  placeholder="*********"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="clearfix"></div>
                          <div className="timer_btns">
                            <div className="button_column">
                              <button
                                type="button"
                                className="cus_timer_btn green_btn"
                              >
                                Clock IN
                              </button>
                              <button
                                type="button"
                                className="cus_timer_btn red_btn"
                              >
                                Clock OUT
                              </button>
                            </div>
                            <div className="button_column">
                              <button
                                type="button"
                                className="cus_timer_btn green_btn"
                              >
                                Start Break
                              </button>
                              <button
                                type="button"
                                className="cus_timer_btn red_btn"
                              >
                                End Break
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="timer_col_right">
                          <div className="timer_content content_top">
                            <span>
                              Current time:
                              <br />
                              Aug 6, 2023, 6:13pm
                            </span>
                          </div>
                          <div className="form_row">
                            <div className="form_column formcol_full">
                              <div className="form-group">
                                <label>Status</label>
                                <select className="form-control" name="status">
                                  <option value="Clocked IN">Clocked IN</option>
                                  <option value="Clocked OUT">
                                    Clocked OUT
                                  </option>
                                  <option value="Start Break">
                                    Start Break
                                  </option>
                                  <option value="End Break">End Break</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="clearfix"></div>
                          <div className="timer_content content_btm">
                            <span>
                              You are clocked in <br />
                              <b>14 minutes</b> <br />
                              at Aug 6, 2023, 6:13pm
                            </span>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </li>
                <li className="dropdown">
                  <a
                    href="#"
                    data-toggle="dropdown"
                    className="nav-link dropdown-toggle nav-link-user"
                  >
                    <div className="user_icon">
                      <img
                        alt="image"
                        src={
                          props?.image
                            ? props.image
                            : data?.userDetails?.profileImage
                            ? data.userDetails.profileImage
                            : "assets/img/Avatar.webp"
                        }
                        className="user-img-radious-style userimageprofilecustm"
                      />
                      {/* <img
                  alt="image"
                  src={
                    props?.image
                      ? props?.image
                      : data?.userDetails?.profileImage
                  }
                  // src="assets/img/Avatar.png"
                  className="user-img-radious-style userimageprofilecustm"
                  // src="assets/img/Avatar.png"
                  // className="user-img-radious-style"
                /> */}
                    </div>{" "}
                    <div className="user_info">
                      <span className="user_name">
                        {(adminState === 0 || permission.length > 0) &&
                          dataUser?.userDetails?.name}
                        {(adminState === 1 || permission.length === 0) &&
                          data?.userDetails?.userName}
                        {adminState === 1 ||
                          (permission.length === 0 && (
                            <small>{data?.userDetails?.email}</small>
                          ))}
                        {(adminState === 0 || permission.length > 0) && (
                          <small>{dataUser?.userDetails?.email}</small>
                        )}
                      </span>
                      <span className="angle_down">
                        <i className="fa fa-angle-down"></i>
                      </span>
                    </div>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right pullDown">
                    <a
                      href="#"
                      className="dropdown-item has-icon"
                      onClick={Profile}
                    >
                      <i className="far fa-user"></i> Profile
                    </a>
                    <a
                      href="#"
                      className="dropdown-item has-icon"
                      onClick={Setting}
                    >
                      <i className="fas fa-cog"></i> Settings
                    </a>
                    <a
                      href="#"
                      className="dropdown-item has-icon"
                      onClick={logOutt}
                    >
                      <i className="fas fa-sign-out-alt"></i> Logout
                    </a>
                  </div>
                </li>
              </ul>
            </nav>
            <MainSidebar />
          </div>
        </>
      ) : (
        <div className={`${isSidebarVisible ? "sidebar-mini" : ""}`}>
          <nav className="navbar navbar-expand-lg main-navbar sticky">
            {(localStorage.getItem("substatus") === "null" ||
              localStorage.getItem("substatus") === "canceled" ||
              localStorage.getItem("substatus") === "expired") &&
            new Date(localStorage.getItem("planDate")) < new Date() ? null : (
              <div className="sidebar-arrow">
                <a
                  href="#"
                  data-toggle="sidebar"
                  onClick={(e) => {
                    toggleSidebar();

                    e.preventDefault();
                  }}
                >
                  <img src="assets/img/sidebar-toggle.webp" alt="" />
                </a>
              </div>
            )}{" "}
            <div className="header-title mr-auto">
              <h4>Dashboard</h4>
            </div>
            <div className="common_search">
              <div className="search_input">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Customer"
                  value={searchTerm}
                  onChange={handleSearchTermChange}
                />
                <button type="button" className="search_btn">
                  <img src="assets/img/search-icon.webp" alt="" />
                </button>
              </div>
              <div
                className={`searchcustomer_list ${showModal1 ? "active" : ""}`}
              >
                {adminState === "0" ? null : (
                  <>
                    <div className="srchcustmer_title common_title">
                      <h4>Search Customer </h4>
                      <a
                        className="popup_close"
                        onClick={handlePopupCloseCustomer}
                      >
                        <span className="close_icon"></span>
                      </a>
                    </div>
                    <div className="customer_list">
                      <ul>
                        {filteredData.length ? (
                          <>
                            {filteredData?.map((item) => (
                              <li key={item.id}>
                                <Link
                                  to="/CustomerDetail"
                                  state={{ data: item }}
                                  // onClick={() => {
                                  //   handleCustomerSelection(item);
                                  //   setSelectedState(true);
                                  // }}
                                  key={item.id}
                                  className="linkcustm"
                                >
                                  <div key={item.id} className="custmer_img">
                                    {item?.profileImage ? (
                                      <img src={item?.profileImage} alt="" />
                                    ) : (
                                      <img
                                        src="assets/img/customer-info-img.webp"
                                        alt=""
                                      />
                                    )}
                                  </div>
                                  <span>
                                    {item?.firstName} {item?.lastName}{" "}
                                    <small>{item?.email}</small>
                                  </span>

                                  <img
                                    className="customeraddeditcstmimg"
                                    src="assets/img/arrow-right-icon.svg"
                                    alt=""
                                  />
                                </Link>
                              </li>
                            ))}
                          </>
                        ) : (
                          <div className="text-center">
                            <h5>No data available</h5>
                          </div>
                        )}
                      </ul>
                    </div>
                  </>
                )}
              </div>
            </div>
            <ul className="navbar-nav navbar-right">
              {adminState === 1 || permission.length === 0 ? (
                <li className="setting_li custom_li">
                  <Link to="/settings">
                    <img src="assets/img/settings-icon.webp" alt="" />
                  </Link>
                </li>
              ) : (
                permission.map((permission) => (
                  <>
                    {permission.name === "Settings" && (
                      <li className="setting_li custom_li">
                        <Link to="/settings">
                          <img src="assets/img/settings-icon.webp" alt="" />
                        </Link>
                      </li>
                    )}
                  </>
                ))
              )}
              {/* <li className="setting_li custom_li">
              <Link to="/settings">
                <img src="assets/img/settings-icon.webp" alt="" />
              </Link>
            </li> */}
              <li className="timer_li custom_li">
                {/* <a href="#">
                <img src="assets/img/clock-icon.webp" alt="" />
              </a> */}
                <div className="timer_shift_popup">
                  <div className="timer_title">
                    <span>Shift Manager</span>
                  </div>
                  <form action="" method="" className="cus_form">
                    <div className="shift_timer_column">
                      <div className="timer_col_left">
                        <div className="form_row">
                          <div className="form_column formcol_half">
                            <div className="form-group">
                              <label>Employee name</label>
                              <input
                                type="text"
                                className="form-control"
                                name="fullname"
                                placeholder="Enter Full Name"
                              />
                            </div>
                          </div>
                          <div className="form_column formcol_half">
                            <div className="form-group">
                              <label>Location</label>
                              <input
                                type="text"
                                className="form-control"
                                name="location"
                                placeholder="Enter Location"
                              />
                            </div>
                          </div>
                          <div className="form_column formcol_half">
                            <div className="form-group">
                              <label>Enter Password</label>
                              <input
                                type="password"
                                className="form-control"
                                name="password"
                                placeholder="*********"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="timer_btns">
                          <div className="button_column">
                            <button
                              type="button"
                              className="cus_timer_btn green_btn"
                            >
                              Clock IN
                            </button>
                            <button
                              type="button"
                              className="cus_timer_btn red_btn"
                            >
                              Clock OUT
                            </button>
                          </div>
                          <div className="button_column">
                            <button
                              type="button"
                              className="cus_timer_btn green_btn"
                            >
                              Start Break
                            </button>
                            <button
                              type="button"
                              className="cus_timer_btn red_btn"
                            >
                              End Break
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="timer_col_right">
                        <div className="timer_content content_top">
                          <span>
                            Current time:
                            <br />
                            Aug 6, 2023, 6:13pm
                          </span>
                        </div>
                        <div className="form_row">
                          <div className="form_column formcol_full">
                            <div className="form-group">
                              <label>Status</label>
                              <select className="form-control" name="status">
                                <option value="Clocked IN">Clocked IN</option>
                                <option value="Clocked OUT">Clocked OUT</option>
                                <option value="Start Break">Start Break</option>
                                <option value="End Break">End Break</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="timer_content content_btm">
                          <span>
                            You are clocked in <br />
                            <b>14 minutes</b> <br />
                            at Aug 6, 2023, 6:13pm
                          </span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </li>
              <li className="dropdown">
                <a
                  href="#"
                  data-toggle="dropdown"
                  className="nav-link dropdown-toggle nav-link-user"
                >
                  <div className="user_icon">
                    <img
                      alt="image"
                      src={
                        props?.image
                          ? props.image
                          : data?.userDetails?.profileImage
                          ? data.userDetails.profileImage
                          : "assets/img/Avatar.webp"
                      }
                      className="user-img-radious-style userimageprofilecustm"
                    />
                    {/* <img
                    alt="image"
                    src={
                      props?.image
                        ? props?.image
                        : data?.userDetails?.profileImage
                    }
                    // src="assets/img/Avatar.png"
                    className="user-img-radious-style userimageprofilecustm"
                    // src="assets/img/Avatar.png"
                    // className="user-img-radious-style"
                  /> */}
                  </div>{" "}
                  <div className="user_info">
                    <span className="user_name">
                      {(adminState === 0 || permission.length > 0) &&
                        dataUser?.userDetails?.name}
                      {(adminState === 1 || permission.length === 0) &&
                        data?.userDetails?.userName}
                      {adminState === 1 ||
                        (permission.length === 0 && (
                          <small>{data?.userDetails?.email}</small>
                        ))}
                      {(adminState === 0 || permission.length > 0) && (
                        <small>{dataUser?.userDetails?.email}</small>
                      )}
                    </span>
                    <span className="angle_down">
                      <i className="fa fa-angle-down"></i>
                    </span>
                  </div>
                </a>
                <div className="dropdown-menu dropdown-menu-right pullDown">
                  <a
                    href="#"
                    className="dropdown-item has-icon"
                    onClick={Profile}
                  >
                    <i className="far fa-user"></i> Profile
                  </a>
                  <a
                    href="#"
                    className="dropdown-item has-icon"
                    onClick={Setting}
                  >
                    <i className="fas fa-cog"></i> Settings
                  </a>
                  <a
                    href="#"
                    className="dropdown-item has-icon"
                    onClick={logOutt}
                  >
                    <i className="fas fa-sign-out-alt"></i> Logout
                  </a>
                </div>
              </li>
            </ul>
          </nav>{" "}
          <MainSidebar />
        </div>
      )}
    </>
  );
};
export default NavBar;
