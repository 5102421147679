import React, { useEffect, useState } from "react";
import { forgotPassApi, otpVerifyApi } from "../Utility/Api";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";

const OtpVerify = () => {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState({});
  const [message, setMessage] = useState("");

  const location = useLocation();

  const Validation = () => {
    let error = {};

    if (!otp) {
      error.otp = "Please Enter Valid OTP";
    }

    if (Object.keys(error).length === 0) {
      //console.log(Object.keys(error).length);
      OTPVerifyApi();
    }
    return error;
  };

  const OnSubmit = () => {
    setError(Validation());
  };

  useEffect(() => {
    let emaill = location?.state?.email;
  }, []);

  const OTPVerifyApi = () => {
    let emaill = location?.state?.email;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AOtAACiWilonK7hAnZ-NM9oo9DZ-fT4GW.KVdND2dnApgbBT%2BE%2B6ibs9YAMVEINv6GKsWGd3diRl4"
    );

    var raw = JSON.stringify({
      email: emaill,
      otp: otp,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(otpVerifyApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setMessage(result?.message);
          //   navigate("/otpverify", { state: email });
        } else {
          setMessage(result?.message);
        }
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <>
      <div className="container">
        <h1>OTP Verify</h1>
        {/* <input
          type="text"
          value={otp}
          name="otp"
          onChange={(e) => {
            setOtp(e.target.value);
          }}
        />
        {error.otp && (
          <span className="customvalidationdesignlogin">{error.otp}</span>
        )}
        {message && (
          <span className="customvalidationdesignlogin">{message}</span>
        )}
        <button type="button" onClick={() => OnSubmit()}>
          Submit
        </button> */}
        <form>
          <div className="row mt-5">
            <div className="col-md-4 ">
              <div className="lablecustver">
                <label>Enter OTP</label>
              </div>
            </div>

            <div className="col-md-8">
              <input
                className="inutfieldcust"
                type="text"
                value={otp}
                name="otp"
                onChange={(e) => {
                  setOtp(e.target.value.trim());
                  setError("");
                }}
              />
            </div>
            {error.otp && (
              <span className="customvalidationdesignlogin">{error.otp}</span>
            )}
            {message && (
              <span className="customvalidationdesignlogin">{message}</span>
            )}
          </div>
          <div className="resendbuttoncustver">
            <button
              onClick={() => OnSubmit()}
              type="button"
              className="buttonresendver"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
export default OtpVerify;
