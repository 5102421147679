import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GetSubUserProfileApi, baseurl, getProfileApi } from "../Utility/Api";
import FreeTrail from "./FreeTrail";
import Swal from "sweetalert2";
// import Timer from "./Timer";

const SideUl = ({ props, onCompanyNameChange }) => {
  const [data, setData] = useState([]);
  const [dataUser, setDataUser] = useState([]);
  const [adminState, setAdminState] = useState("");
  const [permission, setPermission] = useState([]);
  const [days, setDays] = useState("");
  const [sub, setSub] = useState();
  const [status, setStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [data1, setData1] = useState([]);
  const [showModal1, setShowModal1] = useState(false);
  const [selectedState, setSelectedState] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [CompanyName, setCompanyName] = useState("");
  const [timezone, setTimezone] = useState("");

  useEffect(() => {
    let localAdmin = localStorage.getItem("admin");
    let localPerminssion = JSON.parse(localStorage.getItem("permission"));

    if (localAdmin === "0" && localPerminssion?.length) {
      setAdminState(localAdmin);
      setPermission(localPerminssion);
      // GetProfile();
      GetSubUserProfile();
    } else {
      GetProfile();
    }
    // GetCustomer(page, searchTerm);
  }, []);

  const GetProfile = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(getProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("get profile", result);
        if (result.status === true) {
          // console.log("comapny", result?.data?.companyDetails?.companyName);
          setData(result?.data);
          setStatus(
            result?.data?.companyDetails?.subscriptionStatus === "canceled" ||
              result?.data?.companyDetails?.subscriptionStatus === null ||
              result?.data?.companyDetails?.subscriptionStatus === "expired"
          );
          onCompanyNameChange(result?.data?.companyDetails?.companyName);
          setCompanyName(result?.data?.companyDetails?.companyName);

          //  console.log("companyName", result?.data?.companyDetails?.companyName);

          // if (
          //   result?.data?.companyDetails?.subscriptionStatus === "canceled" ||
          //   result?.data?.companyDetails?.subscriptionStatus === null
          // ) {
          //   navigate("/settings", { state: { activeState: 1 } });
          // } else if (
          //   result?.data?.companyDetails?.subscriptionStatus === "complete"
          // ) {
          //   setSub(2);
          // } else {
          //   setSub(1);
          // }
        } else if (
          result?.status === false &&
          result?.message === "Token has expired"
        ) {
          Swal.fire(result?.message);
          window.location.href = "https://app.toolboxpos.com";
        } else {
          // console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetSubUserProfile = () => {
    let local = localStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetSubUserProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("subuser profile", result);
        if (result.status === true) {
          setDataUser(result?.data);
          setCompanyName(result?.data?.companyDetails?.companyName);
          setTimezone(result?.timezone);
          //console.log("setTimezone", result?.timezone);
        } else if (
          result?.status === false &&
          result?.message === "Token has expired"
        ) {
          Swal.fire(result?.message);
          window.location.href = "https://app.toolboxpos.com";
        } else {
          // console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const navigate = useNavigate();

  const logOutt = () => {
    localStorage.clear();
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith("token")) {
        localStorage.removeItem(key);
        localStorage.clear();
      }
    });
    window.location.href = "https://app.toolboxpos.com";
    //navigate("/");
  };

  const Profile = () => {
    navigate("/settings");
  };

  const Setting = () => {
    navigate("/settings");
  };
  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      if (hasMore && !loading) {
        setPage((prevPage) => prevPage + 1);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [hasMore, loading]);

  useEffect(() => {
    setData1([]);
    setFilteredData([]);
    setPage(1);
    GetCustomer(1, searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    if (page > 1) {
      GetCustomer(page, searchTerm);
    }
  }, [page]);

  const GetCustomer = (page, searchTerm) => {
    setLoading(true);
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${baseurl}getAllCustomer?pageSize=20&page=${page}&keyword=${searchTerm}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          // console.log("customer", result.data);
          // setData1((prevData) =>
          //   Array.isArray(prevData)
          //     ? [...prevData, ...result.data]
          //     : result.data
          // );
          // setFilteredData((prevData) =>
          //   Array.isArray(prevData)
          //     ? [...prevData, ...result.data]
          //     : result.data
          // );
          setFilteredData((prevData) => {
            // Combine previous data and new data
            const combinedData = Array.isArray(prevData)
              ? [...prevData, ...result?.data]
              : result?.data;

            // Remove duplicates by creating a Set of unique items based on a unique identifier (e.g., id)
            const uniqueData = Array.from(
              new Set(combinedData.map((item) => item.id))
            ).map((id) => combinedData.find((item) => item.id === id));

            return uniqueData;
          });
          setHasMore(result.data.length > 0);
          // setData1(result?.data);
          setSelectedCustomer(result?.data);
        } else {
          setHasMore(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };
  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
    setShowModal1(true);
  };
  // const handleSearchTermChange = (event) => {
  //   const term = event.target.value;
  //   setSearchTerm(term);
  //   const lowerCaseSearchTerm = term.toLowerCase();
  //   setShowModal1(true);
  //   //setCustomerState(true);
  //   setData1([]);
  //   setPage(1);
  //   const matchedCustomer = data1.find(
  //     (customer) =>
  //       customer?.firstName.toLowerCase().includes(lowerCaseSearchTerm) ||
  //       customer?.email.toLowerCase().includes(lowerCaseSearchTerm) ||
  //       customer?.lastName.toLowerCase().includes(lowerCaseSearchTerm)
  //   );
  //   //setMatched(matchedCustomer);
  //   setSelectedCustomer(matchedCustomer);
  // };

  // const filteredData = data1?.filter((item) => {
  //   const lowerCaseSearchTerm = searchTerm.toLowerCase();

  //   return (
  //     item.firstName.toLowerCase().includes(lowerCaseSearchTerm) ||
  //     item.email.toLowerCase().includes(lowerCaseSearchTerm) ||
  //     item.phoneNumber.toLowerCase().includes(lowerCaseSearchTerm)
  //   );
  // });

  const handlePopupCloseCustomer = () => {
    setShowModal1(false);
  };
  const handleCustomerSelection = (customer) => {
    setSearchTerm(`${customer.firstName}`);
    setSelectedCustomer(customer);
    setShowModal1(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const dropdown = document.querySelector(".searchcustomer_list");

      if (dropdown && !dropdown.contains(event.target)) {
        setShowModal1(false);
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [setShowModal1]);

  const [shiftModal, setShiftModal] = useState(false);
  const toggleShiftModal = () => {
    setShiftModal(!shiftModal); // Toggle the shiftModal state
    setCompanyName(CompanyName);
  };

  return (
    <>
      <div className="common_search">
        <div className="search_input">
          <input
            type="text"
            className="form-control"
            placeholder="Search Customer"
            value={searchTerm}
            onChange={handleSearchTermChange}
          />
          <button type="button" className="search_btn">
            <img src="assets/img/search-icon.webp" alt="" />
          </button>
        </div>
        <div className={`searchcustomer_list ${showModal1 ? "active" : ""}`}>
          <div className="srchcustmer_title common_title">
            <h4>Search Customer </h4>
            <a className="popup_close" onClick={handlePopupCloseCustomer}>
              <span className="close_icon"></span>
            </a>
          </div>
          <div className="customer_list">
            <ul>
              {filteredData?.length ? (
                <>
                  {filteredData?.map((item) => (
                    <li key={item.id}>
                      <Link
                        to="/CustomerDetail"
                        state={{ data: item }}
                        // onClick={() => {
                        //   //handleCustomerSelection(item);
                        //  // setSelectedState(true);
                        // }}
                        key={item.id}
                        className="linkcustm"
                      >
                        <div key={item.id} className="custmer_img">
                          {item?.profileImage ? (
                            <img src={item?.profileImage} alt="" />
                          ) : (
                            <img
                              src="assets/img/customer-info-img.webp"
                              alt=""
                            />
                          )}
                        </div>
                        <span>
                          {item?.firstName} {item?.lastName}{" "}
                          <small>{item?.email}</small>
                          <small>{item?.phoneNumber}</small>
                        </span>

                        <img
                          className="customeraddeditcstmimg"
                          src="assets/img/arrow-right-icon.svg"
                          alt=""
                        />
                      </Link>
                    </li>
                  ))}
                  {loading && <li>Loading...</li>}
                </>
              ) : (
                <div className="text-center">
                  <h5>No data available</h5>
                </div>
              )}
            </ul>
          </div>
        </div>
      </div>

      <ul className="navbar-nav navbar-right">
        {adminState === 1 || permission?.length === 0 ? (
          <li className="setting_li custom_li">
            <Link to="/settings">
              <img src="assets/img/settings-icon.webp" alt="" />
            </Link>
          </li>
        ) : (
          permission?.map((permission) => (
            <>
              {permission.name === "Settings" && (
                <li className="setting_li custom_li" key={permission.id}>
                  <Link to="/settings">
                    <img src="assets/img/settings-icon.webp" alt="" />
                  </Link>
                </li>
              )}
            </>
          ))
        )}

        {/* <li className="setting_li custom_li">
          <Link to="/settings">
            <img src="assets/img/settings-icon.webp" alt="" />
          </Link>
        </li> */}
        {/* {adminState === "0" ? (
          <>
            <li
              className={
                shiftModal ? "timer_li custom_li active" : "timer_li custom_li"
              }
            >
              <a href="#" onClick={toggleShiftModal}>
                <img src="assets/img/clock-icon.webp" alt="" />
              </a>
              {shiftModal && (
                <Timer
                  name={CompanyName}
                  user={dataUser?.userDetails?.name}
                  time={timezone}
                />
              )}
            </li>
          </>
        ) : (
          <>
            <li
              className={
                shiftModal ? "timer_li custom_li active" : "timer_li custom_li"
              }
            >
              <a href="#">
                <img src="assets/img/clock-icon.webp" alt="" />
              </a>
              {shiftModal && <Timer />}
            </li>
          </>
        )} */}
        <li className="dropdown">
          <a
            href="#"
            data-toggle="dropdown"
            className="nav-link dropdown-toggle nav-link-user"
          >
            <div className="user_icon">
              <img
                alt="image"
                src={
                  props?.image
                    ? props?.image
                    : data?.userDetails?.profileImage
                    ? data?.userDetails?.profileImage
                    : "assets/img/Avatar.webp"
                }
                className="user-img-radious-style userimageprofilecustm"
              />
              {/* <img
                alt="image"
                src={
                  props?.image ? props?.image : data?.userDetails?.profileImage
                }
                // src="assets/img/Avatar.png"
                className="user-img-radious-style userimageprofilecustm"
              /> */}
            </div>{" "}
            <div className="user_info">
              <span className="user_name">
                {(adminState === 0 || permission?.length > 0) &&
                  dataUser?.userDetails?.name}
                {((adminState === 1 || permission?.length === 0) &&
                  data?.userDetails?.userName) ||
                  props?.name}
                {adminState === 1 ||
                  (permission?.length === 0 && (
                    <small>{data?.userDetails?.email}</small>
                  ))}
                {adminState === 0 ||
                  (permission?.length > 0 && (
                    <small>{dataUser?.userDetails?.email}</small>
                  ))}
              </span>
              <span className="angle_down">
                <i className="fa fa-angle-down"></i>
              </span>
            </div>
          </a>
          <div className="dropdown-menu dropdown-menu-right pullDown">
            <a href="#" className="dropdown-item has-icon" onClick={Profile}>
              <i className="far fa-user"></i> Profile
            </a>
            <a href="#" className="dropdown-item has-icon" onClick={Setting}>
              <i className="fas fa-cog"></i> Settings
            </a>
            <a href="#" className="dropdown-item has-icon" onClick={logOutt}>
              <i className="fas fa-sign-out-alt"></i> Logout
            </a>
          </div>
        </li>
      </ul>
    </>
  );
};
export default SideUl;
