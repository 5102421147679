import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import {
  AddRecieptApi,
  baseurl,
  getProfileApi,
  GetRecieptApi,
  uploadImageApi,
} from "../Utility/Api";
import Swal from "sweetalert2";
import { data } from "jquery";

const RecieptConfig = () => {
  const [loading, setLoading] = useState("");
  const [printType, setPrintType] = useState();
  const [title, setTitle] = useState("");
  const [logo, setLogo] = useState("");
  const [isLogo, setIsLogo] = useState(true);
  const [logoSize, setLogoSize] = useState();
  const [logoPosition, setLogoPosition] = useState();
  const [header, setHeader] = useState("");
  const [displySales, setDisplaySales] = useState(false);
  const [footer, setFooter] = useState("");
  const [note, setNote] = useState(false);
  const [displayBarcode, setDisplayBarcode] = useState(false);
  const [displayNote, setDisplayNote] = useState(false);
  const [imageMsg, setImageMsg] = useState("");
  const [states, setStates] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    prodname: 0,
    prodname_sku: 0,
    sku: 0,
  });
  const [customerDetails, setCustomerDetails] = useState({
    fullname: 0,
    email: 0,
    phone: 0,
    address: 0,
  });
  const [recieptId, setRecieptId] = useState("");
  const [gstNumber, setSGTNumber] = useState("");
  const [leftMargin, setLeftMargin] = useState(0);
  const [rightMargin, setRightMargin] = useState(0);
  const [gstOnOff, setGstOnOff] = useState(false);

  const handleToggleChange = () => {
    setGstOnOff((prevState) => !prevState);
  };

  const handlePreview = () => {
    // const logoSizeClass = isLogo ? (logoSize === 1 ? "logo-large" : "logo-small") : "";
    const logoSizeClass = isLogo ? `logo-size-${logoSize}` : "";
    const logoPositionClass = isLogo ? `logo-position-${logoPosition}` : "";

    Swal.fire({
      customClass: {
        container: "custom-swal-container",

        confirmButton: "custom-swal-confirm-button",
      },
      title: "",
      html: `
      <body style="font-family: Inter, sans-serif">
    <div>
      <table
        style="width: 400px; margin: 0px auto; padding: 25px 25px 15px"
        border="0"
      >
        <tbody>
          <tr>
            <td>
              <table style="width: 100%; text-align: center" border="0">
                <tbody>
                  <tr>
                     <td style="text-align: ${
                       logoPosition === 0 ? "left" : "center"
                     }; margin-bottom: 25px;">${
        logo
          ? `<img
              src=${logo} style=width: ${logoSize === 1 ? "100px" : "50px"},
    height:  ${logoSize === 1 ? "110px" : "55px"},
  
    margin: '0 auto',
          />`
          : ""
      }
                    
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        font-size: 13px;
                        font-weight: 700;
                        line-height: 20px;
                        color: #000;
                      "
                    >
                          ${title ? title : ""}
                    </td>
                  </tr>
                  
                  <tr>
                    <td style="padding: 8px 0px"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        font-size: 13px;
                        font-weight: 400;
                     
                        color: #000;
                      "
                    >
                    <pre style="
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 20px;
                        color: #000;
                      "> ${header ? `${header}` : ""}  </pre>
                
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <table style="width: 100%" border="0">
                <tbody>
                  <tr>
                    <td
                      style="
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 20px;
                        color: #000;
                        margin-right:20px
                      "
                    >
                      <span style="font-weight: 700 ;    margin-left: -300px;">Sales Ref:</span> #100
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 20px;
                        color: #000;
                      "
                    >
                      <span style="font-weight: 700;    margin-left: -259px;">Customer:</span> ${
                        customerDetails.fullname === 1 ? "Test Subject" : "NA"
                      }
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 20px;
                        color: #000;
                      "
                    >
                     <span style="    margin-left: -259px;"> 04/03/2023 11:33:46 pm</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td style="padding: 8px 0px"></td>
          </tr>
          <tr>
            <td>
              <table class="tabledata" style="width: 100%" border="0">
                <thead>
                  <tr>
                    <th
                      style="
                        font-size: 13px;
                        font-weight: 700;
                        line-height: 20px;
                        color: #000;
                        text-align: center;
                        border-bottom: 2px dashed #000;
                        padding: 4px;
                      "
                    >
                      QTY
                    </th>
                    <th
                      style="
                        font-size: 13px;
                        font-weight: 700;
                        line-height: 20px;
                        color: #000;
                        text-align: left;
                        border-bottom: 2px dashed #000;
                        padding: 4px;
                      "
                    >
                      Item Name
                    </th>
                    <th
                      style="
                        font-size: 13px;
                        font-weight: 700;
                        line-height: 20px;
                        color: #000;
                        text-align: right;
                        border-bottom: 2px dashed #000;
                        padding: 4px;
                      "
                    >
                      Price
                    </th>
                    <th
                      style="
                        font-size: 13px;
                        font-weight: 700;
                        line-height: 20px;
                        color: #000;
                        text-align: right;
                        border-bottom: 2px dashed #000;
                        padding: 4px;
                      "
                    >
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style="
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 20px;
                        color: #000;
                        text-align: center;

                        padding: 4px;
                      "
                    >
                      5
                    </td>
                    <td
                      style="
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 20px;
                        color: #000;
                        text-align: left;

                        padding: 4px;
                      "
                    >
                      ${selectedOption.prodname === 1 ? "Test Product" : "N/A"}
                    </td>
                    <td
                      style="
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 20px;
                        color: #000;
                        text-align: right;

                        padding: 4px;
                      "
                    >
                      5.00
                    </td>
                    <td
                      style="
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 20px;
                        color: #000;
                        text-align: right;

                        padding: 4px;
                      "
                    >
                      25.00
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 20px;
                        color: #000;
                        text-align: center;
                        border-bottom: 2px dashed #000;
                        padding: 4px;
                      "
                    >
                      1
                    </td>
                    <td
                      style="
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 20px;
                        color: #000;
                        text-align: left;
                        border-bottom: 2px dashed #000;
                        padding: 4px;
                      "
                    >
                      Test Product 2
                    </td>
                    <td
                      style="
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 20px;
                        color: #000;
                        text-align: right;
                        border-bottom: 2px dashed #000;
                        padding: 4px;
                      "
                    >
                      35.00
                    </td>
                    <td
                      style="
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 20px;
                        color: #000;
                        text-align: right;
                        border-bottom: 2px dashed #000;
                        padding: 4px;
                      "
                    >
                      35.00
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td
                      style="
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 20px;
                        color: #000;
                        text-align: left;

                        padding: 4px;
                      "
                      colspan="2"
                    >
                      Subtotal
                    </td>
                    <td
                      style="
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 20px;
                        color: #000;
                        text-align: right;

                        padding: 4px;
                      "
                      colspan="2"
                    >
                      60.00
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 20px;
                        color: #000;
                        text-align: left;
                        padding: 4px;
                      "
                      colspan="2"
                    >
                      Discounts
                    </td>
                    <td
                      style="
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 20px;
                        color: #000;
                        text-align: right;

                        padding: 4px;
                      "
                      colspan="2"
                    >
                      0.00
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 20px;
                        color: #000;
                        text-align: left;

                        padding: 4px;
                      "
                      colspan="2"
                    >
                      Tax(13%)
                    </td>
                    <td
                      style="
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 20px;
                        color: #000;
                        text-align: right;

                        padding: 4px;
                      "
                      colspan="2"
                    >
                      7.80
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        font-size: 15px;
                        font-weight: 700;
                        line-height: 20px;
                        color: #000;
                        text-align: left;
                        border-bottom: 2px dashed #000;
                        padding: 4px;
                      "
                      colspan="2"
                    >
                      Grand Total
                    </td>
                    <td
                      style="
                        font-size: 15px;
                        font-weight: 700;
                        line-height: 20px;
                        color: #000;
                        text-align: right;
                        border-bottom: 2px dashed #000;
                        padding: 4px;
                      "
                      colspan="2"
                    >
                      67.80
                    </td>
                  </tr>
                </tfoot>
              </table>
            </td>
          </tr>
          <tr>
            <td style="padding: 8px 0px"></td>
          </tr>
          <tr>
            <td>
              <table style="width: 100%" border="0">
                <tbody>
                  <tr>
                    <td
                      style="
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 20px;
                        color: #000;
                        text-align: left;
                      "
                    >
                      <span style="font-weight: 600">Received:</span> $50.00
                    </td>
                    <td
                      style="
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 20px;
                        color: #000;
                      "
                    >
                      <span style="font-weight: 700">Debit Card:</span>
                      00/00/2000 0:00 pm
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 20px;
                        color: #000;
                      "
                    >
                      <span style="font-weight: 600;    margin-left: -36px;">Received:</span> $17.80
                    </td>
                    <td
                      style="
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 20px;
                        color: #000;
                      "
                    >
                      <span style="font-weight: 700; margin-left: -70px;">Cash:</span> 00/00/2000
                      0:00 pm
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 20px;
                        color: #000;
                            text-align: left;
                      "
                      colspan="2"
                    >
                   Change Given: <span style="font-weight: 700">$17.80</span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 20px;
                        color: #000;
                            text-align: left;
                      "
                      colspan="2"
                    >
                      <span style="font-weight: 700">Cashier:</span> Toolbox
                      Team
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td style="padding: 12px 0px"></td>
          </tr>
          <tr>
            <td>
              <table style="width: 100%; text-align: center" border="0">
                <tbody>
                  <tr>
                    <td
                      style="
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 20px;
                        color: #000;
                      "
                    >
                    <pre>
                    ${footer ? `${footer}` : "--"}
                      </pre>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td style="padding: 8px 0px"></td>
          </tr>
          <tr>
            <td>
              <table style="width: 100%; text-align: center" border="0">
                <tbody>
              
                   <tr>
                     <td
                       style={{
                         fontSize: "10px",
                         fontWeight: "400",
                         lineHeight: "20px",
                         color: "#000",
                       }}
                     >
                       Hst #: ${gstNumber ? `${gstNumber}` : ""}
                     </td>
                   </tr>
               
                  <tr>
                    <td>${
                      displayBarcode === true
                        ? ` <img src="img/receipt-barcode.webp" alt="" />`
                        : ""
                    }</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </body>
  `,
    });
  };

  const Reciept = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      type: printType,
      receiptTitle: title,
      isLogo: isLogo ? 1 : 0,
      logo: logo ? logo : "",
      logoSize: logoSize,
      logoPosition: logoPosition,
      headerInfo: header,
      displaySalesPersonName: displySales ? 1 : 0,
      items: {
        name: selectedOption.prodname,
        sku: selectedOption.sku,
        note: displayNote ? 1 : 0,
      },
      customer: {
        name: customerDetails.fullname,
        email: customerDetails.email,
        phoneNumber: customerDetails.phone,
        address: customerDetails.address,
      },
      footerInfo: footer,
      displayBarcode: displayBarcode ? 1 : 0,
      displayPublicNote: note ? 1 : 0,
      gst: gstNumber,
      rightMargin: Number(rightMargin),
      leftMargin: Number(leftMargin),
      gstButton: gstOnOff ? 1 : 0,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("raw", raw);
    fetch(AddRecieptApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("reciep ,", result);
        if (result?.status === true) {
          Swal.fire(result?.message);
          GetReciept();
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const handlePrintTypeChange = (e) => {
    setPrintType(parseInt(e.target.value, 10));
  };

  const handleLogoChange = (e) => {
    if (e.target.value === "yes") {
      setIsLogo(true);
    } else if (e.target.value === "no") {
      setIsLogo(false);
      setLogo("");
    } else if (e.target.value === "yes" && e.target.value === "no") {
      setIsLogo(false);
      setLogo("");
    }
  };

  // const handleLogoChange = (e) => {
  //   setIsLogo(e.target.value === "yes");
  // };

  const handleLogoSizeChange = (e) => {
    setLogoSize(e.target.value);
  };

  const handleLogoPositionChange = (e) => {
    setLogoPosition(e.target.value);
  };
  const handleCheckboxChange = () => {
    setDisplaySales((prevDisplay) => !prevDisplay);
  };
  const handleCheckboxChangeBarcode = () => {
    setDisplayBarcode((prevDisplay) => !prevDisplay);
  };
  const handleOptionChange = (e) => {
    const { id } = e.target;
    let updatedOptions = {};

    if (id === "prodname_sku") {
      updatedOptions = {
        prodname: 1,
        sku: 1,
        prodname_sku: 1,
      };
    } else if (id === "prodname") {
      updatedOptions = {
        prodname: 1,
        sku: 0,
        prodname_sku: 0,
      };
    } else if (id === "sku") {
      updatedOptions = {
        prodname: 0,
        sku: 1,
        prodname_sku: 0,
      };
    }

    setSelectedOption(updatedOptions);
  };

  const handleCheckboxChangeNote = () => {
    setDisplayNote((prevDisplay) => !prevDisplay);
  };
  const handleCheckboxChangeDisplayNote = () => {
    setNote((prevDisplay) => !prevDisplay);
  };
  const handleCheckboxChangeName = (e) => {
    const { name, checked } = e.target;
    setCustomerDetails((prevDetails) => ({
      ...prevDetails,
      [name]: checked ? 1 : 0,
    }));
  };

  const GetReciept = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetRecieptApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("reciept", result);
        if (result?.status === true) {
          if (!result?.data) {
            setStates(true);
          } else {
            const data = result?.data;
            setRecieptId(data?.id);
            setPrintType(data?.type);
            setTitle(data?.receiptTitle);
            setLogo(data?.logo);
            setIsLogo(data?.isLogo === 1);
            setLogoSize(data?.logoSize);
            setLogoPosition(data?.logoPosition);
            setHeader(data?.headerInfo);
            setDisplaySales(data?.displaySalesPersonName === 1);
            setFooter(data?.footerInfo);
            setNote(data?.displayPublicNote === 1);
            setDisplayBarcode(data?.displayBarcode === 1);
            setDisplayNote(data?.note === 1);
            setSGTNumber(data?.gst);
            setLeftMargin(data?.leftMargin);
            setRightMargin(data?.rightMargin);
            setGstOnOff(data?.gstButton);
            // setImageMsg(""); // Assuming image message is not returned from API
            //setStates(data.activeInactive === 1);

            // Set selected options based on API response
            setSelectedOption({
              prodname: data?.items.name === 1 ? 1 : 0,
              prodname_sku:
                data?.items.name === 1 && data?.items.sku === 1 ? 1 : 0,
              sku: data?.items.sku === 1 ? 1 : 0,
            });

            // Set customer details based on API response
            setCustomerDetails({
              fullname: data?.customer.name === 1 ? 1 : 0,
              email: data?.customer.email === 1 ? 1 : 0,
              phone: data?.customer.phoneNumber === 1 ? 1 : 0,
              address: data?.customer.address === 1 ? 1 : 0,
            });
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    GetReciept();
  }, []);

  const handleImagesDevice = (e) => {
    var file = e.target.files[0];

    if (file) {
      const minSizeInBytes = 500; // 5 KB
      const maxSizeInBytes = 1024 * 1024; // 1 MB
      const maxDimension = 500; // Maximum dimension (width or height) in pixels

      if (file.size < minSizeInBytes) {
        // Image size is below the minimum allowed size (5 KB)
        setImageMsg("Image size is below the minimum allowed size (500px)");
        e.target.value = null;
        setTimeout(() => {
          setImageMsg("");
        }, 2000);
        return;
      }

      // Create a new FileReader to read the image
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (event) {
        var img = new Image();
        img.src = event.target.result;
        img.onload = function () {
          // Check the dimensions of the image
          if (img.width > maxDimension || img.height > maxDimension) {
            // Image dimensions exceed the maximum allowed dimension (500px)
            setImageMsg(
              "Image dimensions exceed the maximum allowed dimension (500px)"
            );

            e.target.value = null;
            setTimeout(() => {
              setImageMsg("");
            }, 2000);
            return;
          }

          const fileName = file.name;
          const lastDotIndex = fileName.lastIndexOf(".");
          const baseName = fileName.substring(0, lastDotIndex);
          const extension = fileName.substring(lastDotIndex + 1);

          // Only allow files with a single dot before the extension (e.g., image.jpg, not image.34.jpg)
          if (baseName.includes(".")) {
            setImageMsg(
              "File name is not valid. Only one dot is allowed before the extension."
            );
            e.target.value = null;

            // Hide the message after 2 seconds
            setTimeout(() => {
              setImageMsg("");
            }, 2000);

            return;
          }

          // If image passes all checks, upload it
          let size = file.type.replace("/", ".");
          var blob = file.slice(0, file.size, file.type);
          const newName = new Date().getTime() + size;
          var newFile = new File([blob], newName, { type: file.type });

          UploadImage(newFile);
        };
      };
    }
  };

  const UploadImage = async (newFile) => {
    //setLoading(true);
    var formdata = new FormData();
    formdata.append("file", newFile);
    formdata.append("type", "logo-images");

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(uploadImageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLogo(result?.data);
        setImageMsg("");
        // setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        //setLoading(false);
      });
  };

  const HandleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete image",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c76cff",
      cancelButtonColor: "#efefef",
      confirmButtonText: "Yes, delete it!",
      confirmButtonText: "<span style='color: white;'>Yes, delete it!</span>",
      cancelButtonText: "<span style='color: black;'>cancel!</span>",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "sweet-alert2-container-zindex",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
          confirmButtonColor: "#c76cff",
          customClass: {
            container: "sweet-alert2-container-zindex",
          },
        }).then(() => {
          setLogo("");
        });
      }
    });
  };

  const UpdateReciept = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      type: printType,
      receiptTitle: title,
      isLogo: isLogo ? 1 : 0,
      logo: logo ? logo : "",
      logoSize: logoSize,
      logoPosition: logoPosition,
      headerInfo: header,
      displaySalesPersonName: displySales ? 1 : 0,
      items: {
        name: selectedOption.prodname,
        sku: selectedOption.sku,
        note: displayNote ? 1 : 0,
      },
      customer: {
        name: customerDetails.fullname,
        email: customerDetails.email,
        phoneNumber: customerDetails.phone,
        address: customerDetails.address,
      },
      footerInfo: footer,
      displayBarcode: displayBarcode ? 1 : 0,
      displayPublicNote: note ? 1 : 0,
      gst: gstNumber ? gstNumber : null,
      rightMargin: Number(rightMargin),
      leftMargin: Number(leftMargin),
      gstButton: gstOnOff === false ? 0 : 1,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("update raww", raw);
    console.log("raww", `${baseurl}updateReceiptConfig/${recieptId}`);
    fetch(`${baseurl}updateReceiptConfig/${recieptId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          Swal.fire(result?.message);
          GetReciept();
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  return (
    <>
      {loading ? (
        <div className="landing_loader">
          <div className="innerloader">
            <img src="img/spin-1s-156px.gif" alt="" />
          </div>
        </div>
      ) : null}
      <div class="setting_content receipt_config_setup">
        <h4>Receipt Configuration & Setup</h4>
        <div class="inner_block plan_billing_setting">
          <form class="setting_form" action="" method="post">
            <div class="block_column wd100">
              <h5>Receipt Configuration & Setup</h5>
              <p>
                Here you can manage and configure your stores receipt, how it
                will look and how it will be printed
              </p>
              <div class="inner_field">
                <div class="tax_label_block">
                  <div class="tax_label">
                    <h6>Default print type</h6>
                    <span>
                      This is the default print method in cash register during a
                      sale
                    </span>
                  </div>
                  <div className="custom_radio">
                    <label htmlFor="thermal">
                      <input
                        type="radio"
                        name="printtype"
                        id="thermal"
                        value="0"
                        checked={printType === 0}
                        onChange={handlePrintTypeChange}
                      />
                      <span className="radio_check"></span>{" "}
                      <span className="label_txt">Thermal</span>
                    </label>
                    <label htmlFor="fullpage">
                      <input
                        type="radio"
                        name="printtype"
                        id="fullpage"
                        value="1"
                        checked={printType === 1}
                        onChange={handlePrintTypeChange}
                      />
                      <span className="radio_check"></span>{" "}
                      <span className="label_txt">Full Page</span>
                    </label>
                    <label htmlFor="noreceipt">
                      <input
                        type="radio"
                        name="printtype"
                        id="noreceipt"
                        value="2"
                        checked={printType === 2}
                        onChange={handlePrintTypeChange}
                      />
                      <span className="radio_check"></span>{" "}
                      <span className="label_txt">No receipt</span>
                    </label>
                  </div>
                </div>
                <div class="tax_label_block">
                  <div class="tax_label">
                    <h6>Receipt Template</h6>
                    <span>Choose a template for your receipt</span>
                  </div>
                  <div class="custom_radio">
                    <div class="cusradio">
                      {/* <label for="template1">
                        <input
                          type="radio"
                          name="receipt_template"
                          id="template1"
                          checked
                        />
                        <span class="radio_check"></span>
                      </label> */}
                      <div class="cus_recpt_temp receipt_templ">
                        <span class="recpt_title">Standard</span>
                        <div class="template_block">
                          <img
                            src="assets/img/receipt-template-img.webp"
                            alt=""
                          />
                        </div>
                        <span class="receipt_note">
                          This template is recconmended
                        </span>
                      </div>
                    </div>
                    {/* <div class="cusradio">
                      <label for="template2">
                        <input
                          type="radio"
                          name="receipt_template"
                          id="template2"
                        />
                        <span class="radio_check"></span>
                      </label>
                      <div class="cus_recpt_temp receipt_templ">
                        <span class="recpt_title">Standard</span>
                        <div class="template_block"></div>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div class="receipt_other_info">
                  <div class="reciept_blockbox header_receipt_block">
                    <div class="receipt_left">
                      <label>
                        Header{" "}
                        <span>
                          Customize your receipts header, to your specifications
                        </span>
                      </label>
                    </div>
                    <div class="receipt_right">
                      <div class="receipt_field receipt_title">
                        <div class="form-group">
                          <label class="cus_label">Receipt Title</label>
                          <input
                            type="text"
                            class="form-control"
                            name="receipt_title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                          <span class="receipt_note">
                            This title will be displayed at the top of the
                            receipt
                          </span>
                        </div>
                      </div>
                      <div class="receipt_logo_field">
                        <div class="logo_field cus_logo_field">
                          <div class="form-group">
                            <label class="cus_label">Logo</label>
                            <div class="upload_logo">
                              {logo ? (
                                <i
                                  class="fa-regular fa-circle-xmark deletimgcustm1"
                                  onClick={() => HandleDelete()}
                                ></i>
                              ) : null}
                              {logo ? (
                                <img src={logo} class="reciprtconfigimages" />
                              ) : (
                                <>
                                  <input
                                    type="file"
                                    class="file_upload"
                                    name="file_upload"
                                    accept="image/jpeg, image/jpg, image/png"
                                    onChange={handleImagesDevice}
                                  />
                                  <span>Drop or upload logo here</span>
                                </>
                              )}
                            </div>
                            <span class="receipt_note">
                              Upload a JPG, PNG or GIF file, no more than 500px
                            </span>
                            <span style={{ color: "red", fontSize: "13px" }}>
                              {" "}
                              {imageMsg}
                            </span>
                          </div>
                        </div>
                        <div class="nologo_field cus_logo_field">
                          <div class="form-group">
                            <label class="cus_label">No Logo</label>
                            <div class="custom_radio">
                              <label for="nologo">
                                <input
                                  type="radio"
                                  name="nologo"
                                  id="nologo"
                                  value="no"
                                  checked={!logo}
                                  onChange={handleLogoChange}
                                />
                                {/* <input
                                  type="radio"
                                  name="nologo"
                                  id="nologo"
                                  value="no"
                                  checked={!isLogo}
                                  onChange={handleLogoChange}
                                /> */}
                                <span class="radio_check"></span>
                              </label>
                            </div>
                            <div class="no_logo">
                              <img src="assets/img/no-logo-icon.webp" alt="" />
                            </div>
                            <span class="receipt_note">
                              You may opt to have no logo on your receipt
                            </span>
                          </div>
                        </div>
                        <div class="logosize_field cus_logo_field">
                          <div class="form-group">
                            <label class="cus_label">Logo Size:</label>
                            <div class="size_field">
                              <select
                                className="form-control"
                                value={logoSize}
                                onChange={handleLogoSizeChange}
                              >
                                <option>Select Size</option>
                                <option value={1}>Large Size</option>
                                <option value={0}>Small Size</option>
                              </select>

                              <span class="receipt_note">
                                Chose weather you want your logo to be
                                displayed, large or small
                              </span>
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="cus_label">Logo Positions:</label>
                            <div class="size_field">
                              <select
                                className="form-control"
                                value={logoPosition}
                                onChange={handleLogoPositionChange}
                              >
                                <option>Select Position</option>
                                <option value={0}>Left</option>
                                <option value={1}>Center</option>
                              </select>

                              <span class="receipt_note">
                                Chose the position of your logo on your receipt
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="header_info_field">
                        <div class="form-group">
                          <label class="cus_label">Header information</label>
                          <textarea
                            class="form-control"
                            name="header_info"
                            value={header}
                            onChange={(e) => setHeader(e.target.value)}
                          ></textarea>
                          <span class="receipt_note">
                            Try to limit this to one paragraph
                          </span>
                        </div>
                      </div>
                      <div class="sales_person_field">
                        <div class="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              name="salesperson"
                              checked={displySales}
                              onChange={handleCheckboxChange}
                            />{" "}
                            Display Sales Person (Served by)
                          </label>
                          <span class="receipt_note">
                            This label will display the sales person on the
                            receipt
                          </span>
                        </div>
                        {/* <div class="sales_field">
                          <div class="form-group">
                            <label class="cus_label">
                              Served by Sales Person label:
                            </label>
                            <input
                              type="text"
                              name="served_sales_person"
                              class="form-control"
                              placeholder="Enter Sales Person"
                            />
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <div class="reciept_blockbox">
                    <div class="receipt_left">
                      <label>
                        Line Items{" "}
                        <span>
                          Customize the line items displayed on your reciept
                        </span>
                      </label>
                    </div>
                    <div class="receipt_right">
                      <div className="eachline_receipt">
                        <label className="cus_label">
                          For each line item show:
                        </label>
                        <div className="custom_radio">
                          <label htmlFor="prodname">
                            <input
                              type="radio"
                              name="lineitems"
                              id="prodname"
                              checked={
                                selectedOption.prodname === 1 &&
                                selectedOption.sku === 0
                              }
                              onChange={handleOptionChange}
                            />
                            <span className="radio_check"></span> Product Name
                          </label>
                          <label htmlFor="prodname_sku">
                            <input
                              type="radio"
                              name="lineitems"
                              id="prodname_sku"
                              checked={
                                selectedOption.prodname === 1 &&
                                selectedOption.sku === 1
                              }
                              onChange={handleOptionChange}
                            />
                            <span className="radio_check"></span> Product Name
                            and Sku
                          </label>
                          <label htmlFor="sku">
                            <input
                              type="radio"
                              name="lineitems"
                              id="sku"
                              checked={
                                selectedOption.sku === 1 &&
                                selectedOption.prodname === 0
                              }
                              onChange={handleOptionChange}
                            />
                            <span className="radio_check"></span> SKU
                          </label>
                        </div>
                      </div>

                      <div class="display_notes">
                        <div class="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              name="notes"
                              checked={displayNote}
                              onChange={handleCheckboxChangeNote}
                            />{" "}
                            Display Notes
                          </label>
                          <span class="receipt_note">
                            Display notes you add on individual products
                          </span>
                        </div>
                        {/* <div class="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              name="printproduct"
                              checked
                            />{" "}
                            Print products with a price of Zero
                          </label>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <div class="reciept_blockbox">
                    <div class="receipt_left">
                      <label>
                        Customer Information{" "}
                        <span>
                          Customize which customer information is displayed on
                          your reciept
                        </span>
                      </label>
                    </div>
                    <div className="receipt_right">
                      <div className="eachline_receipt">
                        <label className="cus_label">
                          Display customer details:
                        </label>
                        <div className="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              name="fullname"
                              id="fullname"
                              checked={customerDetails.fullname === 1}
                              onChange={handleCheckboxChangeName}
                            />{" "}
                            Name
                          </label>
                        </div>
                        <div className="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              name="email"
                              id="email"
                              checked={customerDetails.email === 1}
                              onChange={handleCheckboxChangeName}
                            />{" "}
                            Email
                          </label>
                        </div>
                        <div className="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              name="phone"
                              id="phone"
                              checked={customerDetails.phone === 1}
                              onChange={handleCheckboxChangeName}
                            />{" "}
                            Phone Number
                          </label>
                        </div>
                        <div className="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              name="address"
                              id="address"
                              checked={customerDetails.address === 1}
                              onChange={handleCheckboxChangeName}
                            />{" "}
                            Address
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <div class="reciept_blockbox footer_receipt_block">
                    <div class="receipt_left">
                      <label>
                        Footer/Terms and Conditions{" "}
                        <span>
                          Information that is displayed in your footer section
                          of your receipt, commonly used for terms and
                          conditions
                        </span>
                      </label>
                    </div>
                    <div class="receipt_right">
                      <div class="form-group">
                        <label>Footer Information</label>
                        <textarea
                          class="form-control"
                          name="footer_info"
                          value={footer}
                          onChange={(e) => setFooter(e.target.value)}
                        ></textarea>
                        <span class="receipt_note">
                          Try to limit this to 20 Lines
                        </span>
                      </div>
                      <div class="display_notes">
                        <div class="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              name="barcode"
                              checked={displayBarcode}
                              onChange={handleCheckboxChangeBarcode}
                            />{" "}
                            Display Bar Code
                          </label>
                          <span class="receipt_note">
                            Display a barcode that can allow you to later scan
                            and idenity the reciept
                          </span>
                        </div>
                        <div class="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              name="publicnote"
                              checked={note}
                              onChange={handleCheckboxChangeDisplayNote}
                            />{" "}
                            Display Public Note{" "}
                          </label>
                          <span class="receipt_note">
                            Display the note you write with the transaction in
                            the receipt
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="reciept_blockbox header_receipt_block">
                    <div class="receipt_left">
                      <label>
                        HST <span>Customize your HST Number</span>
                      </label>
                    </div>
                    <div class="receipt_right">
                      <div className="form-group">
                        <label className="cus_label">Enable HST Number</label>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={gstOnOff}
                            onChange={handleToggleChange}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      {gstOnOff && (
                        <div class="receipt_field receipt_title">
                          <div class="form-group">
                            <label class="cus_label">HST Number</label>
                            <input
                              type="text"
                              class="form-control"
                              name="receipt_title"
                              value={gstNumber}
                              onChange={(e) => setSGTNumber(e.target.value)}
                            />
                            <span class="receipt_note">
                              This hst number will be displayed at the bottom of
                              the receipt
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div class="reciept_blockbox header_receipt_block">
                    <div class="receipt_left">
                      <label>
                        Margins<span>Customize your Margin Details</span>
                      </label>
                    </div>
                    <div class="receipt_right">
                      <div class="receipt_field receipt_title">
                        <div class="form-group">
                          <label class="cus_label">LEFT Margin (PX)</label>
                          <input
                            type="number"
                            class="form-control no-arrow-input"
                            name="receipt_title"
                            value={leftMargin}
                            onChange={(e) => setLeftMargin(e.target.value)}
                          />
                        </div>
                        <div class="form-group">
                          <label class="cus_label">RIGHT Margin (PX)</label>
                          <input
                            type="number"
                            class="form-control no-arrow-input"
                            name="receipt_title"
                            value={rightMargin}
                            onChange={(e) => setRightMargin(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                </div>

                <div className="receipt_setup_btn">
                  <button
                    type="button"
                    className="previewbtncstmsetting"
                    onClick={handlePreview}
                  >
                    Preview
                  </button>
                  {states === true ? (
                    <button
                      type="button"
                      className="receipt_save_btn"
                      onClick={Reciept}
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="receipt_save_btn"
                      onClick={UpdateReciept}
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
          <div class="clearfix"></div>
        </div>
      </div>
    </>
  );
};
export default RecieptConfig;
