import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import MainSidebar from "./MainSidebar";
import SideUl from "./SideUl";
import FreeTrail from "./FreeTrail";
import {
  baseurl,
  CashDrawerDetailsGetApi,
  OpenCloseAddApi,
  OpenCloseGetApi,
} from "../Utility/Api";
import Swal from "sweetalert2";

const CashManagmentOpen = (props) => {
  const [amount, setAmount] = useState("");
  const [note, setNote] = useState("");
  const [error, setError] = useState("");
  const [isFreeTrial, setIsFreeTrial] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isFreeTrialVisible, setFreeTrialVisible] = useState(true);
  const [cashDrawerId, setCashDrawerId] = useState("");

  const [cashDrawer, setCashDrawer] = useState([
    { Denomination: "$100", value: 100, Quantity: 0, Total: 0 },
    { Denomination: "$50", value: 50, Quantity: 0, Total: 0 },
    { Denomination: "$20", value: 20, Quantity: 0, Total: 0 },
    { Denomination: "$10", value: 10, Quantity: 0, Total: 0 },
    { Denomination: "$5", value: 5, Quantity: 0, Total: 0 },
    { Denomination: "$2", value: 2, Quantity: 0, Total: 0 },
    { Denomination: "$1", value: 1, Quantity: 0, Total: 0 },
    { Denomination: "Quarters (0.25)", value: 0.25, Quantity: 0, Total: 0 },
    { Denomination: "Dimes (0.10)", value: 0.1, Quantity: 0, Total: 0 },
    { Denomination: "Nickels (0.05)", value: 0.05, Quantity: 0, Total: 0 },
    { Denomination: "Pennies (0.01)", value: 0.01, Quantity: 0, Total: 0 },
  ]);

  const handleChange = (index, field, value) => {
    const updatedCashDrawer = cashDrawer.map((item, i) => {
      if (i === index) {
        const updatedQuantity = parseFloat(value) || 0;
        const updatedTotal = updatedQuantity * item.value; // Calculate total
        return { ...item, [field]: updatedQuantity, Total: updatedTotal };
      }
      return item;
    });
    setCashDrawer(updatedCashDrawer);
  };

  // Calculate the overall total amount
  const totalAmount = cashDrawer.reduce((sum, item) => sum + item.Total, 0);

  const toggleFreeTrial = () => {
    setFreeTrialVisible(!isFreeTrialVisible);
  };

  const sidebarStyleone = {
    overflow: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
  };

  useEffect(() => {
    if (isFreeTrial) {
      // toggleSidebar(false);
      toggleFreeTrial(false);
    } else {
      // toggleSidebar(true);
      toggleFreeTrial(true);
    }
  }, [isFreeTrial]);

  useEffect(() => {
    const substatus = localStorage.getItem("substatus");
    const planDate = localStorage.getItem("planDate");

    if (
      (substatus === "null" ||
        substatus === "canceled" ||
        substatus === "expired") &&
      new Date(localStorage.getItem("planDate")) > new Date()
    ) {
      setIsFreeTrial(true);
    }
  }, []);
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
    localStorage.setItem("sidebar", !isSidebarVisible);
  };

  useEffect(() => {
    GetCashDrawerCounter();
    GetCashDetails();
    const savedSidebarState = localStorage.getItem("sidebar");
    if (savedSidebarState) {
      setSidebarVisible(JSON.parse(savedSidebarState));
    }
  }, []);

  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setAmount(value);
      setError("");
    } else {
      setError("Please enter a valid amount.");
    }
  };

  // Function to handle the Open Register button click
  const handleOpenRegister = () => {
    if (amount === "") {
      setError("Amount is required");
      return;
    }

    // Call API if validation passes
    AddOpenClose();
  };

  const AddOpenClose = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const formatDate = (date) => {
      const dateOptions = {
        month: "short",
        day: "numeric",
        year: "numeric",
      };

      const timeOptions = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };

      // Get the date and time separately
      const formattedDate = date.toLocaleDateString("en-US", dateOptions);
      const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

      // Combine the date and time with the " @ " separator
      return `${formattedDate} @ ${formattedTime}`;
    };

    // Example usage:
    const currentDateFormatted = formatDate(new Date());
    console.log(currentDateFormatted);

    const raw = JSON.stringify({
      name: "gagandeep",
      email: "gagandeepsmartitventures@gmail.com",
      type: "Opening float",
      reason: "",
      amount: amount,
      note: note,
      date: currentDateFormatted,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("openclose", raw);
    fetch(OpenCloseAddApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("add openclose", result);
        if (result?.status === true) {
          setAmount("");
          setNote("");
          props?.onLoad();
          Swal.fire(result?.message);
        } else {
          setAmount("");
          setNote("");

          Swal.fire(result?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetCashDetails = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(OpenCloseGetApi, requestOptions)
      .then((response) => response.json())
      .then((result) => console.log("OpenClose Register details", result))
      .catch((error) => console.error(error));
  };

  const UpdateCashDrawer = () => {
    if (parseFloat(amount) !== totalAmount) {
      Swal.fire({
        icon: "error",
        title:
          "The total denomination amount does not match the entered amount. Please double-check and try again.",
        customClass: {
          container: "custom-swal-container",
          confirmButton: "custom-swal-confirm-button",
        },

        showCloseButton: true,
      });
    } else {
      let local = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${local}`);
      const filteredCashDrawer = cashDrawer.filter((item) => item.Quantity > 0);
      const dataToPost = filteredCashDrawer.map(({ value, ...rest }) => rest);

      const raw = JSON.stringify({
        cashDrawer: dataToPost,
        totalAmount: totalAmount,
      });

      console.log("cashhdrawer", raw);

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log("raaa", raw);
      fetch(`${baseurl}updateCashDrawerCounter/${cashDrawerId}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);

          if (result?.status === true) {
            GetCashDrawerCounter();
            props?.onLoad();
            setShowModal(false);
            document
              .querySelectorAll(".modal-backdrop")
              .forEach((el) => el.classList.remove("modal-backdrop"));
          } else {
            setShowModal(false);
            document
              .querySelectorAll(".modal-backdrop")
              .forEach((el) => el.classList.remove("modal-backdrop"));
          }
        })
        .catch((error) => console.error(error));
    }
  };

  const GetCashDrawerCounter = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(CashDrawerDetailsGetApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("cash drawer count", result);
        if (result?.status === true) {
          setCashDrawerId(result?.data?.id);
        } else {
          console.log(result);
        }
      })
      .catch((error) => console.error(error));
  };
  const resetValues = () => {
    const resetCashDrawer = cashDrawer.map((item) => ({
      ...item,
      Quantity: 0,
      Total: 0,
    }));
    setCashDrawer(resetCashDrawer);
    //setTotalAmount(0);
  };
  const buttonClass = amount
    ? "cus_reg_btn open_reg_btn success_open_reg"
    : "cus_reg_btn open_reg_btn default_open_reg";

  return (
    <>
      <div className="row">
        <div className="offset-lg-2 col-lg-8 col-md-12">
          <div className="cus_regis_close">
            <div className="cash_regis_field">
              <div className="cash_reg_icon">
                <img src="assets/img/cash-register-close.svg" alt="" />
              </div>
              <div className="cash_reg_content">
                <h3>Register is closed</h3>
                <p>
                  Keep Track of your cash. <br />
                  Set an opening float amount to open register, and begin making
                  sales.
                </p>
              </div>
              <div className="inner_reg_field">
                <div className="form-group">
                  <label>Opening Float</label>
                  <div className="cus_input_group">
                    <div className="input_feild">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={0.0}
                        value={amount}
                        onChange={handleAmountChange}
                      />
                      <span>$</span>
                    </div>

                    <div className="input_btn">
                      <button type="button" onClick={() => setShowModal(true)}>
                        <img src="assets/img/cash-counter-icon.svg" alt="" />{" "}
                        Till Counter
                      </button>
                    </div>
                  </div>
                  {error && <p style={{ color: "red" }}>{error}</p>}
                </div>
                <div className="form-group">
                  <label>
                    Notes <span>Optional</span>
                  </label>
                  <div className="input_feild">
                    <textarea
                      className="form-control"
                      placeholder="Enter a note"
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="cash_regis_btns">
              <button className={buttonClass} onClick={handleOpenRegister}>
                Open Register
              </button>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <>
          <div
            className="modal common_modal cashdrawcounter_modal fade show"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="cashdrawcounterModalLabel"
            style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            aria-modal="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="cashdrawcounterModalLabel">
                    Cash Drawer Counter:
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      resetValues();
                      setShowModal(false);
                      document
                        .querySelectorAll(".modal-backdrop")
                        .forEach((el) => el.classList.remove("modal-backdrop"));
                    }}
                  >
                    <span className="close_icon" />
                  </button>
                  <p>
                    Enter each denomination of currency for manageable counting.
                    <br />
                    This is not necessary for opening float. You can enter a
                    whole number.
                  </p>
                </div>
                <div className="modal-body">
                  <div className="cash_draw_table">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Denomination</th>
                            <th>Quantity</th>
                            <th>Total:</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cashDrawer.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <b>{item.Denomination}</b>
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="cash_qty_field no-arrow-input"
                                  value={item.Quantity}
                                  onChange={(e) =>
                                    handleChange(
                                      index,
                                      "Quantity",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Enter Quantity"
                                  onWheel={(e) => e.target.blur()}
                                />
                              </td>
                              <td>{item.Total.toFixed(2)}</td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan={2}>
                              <b>Total:</b>
                            </td>
                            <td>${totalAmount.toFixed(2)}</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary default_btn"
                    data-dismiss="modal"
                    onClick={() => {
                      resetValues();
                      setShowModal(false);
                      document
                        .querySelectorAll(".modal-backdrop")
                        .forEach((el) => el.classList.remove("modal-backdrop"));
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary theme_btn"
                    onClick={() => {
                      UpdateCashDrawer();
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showModal && (
        <div
          className="modal-backdrop fade show"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        ></div>
      )}
    </>
  );
};
export default CashManagmentOpen;
