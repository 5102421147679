import React, { useEffect, useState } from "react";
import { DemoStoreDetailsApi, GetDemoStoreDetailsApi } from "../Utility/Api";
import { useNavigate } from "react-router-dom";

const DemoStore = () => {
  const [step, setStep] = useState(1);
  const [storeType, setStoreType] = useState("");
  const [storeTime, setStoreTime] = useState("");
  const [currentUse, setCurrentUse] = useState("");
  const [openNewStore, setOpenNewStore] = useState("");
  const [description, setDescription] = useState("");
  let navigate = useNavigate();

  const isStep1Valid = storeType !== "" && storeTime !== "";
  const isStep2Valid =
    (currentUse === "Nothing" && openNewStore !== "") ||
    currentUse === "Existing POS" ||
    currentUse === "Other";

  useEffect(() => {
    // GetDemoDetails();
  }, []);

  const handleDemoDetails = () => {
    let token = localStorage.getItem("token");
    const headers = new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    const timeMapping = {
      "Within 1 week": 7,
      "Within 1 month": 30,
      "Within 3 months": 90,
      "I don’t know": null, // Sending null if the user selects "I don't know"
    };

    const durationInDays = timeMapping[storeTime] || null;
    const payload = {
      demoStore: storeType === "demo" ? 1 : 1,
      duration: durationInDays,
      usageType: currentUse,
      storeType: currentUse === "Nothing" ? openNewStore : "",
      description: currentUse === "Other" ? description : "",
    };

    console.log("demo store raww", payload);

    fetch(DemoStoreDetailsApi, {
      method: "PUT",
      headers,
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log("API Response:", result);
        if (result?.status === true) {
          navigate("/cash-register");
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  };

  return (
    <div className="demo_store_page">
      <div className="demo_step_form">
        <form className="stepform">
          <div className="demo_store_inner">
            <div className="demo_store_left">
              <div className="topheader">
                <a href="#">
                  <img src="assets/img/logo-small.png" alt="Logo" />
                </a>
              </div>
            </div>
            <div className="demo_store_right">
              <div className="demo_steps">
                {/* Step 1 */}
                {step === 1 && (
                  <div className="steps_col">
                    <h4>Get Started with Toolbox</h4>
                    <h3>How do you want to get started?</h3>
                    <p>
                      The toolbox team is here to help you during your 14-day
                      trial!
                    </p>

                    <div className="demo_store_option">
                      <div
                        className={`store_option_col ${
                          storeType === "own" ? "selected" : ""
                        }`}
                        onClick={() => setStoreType("own")}
                      >
                        <span>I'm ready to start my own store</span>
                        <img src="assets/img/demo-store-option1.svg" alt="" />
                      </div>
                      <div
                        className={`store_option_col ${
                          storeType === "demo" ? "selected" : ""
                        }`}
                        onClick={() => setStoreType("demo")}
                      >
                        <span>I want to look around a demo store</span>
                        <img src="assets/img/demo-store-option2.svg" alt="" />
                      </div>
                    </div>

                    {/* Store Time Dropdown */}
                    {storeType && (
                      <div className="store_field">
                        <div className="form-group">
                          <label>
                            When will you like to get started with Toolbox?
                          </label>
                          <select
                            className="form-control"
                            value={storeTime}
                            onChange={(e) => setStoreTime(e.target.value)}
                          >
                            <option value="">Select</option>
                            <option value="Within 1 week">Within 1 week</option>
                            <option value="Within 1 month">
                              Within 1 month
                            </option>
                            <option value="Within 3 months">
                              Within 3 months
                            </option>
                            <option value="I don’t know">I don’t know</option>
                          </select>
                        </div>
                      </div>
                    )}

                    <div className="next_step_btn">
                      <button
                        type="button"
                        className={
                          isStep1Valid
                            ? "next_btn cus_btn"
                            : "default_btn cus_btn"
                        }
                        disabled={!isStep1Valid}
                        onClick={() => setStep(2)}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                )}
                {/* Step 2 */}
                {step === 2 && (
                  <div className="steps_col">
                    <h4>
                      Get Started → <span>In-Store</span>
                    </h4>
                    <h3>Tell us about your store</h3>
                    <p>
                      Running a store can be complicated, we can help make it
                      easier.
                    </p>

                    {/* What do you currently use? */}
                    <div className="store_field">
                      <div className="form-group">
                        <label>What do you currently use?</label>
                        <div className="cus_radio">
                          <div
                            className={`radio ${
                              currentUse === "Nothing" ? "active" : ""
                            }`}
                          >
                            <input
                              type="radio"
                              name="currentUse"
                              value="Nothing"
                              checked={currentUse === "Nothing"}
                              onChange={() => setCurrentUse("Nothing")}
                            />
                            <span>Nothing</span>
                          </div>
                          <div
                            className={`radio ${
                              currentUse === "Existing POS" ? "active" : ""
                            }`}
                          >
                            <input
                              type="radio"
                              name="currentUse"
                              value="Existing POS"
                              checked={currentUse === "Existing POS"}
                              onChange={() => setCurrentUse("Existing POS")}
                            />
                            <span>Existing POS</span>
                          </div>
                          <div
                            className={`radio ${
                              currentUse === "Other" ? "active" : ""
                            }`}
                          >
                            <input
                              type="radio"
                              name="currentUse"
                              value="Other"
                              checked={currentUse === "Other"}
                              onChange={() => setCurrentUse("Other")}
                            />
                            <span>Other</span>
                          </div>
                        </div>
                      </div>

                      {/* Show Yes/No only if "Nothing" is selected */}
                      {currentUse === "Nothing" && (
                        <div className="form-group">
                          <label>Are you opening a new store?</label>
                          <div className="cus_radio">
                            <div
                              className={`radio ${
                                openNewStore === "Yes" ? "active" : ""
                              }`}
                            >
                              <input
                                type="radio"
                                name="openNewStore"
                                value="Yes"
                                checked={openNewStore === "Yes"}
                                onChange={() => setOpenNewStore("Yes")}
                              />
                              <span>Yes</span>
                            </div>
                            <div
                              className={`radio ${
                                openNewStore === "No" ? "active" : ""
                              }`}
                            >
                              <input
                                type="radio"
                                name="openNewStore"
                                value="No"
                                checked={openNewStore === "No"}
                                onChange={() => setOpenNewStore("No")}
                              />
                              <span>No</span>
                            </div>
                          </div>
                        </div>
                      )}
                      {currentUse === "Other" && (
                        <div className="form-group">
                          <label>What did/do you use?</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </div>
                      )}
                    </div>

                    <div className="next_step_btn">
                      <button
                        type="button"
                        className="back_btn cus_btn"
                        onClick={() => setStep(1)}
                      >
                        Back
                      </button>
                      <button
                        type="button"
                        className={
                          isStep2Valid
                            ? "next_btn cus_btn"
                            : "default_btn cus_btn"
                        }
                        disabled={!isStep2Valid}
                        onClick={handleDemoDetails}
                      >
                        Let's Go
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DemoStore;
