import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import MainSidebar from "./MainSidebar";
import SideUl from "./SideUl";
import FreeTrail from "./FreeTrail";
import {
  baseurl,
  CashDrawerDetailsGetApi,
  getProfileApi,
  GetRecieptApi,
  GetSalesApi,
  GetSubUserProfileApi,
  LastCashDetailsGetApi,
  OpenCloseAddApi,
  OpenCloseGetApi,
} from "../Utility/Api";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

const OpenCloseCash = () => {
  const [amount, setAmount] = useState("");
  const [note, setNote] = useState("");
  const [error, setError] = useState("");
  const [isFreeTrial, setIsFreeTrial] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isFreeTrialVisible, setFreeTrialVisible] = useState(true);
  const [cashDrawerId, setCashDrawerId] = useState("");

  const [cashDrawer, setCashDrawer] = useState([
    { Denomination: "$100", value: 100, Quantity: 0, Total: 0 },
    { Denomination: "$50", value: 50, Quantity: 0, Total: 0 },
    { Denomination: "$20", value: 20, Quantity: 0, Total: 0 },
    { Denomination: "$10", value: 10, Quantity: 0, Total: 0 },
    { Denomination: "$5", value: 5, Quantity: 0, Total: 0 },
    { Denomination: "$2", value: 2, Quantity: 0, Total: 0 },
    { Denomination: "$1", value: 1, Quantity: 0, Total: 0 },
    { Denomination: "Quarters (0.25)", value: 0.25, Quantity: 0, Total: 0 },
    { Denomination: "Dimes (0.10)", value: 0.1, Quantity: 0, Total: 0 },
    { Denomination: "Nickels (0.05)", value: 0.05, Quantity: 0, Total: 0 },
    { Denomination: "Pennies (0.01)", value: 0.01, Quantity: 0, Total: 0 },
  ]);
  const [userName, setUserName] = useState();
  const [UserEmail, setUserEmail] = useState();
  const [userCompanyName, setUserCompanyName] = useState();
  const [lastClosureDetails, setLastClosureDetails] = useState(null);
  const [openRegisterDetails, setOpenRegisterDetails] = useState(null);
  const [companyCashDetail, setCompanyCashDetails] = useState(null);
  const [isFolded, setIsFolded] = useState(false);
  const [paymentarr, setPaymentArr] = useState([]);
  const [todaySales, setTodaySales] = useState("");
  const [printType, setPrintType] = useState();

  const navigate = useNavigate();

  const handleChange = (index, field, value) => {
    const updatedCashDrawer = cashDrawer.map((item, i) => {
      if (i === index) {
        const updatedQuantity = parseFloat(value) || 0;
        const updatedTotal = updatedQuantity * item.value;
        return { ...item, [field]: updatedQuantity, Total: updatedTotal };
      }
      return item;
    });
    setCashDrawer(updatedCashDrawer);
  };

  // Calculate the overall total amount
  const totalAmount = cashDrawer.reduce((sum, item) => sum + item.Total, 0);

  const toggleFreeTrial = () => {
    setFreeTrialVisible(!isFreeTrialVisible);
  };

  const sidebarStyleone = {
    overflow: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
  };

  useEffect(() => {
    if (isFreeTrial) {
      // toggleSidebar(false);
      toggleFreeTrial(false);
    } else {
      // toggleSidebar(true);
      toggleFreeTrial(true);
    }
  }, [isFreeTrial]);

  useEffect(() => {
    const substatus = localStorage.getItem("substatus");
    const planDate = localStorage.getItem("planDate");

    if (
      (substatus === "null" ||
        substatus === "canceled" ||
        substatus === "expired") &&
      new Date(localStorage.getItem("planDate")) > new Date()
    ) {
      setIsFreeTrial(true);
    }
  }, []);
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
    localStorage.setItem("sidebar", !isSidebarVisible);
  };

  useEffect(() => {
    let localAdmin = localStorage.getItem("admin");
    let localPerminssion = JSON.parse(localStorage.getItem("permission"));
    if (localAdmin === "0" && localPerminssion.length) {
      GetSubUserProfile();
    }
    GetProfile();
    GetCashDrawerCounter();
    GetCashDetails();
    const savedSidebarState = localStorage.getItem("sidebar");
    if (savedSidebarState) {
      setSidebarVisible(JSON.parse(savedSidebarState));
    }
    GetLastCashDetails();
    TodaySaleApi();
    GetReciept();
  }, []);

  const GetProfile = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(getProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("gfdgh", result);
        if (result.status === true) {
          //setData1(result?.data);
          setUserCompanyName(result?.data?.companyDetails?.companyName);
          setUserEmail(result?.data?.userDetails?.email);
          setUserName(result?.data?.userDetails?.userName);
        } else {
          console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetSubUserProfile = () => {
    let local = localStorage.getItem("token");
    //console.log(local);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetSubUserProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("subuser", result);
        if (result.status === true) {
          setUserCompanyName(result?.data?.companyDetails?.companyName);
          setUserEmail(result?.data?.userDetails?.email);
          setUserName(result?.data?.userDetails?.name);
          // setDataUser(result?.data);
        } else {
          console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setAmount(value);
      setError("");
    } else {
      setError("Please enter a valid amount.");
    }
  };

  // Function to handle the Open Register button click
  const handleOpenRegister = () => {
    if (amount === "") {
      setError("Amount is required");
      return;
    }

    // Call API if validation passes
    AddOpenClose();
  };

  const AddOpenClose = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const formatDate = (date) => {
      const dateOptions = {
        month: "short",
        day: "numeric",
        year: "numeric",
      };

      const timeOptions = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };

      // Get the date and time separately
      const formattedDate = date.toLocaleDateString("en-US", dateOptions);
      const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

      // Combine the date and time with the " @ " separator
      return `${formattedDate} @ ${formattedTime}`;
    };

    // Example usage:
    const currentDateFormatted = formatDate(new Date());
    console.log(currentDateFormatted);

    const raw = JSON.stringify({
      name: userName,
      email: UserEmail,
      type: "Opening float",
      reason: "",
      amount: amount,
      note: note,
      date: currentDateFormatted,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("openclose", raw);
    fetch(OpenCloseAddApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("add openclose", result);
        if (result?.status === true) {
          setAmount("");
          setNote("");
          Swal.fire(result?.message);
          printTable(result);
        } else {
          setAmount("");
          setNote("");
          Swal.fire(result?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetCashDetails = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(OpenCloseGetApi, requestOptions)
      .then((response) => response.json())
      .then((result) => console.log("OpenClose Register details", result))
      .catch((error) => console.error(error));
  };

  const UpdateCashDrawer = () => {
    if (parseFloat(amount) !== totalAmount) {
      Swal.fire({
        icon: "error", // Show an error icon
        title:
          "The total denomination amount does not match the entered amount. Please double-check and try again.",
        customClass: {
          container: "custom-swal-container",
          confirmButton: "custom-swal-confirm-button",
        },
        // confirmButtonText: "Fix it", // Customized button text
        // backdrop: `rgba(255,0,0,0.4)`, // Adds a red overlay in the background
        // timer: 5000, // Automatically close after 5 seconds
        showCloseButton: true, // Add a close (X) button
      });

      // Swal.fire(
      //   "Error",
      //   "Total denomination amount does not match the entered amount!",
      //   "error"
      // );
    } else {
      let local = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${local}`);
      const filteredCashDrawer = cashDrawer.filter((item) => item.Quantity > 0);
      const dataToPost = filteredCashDrawer.map(({ value, ...rest }) => rest);

      const raw = JSON.stringify({
        cashDrawer: dataToPost,
        totalAmount: totalAmount,
      });

      console.log("cashhdrawer", raw);

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log("raaa", raw);
      fetch(`${baseurl}updateCashDrawerCounter/${cashDrawerId}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);

          if (result?.status === true) {
            //  setCashDrawer([]);
            GetCashDrawerCounter();

            setShowModal(false);
            document
              .querySelectorAll(".modal-backdrop")
              .forEach((el) => el.classList.remove("modal-backdrop"));
            printTable();
          } else {
            //setCashDrawer([]);
            //  GetCashDrawerCounter();
            //  props?.onLoad();
            setShowModal(false);
            document
              .querySelectorAll(".modal-backdrop")
              .forEach((el) => el.classList.remove("modal-backdrop"));
          }
        })
        .catch((error) => console.error(error));
    }
  };

  const GetCashDrawerCounter = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(CashDrawerDetailsGetApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("cash drawer count", result);
        if (result?.status === true) {
          setCashDrawerId(result?.data?.id);
        } else {
          console.log(result);
        }
      })
      .catch((error) => console.error(error));
  };

  const resetValues = () => {
    const resetCashDrawer = cashDrawer.map((item) => ({
      ...item,
      Quantity: 0,
      Total: 0,
    }));
    setCashDrawer(resetCashDrawer);
    //setTotalAmount(0);
  };
  const buttonClass = amount
    ? "cus_reg_btn open_reg_btn success_open_reg"
    : "cus_reg_btn open_reg_btn default_open_reg";

  const generatePrintContent = (data) => {
    const cashManagement = data?.cashManagementDetail;
    const companyCash = data?.companyCashDetail;

    return `
    <html>
      <head>
        <style>
          @media print {
            @page { margin: auto; }
            body { margin-left: 0px; margin-right: 0px; height:auto; }
            header, footer { display: none; }
          }
        </style>
      </head>
      <body>
        <div style="font-family: 'Inter', sans-serif;${
          printType === 0 ? "88mm" : "400px"
        }">
           <div style="margin: ${
             printType === 0 ? "0px" : "0 auto"
           }; padding: ${printType === 0 ? "0px" : "25px 25px 15px"}; width: ${
      printType === 0 ? "88mm" : "400px"
    };">
            <table style="width: 100%; text-align: center;">
              <tbody>
               
                <tr><td style="padding: 5px 0; font-size: 14px; font-weight: 700; color: #000;">${userCompanyName}</td></tr>
                <tr><td style="padding: 10px 0 0; font-size: 13px; font-weight: 500; color: #000;">${
                  companyCash?.type
                }</td></tr>
                <tr><td style="padding: 5px 0; font-size: 24px; color: #000;"><b>$ ${
                  companyCash?.totalAmount
                }</b></td></tr>
              </tbody>
            </table>
            <div style="padding: 8px 0;"></div>
            <table className="tabledata" style="width: 100%;">
              <tbody>
                <tr><td style="border-top: 2px dashed #000; padding: 8px 6px;"></td><td style="border-top: 2px dashed #000; padding: 8px 6px;"></td></tr>
                <tr>
                  <td style="padding: 5px 0; text-align: center;"><b>Date:</b></td>
                  <td style="padding: 5px 0; text-align: left;">${
                    companyCash?.date
                  }</td>
                </tr>
                <tr>
                  <td style="padding: 5px 0; text-align: center;"><b>Cashier:</b></td>
                  <td style="padding: 5px 0; text-align: left;">${
                    companyCash?.name
                  }</td>
                </tr>
                <tr>
                  <td style="padding: 5px 0; text-align: center;"><b>Register:</b></td>
                  <td style="padding: 5px 0; text-align: left;">Cash Register</td>
                </tr>
                <tr>
                  <td style="padding: 5px 0; text-align: center;"><b>Note:</b></td>
                  <td style="padding: 5px 0; text-align: left;">${
                    companyCash?.note || "-"
                  }</td>
                </tr>
        
              <tr>
                 <td style="padding: 5px 0; font-size: 13px; font-weight: 500; line-height: 20px; color: #000; text-align: center;">
                 </td>
                 <td style="padding: 5px 0; font-size: 13px; font-weight: 500; line-height: 20px; color: #000; text-align: left;">
                 </td>
             </tr>

              </tbody>
               <tfoot>
                  <tr><td style="border-top: 2px dashed #000; padding: 8px 6px;"></td><td style="border-top: 2px dashed #000; padding: 8px 6px;"></td></tr>
                </tfoot>
            </table>
          </div>
        </div>
      </body>
    </html>
  `;
  };

  const printTable = (result) => {
    const printContents = generatePrintContent(result.data[0]);

    const width = 800;
    const height = 600;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    const printWindow = window.open(
      "",
      "_blank",
      `width=${width},height=${height},top=${top},left=${left}`
    );

    if (!printWindow) {
      Swal.fire(
        "Unable to open print window. Please check your pop-up settings."
      );
      return;
    }

    printWindow.document.open();
    printWindow.document.write(printContents);
    printWindow.document.close();

    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 200); // Adjust the delay as needed
    navigate("/cash-register");
  };

  const GetLastCashDetails = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(LastCashDetailsGetApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("last cash detaiils", result);
        if (result?.status === true) {
          setLastClosureDetails(result?.data?.recentClosedRegister);
          const openingDetail = result?.data?.companyCashDetail?.find(
            (item) => item?.type === "Opening float"
          );
          setOpenRegisterDetails(openingDetail);
          setCompanyCashDetails(result?.data?.companyCashDetail);
          // setCashDrawerId(result?.data?.id);
        } else {
          console.log(result);
        }
      })
      .catch((error) => console.error(error));
  };
  const toggleFold = () => {
    setIsFolded(!isFolded); // Toggle the folded state
  };

  const printLastTable = () => {
    const printContents = document.querySelector(
      ".recieptlastsummary"
    ).innerHTML;
    const originalContents = document.body.innerHTML;
    // Calculate the position to center the new window
    const width = 800;
    const height = 600;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    // Open a new window with centered position
    const printWindow = window.open(
      "",
      "_blank",
      `width=${width},height=${height},top=${top},left=${left}`
    );

    if (!printWindow) {
      Swal.fire(
        "Unable to open print window. Please check your pop-up settings."
      );
      return;
    }

    // const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
       <style>
       @media print {
    @page {
      margin:auto; /* Remove default margins to ensure there's no space at the top and bottom */
    }
    body {
   /* Remove margins on body as well */

       height:auto
    }
    /* Hide browser print headers and footers */
    header,
    footer {
      display: none;
    }
  }
       </style>

       </head>
        <body>${printContents}</body>
      </html>
    `);
    printWindow.document.close();
    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 200); // Adjust the delay as needed
    // printWindow.print();
    // printWindow.close();
  };

  const TodaySaleApi = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    const raw = JSON.stringify({
      startDate: moment(new Date()).format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),
      status: 1,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(GetSalesApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          console.log("resultt", result.data);
          setLoading(false);
          // setData(result?.data);
          setPaymentArr(result?.data?.paymentArr);
          setTodaySales(result?.data?.totalSalesAmountGrandTotal);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };
  const GetReciept = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetRecieptApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("reciept", result?.data);
        if (result?.status === true) {
          const data = result?.data;

          setPrintType(data.type);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div className={isSidebarVisible ? "sidebar-mini" : ""}>
        <div
          className={`${
            isFreeTrial
              ? "main-wrapper devicelistcstmtableres customclswrapper"
              : "main-wrapper devicelistcstmtableres "
          } `}
        >
          {loading ? (
            <div className="landing_loader">
              <div className="innerloader">
                <img src="img/spin-1s-156px.gif" alt="" />
              </div>
            </div>
          ) : null}
          {isFreeTrial ? (
            <>
              <div className="app-container-trial">
                <FreeTrail />
              </div>
            </>
          ) : null}
          <div style={sidebarStyleone}>
            <nav className="navbar navbar-expand-lg main-navbar sticky">
              <div className="sidebar-arrow">
                <a
                  href="#"
                  data-toggle="sidebar"
                  onClick={(e) => {
                    toggleSidebar();
                    e.preventDefault();
                  }}
                >
                  <img src="assets/img/sidebar-toggle.webp" alt="" />
                </a>
              </div>
              <div className="header-title mr-auto">
                <h4>
                  Open & Close Register{" "}
                  <a
                    className="notes_icon"
                    href="#"
                    data-toggle="modal"
                    data-target="#noteinfoModal"
                  >
                    <img src="assets/img/notes-icon.svg" alt="" />
                  </a>
                </h4>
              </div>
              <SideUl />
            </nav>
            <MainSidebar />

            <div className="main-content">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="cash_mange_record">
                    <div className="cash_manag_left">
                      <h4>Open &amp; Close Register</h4>
                      <p>
                        Record all your cash movements for the day. If you need
                        help
                      </p>
                      <p>
                        Petty cash is&nbsp;a small amount of money kept on hand
                        for small purchases or expenses.
                      </p>
                    </div>
                  </div>
                  <div className="clearfix" />
                </div>
              </div>
              <div className="cus_regis_openclose_sec">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="cus_regis_close cus_regis_openclose">
                      <div className="cash_regis_field">
                        <div className="cash_reg_left">
                          <div className="cash_reg_content">
                            <h3>
                              Register is closed{" "}
                              <img
                                src="assets/img/cash-register-close.svg"
                                alt=""
                              />
                            </h3>
                            <p>
                              Keep Track of your cash.
                              <br />
                              Set an opening float amount to open register, and
                              begin making sales for the day.
                            </p>
                          </div>
                        </div>
                        <div className="inner_reg_field">
                          <div className="form-group">
                            <label>Opening Float</label>
                            <div className="cus_input_group">
                              <div className="input_feild">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={0.0}
                                  value={amount}
                                  onChange={handleAmountChange}
                                />
                                <span>$</span>
                              </div>
                              <div className="input_btn">
                                <button
                                  type="button"
                                  onClick={() => setShowModal(true)}
                                >
                                  <img
                                    src="assets/img/cash-counter-icon.svg"
                                    alt=""
                                  />{" "}
                                  Till Counter
                                </button>
                              </div>
                            </div>
                            {error && <p style={{ color: "red" }}>{error}</p>}
                          </div>
                          <div className="form-group">
                            <label>
                              Notes <span>Optional</span>
                            </label>
                            <div className="input_feild">
                              <textarea
                                className="form-control"
                                placeholder="Enter a note"
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="cash_regis_btns">
                            <button
                              className={buttonClass}
                              onClick={handleOpenRegister}
                            >
                              Open Register
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="borderline" />
                    <div className="product_details_info">
                      <div className="prod_detail_left">
                        <div className="custom_heading">
                          <h4>Previous Closure Details</h4>
                        </div>
                      </div>
                      <div className="prod_detail_right">
                        <div className="prod_col_label">
                          <label>Register:</label>
                          <span>Cash Register</span>
                        </div>
                        <div className="prod_col_label">
                          <label>Closure #:</label>
                          <span>{lastClosureDetails?.id || "0"}</span>
                        </div>
                        <div className="prod_col_label">
                          <label>Opening time:</label>
                          <span>
                            {lastClosureDetails?.openingDateTime || "--"}
                          </span>
                        </div>
                        <div className="prod_col_label">
                          <label>Closing Time:</label>
                          <span>
                            {lastClosureDetails?.closingDateTime || "--"}
                          </span>
                        </div>
                        <div className="prod_col_label">
                          <label>Opening User:</label>
                          <span>
                            {openRegisterDetails
                              ? `${openRegisterDetails?.name} (${openRegisterDetails?.email})`
                              : "--"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="borderline" />
                    <div className="product_summary_table">
                      <div className="product_summary_heading custom_heading">
                        <h4>Previous Payments Summary</h4>
                        <span>Record all your cash movements for the day.</span>
                      </div>
                      <div className="table-responsive summary_table_data">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Payment Types</th>
                              <th>Expected ($)</th>
                              <th>Counted ($)</th>
                              <th>Diffrences ($)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="tr_view" onClick={toggleFold}>
                              <td>
                                <div className="down_arrow">
                                  <img
                                    src="assets/img/table-angle-down.svg"
                                    alt=""
                                    style={{
                                      transform: isFolded
                                        ? "rotate(180deg)"
                                        : "rotate(0deg)", // Rotate the arrow when folded
                                      transition: "transform 0.3s",
                                    }}
                                  />
                                </div>
                                Cash{" "}
                                <span>View Payments, Floats and Movements</span>
                              </td>
                              <td>
                                {lastClosureDetails?.totalAmount || "0.00"}
                              </td>
                              <td>
                                {lastClosureDetails?.drawerCash || "0.00"}
                              </td>
                              <td>
                                {lastClosureDetails?.changeGiven || "0.00"}
                              </td>
                            </tr>
                            {isFolded && (
                              <tr className="tr_fold">
                                <td className="main_td" colSpan={4}>
                                  <table style={{ width: "100%" }}>
                                    <tbody>
                                      <tr>
                                        <th>Cash Movements</th>
                                        <th>Time &amp; User</th>
                                        <th>Amount ($)</th>
                                        <th>Reason</th>
                                      </tr>
                                      {companyCashDetail &&
                                      companyCashDetail.length > 0 ? (
                                        companyCashDetail
                                          ?.filter(
                                            (item) =>
                                              item.type !== "Closing float"
                                          )
                                          .map((item, index) => {
                                            let amountStyle = {};
                                            let displayAmount =
                                              item.totalAmount || "0.00";

                                            // Apply color and minus sign based on the type of transaction
                                            if (item.type === "Opening float") {
                                              amountStyle = { color: "black" };
                                            } else if (
                                              item.type === "Cash In" ||
                                              item.type === "Petty Cash In"
                                            ) {
                                              amountStyle = { color: "green" };
                                            } else if (
                                              item.type === "Cash Out" ||
                                              item.type === "Petty Cash Out"
                                            ) {
                                              amountStyle = { color: "red" };
                                              displayAmount = `-${displayAmount}`; // Add minus sign for outflows
                                            }

                                            return (
                                              <tr key={index}>
                                                <td></td>
                                                <td>
                                                  {item.date} <br />
                                                  {item.name} ({item.email})
                                                </td>
                                                <td style={amountStyle}>
                                                  {displayAmount}
                                                </td>
                                                <td>{item.type}</td>
                                              </tr>
                                            );
                                          })
                                      ) : (
                                        <tr>
                                          <td colSpan={4}>No Cash Movements</td>
                                        </tr>
                                      )}

                                      <tr>
                                        <th colSpan={4}>
                                          Cash Payments Recieved
                                        </th>
                                      </tr>
                                      <tr>
                                        <td colSpan={4}>
                                          {paymentarr?.find(
                                            (payment) =>
                                              payment?.paymentType === "Cash"
                                          )?.totalGrandTotal || "0.00"}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            )}
                            {(paymentarr || [])
                              ?.filter((item) => item.paymentType !== "Cash")
                              .map((item, index) => {
                                return (
                                  <tr className="tr_view" key={index}>
                                    <td>{item?.paymentType}</td>
                                    <td>{item?.totalGrandTotal?.toFixed(2)}</td>
                                    <td className="input_field">
                                      {/* <input
          type="text"
          className="cus_input"
          placeholder="Enter Amount"
        /> */}
                                    </td>
                                    <td>{item?.totalGrandTotal?.toFixed(2)}</td>
                                  </tr>
                                );
                              })}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td>
                                <b>Total</b>
                              </td>
                              <td>
                                {lastClosureDetails?.totalAmount || "0.00"}
                              </td>
                              <td>
                                {lastClosureDetails?.drawerCash || "0.00"}
                              </td>
                              <td>
                                {lastClosureDetails?.changeGiven || "0.00"}
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>

                    <div className="borderline" />
                    <div className="print_summary_btns">
                      <button
                        type="button"
                        className="print_btn cus_btn"
                        onClick={printLastTable}
                      >
                        Print Previous Summary
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bottom_btns">
                <div className="row">
                  <div className="col-md-12">
                    <div className="bottom_links">
                      <ul>
                        <li>
                          <Link to="/openclosecashregister">
                            Open/Close Float
                          </Link>
                          {/* <a href="#">--</a> */}
                        </li>
                        <li>
                          {/* <a href="#">--</a> */}

                          <Link to="/cashmanagement">Cash Management</Link>
                        </li>
                        <li>
                          <a href="#">Note</a>
                        </li>
                        <li>
                          <a href="#">Signature</a>
                        </li>
                        <li>
                          <a href="#">Start Over</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
      {showModal && (
        <>
          <div
            className="modal common_modal cashdrawcounter_modal fade show"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="cashdrawcounterModalLabel"
            style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            aria-modal="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="cashdrawcounterModalLabel">
                    Cash Drawer Counter:
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      resetValues();
                      setShowModal(false);
                      document
                        .querySelectorAll(".modal-backdrop")
                        .forEach((el) => el.classList.remove("modal-backdrop"));
                    }}
                  >
                    <span className="close_icon" />
                  </button>
                  <p>
                    Enter each denomination of currency for manageable counting.
                    <br />
                    This is not necessary for opening float. You can enter a
                    whole number.
                  </p>
                </div>
                <div className="modal-body">
                  <div className="cash_draw_table">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Denomination</th>
                            <th>Quantity</th>
                            <th>Total:</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cashDrawer?.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <b>{item.Denomination}</b>
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="cash_qty_field no-arrow-input"
                                  value={item.Quantity}
                                  onChange={(e) =>
                                    handleChange(
                                      index,
                                      "Quantity",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Enter Quantity"
                                  onWheel={(e) => e.target.blur()}
                                />
                              </td>
                              <td>{item.Total.toFixed(2)}</td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan={2}>
                              <b>Total:</b>
                            </td>
                            <td>${totalAmount.toFixed(2)}</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary default_btn"
                    data-dismiss="modal"
                    onClick={() => {
                      resetValues();
                      setShowModal(false);
                      document
                        .querySelectorAll(".modal-backdrop")
                        .forEach((el) => el.classList.remove("modal-backdrop"));
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary theme_btn"
                    onClick={() => {
                      UpdateCashDrawer();
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showModal && (
        <div
          className="modal-backdrop fade show"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        ></div>
      )}

      <div style={{ display: "none" }}>
        <div
          className="recieptlastsummary"
          style={{
            height: "auto",
            width: printType === 0 ? "80mm" : "100%", // Set width based on printtype
            // padding: printType === 1 ? "25px" : "0",
          }}
        >
          <table
            style={{
              width: printType === 0 ? "80mm" : "400px",
              margin: printType === 0 ? "0px 0px" : "0 auto",
              padding: printType === 0 ? "0px" : "25px 25px 15px",
            }}
          >
            <tbody>
              <tr>
                <td>
                  <table style={{ width: "100%", textAlign: "center" }}>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            fontSize: "20px",
                            lineHeight: "24px",
                            color: "#000",
                            padding: "5px 0",
                          }}
                        >
                          <strong>
                            End of Day Summary
                            <br />
                            (Register)
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            padding: "0",
                            fontSize: "13px",
                            fontWeight: "700",
                            lineHeight: "20px",
                            color: "#000",
                          }}
                        >
                          {userCompanyName}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td style={{ padding: "8px 0" }}></td>
              </tr>
              <tr>
                <td>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      {[
                        {
                          label: "Register:",
                          value: "Cash Register",
                        },
                        {
                          label: "Current User:",
                          value: openRegisterDetails?.name,
                        },
                        { label: "Closure #:", value: lastClosureDetails?.id },
                        {
                          label: "Opening Time:",
                          value: lastClosureDetails?.openingDateTime,
                        },
                        {
                          label: "Closing Time:",
                          value: lastClosureDetails?.closingDateTime,
                        },
                      ].map((item, index) => (
                        <tr key={index}>
                          <td
                            style={{
                              fontSize: "18px",
                              lineHeight: "21px",
                              color: "#000",
                              padding: "5px 0",
                            }}
                          >
                            <strong>{item.label}</strong>
                          </td>
                          <td
                            style={{
                              fontSize: "13px",
                              lineHeight: "18px",
                              color: "#000",
                              padding: "5px 0",
                            }}
                          >
                            {item.value}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td style={{ padding: "8px 0" }}></td>
              </tr>
              <tr>
                <td>
                  <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead>
                      <tr>
                        <td
                          style={{
                            padding: "4px 0",
                            fontSize: "13px",
                            lineHeight: "18px",
                            color: "#000",
                            borderBottom: "1px dashed #000",
                          }}
                        >
                          <b>Payment Types</b>
                        </td>
                        <td
                          style={{
                            padding: "4px 0",
                            fontSize: "13px",
                            lineHeight: "18px",
                            color: "#000",
                            borderBottom: "1px dashed #000",
                          }}
                        >
                          <b>Expected</b>
                        </td>
                        <td
                          style={{
                            padding: "4px 0",
                            fontSize: "13px",
                            lineHeight: "18px",
                            color: "#000",
                            borderBottom: "1px dashed #000",
                          }}
                        >
                          <b>Counted</b>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            padding: "6px 0",
                            fontSize: "13px",
                            lineHeight: "18px",
                            color: "#000",
                          }}
                        >
                          Cash
                        </td>
                        <td
                          style={{
                            padding: "6px 0",
                            fontSize: "13px",
                            lineHeight: "18px",
                            color: "#000",
                          }}
                        >
                          {lastClosureDetails?.totalAmount || "0.00"}
                        </td>
                        <td
                          style={{
                            padding: "6px 0",
                            fontSize: "13px",
                            lineHeight: "18px",
                            color: "#000",
                          }}
                        >
                          {lastClosureDetails?.drawerCash || "0.00"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan="3"
                          style={{
                            padding: "6px 10px",
                            fontSize: "13px",
                            lineHeight: "18px",
                            color: "#000",
                            borderTop: "1px solid #000",
                          }}
                        >
                          Cash Movements
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead>
                      <tr>
                        <td
                          style={{
                            padding: "4px 0",
                            fontSize: "13px",
                            lineHeight: "18px",
                            color: "#000",
                            borderBottom: "1px dashed #000",
                          }}
                        >
                          <b>User</b>
                        </td>
                        <td
                          style={{
                            padding: "4px 0",
                            fontSize: "13px",
                            lineHeight: "18px",
                            color: "#000",
                            borderBottom: "1px dashed #000",
                          }}
                        >
                          <b>Amount</b>
                        </td>
                        <td
                          style={{
                            padding: "4px 0",
                            fontSize: "13px",
                            lineHeight: "18px",
                            color: "#000",
                            borderBottom: "1px dashed #000",
                          }}
                        >
                          <b>Type</b>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {companyCashDetail && companyCashDetail.length > 0 ? (
                        companyCashDetail
                          .filter((item) => item.type !== "Closing float")
                          .map((item, index) => {
                            let amountStyle = {};
                            let displayAmount = item.totalAmount || "0.00";

                            // Apply color and minus sign based on the type of transaction
                            if (item.type === "Opening float") {
                              amountStyle = { color: "black" };
                            } else if (
                              item.type === "Cash In" ||
                              item.type === "Petty Cash In"
                            ) {
                              amountStyle = { color: "green" };
                            } else if (
                              item.type === "Cash Out" ||
                              item.type === "Petty Cash Out"
                            ) {
                              amountStyle = { color: "red" };
                              displayAmount = `-${displayAmount}`; // Add minus sign for outflows
                            }
                            return (
                              <tr key={index}>
                                <td
                                  style={{
                                    padding: "6px 0",
                                    fontSize: "13px",
                                    lineHeight: "18px",
                                    color: "#000",
                                  }}
                                >
                                  {" "}
                                  {item.name}
                                </td>
                                <td
                                  style={{
                                    ...amountStyle,
                                    padding: "6px 0",
                                    fontSize: "13px",
                                    lineHeight: "18px",
                                    color: "#000",
                                  }}
                                >
                                  {" "}
                                  {displayAmount}
                                </td>
                                <td
                                  style={{
                                    padding: "6px 0",
                                    fontSize: "13px",
                                    lineHeight: "18px",
                                    color: "#000",
                                  }}
                                >
                                  {item.type}
                                </td>
                              </tr>
                            );
                          })
                      ) : (
                        <tr>
                          <td colSpan={4}>No Cash Movements</td>
                        </tr>
                      )}
                      <tr>
                        <td
                          style={{
                            padding: "6px 5px 6px 10px",
                            fontSize: "13px",
                            lineHeight: "18px",
                            color: "#000",
                            borderTop: "1px solid #000",
                          }}
                        >
                          Cash Payment Received
                        </td>
                        <td
                          colSpan="2"
                          style={{
                            padding: "6px 10px 6px 5px",
                            fontSize: "13px",
                            lineHeight: "18px",
                            color: "#000",
                            borderTop: "1px solid #000",
                          }}
                        >
                          {paymentarr?.find(
                            (payment) => payment?.paymentType === "Cash"
                          )?.totalGrandTotal || "0.00"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            padding: "6px 5px 6px 10px",
                            fontSize: "13px",
                            lineHeight: "18px",
                            color: "#000",
                          }}
                        >
                          Cash Float
                        </td>
                        <td
                          colSpan="2"
                          style={{
                            padding: "6px 10px 6px 5px",
                            fontSize: "13px",
                            lineHeight: "18px",
                            color: "#000",
                          }}
                        >
                          {lastClosureDetails?.drawerCash || "0.00"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            padding: "6px 0",
                            fontSize: "13px",
                            lineHeight: "18px",
                            color: "#000",
                            borderBottom: "1px solid #000",
                          }}
                        >
                          <b>Cash to keep</b>
                        </td>
                        <td
                          style={{
                            padding: "6px 0",
                            fontSize: "13px",
                            lineHeight: "18px",
                            color: "#000",
                            borderBottom: "1px solid #000",
                          }}
                        ></td>
                        <td
                          style={{
                            padding: "6px 10px 6px 0",
                            fontSize: "13px",
                            lineHeight: "18px",
                            color: "#000",
                            textAlign: "right",
                            borderBottom: "1px solid #000",
                          }}
                        >
                          <b>{lastClosureDetails?.changeGiven || "0.00"}</b>
                        </td>
                      </tr>
                      {(paymentarr || [])
                        ?.filter((item) => item.paymentType !== "Cash")
                        .map((item, index) => {
                          return (
                            <tr key={index}>
                              <td
                                style={{
                                  padding: "4px 0 4px 10px",
                                  fontSize: "13px",
                                  lineHeight: "18px",
                                  color: "#000",
                                }}
                              >
                                {item?.paymentType}
                              </td>
                              <td
                                style={{
                                  padding: "4px 0",
                                  fontSize: "13px",
                                  lineHeight: "18px",
                                  color: "#000",
                                }}
                              >
                                {item?.totalGrandTotal?.toFixed(2)}
                              </td>

                              <td
                                style={{
                                  padding: "4px 10px 4px 0",
                                  fontSize: "13px",
                                  lineHeight: "18px",
                                  color: "#000",
                                  textAlign: "right",
                                }}
                              >
                                {item?.totalGrandTotal?.toFixed(2)}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td
                          style={{
                            padding: "4px 0",
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#000",
                            borderTop: "1px dashed #000",
                          }}
                        >
                          <b>Totals</b>
                        </td>
                        <td
                          style={{
                            padding: "4px 0",
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#000",
                            borderTop: "1px dashed #000",
                          }}
                        >
                          <b>{lastClosureDetails?.totalAmount || "0.00"}</b>
                        </td>
                        <td
                          style={{
                            padding: "4px 10px 4px 0",
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#000",
                            textAlign: "right",
                            borderTop: "1px dashed #000",
                          }}
                        >
                          <b>{lastClosureDetails?.drawerCash || "0.00"}</b>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        className="modal common_modal noteinfo_modal"
        id="noteinfoModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="noteinfoModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="noteclose"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src="assets/img/notes-close-icon.svg" alt="" />
              </button>
            </div>
            <div className="modal-body">
              <div className="notes_tabs">
                <ul
                  className="nav nav-pills notetabs"
                  id="notetabs"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="video_demo-tab"
                      data-toggle="tab"
                      href="#video_demo"
                      role="tab"
                      aria-controls="video_demo"
                      aria-selected="true"
                    >
                      Video Demo
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="page_info-tab"
                      data-toggle="tab"
                      href="#page_info"
                      role="tab"
                      aria-controls="page_info"
                      aria-selected="false"
                    >
                      Page Information
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="settingContent">
                  <div
                    className="tab-pane fade active show"
                    id="video_demo"
                    role="tabpanel"
                    aria-labelledby="video_demo-tab"
                  >
                    <div className="notetabs_content">
                      <h4>Video Demo</h4>
                      <p>
                        In this short tutorial, we’ll walk you through the key
                        features and functions of the Dashboard page.
                      </p>
                      <div className="notes_videos">
                        <img
                          src="assets/img/notes-demo-videos-img.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="page_info"
                    role="tabpanel"
                    aria-labelledby="page_info-tab"
                  >
                    <div className="notetabs_content">
                      <h4>Page Information</h4>
                      <p>
                        This page provides a comprehensive view of all the tools
                        and features available to you. Each section is designed
                        to help you manage your business more efficiently,
                        whether you’re tracking sales, monitoring inventory, or
                        analyzing reports.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OpenCloseCash;
