import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import {
  AddImeiDetailsApi,
  GetImeiDetailsApi,
  getProfileApi,
  GetSubUserProfileApi,
  SetLimitCheckApi,
} from "../Utility/Api";

const Search = ({ props }) => {
  const [imei, setImei] = useState("");
  const [deviceData, setDeviceData] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deviceDatas, setDeviceDatas] = useState(null);
  const [isFreeTrial, setIsFreeTrial] = useState(false);

  const [ImeiCheck, setImeiCheck] = useState();

  useEffect(() => {
    GetImeiDetails();
    let localAdmin = localStorage.getItem("admin");
    let localPerminssion = JSON.parse(localStorage.getItem("permission"));

    if (localAdmin === "0" && localPerminssion?.length) {
      // GetProfile();
      GetSubUserProfile();
    } else {
      GetProfile();
    }
    // GetCustomer(page, searchTerm);
  }, []);

  const GetProfile = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(getProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("get profile", result);
        if (result.status === true) {
          // console.log("comapny", result?.data?.companyDetails?.companyName);

          setImeiCheck(result?.data?.userDetails?.imeiLimit);
        } else if (
          result?.status === false &&
          result?.message === "Token has expired"
        ) {
          Swal.fire(result?.message);
          window.location.href = "https://app.toolboxpos.com";
        } else {
          // console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetSubUserProfile = () => {
    let local = localStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetSubUserProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("subuser profile", result);
        if (result.status === true) {
          setImeiCheck(result?.data?.userDetails?.imeiLimit);
          //console.log("setTimezone", result?.timezone);
        } else if (
          result?.status === false &&
          result?.message === "Token has expired"
        ) {
          Swal.fire(result?.message);
          window.location.href = "https://app.toolboxpos.com";
        } else {
          // console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    const substatus = localStorage.getItem("substatus");
    const planDate = localStorage.getItem("planDate");

    if (
      (substatus === "null" ||
        substatus === "canceled" ||
        substatus === "expired") &&
      new Date(planDate) > new Date()
    ) {
      setIsFreeTrial(true);
    }
  }, []);

  const BasicImei = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer 90022d0d-d6e2-40cd-816c-0e997758adf7"
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`https://dash.imei.info/api/check/0?imei=${imei}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("result basic check imei==>", result);
        if (result?.status === "Done" && result?.result) {
          setDeviceDatas({
            brand: result?.result?.brand,
            model: result?.result?.model,
            imei: result?.result?.imei,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchIMEIDetails = async () => {
    // if (!imei.trim()) {
    //   Swal.fire("Please enter an IMEI number.");
    //   return;
    // }
    // if (isFreeTrial && imeiChecked) {
    //   Swal.fire("You can only check IMEI once in the free trial.");
    //   return;
    // }

    setLoading(true);

    setDeviceData(null);

    try {
      const response = await fetch(
        `https://dash.imei.info/api/check/27?imei=${imei}`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer 90022d0d-d6e2-40cd-816c-0e997758adf7",
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      console.log("Step 1 Response:", data);

      if (!data?.history_id) {
        setError("Service is busy. Try again later.");
        setLoading(false);
        setImei("");
        return;
      }

      setTimeout(async () => {
        try {
          const historyResponse = await fetch(
            `https://dash.imei.info/api/search_history/${data?.history_id}`,
            {
              method: "GET",
              headers: {
                Authorization: "Bearer 90022d0d-d6e2-40cd-816c-0e997758adf7",
                "Content-Type": "application/json",
              },
            }
          );

          const historyData = await historyResponse.json();
          console.log("Step 2 Response:", historyData);

          if (historyData?.status === "Done" && historyData?.result) {
            setImei("");
            setDeviceData({
              blacklist_status:
                historyData?.result?.device_is_clean === "true"
                  ? "Good"
                  : "Device is Blacklisted",
            });
            setModalIsOpen(true);
            ImeiDetails(
              historyData?.result?.device_is_clean === "true"
                ? "Good"
                : "Device is Blacklisted"
            );

            if (isFreeTrial) {
              LimitCheck();
            }
          } else {
            setError("IMEI data not found. Try again later.");
          }
        } catch (err) {
          console.error("History Fetch Error:", err);
          setError("Error fetching history. Try again.");
        } finally {
          setLoading(false);
        }
      }, 5000);
    } catch (err) {
      console.error("Fetch Error:", err);
      setError("Error fetching data. Please try again.");
      setLoading(false);
    }
  };

  const LimitCheck = () => {
    let local = localStorage.getItem("token");

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      imeiLimit: 1,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("limit raw", raw);

    fetch(SetLimitCheckApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result?.status === true) {
          let localAdmin = localStorage.getItem("admin");
          let localPerminssion = JSON.parse(localStorage.getItem("permission"));

          if (localAdmin === "0" && localPerminssion?.length) {
            // GetProfile();
            GetSubUserProfile();
          } else {
            GetProfile();
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const ImeiDetails = (status) => {
    let local = localStorage.getItem("token");

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      imeiNumber: deviceDatas?.imei,
      deviceModel: deviceDatas?.model,
      deviceBrand: deviceDatas?.brand,
      blackListStatus: status,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddImeiDetailsApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result?.status === true) {
          GetImeiDetails();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const [ImeiNumber, setImeiNumber] = useState();
  const GetImeiDetails = () => {
    let local = localStorage.getItem("token");

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetImeiDetailsApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result?.status && result?.data?.length > 0) {
          const imeiNumber = result?.data[0].imeiNumber;
          setImeiNumber(imeiNumber);
          console.log("IMEI Number Saved:", imeiNumber);
        } else {
          console.log("No IMEI data found");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleCheckIMEI = () => {
    if (isFreeTrial && ImeiCheck === 1) {
      Swal.fire("You are on a free trial and can check IMEI only once.");
      return;
    }

    if (!imei.trim()) {
      Swal.fire("Please enter an IMEI number.");
      return;
    }

    // // Convert IMEI to string before comparing
    // if (imei.trim() === String(ImeiNumber)) {
    //   Swal.fire({
    //     title: "IMEI Already Checked!",
    //     text: "This IMEI has already been checked. You can review the details on the IMEI Details screen.",
    //     icon: "info",
    //     confirmButtonText: "Go to IMEI Details",
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       navigate("/imei-details"); // Change this to your actual IMEI details page route
    //     }
    //   });
    //   return;
    // }

    if (imei === String(ImeiNumber)) {
      Swal.fire("This IMEI has already been checked.");
      return;
    }

    fetchIMEIDetails();
    BasicImei();
  };

  return (
    <>
      <div className="common_search">
        <div className="search_input check_imei_field">
          <div>
            <input
              type="text"
              className="form-control"
              placeholder="Enter IMEI Code"
              value={imei}
              onChange={(e) => setImei(e.target.value)}
              disabled={isFreeTrial && ImeiCheck === 1}
            />
          </div>
          <button
            type="button"
            className="checimei_btn"
            onClick={handleCheckIMEI}
            // onClick={() => {
            //   if (isFreeTrial && ImeiCheck === 1) {
            //     Swal.fire(
            //       "You are on a free trial and can check IMEI only once."
            //     );
            //     return;
            //   }

            //   if (!imei.trim()) {
            //     Swal.fire("Please enter an IMEI number.");
            //     return;
            //   }

            //   fetchIMEIDetails();
            //   BasicImei();
            // }}
          >
            {loading ? <i className="fa-solid fa-arrows-rotate"></i> : null}{" "}
            Check IMEI
          </button>{" "}
        </div>
      </div>
      {modalIsOpen && deviceData && deviceDatas && (
        <div
          className="modal common_modal checkimei_modal show"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">IMEI results</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => setModalIsOpen(false)}
                >
                  <span className="close_icon"></span>
                </button>
              </div>{" "}
              <div className="modal-body">
                <div className="result_table">
                  <div className="table-responsive">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td>IMEI:</td>
                          <td>
                            <span className="emptyspace"></span>
                          </td>
                          <td>{deviceDatas?.imei}</td>
                        </tr>
                        <tr>
                          <td>Device Brand:</td>
                          <td>
                            <span className="emptyspace"></span>
                          </td>
                          <td>{deviceDatas?.brand}</td>
                        </tr>
                        <tr>
                          <td>Device Model:</td>
                          <td>
                            <span className="emptyspace"></span>
                          </td>
                          <td> {deviceDatas?.model}</td>
                        </tr>
                        <tr>
                          <td>Blacklisted:</td>
                          <td>
                            <span className="emptyspace"></span>
                          </td>
                          <td
                            style={{
                              color:
                                deviceData?.blacklist_status === "Good"
                                  ? "#60ca87"
                                  : "red",
                            }}
                          >
                            {deviceData?.blacklist_status}
                          </td>
                          {/* <td style={{ color: "#60ca87" }}>
                            {" "}
                            {deviceData?.blacklist_status}
                          </td>{" "} */}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* <div className="modal-body">
                <table className="table">
                  <tbody>
                    <tr>
                      <td>IMEI:</td>
                      <td>
                        <span className="emptyspace"></span>
                      </td>
                      <td>{deviceDatas?.imei}</td>
                    </tr>
                    <tr>
                      <td>Device Brand:</td>
                      <td>
                        <span className="emptyspace"></span>
                      </td>
                      <td>{deviceDatas?.brand}</td>
                    </tr>
                    <tr>
                      <td>Device Model:</td>
                      <td>
                        <span className="emptyspace"></span>
                      </td>
                      <td> {deviceDatas?.model}</td>
                    </tr>
                    <tr>
                      <td>Blacklisted:</td>
                      <td>
                        <span className="emptyspace"></span>
                      </td>
                      <td style={{ color: "#60ca87" }}>
                        {" "}
                        {deviceData?.blacklist_status}
                      </td>{" "}
                    </tr>
                  </tbody>
                </table>
              </div> */}
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary theme_btn"
                  onClick={() => setModalIsOpen(false)}
                >
                  Go Back
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Search;
