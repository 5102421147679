import React, { useEffect } from "react";
import { NavLink, Link } from "react-router-dom";

const SuccesLink = () => {
  useEffect(() => {
    // GetPaymentStatus();
  }, []);

  const BackLogin = () => {
    window.location.href = "https://app.toolboxpos.com/Login";
  };

  return (
    <>
      <header className="header" style={{ backgroundColor: "#84E9FF" }}>
        <div className="container">
          <div className="header_inner">
            <div className="logo">
              <NavLink>
                <img alt="Toolbox Logo" src="img/toolbox-logo-txt.webp" />
              </NavLink>
            </div>
            <nav className="navbar navbar-expand-lg custom_navbar backbtncancel">
              <NavLink className="navbar-brand">
                <img alt="Toolbox Logo" src="img/toolbox-logo-txt.webp" />
              </NavLink>

              <div className="account_btns" onClick={BackLogin}>
                <Link className="accnt_btn" style={{ backgroundColor: "#000" }}>
                  Back
                </Link>
              </div>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav customulresponsivecss"></ul>
              </div>
            </nav>
          </div>
        </div>
      </header>
      <div className="box">
        <div className="success alert ">
          <div className="alert-body">Please Verify Your Email</div>
          <span className="message mt-lg-5">
            A verification link has been sent to your email successfully. Please
            check your inbox and activate your account.
          </span>
        </div>
      </div>
    </>
  );
};
export default SuccesLink;
