import React, { useState, useEffect } from "react";
import { forgotPassApi, ResetPasswordApi, VerifyLinkApi } from "../Utility/Api";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const UpdatePassword = () => {
  const [error, setError] = useState({});
  const [password, setPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [domain, setDomain] = useState("");

  let navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };

  const Validationp = () => {
    let error = {};
    let reg = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    // let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=])(?=.*\d).{6,}$/;
    // const isValid = /^[0-9]+$/.test(value);
    const regex = /^[A-Za-z ]+$/;

    if (!password) {
      error.password = "Password is required.";
    } else if (password.length < 8) {
      error.password = "Password must be at least 8 characters long.";
    } else if (!passwordRegex.test(password)) {
      error.password =
        "Password must have at least 1 uppercase letter, 1 lowercase letter, 1 special character and 1 number.";
    }

    if (!confirmPassword) {
      error.confirmPassword = "Confirm Password is Required.";
    } else if (confirmPassword !== password) {
      error.confirmPassword = "Confirm Password is not match.";
    }
    if (Object.keys(error).length === 0) {
      ForgotApi();
    }
    return error;
  };

  const SubmitPassword = () => {
    setError(Validationp());
  };

  let location = useLocation();

  useEffect(() => {
    //console.log(location?.state?.email);
  }, []);

  const ForgotApi = () => {
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${id}`);

    const raw = JSON.stringify({
      subDomain: domain,
      email: email,
      password: password,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("rawww", raw);
    fetch(ResetPasswordApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("resuul", result);
        if (result?.status === true) {
          Swal.fire(result?.message);
          setLoading(false);
          navigate("/resetpass");
          // window.location.href = "https://app.toolboxpos.com/Login";
        } else {
          Swal.fire(result?.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const BackLogin = () => {
    window.location.href = "https://app.toolboxpos.com/Login";
  };
  const Register = () => {
    window.location.href = "https://app.toolboxpos.com/Signup";
  };

  const handleKeyPress1 = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      SubmitPassword();
    }
  };

  const urlSearchParams = new URLSearchParams(window.location.search);
  var id = urlSearchParams.get("token");

  useEffect(() => {
    GetTokenApi();
  }, []);

  const GetTokenApi = () => {
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      token: id,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(VerifyLinkApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("console.log", result);
        if (result?.status === true) {
          Swal.fire(result?.message);
          setEmail(result?.data?.email);
          setDomain(result?.data?.subDomain);
          setLoading(false);
          // window.location.href = "https://app.toolboxpos.com/Login";
        } else {
          Swal.fire(result?.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  return (
    <div className="main-wrapper sublogincstm">
      <div className="login_page">
        <div className="regi_step_form">
          <form action="" className="stepform">
            <div className="login_inner">
              <div className="login_left">
                <div className="topheader">
                  <a href="#">
                    <img src="img/toolbox-logo-txt.webp" alt="" />
                  </a>
                </div>
                <div className="logo">
                  <a href="#">
                    <img src="img/landing-logo-icon.webp" alt="" />
                  </a>
                </div>
                <div className="other_link">
                  <ul>
                    <li>
                      <a href="#">Terms and conditions</a>
                    </li>
                    <li>
                      <a href="#">Privacy policy</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="login_right">
                <div
                  className="not_store"
                  style={{ cursor: "pointer" }}
                  onClick={BackLogin}
                >
                  <p>Not your store?</p>
                </div>
                <div className="form_area">
                  <div className="form_title">
                    <h4>Reset Password</h4>
                    <p>Please enter your new password.</p>
                  </div>
                  <div className="form_field">
                    <div className="form-group">
                      <label className="cus_label">New Password</label>
                      <div className="input_field">
                        <img
                          src={
                            showPassword
                              ? "assets/img/eyeclose.webp"
                              : "assets/img/eyeup.webp"
                          }
                          className="customupdatepasswordformeyeiconsettingreset"
                          onClick={togglePasswordVisibility}
                          alt={showPassword ? "Hide password" : "Show password"}
                        />
                        <input
                          type={showPassword ? "text" : "password"}
                          // type="password"
                          className="form-control"
                          value={password}
                          autoComplete="new-password"
                          placeholder="*********"
                          onChange={(e) => {
                            setPassword(e.target.value.trim());
                            if (error.password) {
                              setError({ ...error, password: "" });
                            }
                          }}
                          onKeyDown={handleKeyPress1}
                        />
                      </div>
                      {error.password && (
                        <span className="customvalidationdesignlogin">
                          {error.password}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="cus_label">Confirm Password</label>
                      <div className="input_field">
                        <img
                          src={
                            showPassword1
                              ? "assets/img/eyeclose.webp"
                              : "assets/img/eyeup.webp"
                          }
                          className="customupdatepasswordformeyeiconsettingreset"
                          onClick={togglePasswordVisibility1}
                          alt={
                            showPassword1 ? "Hide password" : "Show password"
                          }
                        />
                        <input
                          type={showPassword1 ? "text" : "password"}
                          className="form-control"
                          name="confirmpassword"
                          placeholder="*********"
                          value={confirmPassword}
                          autoComplete="new-password"
                          onChange={(e) => {
                            setConfirmPassword(e.target.value.trim());
                            if (error.confirmPassword) {
                              setError({
                                ...error,
                                confirmPassword: "",
                              });
                            }
                          }}
                          onKeyDown={handleKeyPress1}
                        />{" "}
                      </div>
                      {error.confirmPassword && (
                        <span className="customvalidationdesignlogin">
                          {error.confirmPassword}
                        </span>
                      )}
                    </div>
                    <div className="form-btn">
                      <button
                        type="button"
                        className="submit_btn"
                        onClick={SubmitPassword}
                      >
                        Reset <img src="img/login-next-arrow.webp" alt="" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="already_exist">
                  <p>
                    Don't have an account? <a onClick={Register}>Sign up</a>
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default UpdatePassword;
