import React, { useEffect, useState } from "react";
import MainSidebar from "./MainSidebar";
import SideUl from "./SideUl";
import { Helmet } from "react-helmet";
import Footer from "../Footer";
import moment from "moment";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import FreeTrail from "./FreeTrail";
import {
  getProfileApi,
  GetRecieptApi,
  GetSubUserProfileApi,
  OpenCloseAddApi,
  OpenCloseGetApi,
} from "../Utility/Api";
import CashManagmentOpen from "./CashManagmentOpen";
import { useMemo } from "react";

const CashManagement = () => {
  const [amount, setAmount] = useState("");
  const [note, setNote] = useState("");
  const [error, setError] = useState("");
  const [cashType, setCashType] = useState("");
  const [isFreeTrial, setIsFreeTrial] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [isFreeTrialVisible, setFreeTrialVisible] = useState(true);
  const [cashDetails, setCasgDetails] = useState([]);
  const [registerId, setRegisterId] = useState(null);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [userName, setUserName] = useState();
  const [UserEmail, setUserEmail] = useState();
  const [userCompanyName, setUserCompanyName] = useState();
  const [printType, setPrintType] = useState();

  useEffect(() => {
    const substatus = localStorage.getItem("substatus");
    const planDate = localStorage.getItem("planDate");

    if (
      (substatus === "null" ||
        substatus === "canceled" ||
        substatus === "expired") &&
      new Date(localStorage.getItem("planDate")) > new Date()
    ) {
      setIsFreeTrial(true);
    }
  }, []);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
    localStorage.setItem("sidebar", !isSidebarVisible);
  };

  useEffect(() => {
    GetCashDetails();
    const savedSidebarState = localStorage.getItem("sidebar");
    if (savedSidebarState) {
      setSidebarVisible(JSON.parse(savedSidebarState));
    }
    let localAdmin = localStorage.getItem("admin");
    let localPerminssion = JSON.parse(localStorage.getItem("permission"));
    if (localAdmin === "0" && localPerminssion.length) {
      GetSubUserProfile();
    }
    GetProfile();
    GetReciept();
  }, []);

  const GetProfile = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(getProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("gfdgh", result);
        if (result.status === true) {
          //setData1(result?.data);
          setUserCompanyName(result?.data?.companyDetails?.companyName);
          setUserEmail(result?.data?.userDetails?.email);
          setUserName(result?.data?.userDetails?.userName);
        } else {
          console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetSubUserProfile = () => {
    let local = localStorage.getItem("token");
    //console.log(local);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetSubUserProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("subuser", result);
        if (result.status === true) {
          setUserCompanyName(result?.data?.companyDetails?.companyName);
          setUserEmail(result?.data?.userDetails?.email);
          setUserName(result?.data?.userDetails?.name);
          // setDataUser(result?.data);
        } else {
          console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const toggleFreeTrial = () => {
    setFreeTrialVisible(!isFreeTrialVisible);
  };

  const sidebarStyleone = {
    overflow: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
  };

  useEffect(() => {
    if (isFreeTrial) {
      // toggleSidebar(false);
      toggleFreeTrial(false);
    } else {
      // toggleSidebar(true);
      toggleFreeTrial(true);
    }
  }, [isFreeTrial]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const sidebarElement = document.querySelector(".main-sidebar");
      const toggleButtonElement = document.querySelector(".sidebar-arrow a");

      if (
        sidebarElement &&
        !sidebarElement.contains(event.target) &&
        toggleButtonElement &&
        !toggleButtonElement.contains(event.target)
      ) {
        const mediaQuery = window.matchMedia("(max-width: 1024px)");
        if (mediaQuery.matches) {
          setSidebarVisible(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const GetCashDetails = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(OpenCloseGetApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);

        if (result?.status === true) {
          setLoading(false);
          setCasgDetails(result?.data);
          const openRegister = result?.data?.find(
            (item) => item?.cashManagementDetail?.isclosed === 0
          );

          if (openRegister) {
            // Set the register ID of the open register
            setRegisterId(openRegister?.cashManagementDetail?.id);
            console.log("regidterre", openRegister?.cashManagementDetail?.id);
          } else {
            // If no open register is found, set it to null (or handle it as needed)
            setRegisterId(null);
          }
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setAmount(value);
      setError("");
    } else {
      setError("Please enter a valid amount.");
    }
  };

  // Function to handle the Open Register button click
  const handleOpenRegister = () => {
    if (cashType === "") {
      setError("Cash type is required");
      return;
    }
    if (amount === "") {
      setError("Amount is required");
      return;
    }

    // Call API if validation passes
    AddOpenClose();
    //setShowModal(true);
  };

  const AddOpenClose = () => {
    let local = localStorage.getItem("token");
    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const formatDate = (date) => {
      const dateOptions = {
        month: "short",
        day: "numeric",
        year: "numeric",
      };

      const timeOptions = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };

      // Get the date and time separately
      const formattedDate = date.toLocaleDateString("en-US", dateOptions);
      const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

      // Combine the date and time with the " @ " separator
      return `${formattedDate} @ ${formattedTime}`;
    };

    // Example usage:
    const currentDateFormatted = formatDate(new Date());
    console.log(currentDateFormatted);

    const raw = JSON.stringify({
      name: userName,
      email: UserEmail,
      type: cashType,
      amount: amount,
      note: note,
      date: currentDateFormatted,
      registerId: registerId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("openclose", raw);

    fetch(OpenCloseAddApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("add openclose", result);
        if (result?.status === true) {
          setCashType("");
          setAmount("");
          setNote("");
          setShowModal1(false);
          setShowModal2(false);
          document
            .querySelectorAll(".modal-backdrop")
            .forEach((el) => el.classList.remove("modal-backdrop"));
          // Swal.fire(result?.message);
          GetCashDetails();
          printTable(result);
        } else {
          setShowModal1(false);
          setShowModal2(false);
          setCashType("");
          setAmount("");
          setNote("");
          document
            .querySelectorAll(".modal-backdrop")
            .forEach((el) => el.classList.remove("modal-backdrop"));
          //Swal.fire(result?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return new Date(dateString).toLocaleString("en-US", options);
  };

  const getCashTypeClass = (type) => {
    switch (type) {
      case "Cash In":
        return "green_type";
      case "Cash Out":
        return "red_type";
      case "Opening float":
        return "blue_type";
      case "Petty Cash In":
        return "green_type";
      case "Petty Cash Out":
        return "red_type";
      default:
        return "";
    }
  };

  const getLatestRegister = useMemo(() => {
    if (!cashDetails || cashDetails.length === 0) return null;

    // Sort by createdAt to find the latest entry
    return cashDetails.sort(
      (a, b) =>
        new Date(b.cashManagementDetail.createdAt) -
        new Date(a.cashManagementDetail.createdAt)
    )[0];
  }, [cashDetails]);

  const latestRegister = getLatestRegister;

  const hasClosedRegister = useMemo(() => {
    return latestRegister?.cashManagementDetail.isclosed === 1;
  }, [latestRegister]);

  const generatePrintContent = (data) => {
    const cashManagement = data?.cashManagementDetail;
    const companyCash = data?.companyCashDetail;
    const negativeTypes = ["Petty Cash Out", "Cash Out"];
    const formattedAmount = negativeTypes.includes(companyCash?.type)
      ? `- $ ${companyCash?.totalAmount}`
      : `$ ${companyCash?.totalAmount}`;

    return `
    <html>
      <head>
        <style>
          @media print {
            @page { margin: auto; }
            body { margin-left: 0px; margin-right: 0px; height:auto; }
            header, footer { display: none; }
          }
        </style>
      </head>
      <body>
         <div style="font-family: 'Inter', sans-serif;${
           printType === 0 ? "88mm" : "400px"
         }">
           <div style="margin: ${
             printType === 0 ? "0px" : "0 auto"
           }; padding: ${printType === 0 ? "0px" : "25px 25px 15px"}; width: ${
      printType === 0 ? "88mm" : "400px"
    };">
            <table style="width: 100%; text-align: center;">
              <tbody>
             
                <tr><td style="padding: 5px 0; font-size: 14px; font-weight: 700; color: #000;">${userCompanyName}</td></tr>
                <tr><td style="padding: 10px 0 0; font-size: 13px; font-weight: 500; color: #000;">${
                  companyCash?.type
                }</td></tr>
                <tr><td style="padding: 5px 0; font-size: 24px; color: #000;"><b> ${
                  formattedAmount
                  // companyCash?.totalAmount
                }</b></td></tr>
              </tbody>
            </table>
            <div style="padding: 8px 0;"></div>
            <table className="tabledata" style="width: 100%;">
              <tbody>
                <tr><td style="border-top: 2px dashed #000; padding: 8px 6px;"></td><td style="border-top: 2px dashed #000; padding: 8px 6px;"></td></tr>
                <tr>
                  <td style="padding: 5px 0; text-align: center;"><b>Date:</b></td>
                  <td style="padding: 5px 0; text-align: left;">${
                    companyCash?.date
                  }</td>
                </tr>
                <tr>
                  <td style="padding: 5px 0; text-align: center;"><b>Cashier:</b></td>
                  <td style="padding: 5px 0; text-align: left;">${
                    companyCash?.name
                  }</td>
                </tr>
                <tr>
                  <td style="padding: 5px 0; text-align: center;"><b>Register:</b></td>
                  <td style="padding: 5px 0; text-align: left;">Cash Register</td>
                </tr>
                <tr>
                  <td style="padding: 5px 0; text-align: center;"><b>Note:</b></td>
                  <td style="padding: 5px 0; text-align: left;">${
                    companyCash?.note || "-"
                  }</td>
                </tr>
              <tr>
                 <td style="padding: 5px 0; font-size: 13px; font-weight: 500; line-height: 20px; color: #000; text-align: center;">
                 </td>
                 <td style="padding: 5px 0; font-size: 13px; font-weight: 500; line-height: 20px; color: #000; text-align: left;">
                 </td>
             </tr>

              </tbody>
               <tfoot>
                  <tr><td style="border-top: 2px dashed #000; padding: 8px 6px;"></td><td style="border-top: 2px dashed #000; padding: 8px 6px;"></td></tr>
                </tfoot>
            </table>
          </div>
        </div>
      </body>
    </html>
  `;
  };

  const printTable = (result) => {
    const printContents = generatePrintContent(result.data[0]);

    const width = 800;
    const height = 600;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    const printWindow = window.open("", "_blank");

    if (!printWindow) {
      Swal.fire(
        "Unable to open print window. Please check your pop-up settings."
      );
      return;
    }

    printWindow.document.open();
    printWindow.document.write(printContents);
    printWindow.document.close();

    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 200); // Adjust the delay as needed
  };

  const GetReciept = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetRecieptApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("reciept", result?.data);
        if (result?.status === true) {
          const data = result?.data;

          setPrintType(data.type);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div className={isSidebarVisible ? "sidebar-mini" : ""}>
        <div
          className={`${
            isFreeTrial
              ? "main-wrapper devicelistcstmtableres customclswrapper"
              : "main-wrapper devicelistcstmtableres "
          } `}
        >
          {/* <Helmet>
            <title>Streamline Invoicing Process | Toolbox Invoice List</title>
            <meta
              name="description"
              content="Enhance your invoicing process with our Invoice List tool. Streamline transactions, track payments, and maintain financial clarity for your business."
            />
          </Helmet> */}

          {loading ? (
            <div className="landing_loader">
              <div className="innerloader">
                <img src="img/spin-1s-156px.gif" alt="" />
              </div>
            </div>
          ) : null}
          {isFreeTrial ? (
            <>
              <div className="app-container-trial">
                <FreeTrail />
              </div>
            </>
          ) : null}
          <div style={sidebarStyleone}>
            <nav className="navbar navbar-expand-lg main-navbar sticky">
              <div className="sidebar-arrow">
                <a
                  href="#"
                  data-toggle="sidebar"
                  onClick={(e) => {
                    toggleSidebar();

                    e.preventDefault();
                  }}
                >
                  <img src="assets/img/sidebar-toggle.webp" alt="" />
                </a>
              </div>
              <div className="header-title mr-auto">
                <h4>
                  Cash Management{" "}
                  <a
                    className="notes_icon"
                    href="#"
                    data-toggle="modal"
                    data-target="#noteinfoModal"
                  >
                    <img src="assets/img/notes-icon.svg" alt="" />
                  </a>
                </h4>
              </div>
              <SideUl />
            </nav>
            <MainSidebar />

            <div className="main-content tableback">
              <div className="cash_management_sec">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="cash_mange_record">
                      <div className="cash_manag_left">
                        <h4>Track Company Cash</h4>
                        <p>
                          Record all your cash movements for the day. If you
                          need help
                        </p>
                        <p>
                          Petty cash is&nbsp;a small amount of money kept on
                          hand for small purchases or expenses.
                        </p>
                      </div>

                      {cashDetails?.length === 0 || hasClosedRegister ? (
                        <>
                          {" "}
                          <div className="cash_manag_right">
                            <div className="cash_manage_btn">
                              <button
                                type="button"
                                className="cus_btn default_btn"
                              >
                                Remove Cash
                              </button>
                              <button
                                type="button"
                                className="cus_btn default_btn"
                              >
                                Add Cash
                              </button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="cash_manag_right">
                            <div className="cash_manage_btn">
                              <button
                                type="button"
                                data-toggle="modal"
                                data-target="#removecashModal"
                                className="cus_btn remove_btn"
                                onClick={() => setShowModal2(true)}
                              >
                                Remove Cash
                              </button>
                              <button
                                type="button"
                                data-toggle="modal"
                                data-target="#addcashModal"
                                className="cus_btn add_btn"
                                onClick={() => setShowModal1(true)}
                              >
                                Add Cash
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="clearfix" />
                  </div>
                </div>
                {cashDetails?.length === 0 || hasClosedRegister ? (
                  <CashManagmentOpen
                    onLoad={GetCashDetails}
                    print={printType}
                  />
                ) : (
                  <>
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="table-responsive">
                          <table
                            className="table table-striped common_datatable cash_management_table"
                            id="customerdata"
                          >
                            <thead>
                              <tr>
                                <th>Time (Est)</th>
                                <th>Cashier</th>
                                <th>Type</th>
                                <th>Reasons</th>
                                <th>Amount($)</th>
                              </tr>
                            </thead>
                            <tbody>
                              {cashDetails?.map((cashDetail) => {
                                // Only proceed if cashManagementDetail is closed
                                if (
                                  cashDetail?.cashManagementDetail?.isclosed ===
                                  0
                                ) {
                                  return cashDetail?.companyCashDetail?.map(
                                    (item) => {
                                      let amountStyle = {};
                                      let amountSign = "";

                                      if (
                                        item?.type === "Cash Out" ||
                                        item?.type === "Petty Cash Out"
                                      ) {
                                        amountStyle = { color: "red" };
                                        amountSign = "-";
                                      } else if (
                                        item?.type === "Cash In" ||
                                        item?.type === "Petty Cash In"
                                      ) {
                                        amountStyle = { color: "green" };
                                        amountSign = "";
                                      } else if (
                                        item?.type === "Opening float"
                                      ) {
                                        // Black color for opening float, no sign
                                        amountStyle = { color: "black" };
                                        amountSign = "";
                                      }

                                      return (
                                        <tr key={item?.id}>
                                          {/* <td>{item.date || "-"}</td> */}
                                          <td>
                                            {item?.timeZone
                                              ? moment(item?.timeZone).format(
                                                  "MMM D, YYYY @ h:mm A"
                                                )
                                              : "-"}
                                          </td>
                                          <td>
                                            {item?.name
                                              ? `${item?.name} (${item?.email})`
                                              : "Unknown"}
                                          </td>
                                          <td className="cash_type">
                                            <span
                                              className={getCashTypeClass(
                                                item?.type
                                              )}
                                            >
                                              {item?.type}
                                            </span>
                                          </td>
                                          <td>{item?.note || "-"}</td>
                                          <td style={amountStyle}>
                                            {/* Add the sign and format the total amount */}
                                            {amountSign}
                                            {item?.totalAmount
                                              ? item?.totalAmount.toFixed(2)
                                              : "0.00"}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  );
                                }
                                return null; // Return null if the cashManagementDetail is not closed
                              })}

                              {/* {cashDetails?.map((cashDetail) => {
                                // Only proceed if cashManagementDetail is closed
                                if (
                                  cashDetail.cashManagementDetail.isclosed === 0
                                ) {
                                  return cashDetail.companyCashDetail?.map(
                                    (item) => (
                                      <tr key={item.id}>
                                        <td>{item.date || "-"}</td>
                                        <td>
                                          {item.name
                                            ? `${item.name} (${item.email})`
                                            : "Unknown"}
                                        </td>
                                        <td className="cash_type">
                                          <span
                                            className={getCashTypeClass(
                                              item?.type
                                            )}
                                          >
                                            {item?.type}
                                          </span>
                                        </td>
                                        <td>{item.note || "-"}</td>
                                        <td>
                                          {item.totalAmount
                                            ? item.totalAmount.toFixed(2)
                                            : "0.00"}
                                        </td>
                                      </tr>
                                    )
                                  );
                                }
                                return null; // Return null if the cashManagementDetail is not closed
                              })} */}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>{" "}
                  </>
                )}
              </div>
              <div className="bottom_btns">
                <div className="row">
                  <div className="col-md-12">
                    <div className="bottom_links">
                      <ul>
                        <li>
                          <Link to="/openclosecashregister">
                            Open/Close Float
                          </Link>
                          {/* <a href="#">--</a> */}
                        </li>
                        <li>
                          {/* <a href="#">--</a> */}

                          <Link to="/cashmanagement">Cash Management</Link>
                        </li>
                        <li>
                          <a href="#">Note</a>
                        </li>
                        <li>
                          <a href="#">Signature</a>
                        </li>
                        <li>
                          <a href="#">Start Over</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
      {showModal1 && (
        <div
          className="modal common_modal cus_cash_modal status_option_modal"
          id="addcashModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="addcashModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="addcashModalLabel">
                  Add Cash
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setCashType("");
                    setAmount("");
                    setNote("");
                    document
                      .querySelectorAll(".modal-backdrop")
                      .forEach((el) => el.classList.remove("modal-backdrop"));
                  }}
                >
                  <span className="close_icon" />
                </button>
              </div>
              <div className="modal-body">
                <div className="prod_status_sec">
                  <form method="" action="">
                    <div className="field_row">
                      <div className="field_col col_full">
                        <label className="cus_label">Type</label>
                        <div className="custom_radio">
                          <label htmlFor="cashin">
                            <input
                              type="radio"
                              name="cashtype"
                              id="Cash In"
                              value="Cash In"
                              checked={cashType === "Cash In"}
                              onChange={() => setCashType("Cash In")}
                            />
                            <span className="radio_check" />{" "}
                            <span className="label_txt">Cash in</span>
                          </label>
                          <label htmlFor="prettycashin">
                            <input
                              type="radio"
                              name="cashtype"
                              id="Petty Cash In"
                              value="Petty Cash In"
                              checked={cashType === "Petty Cash In"}
                              onChange={() => setCashType("Petty Cash In")}
                            />
                            <span className="radio_check" />{" "}
                            <span className="label_txt">Petty cash in</span>
                          </label>
                        </div>
                        {error && cashType === "" && (
                          <p style={{ color: "red" }}>{error}</p>
                        )}
                      </div>
                    </div>
                    <div className="field_row">
                      <div className="field_col col_full">
                        <label className="cus_label">Amount</label>
                        <div className="status_name">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={0.0}
                            value={amount}
                            onChange={handleAmountChange}
                          />
                          <span className="dollar_sign">$</span>
                        </div>
                        {error && <p style={{ color: "red" }}>{error}</p>}
                      </div>
                    </div>
                    <div className="field_row">
                      <div className="field_col col_full">
                        <label className="cus_label">
                          Note <small>Optional</small>
                        </label>
                        <div className="status_name">
                          <textarea
                            className="form-control"
                            placeholder="Enter Note"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary default_btn"
                  data-dismiss="modal"
                  onClick={() => {
                    setCashType("");
                    setAmount("");
                    setNote("");
                    document
                      .querySelectorAll(".modal-backdrop")
                      .forEach((el) => el.classList.remove("modal-backdrop"));
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary success_btn"
                  onClick={handleOpenRegister}
                >
                  Add Cash
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showModal2 && (
        <div
          className="modal common_modal cus_cash_modal status_option_modal"
          id="removecashModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="removecashModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="removecashModalLabel">
                  Remove Cash
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setCashType("");
                    setAmount("");
                    setNote("");
                    document
                      .querySelectorAll(".modal-backdrop")
                      .forEach((el) => el.classList.remove("modal-backdrop"));
                  }}
                >
                  <span className="close_icon" />
                </button>
              </div>
              <div className="modal-body">
                <div className="prod_status_sec">
                  <form method="" action="">
                    <div className="field_row">
                      <div className="field_col col_full">
                        <label className="cus_label">Type</label>
                        <div className="custom_radio">
                          <label htmlFor="cashin">
                            <input
                              type="radio"
                              name="cashtype"
                              id="Cash Out"
                              value="Cash Out"
                              checked={cashType === "Cash Out"}
                              onChange={() => setCashType("Cash Out")}
                            />
                            <span className="radio_check" />{" "}
                            <span className="label_txt">Cash out</span>
                          </label>
                          <label htmlFor="prettycashin">
                            <input
                              type="radio"
                              name="cashtype"
                              id="Petty Cash Out"
                              value="Petty Cash Out"
                              checked={cashType === "Petty Cash Out"}
                              onChange={() => setCashType("Petty Cash Out")}
                            />
                            <span className="radio_check" />{" "}
                            <span className="label_txt">Petty cash out</span>
                          </label>
                        </div>
                        {error && cashType === "" && (
                          <p style={{ color: "red" }}>{error}</p>
                        )}
                      </div>
                    </div>
                    <div className="field_row">
                      <div className="field_col col_full">
                        <label className="cus_label">Amount</label>
                        <div className="status_name">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={0.0}
                            value={amount}
                            onChange={handleAmountChange}
                          />
                          <span className="dollar_sign">$</span>
                        </div>
                        {error && <p style={{ color: "red" }}>{error}</p>}
                      </div>
                    </div>
                    <div className="field_row">
                      <div className="field_col col_full">
                        <label className="cus_label">
                          Note <small>Optional</small>
                        </label>
                        <div className="status_name">
                          <textarea
                            className="form-control"
                            placeholder="Enter Note"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary default_btn"
                  data-dismiss="modal"
                  onClick={() => {
                    setCashType("");
                    setAmount("");
                    setNote("");
                    document
                      .querySelectorAll(".modal-backdrop")
                      .forEach((el) => el.classList.remove("modal-backdrop"));
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary danger_btn"
                  onClick={handleOpenRegister}
                >
                  Remove Cash
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className="modal common_modal noteinfo_modal"
        id="noteinfoModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="noteinfoModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="noteclose"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src="assets/img/notes-close-icon.svg" alt="" />
              </button>
            </div>
            <div className="modal-body">
              <div className="notes_tabs">
                <ul
                  className="nav nav-pills notetabs"
                  id="notetabs"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="video_demo-tab"
                      data-toggle="tab"
                      href="#video_demo"
                      role="tab"
                      aria-controls="video_demo"
                      aria-selected="true"
                    >
                      Video Demo
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="page_info-tab"
                      data-toggle="tab"
                      href="#page_info"
                      role="tab"
                      aria-controls="page_info"
                      aria-selected="false"
                    >
                      Page Information
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="settingContent">
                  <div
                    className="tab-pane fade active show"
                    id="video_demo"
                    role="tabpanel"
                    aria-labelledby="video_demo-tab"
                  >
                    <div className="notetabs_content">
                      <h4>Video Demo</h4>
                      <p>
                        In this short tutorial, we’ll walk you through the key
                        features and functions of the Dashboard page.
                      </p>
                      <div className="notes_videos">
                        <img
                          src="assets/img/notes-demo-videos-img.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="page_info"
                    role="tabpanel"
                    aria-labelledby="page_info-tab"
                  >
                    <div className="notetabs_content">
                      <h4>Page Information</h4>
                      <p>
                        This page provides a comprehensive view of all the tools
                        and features available to you. Each section is designed
                        to help you manage your business more efficiently,
                        whether you’re tracking sales, monitoring inventory, or
                        analyzing reports.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CashManagement;
