import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="main-footer" style={{ display: "none" }}>
        <div className="footer-left">
          <a href="#">Toolbox</a>
        </div>
        <div className="footer-right"></div>
      </footer>
    </>
  );
};
export default Footer;
