import React, { useEffect, useState } from "react";
import {
  AddRecieptApi,
  AddServiceReceiptApi,
  baseurl,
  GetAllTicketApi,
  getProfileApi,
  GetRecieptApi,
  GetServiceReceiptApi,
  uploadImageApi,
} from "../Utility/Api";
import Swal from "sweetalert2";

const ServiceReciept = (props) => {
  const [loading, setLoading] = useState("");
  const [printType, setPrintType] = useState();
  const [title, setTitle] = useState("");
  const [logo, setLogo] = useState("");
  const [isLogo, setIsLogo] = useState(true);
  const [logoSize, setLogoSize] = useState();
  const [logoPosition, setLogoPosition] = useState();
  const [header, setHeader] = useState("");
  const [displySales, setDisplaySales] = useState(false);
  const [footer, setFooter] = useState("");
  const [note, setNote] = useState(false);
  const [displayBarcode, setDisplayBarcode] = useState(false);
  const [displayNote, setDisplayNote] = useState(false);
  const [imageMsg, setImageMsg] = useState("");
  const [states, setStates] = useState(false);
  const [priceZero, setPriceZero] = useState(false);
  const [servedPerson, setServedPerson] = useState(false);
  const [TechPerson, setTechPerson] = useState(false);
  const [ticketNo, setTicketNo] = useState(false);
  const [signature, setSignature] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    prodname: 0,
    prodname_sku: 0,
    sku: 0,
  });
  const [customerDetails, setCustomerDetails] = useState({
    fullname: 0,
    email: 0,
    phone: 0,
    address: 0,
  });
  const [serviceDetails, setServiceDetails] = useState({
    dueDate: 0,
    service: 0,
    brand: 0,
    model: 0,
  });
  const [ticketno, setTicketno] = useState();
  const [recieptId, setRecieptId] = useState("");
  const [gstNumber, setSGTNumber] = useState("");
  const [leftMargin, setLeftMargin] = useState(0);
  const [rightMargin, setRightMargin] = useState(0);
  const [gstOnOff, setGstOnOff] = useState(false);

  const handleCheckboxChangeService = (e) => {
    const { name, checked } = e.target;
    setServiceDetails((prevDetails) => ({
      ...prevDetails,
      [name]: checked ? 1 : 0,
    }));
  };

  const handlePrintTypeChange = (e) => {
    setPrintType(parseInt(e.target.value, 10));
  };

  const handleLogoChange = (e) => {
    if (e.target.value === "yes") {
      setIsLogo(true);
    } else if (e.target.value === "no") {
      setIsLogo(false);
      setLogo("");
    } else if (e.target.value === "yes" && e.target.value === "no") {
      setIsLogo(false);
      setLogo("");
    }
  };

  // const handleLogoChange = (e) => {
  //   setIsLogo(e.target.value === "yes");
  // };

  const handleLogoSizeChange = (e) => {
    setLogoSize(e.target.value);
  };

  const handleLogoPositionChange = (e) => {
    setLogoPosition(e.target.value);
  };
  const handleCheckboxChange = () => {
    setDisplaySales((prevDisplay) => !prevDisplay);
  };
  const handleCheckboxChangeBarcode = () => {
    setDisplayBarcode((prevDisplay) => !prevDisplay);
  };
  const handleOptionChange = (e) => {
    const { id } = e.target;
    let updatedOptions = {};

    if (id === "prodname_sku") {
      updatedOptions = {
        prodname: 1,
        sku: 1,
        prodname_sku: 1,
      };
    } else if (id === "prodname") {
      updatedOptions = {
        prodname: 1,
        sku: 0,
        prodname_sku: 0,
      };
    } else if (id === "sku") {
      updatedOptions = {
        prodname: 0,
        sku: 1,
        prodname_sku: 0,
      };
    }

    setSelectedOption(updatedOptions);
  };

  const handleCheckboxChangeNote = () => {
    setDisplayNote((prevDisplay) => !prevDisplay);
  };

  const handleCheckboxChangePrice = () => {
    setPriceZero((prevDisplay) => !prevDisplay);
  };
  const handleCheckboxChangeDisplayNote = () => {
    setNote((prevDisplay) => !prevDisplay);
  };
  const handleCheckboxChangeName = (e) => {
    const { name, checked } = e.target;
    setCustomerDetails((prevDetails) => ({
      ...prevDetails,
      [name]: checked ? 1 : 0,
    }));
  };
  const handleCheckboxChangeServed = () => {
    setServedPerson((prevDisplay) => !prevDisplay);
  };
  const handleCheckboxChangeTech = () => {
    setTechPerson((prevDisplay) => !prevDisplay);
  };
  const handleCheckboxChangeTicket = () => {
    setTicketNo((prevDisplay) => !prevDisplay);
  };
  const handleCheckboxChangeSignature = () => {
    setSignature((prevDisplay) => !prevDisplay);
  };

  const handleImagesDevice = (e) => {
    var file = e.target.files[0];

    if (file) {
      const minSizeInBytes = 500; // 5 KB
      const maxSizeInBytes = 1024 * 1024; // 1 MB
      const maxDimension = 500; // Maximum dimension (width or height) in pixels

      if (file.size < minSizeInBytes) {
        // Image size is below the minimum allowed size (5 KB)
        setImageMsg("Image size is below the minimum allowed size (500px)");
        e.target.value = null;
        setTimeout(() => {
          setImageMsg("");
        }, 2000);
        return;
      }

      // Create a new FileReader to read the image
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (event) {
        var img = new Image();
        img.src = event.target.result;
        img.onload = function () {
          // Check the dimensions of the image
          if (img.width > maxDimension || img.height > maxDimension) {
            // Image dimensions exceed the maximum allowed dimension (500px)
            setImageMsg(
              "Image dimensions exceed the maximum allowed dimension (500px)"
            );

            e.target.value = null;
            setTimeout(() => {
              setImageMsg("");
            }, 2000);
            return;
          }
          const fileName = file.name;
          const lastDotIndex = fileName.lastIndexOf(".");
          const baseName = fileName.substring(0, lastDotIndex);
          const extension = fileName.substring(lastDotIndex + 1);

          // Only allow files with a single dot before the extension (e.g., image.jpg, not image.34.jpg)
          if (baseName.includes(".")) {
            setImageMsg(
              "File name is not valid. Only one dot is allowed before the extension."
            );
            e.target.value = null;

            // Hide the message after 2 seconds
            setTimeout(() => {
              setImageMsg("");
            }, 2000);

            return;
          }
          // If image passes all checks, upload it
          let size = file.type.replace("/", ".");
          var blob = file.slice(0, file.size, file.type);
          const newName = new Date().getTime() + size;
          var newFile = new File([blob], newName, { type: file.type });

          UploadImage(newFile);
        };
      };
    }
  };

  const UploadImage = async (newFile) => {
    //setLoading(true);
    var formdata = new FormData();
    formdata.append("file", newFile);
    formdata.append("type", "logo-images");

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(uploadImageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLogo(result?.data);
        setImageMsg("");
        // setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        //setLoading(false);
      });
  };

  const HandleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete image",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c76cff",
      cancelButtonColor: "#efefef",
      confirmButtonText: "Yes, delete it!",
      confirmButtonText: "<span style='color: white;'>Yes, delete it!</span>",
      cancelButtonText: "<span style='color: black;'>cancel!</span>",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "sweet-alert2-container-zindex",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
          confirmButtonColor: "#c76cff",
          customClass: {
            container: "sweet-alert2-container-zindex",
          },
        }).then(() => {
          setLogo("");
        });
      }
    });
  };

  const Reciept = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      type: printType,
      receiptTitle: title,
      receiptTemplate: 1,
      isLogo: isLogo ? 1 : 0,
      logo: logo ? logo : "",
      logoSize: logoSize,
      logoPosition: logoPosition,
      headerInfo: header,
      displaySalesPersonName: servedPerson ? 1 : 0,
      displayNextTicket: ticketNo ? 1 : 0,
      ticketId: ticketno,
      servedBySalesPersonlabel: props?.name,
      printProducts: priceZero ? 1 : 0,
      items: {
        name: selectedOption.prodname,
        sku: selectedOption.sku,
        note: displayNote ? 1 : 0,
      },
      customer: {
        name: customerDetails.fullname,
        email: customerDetails.email,
        phoneNumber: customerDetails.phone,
        address: customerDetails.address,
      },
      service: {
        brand: serviceDetails.brand,
        model: serviceDetails.model,
        service: serviceDetails.service,
        dueDate: serviceDetails.dueDate,
        displayTechnicianName: TechPerson ? 1 : 0,
        servedByTechnicianlabel: props?.name,
      },
      footerInfo: footer,
      displayBarcode: displayBarcode ? 1 : 0,
      displayPublicNote: note ? 1 : 0,
      displaySignatureLine: signature ? 1 : 0,
      // gst: gstNumber,
      // rightMargin: Number(rightMargin),
      // leftMargin: Number(leftMargin),
      // gstButton: gstOnOff ? 1 : 0,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("raw", raw);
    fetch(AddServiceReceiptApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("reciep ,", result);
        if (result?.status === true) {
          Swal.fire(result?.message);
          GetReciept();
          setLoading(false);
        } else if (
          result?.message ===
          "notNull Violation: service_ticket_receipts.type cannot be null"
        ) {
          setLoading(false);
          Swal.fire("Please Select Print Type ");
        } else {
          setLoading(false);
          Swal.fire(result?.message);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const GetReciept = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetServiceReceiptApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("service reciept", result);
        if (result?.status === true) {
          if (!result?.data) {
            setStates(true);
          } else {
            const data = result?.data;
            setRecieptId(data?.id);
            setPrintType(data?.type);
            setTitle(data?.receiptTitle);
            setLogo(data?.logo);
            setIsLogo(data?.isLogo === 1);
            setLogoSize(data?.logoSize);
            setLogoPosition(data?.logoPosition);
            setHeader(data?.headerInfo);
            setDisplaySales(data?.displaySalesPersonName === 1);
            setTicketNo(data?.displayNextTicket === 1);
            setFooter(data?.footerInfo);
            setNote(data?.displayPublicNote === 1);
            setDisplayBarcode(data?.displayBarcode === 1);
            setDisplayNote(data?.items?.note === 1);
            setPriceZero(data?.printProducts);
            setSignature(data?.displaySignatureLine);
            setServedPerson(data?.displaySalesPersonName);
            //setSGTNumber(data?.gst);
            // setLeftMargin(data?.leftMargin);
            // setRightMargin(data?.rightMargin);
            // setGstOnOff(data?.gstButton);
            // setImageMsg(""); // Assuming image message is not returned from API
            //setStates(data.activeInactive === 1);

            // Set selected options based on API response
            setSelectedOption({
              prodname: data?.items.name === 1 ? 1 : 0,
              prodname_sku:
                data?.items.name === 1 && data?.items.sku === 1 ? 1 : 0,
              sku: data?.items.sku === 1 ? 1 : 0,
            });

            // Set customer details based on API response
            setCustomerDetails({
              fullname: data?.customer.name === 1 ? 1 : 0,
              email: data?.customer.email === 1 ? 1 : 0,
              phone: data?.customer.phoneNumber === 1 ? 1 : 0,
              address: data?.customer.address === 1 ? 1 : 0,
            });
            setServiceDetails({
              dueDate: data?.service.dueDate === 1 ? 1 : 0,
              brand: data?.service.brand === 1 ? 1 : 0,
              model: data?.service.model === 1 ? 1 : 0,
              service: data?.service.service === 1 ? 1 : 0,
            });
            setTechPerson(data?.service?.displayTechnicianName);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    GetReciept();
    GetAlltickets();
  }, []);

  const UpdateReciept = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      type: printType,
      receiptTitle: title,
      receiptTemplate: 1,
      isLogo: isLogo ? 1 : 0,
      logo: logo ? logo : "",
      logoSize: logoSize,
      logoPosition: logoPosition,
      headerInfo: header,
      displaySalesPersonName: servedPerson ? 1 : 0,
      displayNextTicket: ticketNo ? 1 : 0,
      ticketId: ticketno,
      servedBySalesPersonlabel: props?.name,
      printProducts: priceZero ? 1 : 0,
      items: {
        name: selectedOption.prodname,
        sku: selectedOption.sku,
        note: displayNote ? 1 : 0,
      },
      customer: {
        name: customerDetails.fullname,
        email: customerDetails.email,
        phoneNumber: customerDetails.phone,
        address: customerDetails.address,
      },
      service: {
        brand: serviceDetails.brand,
        model: serviceDetails.model,
        service: serviceDetails.service,
        dueDate: serviceDetails.dueDate,
        displayTechnicianName: TechPerson ? 1 : 0,
        servedByTechnicianlabel: props?.name,
      },
      footerInfo: footer,
      displayBarcode: displayBarcode ? 1 : 0,
      displayPublicNote: note ? 1 : 0,
      displaySignatureLine: signature ? 1 : 0,
      // gst: gstNumber,
      // rightMargin: Number(rightMargin),
      // leftMargin: Number(leftMargin),
      // gstButton: gstOnOff ? 1 : 0,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("update raww", raw);
    console.log("raww", `${baseurl}updateServiceReceiptConfig/${recieptId}`);
    fetch(`${baseurl}updateServiceReceiptConfig/${recieptId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          Swal.fire(result?.message);
          GetReciept();
          setLoading(false);
        } else if (
          result?.message ===
          "notNull Violation: service_ticket_receipts.type cannot be null"
        ) {
          setLoading(false);
          Swal.fire("Please Select Print Type ");
        } else {
          setLoading(false);
          Swal.fire(result?.message);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const printTableService = () => {
    const printContents = document.querySelector(
      ".reciepttableservice"
    ).innerHTML;
    const originalContents = document.body.innerHTML;
    // Calculate the position to center the new window
    const width = 800;
    const height = 600;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    // Open a new window with centered position
    const printWindow = window.open(
      "",
      "_blank",
      `width=${width},height=${height},top=${top},left=${left}`
    );

    if (!printWindow) {
      Swal.fire(
        "Unable to open print window. Please check your pop-up settings."
      );
      return;
    }

    // const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write(`
    <html>
      <head>
     <style>
     @media print {
  @page {
    margin:auto; /* Remove default margins to ensure there's no space at the top and bottom */
  }
  body {
 /* Remove margins on body as well */
     margin-left:0px;
    margin-right: 0px;
     height:auto
  }
  /* Hide browser print headers and footers */
  header,
  footer {
    display: none;
  }
}
     </style>

     </head>
      <body>${printContents}</body>
    </html>
  `);
    printWindow.document.close();
    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 200); // Adjust the delay as needed
    // printWindow.print();
    // printWindow.close();
  };

  const GetAlltickets = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllTicketApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("ticket list", result?.data.reverse());
        const nextTicketNumber = result?.data.length + 1;
        setTicketno(nextTicketNumber);

        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };
  const handleTicketChange = (e) => {
    const inputValue = e.target.value;

    // Allow the user to delete the input, but revert to the valid ticket number if empty or invalid input
    if (inputValue === "" || isNaN(parseInt(inputValue, 10))) {
      setTicketno(ticketno); // Keep the valid ticket number in case of invalid input
      return;
    }

    let enteredTicketNo = parseInt(inputValue, 10);

    // If the entered value is not the next valid number, reset it
    if (enteredTicketNo !== ticketno) {
      setTicketno(ticketno); // Reset to the correct next ticket number
    }
  };
  const handleFooterChange = (e) => {
    const value = e.target.value;
    const lines = value.split("\n");

    // If there are more than 20 lines, trim the text to 20 lines
    if (lines.length > 20) {
      setFooter(lines.slice(0, 20).join("\n")); // Restrict to 20 lines
    } else {
      setFooter(value); // Allow text if it's within 20 lines
    }
  };

  return (
    <>
      <div className="setting_content receipt_config_setup service_ticket_reciept">
        <h4>Service Ticket Receipt Configuration &amp; Setup</h4>
        <div className="inner_block plan_billing_setting">
          <form className="setting_form" action="" method="post">
            <div className="block_column wd100">
              <h5>Service Ticket Receipt Configuration &amp; Setup</h5>
              <p>
                Here you can manage and configure your stores receipt, how it
                will look and how it will be printed.
              </p>
              <div className="inner_field">
                <div className="tax_label_block">
                  <div className="tax_label">
                    <h6>Default print type</h6>
                    <span>
                      This is the default print method in cash register during a
                      sale
                    </span>
                  </div>
                  <div className="custom_radio">
                    <label htmlFor="thermal">
                      <input
                        type="radio"
                        name="printtype"
                        id="thermal"
                        value="0"
                        checked={printType === 0}
                        onChange={handlePrintTypeChange}
                      />
                      <span className="radio_check" />{" "}
                      <span className="label_txt">Thermal</span>
                    </label>
                    <label htmlFor="fullpage">
                      <input
                        type="radio"
                        name="printtype"
                        id="fullpage"
                        value="1"
                        checked={printType === 1}
                        onChange={handlePrintTypeChange}
                      />
                      <span className="radio_check" />{" "}
                      <span className="label_txt">Full Page</span>
                    </label>
                    <label htmlFor="noreceipt">
                      <input
                        type="radio"
                        name="printtype"
                        id="noreceipt"
                        value="2"
                        checked={printType === 2}
                        onChange={handlePrintTypeChange}
                      />
                      <span className="radio_check" />{" "}
                      <span className="label_txt">No receipt</span>
                    </label>
                  </div>
                </div>
                <div className="tax_label_block">
                  <div className="tax_label">
                    <h6>Receipt Template</h6>
                    <span>Choose a template for your receipt</span>
                  </div>
                  <div className="custom_radio">
                    <div className="cusradio">
                      {/* <label htmlFor="template1">
                        <input
                          type="radio"
                          name="receipt_template"
                          id="template1"
                          defaultChecked=""
                        />
                        <span className="radio_check" />
                      </label> */}
                      <div className="cus_recpt_temp receipt_templ">
                        <span className="recpt_title">Standard</span>
                        <div className="template_block">
                          <img
                            src="assets/img/receipt-template-img.png"
                            alt=""
                          />
                        </div>
                        <span className="receipt_note">
                          This template is recconmended
                        </span>
                      </div>
                    </div>
                    {/* <div className="cusradio">
                      <label htmlFor="template2">
                        <input
                          type="radio"
                          name="receipt_template"
                          id="template2"
                        />
                        <span className="radio_check" />
                      </label>
                      <div className="cus_recpt_temp receipt_templ">
                        <span className="recpt_title">Standard</span>
                        <div className="template_block"></div>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="receipt_other_info">
                  <div className="reciept_blockbox header_receipt_block">
                    <div className="receipt_left">
                      <label>
                        Header{" "}
                        <span>
                          Customize your receipts header, to your specifications
                        </span>
                      </label>
                    </div>
                    <div className="receipt_right">
                      <div className="receipt_field receipt_title">
                        <div className="form-group">
                          <label className="cus_label">
                            Service Ticket Title
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Service ticket (Default)"
                            name="receipt_title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                          <span className="receipt_note">
                            This tittle will be displayed at the top of the
                            receipt
                          </span>
                        </div>
                      </div>
                      <div className="receipt_logo_field">
                        <div className="logo_field cus_logo_field">
                          <div className="form-group">
                            <label className="cus_label">Logo</label>
                            <div className="upload_logo">
                              {logo ? (
                                <i
                                  class="fa-regular fa-circle-xmark deletimgcustm1"
                                  onClick={() => HandleDelete()}
                                ></i>
                              ) : null}
                              {logo ? (
                                <img src={logo} class="reciprtconfigimages" />
                              ) : (
                                <>
                                  <input
                                    type="file"
                                    class="file_upload"
                                    name="file_upload"
                                    accept="image/jpeg, image/jpg, image/png"
                                    onChange={handleImagesDevice}
                                  />
                                  <span>Drop or upload logo here</span>
                                </>
                              )}
                            </div>
                            <span className="receipt_note">
                              Upload a JPG, PNG or GIF file, no more than 500px
                            </span>
                            <span style={{ color: "red", fontSize: "13px" }}>
                              {" "}
                              {imageMsg}
                            </span>
                          </div>
                        </div>
                        <div className="nologo_field cus_logo_field">
                          <div className="form-group">
                            <label className="cus_label">No Logo</label>
                            <div className="custom_radio">
                              <label htmlFor="nologo">
                                <input
                                  type="radio"
                                  name="nologo"
                                  id="nologo"
                                  value="no"
                                  checked={!logo}
                                  onChange={handleLogoChange}
                                />
                                <span className="radio_check" />
                              </label>
                            </div>
                            <div className="no_logo">
                              <img src="assets/img/no-logo-icon.svg" alt="" />
                            </div>
                            <span className="receipt_note">
                              You may opt to have no logo on your receipt
                            </span>
                          </div>
                        </div>
                        <div className="logosize_field cus_logo_field">
                          <div class="form-group">
                            <label class="cus_label">Logo Size:</label>
                            <div class="size_field">
                              <select
                                className="form-control"
                                value={logoSize}
                                onChange={handleLogoSizeChange}
                              >
                                <option>Select Size</option>
                                <option value={1}>Large Size</option>
                                <option value={0}>Small Size</option>
                              </select>

                              <span class="receipt_note">
                                Chose weather you want your logo to be
                                displayed, large or small
                              </span>
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="cus_label">Logo Positions:</label>
                            <div class="size_field">
                              <select
                                className="form-control"
                                value={logoPosition}
                                onChange={handleLogoPositionChange}
                              >
                                <option>Select Position</option>
                                <option value={0}>Left</option>
                                <option value={1}>Center</option>
                              </select>

                              <span class="receipt_note">
                                Chose the position of your logo on your receipt
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="header_info_field">
                        <div className="form-group">
                          <label className="cus_label">
                            Header information
                          </label>
                          <textarea
                            className="form-control"
                            name="header_info"
                            value={header}
                            onChange={(e) => setHeader(e.target.value)}
                          />
                          <span className="receipt_note">
                            Try to limit this to one paragraph
                          </span>
                        </div>
                      </div>
                      <div className="sales_person_field">
                        <div className="sales_person_column">
                          <div className="checkbox">
                            <label>
                              <input
                                type="checkbox"
                                name="salesperson"
                                checked={servedPerson}
                                onChange={handleCheckboxChangeServed}
                              />{" "}
                              Display Sales Person (Served by)
                            </label>
                            <span className="receipt_note">
                              This label will display the sales person on the
                              receipt
                            </span>
                          </div>
                          <div className="sales_field">
                            <div className="form-group">
                              <label className="cus_label">
                                Served by Sales Person label:
                              </label>
                              <input
                                type="text"
                                name="served_sales_person"
                                className="form-control"
                                value={props?.name}
                                placeholder="Enter Sales Person"
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                        <div className="sales_person_column">
                          <div className="checkbox">
                            <label>
                              <input
                                type="checkbox"
                                name="salesperson"
                                checked={ticketNo}
                                onChange={handleCheckboxChangeTicket}
                              />{" "}
                              Display Next Ticket #{" "}
                            </label>
                            <span className="receipt_note">
                              Display the ticket # on the receipt, and is set by
                              default to 0
                            </span>
                          </div>
                          <div className="sales_field">
                            <div className="form-group">
                              <label className="cus_label">Ticket #:</label>
                              <input
                                type="text"
                                name="next_picket"
                                className="form-control"
                                placeholder="Enter Ticket"
                                value={ticketno}
                                readOnly
                                // onChange={handleTicketChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="clearfix" />
                    </div>
                  </div>
                  <div className="clearfix" />
                  <div className="reciept_blockbox">
                    <div className="receipt_left">
                      <label>
                        Line Items{" "}
                        <span>
                          Customize the line items displayed on your reciept
                        </span>
                      </label>
                    </div>
                    <div className="receipt_right">
                      <div className="eachline_receipt">
                        <label className="cus_label">
                          For each line item show:
                        </label>
                        <div className="custom_radio">
                          <label htmlFor="prodname">
                            <input
                              type="radio"
                              name="lineitems"
                              id="prodname"
                              checked={
                                selectedOption.prodname === 1 &&
                                selectedOption.sku === 0
                              }
                              onChange={handleOptionChange}
                            />
                            <span className="radio_check"></span> Product Name
                          </label>
                          <label htmlFor="prodname_sku">
                            <input
                              type="radio"
                              name="lineitems"
                              id="prodname_sku"
                              checked={
                                selectedOption.prodname === 1 &&
                                selectedOption.sku === 1
                              }
                              onChange={handleOptionChange}
                            />
                            <span className="radio_check"></span> Product Name
                            and Sku
                          </label>
                          <label htmlFor="sku">
                            <input
                              type="radio"
                              name="lineitems"
                              id="sku"
                              checked={
                                selectedOption.sku === 1 &&
                                selectedOption.prodname === 0
                              }
                              onChange={handleOptionChange}
                            />
                            <span className="radio_check"></span> SKU
                          </label>
                        </div>
                      </div>
                      <div className="display_notes">
                        <div className="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              name="notes"
                              checked={displayNote}
                              onChange={handleCheckboxChangeNote}
                            />{" "}
                            Display Notes
                          </label>
                          <span className="receipt_note">
                            Display notes you add on individual products
                          </span>
                        </div>
                        <div className="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              name="printproduct"
                              checked={priceZero}
                              onChange={handleCheckboxChangePrice}
                            />{" "}
                            Print products with a price of Zero
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="clearfix" />
                  <div className="reciept_blockbox">
                    <div className="receipt_left">
                      <label>
                        Customer Information{" "}
                        <span>
                          Customize which customer information is displayed on
                          your reciept
                        </span>
                      </label>
                    </div>
                    <div className="receipt_right">
                      <div className="eachline_receipt">
                        <label className="cus_label">
                          Display customer details:
                        </label>
                        <div className="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              name="fullname"
                              id="fullname"
                              checked={customerDetails.fullname === 1}
                              onChange={handleCheckboxChangeName}
                            />{" "}
                            Name
                          </label>
                        </div>
                        <div className="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              name="email"
                              id="email"
                              checked={customerDetails.email === 1}
                              onChange={handleCheckboxChangeName}
                            />{" "}
                            Email
                          </label>
                        </div>
                        <div className="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              name="phone"
                              id="phone"
                              checked={customerDetails.phone === 1}
                              onChange={handleCheckboxChangeName}
                            />{" "}
                            Phone Number
                          </label>
                        </div>
                        <div className="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              name="address"
                              id="address"
                              checked={customerDetails.address === 1}
                              onChange={handleCheckboxChangeName}
                            />{" "}
                            Address
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="clearfix" />
                  <div className="reciept_blockbox">
                    <div className="receipt_left">
                      <label>
                        Service Information{" "}
                        <span>
                          Customize which customer information is displayed on
                          your reciept
                        </span>
                      </label>
                    </div>
                    <div className="receipt_right">
                      <div className="eachline_receipt">
                        <label className="cus_label">
                          Display Service details:
                        </label>
                        <div className="custom_checkbox">
                          <div className="checkbox">
                            <label>
                              <input
                                name="brand"
                                type="checkbox"
                                id="brand"
                                checked={serviceDetails.brand === 1}
                                onChange={handleCheckboxChangeService}
                              />
                              Brand
                            </label>
                          </div>
                          <div className="checkbox">
                            <label>
                              <input
                                name="model"
                                type="checkbox"
                                id="model"
                                checked={serviceDetails.model === 1}
                                onChange={handleCheckboxChangeService}
                              />
                              Model
                            </label>
                          </div>
                          <div className="checkbox">
                            <label>
                              <input
                                name="service"
                                type="checkbox"
                                id="service"
                                checked={serviceDetails.service === 1}
                                onChange={handleCheckboxChangeService}
                              />
                              Service Problem
                            </label>
                          </div>
                          <div className="checkbox">
                            <label>
                              <input
                                name="dueDate"
                                type="checkbox"
                                id="dueDate"
                                checked={serviceDetails.dueDate === 1}
                                onChange={handleCheckboxChangeService}
                              />
                              Due Date and Time
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="display_notes">
                        <div className="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              name="notes"
                              checked={TechPerson}
                              onChange={handleCheckboxChangeTech}
                            />{" "}
                            Display Technician (Served by)
                          </label>
                          <span className="receipt_note">
                            This label will display the sales person on the
                            receipt{" "}
                          </span>
                        </div>
                        <div className="form-group">
                          <label className="cus_label">
                            Served by Technician label:
                          </label>
                          <input
                            type="text"
                            name="technician"
                            className="form-control"
                            placeholder="Technician"
                            value={props?.name}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="clearfix" />
                  <div className="reciept_blockbox footer_receipt_block">
                    <div className="receipt_left">
                      <label>
                        Footer/Terms and Conditions{" "}
                        <span>
                          Information that is displayed in your footer section
                          of your receipt, commonly used for terms and
                          conditions
                        </span>
                      </label>
                    </div>
                    <div className="receipt_right">
                      <div className="form-group">
                        <label>Terms and Condidions</label>
                        <textarea
                          className="form-control"
                          name="footer_info"
                          value={footer}
                          onChange={handleFooterChange}
                          //onChange={(e) => setFooter(e.target.value)}
                        />
                        <span className="receipt_note">
                          Try to limit this to 20 Lines
                        </span>
                      </div>
                      <div className="display_notes">
                        <div className="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              name="barcode"
                              checked={displayBarcode}
                              onChange={handleCheckboxChangeBarcode}
                            />{" "}
                            Display Bar Code
                          </label>
                          <span className="receipt_note">
                            Display a barcode that can allow you to later scan
                            and idenity the reciept
                          </span>
                        </div>
                        <div className="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              name="publicnote"
                              checked={note}
                              onChange={handleCheckboxChangeDisplayNote}
                            />{" "}
                            Display Public Note{" "}
                          </label>
                          <span className="receipt_note">
                            Display the note you write with the transaction in
                            the receipt
                          </span>
                        </div>
                        <div className="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              name="publicnote"
                              checked={signature}
                              onChange={handleCheckboxChangeSignature}
                            />{" "}
                            Display Signature Line{" "}
                          </label>
                          <span className="receipt_note">
                            Display a signature line to affirm terms and
                            conditions
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="clearfix" />
                </div>
                <div className="receipt_setup_btn">
                  <button
                    type="button"
                    className="previewbtncstmsetting"
                    onClick={printTableService}
                  >
                    Preview
                  </button>
                  {states === true ? (
                    <button
                      type="button"
                      className="receipt_save_btn"
                      onClick={Reciept}
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="receipt_save_btn"
                      onClick={UpdateReciept}
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
          <div className="clearfix" />
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div className="reciepttableservice">
          <div
            // className="main-wrapper"
            style={{
              width: "80mm",
              // margin: "auto",
              // padding: "auto",
              fontFamily: "Arial, sans-serif",
              height: "auto",
              fontSize: 12,
            }}
          >
            {isLogo && logo ? (
              <div
                style={{
                  textAlign: logoPosition === 0 ? "left" : "center",
                  marginBottom: 25,
                }}
              >
                <img
                  src={logo}
                  style={{
                    width: logoSize === 1 ? 300 : 50,
                    height: logoSize === 1 ? 110 : 55,
                    margin: "0 auto",
                  }}
                  alt="Receipt Logo"
                />
              </div>
            ) : null}
            <div
              style={{
                fontSize: 12,
                fontWeight: 700,
                color: "#000",
              }}
            >
              {title ? title : "--"}
            </div>
            {/* <div
              style={{
                textAlign: "center",
                padding: "5px 0",
                fontSize: "13px",
                fontWeight: "500",
                color: "#000",
              }}
            >
              Address
              <br />
              Phone Number
              <br />
              Email
            </div> */}
            <div
              style={{
                fontSize: 12,
                fontWeight: 500,
                color: "#000",
                marginTop: 20,
                width: "100%",
              }}
            >
              {/* <pre
                style={{
                  fontSize: 12,
                  fontWeight: 500,
                  color: "#000",
                }}
              > */}
              {header ? header.trim().replace(/\n/g, "<br>") : "--"}
              {/* {header ? header : "--"} */}
              {/* </pre> */}
            </div>

            <div style={{ padding: "8px 0" }}></div>

            <div>
              <table style={{ width: "100%" }} border={0}>
                <tbody>
                  <tr>
                    <td
                      style={{
                        fontSize: 12,
                        fontWeight: 400,
                        color: "#000",
                      }}
                    >
                      00/00/2000
                      {/* {moment(time, "YYYY/MM/DD").format("DD/MM/YYYY")} */}
                    </td>
                    <td
                      style={{
                        fontSize: 12,
                        fontWeight: 400,
                        color: "#000",
                      }}
                    >
                      00:00:00 pm
                      {/* {moment(time).format("LT")} */}
                    </td>
                  </tr>
                </tbody>
              </table>

              <div
                style={{
                  fontSize: 12,
                  fontWeight: "400",
                  color: "#000",
                }}
              >
                <span style={{ fontWeight: "700" }}>Ticket:</span> #{ticketno}
              </div>
              <div
                style={{
                  fontSize: 12,
                  fontWeight: "400",
                  color: "#000",
                }}
              >
                <span style={{ fontWeight: "700" }}>Customer:</span>
                {customerDetails?.name === 1
                  ? "First Name last name"
                  : "unassigned "}

                {customerDetails.email === 1 ? (
                  <>
                    {" "}
                    <br />
                    <span
                      style={{
                        fontWeight: 700,
                        marginTop: 35,
                      }}
                    >
                      Email:
                    </span>
                    example@gmail.com
                  </>
                ) : null}

                {customerDetails.phone === 1 ? (
                  <>
                    {" "}
                    <br />
                    <span
                      style={{
                        fontWeight: 700,
                        marginTop: 35,
                      }}
                    >
                      Phone No.:
                    </span>
                    1234567890
                  </>
                ) : null}

                {customerDetails?.address === 1 ? (
                  <>
                    {" "}
                    <br />
                    <span
                      style={{
                        fontWeight: 700,
                        marginTop: 42,
                      }}
                    >
                      Address:
                    </span>
                    Address
                  </>
                ) : null}
              </div>
              <div
                style={{
                  fontSize: 12,
                  fontWeight: "400",
                  color: "#000",
                  marginTop: 0,
                }}
              >
                <span style={{ fontWeight: "700" }}>Due:</span>{" "}
                {serviceDetails.dueDate === 1 ? "00/00/2000 00:00:00 pm" : "--"}
              </div>
            </div>

            <div style={{ padding: "8px 0" }}></div>

            <div>
              <table
                className="tabledata servtabledata"
                style={{ width: "100%", borderCollapse: "collapse" }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        padding: "4px",
                        fontSize: 12,
                        fontWeight: "400",
                        color: "#000",
                        textAlign: "center",
                        borderRight: "2px dashed #000",
                        borderTop: "2px dashed #000",
                      }}
                    >
                      <b>Service</b>
                    </td>
                    <td
                      style={{
                        padding: "4px",
                        fontSize: 12,
                        fontWeight: "400",
                        color: "#000",
                        textAlign: "center",
                        borderTop: "2px dashed #000",
                      }}
                    >
                      <b>Brand & Model</b>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "4px",
                        fontSize: 12,
                        fontWeight: "400",
                        color: "#000",
                        textAlign: "center",
                        borderRight: "2px dashed #000",
                        borderBottom: "2px dashed #000",
                      }}
                    >
                      {serviceDetails.service === 1 ? "Problem" : ""}
                    </td>
                    <td
                      style={{
                        padding: "4px",
                        fontSize: 12,
                        fontWeight: "400",
                        color: "#000",
                        textAlign: "center",
                        borderBottom: "2px dashed #000",
                      }}
                    >
                      {serviceDetails.brand === 1 ? "Brand " : "--"}
                      {"  "}
                      {serviceDetails.model === 1 ? "Model" : "--"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div style={{ padding: "8px 0" }}></div>

            <div>
              <table
                className="tabledata"
                style={{ width: "100%", borderCollapse: "collapse" }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        padding: "4px",
                        fontSize: 12,
                        fontWeight: "700",
                        color: "#000",
                        textAlign: "center",
                        borderBottom: "2px dashed #000",
                      }}
                    >
                      QTY
                    </th>
                    <th
                      style={{
                        padding: "4px",
                        fontSize: 12,
                        fontWeight: "700",
                        color: "#000",
                        textAlign: "left",
                        borderBottom: "2px dashed #000",
                      }}
                    >
                      Item Name
                    </th>
                    <th
                      style={{
                        padding: "4px",
                        fontSize: 12,
                        fontWeight: "700",
                        color: "#000",
                        textAlign: "right",
                        borderBottom: "2px dashed #000",
                      }}
                    >
                      Price
                    </th>
                    <th
                      style={{
                        padding: "4px",
                        fontSize: 12,
                        fontWeight: "700",
                        color: "#000",
                        textAlign: "right",
                        borderBottom: "2px dashed #000",
                      }}
                    >
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{
                        padding: "4px",
                        fontSize: 12,
                        fontWeight: "500",
                        color: "#000",
                        textAlign: "center",
                      }}
                    >
                      1
                    </td>
                    <td
                      style={{
                        padding: "4px",
                        fontSize: 12,
                        fontWeight: "500",
                        color: "#000",
                        textAlign: "left",
                      }}
                    >
                      {selectedOption.prodname === 1 ? "Item Name" : "--"}
                    </td>
                    <td
                      style={{
                        padding: "4px",
                        fontSize: 12,
                        fontWeight: "500",
                        color: "#000",
                        textAlign: "right",
                      }}
                    >
                      0.00
                    </td>
                    <td
                      style={{
                        padding: "4px",
                        fontSize: 12,
                        fontWeight: "500",
                        color: "#000",
                        textAlign: "right",
                      }}
                    >
                      0.00
                    </td>
                  </tr>

                  {/* <tr>
                    <td
                      colSpan="4"
                      style={{
                        textAlign: "center",
                        padding: "10px",
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#000",
                      }}
                    >
                      There is no data found
                    </td>
                  </tr> */}

                  <tr>
                    <td
                      style={{
                        padding: "4px",
                        fontSize: 12,
                        fontWeight: "500",
                        color: "#000",
                        textAlign: "center",
                        borderBottom: "2px dashed #000",
                      }}
                    ></td>
                    <td
                      style={{
                        padding: "4px",
                        fontSize: 12,
                        fontWeight: "500",
                        color: "#000",
                        textAlign: "left",
                        borderBottom: "2px dashed #000",
                      }}
                    ></td>
                    <td
                      style={{
                        padding: "4px",
                        fontSize: 12,
                        fontWeight: "500",
                        color: "#000",
                        textAlign: "right",
                        borderBottom: "2px dashed #000",
                      }}
                    ></td>
                    <td
                      style={{
                        padding: "4px",
                        fontSize: 12,
                        fontWeight: "500",
                        color: "#000",
                        textAlign: "right",
                        borderBottom: "2px dashed #000",
                      }}
                    ></td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td
                      colSpan="2"
                      style={{
                        padding: "4px",
                        fontSize: 12,
                        fontWeight: "500",
                        color: "#000",
                        textAlign: "left",
                      }}
                    >
                      Subtotal
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        padding: "4px",
                        fontSize: 12,
                        fontWeight: "500",
                        color: "#000",
                        textAlign: "right",
                      }}
                    >
                      $ 0.00
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan="2"
                      style={{
                        padding: "4px",
                        fontSize: 12,
                        fontWeight: "400",
                        color: "#000",
                        textAlign: "left",
                      }}
                    >
                      Discounts
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        padding: "4px",
                        fontSize: 12,
                        fontWeight: "500",
                        color: "#000",
                        textAlign: "right",
                      }}
                    >
                      $0.00
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan="2"
                      style={{
                        padding: "4px",
                        fontSize: 12,
                        fontWeight: "500",
                        color: "#000",
                        textAlign: "left",
                      }}
                    >
                      Tax(0%)
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        padding: "4px",
                        fontSize: 12,
                        fontWeight: "500",
                        color: "#000",
                        textAlign: "right",
                      }}
                    >
                      $ 0.00
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan="2"
                      style={{
                        padding: "4px",
                        fontSize: 12,
                        fontWeight: "700",
                        color: "#000",
                        textAlign: "left",
                        borderBottom: "2px dashed #000",
                      }}
                    >
                      Grand Total
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        padding: "4px",
                        fontSize: 12,
                        fontWeight: "700",
                        color: "#000",
                        textAlign: "right",
                        borderBottom: "2px dashed #000",
                      }}
                    >
                      $0.00
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>

            <div style={{ padding: "10px 0" }}></div>
            <table style={{ width: "100%" }} border={0}>
              <tbody>
                <tr>
                  <td
                    style={{
                      // padding: "3px 0px",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#000",
                    }}
                  >
                    ("Payment type")
                    {/* Display Payment Type */}
                  </td>
                  <td
                    style={{
                      // padding: "3px 0px",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#000",
                    }}
                  >
                    Received: <span style={{ fontWeight: 400 }}>$0.00</span>
                  </td>
                  <td
                    style={{
                      // padding: "3px 0px",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#000",
                    }}
                  >
                    00/00/2000 0:00 pm
                  </td>
                </tr>

                <tr>
                  <td
                    style={{
                      // padding: "3px 0px",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#000",
                    }}
                    colSpan={2}
                  >
                    Change Given:{" "}
                    <span style={{ fontWeight: 700 }}>$200.00</span>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      // padding: "10px 0px 3px",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#000",
                    }}
                    colSpan={2}
                  >
                    <span style={{ fontWeight: 700 }}>Cashier:</span>
                    {servedPerson === 1 ? props?.name : "--"}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      padding: "10px 0px 3px",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#000",
                    }}
                    colSpan={2}
                  >
                    <span style={{ fontWeight: 700 }}>Technician:</span>{" "}
                    {TechPerson === 1 ? props?.name : "--"}
                  </td>
                </tr>
              </tbody>
            </table>

            <div style={{ padding: "10px 0" }}></div>

            <div
              style={{
                textAlign: "justify",
                fontSize: "12px",
                fontWeight: "400",
                color: "#000",
                whiteSpace: "pre-wrap",
                // Preserve whitespace and allow wrapping
                wordWrap: "break-word", // Allow long words to break
                maxWidth: "100%",
              }}
              // style={{
              //   textAlign: "center",
              //   fontSize: "12px",
              //   fontWeight: "400",
              //   color: "#000",
              // }}
            >
              <pre
                style={{
                  textAlign: "justify",
                  fontSize: "12px",
                  fontWeight: 400,
                  color: " #000",
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                  maxWidth: "100%",
                }}
              >
                {footer ? footer : "--"}
              </pre>
              <div style={{ padding: "10px 0" }}></div>
              <div style={{ width: "100%", textAlign: "center", border: "0" }}>
                <div>
                  <span
                    style={{
                      padding: "3px 0px 0px",
                      fontSize: 12,
                      fontWeight: 400,
                      lineHeight: "20px",
                      color: "#000",
                    }}
                  >
                    Hst #: 12345678
                  </span>

                  {/* <img
                    src="assets/img/receipt-barcode.jpg"
                    alt="Receipt Barcode"
                  /> */}
                </div>
              </div>

              {/* <img src="assets/img/receipt-logo.svg" alt="Receipt Logo" /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ServiceReciept;
