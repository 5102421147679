import React from "react";

const NotFound = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div className="divcusmtnot">
        <div className="errors">
          <h1 className="code">404</h1>
          <h2 className="desc">Ops... There's something wrong.</h2>
        </div>
        <footer className="footersnot">
          <div className="copyright_p">
            <p>
              <a href="#">ToolboxPos</a>{" "}
              <span>© {currentYear} All Rights Reserved</span>
            </p>
          </div>
        </footer>
      </div>
    </>
  );
};
export default NotFound;
