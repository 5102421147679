import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import MainSidebar from "./MainSidebar";
import SideUl from "./SideUl";
import FreeTrail from "./FreeTrail";
import {
  baseurl,
  CashDrawerDetailsGetApi,
  getProfileApi,
  GetSubUserProfileApi,
  OpenCloseAddApi,
  OpenCloseGetApi,
} from "../Utility/Api";
import Swal from "sweetalert2";

const OpenClose = (props) => {
  const [amount, setAmount] = useState("");
  const [note, setNote] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [cashDrawerId, setCashDrawerId] = useState("");
  const [cashDrawer, setCashDrawer] = useState([
    { Denomination: "$100", value: 100, Quantity: 0, Total: 0 },
    { Denomination: "$50", value: 50, Quantity: 0, Total: 0 },
    { Denomination: "$20", value: 20, Quantity: 0, Total: 0 },
    { Denomination: "$10", value: 10, Quantity: 0, Total: 0 },
    { Denomination: "$5", value: 5, Quantity: 0, Total: 0 },
    { Denomination: "$2", value: 2, Quantity: 0, Total: 0 },
    { Denomination: "$1", value: 1, Quantity: 0, Total: 0 },
    { Denomination: "Quarters (0.25)", value: 0.25, Quantity: 0, Total: 0 },
    { Denomination: "Dimes (0.10)", value: 0.1, Quantity: 0, Total: 0 },
    { Denomination: "Nickels (0.05)", value: 0.05, Quantity: 0, Total: 0 },
    { Denomination: "Pennies (0.01)", value: 0.01, Quantity: 0, Total: 0 },
  ]);
  const [userName, setUserName] = useState();
  const [UserEmail, setUserEmail] = useState();
  const [userCompanyName, setUserCompanyName] = useState();

  const handleChange = (index, field, value) => {
    const updatedCashDrawer = cashDrawer.map((item, i) => {
      if (i === index) {
        const updatedQuantity = parseFloat(value) || 0;
        const updatedTotal = updatedQuantity * item.value; // Calculate total
        return { ...item, [field]: updatedQuantity, Total: updatedTotal };
      }
      return item;
    });
    setCashDrawer(updatedCashDrawer);
  };

  // Calculate the overall total amount
  const totalAmount = cashDrawer.reduce((sum, item) => sum + item.Total, 0);

  useEffect(() => {
    let localAdmin = localStorage.getItem("admin");
    let localPerminssion = JSON.parse(localStorage.getItem("permission"));
    if (localAdmin === "0" && localPerminssion.length) {
      GetSubUserProfile();
    }
    GetProfile();

    GetCashDrawerCounter();
  }, []);

  const GetProfile = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(getProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("gfdgh", result);
        if (result.status === true) {
          //setData1(result?.data);
          setUserCompanyName(result?.data?.companyDetails?.companyName);
          setUserEmail(result?.data?.userDetails?.email);
          setUserName(result?.data?.userDetails?.userName);
        } else {
          console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetSubUserProfile = () => {
    let local = localStorage.getItem("token");
    //console.log(local);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetSubUserProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("subuser", result);
        if (result.status === true) {
          setUserCompanyName(result?.data?.companyDetails?.companyName);
          setUserEmail(result?.data?.userDetails?.email);
          setUserName(result?.data?.userDetails?.name);
          // setDataUser(result?.data);
        } else {
          console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setAmount(value);
      setError("");
    } else {
      setError("Please enter a valid amount.");
    }
  };

  // Function to handle the Open Register button click
  const handleOpenRegister = () => {
    if (amount === "") {
      setError("Amount is required");
      return;
    }

    AddOpenClose();
  };

  const AddOpenClose = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const formatDate = (date) => {
      const dateOptions = {
        month: "short",
        day: "numeric",
        year: "numeric",
      };

      const timeOptions = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };

      // Get the date and time separately
      const formattedDate = date.toLocaleDateString("en-US", dateOptions);
      const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

      // Combine the date and time with the " @ " separator
      return `${formattedDate} @ ${formattedTime}`;
    };

    // Example usage:
    const currentDateFormatted = formatDate(new Date());
    console.log(currentDateFormatted);

    const raw = JSON.stringify({
      name: userName,
      email: UserEmail,
      type: "Opening float",
      amount: amount,
      note: note,
      date: currentDateFormatted,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("openclose", raw);
    fetch(OpenCloseAddApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("add openclose", result);
        if (result?.status === true) {
          setAmount("");
          setNote("");
          props.onLoad();
          printTable(result);
          Swal.fire(result?.message);
        } else {
          setAmount("");
          setNote("");

          Swal.fire(result?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const UpdateCashDrawer = () => {
    if (parseFloat(amount) !== totalAmount) {
      Swal.fire({
        icon: "error",
        title:
          "The total denomination amount does not match the entered amount. Please double-check and try again.",
        customClass: {
          container: "custom-swal-container",
          confirmButton: "custom-swal-confirm-button",
        },

        showCloseButton: true,
      });
    } else {
      let local = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${local}`);
      const filteredCashDrawer = cashDrawer.filter((item) => item.Quantity > 0);
      const dataToPost = filteredCashDrawer.map(({ value, ...rest }) => rest);

      const raw = JSON.stringify({
        cashDrawer: dataToPost,
        totalAmount: totalAmount,
      });

      console.log("cashhdrawer", raw);

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log("raaa", raw);
      fetch(`${baseurl}updateCashDrawerCounter/${cashDrawerId}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);

          if (result?.status === true) {
            //  setCashDrawer([]);
            GetCashDrawerCounter();
            // props?.onLoad();
            setShowModal(false);
            document
              .querySelectorAll(".modal-backdrop")
              .forEach((el) => el.classList.remove("modal-backdrop"));
          } else {
            //setCashDrawer([]);
            //  GetCashDrawerCounter();
            //  props?.onLoad();
            setShowModal(false);
            document
              .querySelectorAll(".modal-backdrop")
              .forEach((el) => el.classList.remove("modal-backdrop"));
          }
        })
        .catch((error) => console.error(error));
    }
  };

  const GetCashDrawerCounter = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(CashDrawerDetailsGetApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result?.status === true) {
          setCashDrawerId(result?.data?.id);
        } else {
          console.log(result);
        }
      })
      .catch((error) => console.error(error));
  };

  const resetValues = () => {
    const resetCashDrawer = cashDrawer.map((item) => ({
      ...item,
      Quantity: 0,
      Total: 0,
    }));
    setCashDrawer(resetCashDrawer);
    //setTotalAmount(0);
  };
  const buttonClass = amount
    ? "cus_reg_btn open_reg_btn success_open_reg"
    : "cus_reg_btn open_reg_btn default_open_reg";

  const generatePrintContent = (data) => {
    const cashManagement = data?.cashManagementDetail;
    const companyCash = data?.companyCashDetail;

    return `
    <html>
      <head>
        <style>
          @media print {
            @page { margin: auto; }
            body { margin-left: 0px; margin-right: 0px; height:auto; }
            header, footer { display: none; }
          }
        </style>
      </head>
      <body>
        <div style="font-family: 'Inter', sans-serif;width: ${
          props?.print === 0 ? "88mm" : "400px"
        };">
           <div style="margin: ${
             props?.print === 0 ? "0px" : "0 auto"
           }; padding: ${
      props?.print === 0 ? "0px" : "25px 25px 15px"
    }; width: ${props?.print === 0 ? "88mm" : "400px"};">
            <table style="width: 100%; text-align: center;">
              <tbody>
               
                <tr><td style="padding: 5px 0; font-size: 14px; font-weight: 700; color: #000;">${userCompanyName}</td></tr>
                <tr><td style="padding: 10px 0 0; font-size: 13px; font-weight: 500; color: #000;">${
                  companyCash?.type
                }</td></tr>
                <tr><td style="padding: 5px 0; font-size: 24px; color: #000;"><b>$ ${
                  companyCash?.totalAmount
                }</b></td></tr>
              </tbody>
            </table>
            <div style="padding: 8px 0;"></div>
            <table className="tabledata" style="width: 100%;">
              <tbody>
                <tr><td style="border-top: 2px dashed #000; padding: 8px 6px;"></td><td style="border-top: 2px dashed #000; padding: 8px 6px;"></td></tr>
                <tr>
                  <td style="padding: 5px 0; text-align: center;"><b>Date:</b></td>
                  <td style="padding: 5px 0; text-align: left;">${
                    companyCash?.date
                  }</td>
                </tr>
                <tr>
                  <td style="padding: 5px 0; text-align: center;"><b>Cashier:</b></td>
                  <td style="padding: 5px 0; text-align: left;">${
                    companyCash?.name
                  }</td>
                </tr>
                <tr>
                  <td style="padding: 5px 0; text-align: center;"><b>Register:</b></td>
                  <td style="padding: 5px 0; text-align: left;">Cash Register</td>
                </tr>
                <tr>
                  <td style="padding: 5px 0; text-align: center;"><b>Note:</b></td>
                  <td style="padding: 5px 0; text-align: left;">${
                    companyCash?.note || "-"
                  }</td>
                </tr>
        
              <tr>
                 <td style="padding: 5px 0; font-size: 13px; font-weight: 500; line-height: 20px; color: #000; text-align: center;">
                 </td>
                 <td style="padding: 5px 0; font-size: 13px; font-weight: 500; line-height: 20px; color: #000; text-align: left;">
                 </td>
             </tr>

              </tbody>
               <tfoot>
                  <tr><td style="border-top: 2px dashed #000; padding: 8px 6px;"></td><td style="border-top: 2px dashed #000; padding: 8px 6px;"></td></tr>
                </tfoot>
            </table>
          </div>
        </div>
      </body>
    </html>
  `;
  };

  const printTable = (result) => {
    const printContents = generatePrintContent(result.data[0]);

    const width = 800;
    const height = 600;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    const printWindow = window.open(
      "",
      "_blank",
      `width=${width},height=${height},top=${top},left=${left}`
    );

    if (!printWindow) {
      Swal.fire(
        "Unable to open print window. Please check your pop-up settings."
      );
      return;
    }

    printWindow.document.open();
    printWindow.document.write(printContents);
    printWindow.document.close();

    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 200); // Adjust the delay as needed
  };

  return (
    <>
      <div className="cus_regis_close">
        <div className="cash_regis_field">
          <div className="cash_reg_icon">
            <img src="assets/img/cash-register-close.svg" alt="" />
          </div>
          <div className="cash_reg_content">
            <h3>Register is closed</h3>
            <p>
              Keep Track of your cash. <br />
              Set an opening float amount to open register, and begin making
              sales.
            </p>
          </div>
          <div className="inner_reg_field">
            <div className="form-group">
              <label>Opening Float</label>
              <div className="cus_input_group">
                <div className="input_feild">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={0.0}
                    value={amount}
                    onChange={handleAmountChange}
                  />
                  <span>$</span>
                </div>
                <div className="input_btn">
                  <button type="button" onClick={() => setShowModal(true)}>
                    <img src="assets/img/cash-counter-icon.svg" alt="" /> Till
                    Counter
                  </button>
                </div>
              </div>
              {error && <p style={{ color: "red" }}>{error}</p>}
            </div>
            <div className="form-group">
              <label>
                Notes <span>Optional</span>
              </label>
              <div className="input_feild">
                <textarea
                  className="form-control"
                  placeholder="Enter a note"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="cash_regis_btns">
          <button
            className={buttonClass}
            // className="cus_reg_btn open_reg_btn default_open_reg"
            onClick={handleOpenRegister}
          >
            Open Register ${amount || 0.0}
          </button>
        </div>
        <div className="cash_regis_btns" style={{ display: "none" }}>
          <button className="cus_reg_btn open_reg_btn success_open_reg">
            Open Register $150.00
          </button>
          <button className="cus_reg_btn skip_btn">Skip</button>
        </div>
      </div>
      {showModal && (
        <>
          <div
            className="modal common_modal cashdrawcounter_modal fade show"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="cashdrawcounterModalLabel"
            style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            aria-modal="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="cashdrawcounterModalLabel">
                    Cash Drawer Counter:
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      resetValues();
                      setShowModal(false);
                      document
                        .querySelectorAll(".modal-backdrop")
                        .forEach((el) => el.classList.remove("modal-backdrop"));
                    }}
                  >
                    <span className="close_icon" />
                  </button>
                  <p>
                    Enter each denomination of currency for manageable counting.
                    <br />
                    This is not necessary for opening float. You can enter a
                    whole number.
                  </p>
                </div>
                <div className="modal-body">
                  <div className="cash_draw_table">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Denomination</th>
                            <th>Quantity</th>
                            <th>Total:</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cashDrawer.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <b>{item.Denomination}</b>
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="cash_qty_field no-arrow-input"
                                  value={item.Quantity}
                                  onChange={(e) =>
                                    handleChange(
                                      index,
                                      "Quantity",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Enter Quantity"
                                  onWheel={(e) => e.target.blur()}
                                />
                              </td>
                              <td>{item.Total.toFixed(2)}</td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan={2}>
                              <b>Total:</b>
                            </td>
                            <td>${totalAmount.toFixed(2)}</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary default_btn"
                    data-dismiss="modal"
                    onClick={() => {
                      resetValues();
                      setShowModal(false);
                      document
                        .querySelectorAll(".modal-backdrop")
                        .forEach((el) => el.classList.remove("modal-backdrop"));
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary theme_btn"
                    onClick={() => {
                      UpdateCashDrawer();
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showModal && (
        <div
          className="modal-backdrop fade show"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        ></div>
      )}
    </>
  );
};
export default OpenClose;
