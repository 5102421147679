import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function ProtectedH(props) {
  let Cmps = props.Cmps;
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const protocol = window.location.protocol;
    const host = window.location.host;

    const home = "/Account";
    const newUrl = `${protocol}//${host}${home}`;
    const targetUrl = `${protocol}//${host}/`;

    // Check if the host is localhost or an IP address
    const isLocalhost =
      host === "toolboxpos.com" ||
      host === "localhost" ||
      host === "127.0.0.1" ||
      host === "0.0.0.0" ||
      /^(\d{1,3}\.){3}\d{1,3}$/.test(host);

    // Check if the host has a subdomain
    const hostParts = host.split(".");
    const hasSubdomain =
      !isLocalhost && hostParts.length > 1 && hostParts[0] !== "toolboxpos.com";

    if (window.location.href === targetUrl && hasSubdomain) {
      window.location.href = newUrl; // Navigate to the new URL
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return;
  }

  return (
    <div>
      <Cmps />
    </div>
  );
}
export default ProtectedH;
