import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Swal from "sweetalert2";

import debounce from "lodash/debounce";
import {
  checkSubdomainApi,
  getComapnyCategoryApi,
  userRegisterApi,
} from "../Utility/Api";
import moment from "moment";

const Register = () => {
  const [state, setState] = useState(0);
  const [value, setValue] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [subDomain, setSubDomain] = useState("");
  const [cpass, setCpass] = useState("");
  const [error, setError] = useState({});
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState("fa fa-eye-slash");
  const [country, setCountry] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [checkboxstate, setCheckboxState] = useState(false);
  const [checkboxstate1, setCheckboxState1] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [ipAddress, setIpAddress] = useState(null);
  const [subdomainState, setSubdomainState] = useState(null);
  const [subd, setSubdomain] = useState("");
  const [statusbar, setStatusbar] = useState(0);
  const [message, setMessage] = useState("");
  const [category, setCategory] = useState([]);
  const [product, setProduct] = useState("");
  const [SubD, setSubD] = useState("");
  const [loading, setLoading] = useState(false);
  const [validstate, setValidState] = useState(false);
  const [hideshow, setHideShow] = useState("Hide");

  const show = () => {
    type === "password" ? setType("text") : setType("password");
    icon === "fa fa-eye" ? setIcon("fa fa-eye-slash") : setIcon("fa fa-eye");
    hideshow === "Hide" ? setHideShow("Show") : setHideShow("Hide");
  };

  let navigate = useNavigate();
  const isDisposableEmail = (email) => {
    const domain = email.split("@")[1];
    return disposableEmailDomains.includes(domain);
  };

  const disposableEmailDomains = [
    // "gmail.com",
    // "yahoo.com",
    // "outlook.com",
    // "hotmail.com",
    // "aol.com",
    // "icloud.com",
    // "mail.com",
    // "yandex.com",
    // "protonmail.com",
    // "zoho.com",
    // "gmx.com",
    // "ymail.com",
    // "comcast.net",
    // "me.com",
    // "msn.com",
    // "live.com",
    // "sbcglobal.net",
    // "verizon.net",
    // "att.net",
    // "cox.net",
    // "smartitventures.com",
    // Add more domains as needed

    "mailinator.com",
    "guerrillamail.com",
    "10minutemail.com",
    "temp-mail.org",
    "yopmail.com",
    "getnada.com",
    "trashmail.com",
    "fakeinbox.com",
    "throwawaymail.com",
    "maildrop.cc",
    "mytemp.email",
    "tempmailo.com",
    "dispostable.com",
    "mintemail.com",
    "airmail.com",
  ];

  const Validation = () => {
    let error = {};
    let reg = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    // let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=])(?=.*\d).{6,}$/;
    // const isValid = /^[0-9]+$/.test(value);
    const regex = /^[A-Za-z ]+$/;
    if (!email) {
      error.email = "Email is required.";
    } else if (!reg.test(email)) {
      error.email = "Invalid email .";
    } else if (isDisposableEmail(email)) {
      error.email =
        "Please use a valid email address. Temporary email addresses are not allowed.";
    }
    if (!password) {
      error.password = "Password is required.";
    } else if (password.length < 8) {
      error.password = "Password is not valid.";
    } else if (!passwordRegex.test(password)) {
      error.password =
        "Password must have at least 1 uppercase letter, 1 lowercase letter, 1 special character and 1 number.";
    }

    if (!fullName) {
      error.fullName = "Full Name is required.";
    } else if (!regex.test(fullName)) {
      error.fullName = "Full Name Is Invalid";
    }
    if (!companyName) {
      error.companyName = "Company Name is required.";
    }
    if (checkboxstate === false) {
      error.checkboxstate = "Please check term and conditions";
    }
    if (checkboxstate1 === false) {
      error.checkboxstate1 = "Please check term and conditions";
    }
    if (!value) {
      error.value = "Phone number is required.";
    }
    if (!subDomain) {
      error.subDomain = "Subdomain is required.";
    } else if (!/^[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*$/.test(subDomain)) {
      error.subDomain =
        "Invalid subdomain format. Use only alphanumeric characters and hyphens. It cannot start or end with a hyphen.";
    } else if (subDomain.startsWith(" ") || subDomain.endsWith(" ")) {
      error.subDomain = "Subdomain cannot start or end with a space.";
    }
    if (Object.keys(error).length === 0) {
      //   console.log(Object.keys(error).length);
      // setCheckboxState(false);
      // setCheckboxState1(false);
      SignupApi();
    }
    return error;
  };

  const Register = () => {
    setError(Validation());
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const hyphenatedValue = inputValue.replace(/\s+/g, "-");
    setSubDomain(hyphenatedValue);
    if (error.subDomain) {
      setError({ ...error, subDomain: "" });
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      //   onsubmit();
    }
  };
  const [completedSteps, setCompletedSteps] = useState([]);
  let location = useLocation();

  useEffect(() => {
    let local = localStorage.getItem("token");

    if (local) {
      navigate("/Login");
    }

    if (location?.state?.state === 3) {
      setState(3);
    }
    if (location?.state?.stat === 2) {
      setState(2);
    }

    // fetch(
    //   "https://ip-api.io/api/json?api_key=fabc32c1-943d-43c7-8a13-ea82cd6ac052"
    // )
    fetch(
      "https://ip-api.io/api/json?api_key=fabc32c1-943d-43c7-8a13-ea82cd6ac052"
    )
      .then((response) => response.json())
      .then((json) => {
        // console.log("country Name", json);
        setCountry(json);
      });
    GetCategory();
  }, []);

  const SignupApi = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userName: fullName,
      email: email,
      password: password,
      subDomain: subDomain,
      phoneNumber: value ? value.replace(/^\+/, "") : "",
      companyName: companyName,
      userRole: "0",
      categoryId: product,
      countryCode: String(country?.country_code),
      currencySymbol: "",
      localTime: moment(new Date()).format("YYYY-MM-DD , hh:mm:ss a"),
      countryName: country?.countryName,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // console.log("object", raw);
    fetch(userRegisterApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("register", result);
        if (result.status === true) {
          setSubdomain(result.data.subDomain);
          localStorage.setItem("subdomain", result.data.subDomain);
          //localStorage.setItem("token", result?.token);
          // localStorage.setItem("email", result?.data?.email);
          setSubD(result.data.subDomain);
          setState(2);
        } else {
          setMessage(result?.errors[0]?.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetCategory = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AsiR0P5lpgj8HXPGJXcD7rsp78oxElWHI.%2F28jZx%2FtRbCtGqn9p%2FVCFEtoEH50MVvcGlqKgZjzaP8"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(getComapnyCategoryApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.status === true) {
          setLoading(false);
          setCategory(result?.data);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error", error);

        setLoading(false);
      });
  };

  const CheckSubDomain = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      subDomain: subDomain,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(checkSubdomainApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.status === true) {
          setSubdomainState(true);
        } else if (result.status === false) {
          setSubdomainState(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const NextLogin = () => {
    navigate("/Login");
  };
  const ver = () => {
    setTimeout(() => {
      setState(3);
    }, 2000);
  };
  const debouncedCheckSubDomain = debounce(CheckSubDomain);
  const renderFormStep = () => {
    switch (state) {
      case 0:
        return (
          <div className="cus_step" id="step1">
            <div className="regis_header reg_themeheader">
              <div className="topheader">
                <div className="logo">
                  <Link to="/">
                    <img src="img/landing-logo-icon.webp" alt="" />
                  </Link>
                </div>
                <div className="already_exist">
                  <p>
                    Already have an account? <Link to="/Login">Sign In</Link>
                  </p>
                </div>
              </div>
              <div className="title">
                <h3>Join Us!</h3>
                <p>
                  To begin this journey, tell us what type of business you
                  operate?
                </p>
              </div>
            </div>
            <div className="product_list">
              {category.map((item) => (
                <div
                  key={item.id}
                  className="product_col"
                  //   onClick={() => {
                  //     setState(1);
                  //   }}
                >
                  <div className="radio">
                    <label>
                      {item.name}
                      <input
                        style={{ cursor: "pointer" }}
                        type="radio"
                        name="product"
                        value={product}
                        onChange={() => {
                          setProduct(item.id);

                          setState(1);
                        }}
                      />
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      case 1:
        return (
          <>
            <div className="cus_step" id="step2">
              <div className="regis_header reg_themeheader">
                <div className="topheader">
                  <div className="logo">
                    <Link to="/">
                      <img src="img/landing-logo-icon.webp" alt="" />
                    </Link>
                  </div>
                  <div className="already_exist">
                    <p>
                      Already have an account? <Link to="/Login">Sign In</Link>
                    </p>
                  </div>
                </div>
                <div className="title">
                  <h3>Register Trial Account</h3>
                  <p>
                    For the purpose of industry regulation, your details are
                    required.
                  </p>
                </div>
              </div>
              <div className="form_field">
                <div className="form-group">
                  <div className="customform">
                    <label className="cus_label">Full Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="fullname"
                      value={fullName}
                      placeholder="Full Name"
                      onChange={(e) => {
                        setFullName(e.target.value);
                        if (error.fullName) {
                          setError({ ...error, fullName: "" });
                        }
                      }}
                    />
                    {error.fullName && (
                      <span className="customvalidationdesignlogin">
                        {error.fullName}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <div className="customform">
                    <label className="cus_label">Email Address</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={email}
                      placeholder="Email Address"
                      onChange={(e) => {
                        setEmail(e.target.value.trim());
                        if (error.email) {
                          setError({ ...error, email: "" });
                        }
                      }}
                    />
                    {error.email && (
                      <span className="customvalidationdesignlogin">
                        {error.email}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-group phone_field">
                  <div className="customform">
                    <label className="cus_label">Phone Number</label>
                    <PhoneInput
                      className="form-control"
                      placeholder="Enter phone number"
                      international
                      countryCallingCodeEditable={false}
                      //limitMaxLength={10}
                      value={value}
                      onChange={(val) => {
                        setValue(val);
                        if (error.value) {
                          setError({ ...error, value: "" });
                        }
                        setValidState(true);
                      }}
                      //   onKeyup={setValidState(true)}
                      defaultCountry={country.country_code}
                      //defaultCountry="IN"
                      error={
                        value
                          ? isValidPhoneNumber(value)
                            ? undefined
                            : "Invalid phone number"
                          : "Phone number required"
                      }
                    />
                    {error.value && (
                      <span className="customvalidationdesignlogin">
                        {error.value}
                      </span>
                    )}
                    {validstate === true ? (
                      <span className="customvalidationdesignlogin">
                        {value && isValidPhoneNumber(value)
                          ? ""
                          : "Invalid phone number"}
                      </span>
                    ) : null}
                  </div>
                </div>

                <div className="form-group">
                  <div className="customform">
                    <label className="cus_label">
                      Password{" "}
                      <span onClick={show}>
                        <i className={icon}> </i>
                        {hideshow}
                        {/* <img src="img/password-hide-icon.svg" alt="" /> */}
                      </span>
                    </label>
                    <span className="note">
                      Use 8 or more characters with a mix of letters, numbers &
                      symbols
                    </span>
                    <input
                      type={type}
                      className="form-control"
                      id="password"
                      name="password"
                      value={password}
                      placeholder="Password"
                      onChange={(e) => {
                        setPassword(e.target.value.trim());
                        if (error.password) {
                          setError({ ...error, password: "" });
                        }
                      }}
                    />
                    {error.password && (
                      <span className="customvalidationdesignlogin">
                        {error.password}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <div className="customform">
                    <label className="cus_label">Company Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="companyname"
                      value={companyName}
                      placeholder="Company Name"
                      onChange={(e) => {
                        setCompanyName(e.target.value);
                        if (error.companyName) {
                          setError({ ...error, companyName: "" });
                        }
                      }}
                    />
                    {error.companyName && (
                      <span className="customvalidationdesignlogin">
                        {error.companyName}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <div className="customform">
                    <label className="cus_label">Store Url</label>
                    <input
                      type="text"
                      className="form-control"
                      name="storeurl"
                      value={subDomain}
                      placeholder=".toolboxpos.com"
                      onChange={handleInputChange}
                      //   onBlur={CheckSubDomain}
                      onKeyUp={CheckSubDomain}
                    />

                    {/* {subdomainState === true && (
                      <img src={Correct} alt="Correct" className="corectimg" />
                    )}
                    {subdomainState === false && (
                      <img src={Delete} alt="Delete" className="corectimg" />
                    )} */}

                    {error.subDomain && (
                      <span className="customvalidationdesignlogin">
                        {error.subDomain}
                      </span>
                    )}
                    {subdomainState === false && (
                      <span className="customvalidationdesignlogin">
                        SubDomain Already Exist.
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <div className="checkbox">
                    <div className="customform">
                      <label>
                        <input
                          type="checkbox"
                          name="checkboxState"
                          onChange={(e) => {
                            setCheckboxState(e.target.checked);
                            if (error.checkboxstate) {
                              setError({ ...error, checkboxstate: "" });
                            }
                          }}
                        />
                        By creating an account, I agree to our{" "}
                        <a href="#">Terms of use</a> and{" "}
                        <a href="#">Privacy Policy</a>
                      </label>
                      {error.checkboxstate && (
                        <span className="customvalidationdesignlogin">
                          {error.checkboxstate}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="checkbox">
                    <div className="customform">
                      <label>
                        <input
                          type="checkbox"
                          name="checkboxState1"
                          onChange={(e) => {
                            setCheckboxState1(e.target.checked);
                            if (error.checkboxstate1) {
                              setError({ ...error, checkboxstate1: "" });
                            }
                          }}
                        />
                        By creating an account, I am also consenting to receive
                        SMS messages and emails, including product new feature
                        updates, events, and marketing promotions.
                      </label>
                      {error.checkboxstate1 && (
                        <span className="customvalidationdesignlogin">
                          {error.checkboxstate1}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="customform">
                  {message && (
                    <span className="customvalidationdesignlogin">
                      {message}
                    </span>
                  )}
                </div>
                <div className="form-btn" onClick={() => Register()}>
                  <input
                    type="button"
                    className="submit_btn"
                    value="Register Account"
                  />
                </div>
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="cus_step" id="step3">
              <div className="regis_header reg_whtheader">
                <div className="topheader">
                  <div className="logo">
                    <Link to="/">
                      <img src="img/landing-logo-icon.webp" alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              {/* <div className="verification_msg custom_msg">
                <p>Verification link sent to your email....</p>
              </div> */}
            </div>
            {/* {ver()} */}
            <div className="verification_msg custom_msg">
              <p>Verification link sent to your email....</p>
            </div>
            <div className="holding_msg custom_msg">
              <p>Hold on....we are creating your store...</p>
              <span>Your login details are being emailed...</span>
              <div className="progress">
                <div
                  className="progress_bar"
                  role="progressbar"
                  aria-valuenow="55"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: " 55%" }}
                ></div>
              </div>
            </div>
          </>
        );
      case 3:
        return (
          <div className="cus_step" id="step3">
            <div className="regis_header reg_whtheader">
              <div className="topheader">
                <div className="logo">
                  <Link to="/">
                    <img src="img/landing-logo-icon.webp" alt="" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="congrates_msg">
              <h3>
                Congratulation! <br />
                Your 14 day trial has begun!
              </h3>
              <p>Explore the toolbox platform.</p>
              <div className="congrat_icon">
                <img src="img/Icon-checkmark.webp" alt="" />
              </div>
              <div className="proceed_btn" onClick={NextLogin}>
                <Link className="cus_btn">
                  Proceed to Platform{" "}
                  <img src="img/angle-right-icon.webp" alt="" />
                </Link>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="main-wrapper custom-mainwrapper">
        {loading ? (
          <div className="landing_loader">
            <div className="innerloader">
              <img src="img/spin-1s-156px.gif" alt="" />
            </div>
          </div>
        ) : null}
        {/* <div className="landing_loader">
          <div className="innerloader">
            <img src="img/landing-logo-icon.svg" alt="" />
          </div>
        </div> */}
        <div className="register_page">
          <div className="regi_step_form">
            {/* <form className="stepform"> */}
            <div className="step_counter">
              <div className="step_label">
                Step{" "}
                {state === 0 ? (
                  <>
                    <span>01</span>/04
                  </>
                ) : null}
                {state === 1 ? (
                  <>
                    <span>02</span>/04
                  </>
                ) : null}
                {state === 2 ? (
                  <>
                    <span>03</span>/04
                  </>
                ) : null}
                {state === 3 ? (
                  <>
                    <span>04</span>/04
                  </>
                ) : null}
              </div>
              <div className="step_count">
                <ul>
                  {[0, 1, 2, 3].map((step) => (
                    <li
                      key={step}
                      className={`${
                        completedSteps.includes(step) ? "completed" : ""
                      } ${state === step ? "active" : ""}`}
                    >
                      <span>{step + 1}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {renderFormStep()}
            {/* </form> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default Register;
