import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { AddCashLayoutApi, baseurl, getProfileApi } from "../Utility/Api";
import { CirclePicker } from "react-color";
import { HexColorPicker } from "react-colorful";
import Swal from "sweetalert2";
import { hsvaToHex, getContrastingColor } from "@uiw/color-convert";
import Swatch from "@uiw/react-color-swatch";

const CashLayout = () => {
  const [layoutState, setLayoutState] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [searchTermProduct, setSearchTermProduct] = useState("");
  const [productState, setProductState] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [displayProduct, setDisplayProduct] = useState(false);
  const [product, setProduct] = useState([]);
  const [selectedProduct, setselectedProduct] = useState();
  const [layoutName, setLayoutName] = useState("");
  const [allLayouts, setAllLayouts] = useState([]);
  const [productColors, setProductColors] = useState({});
  const [productName, setProductName] = useState("");
  const [editState, setEditState] = useState(false);
  const [id, setId] = useState("");
  const [editArray, setEditArray] = useState({});

  const handleChangeComplete = (color, name) => {
    setSelectedColor(color);
    handleProductSelect(selectedProduct, color);
    handleProductSelectedit(selectedProduct, color, name);
  };

  const handleToggleColorPicker = () => {
    setShowColorPicker(!showColorPicker);
  };

  useEffect(() => {
    GetAllProducts();
    GetAllLayouts();
  }, []);

  const GetAllProducts = () => {
    //setLoading(true);
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AJ0pT5xJJsG8rHqot1A6txMupaTPvbUM2.LHRBUtee7s0DQmEebl5p1hig5dbcABIB0m4VWSzru%2Fo"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllItems`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setAllProducts(result?.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
        //setLoading(false);
      });
  };
  const filteredData1 = allProducts?.filter((item) => {
    const lowerCaseSearchTerm = searchTermProduct.toLowerCase();

    return (
      item?.title.toLowerCase().includes(lowerCaseSearchTerm.toLowerCase()) ||
      item?.brand?.name
        .toLowerCase()
        .includes(lowerCaseSearchTerm.toLowerCase()) ||
      item?.skuCode.toString().includes(lowerCaseSearchTerm.toLowerCase())
    );
  });

  const handleSearchTermChangeProduct = (event) => {
    const term = event.target.value;
    setSearchTermProduct(term);
    setShowModal2(true);
    setProductState(true);
  };

  const GetAllLayouts = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllCashRegisterLayout?type=0`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // setAllLayouts(result?.data);
        const layouts = result?.data;
        if (layouts.length === 1 && layouts[0].defaultLayout === 0) {
          // Only one layout found and its defaultLayout is 0
          // Update its defaultLayout to 1
          const updatedLayout = { ...layouts[0], defaultLayout: 1 };
          setAllLayouts([updatedLayout]);
          // Call the API to update the layout in the backend
          UpdateLayout(updatedLayout);
        } else {
          setAllLayouts(layouts);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const AddLayOut = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const layoutItems = selectedProducts.map((product) => {
      return {
        itemId: product.id,
        name: product.title,
        colorCode: product.color,
        skuCode: product.skuCode,
      };
    });
    const raw = JSON.stringify({
      name: layoutName,
      layout: layoutItems,
      defaultLayout: 0,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddCashLayoutApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setLayoutState(false);
          GetAllLayouts();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const [selectedProducts, setSelectedProducts] = useState([]);

  // const handleProductSelect = (item) => {
  //   setSelectedProducts([item, ...selectedProducts]);
  //   console.log("select", [item, ...selectedProducts]);
  // };

  const handleProductSelect = (item, color) => {
    const newProduct = {
      ...item,
      color: color || "#6b7280",
      name: item.name || productName,
    };
    const index = selectedProducts.findIndex((p) => p.id === item.id);

    if (index === -1) {
      setSelectedProducts([newProduct, ...selectedProducts]);
    } else {
      setSelectedProducts([
        ...selectedProducts.slice(0, index),
        newProduct,
        ...selectedProducts.slice(index + 1),
      ]);
    }

    if (selectedProduct && selectedProduct.id === item.id) {
      setselectedProduct(newProduct);
    }

    setSelectedColor(color || "#6b7280");
  };

  const handleProductSelectedit = (item, color, name) => {
    const updatedProducts = selectedProducts.map((product) => {
      if (product.id === item.id) {
        return {
          ...product,
          color: color || "#6b7280",
          name: name || product.name || productName,
        };
      }
      return product;
    });

    setSelectedProducts(updatedProducts);

    if (selectedProduct && selectedProduct.id === item.id) {
      setselectedProduct({
        ...selectedProduct,
        color: selectedColor || "#6b7280",
        name: name || selectedProduct.name || productName,
      });
    }

    setSelectedColor(color || "#6b7280");
  };

  const DeleteLayout = (id) => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}deleteCashRegister/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        GetAllLayouts();
        // Swal.fire(result?.message);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const HandleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete layout",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c76cff",
      cancelButtonColor: "#efefef",
      confirmButtonText: "Yes, delete it!",
      confirmButtonText: "<span style='color: white;'>Yes, delete it!</span>",
      cancelButtonText: "<span style='color: black;'>cancel!</span>",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "sweet-alert2-container-zindex",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your data has been deleted.",
          icon: "success",
          confirmButtonColor: "#c76cff",
          customClass: {
            container: "sweet-alert2-container-zindex",
          },
        }).then(() => {
          DeleteLayout(id);
        });
      }
    });
  };

  const UpdateLayout = (a) => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const layoutItems = a?.layout.map((product) => {
      return {
        itemId: product?.itemId,
        name: product?.name,
        colorCode: product?.colorCode,
        skuCode: product.skuCode,
      };
    });
    const raw = JSON.stringify({
      name: a?.name,
      layout: layoutItems,
      defaultLayout: 1,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseurl}updateCashRegisterLayout/${a?.id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllLayouts();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const UpdateEditLayout = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const layoutItems = selectedProducts.map((product) => {
      return {
        itemId: product.itemId,
        name: product.name,
        colorCode: product.colorCode || selectedColor,
        skuCode: product.skuCode,
      };
    });
    const raw = JSON.stringify({
      name: layoutName,
      layout: layoutItems,
      defaultLayout: 0,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseurl}updateCashRegisterLayout/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllLayouts();
          setLayoutState(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleEditLayout = (layout) => {
    setEditState(true);
    setLayoutState(true);
    setDisplayProduct(true);
    setLayoutName(layout?.name);
    setSelectedProducts(layout?.layout);
    setEditArray(layout?.layout);
    setSearchTermProduct("");
    setId(layout?.id);
  };

  return (
    <>
      <div className="setting_content">
        <h4>Cash Register Layout</h4>
        <div className="inner_block plan_billing_setting">
          <form className="setting_form" action="" method="post">
            {layoutState === false ? (
              <>
                <div className="block_column wd100 add_layout_box">
                  <h5>Cash Register Layout</h5>
                  <p>Add or customize layouts for your cash register</p>
                  <div className="inner_field">
                    <div className="tax_label_block">
                      <div className="tax_label">
                        <h6>Enable Layout Buttons</h6>
                        <span>
                          These are the buttons under the search bar shown in in
                          cash register. Allowing for quicker selections of
                          products
                        </span>
                      </div>
                      <div className="layout_switch_field switch_field">
                        <label className="custom-switch">
                          <input
                            type="checkbox"
                            name="custom-switch-checkbox"
                            className="custom-switch-input"
                            checked=""
                          />
                          <span className="custom-switch-indicator"></span>
                        </label>
                      </div>
                    </div>
                    <div className="tax_label_block">
                      <div className="tax_label">
                        <h6>Layouts</h6>
                        <span>These are your current Layouts</span>
                      </div>
                      <div className="edit_tax_btn">
                        <button
                          type="button"
                          className="cus_btn"
                          onClick={() => {
                            setLayoutState(true);

                            setLayoutName("");
                            setSelectedProducts([]);
                          }}
                        >
                          Add Layout
                        </button>
                      </div>
                    </div>
                    <div className="layout_setting">
                      {allLayouts?.map((item) => (
                        <>
                          <div className="layout_column">
                            <div className="layout_info">
                              <span>{item.name}</span>
                              {item?.defaultLayout === 1 ? (
                                <button type="button" className="current">
                                  Current
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="set_dafault"
                                  onClick={() => {
                                    UpdateLayout(item);
                                  }}
                                >
                                  Set as current
                                </button>
                              )}
                            </div>
                            <div className="layout_action">
                              <a
                                href="#"
                                className="edit_layout"
                                onClick={() => {
                                  handleEditLayout(item);
                                  setSelectedColor("");
                                }}
                              >
                                <img
                                  src="assets/img/layout-edit-icon.webp"
                                  alt=""
                                />
                              </a>
                              <a
                                href="#"
                                className="delete_layout"
                                onClick={() => HandleDelete(item.id)}
                              >
                                <img src="assets/img/delete-icon.webp" alt="" />
                              </a>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            ) : editState === true ? (
              <>
                <div className="add_layout">
                  <div className="block_column wd100">
                    <a
                      onClick={() => {
                        setLayoutState(false);
                        setEditState(false);
                      }}
                      className="back_layout"
                      style={{ cursor: "pointer" }}
                    >
                      <img src="assets/img/back-arrow.webp" alt="" />
                    </a>
                    <h5>Edit Layout</h5>
                    <p>Reposition catergory</p>
                    <div className="inner_field">
                      <div className="tax_label_block">
                        <div className="tax_label">
                          <h6>Select Register</h6>
                          <span>Please assign this layout to a register</span>
                        </div>
                        <div className="tax_field">
                          <select
                            className="form-control"
                            name="payment_option"
                          >
                            {" "}
                            {allLayouts?.map((item, index) => (
                              <option
                                value={item.name}
                                onClick={() => {
                                  handleEditLayout(item);
                                }}
                                // onClick={() => {
                                //   setSelectedProducts(item);

                                //   setDisplayProduct(true);
                                // }}
                              >
                                {item.name}
                              </option>
                            ))}
                          </select>

                          <img src="assets/img/select-arrow.webp" alt="" />
                        </div>
                      </div>
                      <div className="tax_label_block">
                        <div className="tax_label">
                          <h6>Add Products</h6>
                          <span>
                            Add products or categories to your layout, click to
                            customize, label and colour
                          </span>
                        </div>
                      </div>
                      <div className="layout_search">
                        <div className="layout_field">
                          <div className="layout_name">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Layout Name"
                              name="layoutName"
                              value={layoutName}
                              onChange={(e) => setLayoutName(e.target.value)}
                            />
                          </div>
                          <div className="layout_srch">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search Product"
                              value={searchTermProduct}
                              onChange={handleSearchTermChangeProduct}
                              onClick={() => {
                                setSearchTermProduct("");
                              }}
                            />
                            <img src="assets/img/search-icon.webp" alt="" />

                            {showModal2 === true && productState === true ? (
                              <>
                                <div
                                  className={`searchcustomer_list seacrhproductlistcustm ${
                                    showModal2 ? "active" : ""
                                  }`}
                                >
                                  <div className="srchcustmer_title common_title">
                                    <h4>Select Product </h4>
                                  </div>

                                  <div className="customer_list">
                                    <ul>
                                      {filteredData1.length ? (
                                        <>
                                          {filteredData1?.map((item) => (
                                            <li
                                              key={item.id}
                                              onClick={() => {
                                                setShowModal2(false);
                                                handleProductSelectedit(item);
                                                setProductState(false);
                                                setDisplayProduct(true);
                                              }}
                                            >
                                              <div
                                                key={item?.id}
                                                className="custmer_img"
                                              >
                                                {item?.image ? (
                                                  <img
                                                    src={item?.image}
                                                    alt=""
                                                    style={{
                                                      width: "90px",
                                                      height: "90px",
                                                      borderRadius: "50%",
                                                      marginTop: "100px",
                                                    }}
                                                  />
                                                ) : (
                                                  <img
                                                    src="assets/img/customer-info-img.webp"
                                                    alt=""
                                                  />
                                                )}
                                              </div>
                                              <span>
                                                {item?.title}
                                                <small>{item?.skuCode}</small>
                                              </span>
                                            </li>
                                          ))}
                                        </>
                                      ) : (
                                        <div className="text-center">
                                          <h5>No data available</h5>
                                        </div>
                                      )}
                                    </ul>
                                  </div>
                                </div>{" "}
                              </>
                            ) : null}
                          </div>
                        </div>
                        {displayProduct === true ? (
                          <>
                            <div className="layout_product_list">
                              {selectedProducts.map((product, index) => (
                                <div
                                  className="layoout_prod_col"
                                  onClick={() => {
                                    setShowModal(true);
                                    setselectedProduct(product);
                                  }}
                                >
                                  <a
                                    href="#"
                                    data-toggle="modal"
                                    data-target="#layoutModal"
                                  >
                                    {" "}
                                    <div className="selectcustmproduct">
                                      <div
                                        className="classcolorstyle"
                                        style={{
                                          background: selectedColor
                                            ? selectedColor
                                            : product?.colorCode,
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: "13px",
                                            fontWeight: "300",
                                            marginTop: "35px",
                                            textAlign: "center",
                                            color: "#000",
                                          }}
                                        >
                                          {product.name || productName}
                                        </span>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              ))}
                            </div>
                          </>
                        ) : null}
                        <div className="clearfix"></div>
                        <div className="layout_prod_btn">
                          <button
                            type="button"
                            className="prod_save_btn"
                            onClick={UpdateEditLayout}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="add_layout">
                  <div className="block_column wd100">
                    <a
                      onClick={() => setLayoutState(false)}
                      className="back_layout"
                      style={{ cursor: "pointer" }}
                    >
                      <img src="assets/img/back-arrow.webp" alt="" />
                    </a>
                    <h5>Add Layout</h5>
                    <p>Reposition catergory</p>
                    <div className="inner_field">
                      <div className="tax_label_block">
                        <div className="tax_label">
                          <h6>Select Register</h6>
                          <span>Please assign this layout to a register</span>
                        </div>
                        <div className="tax_field">
                          <select
                            className="form-control"
                            name="payment_option"
                          >
                            {" "}
                            {allLayouts?.map((item, index) => (
                              <option
                                value={item.name}
                                onClick={() => {
                                  handleEditLayout(item);
                                }}
                                // onClick={() => {
                                //   setSelectedProducts(item);
                                //   setDisplayProduct(true);
                                // }}
                              >
                                {item.name}
                              </option>
                            ))}
                          </select>

                          <img src="assets/img/select-arrow.webp" alt="" />
                        </div>
                      </div>
                      <div className="tax_label_block">
                        <div className="tax_label">
                          <h6>Add Products</h6>
                          <span>
                            Add products or categories to your layout, click to
                            customize, label and colour
                          </span>
                        </div>
                      </div>
                      <div className="layout_search">
                        <div className="layout_field">
                          <div className="layout_name">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Layout Name"
                              name="layoutName"
                              value={layoutName}
                              onChange={(e) => setLayoutName(e.target.value)}
                            />
                          </div>
                          <div className="layout_srch">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search Product"
                              value={searchTermProduct}
                              onChange={handleSearchTermChangeProduct}
                              onClick={() => {
                                setSearchTermProduct("");
                              }}
                            />
                            <img src="assets/img/search-icon.webp" alt="" />

                            {showModal2 === true && productState === true ? (
                              <>
                                <div
                                  className={`searchcustomer_list seacrhproductlistcustm ${
                                    showModal2 ? "active" : ""
                                  }`}
                                >
                                  <div className="srchcustmer_title common_title">
                                    <h4>Select Product </h4>
                                  </div>

                                  <div className="customer_list">
                                    <ul>
                                      {filteredData1.length ? (
                                        <>
                                          {filteredData1?.map((item) => (
                                            <li
                                              key={item.id}
                                              onClick={() => {
                                                setShowModal2(false);

                                                handleProductSelect(item);

                                                setProductState(false);
                                                setDisplayProduct(true);
                                                //setProduct(item);
                                              }}
                                            >
                                              <div
                                                key={item?.id}
                                                className="custmer_img"
                                              >
                                                {item?.image ? (
                                                  <img
                                                    src={item?.image}
                                                    alt=""
                                                    style={{
                                                      width: "90px",
                                                      height: "90px",
                                                      borderRadius: "50%",
                                                      marginTop: "100px",
                                                    }}
                                                  />
                                                ) : (
                                                  <img
                                                    src="assets/img/customer-info-img.webp"
                                                    alt=""
                                                  />
                                                )}
                                              </div>
                                              <span>
                                                {item?.title}
                                                <small>{item?.skuCode}</small>
                                              </span>
                                            </li>
                                          ))}
                                        </>
                                      ) : (
                                        <div className="text-center">
                                          <h5>No data available</h5>
                                        </div>
                                      )}
                                    </ul>
                                  </div>
                                </div>{" "}
                              </>
                            ) : null}
                          </div>
                        </div>
                        {displayProduct === true ? (
                          <>
                            <div className="layout_product_list">
                              {selectedProducts.map((product, index) => (
                                <div
                                  className="layoout_prod_col"
                                  onClick={() => {
                                    setShowModal(true);
                                    setselectedProduct(product);
                                  }}
                                >
                                  <a
                                    href="#"
                                    data-toggle="modal"
                                    data-target="#layoutModal"
                                  >
                                    {" "}
                                    <div className="selectcustmproduct">
                                      <div
                                        className="classcolorstyle"
                                        style={{
                                          background: product.color
                                            ? product.color
                                            : "#6b7280",
                                        }}
                                      >
                                        <p
                                          style={{
                                            fontSize: "20px",
                                            fontWeight: "400",
                                            marginTop: "35px",
                                            textAlign: "center",
                                            color: "#000",
                                          }}
                                        >
                                          {product.title || productName}
                                        </p>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              ))}
                            </div>
                          </>
                        ) : null}
                        <div className="clearfix"></div>
                        <div className="layout_prod_btn">
                          <button
                            type="button"
                            className="prod_save_btn"
                            onClick={AddLayOut}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </form>
          <div className="clearfix"></div>
        </div>
      </div>
      {showModal === true ? (
        <>
          <div
            className="modal common_modal layout_modal"
            id="layoutModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="layoutModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="layoutModalLabel">
                    Customize Button
                  </h5>
                  <p>Add name for button and unique color.</p>
                </div>
                <div className="modal-body">
                  <div className="prod_layout_sec">
                    <form method="" action="">
                      <div className="prod_name">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={"Product Name"}
                          value={
                            selectedProduct.name ||
                            selectedProduct.title ||
                            productName
                          }
                          onChange={(e) => {
                            if (selectedProduct) {
                              setselectedProduct({
                                ...selectedProduct,
                                title: e.target.value,
                              });
                            } else {
                              setProductName(e.target.value);
                            }
                          }}
                        />
                      </div>

                      <div
                        className="prod_colors"
                        style={{ display: " flex", marginLeft: " 110px" }}
                      >
                        <Swatch
                          colors={[
                            "#000",
                            "#E20000",
                            "#9664FF",
                            "#28DA2E",
                            "#FFC700",
                            "#FF00E5",
                            "#0500FF",
                            "#D0D0D0",
                            "#00D1FF",
                          ]}
                          color={selectedColor}
                          rectProps={{
                            //children: <Point />,
                            style: {
                              display: " block",
                              marginLeft: "10px",
                              alignItems: "center",
                              justifyContent: "center",
                              width: " 28px",
                              height: "23px",
                              borderRadius: "5px",
                            },
                          }}
                          onChange={(hsvColor) => {
                            handleChangeComplete(hsvaToHex(hsvColor));
                          }}
                        />
                        <div
                          className="disvcolorcustmslect"
                          onClick={handleToggleColorPicker}
                        >
                          <span>+</span>
                        </div>

                        {showColorPicker && (
                          <HexColorPicker
                            color={selectedColor}
                            onChange={(color) => setSelectedColor(color)}
                          />
                        )}
                      </div>
                      <div className="prod_info">
                        <div className="prod_left ">
                          <div
                            className="classcolorstyle"
                            style={{
                              background:
                                selectedProduct.colorCode ||
                                selectedColor ||
                                "#6b7280",
                            }}
                          >
                            <h4>
                              {selectedProduct.title || selectedProduct.name}
                            </h4>
                          </div>
                        </div>
                        <div className="prod_right">
                          <h4>
                            {selectedProduct.title || selectedProduct.name}
                          </h4>
                          <span>{selectedProduct.skuCode}</span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary default_btn"
                    //data-dismiss="modal"
                    onClick={() => {
                      setShowModal(false);
                      setSelectedColor("");

                      document
                        .querySelectorAll(".modal-backdrop")
                        .forEach((el) => el.classList.remove("modal-backdrop"));
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary theme_btn"
                    onClick={() => {
                      setShowModal(false);
                      document
                        .querySelectorAll(".modal-backdrop")
                        .forEach((el) => el.classList.remove("modal-backdrop"));
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
export default CashLayout;
