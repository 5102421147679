import React, { useEffect, useState } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { UpdateTrainingApi } from "../Utility/Api";
import Swal from "sweetalert2";

const TrainingMode = () => {
  const [isTrainingMode, setIsTrainingMode] = useState(false);
  const [showPopup, setShowPopup] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleToggle = () => {
    const newMode = !isTrainingMode;
    setIsTrainingMode(newMode);

    if (newMode) {
      setShowPopup("enter");
    } else {
      setShowPopup("exit");
    }

    TrainingMode(newMode);
  };

  const confirmExit = () => {
    setIsTrainingMode(false);
    setShowPopup(null);
    TrainingMode(false);
  };

  let navigate = useNavigate();

  const TrainingMode = (mode) => {
    let token = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const raw = JSON.stringify({
      trainingMode: mode ? 1 : 0,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    console.log("Training mode payload:", raw);

    fetch(UpdateTrainingApi, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP Error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        setTimeout(() => {
          navigate("/cash-register");
        }, 1500);

        console.log("Training mode updated successfully:", result);
      })
      .catch((error) => {
        console.error("Error updating training mode:", error);
      });
  };
  return (
    <>
      <div className="setting_content">
        <h4>Training Mode</h4>
        <div className="inner_block plan_billing_setting">
          <form className="setting_form" action="" method="post">
            <div className="block_column wd100">
              <h5>Enable Training Mode </h5>
              <p>
                Enable Training Mode if you’re new to Toolbox Retail and want to
                learn the ropes. You’ll be selling like a pro in no time.
              </p>
              <div className="inner_field">
                <div className="tax_label_block">
                  <div className="tax_label"></div>
                  <div className="tax_field">
                    <button
                      type="button"
                      className="vd-btn"
                      onClick={handleToggle}
                    >
                      {isTrainingMode
                        ? "Disable Training Mode"
                        : "Enable Training Mode"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className="clearfix"></div>
        </div>
      </div>
      {showPopup === "enter" && (
        <div className="popup-overlay">
          <div className="popup">
            <h2>You're now in training mode</h2>
            <p>
              Sales and payments you make in training mode will not be recorded.
            </p>
            <button
              className="confirm-btn"
              onClick={() => {
                setIsTrainingMode(true);
                setShowPopup(null);
              }}
            >
              Got it
            </button>
          </div>
        </div>
      )}

      {showPopup === "exit" && (
        <div className="popup-overlay">
          <div className="popup">
            <h2>Exit Training Mode?</h2>
            <p>
              Once you leave training mode, all sales and payments will be
              recorded.
            </p>
            <div className="popup-buttons">
              <button
                className="cancel-btn"
                onClick={() => {
                  setShowPopup("enter");
                  setIsTrainingMode(true);
                }}
              >
                Continue Training
              </button>
              <button className="confirm-btn" onClick={confirmExit}>
                Exit Training
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default TrainingMode;
