import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UpdateTrainingApi } from "../Utility/Api";

const Training = (props) => {
  const [isTrainingMode, setIsTrainingMode] = useState(false);
  const [showPopup, setShowPopup] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    console.log("training pros", props);
    if (props?.data?.trainingMode !== undefined) {
      setIsTrainingMode(props.data.trainingMode === 1);
      props?.tooltip(true);
    }
  }, [props?.data?.trainingMode]);

  const handleToggle = () => {
    const newMode = !isTrainingMode;
    setIsTrainingMode(newMode);

    if (newMode) {
      setShowPopup("enter");
      props?.tooltip(true);
    } else {
      setShowPopup("exit");
    }

    TrainingMode(newMode);
  };

  const confirmExit = () => {
    setIsTrainingMode(false);
    setShowPopup(null);
    TrainingMode(false);
  };

  const TrainingMode = (mode) => {
    let token = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const raw = JSON.stringify({
      trainingMode: mode ? 1 : 0,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    console.log("Training mode payload:", raw);

    fetch(UpdateTrainingApi, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP Error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        props?.onLoad();
        window?.location?.reload();
        console.log("Training mode updated successfully:", result);
      })
      .catch((error) => {
        console.error("Error updating training mode:", error);
      });
  };

  return (
    <>
      <div className="training_mode">
        <div className="mode_logo">
          <img src="assets/img/logo-small.png" alt="" />
        </div>
        <div className="mode_title">
          <img src="assets/img/training-mode-icon.svg" alt="" />
          <h3>Training Mode</h3>
        </div>
        <div className="mode_toggle">
          <div className="switch_field">
            <label className="custom-switch">
              <input
                type="checkbox"
                className="custom-switch-input"
                checked={isTrainingMode}
                onChange={handleToggle}
              />
              <span className="custom-switch-indicator" />
            </label>
            <span className="switch_title">Enable Tutorial</span>
          </div>
        </div>
      </div>
      {showPopup === "enter" && (
        <div className="popup-overlay">
          <div className="popup">
            <h2>You're now in training mode</h2>
            <p>
              Sales and payments you make in training mode will not be recorded.
            </p>
            <button
              className="confirm-btn"
              onClick={() => {
                setIsTrainingMode(true);
                setShowPopup(null);
              }}
            >
              Got it
            </button>
          </div>
        </div>
      )}

      {showPopup === "exit" && (
        <div className="popup-overlay">
          <div className="popup">
            <h2>Exit Training Mode?</h2>
            <p>
              Once you leave training mode, all sales and payments will be
              recorded.
            </p>
            <div className="popup-buttons">
              <button
                className="cancel-btn"
                onClick={() => {
                  setShowPopup("enter");
                  setIsTrainingMode(true);
                }}
              >
                Continue Training
              </button>
              <button className="confirm-btn" onClick={confirmExit}>
                Exit Training
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Training;
