import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "../Footer";
import { useMemo } from "react";

import {
  AddInvoiceApi,
  AddTicketInvoiceApi,
  GetAllDiscountApi,
  GetAllTaxApi,
  GetAllTicketApi,
  GetInvoices,
  GetProductDeviceApi,
  GetRecieptApi,
  GetSubUserProfileApi,
  GettAllPaymentApi,
  baseurl,
  customerAddApi,
  getProfileApi,
  uploadImageApi,
} from "../Utility/Api";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { Link, useNavigate } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import Swal from "sweetalert2";
import ProductModal from "../MainDashboard/ProductModal";
import DeviceModal from "../MainDashboard/DeviceModal";
import moment from "moment";
import Ticket from "../MainDashboard/TicketModal";
import TicketDeviceModal from "../MainDashboard/TicketDeviceModal";
import TicketProductModal from "./TicketProductModal";
import Draggable from "react-draggable";

const TicketCash = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [images, setImages] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [city, SetCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [state, setState] = useState("");
  const [error, setError] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [validstate, setValidState] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermProduct, setSearchTermProduct] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [matched, setMatched] = useState([]);
  const [imageMsg, setImageMsg] = useState("");
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [showModal5, setShowModal5] = useState(false);
  const [showModal6, setShowModal6] = useState(false);
  const [showModal8, setShowModal8] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [RetrieveSaleProduct, setRetrieveSaleProduct] = useState([]);
  const [isRetrieveListActive, setRetrieveListActive] = useState(false);
  const [InVoiceData, setInVoiceData] = useState([]);
  const [editedProductIndex, setEditedProductIndex] = useState(null);
  const [note, setNote] = useState(selectedProduct?.map(() => 0));
  const [customerId, setCustomerId] = useState("");
  const [descriptionNote, setDescriptionNote] = useState("");
  const [changeDue, setChangeDue] = useState("");
  const [parkNote, setParkNote] = useState("");
  const [showModal7, setShowModal7] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectRetrieveProduct, setSelectedRetrieveProduct] = useState([]);
  const [retrieveState, setRetrieveState] = useState(false);
  const [inputValue, setInputValue] = useState(0);
  const [productState, setProductState] = useState(false);
  const [paymentState, setPaymnetState] = useState(false);
  const [customerState, setCustomerState] = useState(false);
  const [customerid, setCustomerid] = useState("");
  const [invoiceId, setInvoiceID] = useState("");
  const [countrycode, setCountryCode] = useState({});
  const [productByCategory, setProductByCategory] = useState([]);
  const [discounts, setDiscounts] = useState(
    Array(selectedProduct?.length).fill(10) || 0
  );
  const [unitCosts, setUnitCosts] = useState(selectedProduct?.map(() => 0));
  const [receivedQuantities, setReceivedQuantities] = useState(
    selectedProduct?.map(() => 0)
  );
  const [purchaseQuantities, setPurchaseQuantities] = useState(
    selectedProduct?.map(() => 0)
  );
  const [selectedPaymentType, setSelectedPaymentType] = useState("Cash");
  const [paymentMode, setPaymentMode] = useState([]);
  const [layoutList, setLayoutList] = useState([]);
  const [layoutValue, setLayoutValue] = useState();
  const [showModal10, setShowModal10] = useState(false);
  const [categortProduct, setCategoryProduct] = useState({});
  const [selectedCategory2, setSelectedCategory2] = useState([]);

  const [selectedPaymentTypeId, setSelectedPaymentTypeId] = useState("");
  const [allPaymentOptions, setAllPaymentOptions] = useState([]);
  const [isPaymentOptionSelected, setIsPaymentOptionSelected] = useState(false);
  const [showlist, setShowlist] = useState(false);
  const [alldiscount, setAllDiscount] = useState([]);
  const [alltax, setAllTax] = useState([]);
  const [displayList, setDisplayList] = useState([]);
  const [statusState, setStatusState] = useState(false);
  const [unitCost1, setUnitCost1] = useState([]);
  const [receivedQuantities1, setReceivedQuantities1] = useState([]);
  const [total1, setTotal1] = useState([]);
  const [subTotals, setSubTotals] = useState("");
  const [GrandTotals, setGrandTotals] = useState("");
  const [dis, setDis] = useState("");
  const [taxs, setTaxs] = useState("");
  const [paymentType, setPaymentType] = useState([]);
  const [taxAmounts, setTaxAmounts] = useState();
  const [discountAmount, setDiacountAmount] = useState("");
  const [printType, setPrintType] = useState();
  const [reciept, setReciept] = useState(false);
  const [title, setTitle] = useState("");
  const [logo, setLogo] = useState("");
  const [isLogo, setIsLogo] = useState(false);
  const [logoSize, setLogoSize] = useState(0);
  const [logoPosition, setLogoPosition] = useState(0);
  const [header, setHeader] = useState("");
  const [displySales, setDisplaySales] = useState(false);
  const [footer, setFooter] = useState("");
  const [note1, setNote1] = useState(false);
  const [displayBarcode, setDisplayBarcode] = useState(false);
  const [displayNote, setDisplayNote] = useState(false);
  const [states, setStates] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    prodname: 0,
    prodname_sku: 0,
    sku: 0,
  });
  const [customerDetails1, setCustomerDetails1] = useState({
    fullname: 0,
    email: 0,
    phone: 0,
    address: 0,
  });
  const [recieptId, setRecieptId] = useState("");
  const [InvoiceData1, setInVoiceData1] = useState({});
  const [data1, setData1] = useState([]);
  const [dataUser, setDataUser] = useState([]);
  const [permission, setPermission] = useState([]);
  const [payment, setPayment] = useState([]);
  const [Items, setItems] = useState([]);
  const [ItemData, setItemData] = useState([]);
  const [showModal9, setShowModal9] = useState(false);
  const [showModal11, setShowModal11] = useState(false);
  const [selectedState, setSelectedState] = useState(false);
  const [invoiceId1, setInvoiceID1] = useState("");
  const [gstNumber, setSGTNumber] = useState("");
  const [totalCost, setTotalCost] = useState("");
  const [leftMargin, setLeftMargin] = useState("10mm");
  const [rightMargin, setRightMargin] = useState("10mm");
  const [totalAveraSale, setTotalAveraSale] = useState("");
  const [loading2, setLoading2] = useState(false);
  const [totalSalesQty, setTotalSalesQty] = useState(0);
  const [ImageOnOff, setImageOnOff] = useState();
  const [gstShow, setGstShow] = useState();
  const [originalUnitCosts, setOriginalUnitCosts] = useState([]);
  const [originalReceivedQuantities, setOriginalReceivedQuantities] = useState(
    []
  );
  const [originalNotes, setOriginalNotes] = useState([]);
  const [originalDiscounts, setOriginalDiscounts] = useState([]);
  const [addInvoiceData, setInvoiceAdd] = useState([]);
  const [invoiceData, setInvoiceData] = useState();
  const [invoiceDataId, setInvoiceDataId] = useState();

  const handleListProduct = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllCashRegisterLayout?type=1`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //setLayoutList(result?.data?.layout);
        if (result?.data?.layout?.length > 0) {
          setLayoutList(result?.data?.layout);
          setLayoutValue(result?.data?.layoutButton);
          setImageOnOff(result?.data?.imageButton);
        }
      })
      .catch((error) => console.error(error));
  };
  useEffect(() => {
    // Prepopulate discounts with values from props
    if (props?.data?.invoice?.items) {
      setDiscounts(
        props?.data?.invoice?.items?.map((item) => item?.discount || 0)
      );
    }
  }, [props?.data?.invoice?.items]);

  useEffect(() => {
    handleListProduct();
    // GetAddTicketData();
    GetPaymentOptions();
    console.log("ticketDetails", props?.data);
    setDisplayList(props?.data);
    setUnitCost1(props?.data?.invoice?.items?.map((item) => item?.unitCost));
    setReceivedQuantities1(
      props?.data?.invoice?.items?.map((item) => item?.quantity)
    );
    setSelectedProduct(props?.data?.invoice?.itemIds);
    setUnitCosts(props?.data?.invoice?.items?.map((item) => item?.unitCost));

    setReceivedQuantities(
      props?.data?.invoice?.items?.map((item) => item?.quantity)
    );
    // setDiscounts(
    //   props?.data?.invoice?.items?.map((item) => item?.discount || 0)
    // );
    setDiscount(props?.data?.invoice?.discount || 0);
    // if (props?.data?.invoice?.payment.length > 0) {
    //   setPaymnetState(true);
    //   setPaymentMode(props?.data?.invoice?.payment);
    //   setInputValue(0);
    // } else {
    //   setPaymnetState(false);
    // }
    setInvoiceDataId(props?.data?.invoice?.id);
    setTotal1(props?.data?.invoice?.items?.map((item) => item?.total));
    setSubTotals(props?.data?.invoice?.subTotal);
    setGrandTotals(props?.data?.invoice?.grandTotal);
    setTaxs(props?.data?.invoice?.tax);
    setDis(props?.data?.invoice?.discount);
    setPaymentType(props?.data?.invoice?.payment);

    const subtotal = props?.data?.invoice?.subTotal;
    const discountPercentage = props?.data?.invoice?.discount;
    const taxRate = props?.data?.invoice?.tax;

    const discountedSubtotal = subtotal * (1 - discountPercentage / 100);
    const taxAmount = discountedSubtotal * (taxRate / 100);
    setTaxAmounts(taxAmount.toFixed(2) || 0);
    // setDiacountAmount(discountedSubtotal);
    setDiacountAmount(props?.data?.invoice?.discountAmount);
    const overallTotal1 = discountedSubtotal + taxAmount;

    // setSelectedProduct((prevSelectedProducts) => [
    //   ...prevSelectedProducts,
    //   props?.data?.invoice,
    // ]);
  }, []);

  const GetPaymentOptions = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GettAllPaymentApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const allOptions = result?.data.reverse();

        // Find default payment options
        const defaultOptions = allOptions.filter(
          (option) => option.defaultPaymentOption === 1
        );

        // Set the default options as selected
        setSelectedCategory2(defaultOptions);
        setSelectedOptions(
          defaultOptions.length > 0 ? defaultOptions[0].name : ""
        );

        // Combine default options with the full payment list to show them in the dropdown
        setAllPaymentOptions(allOptions);

        setSelectedPaymentTypeId(defaultOptions.map((option) => option.id));
        setSelectedPaymentType(defaultOptions.map((option) => option.name));
      })

      .catch((error) => {
        console.error(error);
      });
  };

  // const handleEdit = (index) => {
  //   setEditedProductIndex(index);
  //   setUnitCosts((prevUnitCosts) => [
  //     ...prevUnitCosts,
  //     index?.sellingPrice || 0,
  //   ]);
  //   setReceivedQuantities((prevReceivedQuantities) => [
  //     ...prevReceivedQuantities,
  //     index?.receivedQuantities || 0,
  //   ]);
  //   setPurchaseQuantities((prevPurchaseQuantities) => {
  //     const newPurchaseQuantities = [...prevPurchaseQuantities];
  //     newPurchaseQuantities[index] =
  //       selectedProduct[index]?.purchaseQuantity || 0;
  //     return newPurchaseQuantities;
  //   });

  //   setNote((prevNote) => {
  //     const newNote = [...prevNote];
  //     newNote[index] = selectedProduct[index]?.note || 0;
  //     return newNote;
  //   });
  //   setShowModal(true);
  // };

  const resetFormValues = () => {
    setUnitCosts([...originalUnitCosts]); // Restore original unit costs
    setReceivedQuantities([...originalReceivedQuantities]); // Restore original received quantities
    setNote([...originalNotes]); // Restore original notes
    setDiscounts([...originalDiscounts]); // Restore original discounts
  };

  const handleEdit = (index) => {
    setOriginalUnitCosts([...unitCosts]);
    setOriginalReceivedQuantities([...receivedQuantities]);
    setOriginalNotes([...note]);
    setOriginalDiscounts([...discounts]);
    // Store the index of the product being edited
    setEditedProductIndex(index);

    // Update unit cost for the selected product
    setUnitCosts((prevUnitCosts) => {
      const updatedCosts = [...prevUnitCosts];
      updatedCosts[index] =
        prevUnitCosts[index] || selectedProduct[index]?.sellingPrice || 0; // Use value from state first
      return updatedCosts;
    });

    // Update received quantities for the selected product
    setReceivedQuantities((prevReceivedQuantities) => {
      const updatedQuantities = [...prevReceivedQuantities];
      updatedQuantities[index] =
        prevReceivedQuantities[index] ||
        selectedProduct[index]?.receivedQuantities ||
        1; // Use value from state first
      return updatedQuantities;
    });

    // Update purchase quantities for the selected product
    setPurchaseQuantities((prevPurchaseQuantities) => {
      const updatedPurchaseQuantities = [...prevPurchaseQuantities];
      updatedPurchaseQuantities[index] =
        prevPurchaseQuantities[index] ||
        selectedProduct[index]?.purchaseQuantity ||
        0;
      return updatedPurchaseQuantities;
    });

    // Update note for the selected product
    setNote((prevNote) => {
      const updatedNote = [...prevNote];
      updatedNote[index] =
        prevNote[index] || selectedProduct[index]?.note || "";
      return updatedNote;
    });

    // Open the modal to allow editing
    setShowModal(true);
  };

  // const handleUpdate = (selectedArray) => {
  //   if (editedProductIndex !== null) {
  //     selectedArray((prevProducts) => {
  //       const updatedProducts = [...prevProducts];
  //       const updatedProduct = {
  //         ...updatedProducts[editedProductIndex],
  //         unitCost: unitCosts[editedProductIndex],
  //         receivedQuantity: receivedQuantities[editedProductIndex],
  //         purchaseQuantity: purchaseQuantities[editedProductIndex],
  //         note: note[editedProductIndex],
  //         discount: discounts[editedProductIndex], // Add discount value here
  //       };

  //       updatedProducts[editedProductIndex] = updatedProduct;

  //       return updatedProducts;
  //     });

  //     setEditedProductIndex(null);
  //     setShowModal(false);

  //   }
  // };
  const handleUpdate = (selectedArray) => {
    if (editedProductIndex !== null) {
      // selectedArray((prevProducts) => {
      // const updatedProducts = [...prevProducts];
      // const originalProduct = updatedProducts[editedProductIndex];
      // const updatedProduct = {
      //   ...originalProduct,
      //   unitCost: unitCosts[editedProductIndex],
      //   receivedQuantity: receivedQuantities[editedProductIndex],
      //   purchaseQuantity: purchaseQuantities[editedProductIndex],
      //   note: note[editedProductIndex],
      //   discount: discounts[editedProductIndex],
      // };
      selectedArray((prevProducts) => {
        const updatedProducts = [...prevProducts];
        updatedProducts[editedProductIndex] = {
          ...updatedProducts[editedProductIndex],
          unitCost: unitCosts[editedProductIndex],
          receivedQuantity: receivedQuantities[editedProductIndex],
          purchaseQuantity: purchaseQuantities[editedProductIndex],
          note: note[editedProductIndex],
        };

        console.log("upated product", updatedProducts);

        //    const hasChanges = Object.keys(updatedProducts).some(
        //   (key) => updatedProducts[key] !== originalProduct[key]
        // );
        const subTotal = updatedProducts
          .reduce((total, product, index) => {
            const productTotal =
              (unitCosts[index] || 0) * (receivedQuantities[index] || 0);
            const discountedProductTotal = discounts[index]
              ? productTotal * (1 - discounts[index] / 100)
              : productTotal;
            return total + discountedProductTotal;
          }, 0)
          .toFixed(2);

        const taxRate = tax; // Adjust tax rate as needed
        const taxAmount = (subTotal * (taxRate / 100)).toFixed(2);

        const grandTotal = (
          parseFloat(subTotal) + parseFloat(taxAmount)
        ).toFixed(2);

        // Correct calculation of `totalCosts` to sum `unitCost` only
        const totalCosts = updatedProducts
          .reduce((total, product, index) => {
            return total + (parseFloat(unitCosts[index]) || 0); // Ensure unitCost is summed correctly
          }, 0)
          .toFixed(2);

        // Calculate totalSalesQuantity
        const totalSalesQuantity = updatedProducts.reduce(
          (total, product, index) =>
            total + (parseFloat(receivedQuantities[index]) || 0), // Ensure we convert to float
          0
        );

        const invoiceData = {
          activeInactive: 1,
          itemIds: selectedProduct?.map((product) => product.id),
          items: selectedProduct?.map((product, index) => ({
            itemId: product?.id,
            quantity: product?.quantity ?? receivedQuantities?.[index],
            imeiNumber: product?.imeiNumber?.filter(Boolean)?.join(","),
            unitCost: unitCosts?.[index] ?? product?.sellingPrice,
            discount: product?.discount ?? discounts?.[index] ?? 0,
            note: product?.note ?? note?.[index],
            total: (
              (unitCosts?.[index] ?? product?.sellingPrice) *
              (product?.quantity || receivedQuantities?.[index]) *
              (1 - (product?.discount || discounts?.[index] || 0) / 100)
            ).toFixed(2),

            averageCost: product?.averageCost || 0,
            imeiNumberArr: product?.imeiNumber?.filter(Boolean)?.length
              ? product?.imeiNumber?.filter(Boolean)
              : [],
          })),
          customerId: props?.customer?.id,
          subTotal: subTotal,
          discount: discount,
          tax: taxRate,
          grandTotal: grandTotal,
          paymentType: [],
          isCompleted: 1,
          note: "",
          due: 0,
          payment: [],
          parkNote: "",
          isAdmin: adminState,
          totalCost: totalCosts,
          totalSalesCost: subTotal,
          totalSalesQuantity: totalSalesQuantity,
          taxAmount: taxAmount,
          averageSalesCost: Number(totalAverageSalesCost.toFixed(2)),
          discountAmount: (subTotal - discountedSubtotal).toFixed(2),
          invoiceStatus: 0,
          ticketId: props?.ticketid,
        };
        // Call the API with the invoiceData
        UpdateTicketInvoice(invoiceData);

        return updatedProducts;
      });
      setEditedProductIndex(null);
      setShowModal(false);
    }
  };
  // const handleUpdate = (selectedArray) => {
  //   if (editedProductIndex !== null) {
  //     selectedArray((prevProducts) => {
  //       // Create a new array where only the product at `editedProductIndex` is updated
  //       const updatedProducts = prevProducts.map((product, index) => {
  //         if (index === editedProductIndex) {
  //           // Update only the product at the `editedProductIndex`
  //           return {
  //             ...product, // Keep all other product fields unchanged
  //             unitCost: unitCosts[editedProductIndex],
  //             receivedQuantity: receivedQuantities[editedProductIndex],
  //             purchaseQuantity: purchaseQuantities[editedProductIndex],
  //             note: note[editedProductIndex],
  //             discount: discounts[editedProductIndex], // Update discount if necessary
  //           };
  //         }
  //         // Return the original product for other indexes
  //         return product;
  //       });

  //       console.log("updatedProduct", updatedProducts[editedProductIndex]);

  //       // Calculate the updated totals for the invoice
  //       const subTotal = updatedProducts
  //         .reduce((total, product, index) => {
  //           const productTotal =
  //             (unitCosts[index] || 0) * (receivedQuantities[index] || 0);
  //           const discountedProductTotal = discounts[index]
  //             ? productTotal * (1 - discounts[index] / 100)
  //             : productTotal;
  //           return total + discountedProductTotal;
  //         }, 0)
  //         .toFixed(2);

  //       const taxRate = tax; // Adjust tax rate as needed
  //       const taxAmount = (subTotal * (taxRate / 100)).toFixed(2);

  //       const grandTotal = (
  //         parseFloat(subTotal) + parseFloat(taxAmount)
  //       ).toFixed(2);

  //       // Correct calculation of `totalCosts` to sum `unitCost` only
  //       const totalCosts = updatedProducts
  //         .reduce((total, product, index) => {
  //           return total + (parseFloat(unitCosts[index]) || 0); // Ensure unitCost is summed correctly
  //         }, 0)
  //         .toFixed(2);

  //       // Calculate totalSalesQuantity
  //       const totalSalesQuantity = updatedProducts.reduce(
  //         (total, product, index) =>
  //           total + (parseFloat(receivedQuantities[index]) || 0), // Ensure we convert to float
  //         0
  //       );

  //       // Prepare the updated invoice data for the API call
  //       const invoicedata = {
  //         activeInactive: 1,
  //         itemIds: updatedProducts.map((item) => item.id),
  //         items: updatedProducts.map((item, index) => ({
  //           itemId: item.id,
  //           quantity: receivedQuantities[index] || 0, // Updated quantity
  //           unitCost: unitCosts[index] || 0,
  //           discount: discounts[index] || 0, // Ensure to include discounts
  //           note: note[index] || "", // Include notes if applicable
  //           total: calculateDiscountedTotal(
  //             unitCosts[index],
  //             receivedQuantities[index] || 0,
  //             discounts[index] || 0
  //           ),
  //           averageCost: item.averageCost || 0,
  //           imeiNumberArr: item.imeiNumberArr || [], // IMEI numbers as needed
  //         })),
  //         customerId: props?.customer?.id,
  //         subTotal: subTotal, // Use the calculated subTotal (with discount applied)
  //         discount: discount, // Discount (adjust if necessary)
  //         tax: taxRate, // Tax rate (adjust if necessary)
  //         grandTotal: grandTotal, // Use the calculated grandTotal
  //         paymentType: selectedPaymentTypeId,
  //         isCompleted: 1,
  //         note: descriptionNote, // Any notes if applicable
  //         due: changeDue ? changeDue : 0,
  //         payment: paymentMode, // Payment mode state
  //         parkNote: parkNote || "", // Park notes if applicable
  //         isAdmin: adminState,
  //         totalCost: totalCosts, // Corrected totalCost sum
  //         totalSalesCost: subTotal, // Total sales cost
  //         totalSalesQuantity: totalSalesQuantity,
  //         taxAmount: taxAmount, // Updated tax amount
  //         averageSalesCost: Number(totalAverageSalesCost.toFixed(2)), // Average sales cost
  //         discountAmount: Number(discountAmount1), // Adjust discount amount as needed
  //         invoiceStatus: 0,
  //         ticketId: props?.ticketid, // Use ticket ID
  //       };

  //       // Call the Update API with the new invoice data
  //       // UpdateTicketInvoice(invoicedata);

  //       return updatedProducts; // Return the updated product list with only the specific item updated
  //     });

  //     setEditedProductIndex(null); // Reset edited product index
  //     setShowModal(false); // Close the modal
  //   }
  // };

  // const handleUpdate = (selectedArray) => {
  //   if (editedProductIndex !== null) {
  //     selectedArray((prevProducts) => {
  //       const updatedProducts = [...prevProducts];

  //       // Create the updated product object
  //       const updatedProduct = {
  //         ...updatedProducts[editedProductIndex],
  //         unitCost: unitCosts[editedProductIndex],
  //         receivedQuantity: receivedQuantities[editedProductIndex],
  //         purchaseQuantity: purchaseQuantities[editedProductIndex],
  //         note: note[editedProductIndex],
  //         discount: discounts[editedProductIndex], // Include the discount
  //       };

  //       // Update the selected product in the array
  //       updatedProducts[editedProductIndex] = updatedProduct;
  //       console.log("updatedproduct",updatedProduct)

  //       // Calculate the updated totals for the invoice
  //       const subTotal = updatedProducts
  //         .reduce(
  //           (total, product) =>
  //             total +
  //             (product?.unitCost || 0) * (product?.receivedQuantity || 0),
  //           0
  //         )
  //         .toFixed(2);

  //       const discounta = discount; // You can adjust this based on your logic
  //       const discountedSubtotal = discounta
  //         ? (subTotal * (1 - discounta / 100)).toFixed(2)
  //         : subTotal;

  //       const taxRate = tax; // Adjust tax rate as needed
  //       const taxAmount = (discountedSubtotal * (taxRate / 100)).toFixed(2);

  //       const grandTotal = (
  //         parseFloat(discountedSubtotal) + parseFloat(taxAmount)
  //       ).toFixed(2);

  //       const totalCosts = updatedProducts.reduce((total, product) => {
  //         return total + product.unitCost;
  //       }, 0); // Total costs after updates
  //       // Calculate totalSalesQuantity
  //       const totalSalesQuantity = updatedProducts.reduce(
  //         (total, product) =>
  //           total + (parseFloat(product.receivedQuantity) || 0), // Ensure we convert to float
  //         0
  //       );

  //       // Prepare the updated invoice data for the API call
  //       const invoicedata = {
  //         activeInactive: 1,
  //         itemIds: updatedProducts.map((item) => item.id),
  //         items: updatedProducts.map((item) => ({
  //           itemId: item.id,
  //           quantity: item.receivedQuantity || 0, // Updated quantity
  //           unitCost: item.unitCost || 0,
  //           discount: item.discount || 0, // Ensure to include discounts
  //           note: item.note || "", // Include notes if applicable
  //           total: calculateDiscountedTotal(
  //             item.unitCost,
  //             item.receivedQuantity || 0,
  //             item.discount || 0
  //           ),
  //           averageCost: item.averageCost || 0,
  //           imeiNumberArr: item.imeiNumberArr || [], // IMEI numbers as needed
  //         })),
  //         customerId: props?.customer?.id,
  //         subTotal: subTotal, // Use the calculated subTotal
  //         discount: discount, // Discount (adjust if necessary)
  //         tax: taxRate, // Tax rate (adjust if necessary)
  //         grandTotal: grandTotal, // Use the calculated grandTotal
  //         paymentType: selectedPaymentTypeId,
  //         isCompleted: 1,
  //         note: descriptionNote, // Any notes if applicable
  //         due: changeDue ? changeDue : 0,
  //         payment: paymentMode, // Payment mode state
  //         parkNote: parkNote || "", // Park notes if applicable
  //         isAdmin: adminState,
  //         totalCost: Number(totalCosts).toFixed(2), // Use the calculated totalCosts
  //         totalSalesCost: subTotal, // Total sales cost
  //         totalSalesQuantity: totalSalesQuantity,
  //         taxAmount: taxAmount, // Update tax amount
  //         averageSalesCost: Number(totalAverageSalesCost.toFixed(2)), // Average sales cost
  //         discountAmount: Number(discountAmount1), // Adjust discount amount as needed
  //         invoiceStatus: 0,
  //         ticketId: props?.ticketid, // Use ticket ID
  //       };

  //       // Call the Update API with the new invoice data
  //       UpdateTicketInvoice(invoicedata);

  //       return updatedProducts; // Return the updated product list
  //     });

  //     setEditedProductIndex(null); // Reset edited product index
  //     setShowModal(false); // Close the modal
  //   }
  // };

  // const handleUpdate = (selectedArray) => {
  //   if (editedProductIndex !== null) {
  //     selectedArray((prevProducts) => {
  //       const updatedProducts = [...prevProducts];
  //       updatedProducts[editedProductIndex] = {
  //         ...updatedProducts[editedProductIndex],
  //         unitCost: unitCosts[editedProductIndex],
  //         receivedQuantity: receivedQuantities[editedProductIndex],
  //         purchaseQuantity: purchaseQuantities[editedProductIndex],
  //         note: note[editedProductIndex],
  //       };

  //       return updatedProducts;
  //     });
  //     setEditedProductIndex(null);
  //     setShowModal(false);
  //   }
  // };

  const calculateDiscountedTotal = (unitCost, receivedQty, discount) => {
    const total = unitCost * receivedQty;
    if (!discount) {
      return total.toFixed(2);
    }
    const discountedTotal = total * (1 - discount / 100);

    return discountedTotal >= 0 ? `${discountedTotal.toFixed(2)}` : "$0";
  };

  const calculateOverallTotal = () => {
    let overallTotal = 0;

    selectedProduct?.forEach((_, index) => {
      const discountedTotal = calculateDiscountedTotal(
        unitCosts[index],
        receivedQuantities[index],
        discounts[index]
      );

      overallTotal += parseFloat(discountedTotal);
    });
    selectRetrieveProduct?.forEach((product, index) => {
      product.itemIds.forEach((item, itemIndex) => {
        const arrayIndex = selectedProduct.length + index + itemIndex;
        const discountedTotal = calculateDiscountedTotal(
          unitCosts[arrayIndex],
          receivedQuantities[arrayIndex],
          discounts[arrayIndex]
        );
        overallTotal += parseFloat(discountedTotal);
      });
    });

    return overallTotal >= 0 ? overallTotal.toFixed(2) : "0.00";
  };
  const overallTotal = calculateOverallTotal();

  // const handleIncrement = (index) => {
  //   setReceivedQuantities((prevQuantities) => {
  //     const newQuantities = [...prevQuantities];
  //     newQuantities[index] = parseInt(newQuantities[index] || 0) + 1;

  //     return newQuantities;
  //   });

  //   setSelectedProduct((prevProducts) => {
  //     // Update selected product quantity
  //     const updatedProducts = [...prevProducts];
  //     updatedProducts[index] = {
  //       ...updatedProducts[index],
  //       quantity: (updatedProducts[index].quantity || 1) + 1,
  //     };

  //     return updatedProducts;
  //   });

  //   setUnitCosts((prevUnitCosts) => {
  //     // Update unit costs if necessary (assuming unit costs do not change with increment)
  //     const updatedCosts = [...prevUnitCosts];

  //     return updatedCosts;
  //   });

  //   setEditedProductIndex(index);
  // };

  // const handleIncrement = (index) => {
  //   // Update the received quantities state
  //   setReceivedQuantities((prevQuantities) => {
  //     const newQuantities = [...prevQuantities];
  //     newQuantities[index] = parseInt(newQuantities[index] || 0) + 1;
  //     return newQuantities;
  //   });

  //   // Update the selected product state
  //   setSelectedProduct((prevProducts) => {
  //     const updatedProducts = [...prevProducts];
  //     updatedProducts[index] = {
  //       ...updatedProducts[index],
  //       quantity: (updatedProducts[index].quantity || 1) + 1,
  //     };
  //     return updatedProducts;
  //   });

  //   // Update unit costs if necessary (assuming unit costs do not change with increment)
  //   setUnitCosts((prevUnitCosts) => {
  //     const updatedCosts = [...prevUnitCosts];
  //     return updatedCosts; // Assuming unit costs remain unchanged
  //   });

  //   // Calculate updated totals for the invoice
  //   const subTotal = selectedProduct
  //     .reduce(
  //       (total, product, idx) =>
  //         total + (product?.sellingPrice || 0) * (receivedQuantities[idx] + 1),
  //       0
  //     )
  //     .toFixed(2); // Increase quantity for the updated product

  //   const discounta = discount; // Update with your discount logic if need
  //   const discountedSubtotal = discounta
  //     ? (subTotal * (1 - discounta / 100)).toFixed(2)
  //     : subTotal;

  //   const taxRate = tax; // Adjust tax rate as needed
  //   const taxAmount = (discountedSubtotal * (taxRate / 100)).toFixed(2);

  //   const grandTotal = (
  //     parseFloat(discountedSubtotal) + parseFloat(taxAmount)
  //   ).toFixed(2);
  //   const totalCosts = selectedProduct
  //     .reduce((total, product, idx) => {
  //       return total + (product.sellingPrice || unitCosts[idx]);
  //     }, 0)
  //     .toFixed(2);
  //   const totalSalesQuantity = selectedProduct.reduce((total, product, idx) => {
  //     return total + (receivedQuantities[idx] + 1); // Include incremented quantity
  //   }, 0);
  //   // Prepare updated invoice data for the API call
  //   const invoicedata = {
  //     activeInactive: 1,
  //     itemIds: selectedProduct.map((item) => item.id),
  //     items: selectedProduct.map((item, idx) => ({
  //       itemId: item.id,
  //       quantity: receivedQuantities[idx] + 1, // Incremented quantity
  //       unitCost: unitCosts[idx] || 0,
  //       discount: discounts[idx] || 0, // Ensure to include discounts
  //       note: note[idx] || "", // Include notes if applicable
  //       total: calculateDiscountedTotal(
  //         unitCosts[idx],
  //         receivedQuantities[idx] + 1,
  //         discounts[idx] || 0
  //       ),
  //       averageCost: item.averageCost || 0,
  //       imeiNumberArr: item.imeiNumberArr || [], // IMEI numbers as needed
  //     })),
  //     customerId: props?.customer?.id,
  //     subTotal: subTotal,
  //     discount: discount, // Adjust if necessary
  //     tax: taxRate,
  //     grandTotal: grandTotal,
  //     paymentType: selectedPaymentTypeId,
  //     isCompleted: 1,
  //     note: descriptionNote, // Any notes if applicable
  //     due: changeDue ? changeDue : 0,
  //     payment: paymentMode, // Payment mode state
  //     parkNote: parkNote || "", // Park notes if applicable
  //     isAdmin: adminState,
  //     totalCost: totalCosts, // Update as needed
  //     totalSalesCost: subTotal,
  //     totalSalesQuantity: totalSalesQuantity,
  //     taxAmount: taxAmount,
  //     averageSalesCost: Number(totalAverageSalesCost.toFixed(2)), // Average sales cost
  //     discountAmount: Number(discountAmount1), // Adjust discount amount as needed
  //     invoiceStatus: 0,
  //     ticketId: props?.ticketid,
  //   };

  //   // Call the Update API with the new invoice data
  //   UpdateTicketInvoice(invoicedata);

  //   // Set the index of the edited product (if needed)
  //   setEditedProductIndex(index);
  // };

  const handleIncrement = (index) => {
    // Update the received quantities state only for the specific product
    setReceivedQuantities((prevQuantities) => {
      const newQuantities = [...prevQuantities];
      newQuantities[index] = parseInt(newQuantities[index] || 0) + 1;
      return newQuantities;
    });

    // Update the selected product state only for the specific product
    setSelectedProduct((prevProducts) => {
      const updatedProducts = [...prevProducts];
      updatedProducts[index] = {
        ...updatedProducts[index],
        quantity: (updatedProducts[index].quantity || 1) + 1,
      };
      return updatedProducts;
    });

    // Calculate the updated totals **only for the specific product** at the given index
    const updatedProduct = selectedProduct[index];
    const updatedQuantity = receivedQuantities[index] + 1;

    const subTotalForProduct = (
      (updatedProduct?.sellingPrice || 0) * updatedQuantity
    ).toFixed(2); // Calculate subtotal for the updated product

    // Recalculate total cost for all products (only sum sellingPrice or unitCost, no quantity multiplication)
    const totalCostsForAllProducts = selectedProduct
      .reduce((total, product, idx) => {
        const productCost = product.sellingPrice || unitCosts[idx] || 0;
        return total + productCost; // Just sum the prices without multiplying by quantity
      }, 0)
      .toFixed(2);

    const totalSalesQuantity = selectedProduct.reduce(
      (total, product, idx) =>
        total + (idx === index ? updatedQuantity : receivedQuantities[idx]),
      0
    );

    // You can now recalculate the overall totals for the invoice using the updated product

    const subTotal = selectedProduct
      .reduce(
        (total, product, idx) =>
          total +
          (product?.sellingPrice || 0) *
            (idx === index ? updatedQuantity : receivedQuantities[idx]),
        0
      )
      .toFixed(2); // Adjust subtotal calculation

    const discounta = discount; // Update with your discount logic if need
    const discountedSubtotal = discounta
      ? (subTotal * (1 - discounta / 100)).toFixed(2)
      : subTotal;

    const taxRate = tax; // Adjust tax rate as needed
    const taxAmount = (discountedSubtotal * (taxRate / 100)).toFixed(2);

    const grandTotal = (
      parseFloat(discountedSubtotal) + parseFloat(taxAmount)
    ).toFixed(2);

    // Prepare the updated invoice data for the API call
    const invoicedata = {
      activeInactive: 1,
      itemIds: selectedProduct.map((item) => item.id),
      items: selectedProduct.map((item, idx) => ({
        itemId: item.id,
        quantity: idx === index ? updatedQuantity : receivedQuantities[idx], // Updated quantity only for the selected product
        unitCost: unitCosts[idx] || 0,
        discount: discounts[idx] || 0, // Ensure to include discounts
        note: note[idx] || "", // Include notes if applicable
        total: calculateDiscountedTotal(
          unitCosts[idx],
          idx === index ? updatedQuantity : receivedQuantities[idx],
          discounts[idx] || 0
        ),
        imeiNumber: item?.imeiNumber,
        averageCost: item.averageCost || 0,
        imeiNumberArr: item.imeiNumberArr || [], // IMEI numbers as needed
      })),
      customerId: props?.customer?.id,
      subTotal: subTotal,
      discount: discount, // Adjust if necessary
      tax: taxRate,
      grandTotal: grandTotal,
      paymentType: selectedPaymentTypeId,
      isCompleted: 1,
      note: descriptionNote, // Any notes if applicable
      due: changeDue ? changeDue : 0,
      payment: paymentMode, // Payment mode state
      parkNote: parkNote || "", // Park notes if applicable
      isAdmin: adminState,
      totalCost: totalCostsForAllProducts, // Updated total costs for the specific product
      totalSalesCost: subTotal,
      totalSalesQuantity: totalSalesQuantity,
      taxAmount: taxAmount,
      averageSalesCost: Number(totalAverageSalesCost.toFixed(2)), // Average sales cost
      discountAmount: Number(discountAmount1), // Adjust discount amount as needed
      invoiceStatus: 0,
      ticketId: props?.ticketid,
    };
    // console.log("increment", invoiceData);
    // Call the Update API with the new invoice data
    UpdateTicketInvoice(invoicedata);

    // Set the index of the edited product (if needed)
    setEditedProductIndex(index);
  };

  // const handleDecrement = (index) => {
  //   setReceivedQuantities((prevQuantities) => {
  //     const newQuantities = [...prevQuantities];
  //     newQuantities[index] = Math.max((newQuantities[index] || 0) - 1, 0);
  //     // If quantity reaches 0, remove the product and related states
  //     if (newQuantities[index] === 0) {
  //       newQuantities.splice(index, 1); // Remove corresponding received quantity

  //       setSelectedProduct((prevProducts) => {
  //         const updatedProductsState = [...prevProducts];
  //         updatedProductsState.splice(index, 1);
  //         return updatedProductsState;
  //       });

  //       setUnitCosts((prevUnitCosts) => {
  //         const updatedCostsState = [...prevUnitCosts];
  //         updatedCostsState.splice(index, 1);
  //         return updatedCostsState;
  //       });
  //     }

  //     return newQuantities;
  //   });

  //   // Fetch all products again if needed
  //   GetAllProducts();
  // };

  const handleDecrement = (index) => {
    setReceivedQuantities((prevQuantities) => {
      const newQuantities = [...prevQuantities];
      newQuantities[index] = Math.max((newQuantities[index] || 0) - 1, 0); // Decrement the quantity

      // If quantity reaches 0, remove the product and related states
      if (newQuantities[index] === 0) {
        // Remove corresponding received quantity
        newQuantities.splice(index, 1);

        setSelectedProduct((prevProducts) => {
          const updatedProductsState = [...prevProducts];
          updatedProductsState.splice(index, 1); // Remove product
          return updatedProductsState;
        });

        setUnitCosts((prevUnitCosts) => {
          const updatedCostsState = [...prevUnitCosts];
          updatedCostsState.splice(index, 1); // Remove corresponding unit cost
          return updatedCostsState;
        });
      }

      // Prepare updated invoice data for the API call
      const updatedProducts = selectedProduct.filter(
        (_, idx) => newQuantities[idx] !== undefined
      ); // Filter out removed products
      const subTotal = updatedProducts
        .reduce(
          (total, product, idx) =>
            total + (product?.sellingPrice || 0) * (newQuantities[idx] || 0),
          0
        )
        .toFixed(2);

      const discounta = discount; // Adjust with your discount logic if needed
      const discountedSubtotal = discounta
        ? (subTotal * (1 - discounta / 100)).toFixed(2)
        : subTotal;

      const taxRate = tax; // Adjust tax rate as needed
      const taxAmount = (discountedSubtotal * (taxRate / 100)).toFixed(2);

      const grandTotal = (
        parseFloat(discountedSubtotal) + parseFloat(taxAmount)
      ).toFixed(2);

      const totalCosts = updatedProducts
        .reduce((total, product, idx) => {
          return total + (product.sellingPrice || unitCosts[idx]);
        }, 0)
        .toFixed(2); // Calculate total costs based on the new quantities

      // Prepare the updated invoice data
      const invoicedata = {
        activeInactive: 1,
        itemIds: updatedProducts.map((item) => item.id),
        items: updatedProducts.map((item, idx) => ({
          itemId: item.id,
          quantity: newQuantities[idx] || 0, // New quantity
          unitCost: item.sellingPrice || 0,
          discount: discounts[idx] || 0, // Ensure to include discounts
          note: note[idx] || "", // Include notes if applicable
          total: calculateDiscountedTotal(
            item.sellingPrice,
            newQuantities[idx] || 0,
            discounts[idx] || 0
          ),
          averageCost: item.averageCost || 0,
          imeiNumberArr: item.imeiNumberArr || [], // IMEI numbers as needed
        })),
        customerId: props?.customer?.id,
        subTotal: subTotal, // Use the calculated subTotal
        discount: discount, // Discount (adjust if necessary)
        tax: taxRate, // Tax rate (adjust if necessary)
        grandTotal: grandTotal, // Use the calculated grandTotal
        paymentType: selectedPaymentTypeId,
        isCompleted: 1,
        note: descriptionNote, // Any notes if applicable
        due: changeDue ? changeDue : 0,
        payment: paymentMode, // Payment mode state
        parkNote: parkNote || "", // Park notes if applicable
        isAdmin: adminState,
        totalCost: totalCosts, // Use the calculated totalCosts
        totalSalesCost: subTotal, // Total sales cost
        totalSalesQuantity: updatedProducts.reduce(
          (total, product) =>
            total + (newQuantities[selectedProduct.indexOf(product)] || 0),
          0
        ),
        taxAmount: taxAmount, // Update tax amount
        averageSalesCost: Number(totalAverageSalesCost.toFixed(2)), // Average sales cost
        discountAmount: Number(discountAmount1), // Adjust discount amount as needed
        invoiceStatus: 0,
        ticketId: props?.ticketid, // Use ticket ID
      };

      // Call the Update API with the new invoice data
      UpdateTicketInvoice(invoicedata);

      return newQuantities; // Return the updated quantities
    });

    // Optionally fetch all products again if needed
    GetAllProducts();
  };

  const handleSearchTermChangeProduct = (event) => {
    const term = event.target.value;
    setSearchTermProduct(term);
    setShowModal2(true);
    setProductState(true);
  };

  // const handleCustomerSelectionProduct = (customer, selectedIMEI) => {
  //   console.log("searchproduct", customer);
  //   console.log("selectedIMEI", selectedIMEI);

  //   if (customer?.allowOverSelling === 0 && customer.inventory <= 0) {
  //     Swal.fire("This product is not available");
  //   } else {
  //     setSelectedProduct((prevSelectedProducts) => {
  //       const productIndex = prevSelectedProducts?.findIndex(
  //         (product) => product?.id === customer?.id
  //       );

  //       let updatedProducts;

  //       if (productIndex !== -1) {
  //         // Product already selected, update its quantity, total price, and IMEI numbers
  //         const existingProduct = prevSelectedProducts[productIndex];

  //         updatedProducts = [...prevSelectedProducts];
  //         updatedProducts[productIndex] = {
  //           ...existingProduct,
  //           quantity: (existingProduct.quantity || 1) + 1,
  //           totalPrice:
  //             (existingProduct.totalPrice ||
  //               existingProduct?.sellingPrice ||
  //               0) + (existingProduct?.sellingPrice || 0),
  //           imeiNumber: [
  //             ...new Set([
  //               ...(existingProduct?.imeiNumber || []),
  //               selectedIMEI,
  //             ]),
  //           ], // Combine IMEI numbers and ensure uniqueness
  //         };
  //       } else {
  //         // New product selection
  //         updatedProducts = [
  //           ...prevSelectedProducts,
  //           {
  //             ...customer,
  //             quantity: 1,
  //             totalPrice: customer?.sellingPrice || 0,
  //             imeiNumber: [selectedIMEI], // Add IMEI number for the new product
  //           },
  //         ];
  //       }

  //       // Save updated products to localStorage
  //       // localStorage.setItem(
  //       //   `selectedProducts_${props?.ticketid}`,
  //       //   JSON.stringify(updatedProducts)
  //       // );
  //       // Calculate invoice details
  //       const subTotal = updatedProducts
  //         .reduce(
  //           (total, product) =>
  //             total + (product?.sellingPrice || 0) * (product?.quantity || 1),
  //           0
  //         )
  //         .toFixed(2); // Calculate subtotal

  //       const discount = 0; // Add your discount logic if needed
  //       const discountedSubtotal = discount
  //         ? (subTotal * (1 - discount / 100)).toFixed(2)
  //         : subTotal;

  //       const taxRate = 8; // Adjust tax rate as needed
  //       const taxAmount = (discountedSubtotal * (taxRate / 100)).toFixed(2); // Calculate tax amount

  //       const grandTotal = (
  //         parseFloat(discountedSubtotal) + parseFloat(taxAmount)
  //       ).toFixed(2); // Calculate grand total
  //       const ProductCost = updatedProducts
  //         .reduce((total, product) => total + (product?.sellingPrice || 0), 0)
  //         .toFixed(2); // Calculate subtotal
  //       // Prepare invoice data
  //       const invoiceData = {
  //         activeInactive: 1,
  //         itemIds: updatedProducts?.map((product) => product.id),
  //         items: updatedProducts?.map((product) => ({
  //           itemId: product?.id,
  //           quantity: product?.quantity,
  //           imeiNumber: "",
  //           unitCost: product?.sellingPrice || 0,
  //           discount: 0,
  //           note: "",
  //           total: (product?.sellingPrice || 0) * (product?.quantity || 1),
  //           averageCost: product?.averageCost || 0,
  //           imeiNumberArr: [],
  //         })),
  //         customerId: props?.customer?.id,
  //         subTotal: subTotal,
  //         discount: discount,
  //         tax: taxRate,
  //         grandTotal: grandTotal,
  //         paymentType: [], // Adjust payment type as needed
  //         isCompleted: 1,
  //         note: "",
  //         due: 0,
  //         payment: [],
  //         parkNote: "",
  //         isAdmin: adminState,
  //         totalCost: ProductCost,
  //         totalSalesCost: subTotal,
  //         totalSalesQuantity: updatedProducts?.reduce(
  //           (total, product) => total + (product.quantity || 0),
  //           0
  //         ),
  //         taxAmount: taxAmount,
  //         averageSalesCost: calculateTotalSalesAverageCostadd(updatedProducts), // Adjust if needed
  //         discountAmount: (subTotal - discountedSubtotal).toFixed(2), // Calculate discount amount
  //         invoiceStatus: 0,
  //         ticketId: props?.ticketid,
  //       };

  //       console.log("Invoice Data:", invoiceData);
  //       // // Make your API call here
  //       // if (addInvoiceData?.length > 0) {
  //       //   UpdateTicketInvoice();
  //       // } else {
  //       //   AddTicketInvoice(invoiceData);
  //       // }
  //       return updatedProducts;
  //     });

  //     setUnitCosts((prevUnitCosts) => {
  //       const productIndex = prevUnitCosts.findIndex(
  //         (cost, index) => selectedProduct[index]?.id === customer?.id
  //       );

  //       let updatedUnitCosts;

  //       if (productIndex !== -1) {
  //         // Product already selected, unit cost remains the same
  //         updatedUnitCosts = prevUnitCosts;
  //       } else {
  //         // New product selection
  //         updatedUnitCosts = [...prevUnitCosts, customer?.sellingPrice || 0];
  //       }

  //       // Save unit costs to localStorage
  //       // localStorage.setItem(
  //       //   `unitCosts_${props?.ticketid}`,
  //       //   JSON.stringify(updatedUnitCosts)
  //       // );

  //       return updatedUnitCosts;
  //     });

  //     setReceivedQuantities((prevReceivedQuantities) => {
  //       const productIndex = prevReceivedQuantities.findIndex(
  //         (quantity, index) => selectedProduct[index]?.id === customer?.id
  //       );

  //       let updatedQuantities;

  //       if (productIndex !== -1) {
  //         // Product already selected, update its received quantity
  //         updatedQuantities = [...prevReceivedQuantities];
  //         updatedQuantities[productIndex] += 1;
  //       } else {
  //         // New product selection
  //         updatedQuantities = [...prevReceivedQuantities, 1];
  //       }

  //       // Save received quantities to localStorage
  //       // localStorage.setItem(
  //       //   `receivedQuantities_${props?.ticketid}`,
  //       //   JSON.stringify(updatedQuantities)
  //       // );

  //       return updatedQuantities;
  //     });

  //     // Remove the selected product with the specific IMEI number from all products list
  //     setAllProducts((prevData) =>
  //       prevData.filter(
  //         (item) => !item?.imeiNumber || item?.imeiNumber !== selectedIMEI
  //       )
  //     );
  //     // AddTicketInvoice({
  //     //     products: updatedProducts,
  //     //     unitCosts: unitCosts, // Make sure these are up-to-date
  //     //     receivedQuantities: receivedQuantities, // Make sure these are up-to-date
  //     //   });
  //     setSearchTermProduct("");
  //     setShowModal2(false);
  //     setProductState(false);
  //   }
  // };

  // const handleCustomerSelectionProduct = (customer, selectedIMEI) => {
  //   console.log("searchproduct", customer);
  //   console.log("selectedIMEI", selectedIMEI);

  //   if (customer?.allowOverSelling === 0 && customer.inventory <= 0) {
  //     Swal.fire("This product is not available");
  //   } else {
  //     setSelectedProduct((prevSelectedProducts = []) => {
  //       const productIndex = prevSelectedProducts?.findIndex(
  //         (product) => product?.id === customer?.id
  //       );

  //       let updatedProducts;

  //       if (productIndex !== -1) {
  //         // Product already selected, update its quantity, total price, and IMEI numbers
  //         const existingProduct = prevSelectedProducts[productIndex];

  //         updatedProducts = [...prevSelectedProducts];
  //         updatedProducts[productIndex] = {
  //           ...existingProduct,
  //           quantity: (existingProduct.quantity || 1) + 1,
  //           totalPrice:
  //             (existingProduct.totalPrice ||
  //               existingProduct?.sellingPrice ||
  //               0) + (existingProduct?.sellingPrice || 0),
  //           imeiNumber: [
  //             ...new Set([
  //               ...(existingProduct?.imeiNumber || []),
  //               selectedIMEI,
  //             ]),
  //           ], // Combine IMEI numbers and ensure uniqueness
  //         };
  //       } else {
  //         // New product selection
  //         updatedProducts = [
  //           ...prevSelectedProducts,
  //           {
  //             ...customer,
  //             quantity: 1,
  //             totalPrice: customer?.sellingPrice || 0,
  //             imeiNumber: [selectedIMEI], // Add IMEI number for the new product
  //           },
  //         ];
  //       }

  //       console.log("updated productss", updatedProducts);

  //       const subTotal = updatedProducts
  //         .reduce(
  //           (total, product) =>
  //             total + (product?.sellingPrice || 0) * (product?.quantity || 1),
  //           0
  //         )
  //         .toFixed(2);

  //       const discounta = discount; // Add your discount logic if needed
  //       const discountedSubtotal = discounta
  //         ? (subTotal * (1 - discounta / 100)).toFixed(2)
  //         : subTotal;

  //       const taxRate = tax; // Adjust tax rate as needed
  //       const taxAmount = (discountedSubtotal * (taxRate / 100)).toFixed(2);

  //       const grandTotal = (
  //         parseFloat(discountedSubtotal) + parseFloat(taxAmount)
  //       ).toFixed(2);

  //       const ProductCost = updatedProducts
  //         .reduce(
  //           (total, product, index) =>
  //             total + (product?.sellingPrice || unitCosts[index]),
  //           0
  //         )
  //         .toFixed(2);
  //       const totalSalesQuantity = updatedProducts.reduce(
  //         (total, product, index) =>
  //           total + (product.quantity || receivedQuantities[index]),
  //         0
  //       );
  //       const calculateTotalSalesAverageCostupdate = () => {
  //         return updatedProducts.reduce((total, product, index) => {
  //           const isDevice = product?.productCategoryId === 2;
  //           const productCost = isDevice
  //             ? product?.unitCost
  //             : product?.averageCost || 0;
  //           const productQuantity = receivedQuantities[index] || 1;

  //           return total + productCost * productQuantity;
  //         }, 0);
  //       };

  //       const totalAverageSalesCostupdate =
  //         calculateTotalSalesAverageCostupdate();
  //       console.log("hrkk", totalAverageSalesCostupdate);

  //       const invoiceData = {
  //         activeInactive: 1,
  //         itemIds: updatedProducts?.map((product) => product.id),
  //         items: updatedProducts?.map((product, index) => ({
  //           itemId: product?.id,
  //           quantity: product?.quantity || receivedQuantities[index],
  //           imeiNumber: "",
  //           unitCost: product?.sellingPrice || unitCosts[index],
  //           discount: 0,
  //           note: "",
  //           total: (product?.sellingPrice || 0) * (product?.quantity || 1),
  //           averageCost: product?.averageCost || 0,
  //           imeiNumberArr: product?.imeiNumber?.filter(Boolean)?.length
  //             ? product?.imeiNumber?.filter(Boolean) // Post IMEI numbers if available
  //             : [],
  //         })),
  //         customerId: props?.customer?.id,
  //         subTotal: subTotal,
  //         discount: discount,
  //         tax: taxRate,
  //         grandTotal: grandTotal,
  //         paymentType: [],
  //         isCompleted: 1,
  //         note: "",
  //         due: 0,
  //         payment: [],
  //         parkNote: "",
  //         isAdmin: adminState,
  //         totalCost: ProductCost,
  //         totalSalesCost: subTotal,
  //         totalSalesQuantity: totalSalesQuantity,
  //         taxAmount: taxAmount,
  //         averageSalesCost: Number(totalAverageSalesCostupdate),
  //         discountAmount: (subTotal - discountedSubtotal).toFixed(2),
  //         invoiceStatus: 0,
  //         ticketId: props?.ticketid,
  //       };

  //       console.log("Invoice Data:", invoiceData);
  //       if (invoiceDataId) {
  //         UpdateTicketInvoice(invoiceData);
  //       } else {
  //         AddTicketInvoice(invoiceData);
  //       }
  //       setInvoiceData(invoiceData);
  //       return updatedProducts;
  //     });

  //     setUnitCosts((prevUnitCosts = []) => {
  //       const productIndex = prevUnitCosts.findIndex(
  //         (cost, index) => selectedProduct?.[index]?.id === customer?.id
  //       );

  //       let updatedUnitCosts;

  //       if (productIndex !== -1) {
  //         updatedUnitCosts = prevUnitCosts;
  //       } else {
  //         updatedUnitCosts = [...prevUnitCosts, customer?.sellingPrice || 0];
  //       }

  //       return updatedUnitCosts;
  //     });

  //     setReceivedQuantities((prevReceivedQuantities = []) => {
  //       const productIndex = prevReceivedQuantities.findIndex(
  //         (quantity, index) => selectedProduct?.[index]?.id === customer?.id
  //       );

  //       let updatedQuantities;

  //       if (productIndex !== -1) {
  //         updatedQuantities = [...prevReceivedQuantities];
  //         updatedQuantities[productIndex] += 1;
  //       } else {
  //         updatedQuantities = [...prevReceivedQuantities, 1];
  //       }

  //       return updatedQuantities;
  //     });

  //     // Remove the selected product with the specific IMEI number from all products list
  //     setAllProducts((prevData) =>
  //       prevData.filter(
  //         (item) => !item?.imeiNumber || item?.imeiNumber !== selectedIMEI
  //       )
  //     );

  //     setSearchTermProduct("");
  //     setShowModal2(false);
  //     setProductState(false);
  //   }
  // };

  const handleCustomerSelectionProduct = (customer, selectedIMEI) => {
    // console.log("searchproduct", customer);
    // console.log("selectedIMEI", selectedIMEI);

    if (customer?.allowOverSelling === 0 && customer.inventory <= 0) {
      Swal.fire("This product is not available");
    } else {
      setSelectedProduct((prevSelectedProducts = []) => {
        const productIndex = prevSelectedProducts?.findIndex(
          (product) => product?.id === customer?.id
        );

        let updatedProducts;

        if (productIndex !== -1) {
          // Product already selected, update its quantity, total price, and IMEI numbers
          const existingProduct = prevSelectedProducts[productIndex];

          updatedProducts = [...prevSelectedProducts];
          updatedProducts[productIndex] = {
            ...existingProduct,
            quantity: (existingProduct.quantity || 1) + 1,
            totalPrice:
              (existingProduct.totalPrice ||
                existingProduct?.sellingPrice ||
                0) + (existingProduct?.sellingPrice || 0),
            imeiNumber: [
              ...new Set([
                ...(existingProduct?.imeiNumber || []),
                selectedIMEI,
              ]),
            ], // Combine IMEI numbers and ensure uniqueness
          };
        } else {
          // New product selection
          updatedProducts = [
            ...prevSelectedProducts,
            {
              ...customer,
              quantity: 1,
              totalPrice: customer?.sellingPrice || 0,
              imeiNumber: [selectedIMEI], // Add IMEI number for the new product
            },
          ];
        }

        console.log("updated productss", updatedProducts);

        const subTotal = updatedProducts
          .reduce(
            (total, product, index) =>
              total +
              (product?.sellingPrice || unitCosts?.[index] || 0) *
                (product?.quantity || 1),
            0
          )
          .toFixed(2);

        const discounta = discount; // Add your discount logic if needed
        const discountedSubtotal = discounta
          ? (subTotal * (1 - discounta / 100)).toFixed(2)
          : subTotal;

        const taxRate = tax; // Adjust tax rate as needed
        const taxAmount = (discountedSubtotal * (taxRate / 100)).toFixed(2);

        const grandTotal = (
          parseFloat(discountedSubtotal) + parseFloat(taxAmount)
        ).toFixed(2);

        const ProductCost = Number(
          updatedProducts.reduce(
            (total, product, index) =>
              total + Number(product?.sellingPrice || unitCosts?.[index] || 0),
            0
          )
        ).toFixed(2);
        // const totalSalesQuantity = updatedProducts.reduce(
        //   (total, product, index) =>
        //     total + (product.quantity || receivedQuantities?.[index] || 0),
        //   0
        // );
        const totalSalesQuantity = updatedProducts.reduce(
          (total, product, index) =>
            total +
            (Number(product.quantity) ||
              Number(receivedQuantities?.[index]) ||
              0),
          0
        );

        const calculateTotalSalesAverageCostupdate = () => {
          return updatedProducts.reduce((total, product, index) => {
            const isDevice = product?.productCategoryId === 2;
            const productCost = isDevice
              ? product?.unitCost
              : product?.averageCost || 0;
            const productQuantity = receivedQuantities?.[index] || 1;

            return total + productCost * productQuantity;
          }, 0);
        };

        const totalAverageSalesCostupdate =
          calculateTotalSalesAverageCostupdate();
        // console.log("hrkk", totalAverageSalesCostupdate);

        const invoiceData = {
          activeInactive: 1,
          itemIds: updatedProducts?.map((product) => product.id),
          items: updatedProducts?.map((product, index) => ({
            itemId: product?.id,
            quantity: product?.quantity || receivedQuantities?.[index] || 1,
            imeiNumber: product?.imeiNumber?.filter(Boolean)?.join(","),
            unitCost: product?.sellingPrice || unitCosts?.[index] || 0,
            discount: product?.discount || discounts?.[index] || 0,
            note: product?.note || "",
            total: (product?.sellingPrice || 0) * (product?.quantity || 1),
            averageCost: product?.averageCost || 0,
            imeiNumberArr: product?.imeiNumber?.filter(Boolean)?.length
              ? product?.imeiNumber?.filter(Boolean) // Post IMEI numbers if available
              : [],
          })),
          customerId: props?.customer?.id,
          subTotal: subTotal,
          discount: discount,
          tax: taxRate,
          grandTotal: grandTotal,
          paymentType: [],
          isCompleted: 1,
          note: "",
          due: 0,
          payment: [],
          parkNote: "",
          isAdmin: adminState,
          totalCost: ProductCost,
          totalSalesCost: subTotal,
          totalSalesQuantity: totalSalesQuantity,
          taxAmount: taxAmount,
          averageSalesCost: Number(totalAverageSalesCostupdate),
          discountAmount: (subTotal - discountedSubtotal).toFixed(2),
          invoiceStatus: 0,
          ticketId: props?.ticketid,
        };

        console.log("Invoice Data:", invoiceData);
        if (invoiceDataId) {
          UpdateTicketInvoice(invoiceData);
        } else {
          AddTicketInvoice(invoiceData);
        }
        setInvoiceData(invoiceData);
        return updatedProducts;
      });

      // Update unitCosts and receivedQuantities similarly
      setUnitCosts((prevUnitCosts = []) => {
        const productIndex = prevUnitCosts.findIndex(
          (cost, index) => selectedProduct?.[index]?.id === customer?.id
        );

        let updatedUnitCosts;

        if (productIndex !== -1) {
          updatedUnitCosts = prevUnitCosts;
        } else {
          updatedUnitCosts = [...prevUnitCosts, customer?.sellingPrice || 0];
        }

        return updatedUnitCosts;
      });

      setReceivedQuantities((prevReceivedQuantities = []) => {
        const productIndex = prevReceivedQuantities.findIndex(
          (quantity, index) => selectedProduct?.[index]?.id === customer?.id
        );

        let updatedQuantities;

        if (productIndex !== -1) {
          updatedQuantities = [...prevReceivedQuantities];
          updatedQuantities[productIndex] += 1;
        } else {
          updatedQuantities = [...prevReceivedQuantities, 1];
        }

        return updatedQuantities;
      });

      // Remove the selected product with the specific IMEI number from all products list
      setAllProducts((prevData) =>
        prevData.filter(
          (item) => !item?.imeiNumber || item?.imeiNumber !== selectedIMEI
        )
      );

      setSearchTermProduct("");
      setShowModal2(false);
      setProductState(false);
    }
  };

  // const filteredData1 = allProducts?.filter((item) => {
  //   const lowerCaseSearchTerm = searchTermProduct.toLowerCase();

  //   return (
  //     item.title.toLowerCase().includes(lowerCaseSearchTerm.toLowerCase()) ||
  //     item?.brand?.name
  //       .toLowerCase()
  //       .includes(lowerCaseSearchTerm.toLowerCase()) ||
  //     item.skuCode.toString().includes(lowerCaseSearchTerm.toLowerCase())
  //   );
  // });

  const filteredData1 = useMemo(() => {
    const lowerCaseSearchTerm = searchTermProduct.toLowerCase();

    if (!lowerCaseSearchTerm) {
      return allProducts; // Return all products when the search term is empty
    }

    return allProducts.filter((item) => {
      const lowerCaseItemTitle = item?.title?.toLowerCase() || "";
      const lowerCaseItemBrandName = item?.brand?.name?.toLowerCase() || "";
      const lowerCaseItemSkuCode =
        item?.skuCode?.toString().toLowerCase() || "";

      return (
        lowerCaseItemTitle.includes(lowerCaseSearchTerm) ||
        lowerCaseItemBrandName.includes(lowerCaseSearchTerm) ||
        lowerCaseItemSkuCode.includes(lowerCaseSearchTerm)
      );
    });
  }, [allProducts, searchTermProduct]);

  const navigate = useNavigate();

  const [adminState, setAdminState] = useState("");

  useEffect(() => {
    let localAdmin = localStorage.getItem("admin");
    setAdminState(localAdmin);
    GetReciept();
    GetProfile();
    GetSubUserProfile();
    GetAllTax();
    GetAllDiscount();
    GetCustomer();
    GetAllProducts();
    handleGetInVoices();
    // fetch(
    //   "https://ip-api.io/api/json?api_key=fabc32c1-943d-43c7-8a13-ea82cd6ac052"
    // )
    //   .then((response) => response.json())
    //   .then((json) => {
    //     console.log("countryyy", json);
    //     setCountryCode(json);
    //   });
  }, []);

  const GetCustomer = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllCustomer?pageSize=100&page=1`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setData(result?.data);
          setSelectedCustomer(result?.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllProducts = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AJ0pT5xJJsG8rHqot1A6txMupaTPvbUM2.LHRBUtee7s0DQmEebl5p1hig5dbcABIB0m4VWSzru%2Fo"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetProductDeviceApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          // setAllProducts(result?.data);
          setAllProducts(
            result?.data.filter((item) => item.activeInactive === 1)
          );
          setLoading(false);
          //setSelectedProduct(result?.data);
          setRetrieveSaleProduct(
            result?.data.filter((item) => item.activeInactive === 0)
          );
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const dropdown = document.querySelector(".searchproductdisplycstm");

      if (dropdown && !dropdown.contains(event.target)) {
        setShowModal2(false);
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [setShowModal2]);

  // const handleDelete = (index) => {
  //   setSelectedProduct((prevProducts) => {
  //     const updatedProducts = [...prevProducts];
  //     updatedProducts.splice(index, 1);
  //     return updatedProducts;
  //   });
  //   setReceivedQuantities((prevQuantities) => {
  //     const updatedQuantities = [...prevQuantities];
  //     updatedQuantities.splice(index, 1);
  //     return updatedQuantities;
  //   });

  //   setPaymnetState(false);
  //   setPaymentMode([]);
  //   GetAllProducts();
  // };
  // const handleDelete = (index) => {
  //   setSelectedProduct((prevProducts) => {
  //     const updatedProducts = [...prevProducts];
  //     updatedProducts.splice(index, 1);
  //     UpdateTicketInvoice(updatedProducts);
  //     return updatedProducts;
  //   });

  //   setUnitCosts((prevUnitCosts) => {
  //     const updatedCosts = [...prevUnitCosts];
  //     updatedCosts.splice(index, 1);

  //     return updatedCosts;
  //   });

  //   setReceivedQuantities((prevQuantities) => {
  //     const updatedQuantities = [...prevQuantities];
  //     updatedQuantities.splice(index, 1);

  //     return updatedQuantities;
  //   });

  //   // Reset payment-related state
  //   setPaymnetState(false);
  //   setPaymentMode([]);

  //   // Fetch all products again if needed
  //   GetAllProducts();
  // };

  const handleDelete = (index) => {
    setSelectedProduct((prevProducts) => {
      const updatedProducts = [...prevProducts];
      updatedProducts.splice(index, 1); // Remove product immutably

      // Perform the necessary calculations
      const subTotal = updatedProducts
        .reduce(
          (total, product) =>
            total + (product?.sellingPrice || 0) * (product?.quantity || 1),
          0
        )
        .toFixed(2);

      const discounta = discount; // Add your discount logic if needed
      const discountedSubtotal = discounta
        ? (subTotal * (1 - discounta / 100)).toFixed(2)
        : subTotal;

      const taxRate = tax; // Adjust tax rate as needed
      const taxAmount = (discountedSubtotal * (taxRate / 100)).toFixed(2);

      const grandTotal = (
        parseFloat(discountedSubtotal) + parseFloat(taxAmount)
      ).toFixed(2);

      const ProductCost = updatedProducts
        .reduce((total, product) => total + (product?.sellingPrice || 0), 0)
        .toFixed(2);

      const totalSalesQuantity = updatedProducts.reduce(
        (total, product, idx) => total + (receivedQuantities[idx] || 0),
        0
      );

      const calculateTotalSalesAverageCostupdate = () => {
        return updatedProducts.reduce((total, product, index) => {
          const isDevice = product?.productCategoryId === 2;
          const productCost = isDevice
            ? product?.unitCost
            : product?.averageCost || 0;
          const productQuantity = receivedQuantities?.[index] || 1;

          return total + productCost * productQuantity;
        }, 0);
      };

      const totalAverageSalesCostupdate =
        calculateTotalSalesAverageCostupdate();

      // Prepare the updated invoice data
      const invoicedata = {
        activeInactive: 1,
        itemIds: updatedProducts.map((item) => item.id),
        items: updatedProducts.map((item, idx) => ({
          itemId: item.id,
          quantity: receivedQuantities[idx], // Use the updated quantities
          unitCost: unitCosts[idx], // Use the updated unit costs
          discount: discounts[idx] || 0, // Adjust discount logic if necessary
          note: note[idx] || "", // Add notes if applicable
          total: calculateDiscountedTotal(
            unitCosts[idx],
            receivedQuantities[idx],
            discounts[idx] || 0
          ),
          averageCost: item?.averageCost || 0,
          imeiNumberArr: item?.imeiNumber || [], // Keep the IMEI numbers as an array
        })),
        customerId: props?.customer?.id,
        subTotal: subTotal,
        discount: discount,
        tax: taxRate,
        grandTotal: grandTotal,
        paymentType: selectedPaymentTypeId,
        isCompleted: 1,
        note: descriptionNote,
        due: changeDue ? changeDue : 0,
        payment: paymentMode, // Payment mode state
        parkNote: parkNote || "",
        isAdmin: adminState,
        totalCost: ProductCost,
        totalSalesCost: subTotal,
        totalSalesQuantity: totalSalesQuantity,
        taxAmount: taxAmount,
        averageSalesCost: Number(totalAverageSalesCostupdate.toFixed(2)),
        discountAmount: (subTotal - discountedSubtotal).toFixed(2),
        invoiceStatus: 0,
        ticketId: props?.ticketid,
      };

      // Call UpdateTicketInvoice to update the API
      // UpdateTicketInvoice(invoicedata);
      return updatedProducts;
    });

    // Remove the corresponding unit cost and quantity immutably
    setUnitCosts((prevUnitCosts) => {
      const updatedCosts = [...prevUnitCosts];
      updatedCosts.splice(index, 1);
      return updatedCosts;
    });

    setReceivedQuantities((prevQuantities) => {
      const updatedQuantities = [...prevQuantities];
      updatedQuantities.splice(index, 1);
      return updatedQuantities;
    });
    // Reset payment-related state
    setPaymnetState(false);
    setPaymentMode([]);

    // Optionally fetch all products again if needed
    GetAllProducts();
  };

  // const handleDelete = (index) => {
  //   // Remove the product, unit cost, and received quantity at the same index
  //   setSelectedProduct((prevProducts) => {
  //     const updatedProducts = [...prevProducts];
  //     updatedProducts.splice(index, 1); // Remove the product at the specified index

  //     // Perform the necessary calculations
  //     const subTotal = updatedProducts
  //       .reduce(
  //         (total, product) =>
  //           total + (product?.sellingPrice || 0) * (product?.quantity || 1),
  //         0
  //       )
  //       .toFixed(2);

  //     const discounta = discount; // Add your discount logic if needed
  //     const discountedSubtotal = discounta
  //       ? (subTotal * (1 - discounta / 100)).toFixed(2)
  //       : subTotal;

  //     const taxRate = tax; // Adjust tax rate as needed
  //     const taxAmount = (discountedSubtotal * (taxRate / 100)).toFixed(2);

  //     const grandTotal = (
  //       parseFloat(discountedSubtotal) + parseFloat(taxAmount)
  //     ).toFixed(2);

  //     const ProductCost = updatedProducts
  //       .reduce((total, product) => total + (product?.sellingPrice || 0), 0)
  //       .toFixed(2);
  //     const totalSalesQuantity = updatedProducts.reduce(
  //       (total, product, idx) => total + (receivedQuantities[idx] || 0),
  //       0
  //     );

  //     const calculateTotalSalesAverageCostupdate = () => {
  //       return updatedProducts.reduce((total, product, index) => {
  //         const isDevice = product?.productCategoryId === 2;
  //         const productCost = isDevice
  //           ? product?.unitCost
  //           : product?.averageCost || 0;
  //         const productQuantity = receivedQuantities?.[index] || 1;

  //         return total + productCost * productQuantity;
  //       }, 0);
  //     };

  //     const totalAverageSalesCostupdate =
  //       calculateTotalSalesAverageCostupdate();
  //     console.log("hrkk", totalAverageSalesCostupdate);
  //     // Prepare the updated invoice data
  //     const invoicedata = {
  //       activeInactive: 1,
  //       itemIds: updatedProducts.map((item) => item.id),
  //       items: updatedProducts.map((item, idx) => ({
  //         itemId: item.id,
  //         quantity: receivedQuantities[idx], // Use the updated quantities
  //         unitCost: unitCosts[idx], // Use the updated unit costs
  //         discount: discounts[idx] || 0, // Adjust discount logic if necessary
  //         note: note[idx] || "", // Add notes if applicable
  //         total: calculateDiscountedTotal(
  //           unitCosts[idx],
  //           receivedQuantities[idx],
  //           discounts[idx] || 0
  //         ),
  //         averageCost: item?.averageCost || 0,
  //         imeiNumberArr: item?.imeiNumber || [], // Keep the IMEI numbers as an array
  //       })),
  //       customerId: props?.customer?.id,
  //       subTotal: subTotal, // Use the calculated subTotal
  //       discount: discount, // Discount (adjust as needed)
  //       tax: taxRate, // Tax rate (adjust as needed)
  //       grandTotal: grandTotal, // Use the calculated grandTotal
  //       paymentType: selectedPaymentTypeId,
  //       isCompleted: 1,
  //       note: descriptionNote, // Add any note if needed
  //       due: changeDue ? changeDue : 0,
  //       payment: paymentMode, // Payment mode state
  //       parkNote: parkNote || "", // Any park notes if applicable
  //       isAdmin: adminState,
  //       totalCost: ProductCost, // Use the calculated ProductCost
  //       totalSalesCost: subTotal, // Update sales cost
  //       totalSalesQuantity: totalSalesQuantity,
  //       taxAmount: taxAmount, // Update tax amount
  //       averageSalesCost: Number(totalAverageSalesCostupdate.toFixed(2)), // Average sales cost (adjust as needed)
  //       discountAmount: (subTotal - discountedSubtotal).toFixed(2), // Discount amount
  //       invoiceStatus: 0,
  //       ticketId: props?.ticketid, // Use ticket ID
  //     };

  //     // Call UpdateTicketInvoice to update the API
  //     UpdateTicketInvoice(invoicedata);
  //     // setInVoiceData(invoicedata);
  //     return updatedProducts; // Return the updated product list
  //   });

  //   // Remove the corresponding unit cost and quantity
  //   setUnitCosts((prevUnitCosts) => {
  //     const updatedCosts = [...prevUnitCosts];
  //     updatedCosts.splice(index, 1); // Remove the unit cost at the same index
  //     return updatedCosts;
  //   });

  //   setReceivedQuantities((prevQuantities) => {
  //     const updatedQuantities = [...prevQuantities];
  //     updatedQuantities.splice(index, 1); // Remove the received quantity at the same index
  //     return updatedQuantities;
  //   });

  //   // Reset payment-related state
  //   setPaymnetState(false); // Reset payment state
  //   setPaymentMode([]); // Clear any payment mode

  //   // Optionally fetch all products again if needed
  //   GetAllProducts();
  // };

  // const handleDelete = (index) => {
  //   setSelectedProduct((prevProducts) => {
  //     const updatedProducts = [...prevProducts];
  //     updatedProducts.splice(index, 1);
  //     return updatedProducts;
  //   });

  //   setUnitCosts((prevUnitCosts) => {
  //     const updatedCosts = [...prevUnitCosts];
  //     updatedCosts.splice(index, 1);
  //     return updatedCosts;
  //   });

  //   setReceivedQuantities((prevQuantities) => {
  //     const updatedQuantities = [...prevQuantities];
  //     updatedQuantities.splice(index, 1);
  //     return updatedQuantities;
  //   });

  //   setPaymnetState(false);
  //   setPaymentMode([]);
  //   GetAllProducts();
  // };

  const handleDeleteRetrieve = (index) => {
    setSelectedRetrieveProduct((prevProducts) => {
      const updatedProducts = [...prevProducts];
      updatedProducts.splice(index, 1);
      return updatedProducts;
    });

    setPaymnetState(false);
    setPaymentMode([]);
  };
  // const calculateTotalSellingPrice = () => {
  //   return selectedProduct.reduce((total, product) => {
  //     return total + (product?.sellingPrice || 0);
  //   }, 0);
  // };
  const calculateTotalSellingPrice = () => {
    return selectedProduct?.reduce((total, product, index) => {
      const sellingPrice = Number(product?.sellingPrice) || 0;
      const unitCost = Number(unitCosts[index]) || 0;

      // If the unitCost is edited (non-zero), use it, otherwise use sellingPrice
      const priceToAdd =
        unitCost !== undefined && unitCost !== null ? unitCost : sellingPrice;

      return total + priceToAdd;
    }, 0);
  };
  const Totalcosts = calculateTotalSellingPrice();
  //console.log("Totalcosts", Totalcosts);
  const calculateDiscountedAverageCost = (
    sellingPrice,
    receivedQty,
    discount
  ) => {
    const total = sellingPrice * receivedQty;
    if (!discount) {
      return total;
    }
    const discountedTotal = total * (1 - discount / 100);
    return discountedTotal >= 0 ? discountedTotal : 0;
  };
  const calculateTotalAverageCost = () => {
    return selectedProduct?.reduce((total, product, index) => {
      // Get the selling price and unit cost
      const sellingPrice = Number(product?.sellingPrice) || 0;
      const unitCost = Number(unitCosts[index]) || 0;

      // Use unitCost if available, otherwise fallback to sellingPrice
      const priceToUse =
        unitCost !== undefined && unitCost !== null ? unitCost : sellingPrice;

      // Get the product quantity, defaulting to 1 if none is specified
      const productQuantity = Number(receivedQuantities[index]) || 1;
      // console.log("productQuantity", productQuantity);
      // console.log("priceToUse", priceToUse);
      // Calculate total based on price and quantity
      return total + priceToUse * productQuantity;
    }, 0);
  };
  // const calculateTotalAverageCost = () => {
  //   return selectedProduct.reduce((total, product, index) => {
  //     const productAverageCost = product?.sellingPrice || 0;
  //     const productQuantity = receivedQuantities[index] || 1;
  //     console.log("productQuantity", productQuantity);
  //     console.log("productAverageCost", productAverageCost);
  //     return total + productAverageCost * productQuantity;
  //   }, 0);
  // };
  const totalAverageCost = calculateTotalAverageCost();
  //.log("totalsales", totalAverageCost);

  const calculateTotalSalesAverageCost = () => {
    return selectedProduct?.reduce((total, product, index) => {
      const isDevice = product?.productCategoryId === 2;
      const productCost = isDevice
        ? product?.unitCost
        : product?.averageCost || 0;
      const productQuantity = receivedQuantities[index] || 1;

      return total + productCost * productQuantity;
    }, 0);
  };

  const totalAverageSalesCost = calculateTotalSalesAverageCost();
  // console.log("totalAverageSalesCost", totalAverageSalesCost);

  const calculateTotalSalesQuantity = () => {
    return receivedQuantities?.reduce(
      (total, quantity) => total + Number(quantity),
      0
    );
  };
  const totalSalesQuantity = calculateTotalSalesQuantity();

  // console.log("Total Sales Quantity:", totalSalesQuantity);
  const updateTotalSalesQuantity = () => {
    const total = calculateTotalSalesQuantity();
    setTotalSalesQty(total);
  };

  const AddInvoice = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    let totalCosts = 0; // Initialize totalCosts
    const itemsData = selectedProduct.map((item, index) => {
      const itemId = item?.id;
      const selectedIMEINumbers = item?.imeiNumber || [];
      let filteredImeiNumbers = [];

      try {
        const parsedImeiArr = JSON.parse(item?.imeiNumberArr || "[]");
        filteredImeiNumbers = Array.isArray(parsedImeiArr)
          ? parsedImeiArr.filter((imei) => selectedIMEINumbers.includes(imei))
          : [];
      } catch (error) {
        console.error("Error parsing IMEI array: ", error);
        filteredImeiNumbers = [];
      }

      const Unitcost = Number(unitCosts?.[index] || 0);
      totalCosts += Unitcost;

      return {
        discount: Number(discounts?.[index]) || 0,
        quantity: Number(receivedQuantities?.[index]) || 0,
        unitCost: Unitcost,
        itemId: itemId,
        note: note?.[index] || "",
        total: Number(
          calculateDiscountedTotal(
            unitCosts?.[index],
            receivedQuantities?.[index],
            discounts?.[index] || 0
          )
        ),
        averageCost: item?.averageCost || 0,
        imeiNumber: selectedIMEINumbers.join(","), // Combine selected IMEI numbers into a single string
        imeiNumberArr: filteredImeiNumbers, // IMEI numbers array
      };
    });

    const dueData = selectedProduct.map((item) => {
      return {
        itemId: item.id,
        paymentType: selectedPaymentType,
        date: moment(new Date()).format("YYYY-MM-DD"),
        time: moment(new Date()).format("HH:mm:ss"),
        payAmount: Number(inputValue) || 0,
      };
    });
    const dis = parseFloat(overallTotal - discountedSubtotal);
    // console.log("itemsss", dueData);

    const raw = JSON.stringify({
      activeInactive: 1,
      itemIds: selectedProduct.map((item) => item.id),
      items: itemsData,
      customerId: props?.customer?.id,
      subTotal: overallTotal,
      discount: discount, //dis.toFixed(2),
      tax: tax, //taxAmount.toFixed(2),
      grandTotal: overallTotal1.toFixed(2),
      paymentType: selectedPaymentTypeId,
      isCompleted: 1,
      note: descriptionNote,
      due: changeDue ? changeDue : 0,
      payment: paymentMode,
      parkNote: parkNote ? parkNote : "",
      isAdmin: adminState,
      totalCost: totalCosts.toFixed(2), // Number(Totalcosts.toFixed(2)),

      // totalCost: Number(Totalcosts.toFixed(2)),
      totalSalesCost: overallTotal, //Number(totalAverageCost.toFixed(2)),
      totalSalesQuantity: totalSalesQuantity,
      taxAmount: taxAmount.toFixed(2),
      averageSalesCost: Number(totalAverageSalesCost.toFixed(2)),
      discountAmount: Number(discountAmount1),
      invoiceStatus: 1,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("addinvoice", raw);

    fetch(AddInvoiceApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("resultAdd", result);
        if (result?.status === true) {
          setShowModal9(true);
          props?.onEdit(result?.data?.id);
          // props.onload(props?.statusId, result?.data?.id);
          setInvoiceID1(result?.data?.id);
          GetInvoiceId(result?.data?.id);
          GetAllProducts();
          // setSelectedProduct([]);
          updateTotalSalesQuantity();
          setTotalSalesQty(0);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleGetInVoices = () => {
    let local = localStorage.getItem("token");

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetInvoices, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setInVoiceData(
            result?.data.filter((item) => item.activeInactive === 0).reverse()
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [discount, setDiscount] = useState(0);
  const [tax, setTax] = useState(0);

  const handleDiscountChange = (event) => {
    const selectedDiscount = parseInt(event.target.value, 10);
    setDiscount(selectedDiscount);
  };

  const handleTaxChange = (event) => {
    const selectedTax = parseInt(event.target.value, 10);
    setTax(selectedTax);
  };

  const discountedSubtotal = overallTotal * (1 - discount / 100);
  // console.log("discountedSubtotal,", discountedSubtotal);

  const taxAmount = discountedSubtotal * (tax / 100);
  // console.log("object,", taxAmount);
  const overallTotal1 = discountedSubtotal + taxAmount;
  const discountAmount1 = parseFloat(overallTotal - discountedSubtotal).toFixed(
    2
  );
  // console.log("discountAmount", discountAmount1);
  const [errormsg, setErrormsg] = useState("");
  const maxDescriptionLength = 300;

  const handleDescriptionChange = (e) => {
    const newDescription = e.target.value;

    if (newDescription.length <= maxDescriptionLength) {
      setDescriptionNote(newDescription);
      setErrormsg("");
    } else if (newDescription.length > maxDescriptionLength) {
      setTimeout(() => {
        setErrormsg("Allow Maximum 300 Characters");
      }, 2000);
    }
  };

  const handleDescriptionChangeNote = (e) => {
    const newDescription = e.target.value;

    if (newDescription.length <= maxDescriptionLength) {
      setNote((prevUnitCosts) => {
        const newUnitCosts = [...prevUnitCosts];
        newUnitCosts[editedProductIndex] = newDescription;
        return newUnitCosts;
      });
      // setDescriptionNote(newDescription);
      setErrormsg("");
    } else if (newDescription.length > maxDescriptionLength) {
      setTimeout(() => {
        setErrormsg("Allow Maximum 300 Characters");
      }, 2000);
    }
  };

  const [remainingAmount, setRemainingAmount] = useState(overallTotal1);

  const Due = parseFloat(overallTotal1) - parseFloat(inputValue);

  useEffect(() => {
    if (overallTotal1 !== null) {
      setInputValue(overallTotal1.toFixed(2));
    }
  }, [overallTotal1]);

  const [paymentTotal, setPaymentTotal] = useState();
  const [ErrorAmount, setErrorAmount] = useState(false);

  // const handlePay = () => {
  //   if (selectedProduct.length === 0) {
  //     Swal.fire("Please select a product first.");
  //     return;
  //   }

  //   if (inputValue > overallTotal1) {
  //     // Calculate change due

  //     const changes = inputValue - overallTotal1;
  //     // Swal.fire(`Change due: $${changes.toFixed(2)}`);

  //     setChangeDue(changes.toFixed(2));
  //   }

  //   const payAmount = Number(inputValue) || 0;

  //   if (payAmount === 0) {
  //     // Swal.fire("Your payment is complete.");
  //     return;
  //   }
  //   setPaymentTotal(payAmount);
  //   const paymentData = {
  //     itemId: selectedProduct.map((item) => item.id),
  //     paymentType: selectedPaymentType,
  //     date: moment(new Date()).format("YYYY-MM-DD"),
  //     time: moment(new Date()).format("HH:mm:ss"),
  //     payAmount: payAmount,
  //   };

  //   // Update payment mode
  //   setPaymentMode((prevPaymentMode) => [...prevPaymentMode, paymentData]);

  //   // Update total payment
  //   setTotalPayment((prevTotalPayment) => prevTotalPayment + payAmount);

  //   // Update remaining amount and input value
  //   const newRemainingAmount = overallTotal1 - totalPayment - payAmount;

  //   setRemainingAmount(newRemainingAmount);
  //   setInputValue(
  //     newRemainingAmount !== undefined ? newRemainingAmount.toFixed(2) : ""
  //   );

  //   // Check if the total payment equals the overall total
  //   if (totalPayment + payAmount == overallTotal1.toFixed(2)) {
  //     setErrorAmount(true);
  //   }

  //   const invoiceDataWithPayment = {
  //     ...invoiceData, // Retrieve stored invoice data
  //     payment: [...(invoiceData?.payment || []), paymentData], // Add payment info
  //   };
  //   if (invoiceData) {
  //     AddTicketInvoice(invoiceDataWithPayment);
  //   }

  //   // UpdateTicketInvoice();
  //   // Optionally set payment state
  //   setPaymnetState(true);
  // };

  const handlePay = () => {
    if (selectedProduct.length === 0) {
      Swal.fire("Please select a product first.");
      return;
    }

    // If payment is more than total, calculate change
    if (inputValue > overallTotal1) {
      const changes = inputValue - overallTotal1;
      setChangeDue(changes.toFixed(2)); // Set change due
    }

    const payAmount = Number(inputValue) || 0;

    if (payAmount === 0) {
      return;
    }

    setPaymentTotal(payAmount);

    const paymentData = {
      itemId: selectedProduct.map((item) => item.id),
      paymentType: selectedPaymentType,
      date: moment(new Date()).format("YYYY-MM-DD"),
      time: moment(new Date()).format("HH:mm:ss"),
      payAmount: payAmount,
    };

    // Update payment mode
    setPaymentMode((prevPaymentMode) => [...prevPaymentMode, paymentData]);

    // Update total payment
    setTotalPayment((prevTotalPayment) => prevTotalPayment + payAmount);

    // Update remaining amount and input value
    const newRemainingAmount = overallTotal1 - totalPayment - payAmount;
    setRemainingAmount(newRemainingAmount);
    setInputValue(newRemainingAmount.toFixed(2));

    // Check if payment matches the total amount
    if (totalPayment + payAmount === overallTotal1.toFixed(2)) {
      setErrorAmount(true);
    }

    // Invoice data object including payment data
    let totalCosts = 0; // Initialize totalCosts
    const itemsData = selectedProduct.map((item, index) => {
      const itemId = item?.id;
      const selectedIMEINumbers = item?.imeiNumber || [];
      let filteredImeiNumbers = [];

      try {
        const parsedImeiArr = JSON.parse(item?.imeiNumberArr || "[]");
        filteredImeiNumbers = Array.isArray(parsedImeiArr)
          ? parsedImeiArr.filter((imei) => selectedIMEINumbers.includes(imei))
          : [];
      } catch (error) {
        console.error("Error parsing IMEI array: ", error);
        filteredImeiNumbers = [];
      }

      const Unitcost = Number(unitCosts?.[index] || 0);
      totalCosts += Unitcost;

      return {
        discount: Number(discounts?.[index]) || 0,
        quantity: Number(receivedQuantities?.[index]) || 0,
        unitCost: Unitcost,
        itemId: itemId,
        note: note?.[index] || "",
        total: Number(
          calculateDiscountedTotal(
            unitCosts?.[index],
            receivedQuantities?.[index],
            discounts?.[index] || 0
          )
        ),
        averageCost: item?.averageCost || 0,
        imeiNumber: selectedIMEINumbers.join(","), // Combine selected IMEI numbers into a single string
        imeiNumberArr: filteredImeiNumbers, // IMEI numbers array
      };
    });

    const dis = parseFloat(overallTotal - discountedSubtotal);
    // console.log("itemsss", dueData);
    const invoiceData = {
      activeInactive: 1,
      itemIds: selectedProduct.map((item) => item.id),
      items: itemsData,
      customerId: props?.customer?.id,
      subTotal: overallTotal,
      discount: discount, //dis.toFixed(2),
      tax: tax, //taxAmount.toFixed(2),
      grandTotal: overallTotal1.toFixed(2),
      paymentType: selectedPaymentTypeId,
      isCompleted: 1,
      note: descriptionNote,
      due: changeDue ? changeDue : 0,
      payment: [paymentData],
      parkNote: parkNote ? parkNote : "",
      isAdmin: adminState,
      totalCost: totalCosts.toFixed(2), // Number(Totalcosts.toFixed(2)),

      // totalCost: Number(Totalcosts.toFixed(2)),
      totalSalesCost: overallTotal, //Number(totalAverageCost.toFixed(2)),
      totalSalesQuantity: totalSalesQuantity,
      taxAmount: taxAmount.toFixed(2),
      averageSalesCost: Number(totalAverageSalesCost.toFixed(2)),
      discountAmount: Number(discountAmount1),
      invoiceStatus: 0,
      ticketId: props?.ticketid,
    };

    console.log("Invoice Data with Payment:", invoiceData);

    // API call to submit invoice data with payment
    UpdateTicketInvoice(invoiceData);

    // Optionally set payment state
    setPaymnetState(true);
  };

  // const handlePay = () => {
  //   if (selectedProduct.length === 0) {
  //     Swal.fire("Please select a product first.");
  //     return;
  //   }

  //   if (inputValue > overallTotal1) {
  //     // Calculate change due

  //     const changes = inputValue - overallTotal1;
  //     // Swal.fire(`Change due: $${changes.toFixed(2)}`);

  //     setChangeDue(changes.toFixed(2));
  //   }

  //   const payAmount = Number(inputValue) || 0;

  //   if (payAmount === 0) {
  //     // Swal.fire("Your payment is complete.");
  //     return;
  //   }
  //   setPaymentTotal(payAmount);
  //   const paymentData = {
  //     itemId: selectedProduct.map((item) => item.id),
  //     paymentType: selectedPaymentType,
  //     date: moment(new Date()).format("YYYY-MM-DD"),
  //     time: moment(new Date()).format("HH:mm:ss"),
  //     payAmount: payAmount,
  //   };

  //   // Update payment mode
  //   setPaymentMode((prevPaymentMode) => [...prevPaymentMode, paymentData]);

  //   // Update total payment
  //   setTotalPayment((prevTotalPayment) => prevTotalPayment + payAmount);

  //   // Update remaining amount and input value
  //   const newRemainingAmount = overallTotal1 - totalPayment - payAmount;

  //   setRemainingAmount(newRemainingAmount);
  //   setInputValue(
  //     newRemainingAmount !== undefined ? newRemainingAmount.toFixed(2) : ""
  //   );

  //   // Check if the total payment equals the overall total
  //   if (totalPayment + payAmount == overallTotal1.toFixed(2)) {
  //     setErrorAmount(true);
  //   }

  //   const invoiceDataWithPayment = {
  //     ...invoiceData, // Retrieve stored invoice data
  //     payment: [...(invoiceData?.payment || []), paymentData], // Add payment info
  //   };
  //      console.log("updated productss", updatedProducts);

  //       const subTotal = updatedProducts
  //         .reduce(
  //           (total, product) =>
  //             total + (product?.sellingPrice || 0) * (product?.quantity || 1),
  //           0
  //         )
  //         .toFixed(2);

  //       const discounta = discount; // Add your discount logic if needed
  //       const discountedSubtotal = discounta
  //         ? (subTotal * (1 - discounta / 100)).toFixed(2)
  //         : subTotal;

  //       const taxRate = tax; // Adjust tax rate as needed
  //       const taxAmount = (discountedSubtotal * (taxRate / 100)).toFixed(2);

  //       const grandTotal = (
  //         parseFloat(discountedSubtotal) + parseFloat(taxAmount)
  //       ).toFixed(2);

  //       const ProductCost = updatedProducts
  //         .reduce(
  //           (total, product, index) =>
  //             total + (product?.sellingPrice || unitCosts?.[index] || 0),
  //           0
  //         )
  //         .toFixed(2);

  //       // const totalSalesQuantity = updatedProducts.reduce(
  //       //   (total, product, index) =>
  //       //     total + (product.quantity || receivedQuantities?.[index] || 0),
  //       //   0
  //       // );
  //       const totalSalesQuantity = updatedProducts.reduce(
  //         (total, product, index) =>
  //           total +
  //           (Number(product.quantity) ||
  //             Number(receivedQuantities?.[index]) ||
  //             0),
  //         0
  //       );

  //       const calculateTotalSalesAverageCostupdate = () => {
  //         return updatedProducts.reduce((total, product, index) => {
  //           const isDevice = product?.productCategoryId === 2;
  //           const productCost = isDevice
  //             ? product?.unitCost
  //             : product?.averageCost || 0;
  //           const productQuantity = receivedQuantities?.[index] || 1;

  //           return total + productCost * productQuantity;
  //         }, 0);
  //       };

  //       const totalAverageSalesCostupdate =
  //         calculateTotalSalesAverageCostupdate();
  //       console.log("hrkk", totalAverageSalesCostupdate);

  //       const invoiceData = {
  //         activeInactive: 1,
  //         itemIds: updatedProducts?.map((product) => product.id),
  //         items: updatedProducts?.map((product, index) => ({
  //           itemId: product?.id,
  //           quantity: product?.quantity || receivedQuantities?.[index] || 1,
  //           imeiNumber: product?.imeiNumber?.filter(Boolean)?.join(","),
  //           unitCost: product?.sellingPrice || unitCosts?.[index] || 0,
  //           discount: 0,
  //           note: "",
  //           total: (product?.sellingPrice || 0) * (product?.quantity || 1),
  //           averageCost: product?.averageCost || 0,
  //           imeiNumberArr: product?.imeiNumber?.filter(Boolean)?.length
  //             ? product?.imeiNumber?.filter(Boolean) // Post IMEI numbers if available
  //             : [],
  //         })),
  //         customerId: props?.customer?.id,
  //         subTotal: subTotal,
  //         discount: discount,
  //         tax: taxRate,
  //         grandTotal: grandTotal,
  //         paymentType: [],
  //         isCompleted: 1,
  //         note: "",
  //         due: 0,
  //         payment: [],
  //         parkNote: "",
  //         isAdmin: adminState,
  //         totalCost: ProductCost,
  //         totalSalesCost: subTotal,
  //         totalSalesQuantity: totalSalesQuantity,
  //         taxAmount: taxAmount,
  //         averageSalesCost: Number(totalAverageSalesCostupdate),
  //         discountAmount: (subTotal - discountedSubtotal).toFixed(2),
  //         invoiceStatus: 0,
  //         ticketId: props?.ticketid,
  //       };

  //       console.log("Invoice Data:", invoiceData);

  // UpdateTicketInvoice(invoiceDataWithPayment);

  //   // AddTicketInvoice(invoiceDataWithPayment);

  //   // Optionally set payment state
  //   setPaymnetState(true);
  // };

  const [totalPayment, setTotalPayment] = useState(0);

  useEffect(() => {
    const totalPayment = paymentMode.reduce(
      (total, payment) => total + payment.payAmount,
      0
    );
    setTotalPayment(totalPayment);
  }, [paymentMode]);

  const renderProductRows = (products, indexPrefix, isRetrieve = false) => {
    return products.map((item, index) => (
      <tr key={`${isRetrieve ? "retrieve" : "product"}_${indexPrefix + index}`}>
        <td>
          <span className="item_no">#{index + 1}</span>
        </td>
        {isRetrieve ? (
          <>
            {item.itemIds.map((ite, i) => (
              <React.Fragment key={i}>
                <td>
                  <Link state={{ data: ite }}>
                    {ite?.title}
                    <span>({ite?.skuCode})</span>
                  </Link>
                </td>
                <td
                  className={ite?.inventory <= 0 ? "red-text" : ""}
                  style={{ color: ite?.inventory <= 0 ? "red" : "" }}
                >
                  {ite?.inventory}
                </td>
              </React.Fragment>
            ))}
          </>
        ) : (
          <>
            <td>
              <Link state={{ data: item }}>
                {item?.title} <span>({item?.skuCode})</span>
              </Link>
            </td>
            <td
              className={item?.inventory <= 0 ? "red-text" : ""}
              style={{ color: item?.inventory <= 0 ? "red" : "" }}
            >
              {item.inventory}
            </td>
          </>
        )}
        <td>
          <div className="quantity_btn">
            <a
              href="#"
              className="qty_minus"
              onClick={() => handleDecrement(index)}
            >
              <img src="assets/img/minus-icon.webp" alt="" />
            </a>
            <input
              type="text"
              className="qty_count"
              value={receivedQuantities[index] || 1}
              readOnly
            />

            <a
              href="#"
              className="qty_plus"
              onClick={() => handleIncrement(index)}
              style={{
                pointerEvents:
                  item?.productCategoryId === 2 &&
                  item?.imeiNumber !== "" &&
                  item?.imeiNumberArr !== null
                    ? "none"
                    : "auto",
                opacity:
                  item?.productCategoryId === 2 &&
                  item?.imeiNumber !== "" &&
                  item?.imeiNumberArr !== null
                    ? 0.5
                    : 1,
              }}
            >
              <img src="assets/img/plus-icon.webp" alt="" />
            </a>

            {/* <a
              href="#"
              className="qty_plus"
              onClick={() => handleIncrement(index)}
            >
              <img src="assets/img/plus-icon.webp" alt="" />
            </a> */}
          </div>
        </td>
        {unitCosts[index] ? <td>${unitCosts[index]}</td> : <td>$0.00</td>}
        {unitCosts[index] && receivedQuantities[index] ? (
          <td>
            {calculateDiscountedTotal(
              unitCosts[index],
              receivedQuantities[index],
              discounts[index] || 0
            )}
          </td>
        ) : (
          <td>$ 0.00</td>
        )}
        <td>
          <div className="action_btn">
            <a
              href="#"
              data-toggle="modal"
              data-target="#editinfoModal"
              className="edit_btn"
              onClick={() => handleEdit(index)}
              style={{
                pointerEvents:
                  item?.productCategoryId === 2 &&
                  item?.imeiNumber !== "" &&
                  item?.imeiNumberArr !== null
                    ? "none"
                    : "auto",
                opacity:
                  item?.productCategoryId === 2 &&
                  item?.imeiNumber !== "" &&
                  item?.imeiNumberArr !== null
                    ? 0.5
                    : 1,
              }}
            >
              <img src="assets/img/edit-icon.webp" alt="" />
            </a>

            <a
              href="#"
              className="delete_btn"
              onClick={
                isRetrieve
                  ? () => handleDeleteRetrieve(index)
                  : () => handleDelete(index)
              }
            >
              <img src="assets/img/delete-icon.webp" alt="" />
            </a>
          </div>
        </td>
      </tr>
    ));
  };

  const handleBrandChange = (value) => {
    setSelectedCategory2([value]);
    setSelectedPaymentTypeId(value?.id);
    setSelectedPaymentType(value?.name);
    setIsPaymentOptionSelected(true);
  };

  const GetAllDiscount = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllDiscountApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllDiscount(result?.data?.reverse());
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [defaultTax, setDefaultTax] = useState(null);

  const GetAllTax = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllTaxApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // setAllTax(result?.data);
        // setAllTax(result?.data || []);
        const defaultTaxItem = result?.data?.find(
          (item) => item?.defaultTax === 1
        );
        if (defaultTaxItem) {
          setTax(defaultTaxItem.rate);
        } else {
          setTax(0);
        }
        setDefaultTax(defaultTaxItem || null);
        const filteredTaxItems = result?.data.filter(
          (item) => item?.defaultTax !== 1
        );
        setAllTax(filteredTaxItems);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const GetPrductbyCategory = (id) => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}/getItemByCategory/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //setLayoutList(result?.data?.layout);
        if (result?.status === true) {
          setProductByCategory(result?.data);
        }
      })
      .catch((error) => console.error(error));
  };

  const handleClick = (item) => {
    handleCustomerSelectionProduct(item?.item);
  };

  // const handleCategoryClick = (e, item) => {
  //   e.preventDefault(); // Prevent the default anchor link behavior
  //   setShowModal10(true);
  //   GetPrductbyCategory(item?.itemId);
  //   setCategoryProduct(item);
  // };
  const handleCategoryClick = async (e, item) => {
    e.preventDefault(); // Prevent the default anchor link behavior

    // Clear the current product list
    setProductByCategory([]);

    // Fetch the new product list
    await GetPrductbyCategory(item?.itemId);

    // Set the category product and open the modal
    setCategoryProduct(item);
    setShowModal10(true);
  };

  function scrollToTop() {
    window.scrollTo({ top: 700, behavior: "smooth" });
  }

  const GetInvoiceId = (id) => {
    // setLoading(true);
    let local = localStorage.getItem("token");

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getInvoiceById/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setInVoiceData1(result?.data[0]);
          setShowModal9(true);
          setPayment(result?.data[0]?.payment);
          setItems(result?.data[0]?.items);
          setItemData(result?.data[0]?.itemIds);
          // if (printType === 2 || reciept === true) {
          //   printSetup();
          // } else {
          //   printTable();
          // }
          //setLoading(false);
        } else {
          setShowModal9(false);
          // setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        // setLoading(false);
      });
  };

  const EmailSend = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: selectedCustomer?.firstName,
      email: selectedCustomer?.email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseurl}sendInvoiceMail/${invoiceId1}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          navigate("/ticketlist");
          setLoading(false);
          setSelectedProduct([]);
          setSelectedRetrieveProduct([]);
          setPaymentMode([]);
          setPaymnetState(false);
          setSelectedCustomer({});
          setShowModal9(false);
          setSelectedState(false);
          setDiscounts([]);
          setUnitCosts([]);
          // Swal.fire("Receipt sent to email successfully! ");
          Swal.fire({
            title: "Receipt sent to email successfully!!",
            customClass: {
              container: "custom-swal-container",

              confirmButton: "custom-swal-confirm-button",
            },
          });
        } else {
          setLoading(false);
          Swal.fire({
            title: result.message,
            customClass: {
              container: "custom-swal-container",

              confirmButton: "custom-swal-confirm-button",
            },
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };
  const EmailSend1 = () => {
    setLoading(true);
    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));

    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: selectedCustomer?.firstName ? selectedCustomer?.firstName : "user",
      email: email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseurl}sendInvoiceMail/${invoiceId1}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          navigate("/ticketlist");
          setLoading(false);
          // Swal.fire("Email Receipt Successfully! ");
          Swal.fire({
            title: "Receipt sent to email successfully!!",
            customClass: {
              container: "custom-swal-container",
              confirmButton: "custom-swal-confirm-button",
            },
          });
          setShowModal11(false);
          setSelectedProduct([]);
          setSelectedRetrieveProduct([]);
          setPaymentMode([]);
          setPaymnetState(false);
          setSelectedCustomer({});
          setShowModal9(false);
          setSelectedState(false);
          setDiscounts([]);
          setUnitCosts([]);
          document
            .querySelectorAll(".modal-backdrop")
            .forEach((el) => el.classList.remove("modal-backdrop"));
          setEmail("");
        } else {
          setLoading(false);
          Swal.fire({
            title: result.message,
            customClass: {
              container: "custom-swal-container",
              confirmButton: "custom-swal-confirm-button",
            },
          });
          setEmail("");
          setShowModal11(false);
          document
            .querySelectorAll(".modal-backdrop")
            .forEach((el) => el.classList.remove("modal-backdrop"));
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const OnSubmit = () => {
    setError(ValidationEmail());
  };

  const isDisposableEmail = (email) => {
    const domain = email.split("@")[1];
    return disposableEmailDomains.includes(domain);
  };

  const disposableEmailDomains = [
    // "gmail.com",
    // "yahoo.com",
    // "outlook.com",
    // "hotmail.com",
    // "aol.com",
    // "icloud.com",
    // "mail.com",
    // "yandex.com",
    // "protonmail.com",
    // "zoho.com",
    // "gmx.com",
    // "ymail.com",
    // "comcast.net",
    // "me.com",
    // "msn.com",
    // "live.com",
    // "sbcglobal.net",
    // "verizon.net",
    // "att.net",
    // "cox.net",
    // "smartitventures.com",
    // Add more domains as needed

    "mailinator.com",
    "guerrillamail.com",
    "10minutemail.com",
    "temp-mail.org",
    "yopmail.com",
    "getnada.com",
    "trashmail.com",
    "fakeinbox.com",
    "throwawaymail.com",
    "maildrop.cc",
    "mytemp.email",
    "tempmailo.com",
    "dispostable.com",
    "mintemail.com",
    "airmail.com",
  ];

  const ValidationEmail = () => {
    let error = {};
    let reg = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    // let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (!email) {
      error.email = "Email is required.";
    } else if (!reg.test(email)) {
      error.email = "Invalid email .";
    } else if (email && isDisposableEmail(email)) {
      error.email =
        "Please use a valid email address. Temporary email addresses are not allowed.";
    }
    if (Object.keys(error).length === 0) {
      EmailSend1();
    }
    return error;
  };

  const printSetup = () => {
    Swal.fire("You have not  Setup Receipt Configuration ");
    setSelectedProduct([]);
    setSelectedRetrieveProduct([]);
    setPaymentMode([]);
    setPaymnetState(false);
    setSelectedCustomer({});
    setShowModal9(false);
    setUnitCosts([]);
    setSelectedState(false);
    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
  };
  const printTable = () => {
    const printContents = document.querySelector(".reciepttable").innerHTML;
    const originalContents = document.body.innerHTML;
    // Calculate the position to center the new window
    const width = 800;
    const height = 600;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    // Open a new window with centered position
    const printWindow = window.open(
      "",
      "_blank",
      `width=${width},height=${height},top=${top},left=${left}`
    );
    // if (!printWindow) {
    //   Swal.fire(
    //     "Unable to open print window. Please check your pop-up settings."
    //   );
    //   return;
    // }

    // const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write(`
    <html>
       <head>
     <style>
     @media print {
  @page {
    margin: 0; /* Remove default margins to ensure there's no space at the top and bottom */
  }
  body {
    /* Remove margins on body as well */
       margin-left: ${leftMargin}px;
    margin-right: ${rightMargin}px;
     height:auto
  }
  /* Hide browser print headers and footers */
  header,
  footer {
    display: none;
  }
}
     </style>

     </head>
      <body>${printContents}</body>
    </html>
  `);
    printWindow.document.close();
    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 250);
    // printWindow.print();
    // printWindow.close();
    setSelectedProduct([]);
    setSelectedRetrieveProduct([]);
    setPaymentMode([]);
    setPaymnetState(false);
    setSelectedCustomer({});
    setShowModal9(false);
    setSelectedState(false);
    setUnitCosts([]);
    navigate("/ticketlist");
    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
  };

  const GetReciept = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetRecieptApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("fgdfgfd", result);
        if (result?.status === true) {
          if (!result.data) {
            setReciept(true);
          } else {
            setStates(true);
            const data = result?.data;
            setRecieptId(data?.id);
            setPrintType(data.type);
            setTitle(data.receiptTitle);
            setLogo(data?.logo);
            setIsLogo(data.isLogo === 1);
            setLogoSize(data.logoSize);
            setLogoPosition(data.logoPosition);
            setHeader(data.headerInfo);
            setDisplaySales(data.displaySalesPersonName === 1);
            setFooter(data.footerInfo);
            setNote1(data.displayPublicNote === 1);
            setDisplayBarcode(data.displayBarcode === 1);
            setDisplayNote(data.note === 1);
            setImageMsg(""); // Assuming image message is not returned from API
            setStates(data.activeInactive === 1);
            setLeftMargin(data?.leftMargin || "10mm");
            setRightMargin(data?.rightMargin || "10mm");
            setSGTNumber(data?.gst);
            setGstShow(data?.gstButton);
            setSelectedOption({
              prodname: data.items.name === 1 ? 1 : 0,
              prodname_sku:
                data.items.name === 1 && data.items.sku === 1 ? 1 : 0,
              sku: data.items.sku === 1 ? 1 : 0,
            });

            // Set customer details based on API response
            setCustomerDetails1({
              fullname: data.customer.name === 1 ? 1 : 0,
              email: data.customer.email === 1 ? 1 : 0,
              phone: data.customer.phoneNumber === 1 ? 1 : 0,
              address: data.customer.address === 1 ? 1 : 0,
            });
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetProfile = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(getProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("adminnn", result);
        if (result.status === true) {
          setData1(result?.data);
        } else {
          // console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetSubUserProfile = () => {
    let local = localStorage.getItem("token");
    //console.log(local);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetSubUserProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log("subuser", result);
        if (result.status === true) {
          setDataUser(result?.data);
        } else {
          // console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleDeletePayment = (index) => {
    setPaymentMode((prevPaymentMode) => {
      const updatedPaymentMode = [...prevPaymentMode];
      const [removedPayment] = updatedPaymentMode.splice(index, 1);
      updatePaymentTotals(removedPayment.payAmount);
      return updatedPaymentMode;
    });
  };

  const updatePaymentTotals = (amount) => {
    setTotalPayment((prevTotalPayment) => prevTotalPayment - amount);
    setRemainingAmount((prevRemainingAmount) => {
      const newRemainingAmount = prevRemainingAmount + amount;
      setInputValue(newRemainingAmount.toFixed(2));
      return newRemainingAmount;
    });
  };

  const [selectedOptions, setSelectedOptions] = useState("");

  const handleOptionChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedOptions(selectedValue);
    const selectedPayment = allPaymentOptions.find(
      (item) => item.name === selectedValue
    );
    handleBrandChange(selectedPayment);
  };
  const handlePaymentClick = () => {
    if (selectedOptions.length === 0 || selectedPaymentType === "") {
      Swal.fire("Please select a payment option");
    } else if (selectedProduct.length > 0) {
      handlePay();
    } else {
      //handlePayRet();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (filteredData1?.length > 0) {
        handleCustomerSelectionProduct(filteredData1[0]);
      }
    }
  };
  const HandleRefresh = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to reset transactions",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c76cff",
      cancelButtonColor: "#efefef",
      confirmButtonText: "Yes, reset it!",
      confirmButtonText: "<span style='color: white;'>Yes, reset it!</span>",
      cancelButtonText: "<span style='color: black;'>cancel!</span>",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "sweet-alert2-container-zindex",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your data has been deleted.",
          icon: "success",
          confirmButtonColor: "#c76cff",
          customClass: {
            container: "sweet-alert2-container-zindex",
          },
        }).then(() => {
          setSelectedProduct([]);
          setSelectedRetrieveProduct([]);
          // window.location.reload();
          // UpdateCustomer(id);
        });
      }
    });
  };

  const AddTicketInvoice = (invoicedata) => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify(invoicedata);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("addinvoice", raw);
    fetch(AddTicketInvoiceApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("resultAdd", result);
        if (result?.status === true) {
          //GetAddTicketData();
          props?.onloaded();
        } else {
          Swal.fire(result?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // const GetAddTicketData = () => {
  //   setLoading(true);
  //   let local = localStorage.getItem("token");
  //   const myHeaders = new Headers();
  //   myHeaders.append("Authorization", `Bearer ${local}`);

  //   const requestOptions = {
  //     method: "GET",
  //     headers: myHeaders,
  //     redirect: "follow",
  //   };

  //   fetch(`${baseurl}/getTicketById/${props?.ticketid}`, requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       console.log("ticket listdadadadd", result);
  //       setInvoiceAdd(result?.data?.invoice);
  //       setInvoiceDataId(result?.data?.invoice?.id);

  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       setLoading(false);
  //     });
  // };

  const UpdateTicketInvoice = (invoicedata) => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify(invoicedata);

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    console.log("itemsssupdate", raw);
    console.log("urlll", `${baseurl}/updateServiceInvoice/${invoiceDataId}`);
    fetch(`${baseurl}/updateServiceInvoice/${invoiceDataId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("resultupdatee", result);
        if (result?.status === true) {
          props?.onloaded();
        } else {
          Swal.fire(result?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div className="main-wrapper devicelistcstmtableres ">
        {loading ? (
          <div className="landing_loader">
            <div className="innerloader">
              <img src="img/spin-1s-156px.gif" alt="" />
            </div>
          </div>
        ) : null}

        {/* <!-- Main Content --> */}

        <div className="row">
          <div className="col-lg-5 col-md-12">
            {props?.status1 === "Invoiced" ||
            props?.status1 === "Cancelled" ? null : (
              <>
                <div className="search_btn">
                  <div className="search_field">
                    <div
                      className="top_cus_btn srch_customer_btn searchproductdisplycstm"
                      onClick={() => setShowModal2(true)}
                    >
                      <a href="#">
                        <img
                          className="productimgcustom"
                          src="assets/img/search-icon.webp"
                          alt=""
                        />
                        {/* <input
                        type="text"
                        className="inputproductdisplaycst"
                        placeholder="Search Product"
                        value={searchTermProduct}
                        onChange={handleSearchTermChangeProduct}
                        onClick={() => {
                          setProductState(!productState);

                          setSearchTermProduct("");
                        }}
                        
                      /> */}
                        <input
                          type="text"
                          className="inputproductdisplaycst"
                          placeholder="Search Product"
                          value={searchTermProduct}
                          onFocus={scrollToTop}
                          onChange={handleSearchTermChangeProduct}
                          onClick={(e) => {
                            e.preventDefault();
                            setProductState(!productState);

                            setSearchTermProduct("");
                          }}
                          onKeyDown={handleKeyDown}
                        />
                      </a>
                      {showModal2 === true && productState === true ? (
                        <>
                          <div
                            className={`searchcustomer_list seacrhproductlistcustm ${
                              showModal2 ? "active" : ""
                            }`}
                          >
                            <div className="srchcustmer_title common_title">
                              <h4>Search Product </h4>
                            </div>

                            <div className="customer_list">
                              <ul>
                                {filteredData1.length ? (
                                  <>
                                    {filteredData1?.map((item, index) => (
                                      <li key={`${item.id}-${index}`}>
                                        <Link
                                          onClick={() => {
                                            handleCustomerSelectionProduct(
                                              item,
                                              item?.imeiNumber
                                            );
                                            setCustomerId(item?.userId);
                                          }}
                                          key={item.id}
                                          className="linkcustm"
                                        >
                                          <div
                                            key={item.id}
                                            className="custmer_img"
                                          >
                                            {item?.image ? (
                                              <img src={item?.image} alt="" />
                                            ) : (
                                              <img
                                                src="assets/img/customer-info-img.webp"
                                                alt=""
                                              />
                                            )}
                                          </div>
                                          <span>
                                            {item?.title}
                                            <small>{item?.skuCode}</small>
                                            <small
                                              style={{
                                                color: "#0000ff",
                                              }}
                                            >
                                              {item?.imeiNumber}
                                            </small>
                                          </span>
                                        </Link>
                                      </li>
                                    ))}
                                  </>
                                ) : (
                                  <div className="text-center">
                                    <h5>No data available</h5>
                                  </div>
                                )}
                              </ul>
                            </div>
                          </div>{" "}
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="new_btn cus_btn">
                    <a
                      href=""
                      data-toggle="modal"
                      data-target="#newproductModal"
                      onClick={() => setShowModal3(true)}
                    >
                      <i className="fa fa-plus"></i> New
                    </a>
                  </div>
                </div>

                <div
                  className="prod_type"
                  style={{
                    maxHeight: layoutList.length > 16 ? "400px" : "auto",
                    overflowY: layoutList.length > 16 ? "auto" : "visible",
                  }}
                >
                  {layoutValue === 1 ? (
                    <ul>
                      {layoutList.length > 0 ? (
                        <>
                          {layoutList.map((item, index) => (
                            <li
                              className="cus_prod_tag"
                              key={index}
                              style={{ cursor: "pointer" }}
                            >
                              {item?.isCategory === 0 ? (
                                <a onClick={() => handleClick(item)}>
                                  <div
                                  // className=" selectcustmproduct"
                                  >
                                    <div
                                      className=" tag_strip classcolorstyle"
                                      style={{ background: item?.colorCode }}
                                    ></div>
                                    <span>{item?.name}</span>
                                  </div>
                                </a>
                              ) : (
                                <a
                                  href="#"
                                  data-toggle="modal"
                                  data-target="#casesModal"
                                  onClick={(e) => handleCategoryClick(e, item)}
                                >
                                  <div
                                  //  className="selectcustmproduct"
                                  >
                                    <div
                                      className="tag_strip classcolorstyle"
                                      style={{ background: item?.colorCode }}
                                    ></div>
                                    <span>{item?.name}</span>
                                  </div>
                                </a>
                              )}
                            </li>
                          ))}
                          {/* {layoutList.map((item, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              if (item?.isCategory === 0) {
                                handleCustomerSelectionProduct(item?.item);
                              } else {
                                setShowModal10(true);
                                GetPrductbyCategory(item?.itemId);
                                setCategoryProduct(item);
                                console.log("product", item?.itemId);
                              }
                            }}
                          >
                            {item?.isCategory === 0 ? (
                              <>
                                <a>
                                  <div className="selectcustmproduct">
                                    <div
                                      className="classcolorstyle"
                                      style={{ background: item?.colorCode }}
                                    ></div>
                                    <span>{item?.name}</span>
                                  </div>
                                </a>
                              </>
                            ) : (
                              <>
                                {" "}
                                <a
                                  href=""
                                  data-toggle="modal"
                                  data-target="#casesModal"
                                >
                                  <div className="selectcustmproduct">
                                    <div
                                      className="classcolorstyle"
                                      style={{ background: item?.colorCode }}
                                    ></div>
                                    <span>{item?.name}</span>
                                  </div>
                                </a>
                              </>
                            )}
                          </li>
                        ))} */}
                        </>
                      ) : (
                        <div className="cash_register_setup ">
                          <h4>Set Up Cash Register Layout Keys</h4>
                          <Link to="/settings" state={{ activeState: 6 }}>
                            <button className="setup_btn">Setup</button>
                          </Link>
                        </div>
                      )}
                    </ul>
                  ) : (
                    <div className="cash_register_setup ">
                      <Link to="/settings" state={{ activeState: 6 }}>
                        <h4>Set Up Cash Register Layout Keys</h4>
                        <button className="setup_btn">Setup</button>
                      </Link>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          {props?.status1 === "Invoiced" || props?.status1 === "Cancelled" ? (
            <>
              {" "}
              <div className="col-lg-7 col-md-12">
                <div className="cus_table cash_register_table">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th colSpan="2">ITEM(s)</th>
                          <th>In stock</th>
                          <th>Qty.</th>
                          <th>Unit Price</th>
                          <th>Total Price</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {displayList?.invoice?.itemIds?.map((item, index) => {
                          const correspondingItem =
                            displayList?.invoice?.items?.find(
                              (itemId) => itemId?.id === item?.itemId
                            );

                          return (
                            <>
                              <tr key={`default_${index}`}>
                                <td className="itemnocstm">
                                  <span className="item_no ">#{index + 1}</span>
                                </td>
                                <td>
                                  <a href="#">
                                    {item?.title}
                                    <span>({item?.skuCode})</span>
                                  </a>
                                </td>
                                <td>{item?.inventory}</td>
                                <td>
                                  <div className="quantity_btn">
                                    <a href="#" className="qty_minus">
                                      <img
                                        src="assets/img/minus-icon.webp"
                                        alt=""
                                      />
                                    </a>
                                    <input
                                      type="text"
                                      className="qty_count"
                                      value={receivedQuantities1[index]}
                                    />
                                    <a href="#" className="qty_plus">
                                      <img
                                        src="assets/img/plus-icon.webp"
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                </td>
                                <td>${unitCost1[index]}</td>
                                <td>${total1[index]}</td>
                                <td>
                                  <div className="action_btn">
                                    <a
                                      href="#"
                                      data-toggle="modal"
                                      data-target="#editinfoModal"
                                      className="edit_btn"
                                    >
                                      <img
                                        src="assets/img/edit-icon.webp"
                                        alt=""
                                      />
                                    </a>
                                    <a href="#" className="delete_btn">
                                      <img
                                        src="assets/img/delete-icon.webp"
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>

                      <tfoot>
                        <tr className="price_tr">
                          <td colSpan="3">Sub Total</td>
                          <td></td>

                          <td colSpan="3">${subTotals || 0}</td>
                        </tr>
                        <tr className="price_tr">
                          <td colSpan="3">
                            <span>Discount:</span>
                            <select
                              className="tble_select"
                              // onChange={handleDiscountChange}
                              value={dis}
                            >
                              <option value={dis}>{dis}%</option>
                              {/* {alldiscount?.map((item) => (
                                <>
                                  {" "}
                                  <option key={item?.id} value={item?.rate}>
                                    {item?.rate}%
                                  </option>
                                </>
                              ))} */}
                            </select>
                          </td>
                          <td></td>
                          <td colSpan="3">${discountAmount || 0}</td>
                        </tr>
                        <tr className="price_tr">
                          <td colSpan="3">
                            <span>Tax:</span>
                            <select
                              className="tble_select"
                              // onChange={handleTaxChange}
                              value={taxs}
                            >
                              <option value={0}>Tax {taxs}%</option>
                              {/* {alltax?.map((item) => (
                                <>
                                  {" "}
                                  <option value={item?.rate}>
                                    {item?.name} {item?.rate}%
                                  </option>
                                </>
                              ))} */}
                            </select>
                          </td>
                          <td></td>
                          <td colSpan="3">${taxAmounts || 0}</td>
                        </tr>
                        <tr className="grand_total">
                          <td colSpan="3">Grand Total</td>
                          <td></td>

                          <td colSpan="3">${GrandTotals || 0}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>

                  <div className="payment_method">
                    <div className="payment_mode">
                      <div className="inner_payment_mode">
                        <div className="mode_title">
                          <span>Payment Made</span>
                        </div>
                        <div className="mode_table">
                          <table>
                            <thead>
                              <tr>
                                <th>Time</th>
                                <th>Type</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {paymentType?.map((payment, index) => (
                                <>
                                  <tr key={index}>
                                    <td>
                                      {payment?.date || "--"}
                                      {payment?.time || "--"}
                                    </td>
                                    <td>
                                      <b>{payment?.paymentType || "--"}</b>
                                    </td>
                                    <td>
                                      <b>${payment?.payAmount || "0"}</b>
                                    </td>
                                    <td>
                                      <a
                                        href="#"
                                        className="delete_btn"
                                        onClick={() =>
                                          handleDeletePayment(index)
                                        }
                                      >
                                        <img
                                          src="assets/img/delete-icon.webp"
                                          alt=""
                                        />
                                      </a>
                                    </td>
                                  </tr>
                                </>
                              ))}
                              <tr>
                                <td colSpan={3}>Total</td>
                                <td>${GrandTotals || 0}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="pending_amt">
                        <span>(Due: ${remainingAmount.toFixed(2) || 0})</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-lg-7 col-md-12">
                <div className="cus_table cash_register_table">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th colSpan="2">ITEM(s)</th>
                          <th>In stock</th>
                          <th>Qty.</th>
                          <th>Unit Price</th>
                          <th>Total Price</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {selectedProduct?.length > 0 ? (
                          <>
                            {renderProductRows(selectedProduct, 1)}
                            {[
                              ...Array(Math.max(3 - selectedProduct.length, 0)),
                            ].map((_, index) => (
                              <tr key={`default_${index}`}>
                                <td className="itemnocstm">
                                  <span className="item_no ">
                                    #{selectedProduct.length + index + 1}
                                  </span>
                                </td>
                                <td>
                                  <a href="#">
                                    <span></span>
                                  </a>
                                </td>
                                <td></td>
                                <td>
                                  <div className="quantity_btn">
                                    {/* <a href="#" className="qty_minus">
                                      <img
                                        src="assets/img/minus-icon.webp"
                                        alt=""
                                      />
                                    </a>
                                    <input
                                      type="text"
                                      className="qty_count"
                                      value="0"
                                    />
                                    <a href="#" className="qty_plus">
                                      <img
                                        src="assets/img/plus-icon.webp"
                                        alt=""
                                      />
                                    </a> */}
                                  </div>
                                </td>
                                <td></td>
                                <td></td>
                                <td>
                                  <div className="action_btn">
                                    {/* <a
                                      href="#"
                                      data-toggle="modal"
                                      data-target="#editinfoModal"
                                      className="edit_btn"
                                    >
                                      <img
                                        src="assets/img/edit-icon.webp"
                                        alt=""
                                      />
                                    </a>
                                    <a href="#" className="delete_btn">
                                      <img
                                        src="assets/img/delete-icon.webp"
                                        alt=""
                                      />
                                    </a> */}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          // Render default rows if no retrieved products
                          <>
                            {[...Array(3)].map((_, index) => (
                              <tr key={`default_${index}`}>
                                <td className="itemnocstm">
                                  <span className="item_no ">#{index + 1}</span>
                                </td>
                                <td>
                                  <a href="#">
                                    <span></span>
                                  </a>
                                </td>
                                <td></td>
                                <td>
                                  <div className="quantity_btn">
                                    {/* <a href="#" className="qty_minus">
                                      <img
                                        src="assets/img/minus-icon.webp"
                                        alt=""
                                      />
                                    </a>
                                    <input
                                      type="text"
                                      className="qty_count"
                                      value="0"
                                    />
                                    <a href="#" className="qty_plus">
                                      <img
                                        src="assets/img/plus-icon.webp"
                                        alt=""
                                      />
                                    </a> */}
                                  </div>
                                </td>
                                <td></td>
                                <td></td>
                                <td>
                                  <div className="action_btn">
                                    {/* <a
                                      href="#"
                                      data-toggle="modal"
                                      data-target="#editinfoModal"
                                      className="edit_btn"
                                    >
                                      <img
                                        src="assets/img/edit-icon.webp"
                                        alt=""
                                      />
                                    </a>
                                    <a href="#" className="delete_btn">
                                      <img
                                        src="assets/img/delete-icon.webp"
                                        alt=""
                                      />
                                    </a> */}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </tbody>

                      <tfoot>
                        <tr className="price_tr">
                          <td colSpan="3">Sub Total</td>
                          <td></td>
                          {overallTotal ? (
                            <td colSpan="3">${overallTotal}</td>
                          ) : (
                            <td colSpan="3">$0.00</td>
                          )}
                        </tr>
                        <tr className="price_tr">
                          <td colSpan="3">
                            <span>Discount:</span>
                            <select
                              className="tble_select"
                              onChange={handleDiscountChange}
                              value={discount}
                            >
                              <option value={0}>0%</option>
                              {alldiscount?.map((item) => (
                                <>
                                  {" "}
                                  <option key={item?.id} value={item?.rate}>
                                    {item?.rate}%
                                  </option>
                                </>
                              ))}
                            </select>
                          </td>
                          <td></td>
                          <td colSpan="3">
                            $
                            {parseFloat(
                              overallTotal - discountedSubtotal
                            ).toFixed(2)}
                          </td>
                        </tr>
                        <tr className="price_tr">
                          <td colSpan="3">
                            <span>Tax:</span>
                            <select
                              className="tble_select"
                              onChange={handleTaxChange}
                              value={tax}
                            >
                              {defaultTax ? (
                                <option value={defaultTax.rate}>
                                  {defaultTax.name} {defaultTax.rate}%
                                </option>
                              ) : (
                                <option value={0}>Tax 0%</option>
                              )}
                              {alltax?.map((item, index) => (
                                <>
                                  {" "}
                                  <option key={item?.id} value={item?.rate}>
                                    {item?.name} {item?.rate}%
                                  </option>
                                </>
                              ))}
                            </select>
                          </td>
                          <td></td>
                          <td colSpan="3">${taxAmount.toFixed(2)}</td>
                        </tr>
                        <tr className="grand_total">
                          <td colSpan="3">Grand Total</td>
                          <td></td>
                          {overallTotal1 ? (
                            <td colSpan="3">${overallTotal1.toFixed(2)}</td>
                          ) : (
                            <td colSpan="3">$0.00</td>
                          )}
                        </tr>
                      </tfoot>
                    </table>
                  </div>

                  <div className="payment_method">
                    {paymentState === true ? (
                      <>
                        <div className="payment_mode">
                          <div className="inner_payment_mode">
                            <div className="mode_title">
                              <span>Payment Made</span>
                            </div>
                            <div className="mode_table">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Time</th>
                                    <th>Type</th>
                                    <th>Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {paymentMode?.map((payment, index) => (
                                    <>
                                      <tr key={index}>
                                        <td>
                                          {moment(new Date()).format(
                                            "DD/MM/YYYY hh:mm a"
                                          )}
                                        </td>
                                        <td>
                                          <b>{payment?.paymentType}</b>
                                        </td>
                                        <td>
                                          <b>
                                            ${payment?.payAmount.toFixed(2)}
                                          </b>
                                        </td>
                                      </tr>
                                    </>
                                  ))}
                                  <tr>
                                    <td colSpan={3}>Total</td>
                                    <td>${totalPayment.toFixed(2)}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="pending_amt">
                            <span>(Due: ${remainingAmount.toFixed(2)})</span>
                          </div>
                        </div>
                      </>
                    ) : null}
                    {props?.status1 === "Invoiced" ||
                    props?.status1 === "Cancelled" ? null : (
                      <>
                        <div className="inner_payment_method">
                          <div className="cash_dropdown payment_col">
                            <select
                              className="form-control cashpaymentcustmcss"
                              value={selectedOptions}
                              onChange={handleOptionChange}
                            >
                              {allPaymentOptions?.length > 0 ? (
                                allPaymentOptions?.map((item) => (
                                  <option key={item.id} value={item.name}>
                                    {item.name}
                                  </option>
                                ))
                              ) : (
                                <option value="">Payment Options</option>
                              )}
                            </select>

                            <span className="angle_down">
                              <img
                                src="assets/img/angledown-icon.webp"
                                alt=""
                              />
                            </span>
                          </div>

                          <input
                            type="number"
                            value={inputValue}
                            className="price_value payment_col no-arrow-input"
                            style={{
                              fontWeight: 700,
                              color: "black",
                              fontSize: 18,
                            }}
                            onChange={(e) => {
                              setInputValue(e.target.value);
                            }}
                          />

                          {inputValue !== 0 && selectedProduct?.length > 0 ? (
                            <div className="pay_btn payment_col">
                              <a
                                onClick={handlePaymentClick}
                                style={{ cursor: "pointer" }}
                              >
                                Pay ${inputValue}
                                {/* <a
                          onClick={() => {
                            if (selectedProduct?.length > 0) {
                              handlePay();
                            } else {
                              handlePayRet();
                            }
                          }}
                          //disabled={totalPayment === overallTotal1}
                        > */}
                              </a>{" "}
                            </div>
                          ) : (
                            <div className=" pay_btn payment_col ">
                              <a
                                style={{ cursor: "pointer" }}
                                className="apaybtncstm"
                                href="#"
                                onClick={() => {
                                  Swal.fire("Please Select Product First");
                                }}
                              >
                                Pay $0.00
                              </a>{" "}
                            </div>
                          )}
                        </div>{" "}
                        {ErrorAmount === true ? (
                          <span
                            style={{
                              fontSize: "15px",
                              color: "red",
                              fontWeight: "600",
                              marginTop: "20px",
                              textAlign: "center",
                            }}
                          >
                            Amount Should{" "}
                            <i className="fa-solid fa-greater-than"></i> 0
                          </span>
                        ) : null}
                      </>
                    )}
                  </div>
                  {props?.status1 === "Invoiced" ||
                  props?.status1 === "Cancelled" ? null : (
                    <>
                      <div className="complete_pross_btn">
                        {totalPayment.toFixed(2) == overallTotal1.toFixed(2) &&
                        props?.status === "Finished" ? (
                          <>
                            {" "}
                            <a
                              href="#"
                              // data-toggle="modal"
                              // data-target="#paymentModal"
                              className="complete_btn1"
                              // className={`complete_btn ${
                              //   totalPayment === overallTotal1 ? "complete_btn1" : ""
                              // }`}
                              onClick={(e) => {
                                setErrorAmount(false);
                                e.preventDefault();
                                if (selectedProduct?.length > 0) {
                                  // setShowModal9(true);
                                  AddInvoice();
                                } else {
                                  Swal.fire("Please Select Product First");
                                }
                              }}
                            >
                              Complete
                            </a>
                            <a
                              href="#"
                              className="close_btn"
                              onClick={(e) => {
                                setSelectedProduct([]);
                                e.preventDefault();
                              }}
                            >
                              <img src="assets/img/close-icon.webp" alt="" />
                            </a>
                          </>
                        ) : (
                          <>
                            <a
                              href="#"
                              // data-toggle="modal"
                              // data-target="#paymentModal"
                              className="complete_btn"
                              onClick={(e) => {
                                e.preventDefault();
                                if (props?.status !== "Finished") {
                                  Swal.fire({
                                    title: "Please Select Status Finished!",
                                    customClass: {
                                      container: "custom-swal-container",

                                      confirmButton:
                                        "custom-swal-confirm-button",
                                    },
                                  });
                                  // setShowModal9(true);
                                  // AddInvoice();
                                } else {
                                  Swal.fire("Please Select Product First");
                                }
                              }}
                            >
                              Complete
                            </a>
                            <a
                              href=""
                              className="close_btn"
                              // onClick={() => {
                              //   setSelectedProduct([]);
                              //   setSelectedRetrieveProduct([]);
                              // }}
                            >
                              <img src="assets/img/close-icon.webp" alt="" />
                            </a>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>{" "}
            </>
          )}
        </div>
        <div className="bottom_btns">
          <div className="row">
            <div className="col-md-12">
              <div className="bottom_links">
                <ul>
                  <li>
                    <a href="#">--</a>
                  </li>
                  <li>
                    <a href="#">--</a>
                  </li>
                  <li
                    onClick={() => {
                      if (selectedProduct.length > 0) {
                        setShowModal6(true);
                        setDescriptionNote("");
                      } else {
                        setShowModal6(false);
                        setDescriptionNote("");
                      }
                    }}
                  >
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target="#parksaleModal1"
                    >
                      Note
                    </a>
                  </li>
                  <li>
                    <a href="#">Signature</a>
                  </li>

                  <li>
                    <a
                      href="#"
                      onClick={() => {
                        HandleRefresh();
                        // setSelectedProduct([]);
                        // setSelectedRetrieveProduct([]);
                      }}
                    >
                      Start Over
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

      {showModal6 === true ? (
        <>
          <div
            className="modal common_modal parksale_modal"
            id="parksaleModal1"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="parksaleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="parksaleModalLabel">
                    Note
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setShowModal6(false);
                      setDescriptionNote("");

                      document
                        .querySelectorAll(".modal-backdrop")
                        .forEach((el) => el.classList.remove("modal-backdrop"));
                    }}
                  >
                    <span className="close_icon"></span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="custom_form parksale_form">
                    <form action="" method="" className="cus_form">
                      <div className="form_row">
                        <div className="form_column col_full">
                          <div className="form-group">
                            <textarea
                              className="form-control"
                              name="address"
                              placeholder="Add Note"
                              value={descriptionNote}
                              onChange={handleDescriptionChange}
                            ></textarea>{" "}
                            {errormsg && (
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: "red",
                                }}
                              >
                                {errormsg}
                              </span>
                            )}
                            <span className="span_note">
                              Max Characters 300
                            </span>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary theme_btn"
                    onClick={() => {
                      setShowModal6(false);
                      // setDescriptionNote("");

                      document
                        .querySelectorAll(".modal-backdrop")
                        .forEach((el) => el.classList.remove("modal-backdrop"));
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {showModal10 === true ? (
        <>
          {" "}
          <div
            className="modal common_modal cases_modal"
            id="casesModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="casesModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="casesModalLabel">
                    Products
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setShowModal10(false);

                      setProductByCategory([]);
                    }}
                  >
                    <span className="close_icon"></span>
                  </button>
                  {/* {loading2 ? (
                      <p>Loading...</p>
                    ) : productByCategory.length > 0 ? (
                      <p>Select Product:</p>
                    ) : (
                      <p>No Products available</p>
                    )} */}
                  {/* {productByCategory.length > 0 ? (
                      <p> Select Product:</p>
                    ) : (
                      <p>No Products available</p>
                    )} */}
                  <p> Select Product:</p>
                </div>
                {loading2 ? <p>Loading...</p> : null}
                <div className="modal-body">
                  <div className="cases_list">
                    <ul>
                      {productByCategory?.map((item, index) => (
                        <li
                          onClick={() => {
                            handleCustomerSelectionProduct(
                              item,
                              item?.imeiNumber
                            );
                            setShowModal10(false);
                            setProductByCategory([]);
                            document
                              .querySelectorAll(".modal-backdrop")
                              .forEach((el) =>
                                el.classList.remove("modal-backdrop")
                              );
                          }}
                        >
                          <a href="#">
                            {ImageOnOff === 1 ? (
                              item?.image ? (
                                <img src={item?.image} alt="" />
                              ) : (
                                <img
                                  src="assets/img/casemodal-img.png"
                                  alt=""
                                />
                              )
                            ) : null}
                            <span>{item?.title}</span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {showModal3 === true ? (
        <>
          <div
            className="modal common_modal newproduct_modal"
            id="newproductModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="newproductModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="newproductModalLabel">
                    New Product
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      {
                        setShowModal3(false);

                        document
                          .querySelectorAll(".modal-backdrop")
                          .forEach((el) =>
                            el.classList.remove("modal-backdrop")
                          );
                      }
                    }}
                  >
                    <span className="close_icon"></span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="product_list">
                    <div
                      className="product_col"
                      onClick={() => {
                        setShowModal4(true);
                        setShowModal3(false);
                      }}
                    >
                      <a
                        href="#"
                        data-toggle="modal"
                        data-target="#createproductModal"
                      >
                        <h5>Standard</h5>
                        <p>
                          Most Physical product and services including parts,
                          except devices with Imei/ESN
                        </p>
                      </a>
                    </div>
                    <div
                      className="product_col"
                      onClick={() => {
                        setShowModal5(true);
                        setShowModal3(false);
                      }}
                    >
                      <a
                        href="#"
                        data-toggle="modal"
                        data-target="#createdeviceModal"
                      >
                        <h5>Devices</h5>
                        <p>Products with serial, IMEI, ESN, MEID</p>
                      </a>
                    </div>
                    <div className="product_col coming_product">
                      <a href="#">
                        <img src="assets/img/comingsoon-img.webp" alt="" />
                        <h5>Coming Soon...</h5>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {showModal === true ? (
        <>
          <div
            className="modal common_modal editinfo_modal"
            id="editinfoModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="editinfoModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="editinfoModalLabel">
                    Edit Information
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      resetFormValues(); // Reset form values
                      setShowModal(false); // Close the modal
                    }}
                  >
                    <span className="close_icon"></span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="custom_form editinfo_form">
                    <form action="" method="" className="cus_form">
                      <div className="form_row">
                        <div className="form_column col_three">
                          <div className="form_field price_field">
                            <div className="form-group">
                              <label>Unit Price</label>
                              <input
                                type="number"
                                className="form-control no-arrow-input"
                                name="unit_price"
                                placeholder="Enter Price"
                                value={
                                  unitCosts[editedProductIndex] === 0
                                    ? ""
                                    : unitCosts[editedProductIndex]
                                }
                                onChange={(e) =>
                                  setUnitCosts((prevUnitCosts) => {
                                    const newUnitCosts = [...prevUnitCosts];
                                    newUnitCosts[editedProductIndex] =
                                      e.target.value;
                                    return newUnitCosts;
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div className="form_column col_three">
                          <div className="form_field quantity_field">
                            <div className="form-group">
                              <label> Sales Quantity</label>
                              <input
                                type="number"
                                className="form-control no-arrow-input"
                                name="quantity"
                                placeholder="Enter Quantity"
                                value={
                                  receivedQuantities[editedProductIndex] === 0
                                    ? receivedQuantities
                                    : receivedQuantities[editedProductIndex]
                                }
                                onChange={(e) =>
                                  setReceivedQuantities(
                                    (prevReceivedQuantities) => {
                                      const newReceivedQuantities = [
                                        ...prevReceivedQuantities,
                                      ];
                                      newReceivedQuantities[
                                        editedProductIndex
                                      ] = e.target.value;
                                      return newReceivedQuantities;
                                    }
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form_column col_three">
                          <div className="form_field discount_field">
                            <div className="form-group">
                              <label>Discount</label>
                              <input
                                type="text"
                                className="form-control"
                                name="discount"
                                placeholder="Enter Discount"
                                value={discounts[editedProductIndex]}
                                onChange={(e) => {
                                  const newDiscounts = [...discounts];
                                  newDiscounts[editedProductIndex] =
                                    e.target.value;
                                  setDiscounts(newDiscounts);
                                }}
                              />
                              <div className="price_type">
                                <h5>%</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form_row">
                          <div className="form_column col_full">
                            <div className="form_field note_field">
                              <div className="form-group">
                                {/* <label>Note:</label> */}
                                <textarea
                                  className="form-control"
                                  name="note"
                                  placeholder="Enter your note"
                                  value={
                                    note[editedProductIndex] === 0
                                      ? ""
                                      : note[editedProductIndex]
                                  }
                                  onChange={handleDescriptionChangeNote}
                                ></textarea>
                                <span className="span_note">
                                  Max Characters 300
                                </span>
                                {errormsg && (
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      color: "red",
                                    }}
                                  >
                                    {errormsg}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary default_btn"
                    data-dismiss="modal"
                    //onClick={() => setShowModal(false)}
                    onClick={() => {
                      resetFormValues(); // Reset form values
                      setShowModal(false); // Close the modal
                    }}
                  >
                    Cancel
                  </button>
                  {selectedProduct.length ? (
                    <button
                      type="button"
                      className="btn btn-primary theme_btn"
                      data-dismiss="modal"
                      onClick={() => handleUpdate(setSelectedProduct)}
                    >
                      Update Selected Product
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary theme_btn"
                      data-dismiss="modal"
                      onClick={() => handleUpdate(setSelectedRetrieveProduct)}
                    >
                      Update Retrieved Product
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {showModal4 === true ? (
        <>
          <div
            className="modal common_modal createproduct_modal"
            id="createproductModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="createproductModalLabel"
            aria-hidden="true"
          >
            <TicketProductModal api={GetAllProducts} />
          </div>
        </>
      ) : null}

      {showModal5 === true ? (
        <>
          <div
            className="modal common_modal createdevice_modal"
            id="createdeviceModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="createdeviceModalLabel"
            aria-hidden="true"
          >
            <DeviceModal />
          </div>
        </>
      ) : null}
      {showModal9 && (
        <>
          <div
            className="modal common_modal payment_modal modal fade show"
            id="paymentModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="paymentModalLabel"
            aria-hidden="true"
            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
            // className={`modal common_modal payment_modal ${
            //   showModal9 ? "active" : ""
            // }`}
            // id="paymentModal"
            // tabIndex={-1}
            // role="dialog"
            // aria-labelledby="paymentModalLabel"
            // aria-hidden="true"
            // data-backdrop="static" // Prevent closing when clicking outside
            // data-keyboard="false" // Prevent closing when pressing escape
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setSelectedProduct([]);
                      setSelectedRetrieveProduct([]);
                      setPaymentMode([]);
                      setPaymnetState(false);
                      setSelectedCustomer({});
                      setShowModal9(false);
                      setSelectedState(false);
                      setDiscounts([]);
                      updateTotalSalesQuantity();
                      setTotalSalesQty(0);
                      setReceivedQuantities([]);
                      setSelectedOptions("");
                      setSelectedPaymentType("");
                      setSelectedPaymentTypeId("");
                      setSelectedCategory2("");
                      setDiscount(0);
                      setUnitCosts([]);
                      // setSelectedProduct([]);
                      // setSelectedRetrieveProduct([]);
                      // setPaymentMode([]);
                      // setPaymnetState(false);
                      // setSelectedCustomer({});
                      // setShowModal9(false);
                      // setSelectedState(false);
                      // setDiscounts([]);
                      document
                        .querySelectorAll(".modal-backdrop")
                        .forEach((el) => el.classList.remove("modal-backdrop"));
                      navigate("/ticketlist");
                    }}
                  >
                    <span className="close_icon" />
                  </button>
                </div>
                <div className="modal-body">
                  <div className="payment_receipt_btns">
                    <div className="receipt_btn">
                      <div className="receipt_col colhalf">
                        {selectedCustomer?.firstName &&
                        selectedCustomer?.email ? (
                          <>
                            <a
                              href="#"
                              className="recept_btn recpt_email_btn"
                              onClick={() => {
                                EmailSend();
                              }}
                            >
                              Email
                            </a>
                          </>
                        ) : (
                          <>
                            <a
                              href="#"
                              data-toggle="modal"
                              data-target="#parksaleModal"
                              className="recept_btn recpt_email_btn"
                              onClick={() => {
                                setShowModal11(true);
                                //  EmailSend();
                              }}
                            >
                              Email
                            </a>
                          </>
                        )}
                      </div>
                      <div className="receipt_col colhalf">
                        <a
                          href="#"
                          data-dismiss="modal"
                          aria-label="Close"
                          className="recept_btn recpt_noreceipt_btn"
                          onClick={() => {
                            setSelectedProduct([]);
                            setSelectedRetrieveProduct([]);
                            setPaymentMode([]);
                            setPaymnetState(false);
                            setSelectedCustomer({});
                            setShowModal9(false);
                            setSelectedState(false);
                            setDiscounts([]);
                            updateTotalSalesQuantity();
                            setTotalSalesQty(0);
                            setReceivedQuantities([]);
                            setSelectedOptions("");
                            setSelectedPaymentType("");
                            setSelectedPaymentTypeId("");
                            setSelectedCategory2("");
                            setDiscount(0);
                            setUnitCosts([]);
                            document
                              .querySelectorAll(".modal-backdrop")
                              .forEach((el) =>
                                el.classList.remove("modal-backdrop")
                              );
                            navigate("/ticketlist");
                          }}
                        >
                          No Receipt
                        </a>
                      </div>

                      <div className="receipt_col colfull">
                        {printType === 2 || reciept === true ? (
                          <a
                            href="#"
                            className="recept_btn recpt_print_btn"
                            onClick={printSetup}
                          >
                            Print Receipt
                          </a>
                        ) : (
                          <a
                            href="#"
                            className="recept_btn recpt_print_btn"
                            onClick={printTable}
                          >
                            Print Receipt
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {showModal11 === true ? (
        <>
          <div
            className="modal common_modal parksale_modal"
            id="parksaleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="parksaleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="parksaleModalLabel">
                    Email
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setShowModal11(false);
                      setEmail("");

                      document
                        .querySelectorAll(".modal-backdrop")
                        .forEach((el) => el.classList.remove("modal-backdrop"));
                    }}
                  >
                    <span className="close_icon"></span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="custom_form parksale_form">
                    <form className="cus_form">
                      <div className="form_row">
                        <div className="form_column col_full">
                          <div className="form-group">
                            <input
                              type="email"
                              className="form-control"
                              name="address"
                              placeholder="Enter Email"
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value.trim());
                                if (error.email) {
                                  setError({ ...error, email: "" });
                                }
                              }}
                            />
                            {error.email && (
                              <span className="customvalidationdesignlogin">
                                {error.email}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary theme_btn"
                    onClick={() => {
                      OnSubmit();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      <div style={{ display: "none" }}>
        <div className="reciepttable">
          <div
            style={{
              width: "80mm",
              // margin: "auto",
              // padding: "auto",
              fontFamily: "Arial, sans-serif",
              height: "auto",
              fontSize: 12,
              // leftMargin: `${leftMargin}px`,
              // rightMargin: `${rightMargin}px`,
            }}
            border={0}
          >
            <div>
              <div>
                <div>
                  <div
                    style={{ width: "100%", textAlign: "center" }}
                    border={0}
                  >
                    <div>
                      <div>
                        <div>
                          {isLogo && logo ? (
                            <div
                              style={{
                                textAlign:
                                  logoPosition === 0 ? "left" : "center",
                                marginBottom: 25,
                              }}
                            >
                              <img
                                src={logo}
                                alt="Logo"
                                style={{
                                  width: logoSize === 1 ? 300 : 50,
                                  height: logoSize === 1 ? 110 : 55,
                                  // objectFit: "cover",
                                  // display: "block",
                                  margin: "0 auto",
                                }}
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            fontSize: 12,
                            fontWeight: 700,

                            color: "#000",
                          }}
                        >
                          {title ? title : "--"}
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            fontSize: 12,
                            fontWeight: 500,
                            marginTop: 20,
                            color: "#000",
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: header
                                ? header.trim().replace(/\n/g, "<br>")
                                : "--",
                            }}
                          ></div>
                          {/* {" "}
                          <pre
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              color: "#000",
                              //marginTop: 20,
                            }}
                          >
                            {header ? header : "--"}
                          </pre> */}
                        </div>
                      </div>
                      <div>
                        <div style={{ padding: "8px 0px" }} />
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div style={{ width: "100%" }} border={0}>
                    <div>
                      <div>
                        <div
                          style={{
                            fontSize: 12,
                            fontWeight: 400,

                            color: "#000",
                          }}
                        >
                          <span style={{ fontWeight: 700 }}>Sales Ref:</span> #
                          {InvoiceData1?.id}
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            fontSize: 12,
                            fontWeight: 400,
                            //lineHeight: 20,
                            color: "#000",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: 700,
                            }}
                          >
                            Customer:
                          </span>{" "}
                          {customerDetails1.fullname === 1 &&
                          InvoiceData1?.customer?.firstName
                            ? `${InvoiceData1?.customer?.firstName}`
                            : "unassigned"}
                          {customerDetails1.email === 1 &&
                          InvoiceData1?.customer?.email ? (
                            <>
                              {" "}
                              <br />
                              <span
                                style={{
                                  fontWeight: 700,
                                  marginTop: 28,
                                }}
                              >
                                Email:
                              </span>
                              {InvoiceData1?.customer?.email}
                            </>
                          ) : null}
                          {customerDetails1.phone === 1 &&
                          InvoiceData1?.customer?.phoneNumber ? (
                            <>
                              <br />
                              <span
                                style={{
                                  fontWeight: 700,
                                  marginTop: 28,
                                }}
                              >
                                Phone No.:
                              </span>

                              {InvoiceData1?.customer?.phoneNumber
                                ? InvoiceData1?.customer?.phoneNumber
                                : "--"}
                            </>
                          ) : null}
                          {customerDetails1.address === 1 &&
                          InvoiceData1?.customer?.streetAdress ? (
                            <>
                              <br />
                              <span
                                style={{
                                  fontWeight: 700,
                                  marginTop: 28,
                                }}
                              >
                                Address:
                              </span>

                              {InvoiceData1?.customer?.streetAdress
                                ? InvoiceData1?.customer?.streetAdress
                                : "--"}
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            fontSize: 12,
                            fontWeight: 400,
                            marginTop: 0,
                            color: "#000",
                          }}
                        >
                          {moment(InvoiceData1.createdAt, "YYYY/MM/DD").format(
                            "DD/MM/YYYY"
                          )}
                          {"  "}
                          {moment(InvoiceData1?.createdAt).format("LT")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div style={{ padding: "4px 0px" }} />
              </div>
              <div>
                <div>
                  <table
                    className="tabledata"
                    style={{ width: "100%" }}
                    border={0}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            fontSize: 12,
                            fontWeight: 700,
                            marginTop: 20,
                            color: "#000",
                            textAlign: "center",
                            borderBottom: "2px dashed #000",
                            padding: 4,
                          }}
                        >
                          QTY
                        </th>
                        <th
                          style={{
                            fontSize: 12,
                            fontWeight: 700,
                            marginTop: 20,
                            color: "#000",
                            textAlign: "left",
                            borderBottom: "2px dashed #000",
                            padding: 4,
                          }}
                        >
                          Item Name
                        </th>
                        <th
                          style={{
                            fontSize: 12,
                            fontWeight: 700,
                            marginTop: 20,
                            color: "#000",
                            textAlign: "right",
                            borderBottom: "2px dashed #000",
                            padding: 4,
                          }}
                        >
                          Discount
                        </th>
                        <th
                          style={{
                            fontSize: 12,
                            fontWeight: 700,
                            marginTop: 20,
                            color: "#000",
                            textAlign: "right",
                            borderBottom: "2px dashed #000",
                            padding: 4,
                          }}
                        >
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Items.map((item, index) => {
                        const correspondingItem = ItemData?.find(
                          (itemId) => itemId?.id === item?.itemId
                        );
                        const subtotal = parseFloat(InvoiceData1.subTotal);
                        const discountPercentage = parseFloat(
                          InvoiceData1.discount
                        );
                        const taxRate = parseFloat(InvoiceData1.tax);

                        const discountedSubtotal =
                          subtotal * (1 - discountPercentage / 100);
                        const taxAmount = discountedSubtotal * (taxRate / 100);
                        const overallTotal1 = discountedSubtotal + taxAmount;
                        return (
                          <tr>
                            <td
                              style={{
                                fontSize: 12,
                                fontWeight: 500,
                                marginTop: 20,
                                color: "#000",
                                textAlign: "center",
                                padding: 4,
                              }}
                            >
                              {item?.quantity}
                            </td>
                            <td
                              style={{
                                fontSize: 12,
                                fontWeight: 500,
                                marginTop: 20,
                                color: "#000",
                                textAlign: "left",
                                padding: 4,
                              }}
                            >
                              {selectedOption?.prodname === 1 &&
                              selectedOption?.sku === 0 &&
                              selectedOption?.prodname_sku === 0
                                ? correspondingItem?.title
                                : ""}
                              {selectedOption?.sku === 1 &&
                              selectedOption?.prodname === 0 &&
                              selectedOption?.prodname_sku === 0
                                ? correspondingItem?.skuCode
                                : ""}
                              {selectedOption?.prodname_sku === 1 &&
                              selectedOption?.prodname === 1 &&
                              selectedOption?.sku === 1
                                ? `${correspondingItem?.title}(${correspondingItem?.skuCode})`
                                : ""}
                              {/* {selectedOption.prodname === 1
                                ? correspondingItem?.title
                                : "--"} */}
                            </td>
                            <td
                              style={{
                                fontSize: 12,
                                fontWeight: 500,
                                marginTop: 20,
                                color: "#000",
                                textAlign: "right",
                                padding: 4,
                              }}
                            >
                              {item?.discount}
                            </td>
                            <td
                              style={{
                                fontSize: 12,
                                fontWeight: 500,
                                marginTop: 20,
                                color: "#000",
                                textAlign: "right",
                                padding: 4,
                              }}
                            >
                              {item?.total}
                            </td>
                          </tr>
                        );
                      })}

                      <tr>
                        <td
                          style={{
                            fontSize: 12,
                            fontWeight: 500,
                            // lineHeight: 20,
                            color: "#000",
                            textAlign: "center",
                            padding: 4,
                            marginTop: 20,
                            borderBottom: "2px dashed #000",
                          }}
                        ></td>
                        <td
                          style={{
                            fontSize: 12,
                            fontWeight: 500,
                            //lineHeight: 20,
                            color: "#000",
                            textAlign: "left",
                            padding: 4,
                            marginTop: 20,
                            borderBottom: "2px dashed #000",
                          }}
                        ></td>
                        <td
                          style={{
                            fontSize: 12,
                            fontWeight: 500,
                            //lineHeight: 20,
                            color: "#000",
                            textAlign: "right",
                            padding: 4,
                            marginTop: 20,
                            borderBottom: "2px dashed #000",
                          }}
                        ></td>
                        <td
                          style={{
                            fontSize: 12,
                            fontWeight: 500,
                            //lineHeight: 20,
                            color: "#000",
                            textAlign: "right",
                            padding: 4,
                            marginTop: 20,
                            borderBottom: "2px dashed #000",
                          }}
                        ></td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td
                          style={{
                            fontSize: 12,
                            fontWeight: 500,
                            marginTop: 20,
                            color: "#000",
                            textAlign: "left",
                            padding: 4,
                          }}
                          colSpan={2}
                        >
                          Subtotal
                        </td>
                        <td
                          style={{
                            fontSize: 12,
                            fontWeight: 500,
                            marginTop: 20,
                            color: "#000",
                            textAlign: "right",
                            padding: 4,
                          }}
                          colSpan={2}
                        >
                          ${InvoiceData1?.subTotal}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontSize: 12,
                            fontWeight: 400,
                            marginTop: 20,
                            color: "#000",
                            padding: 4,
                          }}
                          colSpan={2}
                        >
                          Discounts ({InvoiceData1.discount || 0}%)
                        </td>
                        <td
                          style={{
                            fontSize: 12,
                            fontWeight: 500,
                            marginTop: 20,
                            color: "#000",
                            textAlign: "right",
                            padding: 4,
                          }}
                          colSpan={2}
                        >
                          {" "}
                          $
                          {(
                            parseFloat(InvoiceData1?.subTotal) -
                            parseFloat(discountedSubtotal)
                          ).toFixed(2) || 0}
                          {/* ${discountedSubtotal.toFixed(2)} */}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontSize: 12,
                            fontWeight: 500,
                            marginTop: 20,
                            color: "#000",
                            textAlign: "left",
                            padding: 4,
                          }}
                          colSpan={2}
                        >
                          Tax ({InvoiceData1.tax || 0}%)
                        </td>
                        <td
                          style={{
                            fontSize: 12,
                            fontWeight: 500,
                            marginTop: 20,
                            color: "#000",
                            textAlign: "right",
                            padding: 4,
                          }}
                          colSpan={2}
                        >
                          ${taxAmount.toFixed(2) || 0}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontSize: 12,
                            fontWeight: 700,
                            marginTop: 20,
                            color: "#000",
                            textAlign: "left",
                            borderBottom: "2px dashed #000",
                            padding: 4,
                          }}
                          colSpan={2}
                        >
                          Grand Total
                        </td>
                        <td
                          style={{
                            fontSize: 12,
                            fontWeight: 700,
                            marginTop: 20,
                            color: "#000",
                            textAlign: "right",
                            borderBottom: "2px dashed #000",
                            padding: 4,
                          }}
                          colSpan={2}
                        >
                          ${overallTotal1.toFixed(2)}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
              <div>
                <div style={{ padding: "8px 0px" }} />
              </div>
              <div>
                <div>
                  <table style={{ width: "100%" }} border={0}>
                    <tbody>
                      {payment.map((item) => (
                        <>
                          <tr>
                            <td
                              style={{
                                fontSize: 12,
                                fontWeight: 400,
                                //lineHeight: 20,
                                color: "#000",
                                marginTop: 20,
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: 600,
                                }}
                              >
                                Received:
                              </span>{" "}
                              ${item.payAmount}
                            </td>
                            <td
                              style={{
                                fontSize: 12,
                                fontWeight: 400,
                                //lineHeight: 20,
                                color: "#000",
                                marginTop: 20,
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: 700,
                                }}
                              >
                                {item.paymentType}
                                {"  "}
                              </span>
                              {item.date}{" "}
                              {moment(item.time, "HH:mm:ss").format("hh:mm A")}{" "}
                            </td>
                          </tr>{" "}
                        </>
                      ))}

                      <tr>
                        <td
                          style={{
                            fontSize: 12,
                            fontWeight: 400,
                            marginTop: 20,
                            color: "#000",
                          }}
                          colSpan={2}
                        >
                          Change Given:{" "}
                          <span style={{ fontWeight: 700 }}>
                            ${InvoiceData1?.due}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontSize: 12,
                            fontWeight: 400,
                            marginTop: 20,
                            color: "#000",
                          }}
                          colSpan={2}
                        >
                          <span style={{ fontWeight: 700 }}>Cashier:</span>{" "}
                          {(adminState === 0 || permission.length > 0) &&
                            dataUser?.userDetails?.name}
                          {(adminState === 1 || permission.length === 0) &&
                            data1?.userDetails?.userName}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div>
                <div style={{ padding: "12px 0px" }} />
              </div>
              <div>
                <div>
                  <div
                    style={{ width: "100%", textAlign: "center" }}
                    border={0}
                  >
                    <div>
                      <div>
                        <div
                          style={{
                            textAlign: "justify",
                            fontSize: "12px",
                            fontWeight: "400",
                            color: "#000",
                            whiteSpace: "pre-wrap",
                            // Preserve whitespace and allow wrapping
                            wordWrap: "break-word", // Allow long words to break
                            maxWidth: "100%",
                          }}
                          // style={{
                          //   textAlign: "center",
                          //   fontSize: "12px",
                          //   fontWeight: "400",
                          //   color: "#000",
                          // }}
                        >
                          <pre
                            style={{
                              textAlign: "justify",
                              fontSize: "12px",
                              fontWeight: 400,
                              color: " #000",
                              whiteSpace: "pre-wrap",
                              wordWrap: "break-word",
                              maxWidth: "100%",
                            }}
                          >
                            {footer ? footer : "--"}
                          </pre>
                          {/* <div
                          style={{
                            fontSize: 12,
                            fontWeight: 400,
                            marginTop: 20,
                            color: "#000",
                          }}
                        >
                          <pre> {footer ? footer : "--"}</pre> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div style={{ padding: "8px 0px" }} />
              </div>
              <div>
                <div>
                  <div
                    style={{ width: "100%", textAlign: "center" }}
                    border={0}
                  >
                    <div>
                      {gstShow === 1 ? (
                        <div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 400,
                              marginTop: 20,
                              color: "#000",
                            }}
                          >
                            Hst #: {gstNumber ? gstNumber : "--"}
                          </div>
                        </div>
                      ) : null}
                      {/* <div>
                        <div>
                          <img src="img/receipt-barcode.jpg" alt="" />
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketCash;
