import React, { useEffect, useState } from "react";
import $ from "jquery";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Footer from "../Footer";
import SideUl from "../MainDashboard/SideUl";
import MainSidebar from "../MainDashboard/MainSidebar";
import { baseurl, customerEditApi, uploadImageApi } from "../Utility/Api";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Swal from "sweetalert2";
import moment from "moment";
import FreeTrail from "../MainDashboard/FreeTrail";

const CustomerDetail = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [images, setImages] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [city, SetCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [state, setState] = useState("");
  const [error, setError] = useState({});
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [validstate, setValidState] = useState(false);
  const [imageMsg, setImageMsg] = useState("");
  let location = useLocation();
  const [countrycode, setCountryCode] = useState({});
  const [transservice, setTranService] = useState([]);
  const [showMoreInvoice, setShowMoreInvoice] = useState(false);
  const [showMoreTicket, setShowMoreTicket] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let detail = location?.state?.data;

    setFirstName(detail?.firstName);
    setLastName(detail?.lastName);
    setPhoneNo(detail?.phoneNumber);
    setEmail(detail?.email);
    setAddress(detail?.streetAddress);
    setCountry(detail?.country);
    SetCity(detail?.city);
    setZipcode(detail?.zipCode);
    setState(detail?.state);
    setImages(detail?.profileImage);
    setId(detail?.id);
    GetCustomerId(detail?.id);
    GetServiceTransaction(detail?.id);
    // fetch(
    //   "https://ip-api.io/api/json?api_key=fabc32c1-943d-43c7-8a13-ea82cd6ac052"
    // )
    fetch(
      "https://ip-api.io/api/json?api_key=fabc32c1-943d-43c7-8a13-ea82cd6ac052"
    )
      .then((response) => response.json())
      .then((json) => {
        setCountryCode(json);
      });
  }, []);

  const isDisposableEmail = (email) => {
    const domain = email.split("@")[1];
    return disposableEmailDomains.includes(domain);
  };

  const disposableEmailDomains = [
    // "gmail.com",
    // "yahoo.com",
    // "outlook.com",
    // "hotmail.com",
    // "aol.com",
    // "icloud.com",
    // "mail.com",
    // "yandex.com",
    // "protonmail.com",
    // "zoho.com",
    // "gmx.com",
    // "ymail.com",
    // "comcast.net",
    // "me.com",
    // "msn.com",
    // "live.com",
    // "sbcglobal.net",
    // "verizon.net",
    // "att.net",
    // "cox.net",
    // "smartitventures.com",
    // Add more domains as needed
    "mailinator.com",
    "guerrillamail.com",
    "10minutemail.com",
    "temp-mail.org",
    "yopmail.com",
    "getnada.com",
    "trashmail.com",
    "fakeinbox.com",
    "throwawaymail.com",
    "maildrop.cc",
    "mytemp.email",
    "tempmailo.com",
    "dispostable.com",
    "mintemail.com",
    "airmail.com",
  ];

  const Validation = () => {
    let error = {};
    let reg = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    // let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=])(?=.*\d).{6,}$/;
    // const isValid = /^[0-9]+$/.test(value);
    if (email && !reg.test(email)) {
      error.email = "Invalid email .";
    } else if (email && isDisposableEmail(email)) {
      error.email =
        "Please use a valid email address. Temporary email addresses are not allowed.";
    }
    if (!firstName) {
      error.firstName = "First Name Is Required";
    }
    // if (!lastName) {
    //   error.lastName = "Last Name Is Required";
    // }
    // if (!phoneNo) {
    //   error.phoneNo = "Phone Number Is Required";
    // }
    // if (!address) {
    //   error.address = "Address Is Required";
    // }
    // if (!country) {
    //   error.country = "Country Is Required";
    // }
    // if (!city) {
    //   error.city = "City Is Required";
    // }
    // if (!state) {
    //   error.state = "State Is Required";
    // }
    // if (!zipcode) {
    //   error.zipcode = "Zipcode Is Required";
    // }

    if (Object.keys(error).length === 0) {
      // console.log(Object.keys(error).length);
      EditCustomer();
    }
    return error;
  };
  const SubmitCus = () => {
    setError(Validation());
  };

  const handleImages = (e) => {
    var file = e.target.files[0];

    if (file) {
      const minSizeInBytes = 5000; // 5 KB
      const maxSizeInBytes = 1024 * 1024; // 1 MB

      if (file.size < minSizeInBytes) {
        setImageMsg("Image size is below the minimum allowed size (5 KB)");
        e.target.value = null;
        setTimeout(() => {
          setImageMsg("");
        }, 2000);
        return;
      }

      // Check if the file name contains only one dot before the extension
      const fileName = file.name;
      const lastDotIndex = fileName.lastIndexOf(".");
      const baseName = fileName.substring(0, lastDotIndex);
      const extension = fileName.substring(lastDotIndex + 1);

      // Only allow files with a single dot in the base name
      if (baseName.includes(".")) {
        setImageMsg(
          "File name is not valid. Only one dot is allowed before the extension."
        );
        e.target.value = null;
        setTimeout(() => {
          setImageMsg("");
        }, 2000);

        return;
      }

      let size = file.type.replace("/", ".");
      var blob = file.slice(0, file.size, file.type);
      const newName = new Date().getTime() + size;
      var newFile = new File([blob], newName, { type: file.type });

      UploadImage(newFile);
    }
  };

  const UploadImage = async (newFile) => {
    var formdata = new FormData();
    formdata.append("file", newFile);
    formdata.append("type", "customers-images");

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(uploadImageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        setImages(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const EditCustomer = () => {
    let local = localStorage.getItem("token");
    let userid = localStorage.getItem("userId");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    var raw = JSON.stringify({
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNo, // phoneNo ? phoneNo.replace(/^\+/, "") : "",
      profileImage: images,
      country: country,
      streetAddress: address,
      state: state,
      zipCode: zipcode,
      city: city,
      countryCode: String(countrycode.country_code),
      storeCredit: 32.23,
      account: 10.23,
      mainUserId: userid,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseurl}updateCustomerProfile/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetCustomerId(id);
          // Swal.fire({
          //   icon: "success",
          //   title: "Your data is updated Successfully!",
          //   showConfirmButton: false,
          //   timer: 1500,
          // });
          navigate("/customerList");
        } else {
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetCustomerId = (id) => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getCustomerProfile/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setFirstName(result?.data?.firstName);
          setLastName(result?.data?.lastName);
          setPhoneNo(result?.data?.phoneNumber);
          setEmail(result?.data?.email);
          setAddress(result?.data?.streetAddress);
          setCountry(result?.data?.country);
          SetCity(result?.data?.city);
          setZipcode(result?.data?.zipCode);
          setState(result?.data?.state);
          setImages(result?.data?.profileImage);
          setId(result?.data?.id);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetServiceTransaction = (id) => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getInvoiceAndService/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("getInvoiceAndService", result);
        if (result?.status === true) {
          setTranService(result);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const UpdateCustomer = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    let userid = localStorage.getItem("userId");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    var raw = JSON.stringify({
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNo,
      profileImage: "",
      country: country,
      streetAddress: address,
      state: state,
      zipCode: zipcode,
      city: city,
      storeCredit: 32.23,
      account: 10.23,
      mainUserId: userid,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseurl}updateCustomerProfile/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          GetCustomerId(id);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const HandleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete image",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c76cff",
      cancelButtonColor: "#efefef",
      confirmButtonText: "Yes, delete it!",
      confirmButtonText: "<span style='color: white;'>Yes, delete it!</span>",
      cancelButtonText: "<span style='color: black;'>cancel!</span>",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "sweet-alert2-container-zindex",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
          confirmButtonColor: "#c76cff",
          customClass: {
            container: "sweet-alert2-container-zindex",
          },
        }).then(() => {
          setImages("");
        });
      }
    });
  };
  const [isFreeTrial, setIsFreeTrial] = useState(false);

  useEffect(() => {
    const substatus = localStorage.getItem("substatus");
    const planDate = localStorage.getItem("planDate");

    if (
      (substatus === "null" ||
        substatus === "canceled" ||
        substatus === "expired") &&
      new Date(localStorage.getItem("planDate")) > new Date()
    ) {
      setIsFreeTrial(true);
    }
  }, []);

  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
    localStorage.setItem("sidebar", !isSidebarVisible);
  };

  useEffect(() => {
    const savedSidebarState = localStorage.getItem("sidebar");
    if (savedSidebarState) {
      setSidebarVisible(JSON.parse(savedSidebarState));
    }
  }, []);
  const [isFreeTrialVisible, setFreeTrialVisible] = useState(true);

  const toggleFreeTrial = () => {
    setFreeTrialVisible(!isFreeTrialVisible);
  };
  const sidebarStyle = {
    overflow: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    marginTop: isFreeTrialVisible ? "76px" : "80px",
  };
  const sidebarStyleone = {
    overflow: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    // position: 'sticky',

    // marginTop: isFreeTrialVisible ? "-40px" : "0px",
    // marginTop: isFreeTrialVisible ? "0px" : "0px",
  };

  const navbarStyle = {
    // top: isFreeTrialVisible ? "-4px" : "-50px",
  };
  const mainContentStyle = {
    // marginTop: isFreeTrialVisible ? "132px" : "92px",
  };
  const mainwrapper = {
    // marginTop: isSidebarVisible ? "0" : "0",
  };
  useEffect(() => {
    if (isFreeTrial) {
      // toggleSidebar(false);
      toggleFreeTrial(false);
    } else {
      // toggleSidebar(true);
      toggleFreeTrial(true);
    }
  }, [isFreeTrial]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      const sidebarElement = document.querySelector(".main-sidebar");
      const toggleButtonElement = document.querySelector(".sidebar-arrow a");

      if (
        sidebarElement &&
        !sidebarElement.contains(event.target) &&
        toggleButtonElement &&
        !toggleButtonElement.contains(event.target)
      ) {
        const mediaQuery = window.matchMedia("(max-width: 1024px)");
        if (mediaQuery.matches) {
          setSidebarVisible(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className={isSidebarVisible ? "sidebar-mini" : ""}>
        {loading ? (
          <div className="landing_loader">
            <div className="innerloader">
              <img src="img/spin-1s-156px.gif" alt="" />
            </div>
          </div>
        ) : null}
        <div
          className={`${
            isFreeTrial
              ? "main-wrapper devicelistcstmtableres customclswrapper"
              : "main-wrapper devicelistcstmtableres "
          } `}
        >
          {isFreeTrial ? (
            <>
              <div className="app-container-trial">
                <FreeTrail />
              </div>
            </>
          ) : null}
          <div
            // className={`${isSidebarVisible ? "sidebar-mini" : ""} ${
            //   !isFreeTrialVisible ? "no-free-trial" : ""
            // }`}
            style={sidebarStyleone}
          >
            <nav
              className="navbar navbar-expand-lg main-navbar sticky"
              style={navbarStyle}
            >
              <div className="sidebar-arrow">
                <a
                  href="#"
                  data-toggle="sidebar"
                  onClick={(e) => {
                    toggleSidebar();

                    e.preventDefault();
                  }}
                >
                  <img src="assets/img/sidebar-toggle.webp" alt="" />
                </a>
              </div>
              <div className="header-title mr-auto">
                <h4>
                  Customer Details{" "}
                  <a
                    className="notes_icon"
                    href="#"
                    data-toggle="modal"
                    data-target="#noteinfoModal"
                  >
                    <img src="assets/img/notes-icon.svg" alt="" />
                  </a>
                </h4>
              </div>
              <SideUl />
            </nav>
            <MainSidebar />
            <div className="main-content" style={mainContentStyle}>
              <div className="customer_details">
                <div className="cust_detail_left">
                  <div className="custom_form customer_form">
                    <form className="cus_form">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <div className="upload_files">
                              {images ? (
                                <img
                                  className="uploaded_img"
                                  src={images}
                                  alt=""
                                />
                              ) : (
                                <img
                                  className="uploaded_img"
                                  src="assets/img/customer-banner.webp"
                                  alt=""
                                />
                              )}
                              <div className="file_upload">
                                <input
                                  type="file"
                                  name="files"
                                  accept="image/jpeg, image/jpg, image/png"
                                  onChange={handleImages}
                                />
                                <div className="fileedit_icon">
                                  <img
                                    src="assets/img/file-edit-icon.webp"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div
                                className="filetrash_icon"
                                onClick={() => {
                                  if (images) {
                                    HandleDelete();
                                  }
                                }}
                              >
                                <a href="#">
                                  <img
                                    src="assets/img/file-trash-icon.webp"
                                    alt=""
                                  />
                                </a>
                              </div>
                            </div>
                            {imageMsg && (
                              <span className="customvalidationdesignlogin">
                                {imageMsg}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 responscustm">
                          <div className="form_column col_half">
                            <div className="form-group">
                              <label>First Name</label>
                              <input
                                type="text"
                                className="form-control"
                                name="first_name"
                                placeholder="First Name"
                                value={firstName}
                                onChange={(e) => {
                                  setFirstName(e.target.value.trim());
                                  if (error.firstName) {
                                    setError({
                                      ...error,
                                      firstName: "",
                                    });
                                  }
                                }}
                              />
                              {error.firstName && (
                                <span className="customvalidationdesignlogin">
                                  {error.firstName}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 responscustm">
                          <div className="form_column col_half">
                            <div className="form-group">
                              <label>Last Name</label>
                              <input
                                type="text"
                                className="form-control"
                                name="last_name"
                                placeholder="Last Name"
                                value={lastName}
                                onChange={(e) => {
                                  setLastName(e.target.value.trim());
                                  // if (error.lastName) {
                                  //   setError({ ...error, lastName: "" });
                                  // }
                                }}
                              />
                              {/* {error.lastName && (
                            <span className="customvalidationdesignlogin">
                              {error.lastName}
                            </span>
                          )} */}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 responscustm">
                          <div className="form_column col_half">
                            <div className="form-group">
                              <label>Email</label>
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                placeholder="Email Address"
                                value={email}
                                onChange={(e) => {
                                  setEmail(e.target.value.trim());
                                  if (error.email) {
                                    setError({ ...error, email: "" });
                                  }
                                }}
                              />
                              {error.email && (
                                <span className="customvalidationdesignlogin">
                                  {error.email}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 responscustm">
                          <div className="form_column col_half">
                            <div className="form-group">
                              <label>Phone No.</label>
                              <PhoneInput
                                className="form-control PhoneInputInput1"
                                placeholder="Enter phone number"
                                international
                                countryCallingCodeEditable={false}
                                //limitMaxLength={10}
                                value={phoneNo}
                                onChange={(val) => {
                                  setPhoneNo(val);
                                  // if (error.phoneNo) {
                                  //   setError({ ...error, phoneNo: "" });
                                  // }
                                  setValidState(true);
                                }}
                                //   onKeyup={setValidState(true)}
                                defaultCountry={countrycode.country_code}
                                // defaultCountry="IN"
                                // defaultCountry={country.countryCode}
                                // error={
                                //   value
                                //     ? isValidPhoneNumber(value)
                                //       ? undefined
                                //       : "Invalid phone number"
                                //     : "Phone number required"
                                // }
                              />

                              {/* {error.phoneNo && (
                            <span className="customvalidationdesignlogin">
                              {error.phoneNo}
                            </span>
                          )} */}
                              {validstate === true ? (
                                <span className="customvalidationdesignlogin">
                                  {phoneNo && isValidPhoneNumber(phoneNo)
                                    ? ""
                                    : "Invalid phone number"}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="clearfix"></div>
                        <div className="col-md-12">
                          <h5>Address</h5>
                        </div>
                        <div className="col-md-12">
                          <div className="form_column col_full">
                            <div className="form-group">
                              <textarea
                                className="form-control"
                                name="address"
                                placeholder="Street Address"
                                value={address}
                                onChange={(e) => {
                                  setAddress(e.target.value);
                                  // if (error.address) {
                                  //   setError({ ...error, address: "" });
                                  // }
                                }}
                              ></textarea>

                              {/* {error.address && (
                            <span className="customvalidationdesignlogin">
                              {error.address}
                            </span>
                          )} */}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 responscustm">
                          <div className="form_column state_col">
                            <div className="form-group">
                              <label>Country</label>
                              <input
                                type="text"
                                className="form-control"
                                name="state"
                                placeholder="Country"
                                value={country}
                                onChange={(e) => {
                                  setCountry(e.target.value.trim());
                                  // if (error.state) {
                                  //   setError({ ...error, state: "" });
                                  // }
                                }}
                              />
                              {/* {error.state && (
                            <span className="customvalidationdesignlogin">
                              {error.state}
                            </span>
                          )} */}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 responscustm">
                          <div className="form_column city_col">
                            <div className="form-group">
                              <label>City</label>
                              <input
                                type="text"
                                className="form-control"
                                name="city"
                                placeholder="City"
                                value={city}
                                onChange={(e) => {
                                  SetCity(e.target.value.trim());
                                  // if (error.city) {
                                  //   setError({ ...error, city: "" });
                                  // }
                                }}
                              />
                              {/* {error.city && (
                            <span className="customvalidationdesignlogin">
                              {error.city}
                            </span>
                          )} */}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 responscustm">
                          <div className="form_column state_col">
                            <div className="form-group">
                              <label>State</label>
                              <input
                                type="text"
                                className="form-control"
                                name="state"
                                placeholder="State / Province"
                                value={state}
                                onChange={(e) => {
                                  setState(e.target.value.trim());
                                  // if (error.state) {
                                  //   setError({ ...error, state: "" });
                                  // }
                                }}
                              />
                              {/* {error.state && (
                            <span className="customvalidationdesignlogin">
                              {error.state}
                            </span>
                          )} */}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 responscustm">
                          <div className="form_column zip_col">
                            <div className="form-group">
                              <label>ZipCode</label>
                              <input
                                type="text"
                                className="form-control"
                                name="zipcode"
                                placeholder="Zip Code"
                                value={zipcode}
                                onChange={(e) => {
                                  setZipcode(e.target.value.trim());
                                  // if (error.zipcode) {
                                  //   setError({ ...error, zipcode: "" });
                                  // }
                                }}
                              />
                              {/* {error.zipcode && (
                            <span className="customvalidationdesignlogin">
                              {error.zipcode}
                            </span>
                          )} */}
                            </div>
                          </div>
                        </div>

                        <div className="form_column col_full">
                          <div className="form-btn">
                            <button
                              type="button"
                              className="btn btn-primary theme_btn"
                              onClick={() => SubmitCus()}
                            >
                              Submit
                            </button>
                          </div>
                        </div>

                        <div className="clearfix"></div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="cust_detail_right">
                  <div className="recent_opiton">
                    <div className="title">
                      <h5>Recent Transactions</h5>
                      <Link to="/cash-register">+</Link>
                    </div>
                    <div className="recent_content">
                      {/* <ul>
                    {transservice?.invoice?.map((item, index) => (
                      <>
                        <li key={index}>
                          Invoice {item?.id}
                          <span className="recent_span">
                            <span>
                              {moment(item?.createdAt).format(
                                "MMM DD, hh:mm A"
                              )}
                            </span>
                            <span>$ {item?.grandTotal}</span>
                          </span>
                        </li>
                      </>
                    ))}

                  </ul> */}
                      <ul>
                        {transservice?.invoice
                          ?.slice(
                            0,
                            showMoreInvoice ? transservice.invoice.length : 2
                          )
                          .map((item, index) => (
                            <li key={index}>
                              Invoice {item?.id}
                              <span className="recent_span">
                                <span>
                                  {moment(item?.timeZone).format(
                                    "MMM DD, hh:mm A"
                                  )}
                                </span>
                                <span>$ {item?.grandTotal}</span>
                              </span>
                            </li>
                          ))}
                      </ul>
                      {showMoreInvoice && (
                        <a
                          className="loadmore"
                          href="#"
                          onClick={() => setShowMoreInvoice(false)}
                        >
                          Load Less
                        </a>
                      )}
                      {!showMoreInvoice && (
                        <a
                          className="loadmore"
                          href="#"
                          onClick={() => setShowMoreInvoice(true)}
                        >
                          Load More
                        </a>
                      )}
                      {/* <a className="loadmore" href="#">
                    Load More
                  </a> */}
                    </div>
                  </div>
                  <div className="recent_opiton">
                    <div className="title">
                      <h5>Recent Services</h5>
                      <Link to="/cash-register">+</Link>
                    </div>
                    <div className="recent_content">
                      <ul>
                        {transservice?.ticket
                          ?.slice(
                            0,
                            showMoreTicket ? transservice?.ticket?.length : 2
                          )
                          .map((item, index) => (
                            <li key={index}>
                              Ticket {item?.id}, {item?.serviceId1?.name}
                              <span className="recent_span">
                                <span>
                                  {moment(item?.timeZone).format(
                                    "MMM DD, hh:mm A"
                                  )}
                                </span>
                                {/* <span>$ 6000</span> */}
                              </span>
                            </li>
                          ))}
                      </ul>
                      {/* <ul>
                    {transservice?.ticket?.map((item, index) => (
                      <li key={index}>
                        Ticket {item?.id}, {item?.serviceId1?.name}
                        <span className="recent_span">
                          <span>
                            {moment(item?.createdAt).format("MMM DD, hh:mm A")}
                          </span>
                          <span>$ 6000</span>
                        </span>
                      </li>
                    ))}
                  </ul> */}
                      {showMoreTicket && (
                        <a
                          className="loadmore"
                          href="#"
                          onClick={() => setShowMoreTicket(false)}
                        >
                          Load Less
                        </a>
                      )}
                      {!showMoreTicket && (
                        <a
                          className="loadmore"
                          href="#"
                          onClick={() => setShowMoreTicket(true)}
                        >
                          Load More
                        </a>
                      )}
                      {/* <a className="loadmore" href="#">
                    Load More
                  </a> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
      <div
        className="modal common_modal noteinfo_modal"
        id="noteinfoModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="noteinfoModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="noteclose"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src="assets/img/notes-close-icon.svg" alt="" />
              </button>
            </div>
            <div className="modal-body">
              <div className="notes_tabs">
                <ul
                  className="nav nav-pills notetabs"
                  id="notetabs"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="video_demo-tab"
                      data-toggle="tab"
                      href="#video_demo"
                      role="tab"
                      aria-controls="video_demo"
                      aria-selected="true"
                    >
                      Video Demo
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="page_info-tab"
                      data-toggle="tab"
                      href="#page_info"
                      role="tab"
                      aria-controls="page_info"
                      aria-selected="false"
                    >
                      Page Information
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="settingContent">
                  <div
                    className="tab-pane fade active show"
                    id="video_demo"
                    role="tabpanel"
                    aria-labelledby="video_demo-tab"
                  >
                    <div className="notetabs_content">
                      <h4>Video Demo</h4>
                      <p>
                        In this short tutorial, we’ll walk you through the key
                        features and functions of the Dashboard page.
                      </p>
                      <div className="notes_videos">
                        <img
                          src="assets/img/notes-demo-videos-img.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="page_info"
                    role="tabpanel"
                    aria-labelledby="page_info-tab"
                  >
                    <div className="notetabs_content">
                      <h4>Page Information</h4>
                      <p>
                        This page provides a comprehensive view of all the tools
                        and features available to you. Each section is designed
                        to help you manage your business more efficiently,
                        whether you’re tracking sales, monitoring inventory, or
                        analyzing reports.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CustomerDetail;
