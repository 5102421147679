import React, { useEffect, useState } from "react";
import MainSidebar from "./MainSidebar";
import SideUl from "./SideUl";
import { Helmet } from "react-helmet";
import Footer from "../Footer";
import moment from "moment";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import FreeTrail from "./FreeTrail";
import {
  GetMessagesApi,
  getProfileApi,
  GetSubUserProfileApi,
} from "../Utility/Api";
import CashManagmentOpen from "./CashManagmentOpen";
import { useMemo } from "react";
import BannerMessage from "./BannerMessage";

const HelpCenter = () => {
  const [isFreeTrial, setIsFreeTrial] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [isFreeTrialVisible, setFreeTrialVisible] = useState(true);
  const [userName, setUserName] = useState();
  const [UserEmail, setUserEmail] = useState();
  const [userCompanyName, setUserCompanyName] = useState();
  const [messageData, setMessageData] = useState();
  const [videoFile, setVideoFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [videos, setVideos] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [thumbnails, setThumbnails] = useState({});
  const [filteredVideos1, setFilteredVideos] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    // Check if Tidio script is already added
    if (window.tidioChatApi) {
      setIsLoaded(true);
    } else {
      const script = document.createElement("script");
      script.src = "//code.tidio.co/im43woctro9766xmgfico7awakn4qrup.js";
      script.async = true;

      script.onload = () => {
        setIsLoaded(true);

        const checkTidio = setInterval(() => {
          if (window.tidioChatApi) {
            clearInterval(checkTidio);
            window.tidioChatApi.show(); // Ensure it's visible
          }
        }, 500);
      };

      document.body.appendChild(script);
    }
  }, []);

  const openChat = () => {
    if (window.tidioChatApi) {
      window.tidioChatApi.show();
      window.tidioChatApi.open();
    }
  };

  // Filter videos based on searchQuery
  const filteredVideos = videos.filter((video) =>
    video.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const fetchVideos = () => {
    fetch("https://api2.toolboxpos.com/getAllVideo")
      .then((response) => response.json())
      .then((result) => {
        if (result.status && Array.isArray(result.data)) {
          console.log("videooo", result.data);
          const reversedVideos = result.data.reverse();
          setVideos(reversedVideos);
          setFilteredVideos(reversedVideos);

          generateThumbnails(result.data);
        } else {
          console.error("Failed to fetch videos");
        }
      })
      .catch(() => console.error("Error fetching videos"));
  };

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchTerm(query);

    if (query === "") {
      setFilteredVideos(videos); // Show all videos when search is cleared
    } else {
      const filtered = videos.filter((video) =>
        video.title.toLowerCase().includes(query)
      );
      setFilteredVideos(filtered);
    }
  };

  const openPopup = (video) => {
    setSelectedVideo(video);
    setShowPopup(true);
  };

  const closePopup = () => {
    setSelectedVideo(null);
    setShowPopup(false);
  };

  useEffect(() => {
    fetchVideos();
    GetMessages();
    const substatus = localStorage.getItem("substatus");
    const planDate = localStorage.getItem("planDate");

    if (
      (substatus === "null" ||
        substatus === "canceled" ||
        substatus === "expired") &&
      new Date(localStorage.getItem("planDate")) > new Date()
    ) {
      setIsFreeTrial(true);
    }
  }, []);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
    localStorage.setItem("sidebar", !isSidebarVisible);
  };

  useEffect(() => {
    const savedSidebarState = localStorage.getItem("sidebar");
    if (savedSidebarState) {
      setSidebarVisible(JSON.parse(savedSidebarState));
    }
    let localAdmin = localStorage.getItem("admin");
    let localPerminssion = JSON.parse(localStorage.getItem("permission"));
    if (localAdmin === "0" && localPerminssion.length) {
      GetSubUserProfile();
    }
    GetProfile();
  }, []);

  const GetProfile = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(getProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("gfdgh", result);
        if (result.status === true) {
          //setData1(result?.data);
          setUserCompanyName(result?.data?.companyDetails?.companyName);
          setUserEmail(result?.data?.userDetails?.email);
          setUserName(result?.data?.userDetails?.userName);
        } else {
          console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetSubUserProfile = () => {
    let local = localStorage.getItem("token");
    //console.log(local);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetSubUserProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("subuser", result);
        if (result.status === true) {
          setUserCompanyName(result?.data?.companyDetails?.companyName);
          setUserEmail(result?.data?.userDetails?.email);
          setUserName(result?.data?.userDetails?.name);
          // setDataUser(result?.data);
        } else {
          console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const toggleFreeTrial = () => {
    setFreeTrialVisible(!isFreeTrialVisible);
  };

  const sidebarStyleone = {
    overflow: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
  };

  useEffect(() => {
    if (isFreeTrial) {
      // toggleSidebar(false);
      toggleFreeTrial(false);
    } else {
      // toggleSidebar(true);
      toggleFreeTrial(true);
    }
  }, [isFreeTrial]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const sidebarElement = document.querySelector(".main-sidebar");
      const toggleButtonElement = document.querySelector(".sidebar-arrow a");

      if (
        sidebarElement &&
        !sidebarElement.contains(event.target) &&
        toggleButtonElement &&
        !toggleButtonElement.contains(event.target)
      ) {
        const mediaQuery = window.matchMedia("(max-width: 1024px)");
        if (mediaQuery.matches) {
          setSidebarVisible(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const GetMessages = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(GetMessagesApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        if (result?.status === true) {
          const bannerData = result?.data?.bannerText;
          setMessageData(bannerData);
        } else {
          // Swal.fire(
          //   "To ensure you see the most recent changes, please clear your browser's cache"
          // );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const generateThumbnails = (videos) => {
    videos.forEach((video) => {
      const videoElement = document.createElement("video");
      videoElement.src = video.url;
      videoElement.crossOrigin = "anonymous";

      videoElement.addEventListener("loadeddata", () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = 320; // Adjust width
        canvas.height = 180; // Adjust height

        videoElement.currentTime = 1; // Capture frame at 1s

        videoElement.addEventListener("seeked", () => {
          ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
          setThumbnails((prev) => ({
            ...prev,
            [video.id]: canvas.toDataURL("image/png"),
          }));
        });
      });
    });
  };

  return (
    <>
      <div className={isSidebarVisible ? "sidebar-mini" : ""}>
        <div
          className={`${
            isFreeTrial || messageData
              ? "main-wrapper devicelistcstmtableres customclswrapper"
              : "main-wrapper devicelistcstmtableres "
          } `}
        >
          {loading ? (
            <div className="landing_loader">
              <div className="innerloader">
                <img src="img/spin-1s-156px.gif" alt="" />
              </div>
            </div>
          ) : null}
          {isFreeTrial ? (
            <div className="app-container-trial">
              <FreeTrail />
            </div>
          ) : messageData ? (
            <div className="app-container-trial bannercssmsg">
              <BannerMessage />
            </div>
          ) : null}
          <div style={sidebarStyleone}>
            <nav className="navbar navbar-expand-lg main-navbar sticky">
              <div className="sidebar-arrow">
                <a
                  href="#"
                  data-toggle="sidebar"
                  onClick={(e) => {
                    toggleSidebar();

                    e.preventDefault();
                  }}
                >
                  <img src="assets/img/sidebar-toggle.webp" alt="" />
                </a>
              </div>
              <div className="header-title mr-auto">
                <h4>Help Center</h4>
              </div>
              <SideUl />
            </nav>
            <MainSidebar />

            <div className="main-content " style={{ background: "#fff" }}>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="help_center_search">
                    <h3>
                      Toolbox Help Center{" "}
                      <img src="assets/img/help-center-bag-icon.svg" alt="" />
                    </h3>
                    <p>
                      Reach into our toolkit and find the help your looking for.
                    </p>
                    <div className="help_center_search_field">
                      <input
                        type="text"
                        className="form-control"
                        name="helpcentersearch"
                        placeholder="Search Guides, Videos"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                      <img src="assets/img/search-icon.svg" alt="" />
                    </div>
                  </div>
                  <div className="help_center_service_sec">
                    <div className="help_center_service">
                      <div
                        className="help_center_column"
                        onClick={openChat}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="help_icon">
                          <img src="assets/img/help-option-icon1.svg" alt="" />
                        </div>
                        <div className="help_content">
                          <h5>24/7 Support</h5>
                          <p>Get support instantly from our qualified team</p>
                        </div>
                      </div>
                      <div className="help_center_column">
                        <div className="help_icon">
                          <img src="assets/img/help-option-icon2.svg" alt="" />
                        </div>
                        <div className="help_content">
                          <h5>FAQS</h5>
                          <p>FAQ gives your clarity to your questions</p>
                        </div>
                      </div>
                      <div className="help_center_column">
                        <div className="help_icon">
                          <img src="assets/img/help-option-icon3.svg" alt="" />
                        </div>
                        <div className="help_content">
                          <h5>Blog</h5>
                          <p>Stay up with latest updates on community</p>
                        </div>
                      </div>
                      <div className="help_center_column">
                        <div className="help_icon">
                          <img src="assets/img/help-option-icon4.svg" alt="" />
                        </div>
                        <div className="help_content">
                          <h5>Email</h5>
                          <p>
                            Send you all the updates instantly to your email
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="help_center_contact">
                      <a href="https://www.toolboxpos.com/contact-us/">
                        Contact us
                      </a>
                    </div>
                  </div>
                  <div className="help_center_option_sec">
                    <h4>'How To' Series</h4>
                    <div className="help_center_box">
                      {filteredVideos.length > 0 ? (
                        filteredVideos.map((video) => (
                          <div
                            key={video.id}
                            className="option_box_col"
                            onClick={() => openPopup(video)}
                          >
                            <div className="option_img">
                              {thumbnails[video.id] ? (
                                <img
                                  src={thumbnails[video.id]}
                                  alt={video.title}
                                />
                              ) : (
                                <video width="100%" height="auto">
                                  <source src={video.url} type="video/mp4" />
                                </video>
                              )}
                            </div>
                            <div className="option_name">
                              <h6>{video.title}</h6>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="no_videos_found">
                          <img
                            src="assets/img/empty-search.png"
                            alt="No videos found"
                          />
                          <h2>No Videos Match Your Search</h2>
                          <p>
                            Try searching with different terms, or browse
                            through our video library for more tutorials. Need
                            help? Reach out to our support team anytime!
                          </p>
                          <div className="buttons">
                            <button className="support_button">
                              Talk to Support
                            </button>
                            <button
                              className="browse_button"
                              onClick={() => setSearchQuery("")}
                            >
                              Browse Video Library
                            </button>
                          </div>
                        </div>
                      )}

                      {showPopup && selectedVideo && (
                        <div className="popup_overlay" onClick={closePopup}>
                          <div
                            className="popup_content"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <button className="close_btn1" onClick={closePopup}>
                              ✖
                            </button>
                            <h2>{selectedVideo.title}</h2>
                            <video width="600" controls autoPlay>
                              <source
                                src={selectedVideo.url}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="clearfix" />
                  </div>
                </div>
              </div>

              <div className="bottom_btns">
                <div className="row">
                  <div className="col-md-12">
                    <div className="bottom_links">
                      <ul>
                        <li>
                          <a href="#">--</a>
                        </li>
                        <li>
                          <a href="#">--</a>
                        </li>
                        <li>
                          <a href="#">Note</a>
                        </li>
                        <li>
                          <a href="#">Signature</a>
                        </li>
                        <li>
                          <a href="#">Start Over</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
    </>
  );
};
export default HelpCenter;
