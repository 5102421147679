import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { baseurl, getProfileApi, uploadImageApi } from "../Utility/Api";
import Swal from "sweetalert2";

const CompanyEdit = () => {
  const [userDetails, setUserDetails] = useState({});
  const [companyDetails, setCompanyDetails] = useState({});
  const [companyName, setCompanyName] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [companyService, setCompanyService] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState("");

  useEffect(() => {
    GetProfile();
  }, []);

  const GetProfile = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(getProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setUserDetails(result?.data?.userDetails);
          setCompanyDetails(result?.data?.companyDetails);
          setCompanyName(result?.data?.companyDetails?.companyName);
          setCompanyPhone(result?.data?.companyDetails?.companyPhoneNo);
          setCompanyService(result?.data?.companyDetails?.companyServiceNo);
          setCountry(result?.data?.companyDetails?.country);
          setAddress(result?.data?.companyDetails?.streetAddress);
          setState(result?.data?.companyDetails?.state);
          setZipcode(result?.data?.companyDetails?.zipCode);
          setImages(result?.data?.companyDetails?.image);
        } else {
          //console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const CompanyUpdate = () => {
    setLoading(true);
    let local = localStorage.getItem("token");

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      companyName: companyName,
      companyPhoneNo: companyPhone,
      companyServiceNo: companyService,
      country: country,
      streetAddress: address,
      state: state,
      zipCode: zipcode,
      image: images,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseurl}updateCompany/${userDetails?.companyId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setLoading(false);
          GetProfile();
          setCompanyName(result?.data?.companyName);
          setCompanyPhone(result?.data?.companyPhoneNo);
          setCompanyService(result?.data?.companyServiceNo);
          setCountry(result?.data?.country);
          setAddress(result?.data?.streetAddress);
          setState(result?.data?.state);
          setZipcode(result?.data?.zipCode);
          setImages(result?.data?.image);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const handleImages = (e) => {
    var file = e.target.files[0];
    let size = e.target.files[0].type.replace("/", ".");
    var blob = file.slice(0, file.size, e.target.files[0].type);
    const newName = new Date().getTime() + size;
    var newFile = new File([blob], newName, { type: e.target.files[0].type });
    UploadImage(newFile);
  };

  const UploadImage = async (newFile) => {
    setLoading(true);
    var formdata = new FormData();
    formdata.append("file", newFile);
    formdata.append("type", "company-images");

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(uploadImageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setImages(result?.data);
          setLoading(false);
        } else {
          Swal.fire(result?.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  };

  return (
    <>
      {loading ? (
        <div className="landing_loader">
          <div className="innerloader">
            <img src="img/spin-1s-156px.gif" alt="" />
          </div>
        </div>
      ) : null}
      <div className="setting_content">
        <h4>Company Profile</h4>
        <div className="inner_block profile_setting compnyphotocusmtcss ">
          <form className="setting_form" action="" method="post">
            <div className="block_column wd65 ">
              <h6>Company Profile</h6>
              <div className="inner_field">
                <div className="form-group">
                  <div className="form_field">
                    <label>Sub-Domain</label>
                    <p className="form-control">{userDetails?.subDomain}</p>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form_field">
                    <label>Company Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="company_name"
                      placeholder="Enter Company Name"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form_field">
                    <label>Company Phone No.</label>
                    <input
                      type="text"
                      className="form-control"
                      name="company_phone"
                      placeholder="Enter Company phone No."
                      value={companyPhone}
                      onChange={(e) => setCompanyPhone(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form_field">
                    <label>Customer Service No.</label>
                    <input
                      type="text"
                      className="form-control"
                      name="company_serviceno"
                      placeholder="Enter Company Service No."
                      value={companyService}
                      onChange={(e) => setCompanyService(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <h6>Security</h6>
              <div className="inner_field">
                <div className="form-group">
                  <div className="form_field">
                    <label>Country</label>
                    <input
                      type="text"
                      className="form-control"
                      name="country"
                      placeholder="Enter Country"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form_field">
                    <label>Street Address</label>
                    <input
                      type="text"
                      className="form-control"
                      name="street_address"
                      placeholder="Enetr Address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form_field">
                    <label>State/Province</label>
                    <input
                      type="text"
                      className="form-control"
                      name="state_province"
                      placeholder="Enter State"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form_field">
                    <label>Zip/Postal Code</label>
                    <input
                      type="text"
                      className="form-control"
                      name="zip_postalcode"
                      placeholder="Enter Zip/Postal code"
                      value={zipcode}
                      onChange={(e) => setZipcode(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="leftcompanypicturecustm">
              <div className="block_column wd35">
                <h6>Company Picture</h6>
                <div className="inner_field">
                  <div className="form-group">
                    <div className="form_field profile_field">
                      <div className="profile_upload">
                        <input
                          type="file"
                          name="profile_img"
                          accept="image/jpeg, image/jpg, image/png"
                          onChange={handleImages}
                        />
                        {images ? (
                          <img
                            src={images}
                            className="userupdateimagecustom"
                            alt=""
                          />
                        ) : (
                          <img src="assets/img/profile-image.webp" alt="" />
                        )}
                        {/* <img src="assets/img/profile-image.webp" alt="" /> */}
                      </div>
                      <button type="button" className="cus_btn">
                        <img src="assets/img/profile-edit-icon.webp" alt="" />{" "}
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className="editsavechangesbtn">
            <button
              type="button"
              className="btnprofileinfo"
              onClick={CompanyUpdate}
            >
              Save Changes
            </button>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    </>
  );
};
export default CompanyEdit;
