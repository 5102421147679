import React, { useEffect, useState, useRef } from "react";
import $ from "jquery";
import Footer from "../Footer";
import NavBar from "../MainDashboard/NavBar";
import SideUl from "../MainDashboard/SideUl";

import MainSidebar from "../MainDashboard/MainSidebar";
import {
  AddBrandApi,
  AddItemApi,
  AddManufactureApi,
  AddProductApi,
  AddSupplierApi,
  AddTagsApi,
  GetAllBrandApi,
  GetAllItemApi,
  GetAllProductApi,
  GetAllSupplierApi,
  GetManufactureApi,
  GetTagsApi,
  baseurl,
  customerAddApi,
  customerGetApi,
  uploadImageApi,
} from "../Utility/Api";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { Link, useNavigate } from "react-router-dom";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Swal from "sweetalert2";

const EditProductModal = (props) => {
  const [images, setImages] = useState("");
  const [error, setError] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermCategory, setSearchTermCategory] = useState("");
  const [searchTermSuppiler, setsearchTermSuppiler] = useState("");
  const [searchTermTags, setsearchTermTags] = useState("");
  const [imageMsg, setImageMsg] = useState("");
  const [brand, setBrand] = useState("");
  const [AllBrand, setAllBrand] = useState([]);
  const [supplier, setSupplier] = useState("");
  const [AllSupplier, setAllSupplier] = useState([]);
  const [SKU, setSKU] = useState("");
  const [AllItems, setAllItems] = useState([]);
  const [AllTags, setAllTags] = useState([]);
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [unitCost, setUnitCost] = useState("");
  const [minimumPrice, setMinimunPrice] = useState("");
  const [averageCost, setAverageCost] = useState("");
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [selectedCateId, setSelectedCateId] = useState(null);
  const [selectedSupplierId, setSelectedSuppierId] = useState(null);
  const [selectedTagIds, setSelectedTagIds] = useState([]);
  const [draftSave, setDraftSave] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errormsg, setErrormsg] = useState("");
  const [showList, setShowList] = useState(false);
  const [showListCategory, setShowListCategory] = useState(false);
  const [showListSuppiler, setShowListSuppiler] = useState(false);
  const [showListAuto, setShowListAuto] = useState(false);
  const [showListTags, setShowListTags] = useState(false);
  //const [searchTerm, setSearchTerm] = useState('');
  const [typedText, setTypedText] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSuppiler, setSelectedSuppiler] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [skuFill, setSKUFill] = useState("");
  const [autogenrate, setAutoGenrate] = useState(0);

  const [autogenText, setAutoGenTex] = useState("Auto Generated");

  const inputRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const dropdownRef = useRef(null);
  const dropdownRef1 = useRef(null);
  const dropdownRef2 = useRef(null);
  const dropdownRef3 = useRef(null);
  const dropdownRef4 = useRef(null);
  const dropdownRef11 = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef11.current &&
        !dropdownRef11.current.contains(event.target)
      ) {
        setShowListAuto(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef11]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowList(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef1.current &&
        !dropdownRef1.current.contains(event.target)
      ) {
        setShowListCategory(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef1]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef2.current &&
        !dropdownRef2.current.contains(event.target)
      ) {
        setShowListSuppiler(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef2]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef3.current &&
        !dropdownRef3.current.contains(event.target)
      ) {
        setShowListTags(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef3]);

  const filteredBrands = AllBrand.filter((brand) => {
    const name = brand.name;

    // Check if 'name' is not null before calling toLowerCase
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTerm.toLowerCase());
    }

    return false; // Or handle null or non-string values as needed
  });

  const handleButtonClick = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowList(!showList);
  };
  const handleButtonClickCategory = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowListCategory(!showListCategory);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setTypedText(e.target.value);
    setSelectedBrand(null); // Reset selected brand when typing in the input field
  };

  const handleBrandSelect = (brand) => {
    setSelectedBrand(brand.name);
    setSelectedBrandId(brand.id);
    setShowList(false);
  };

  // const handleInputKeyDown = (e) => {
  //   if (e.key === 'Backspace') {
  //     setShowList(true);
  //   }
  // };
  const handleAddNewClick = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Brand name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    // e.preventDefault();
    setSelectedBrand(typedText);
    setShowList(false);
    setSearchTerm("");
    setTypedText("");
    AddBrand();
  };

  const filteredCategory = AllItems.filter((brand) => {
    const name = brand.name;

    // Check if 'name' is not null before calling toLowerCase
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermCategory.toLowerCase());
    }

    return false; // Or handle null or non-string values as needed
  });

  const handleSearchChangeCategory = (e) => {
    setSearchTermCategory(e.target.value);
    setTypedText(e.target.value);
    setSelectedCategory(null);
  };

  const handleCategorySelect = (brand) => {
    setSelectedCategory(brand.name);
    setSelectedCateId(brand.id);
    setShowListCategory(false);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Backspace") {
      setShowList(true);
    }
  };
  const handleAddNewClickCategory = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Category name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    //e.preventDefault();
    setSelectedCategory(typedText);
    setShowListCategory(false);
    setSearchTermCategory("");
    setTypedText("");
    AddItem();
  };

  const filteredSuppiler = AllSupplier.filter((brand) => {
    const name = brand.name;

    // Check if 'name' is not null before calling toLowerCase
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermSuppiler.toLowerCase());
    }

    return false;
  });

  const filteredTags = AllTags.filter((brand) => {
    const name = brand.name;

    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermTags.toLowerCase());
    }

    return false;
  });

  const handleSearchChangeSupplier = (e) => {
    setsearchTermSuppiler(e.target.value);
    setTypedText(e.target.value);
    setSelectedSuppiler(null);
  };
  const handleSearchChangeTags = (e) => {
    setsearchTermTags(e.target.value);

    setTypedText(e.target.value);
  };
  const handleSupplierSelect = (brand) => {
    setSelectedSuppiler(brand.name);
    setSelectedSuppierId(brand.id);
    setShowListSuppiler(false);
  };

  // const handleTagsSelect = (brand) => {

  //   if (!selectedTags.find((tag) => tag.id === brand.id)) {
  //     setSelectedTags([...selectedTags, brand]);
  //   }
  //   //setSelectedTags(brand.name);

  //   setSelectedTagIds(brand.id);
  //   setShowListTags(false);
  // };

  const handleTagsSelect = (selectedTag) => {
    // Check if the selected tag is already in the selectedTags array
    const isAlreadySelected = selectedTags.some(
      (tag) => tag.id === selectedTag.id
    );

    if (isAlreadySelected) {
      // If the tag is already selected, remove it
      const updatedTags = selectedTags.filter(
        (tag) => tag.id !== selectedTag.id
      );
      setSelectedTags(updatedTags);

      setsearchTermTags("");
    } else {
      setsearchTermTags("");

      // If the tag is not selected yet, add it to the selectedTags array
      setSelectedTags([...selectedTags, selectedTag]);
    }
  };
  const handleTagDelete = (tagId) => {
    const updatedTags = selectedTags.filter((tag) => tag.id !== tagId);
    setSelectedTags(updatedTags);
  };
  const handleAddNewClickSupplier = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Manufacturer name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    // e.preventDefault();
    setSelectedSuppiler(typedText);
    setShowListSuppiler(false);
    setsearchTermSuppiler("");
    setTypedText("");
    AddSuppier();
  };

  const handleInputClick = () => {
    setShowListTags(!showListTags); // Show the list of tags when the input is clicked
  };

  const handleAddNewClickTags = () => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Tag name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    // Check if the typed text already exists among the selected tags
    const isExistingTag = selectedTags.some(
      (tag) => tag.name.toLowerCase() === searchTermTags.toLowerCase()
    );

    // If the typed text doesn't already exist among the selected tags, add it as a new tag
    if (!isExistingTag) {
      // Create a new tag object and add it to the selected tags array
      const newTag = { id: "3e3e3", name: searchTermTags };

      // Add a comma separator if there are existing selected tags
      const updatedTags =
        selectedTags.length > 0 ? [...selectedTags, newTag] : [newTag];

      setSelectedTags(updatedTags);
    }
    AddTags();
    // Reset the search term
    setsearchTermTags("");
  };

  // const handleAddNewClickTags = (e) => {
  //   if (!typedText.trim()) {
  //     Swal.fire({
  //       title: "Please enter a valid Tag name!",
  //       customClass: {
  //         container: "custom-swal-container",

  //         confirmButton: "custom-swal-confirm-button",
  //       },
  //     });
  //     return;
  //   }
  //   e.preventDefault(); // Prevent anchor tag behavior
  //   AddTags();
  //   console.log("Add New button clicked", typedText);
  // };

  const handleButtonClickSupplier = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowListSuppiler(!showListSuppiler);
  };
  const handleButtonClickAuto = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowListAuto(!showListAuto);
  };
  const handleButtonClickTags = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowListTags(!showListTags);
  };

  const createOption = (label, id) => ({
    label,
    value: id,
  });

  const Validation = () => {
    let error = {};
    const numericRegex = /^[0-9]+$/;
    if (!title) {
      error.title = "Title Is Required";
    } else if (title.trim().length === 0) {
      error.title = "Title Is Required";
    }

    if (!price) {
      error.price = "Selling Price Is Required";
    }
    if (!SKU) {
      error.SKU = "SKU Code Is Required";
    }
    // if (!images) {
    //   error.images = "Image Is Required";
    // }

    if (Object.keys(error).length === 0) {
      // console.log(Object.keys(error).length);
      AddProduct();
    }
    return error;
  };

  const Validationd = () => {
    let error = {};
    const numericRegex = /^[0-9]+$/;
    if (!title) {
      error.title = "Title Is Required";
    } else if (title.trim().length === 0) {
      error.title = "Title Is Required";
    }

    if (!price) {
      error.price = "Selling Price Is Required";
    }
    if (!SKU) {
      error.SKU = "SKU Code Is Required";
    } else if (SKU.length !== 6) {
      error.SKU = "Allow Only Six Numbers";
    }

    // if (!images) {
    //   error.images = "Image Is Required";
    // }

    if (Object.keys(error).length === 0) {
      // console.log(Object.keys(error).length);
      AddProduct1();
    }
    return error;
  };

  const navigate = useNavigate();

  const AddBrand = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var raw = JSON.stringify({
      name: typedText,
      productCategoryId: 1,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddBrandApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllBrand();
          setSelectedBrandId(result?.data?.id);
          setSearchTerm("");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllBrand = () => {
    let local = localStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllBrand?productCategoryId=1`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllBrand(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const AddSuppier = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    var raw = JSON.stringify({
      name: typedText,
      productCategoryId: 1,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddManufactureApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllSupplier();
          setSelectedSuppierId(result?.data?.id);
          setsearchTermSuppiler("");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllSupplier = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetManufactureApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllSupplier(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const SubmitProduct = () => {
    setError(Validation());
  };
  const SubmitProductdraft = () => {
    setError(Validationd());
  };

  const HandleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete image",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c76cff",
      cancelButtonColor: "#efefef",
      confirmButtonText: "Yes, delete it!",
      confirmButtonText: "<span style='color: white;'>Yes, delete it!</span>",
      cancelButtonText: "<span style='color: black;'>cancel!</span>",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "sweet-alert2-container-zindex",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
          confirmButtonColor: "#c76cff",
          customClass: {
            container: "sweet-alert2-container-zindex",
          },
        }).then(() => {
          setImages("");
        });
      }
    });
  };
  const generateSKU = () => {
    // Assuming you have a specific format for SKU, you can customize this logic
    const sku = Math.floor(100000 + Math.random() * 900000)
      .toString()
      .substring(0, 6);

    setSKU(sku);
    setError("");
    return sku;
  };
  useEffect(() => {
    generateSKU();

    setTitle(props?.data?.title);
    setSelectedBrand(props?.data?.brand?.name);
    setSelectedBrandId(props?.data?.brand?.id);
    setSelectedCategory(props?.data?.itemCategory?.name);
    setSelectedCateId(props?.data?.itemCategory?.id);
    setDescription(props?.data?.description);
    //setTags(props?.data?.tags);

    // setSKU(props?.skuCode);
    setSelectedSuppiler(props?.data?.manufacturer?.name);
    // SetSupplierId(props?.supplier?.id);
    setSelectedSuppierId(props?.data?.manufacturer?.id);
    setUnitCost(props?.data?.unitCost);
    setPrice(props?.data?.sellingPrice);
    setImages(props?.data?.image);
    setMinimunPrice(props?.data?.minimumPrice);
    // setId(props?.id);
    setAverageCost(props?.data?.averageCost);
    // setTagsId(props?.tagIds);
    // if (props?.draft) {
    //   setSelectedCategory4("Draft");
    //   setActive(0);
    // } else if (detail?.activeInactive === 1) {
    //   setSelectedCategory4("Active");
    //   setActive(1);
    // } else {
    //   setSelectedCategory4("Inactive");
    //   setActive(0);
    // }

    //setQuantity(props?.inventory);

    //setSelectedTags(props?.data?.tags.map((item) => ({ name: item })));
    // console.log(detail?.tags.map((item) => ({ name: item })));

    //setSelectedTags(props?.data.tagIds.map((item) => ({ id: item })));
    const namesArray = props?.data?.tags.map((item) => ({ name: item })) || [];
    const idsArray = props?.data?.tagIds.map((item) => ({ id: item })) || [];

    const mergedArray =
      namesArray.length === idsArray.length
        ? namesArray.map((item, index) => ({ ...item, ...idsArray[index] }))
        : [];

    setSelectedTags(mergedArray);
    GetAllBrand();
    GetAllSupplier();
    GetAllTags();
    GetAllItem();
    //GetCustomer();
  }, []);

  const AddProduct = () => {
    let local = localStorage.getItem("token");
    const tagIdsArray = selectedTags.map((tag) => tag.id.toString());

    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var raw = JSON.stringify({
      itemCategoryId: selectedCateId,
      productCategoryId: 1,
      brandId: selectedBrandId,
      manufacturerId: selectedSupplierId,
      title: title,
      image: images,
      activeInactive: 1,
      draft: 0,
      skuCode: SKU ? SKU : skuFill,
      description: description,
      tags: tagIdsArray,
      sellingPrice: price ? price : 0,
      unitCost: 0,
      minimumPrice: minimumPrice ? minimumPrice : 0,
      averageCost: 0,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddProductApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          navigate("/productlist");

          setTitle("");
          setImages("");
          setDescription("");
          setPrice("");
          setUnitCost("");
          setAverageCost("");
          setMinimunPrice("");
          setSKUFill("");
          setSelectedBrand("");
          setSelectedCategory("");
          setSelectedTags([]);
          setSelectedSuppiler("");
          setError("");
          setSKU("");
          Swal.fire(result?.message);
        } else {
          // Swal.fire(result?.message);
          Swal.fire({
            title: result?.message,
            customClass: {
              container: "custom-swal-container",

              confirmButton: "custom-swal-confirm-button",
            },
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const AddProduct1 = () => {
    let local = localStorage.getItem("token");
    const tagIdsArray = selectedTags.map((tag) => tag.id.toString());

    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var raw = JSON.stringify({
      itemCategoryId: selectedCateId,
      productCategoryId: 1,
      brandId: selectedBrandId,
      manufacturerId: selectedSupplierId,
      title: title,
      image: images,
      activeInactive: 1,
      draft: 1,
      skuCode: SKU,
      description: description,
      tags: tagIdsArray,
      sellingPrice: price ? price : 0,
      unitCost: 0,
      minimumPrice: minimumPrice ? minimumPrice : 0,
      averageCost: 0,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddProductApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          navigate("/productlist");
          //   props.onpress(false);
          //   props.onload();
          setTitle("");
          setImages("");
          setDescription("");
          setPrice("");
          setUnitCost("");
          setAverageCost("");
          setMinimunPrice("");
          setSKUFill("");
          setSelectedBrand("");
          setSelectedCategory("");
          setSelectedTags([]);
          setSelectedSuppiler("");
          setError("");
          setSKU("");
          Swal.fire(result?.message);
        } else {
          // Swal.fire(result?.message);
          Swal.fire({
            title: result?.message,
            customClass: {
              container: "custom-swal-container",

              confirmButton: "custom-swal-confirm-button",
            },
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleBrandChange = (e) => {
    setBrand(e.target.value);
  };
  const handleSupplierChange = (e) => {
    setSupplier(e.target.value);
  };

  const AddItem = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    var raw = JSON.stringify({
      name: typedText,
      productCategoryId: 1,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddItemApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllItem();
          setSelectedCateId(result?.data?.id);
          setSearchTermCategory("");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllItem = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllItemCategory?productCategoryId=1`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllItems(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const AddTags = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    var raw = JSON.stringify({
      name: typedText,
      productCategoryId: "1",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddTagsApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setSelectedTags([...selectedTags, result.data]);
          GetAllTags();
          setsearchTermTags("");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllTags = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllTag?productCategoryId=1`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllTags(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const handleTagsChange = (e) => {
    // Update the selected tags when the user changes the selection
    setAllTags(Array.from(e.target.selectedOptions, (option) => option.value));
  };

  const [selectedOption, setSelectedOption] = useState(null);
  const [customValue, setCustomValue] = useState("");

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handleInputChange = (inputValue) => {
    setCustomValue(inputValue);
  };

  const [selectedBrand, setSelectedBrand] = useState(null);

  const handleImages = (e) => {
    var file = e.target.files[0];

    if (file) {
      const minSizeInBytes = 5000; // 5 KB
      const maxSizeInBytes = 1024 * 1024; // 1 MB

      if (file.size < minSizeInBytes) {
        setImageMsg("Image size is below the minimum allowed size (5 KB)");
        e.target.value = null;
        setTimeout(() => {
          setImageMsg("");
        }, 2000);
        return;
      }
      const fileName = file.name;
      const lastDotIndex = fileName.lastIndexOf(".");
      const baseName = fileName.substring(0, lastDotIndex);
      const extension = fileName.substring(lastDotIndex + 1);

      // Only allow files with a single dot in the base name
      if (baseName.includes(".")) {
        setImageMsg(
          "File name is not valid. Only one dot is allowed before the extension."
        );
        e.target.value = null;
        setTimeout(() => {
          setImageMsg("");
        }, 2000);

        return;
      }
      let size = file.type.replace("/", ".");
      var blob = file.slice(0, file.size, file.type);
      const newName = new Date().getTime() + size;
      var newFile = new File([blob], newName, { type: file.type });

      UploadImage(newFile);
    }
  };
  const UploadImage = async (newFile) => {
    setLoading(true);
    var formdata = new FormData();
    formdata.append("file", newFile);
    formdata.append("type", "product-images");

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(uploadImageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setImages(result?.data);
        setLoading(false);
        setImageMsg("");
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };
  const maxDescriptionLength = 200;
  const handleDescriptionChange = (e) => {
    const newDescription = e.target.value;

    if (newDescription.length <= maxDescriptionLength) {
      setDescription(newDescription);
      setErrormsg("");
    } else if (newDescription.length > maxDescriptionLength) {
      setTimeout(() => {
        setErrormsg("Allow Maximum 200 Characters");
      }, 2000);
    }
  };

  return (
    <>
      {loading ? (
        <div className="landing_loader">
          <div className="innerloader">
            <img src="img/spin-1s-156px.gif" alt="" />
          </div>
        </div>
      ) : null}
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="createproductModalLabel">
              Create New Product
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                //setShowModal4(false);
                document
                  .querySelectorAll(".modal-backdrop")
                  .forEach((el) => el.classList.remove("modal-backdrop"));
              }}
            >
              <span className="close_icon"></span>
            </button>
          </div>
          <div className="modal-body">
            <div className="custom_form createproduct_form">
              <form action="" method="" className="cus_form">
                <div className="form_row product_radio_row">
                  <div className="form_column col_full">
                    <div className="form-group"></div>
                  </div>
                </div>

                <div className="product_row">
                  <div className="product_col70 cus_prod_col">
                    <h4>
                      General information<span>*</span>
                    </h4>
                    <p>To start selling, all you need is a name and a price.</p>
                    <div className="form_row">
                      <div className="form_column col_full">
                        <div className="form-group">
                          <label className="cus_label label_red">
                            Title<span>*</span>
                          </label>
                          <div className="formfield field_wd100">
                            <input
                              type="text"
                              className="form-control"
                              name="title"
                              placeholder="Enter Title"
                              value={title}
                              onChange={(e) => {
                                setTitle(e.target.value);
                                if (error.title) {
                                  setError({ ...error, title: "" });
                                }
                              }}
                            />{" "}
                          </div>{" "}
                          {error.title && (
                            <span
                              style={{
                                fontSize: "12px",
                                color: "red",
                                marginLeft: "110px",
                              }}
                            >
                              {error.title}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="form_column col_full">
                        <div className="form-group">
                          <label className="cus_label">Brand</label>
                          <div
                            className="formfield field_wd100 selectcategoryformbox"
                            ref={dropdownRef}
                          >
                            <div className="tagsscustomdiv">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onClick={() => setShowList(!showList)}
                              >
                                <input
                                  ref={inputRef}
                                  type="text"
                                  className="customsearchinputboxsetting inputdeviceformcustmbox form-control"
                                  placeholder={`Select Brand`}
                                  value={selectedBrand || searchTerm}
                                  onChange={handleSearchChange}
                                  onClick={() => setShowList(true)}
                                />

                                <img
                                  src="assets/img/select-arrow.webp"
                                  alt="Handy Icon"
                                  onClick={handleButtonClick}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>

                              {showList && (
                                <div className="addselectbox">
                                  <ul>
                                    {filteredBrands.map((brand) => (
                                      <li
                                        key={brand.id}
                                        onClick={() => handleBrandSelect(brand)}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        {brand.name}
                                      </li>
                                    ))}
                                  </ul>

                                  <button
                                    onClick={() => {
                                      handleAddNewClick();
                                      if (inputRef.current) {
                                        inputRef.current.focus();
                                      }
                                    }}
                                    style={{ backgroundColor: "#9d3dd3" }}
                                    type="button"
                                    className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                  >
                                    <span>+</span> Add New Brand
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form_column col_full">
                        <div className="form-group">
                          <label className="cus_label">Category</label>
                          <div
                            className="formfield field_wd100  selectcategoryformbox"
                            ref={dropdownRef1}
                          >
                            <div className="tagsscustomdiv">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onClick={() =>
                                  setShowListCategory(!showListCategory)
                                }
                              >
                                <input
                                  ref={inputRef3}
                                  type="text"
                                  className="customsearchinputboxsetting inputdeviceformcustmbox form-control"
                                  placeholder={`Select Category`}
                                  value={selectedCategory || searchTermCategory}
                                  onChange={handleSearchChangeCategory}
                                  onClick={() => setShowListCategory(true)}
                                />

                                <img
                                  src="assets/img/select-arrow.webp"
                                  alt="Handy Icon"
                                  onClick={handleButtonClickCategory}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>

                              {showListCategory && (
                                <div className="addselectbox">
                                  <ul>
                                    {filteredCategory.map((brand) => (
                                      <li
                                        key={brand.id}
                                        onClick={() =>
                                          handleCategorySelect(brand)
                                        }
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        aria-selected="true"
                                      >
                                        {brand.name}
                                      </li>
                                    ))}
                                  </ul>

                                  <button
                                    onClick={() => {
                                      handleAddNewClickCategory();
                                      if (inputRef3.current) {
                                        inputRef3.current.focus();
                                      }
                                    }}
                                    style={{ backgroundColor: "#9d3dd3" }}
                                    type="button"
                                    className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                  >
                                    <span>+</span> Add New Category
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form_column col_full">
                        <div className="form-group">
                          <label className="cus_label">Manufacturer</label>
                          <div
                            className="formfield field_wd100 selectcategoryformbox"
                            ref={dropdownRef2}
                          >
                            <div className="tagsscustomdiv">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onClick={() =>
                                  setShowListSuppiler(!showListSuppiler)
                                }
                              >
                                <input
                                  ref={inputRef2}
                                  type="text"
                                  className="customsearchinputboxsetting inputdeviceformcustmbox form-control"
                                  placeholder={`Select Manufacturer`}
                                  value={selectedSuppiler || searchTermSuppiler}
                                  onChange={handleSearchChangeSupplier}
                                  onClick={() => setShowListSuppiler(true)}
                                />

                                <img
                                  src="assets/img/select-arrow.webp"
                                  alt="Handy Icon"
                                  onClick={handleButtonClickSupplier}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>

                              {showListSuppiler && (
                                <div className="addselectbox">
                                  <ul>
                                    {filteredSuppiler.map((brand) => (
                                      <li
                                        key={brand.id}
                                        onClick={() => {
                                          handleSupplierSelect(brand);
                                        }}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        {brand.name}
                                      </li>
                                    ))}
                                  </ul>

                                  <button
                                    onClick={() => {
                                      handleAddNewClickSupplier();
                                      if (inputRef2.current) {
                                        inputRef2.current.focus();
                                      }
                                    }}
                                    style={{ backgroundColor: "#9d3dd3" }}
                                    type="button"
                                    className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                  >
                                    <span>+</span> Add New Manufacturer
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form_column col_full">
                        <div className="form-group">
                          <label className="cus_label">
                            SKU Code type:{" "}
                            <img src="assets/img/info-icon.webp" alt="" />
                          </label>
                          <div
                            className="formfield field_wd100 formfield_col2"
                            ref={dropdownRef11}
                          >
                            <div
                              className="field_wd50"
                              onClick={() => setShowListAuto(!showListAuto)}
                            >
                              <div className="selectcategoryformbox1">
                                <div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() =>
                                      setShowListAuto(!showListAuto)
                                    }
                                  >
                                    <span className="customsearchinputboxsetting">
                                      {autogenText
                                        ? autogenText
                                        : "Auto Generated"}
                                    </span>
                                    <button
                                      onClick={handleButtonClickAuto}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img
                                        src="assets/img/select-arrow.webp"
                                        alt="Handy Icon"
                                      />
                                    </button>
                                  </div>
                                  {showListAuto && (
                                    <div className="addselectbox">
                                      <ul>
                                        <li
                                          onClick={() => {
                                            setShowListAuto(false);
                                            setAutoGenrate(0);
                                            generateSKU();
                                            setAutoGenTex("Auto Generated");
                                          }}
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          Auto Generated
                                        </li>
                                        <li
                                          onClick={() => {
                                            setShowListAuto(false);
                                            setAutoGenrate(1);
                                            setAutoGenTex("Manually Filled");
                                            setSKU("");
                                          }}
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          Manually Filled
                                        </li>
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            {autogenrate === 1 ? (
                              <div className="field_wd50">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="sku_code"
                                  value={SKU}
                                  placeholder="Enter SKU Code"
                                  onChange={(e) => {
                                    setSKU(e.target.value);
                                    if (error.SKU) {
                                      setError({ ...error, SKU: "" });
                                    }
                                  }}
                                />
                                {error.SKU && (
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      color: "red",
                                    }}
                                  >
                                    {error.SKU}
                                  </span>
                                )}
                              </div>
                            ) : null}

                            {autogenrate === 0 ? (
                              <div className="field_wd50">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="sku_code"
                                  placeholder={SKU}
                                  readOnly
                                />
                                {error.SKU && (
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      color: "red",
                                    }}
                                  >
                                    {error.SKU}
                                  </span>
                                )}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="product_col30 cus_prod_col">
                    <h4>Thumbnail</h4>
                    <p>Upload image of product</p>

                    <div className="form_row">
                      <div className="form_column col_full">
                        <div className="form-group">
                          <div className="thumbnail_img">
                            {images ? (
                              <i
                                className="fa-regular fa-circle-xmark deletimgcustm1"
                                onClick={() => HandleDelete()}
                              ></i>
                            ) : null}
                            {images ? (
                              <>
                                <img src={images} className="imgescstmdisply" />
                              </>
                            ) : (
                              <>
                                <input
                                  type="file"
                                  className="form-control"
                                  name="prod_thumnail"
                                  accept="image/jpeg, image/jpg, image/png"
                                  onChange={handleImages}
                                />
                                <div className="file_upload">
                                  <img
                                    src="assets/img/thumbnail_img.webp"
                                    alt=""
                                  />
                                  <div className="file_txt">
                                    Drop your file here, or{" "}
                                    <span>click to browse</span>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          {imageMsg && (
                            <span
                              style={{
                                fontSize: "12px",
                                color: "red",
                              }}
                            >
                              {imageMsg}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clearfix"></div>
                <div className="product_row">
                  <div className="product_full cus_prod_col">
                    <div className="form_row">
                      <div className="form_column col_full">
                        <div className="form-group tsgddisplycstmmm1">
                          <label className="cus_label label_red">
                            Pricing<span>*</span>{" "}
                            <img src="assets/img/info-icon.webp" alt="" />
                          </label>
                          <div className="formfield pricefield field_wd100">
                            <div className="price_col">
                              <label className="cus_label label_red">
                                Selling Price *
                              </label>
                              <input
                                type="number"
                                className="form-control no-arrow-input"
                                name="selling_price"
                                value={price}
                                onChange={(e) => {
                                  setPrice(e.target.value);

                                  if (error.price) {
                                    setError({ ...error, price: "" });
                                  }
                                }}
                              />
                              {error.price && (
                                <span
                                  style={{ fontSize: "12px", color: "red" }}
                                >
                                  {error.price}
                                </span>
                              )}
                              <div className="doller_sign">
                                <span>$</span>
                              </div>
                            </div>
                            {/* <div className="price_col">
                              <label className="cus_label">Unit Cost</label>
                              <input
                                type="number"
                                className="form-control no-arrow-input"
                                name="unit_cost"
                                value={unitCost}
                                onChange={(e) => setUnitCost(e.target.value)}
                              />
                              <div className="doller_sign">
                                <span>$</span>
                              </div>
                            </div> */}
                            <div className="price_col">
                              <label className="cus_label">Minimum Price</label>
                              <input
                                type="number"
                                className="form-control no-arrow-input"
                                name="minimum_price"
                                value={minimumPrice}
                                onChange={(e) =>
                                  setMinimunPrice(e.target.value)
                                }
                              />
                              <div className="doller_sign">
                                <span>$</span>
                              </div>
                            </div>
                            {/* <div className="price_col">
                              <label className="cus_label">Average Cost</label>
                              <input
                                type="number"
                                className="form-control no-arrow-input"
                                name="average_cost"
                                value={averageCost}
                                onChange={(e) => setAverageCost(e.target.value)}
                              />
                              <div className="doller_sign">
                                <span>$</span>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form_column col_full">
                    <div className="form-group tsgddisplycstmmm">
                      <label className="cus_label"></label>
                      <div className="formfield field_wd100">
                        <div className="tagsdisplaydivcstm">
                          {selectedTags.map((tag) => (
                            <div key={tag.id} className="selected-tag">
                              <span style={{ marginRight: "5px" }}>
                                {tag.name}
                              </span>
                              <button
                                className="delete-tag-button"
                                onClick={() => handleTagDelete(tag.id)}
                              >
                                &#x2715;
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                  <div className="form_column col_full">
                    <div className="form-group">
                      <label className="cus_label">Tags</label>
                      <div
                        className="formfield field_wd100 selectcategoryformbox"
                        ref={dropdownRef3}
                      >
                        <div
                          className="tagsscustomdiv"
                          onClick={handleButtonClickTags}
                        >
                          {/* Input field for searching */}
                          <input
                            ref={inputRef1}
                            type="text"
                            className="customsearchinputboxsetting tagsinputcstmwit inputdeviceformcustmbox"
                            placeholder={`Search Tags`}
                            value={searchTermTags}
                            onChange={handleSearchChangeTags}
                            onClick={handleInputClick}
                          />

                          {/* Arrow button to open the list */}
                          <button
                            onClick={handleButtonClickTags}
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src="assets/img/select-arrow.webp"
                              alt="Handy Icon"
                            />
                          </button>

                          {/* Display selected tags in individual boxes */}

                          {/* List of tags */}
                          {showListTags && (
                            <div className="addselectbox">
                              <ul>
                                {filteredTags.map((brand) => (
                                  <li
                                    key={brand.id}
                                    onClick={() => {
                                      handleTagsSelect(brand);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {brand.name}
                                  </li>
                                ))}
                              </ul>

                              <button
                                onClick={() => {
                                  handleAddNewClickTags();
                                  if (inputRef1.current) {
                                    inputRef1.current.focus();
                                  }
                                }}
                                style={{ backgroundColor: "#9d3dd3" }}
                                type="button"
                                className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                              >
                                <span>+</span> Add New Tag
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form_column col_full">
                    <div className="form-group">
                      <label className="cus_label">
                        Description (optional)
                      </label>
                      <div className="formfield field_wd100">
                        <textarea
                          className="form-control"
                          name="description"
                          placeholder="Enter Description"
                          value={description}
                          onChange={handleDescriptionChange}
                        ></textarea>
                      </div>{" "}
                      {errormsg && (
                        <span
                          style={{
                            fontSize: "12px",
                            color: "red",
                            marginLeft: "110px",
                          }}
                        >
                          {errormsg}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form_column col_full">
                    <div className="form-group">
                      <div className="taxfield switch_field">
                        <label className="custom-switch">
                          <span className="switch_txt">Default Tax</span>
                          <input
                            type="checkbox"
                            name="custom-switch-checkbox"
                            className="custom-switch-input"
                            checked
                          />
                          <span className="custom-switch-indicator"></span>
                        </label>
                        <label className="custom-switch">
                          <span className="switch_txt">Tax Inclusive</span>
                          <input
                            type="checkbox"
                            name="custom-switch-checkbox"
                            className="custom-switch-input"
                            checked
                          />
                          <span className="custom-switch-indicator"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="clearfix"></div>
              </form>
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary default_btn"
              // data-dismiss="modal"
              onClick={() => {
                setDraftSave(1);
                SubmitProductdraft();
                //setShowModal4(false);
              }}
            >
              Save as draft
            </button>
            <button
              type="button"
              className="btn btn-primary theme_btn"
              onClick={() => {
                SubmitProduct();
                setDraftSave(0);
              }}
            >
              Publish Product
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditProductModal;
