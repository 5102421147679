import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AddClockInApi,
  BreakTimeApi,
  ClockInApi,
  ClockOutApi,
  CloclInApi,
  GetAllBreakTimeApi,
  GetAllSubdomainApi,
  GetDemoStoreDetailsApi,
  GetSubUserProfileApi,
  SwitchstoreApi,
  baseurl,
  getProfileApi,
  loginApi,
} from "../Utility/Api";
import FreeTrail from "./FreeTrail";
import Swal from "sweetalert2";
import Search from "../CashRegister/Search";
// import Timer from "./Timer";

const SideUl = ({ props, onCompanyNameChange }) => {
  const [data, setData] = useState([]);
  const [dataUser, setDataUser] = useState([]);
  const [adminState, setAdminState] = useState("");
  const [permission, setPermission] = useState([]);
  const [status, setStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [data1, setData1] = useState([]);
  const [showModal1, setShowModal1] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  const [filteredData, setFilteredData] = useState([]);
  const [CompanyName, setCompanyName] = useState("");
  const [timezone, setTimezone] = useState("");
  const [subdomains, setSubdomains] = useState([]);
  const [subDomainName, setSubDomainName] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
  const [password, setPassword] = useState("");

  const [isClockedIn, setIsClockedIn] = useState(false);
  const [isOnBreak, setIsOnBreak] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [breakStartTime, setBreakStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [breakTime, setBreakTime] = useState(0);
  const [onBreak, setOnBreak] = useState(false);
  const [breakId, setBreakId] = useState(null);
  const [breakElapsedTime, setBreakElapsedTime] = useState(0);
  const [timer, setTimer] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  const [clockInId, setClockInId] = useState(null);
  const [userRolee, setUserRolee] = useState("");

  useEffect(() => {
    if (isClockedIn && startTime) {
      const calculateElapsedTime = () => {
        const now = new Date();
        const diffInSeconds = Math.floor((now - startTime) / 1000);
        setElapsedTime(diffInSeconds);
      };

      calculateElapsedTime();
      const id = setInterval(calculateElapsedTime, 1000);
      setIntervalId(id);

      return () => clearInterval(id);
    }
  }, [isClockedIn, startTime]);

  const startTimer = (clockInTime) => {
    const startTimestamp = new Date(clockInTime).getTime();
    setTimer(
      setInterval(() => {
        const currentTime = new Date().getTime();
        const diff = Math.floor((currentTime - startTimestamp) / 1000); // Convert to seconds
        setElapsedTime(diff);
      }, 1000)
    );
  };
  useEffect(() => {
    let timer;
    if (isClockedIn && !isOnBreak) {
      timer = setInterval(() => {
        setElapsedTime((prev) => prev + 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isClockedIn, isOnBreak]);

  useEffect(() => {
    // Restore state from localStorage on component mount
    const storedBreakId = localStorage.getItem("breakId");
    const storedBreakStartTime = localStorage.getItem("breakStartTime");

    if (storedBreakId && storedBreakStartTime) {
      setBreakId(storedBreakId);
      setBreakStartTime(new Date(storedBreakStartTime));
      setIsOnBreak(true);
      startBreakTimer(new Date(storedBreakStartTime));
    }
  }, []);

  const startBreakTimer = (start) => {
    const interval = setInterval(() => {
      setBreakTime(Math.floor((new Date() - start) / 1000));
    }, 1000);
    return interval;
  };

  const toggleDropdown1 = () => {
    setIsDropdownOpen1(!isDropdownOpen1);
    console.log("toggleee", isDropdownOpen1);
  };
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const [isChatLoaded, setIsChatLoaded] = useState(false);

  const loadChat = () => {
    if (!isChatLoaded) {
      const script = document.createElement("script");
      script.src = "https://embed.tawk.to/67a09b3e825083258e0f5ac9/1ij5lsrdj";
      script.async = true;
      script.charset = "UTF-8";
      script.setAttribute("crossorigin", "*");
      document.body.appendChild(script);

      script.onload = () => {
        window.Tawk_API = window.Tawk_API || {};

        window.Tawk_API.onLoad = function () {
          console.log("Tawk.to API Loaded Successfully!");
          setIsChatLoaded(true);

          // Hide the default chat icon
          if (typeof window.Tawk_API.hide === "function") {
            window.Tawk_API.hide();
          }

          // Open chat window directly
          setTimeout(() => {
            if (typeof window.Tawk_API.maximize === "function") {
              window.Tawk_API.maximize();
            }
          }, 500);

          // Hide the icon whenever the chat is closed
          window.Tawk_API.onStatusChange = function (status) {
            if (status === "offline") {
              console.log("Chat went offline, hiding icon again.");
              if (typeof window.Tawk_API.hide === "function") {
                window.Tawk_API.hide();
              }
            }
          };

          // Also ensure chat is hidden after minimizing
          window.Tawk_API.onChatMinimized = function () {
            console.log("Chat minimized, hiding widget.");
            if (typeof window.Tawk_API.hide === "function") {
              window.Tawk_API.hide();
            }
          };
        };
      };

      return () => {
        document.body.removeChild(script);
      };
    } else {
      // Open chat directly if already loaded
      window.Tawk_API?.maximize();
    }
  };

  const [isLoaded, setIsLoaded] = useState(false);
  // useEffect(() => {
  //   // Check if Tidio script is already added
  //   if (window.tidioChatApi) {
  //     setIsLoaded(true);
  //   }
  //   //loadTidio();
  // }, []);

  const loadTidio = () => {
    if (!isLoaded) {
      const script = document.createElement("script");
      script.src = "//code.tidio.co/im43woctro9766xmgfico7awakn4qrup.js";
      script.async = true;

      script.onload = () => {
        setIsLoaded(true);

        const checkTidio = setInterval(() => {
          if (window.tidioChatApi) {
            clearInterval(checkTidio);
            window.tidioChatApi.open();

            // Listen for chat minimize event
            window.tidioChatApi.on("close", () => {
              console.log("Chat minimized, hiding widget.");
              window.tidioChatApi.hide();
              localStorage.setItem("tidioHidden", "true"); // Store state
            });
          }
        }, 500);
      };

      document.body.appendChild(script);
    } else {
      // If already loaded, check if it was hidden
      const wasHidden = localStorage.getItem("tidioHidden") === "true";
      if (wasHidden) {
        window.tidioChatApi.show();
        localStorage.removeItem("tidioHidden");
      }
      window.tidioChatApi.open();
    }
  };

  useEffect(() => {
    // Check if Tidio script is already added
    if (window.tidioChatApi) {
      setIsLoaded(true);
    } else {
      const script = document.createElement("script");
      script.src = "//code.tidio.co/im43woctro9766xmgfico7awakn4qrup.js";
      script.async = true;

      script.onload = () => {
        setIsLoaded(true);

        const checkTidio = setInterval(() => {
          if (window.tidioChatApi) {
            clearInterval(checkTidio);
            window.tidioChatApi.show(); // Ensure it's visible
          }
        }, 500);
      };

      document.body.appendChild(script);
    }
  }, []);

  const openChat = () => {
    if (window.tidioChatApi) {
      window.tidioChatApi.show();
      window.tidioChatApi.open();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false); // Close dropdown if clicked outside
      }
    };

    // Add the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [imeicheck, setImeiCheck] = useState();
  useEffect(() => {
    GetDemoDetails();
    ClockINOUTDetail();
    GetALLBreakDetail();
    const protocol = window.location.protocol;
    const host = window.location.host;
    const subdomain = host.split(".")[0];
    console.log("subdomain,,,,,", subdomain);
    setSubDomainName(subdomain);
    //  setSubdomain(subdomain);
    GetAllSubdomain(subdomain);

    let localAdmin = localStorage.getItem("admin");
    let localPerminssion = JSON.parse(localStorage.getItem("permission"));
    let userRole = localStorage.getItem("userRole");

    setUserRolee(userRole);

    if (localAdmin === "0" && localPerminssion?.length) {
      setAdminState(localAdmin);
      setPermission(localPerminssion);
      const hasImeiCheck = localPerminssion.some(
        (perm) => perm.name === "Imei Check"
      );
      setImeiCheck(hasImeiCheck);
      console.log("userRole", hasImeiCheck);
      // GetProfile();
      GetSubUserProfile();
    } else {
      GetProfile();
    }
    // GetCustomer(page, searchTerm);
  }, []);

  const GetProfile = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(getProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("get profile", result);
        if (result.status === true) {
          // console.log("comapny", result?.data?.companyDetails?.companyName);
          setData(result?.data);
          setStatus(
            result?.data?.companyDetails?.subscriptionStatus === "canceled" ||
              result?.data?.companyDetails?.subscriptionStatus === null ||
              result?.data?.companyDetails?.subscriptionStatus === "expired"
          );
          onCompanyNameChange(result?.data?.companyDetails?.companyName);
          setCompanyName(result?.data?.companyDetails?.companyName);

          //  console.log("companyName", result?.data?.companyDetails?.companyName);

          // if (
          //   result?.data?.companyDetails?.subscriptionStatus === "canceled" ||
          //   result?.data?.companyDetails?.subscriptionStatus === null
          // ) {
          //   navigate("/settings", { state: { activeState: 1 } });
          // } else if (
          //   result?.data?.companyDetails?.subscriptionStatus === "complete"
          // ) {
          //   setSub(2);
          // } else {
          //   setSub(1);
          // }
        } else if (
          result?.status === false &&
          result?.message === "Token has expired"
        ) {
          Swal.fire(result?.message);
          window.location.href = "https://app.toolboxpos.com";
        } else {
          // console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetSubUserProfile = () => {
    let local = localStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetSubUserProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("subuser profile", result);
        if (result.status === true) {
          setDataUser(result?.data);
          setCompanyName(result?.data?.companyDetails?.companyName);
          setTimezone(result?.timezone);
          //console.log("setTimezone", result?.timezone);
        } else if (
          result?.status === false &&
          result?.message === "Token has expired"
        ) {
          Swal.fire(result?.message);
          window.location.href = "https://app.toolboxpos.com";
        } else {
          // console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const navigate = useNavigate();

  const logOutt = () => {
    localStorage.clear();
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith("token")) {
        localStorage.removeItem(key);
        localStorage.clear();
      }
    });
    window.location.href = "https://app.toolboxpos.com";
    //navigate("/");
  };

  const Profile = () => {
    navigate("/settings");
  };
  const Helpcenter = () => {
    navigate("/helpcenter");
  };
  const Setting = () => {
    navigate("/settings");
  };
  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      if (hasMore && !loading) {
        setPage((prevPage) => prevPage + 1);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [hasMore, loading]);

  useEffect(() => {
    setData1([]);
    setFilteredData([]);
    setPage(1);
    GetCustomer(1, searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    if (page > 1) {
      GetCustomer(page, searchTerm);
    }
  }, [page]);

  const GetCustomer = (page, searchTerm) => {
    setLoading(true);
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${baseurl}getAllCustomer?pageSize=20&page=${page}&keyword=${searchTerm}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          // console.log("customer", result.data);
          // setData1((prevData) =>
          //   Array.isArray(prevData)
          //     ? [...prevData, ...result.data]
          //     : result.data
          // );
          // setFilteredData((prevData) =>
          //   Array.isArray(prevData)
          //     ? [...prevData, ...result.data]
          //     : result.data
          // );
          setFilteredData((prevData) => {
            // Combine previous data and new data
            const combinedData = Array.isArray(prevData)
              ? [...prevData, ...result?.data]
              : result?.data;

            // Remove duplicates by creating a Set of unique items based on a unique identifier (e.g., id)
            const uniqueData = Array.from(
              new Set(combinedData.map((item) => item.id))
            ).map((id) => combinedData.find((item) => item.id === id));

            return uniqueData;
          });
          setHasMore(result.data.length > 0);
          // setData1(result?.data);
          setSelectedCustomer(result?.data);
        } else {
          setHasMore(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };
  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
    setShowModal1(true);
  };

  const handlePopupCloseCustomer = () => {
    setShowModal1(false);
  };
  const handleCustomerSelection = (customer) => {
    setSearchTerm(`${customer.firstName}`);
    setSelectedCustomer(customer);
    setShowModal1(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const dropdown = document.querySelector(".searchcustomer_list");

      if (dropdown && !dropdown.contains(event.target)) {
        setShowModal1(false);
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [setShowModal1]);

  const [shiftModal, setShiftModal] = useState(false);
  const toggleShiftModal = () => {
    setShiftModal(!shiftModal); // Toggle the shiftModal state
    setCompanyName(CompanyName);
  };

  const GetAllSubdomain = (sub) => {
    let local = localStorage.getItem("token");
    let mail = localStorage.getItem("email");

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      subDomain: sub,
      email: mail,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(GetAllSubdomainApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("subdomain list,,,", result);
        setSubdomains(result.data);
      })
      .catch((error) => console.error(error));
  };

  const AddClockIn = () => {
    setLoading1(true);
    const now = new Date();
    const start_time = now.toLocaleTimeString("en-US", { hour12: false });
    const date = now.toISOString().split("T")[0];
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    const raw = JSON.stringify({
      start_time,
      date,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("clockin raw", raw);
    fetch(ClockInApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("user clockin", result);
        if (result?.status === true) {
          setLoading1(false);
          ClockINOUTDetail();
          setStartTime(now);
          setIsClockedIn(true);
          localStorage.setItem("clockInStartTime", now.toISOString());
        } else {
          setLoading1(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading1(false);
      });
  };

  const AddClockOut = () => {
    if (!clockInId) return;
    setLoading1(true);

    const now = new Date();
    const end_time = now.toLocaleTimeString("en-US", { hour12: false });
    const time_In_Minutes = Math.floor(elapsedTime / 60);
    console.log("tuiuiu", time_In_Minutes);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    const raw = JSON.stringify({
      end_time,
      time_In_Minutes,
    });
    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("clockout raww", raw);

    fetch(`${baseurl}clockOut/${clockInId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("user clockin", result);
        if (result?.status === true) {
          setLoading1(false);

          setIsClockedIn(false);
          setClockInId(null);
          setStartTime(null);
          setElapsedTime(0);
          localStorage.removeItem("clockInStartTime");
        } else {
          setLoading1(false);

          Swal.fire({
            title: result?.message,
            customClass: {
              container: "custom-swal-container",
              confirmButton: "custom-swal-confirm-button",
            },
          });
        }
      })
      .catch((error) => {
        console.error(error);

        setLoading1(false);
      });
  };

  const ClockINOUTDetail = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAttendanceDetails`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);

        if (result.status && result.data.length > 0) {
          const latestEntry = result.data.find((entry) => !entry.end_time); // Active session
          if (latestEntry) {
            const storedStartTime = new Date(
              `${latestEntry.date}T${latestEntry.start_time}`
            );
            setClockInId(latestEntry.id);
            setStartTime(storedStartTime);
            setIsClockedIn(true);
            localStorage.setItem(
              "clockInStartTime",
              storedStartTime.toISOString()
            );
          } else {
            setIsClockedIn(false);
            setClockInId(null);
            setStartTime(null);
            setElapsedTime(0);
            localStorage.removeItem("clockInStartTime");
          }
        }
      })
      .catch((error) => console.error(error));
  };

  const BreakTime = () => {
    setLoading1(true);

    const now = new Date();
    const start_time = now.toLocaleTimeString("en-US", { hour12: false });
    const date = now.toISOString().split("T")[0];
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      start_time,
      date,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BreakTimeApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("break ", result);
        if (result.status) {
          setLoading1(false);

          // setBreakId(result.data.id);
          setBreakStartTime(now);
          setIsOnBreak(true);

          localStorage.setItem("breakStartTime", now.toISOString());
          startBreakTimer(now);
          GetALLBreakDetail();
        } else {
          setLoading1(false);
        }
      })
      .catch((error) => {
        console.error(error);

        setLoading1(false);
      });
  };

  const ResumeSession = () => {
    if (!breakId) return;
    setLoading1(true);

    const now = new Date();
    const end_time = now.toLocaleTimeString("en-US", { hour12: false });
    const breakDuration = Math.floor((now - breakStartTime) / 60000);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      end_time,
      time_In_Minutes: breakDuration,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("breakdetaillss  stop", raw);
    fetch(`${baseurl}resumeSession/${breakId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("break stop", result);
        if (result.status) {
          setLoading1(false);

          setIsOnBreak(false);
          // setBreakId(null);
          setBreakStartTime(null);
          setBreakTime(0);
          localStorage.removeItem("breakId");
          localStorage.removeItem("breakStartTime");
        } else {
          setLoading1(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading1(false);
      });
  };

  const GetALLBreakDetail = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getBreakDetails`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("getbreak details", result);
        if (result.status && result.data.length > 0) {
          const latestBreak = result.data.find((entry) => !entry.end_time);
          if (latestBreak) {
            setBreakId(latestBreak.id);
            const breakStart = new Date(
              `${latestBreak.date}T${latestBreak.start_time}`
            );
            setBreakStartTime(breakStart);
            setOnBreak(true);
            localStorage.setItem("breakId", latestBreak.id);
            localStorage.setItem("breakStartTime", breakStart.toISOString());
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const SubDomainLink = (domain, email) => {
    const protocol = window.location.protocol;
    let host = window.location.host;
    const subdomain = domain;
    const home = "/password";

    let hostParts = host.split(".");
    if (hostParts[0]) {
      hostParts.shift();
    }
    hostParts.unshift(subdomain);
    const newHost = hostParts.join(".");

    // Create URL with email parameter
    const params = new URLSearchParams();
    params.set("email", localStorage.getItem("email")); // Adding email param

    const newUrl = `${protocol}//${newHost}${home}?${params.toString()}`;
    console.log("newUrl", newUrl);

    window.location.href = newUrl;
  };

  const [showDemoStore, setShowDemoStore] = useState(false);
  const [dataDemo, setDataDemo] = useState();
  const GetDemoDetails = () => {
    let token = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetDemoStoreDetailsApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result?.status && result?.data) {
          setShowDemoStore(false);
          setDataDemo(result?.data?.demoStore);
          setDemoStoreData(result?.data);
        }
        // else if(result?.status && result?.data&&result?.data?.demoStore==="1"){

        // }
        else {
          setShowDemoStore(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const [demoStoreData, setDemoStoreData] = useState();

  return (
    <>
      {((adminState !== "0" ||
        permission?.length === 0 ||
        userRolee === "Super Admin") &&
        dataDemo !== "1" &&
        demoStoreData?.trainingMode !== 1) ||
      permission?.some((item) => item?.name === "Imei Check") ? (
        <Search />
      ) : null}

      {/* {(adminState === "0" &&
        permission?.length !== 0 &&
        userRolee !== "Super Admin") ||
      dataDemo === "1" ||
      demoStoreData?.trainingMode === 1 ? null : (
        <Search />
      )}
      {permission?.some((item) => item?.name === "Imei Check") ? (
        <Search />
      ) : null} */}
      <div
        className="common_search"
        style={
          dataDemo === "1" || demoStoreData?.trainingMode === 1
            ? { pointerEvents: "none", opacity: 0.5 }
            : {}
        }
      >
        <div className="search_input">
          <input
            type="text"
            className="form-control"
            placeholder="Search Customer"
            value={searchTerm}
            onChange={handleSearchTermChange}
          />
          <button type="button" className="search_btn">
            <img src="assets/img/search-icon.webp" alt="" />
          </button>
        </div>
        <div className={`searchcustomer_list ${showModal1 ? "active" : ""}`}>
          <div className="srchcustmer_title common_title">
            <h4>Search Customer </h4>
            <a className="popup_close" onClick={handlePopupCloseCustomer}>
              <span className="close_icon"></span>
            </a>
          </div>
          <div className="customer_list">
            <ul>
              {filteredData?.length ? (
                <>
                  {filteredData?.map((item) => (
                    <li key={item.id}>
                      <Link
                        to="/CustomerDetail"
                        state={{ data: item }}
                        // onClick={() => {
                        //   //handleCustomerSelection(item);
                        //  // setSelectedState(true);
                        // }}
                        key={item.id}
                        className="linkcustm"
                      >
                        <div key={item.id} className="custmer_img">
                          {item?.profileImage ? (
                            <img src={item?.profileImage} alt="" />
                          ) : (
                            <img
                              src="assets/img/customer-info-img.webp"
                              alt=""
                            />
                          )}
                        </div>
                        <span>
                          {item?.firstName} {item?.lastName}{" "}
                          <small>{item?.email}</small>
                          <small>{item?.phoneNumber}</small>
                        </span>

                        <img
                          className="customeraddeditcstmimg"
                          src="assets/img/arrow-right-icon.svg"
                          alt=""
                        />
                      </Link>
                    </li>
                  ))}
                  {loading && <li>Loading...</li>}
                </>
              ) : (
                <div className="text-center">
                  <h5>No data available</h5>
                </div>
              )}
            </ul>
          </div>
        </div>
      </div>
      <ul className="navbar-nav navbar-right">
        {adminState === 1 || permission?.length === 0 ? (
          <li
            className="setting_li custom_li"
            style={
              dataDemo === "1" || demoStoreData?.trainingMode === 1
                ? { pointerEvents: "none", opacity: 0.5 }
                : {}
            }
          >
            <Link to="/settings">
              <img src="assets/img/settings-icon.webp" alt="" />
            </Link>
          </li>
        ) : (
          permission?.map((permission) => (
            <>
              {permission.name === "Settings" && (
                <li
                  className="setting_li custom_li"
                  key={permission.id}
                  style={
                    dataDemo === "1" || demoStoreData?.trainingMode === 1
                      ? { pointerEvents: "none", opacity: 0.5 }
                      : {}
                  }
                >
                  <Link to="/settings">
                    <img src="assets/img/settings-icon.webp" alt="" />
                  </Link>
                </li>
              )}
            </>
          ))
        )}

        <li className="dropdown">
          <a
            href="#"
            data-toggle="dropdown"
            className="nav-link dropdown-toggle nav-link-user"
          >
            <div className="user_icon">
              <img
                alt="image"
                src={
                  props?.image
                    ? props?.image
                    : data?.userDetails?.profileImage
                    ? data?.userDetails?.profileImage
                    : "assets/img/Avatar.webp"
                }
                className="user-img-radious-style"
              />
            </div>{" "}
            <div className="user_info">
              <span className="user_name">
                {(adminState === 0 || permission?.length > 0) &&
                  dataUser?.userDetails?.name}
                {((adminState === 1 || permission?.length === 0) &&
                  data?.userDetails?.userName) ||
                  props?.name}
                {adminState === 1 ||
                  (permission?.length === 0 && (
                    <small>{data?.userDetails?.email}</small>
                  ))}
                {adminState === 0 ||
                  (permission?.length > 0 && (
                    <small>{dataUser?.userDetails?.email}</small>
                  ))}
              </span>
              <span className="angle_down">
                <i className="fa fa-angle-down" />
              </span>
            </div>
          </a>
          <div
            className="dropdown-menu dropdown-menu-right "
            style={
              dataDemo === "1" || demoStoreData?.trainingMode === 1
                ? { pointerEvents: "none", opacity: 0.9 }
                : {}
            }
          >
            <div className="nav_title">
              <span className="nametitle"> {subDomainName}</span>
              <div className="user_nav_name">
                <span>{subDomainName?.charAt(0).toUpperCase()}</span>{" "}
                {subDomainName}
              </div>
            </div>
            <div className="nav_links">
              <a href="#" className="dropdown-item has-icon">
                <img src="assets/img/whatsapp-support-icon.svg" alt="" />
                Whatsapp Support <i className="fa fa-angle-right" />
              </a>
              <a href="#" className="dropdown-item has-icon" onClick={openChat}>
                <img src="assets/img/livechat-support.svg" alt="" />
                Live Chat Support
              </a>
              <a
                href="#"
                className="dropdown-item has-icon"
                onClick={Helpcenter}
              >
                <img src="assets/img/help-center.svg" alt="" />
                Help Center <i className="fa fa-angle-right" />
              </a>
            </div>
            <div className="nav_links">
              <a href="#" className="dropdown-item has-icon" onClick={Profile}>
                <img src="assets/img/manage-account-icon.svg" alt="" />
                Manage Account
              </a>
              {adminState === "0" &&
              permission?.length !== 0 &&
              userRolee !== "Super Admin" ? null : (
                <>
                  <div
                    className="dropdown-item has-icon"
                    //data-toggle="dropdown"
                    // onClick={toggleDropdown1}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleDropdown1();
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <img src="assets/img/switch-account-icon.svg" alt="" />
                    Switch Account
                    {isDropdownOpen1 && (
                      <div className="inner_dropdown dropdown-menu ">
                        {subdomains.length > 0 ? (
                          subdomains.map((subdomain, index) => (
                            <a
                              key={index}
                              href="#"
                              data-toggle="modal"
                              data-target="#logindomainModal"
                              onClick={() => {
                                SubDomainLink(
                                  subdomain.usersAccountDetail.subDomain
                                );
                              }}
                            >
                              {subdomain.usersAccountDetail.subDomain}
                            </a>
                          ))
                        ) : (
                          <p>No subdomains available.</p>
                        )}

                        {/* {subdomains.map((subdomain, index) => (
                          <a
                            href="#"
                            data-toggle="modal"
                            data-target="#logindomainModal"
                            onClick={() => {
                              SubDomainLink(
                                subdomain.usersAccountDetail.subDomain
                              );
                              //SwitchAccount(subdomain.usersAccountDetail.subDomain);
                              //openModal(subdomain.usersAccountDetail.subDomain)
                            }}
                          >
                            {subdomain.usersAccountDetail.subDomain}
                          </a>
                        ))} */}
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className="nav_user_info">
              <div className="username">
                <h5>
                  {" "}
                  {(adminState === 0 || permission?.length > 0) &&
                    dataUser?.userDetails?.name}
                  {((adminState === 1 || permission?.length === 0) &&
                    data?.userDetails?.userName) ||
                    props?.name}
                </h5>
                {adminState === 1 ||
                  (permission?.length === 0 && (
                    <span>{data?.userDetails?.email}</span>
                  ))}

                {adminState === 0 ||
                  (permission?.length > 0 && (
                    <span>{dataUser?.userDetails?.email}</span>
                  ))}
              </div>

              {!isClockedIn ? (
                <div className="clocked_timeout">
                  <h4>You are clocked out</h4>
                  <p>Clock in to record your hours of work</p>
                  <div className="clock_buttons">
                    <button
                      className="clockinbtn"
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        // handleClockIn();
                        AddClockIn();
                      }}
                    >
                      {" "}
                      {loading1 ? (
                        <i className="fa-solid fa-arrows-rotate"></i>
                      ) : null}{" "}
                      Clock In
                    </button>
                  </div>
                </div>
              ) : !isOnBreak ? (
                <>
                  {" "}
                  <div className="clocked_timeout">
                    <h4>
                      You are clocked in
                      <br />
                      {new Date(elapsedTime * 1000).toISOString().substr(11, 8)}
                      {/* {new Date(elapsedTime * 1000).toISOString().substr(11, 8)} */}
                    </h4>
                    <p>
                      You clocked in today at {startTime?.toLocaleTimeString()}
                    </p>
                    <div className="clock_buttons">
                      <button
                        className="clockoutbtn"
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          //handleClockOut();
                          AddClockOut();
                        }}
                      >
                        {" "}
                        {loading1 ? (
                          <i className="fa-solid fa-arrows-rotate"></i>
                        ) : null}{" "}
                        Clock Out
                      </button>
                      <button
                        className="defaultbtn"
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          // handleTakeBreak();
                          BreakTime();
                        }}
                      >
                        {" "}
                        {loading1 ? (
                          <i className="fa-solid fa-arrows-rotate"></i>
                        ) : null}{" "}
                        Take A Break
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <div className="clocked_timeout">
                  <h4>
                    You are on break
                    <br />
                    {new Date(breakTime * 1000).toISOString().substr(11, 8)}
                  </h4>
                  <p>
                    Your break started at {breakStartTime?.toLocaleTimeString()}
                  </p>
                  <div className="clock_buttons">
                    <button
                      className="defaultbtn"
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        //handleResumeSession();
                        ResumeSession();
                      }}
                    >
                      {" "}
                      {loading1 ? (
                        <i className="fa-solid fa-arrows-rotate"></i>
                      ) : null}{" "}
                      Resume Session
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="nav_links">
              <a href="#" className="dropdown-item has-icon" onClick={Setting}>
                <img src="assets/img/setting-info-icon.svg" alt="" />
                Settings
              </a>
              <a
                href="#"
                className="dropdown-item has-icon signout"
                onClick={logOutt}
              >
                <img src="assets/img/signout-icon.svg" alt="" />
                Sign out
              </a>
            </div>
          </div>
        </li>
      </ul>
    </>
  );
};
export default SideUl;
