import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [navState, setNavState] = useState(0);

  const handleToggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };
  const location = useLocation();

  const isLinkActive = (path) => {
    return location.pathname === path;
  };

  return (
    <header className="header" style={{ backgroundColor: "#84E9FF" }}>
      <div className="container">
        <div className="header_inner">
          <div className="logo">
            <NavLink to="/">
              <img alt="Toolbox Logo" src="img/toolbox-logo-txt.webp" />
            </NavLink>
          </div>
          <nav className="navbar navbar-expand-lg custom_navbar cstmnavbarresponscss">
            <NavLink className="navbar-brand" to="/">
              <img alt="Toolbox Logo" src="img/toolbox-logo-txt.webp" />
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              aria-expanded={!isNavOpen}
              onClick={() => handleToggleNav()}
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="account_btns">
              <NavLink to="/Login" className="cus_link">
                Login
              </NavLink>
              <NavLink
                to="/Signup"
                className="accnt_btn"
                style={{ backgroundColor: "#000" }}
              >
                Open Account
              </NavLink>
            </div>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav customulresponsivecss">
                <li className="nav-item">
                  <NavLink
                    className={`nav-link ${isLinkActive("/") ? "active" : ""}`}
                    to="/"
                    style={{ color: "#000" }}
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={`nav-link ${
                      isLinkActive("/features") ? "active" : ""
                    }`}
                    style={{ color: "#000" }}
                  >
                    Features
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={`nav-link ${
                      isLinkActive("/industries") ? "active" : ""
                    }`}
                    style={{ color: "#000" }}
                  >
                    Industries
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={`nav-link ${
                      isLinkActive("/pricing") ? "active" : ""
                    }`}
                    style={{ color: "#000" }}
                  >
                    Pricing
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={`nav-link ${
                      isLinkActive("/blog") ? "active" : ""
                    }`}
                    style={{ color: "#000" }}
                  >
                    Blog
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={`nav-link ${
                      isLinkActive("/faq") ? "active" : ""
                    }`}
                    style={{ color: "#000" }}
                  >
                    FAQ
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={`nav-link ${
                      isLinkActive("/contact") ? "active" : ""
                    }`}
                    to="/contact"
                    style={{ color: "#000" }}
                  >
                    Contact Us
                  </NavLink>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
