import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { ContactUsApi } from "../Utility/Api";
import Swal from "sweetalert2";
import Maps from "../ContactUs/Maps";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});

  const isDisposableEmail = (email) => {
    const domain = email.split("@")[1];
    return disposableEmailDomains.includes(domain);
  };

  const disposableEmailDomains = [
    // "gmail.com",
    // "yahoo.com",
    // "outlook.com",
    // "hotmail.com",
    // "aol.com",
    // "icloud.com",
    // "mail.com",
    // "yandex.com",
    // "protonmail.com",
    // "zoho.com",
    // "gmx.com",
    // "ymail.com",
    // "comcast.net",
    // "me.com",
    // "msn.com",
    // "live.com",
    // "sbcglobal.net",
    // "verizon.net",
    // "att.net",
    // "cox.net",
    // "smartitventures.com",
    // Add more domains as needed
    "mailinator.com",
    "guerrillamail.com",
    "10minutemail.com",
    "temp-mail.org",
    "yopmail.com",
    "getnada.com",
    "trashmail.com",
    "fakeinbox.com",
    "throwawaymail.com",
    "maildrop.cc",
    "mytemp.email",
    "tempmailo.com",
    "dispostable.com",
    "mintemail.com",
    "airmail.com",
  ];

  const Validations = () => {
    let error = {};
    let reg = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    // let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=])(?=.*\d).{6,}$/;
    // const isValid = /^[0-9]+$/.test(value);
    const regex = /^[A-Za-z ]+$/;
    if (!email) {
      error.email = "Email is required.";
    } else if (!reg.test(email)) {
      error.email = "Invalid email .";
    } else if (email && isDisposableEmail(email)) {
      error.email =
        "Please use a valid email address. Temporary email addresses are not allowed.";
    }

    if (!name) {
      error.name = "Full Name is required.";
    } else if (!regex.test(name)) {
      error.name = "Full Name Is Invalid";
    }
    if (!phoneNumber) {
      error.phoneNumber = "Phone Number is required.";
    }
    if (!message) {
      error.message = "Message is required.";
    }

    if (Object.keys(error).length === 0) {
      handleSubmit();
    }
    return error;
  };

  const Submit = () => {
    setError(Validations());
  };

  const handleSubmit = () => {
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      name,
      email,
      phoneNumber,
      message,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(ContactUsApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result?.status === true) {
          setLoading(false);

          Swal.fire(
            "Your query has been successfully sent to the admin for review. You will receive a response shortly."
          );
          setEmail("");
          setName("");
          setPhoneNumber("");
          setMessage("");
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  return (
    <>
      {loading ? (
        <div className="landing_loader">
          <div className="innerloader">
            <img src="img/spin-1s-156px.gif" alt="" />
          </div>
        </div>
      ) : null}

      <div className="getintouch_sec classcontactuscustmset">
        <div className="container">
          <div className="inner_gettouch">
            <div className="gettouch_left">
              <div className="gettouch_form">
                <h2>
                  Get in <span>Touch</span>
                </h2>
                <p>
                  Enim tempor eget pharetra facilisis sed maecenas adipiscing.
                  Eu leo molestie vel, ornare non id blandit netus.
                </p>
                <form className="touchform">
                  <div className="form_field">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control focustcoantstusinputvstm"
                        name="fullname"
                        placeholder="Name"
                        required=""
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                          if (error.name) {
                            setError({ ...error, name: "" });
                          }
                        }}
                      />
                      {error.name && (
                        <span className="customvalidationdesignlogin">
                          {error.name}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control focustcoantstusinputvstm "
                        name="email"
                        placeholder="Email"
                        required=""
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          if (error.email) {
                            setError({ ...error, email: "" });
                          }
                        }}
                      />
                      {error.email && (
                        <span className="customvalidationdesignlogin">
                          {error.email}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-control no-arrow-input focustcoantstusinputvstm"
                        name="phone"
                        placeholder="Phone Number"
                        required=""
                        value={phoneNumber}
                        onChange={(e) => {
                          setPhoneNumber(e.target.value);
                          if (error.phoneNumber) {
                            setError({ ...error, phoneNumber: "" });
                          }
                        }}
                      />
                      {error.phoneNumber && (
                        <span className="customvalidationdesignlogin">
                          {error.phoneNumber}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control focustcoantstusinputvstm"
                        name="message"
                        placeholder="Enter Your Message here!"
                        value={message}
                        onChange={(e) => {
                          setMessage(e.target.value);
                          if (error.message) {
                            setError({ ...error, message: "" });
                          }
                        }}
                      />
                      {error.message && (
                        <span className="customvalidationdesignlogin">
                          {error.message}
                        </span>
                      )}
                    </div>
                    <div className="form-btn">
                      <input
                        type="button"
                        className="submit_btn"
                        name="submit"
                        defaultValue="Send"
                        onClick={Submit}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="gettouch_info">
                <div className="gettouch_chat">
                  <div className="getouch_call">
                    <h4>Want to call us?</h4>
                    <p>We are here to help.</p>
                  </div>
                  {/* <div className="chat_btn">
                      <a href="#">Chat with us</a>
                    </div> */}
                </div>
                <div className="gettouch_detail">
                  <div className="ques_col ques_call">
                    <div className="info_icon imgiconcontactsetcstm">
                      <img src="img/faq-info-phone.svg" alt="" />
                    </div>
                    <div className="detail">
                      <h5 className="contactphonecustm">
                        <a
                          href="tel:+1 000 123 456"
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          +1 000 123 456
                        </a>
                      </h5>
                      <span>Support Hotline</span>
                    </div>
                  </div>
                  <div className="ques_col ques_call">
                    <div className="info_icon imgiconcontactsetcstm">
                      <img src="img/faq-info-mail.svg" alt="" />
                    </div>
                    <div className="detail">
                      <h5 className="contactphonecustm">
                        {" "}
                        <a
                          href="mailto:info@toolboxpos.com"
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          info@toolboxpos.com
                        </a>
                      </h5>
                      <span>Support Email</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gettouch_right">
              <div className="gettouch_map">
                <Maps />
                {/* <img src="img/google-map-img.png" alt="" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Contact;
