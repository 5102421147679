import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";

import Footer from "../Footer";
import NavBar from "../MainDashboard/NavBar";
import SideUl from "../MainDashboard/SideUl";

import MainSidebar from "../MainDashboard/MainSidebar";
import {
  AddBrandApi,
  AddCarrierApi,
  AddColorApi,
  AddConditionApi,
  AddItemApi,
  AddManufactureApi,
  AddProductApi,
  AddStorageApi,
  AddSupplierApi,
  AddTagsApi,
  GetAllBrandApi,
  GetAllColorApi,
  GetAllConditionApi,
  GetAllItemApi,
  GetAllProductApi,
  GetAllStorageApi,
  GetAllSupplierApi,
  GetManufactureApi,
  GetTagsApi,
  baseurl,
  customerAddApi,
  customerGetApi,
  uploadImageApi,
} from "../Utility/Api";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { Link, useNavigate } from "react-router-dom";

import { parsePhoneNumberFromString } from "libphonenumber-js";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Swal from "sweetalert2";

const ProductModal = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [images, setImages] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [city, SetCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [state, setState] = useState("");
  const [error, setError] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [validstate, setValidState] = useState(false);
  const [cropData, setCropData] = useState("");
  const [cropper, setCropper] = useState();
  const [image, setImage] = useState("");
  const [isValid, setValid] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [filetype, setFileType] = useState("");
  const [selectedState, setSelectedState] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTerm1, setSearchTerm1] = useState("");
  const [searchTermCategory, setSearchTermCategory] = useState("");
  const [searchTermSuppiler, setsearchTermSuppiler] = useState("");
  const [searchTermTags, setsearchTermTags] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [matched, setMatched] = useState([]);
  const [file, setFile] = useState("");
  const [imageMsg, setImageMsg] = useState("");
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [showModal5, setShowModal5] = useState(false);
  const [modalState, setModalState] = useState(0);
  const [addBrandState, setAddBrandState] = useState(false);
  const [brand, setBrand] = useState("");
  const [AllBrand, setAllBrand] = useState([]);
  const [AllBrand1, setAllBrand1] = useState([]);
  const [addSupplyState, setAddSupplyState] = useState(false);
  const [supplier, setSupplier] = useState("");
  const [AllSupplier, setAllSupplier] = useState([]);
  const [SKU, setSKU] = useState("");
  const [AddItemState, setAddItemState] = useState(false);
  const [AddItemCate, setAddItemCate] = useState("");
  const [AllItems, setAllItems] = useState([]);
  const [tags, setTags] = useState("");
  const [TagsState, setTagsState] = useState(false);
  const [AllTags, setAllTags] = useState([]);
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [unitCost, setUnitCost] = useState("");
  const [minimumPrice, setMinimunPrice] = useState("");
  const [averageCost, setAverageCost] = useState("");
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [selectedBrandId1, setSelectedBrandId1] = useState(null);
  const [selectedCateId, setSelectedCateId] = useState(null);
  const [selectedSupplierId, setSelectedSuppierId] = useState(null);
  const [selectedTagIds, setSelectedTagIds] = useState([]);
  const [draftSave, setDraftSave] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errormsg, setErrormsg] = useState("");
  const [showList, setShowList] = useState(false);
  const [showList1, setShowList1] = useState(false);
  const [showListCategory, setShowListCategory] = useState(false);
  const [showListSuppiler, setShowListSuppiler] = useState(false);
  const [showListTags, setShowListTags] = useState(false);
  //const [searchTerm, setSearchTerm] = useState('');
  const [typedText, setTypedText] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSuppiler, setSelectedSuppiler] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [autogenrate, setAutoGenrate] = useState(0);
  const [skuFill, setSKUFill] = useState("");
  const [autogenText, setAutoGenTex] = useState("Auto Generated");
  const [showListAuto, setShowListAuto] = useState(false);
  const [errorBrand, setErrorBrand] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [newOption, setNewOption] = useState("");
  const [options, setOptions] = useState(["Option 1", "Option 2", "Option 3"]);
  //const [selectedBrand, setSelectedBrand] = useState(null);
  const [imeiNumber, setIMEINumber] = useState("");
  const [showListStorage, setShowListStorage] = useState(false);
  const [showListColor, setShowListColor] = useState(false);
  const [showListCondition, setShowListCondition] = useState(false);
  const [showListCarrier, setShowListCarrier] = useState(false);
  const [selectedCondition, setSelectedCondition] = useState(null);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedStorageId, setSelectedStorageId] = useState(null);
  const [selectedColorId, setSelectedColorId] = useState(null);
  const [selectedConditionId, setSelectedConditionId] = useState(null);
  const [selectedCarrierId, setSelectedCarrierId] = useState(null);
  const [allColor, setAllColor] = useState([]);
  const [allStorage, setAllStorage] = useState([]);
  const [allCondition, setAllCondition] = useState([]);
  const [allCarrier, setAllCarrier] = useState([]);
  const [searchTermStorage, setSearchTermStorage] = useState("");
  const [searchTermColor, setsearchTermColor] = useState("");
  const [selectedStorage, setSelectedStorage] = useState(null);
  const [searchTermCondition, setSearchTermCondition] = useState("");
  const [searchTermCarrier, setSearchTermCarrier] = useState("");
  const inputRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const inputRef5 = useRef(null);
  const inputRef6 = useRef(null);
  const inputRef7 = useRef(null);
  const inputRef8 = useRef(null);
  const inputRef9 = useRef(null);
  const inputRef10 = useRef(null);
  const dropdownRef = useRef(null);
  const dropdownRef1 = useRef(null);
  const dropdownRef2 = useRef(null);
  const dropdownRef3 = useRef(null);
  const dropdownRef4 = useRef(null);
  const dropdownRef5 = useRef(null);
  const dropdownRef6 = useRef(null);
  const dropdownRef7 = useRef(null);
  const dropdownRef8 = useRef(null);
  const dropdownRef9 = useRef(null);
  const dropdownRef10 = useRef(null);
  const dropdownRef11 = useRef(null);
  const [showModal9, setShowModal9] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalText, setModalText] = useState("");
  const [descriptionPop, setDescriptionPop] = useState("");

  const openModal = (type, text) => {
    setModalType(type);

    setModalText(text);
    setShowModal9(true);
  };

  const handleAdd = () => {
    if (modalType === "Brand") {
      handleAddNewClick();
    } else if (modalType === "brand") {
      handleAddNewClickDevice();
    } else if (modalType === "Category") {
      handleAddNewClickCategory();
    } else if (modalType === "Manufacturer") {
      handleAddNewClickSupplier();
    } else if (modalType === "Storage") {
      handleAddNewClickStorage();
    } else if (modalType === "Color") {
      handleAddNewClickColor();
    } else if (modalType === "Condition") {
      handleAddNewClickCondition();
    } else if (modalType === "Carrier") {
      handleAddNewClickCarrier();
    } else if (modalType === "Tags") {
      handleAddNewClickTags();
    }

    setShowModal9(false);
  };

  const handleButtonClickStorage = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowListStorage(!showListStorage);
  };

  const filteredStorage = allStorage.filter((brand) => {
    const name = brand.size;
    // Check if 'name' is not null before calling toLowerCase
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermStorage.toLowerCase());
    }

    return false; // Or handle null or non-string values as needed
  });

  const handleSearchChangeStorage = (e) => {
    setSearchTermStorage(e.target.value);
    setTypedText(e.target.value);
    setSelectedStorage(null); // Reset selected brand when typing in the input field
  };

  const handleStorageSelect = (storage) => {
    setSelectedStorage(storage.size);
    setSelectedStorageId(storage.id);
    setShowListStorage(false);
  };

  const handleAddNewClickStorage = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Category name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    //e.preventDefault();
    setSelectedStorage(typedText);
    setShowListStorage(false);
    setSearchTermStorage("");
    setTypedText("");
    AddStorage();
  };

  const filteredCondition = allCondition.filter((item) => {
    const name = item.condition;

    // Check if 'name' is not null before calling toLowerCase
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermCondition.toLowerCase());
    }

    return false;
  });
  const filteredCarrier = allCarrier.filter((item) => {
    const name = item.name;

    // Check if 'name' is not null before calling toLowerCase
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermCarrier.toLowerCase());
    }

    return false;
  });
  const filteredColor = allColor.filter((item) => {
    const name = item.name;

    // Check if 'name' is not null before calling toLowerCase
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermColor.toLowerCase());
    }

    return false;
  });

  const handleSearchChangeColor = (e) => {
    setsearchTermColor(e.target.value);
    setTypedText(e.target.value);
    setSelectedColor(null);
  };

  const handleSearchChangeCondition = (e) => {
    setSearchTermCondition(e.target.value);
    setTypedText(e.target.value);
    setSelectedCondition(null);
  };
  const handleSearchChangeCarrier = (e) => {
    setSearchTermCarrier(e.target.value);
    setTypedText(e.target.value);
    setSelectedCarrier(null);
  };
  const handleColorSelect = (item) => {
    setSelectedColor(item.name);
    setSelectedColorId(item.id);
    setShowListColor(false);
  };

  const handleConditionSelect = (item) => {
    setSelectedCondition(item.condition);
    setSelectedConditionId(item.id);
    setShowListCondition(false);
  };

  const handleCarrierSelect = (brand) => {
    setSelectedCarrier(brand.name);
    setSelectedCarrierId(brand.id);
    setShowListCarrier(false);
  };

  const handleAddNewClickColor = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Color name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    //  e.preventDefault();
    setSelectedColor(typedText);
    setShowListColor(false);
    setsearchTermColor("");
    setTypedText("");
    AddColorDevice();
  };

  const handleAddNewClickCondition = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Condition!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    // e.preventDefault();
    // setSelectedSuppiler(typedText);
    setSelectedCondition(typedText);
    setShowListCondition(false);
    setSearchTermCondition("");
    setTypedText("");
    AddCondition();
  };

  const handleAddNewClickCarrier = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Carrier name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    // e.preventDefault();
    // setSelectedSuppiler(typedText);
    setSelectedCarrier(typedText);
    setShowListCarrier(false);
    setSearchTermCarrier("");
    setTypedText("");
    AddCarrier();
  };

  const AddColorDevice = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      name: typedText,
      description: descriptionPop ? descriptionPop : "",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddColorApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllColor();
          setSelectedColorId(result?.data?.id);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const AddCondition = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3A-_3JwaXdjnqK5jnz_MupfyYwBOQ-xwpN.F15%2BK8LY79Ye8glfaA8Fk2LX1rgoPDz%2BrF7ZAbnGd58"
    );

    var raw = JSON.stringify({
      condition: typedText,
      description: descriptionPop ? descriptionPop : "",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddConditionApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllCondition();
          setSelectedConditionId(result?.data?.id);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GettAllCarrier = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3A-_3JwaXdjnqK5jnz_MupfyYwBOQ-xwpN.F15%2BK8LY79Ye8glfaA8Fk2LX1rgoPDz%2BrF7ZAbnGd58"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllCarrier`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllCarrier(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const AddCarrier = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    var raw = JSON.stringify({
      name: typedText,
      productCategoryId: 2,
      description: descriptionPop ? descriptionPop : "",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddCarrierApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GettAllCarrier();
          setSelectedCarrierId(result?.data?.id);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const GetAllStorage = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllStorageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllStorage(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleButtonClickColor = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowListColor(!showListColor);
  };

  const handleButtonClickCondition = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowListCondition(!showListColor);
  };

  const handleButtonClickCarrier = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowListCarrier(!showListColor);
  };

  const GetAllColor = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllColorApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllColor(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllCondition = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllConditionApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllCondition(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const AddStorage = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    var raw = JSON.stringify({
      size: typedText,
      description: descriptionPop ? descriptionPop : "",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddStorageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllStorage();
          setSelectedStorageId(result?.data?.id);
          setSearchTermStorage("");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowList(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef1.current &&
        !dropdownRef1.current.contains(event.target)
      ) {
        setShowListCategory(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef1]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef11.current &&
        !dropdownRef11.current.contains(event.target)
      ) {
        setShowListAuto(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef11]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef2.current &&
        !dropdownRef2.current.contains(event.target)
      ) {
        setShowListSuppiler(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef2]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef3.current &&
        !dropdownRef3.current.contains(event.target)
      ) {
        setShowListTags(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef3]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef5.current &&
        !dropdownRef5.current.contains(event.target)
      ) {
        setShowList1(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef5]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef6.current &&
        !dropdownRef6.current.contains(event.target)
      ) {
        setShowListStorage(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef6]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef7.current &&
        !dropdownRef7.current.contains(event.target)
      ) {
        setShowListColor(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef7]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef8.current &&
        !dropdownRef8.current.contains(event.target)
      ) {
        setShowListCondition(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef8]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef9.current &&
        !dropdownRef9.current.contains(event.target)
      ) {
        setShowListCarrier(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef9]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef10.current &&
        !dropdownRef10.current.contains(event.target)
      ) {
        setShowListSuppiler(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef10]);

  const filteredBrands = AllBrand.filter((brand) => {
    const name = brand.name;

    // Check if 'name' is not null before calling toLowerCase
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTerm.toLowerCase());
    }

    return false; // Or handle null or non-string values as needed
  });

  const filteredBrands1 = AllBrand1.filter((brand) => {
    const name = brand.name;

    // Check if 'name' is not null before calling toLowerCase
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTerm1.toLowerCase());
    }

    return false; // Or handle null or non-string values as needed
  });

  const handleButtonClickAuto = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowListAuto(!showListAuto);
  };
  const handleButtonClick = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowList(!showList);
  };

  const handleButtonClick1 = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowList1(!showList1);
  };
  const handleButtonClickCategory = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowListCategory(!showListCategory);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setTypedText(e.target.value);
    setSelectedBrand(null);
  };

  const handleSearchChange1 = (e) => {
    setSearchTerm1(e.target.value);
    setTypedText(e.target.value);
    setSelectedBrand1(null);
  };

  const handleBrandSelect = (brand) => {
    setSelectedBrand(brand.name);
    setSelectedBrandId(brand.id);
    setShowList(false);
  };

  const handleBrandSelect1 = (brand) => {
    setSelectedBrand1(brand?.name);
    setSelectedBrandId1(brand.id);
    setShowList1(false);
  };

  const handleAddNewClick = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid brand name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    // e.preventDefault();
    setSelectedBrand(typedText);
    setShowList(false);
    setSearchTerm("");
    setTypedText("");
    AddBrand();
  };

  const filteredCategory = AllItems.filter((brand) => {
    const name = brand.name;

    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermCategory.toLowerCase());
    }

    return false;
  });

  const handleSearchChangeCategory = (e) => {
    setSearchTermCategory(e.target.value);
    setTypedText(e.target.value);
    setSelectedCategory(null);
  };

  const handleCategorySelect = (brand) => {
    setSelectedCategory(brand.name);
    setSelectedCateId(brand.id);
    setShowListCategory(false);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Backspace") {
      setShowList(true);
    }
  };
  const handleAddNewClickCategory = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Category name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    // e.preventDefault();
    setSelectedCategory(typedText);
    setShowListCategory(false);
    setSearchTermCategory("");
    setTypedText("");
    AddItem();
  };

  const filteredSuppiler = AllSupplier.filter((brand) => {
    const name = brand.name;

    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermSuppiler.toLowerCase());
    }

    return false;
  });

  const filteredTags = AllTags.filter((brand) => {
    const name = brand.name;

    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermTags.toLowerCase());
    }

    return false;
  });

  const handleSearchChangeSupplier = (e) => {
    setsearchTermSuppiler(e.target.value);
    setTypedText(e.target.value);
    setSelectedSuppiler(null);
  };
  const handleSearchChangeTags = (e) => {
    setsearchTermTags(e.target.value);

    setTypedText(e.target.value);
  };
  const handleInputClick = () => {
    setShowListTags(!showListTags);
  };
  const handleSupplierSelect = (brand) => {
    setSelectedSuppiler(brand.name);
    setSelectedSuppierId(brand.id);
    setShowListSuppiler(false);
  };

  const handleTagsSelect = (selectedTag) => {
    const isAlreadySelected = selectedTags.some(
      (tag) => tag.id === selectedTag.id
    );

    if (isAlreadySelected) {
      const updatedTags = selectedTags.filter(
        (tag) => tag.id !== selectedTag.id
      );
      setSelectedTags(updatedTags);

      setsearchTermTags("");
    } else {
      setsearchTermTags("");

      setSelectedTags([...selectedTags, selectedTag]);
    }
  };
  const handleTagDelete = (tagId) => {
    const updatedTags = selectedTags.filter((tag) => tag.id !== tagId);
    setSelectedTags(updatedTags);
  };

  const removeSelectedTag = (tagToRemove) => {
    const updatedTags = selectedTags.filter((tag) => tag.id !== tagToRemove.id);
    setSelectedTags(updatedTags);
  };

  const handleAddNewClickSupplier = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Manufacturer name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });

      return;
    }

    //e.preventDefault();
    setSelectedSuppiler(typedText);
    setShowListSuppiler(false);
    setsearchTermSuppiler("");
    setTypedText("");
    AddSuppier();
  };
  const handleAddNewClickTags = () => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Tag name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    // Check if the typed text already exists among the selected tags
    const isExistingTag = selectedTags.some(
      (tag) => tag.name.toLowerCase() === searchTermTags.toLowerCase()
    );

    // If the typed text doesn't already exist among the selected tags, add it as a new tag
    if (!isExistingTag) {
      // Create a new tag object and add it to the selected tags array
      const newTag = { id: "3e3e3", name: searchTermTags };

      // Add a comma separator if there are existing selected tags
      const updatedTags =
        selectedTags.length > 0 ? [...selectedTags, newTag] : [newTag];

      setSelectedTags(updatedTags);
    }
    AddTags();
    // Reset the search term
    setsearchTermTags("");
  };

  const handleButtonClickSupplier = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowListSuppiler(!showListSuppiler);
  };

  const handleButtonClickTags = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowListTags(!showListTags);
  };

  const Validation = () => {
    let error = {};
    const numericRegex = /^[0-9]+$/;
    const priceRegex = /^\d+(\.\d{1,2})?$/;
    if (!title) {
      error.title = "Title Is Required";
    } else if (title.trim().length === 0) {
      error.title = "Title Is Required";
    }

    if (!price) {
      error.price = "Selling Price Is Required";
    } else if (!priceRegex.test(price)) {
      error.price = "Please enter a valid price with up to two decimal points.";
    }
    // else if (!numericRegex.test(price)) {
    //   error.price = "Invalid Contains non-numeric characters";
    // }
    if (!SKU) {
      error.SKU = "SKU Code Is Required";
    }
    if (Object.keys(error).length === 0) {
      // console.log(Object.keys(error).length);
      AddProduct();
    }
    return error;
  };

  const ValidationDevice = () => {
    let error = {};
    const numericRegex = /^[0-9]+$/;
    const priceRegex = /^\d+(\.\d{1,2})?$/;
    if (!title) {
      error.title = "Title Is Required";
    } else if (title.trim().length === 0) {
      error.title = "Title Is Required";
    }

    if (!price) {
      error.price = "Selling Price Is Required";
    } else if (!priceRegex.test(price)) {
      error.price = "Please enter a valid price with up to two decimal points.";
    }
    //  else if (!numericRegex.test(price)) {
    //   error.price = "Invalid Contains non-numeric characters";
    // }
    if (!SKU) {
      error.SKU = "SKU Code Is Required";
    }
    if (Object.keys(error).length === 0) {
      // console.log(Object.keys(error).length);
      AddDevice();
    }
    return error;
  };
  const ValidationDeviceDraft = () => {
    let error = {};
    const numericRegex = /^[0-9]+$/;
    const priceRegex = /^\d+(\.\d{1,2})?$/;
    if (!title) {
      error.title = "Title Is Required";
    } else if (title.trim().length === 0) {
      error.title = "Title Is Required";
    }

    if (!price) {
      error.price = "Selling Price Is Required";
    } else if (!priceRegex.test(price)) {
      error.price = "Please enter a valid price with up to two decimal points.";
    }
    // else if (!numericRegex.test(price)) {
    //   error.price = "Invalid Contains non-numeric characters";
    // }
    if (!SKU) {
      error.SKU = "SKU Code Is Required";
    }
    if (Object.keys(error).length === 0) {
      // console.log(Object.keys(error).length);
      AddDeviceDraft();
    }
    return error;
  };

  const Validationd = () => {
    let error = {};
    const numericRegex = /^[0-9]+$/;
    const priceRegex = /^\d+(\.\d{1,2})?$/;
    if (!title) {
      error.title = "Title Is Required";
    } else if (title.trim().length === 0) {
      error.title = "Title Is Required";
    }

    if (!price) {
      error.price = "Selling Price Is Required";
    } else if (!priceRegex.test(price)) {
      error.price = "Please enter a valid price with up to two decimal points.";
    }
    // else if (!numericRegex.test(price)) {
    //   error.price = "Invalid Contains non-numeric characters";
    // }
    if (!SKU) {
      error.SKU = "SKU Code Is Required";
    }
    if (Object.keys(error).length === 0) {
      // console.log(Object.keys(error).length);
      AddProduct1();
    }
    return error;
  };

  const navigate = useNavigate();

  const AddBrand = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var raw = JSON.stringify({
      name: typedText,
      productCategoryId: 1,
      description: descriptionPop ? descriptionPop : "",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("raw brand product", raw);

    fetch(AddBrandApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("result brand product", result);

        if (result?.status === true) {
          GetAllBrand();
          setSelectedBrandId(result?.data?.id);
          setSearchTerm("");
          setShowModal9(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllBrand = () => {
    let local = localStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllBrand?productCategoryId=1`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllBrand(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const AddSuppier = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    var raw = JSON.stringify({
      name: typedText,
      productCategoryId: 1,
      description: descriptionPop ? descriptionPop : "",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddManufactureApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllSupplier();
          setSelectedSuppierId(result?.data?.id);
          setsearchTermSuppiler("");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllSupplier = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetManufactureApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllSupplier(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const SubmitProduct = () => {
    setError(Validation());
  };
  const SubmitProductdraft = () => {
    setError(Validationd());
  };

  const SubmitDevice = () => {
    setError(ValidationDevice());
  };
  const SubmitDevice1 = () => {
    setError(ValidationDeviceDraft());
  };

  const AddDevice = () => {
    let local = localStorage.getItem("token");
    const tagIdsArray = selectedTags.map((tag) => tag.id.toString());

    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var raw = JSON.stringify({
      //itemCategoryId: selectedCateId,
      productCategoryId: 2,
      brandId: selectedBrandId1,
      manufacturerId: selectedSupplierId,
      title: title,
      image: images,
      activeInactive: 1,
      draft: 0,
      skuCode: SKU,
      description: description,
      //tags: tagIdsArray,
      sellingPrice: price ? price : "0",
      unitCost: 0,
      minimumPrice: minimumPrice ? minimumPrice : "0",
      averageCost: 0,
      storageId: selectedStorageId,
      colorId: selectedColorId,
      conditionId: selectedConditionId,
      imeiNumber: imeiNumber,
      carrierId: selectedCarrierId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddProductApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          navigate("/productlist");
          setTitle("");
          setImages("");
          setDescription("");
          setPrice("");
          setUnitCost("");
          setAverageCost("");
          setMinimunPrice("");
          setSKUFill("");
          setSelectedBrand("");
          //setSelectedCategory("");
          //setSelectedTags([]);
          // setSelectedSuppiler("");
          Swal.fire(result?.message);
        } else {
          // Swal.fire(result?.message);
          Swal.fire({
            title: result?.message,
            customClass: {
              container: "custom-swal-container",

              confirmButton: "custom-swal-confirm-button",
            },
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const AddDeviceDraft = () => {
    let local = localStorage.getItem("token");
    const tagIdsArray = selectedTags.map((tag) => tag.id.toString());

    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var raw = JSON.stringify({
      //itemCategoryId: selectedCateId,
      productCategoryId: 2,
      brandId: selectedBrandId1,
      manufacturerId: selectedSupplierId,
      title: title,
      image: images,
      activeInactive: 1,
      draft: 1,
      skuCode: SKU,
      description: description,
      //tags: tagIdsArray,
      sellingPrice: price ? price : "0",
      unitCost: 0,
      minimumPrice: minimumPrice ? minimumPrice : "0",
      averageCost: 0,
      storageId: selectedStorageId,
      colorId: selectedColorId,
      conditionId: selectedConditionId,
      imeiNumber: imeiNumber,
      carrierId: selectedCarrierId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddProductApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          navigate("/productlist");

          setTitle("");
          setImages("");
          setDescription("");
          setPrice("");
          setUnitCost("");
          setAverageCost("");
          setMinimunPrice("");
          setSKUFill("");
          setSelectedBrand("");
          //setSelectedCategory("");
          //setSelectedTags([]);
          // setSelectedSuppiler("");
          Swal.fire(result?.message);
        } else {
          // Swal.fire(result?.message);
          Swal.fire({
            title: result?.message,
            customClass: {
              container: "custom-swal-container",

              confirmButton: "custom-swal-confirm-button",
            },
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const AddBrandDevice = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var raw = JSON.stringify({
      name: typedText,
      productCategoryId: 2,
      description: descriptionPop ? descriptionPop : "",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("raw brand device", raw);

    fetch(AddBrandApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("result brand device", result);
        if (result?.status === true) {
          GetAllBrandDevice();
          setSelectedBrandId(result?.data?.id);
          setSearchTerm("");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllBrandDevice = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllBrand?productCategoryId=2`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllBrand1(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const HandleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete image",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c76cff",
      cancelButtonColor: "#efefef",
      confirmButtonText: "Yes, delete it!",
      confirmButtonText: "<span style='color: white;'>Yes, delete it!</span>",
      cancelButtonText: "<span style='color: black;'>cancel!</span>",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "sweet-alert2-container-zindex",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
          confirmButtonColor: "#c76cff",
          customClass: {
            container: "sweet-alert2-container-zindex",
          },
        }).then(() => {
          setImages("");
        });
      }
    });
  };

  const generateSKU = () => {
    const sku = Math.floor(100000 + Math.random() * 900000)
      .toString()
      .substring(0, 6);

    setSKU(sku);
    return sku;
  };

  const handleAddNewClickDevice = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Brand name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    // e.preventDefault();
    setSelectedBrand1(typedText);
    setShowList1(false);
    setSearchTerm1("");
    setTypedText("");
    AddBrandDevice();
  };

  useEffect(() => {
    generateSKU();
    GetAllBrand();
    GetAllSupplier();
    GetAllTags();
    GetAllItem();
    GetAllStorage();
    GetAllColor();
    GetAllCondition();
    GettAllCarrier();
    GetAllBrandDevice();
  }, []);

  const AddProduct = () => {
    let local = localStorage.getItem("token");
    const tagIdsArray = selectedTags.map((tag) => tag.id.toString());

    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var raw = JSON.stringify({
      itemCategoryId: selectedCateId,
      productCategoryId: 1,
      brandId: selectedBrandId,
      manufacturerId: selectedSupplierId,
      title: title,
      image: images,
      activeInactive: 1,
      draft: 0,
      skuCode: SKU,
      description: description,
      tags: tagIdsArray,
      sellingPrice: price ? price : "0",
      unitCost: 0,
      minimumPrice: minimumPrice ? minimumPrice : "0",
      averageCost: 0,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddProductApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          navigate("/productlist");
          setTitle("");
          setImages("");
          setDescription("");
          setPrice("");
          setUnitCost("");
          setAverageCost("");
          setMinimunPrice("");
          setSKUFill("");
          setSelectedBrand("");
          setSelectedCategory("");
          setSelectedTags([]);
          setSelectedSuppiler("");
          Swal.fire(result?.message);
        } else {
          // Swal.fire(result?.message);
          Swal.fire({
            title: result?.message,
            customClass: {
              container: "custom-swal-container",

              confirmButton: "custom-swal-confirm-button",
            },
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const AddProduct1 = () => {
    let local = localStorage.getItem("token");
    const tagIdsArray = selectedTags.map((tag) => tag.id.toString());

    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var raw = JSON.stringify({
      itemCategoryId: selectedCateId,
      productCategoryId: 1,
      brandId: selectedBrandId,
      manufacturerId: selectedSupplierId,
      title: title,
      image: images,
      activeInactive: 1,
      draft: 1,
      skuCode: SKU,
      description: description,
      tags: tagIdsArray,
      sellingPrice: price ? price : "0",
      unitCost: 0,
      minimumPrice: minimumPrice ? minimumPrice : "0",
      averageCost: 0,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddProductApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          navigate("/productlist");
          setTitle("");
          setImages("");
          setDescription("");
          setPrice("");
          setUnitCost("");
          setAverageCost("");
          setMinimunPrice("");
          setSKUFill("");
          setSelectedBrand("");
          setSelectedCategory("");
          setSelectedTags([]);
          setSelectedSuppiler("");
          Swal.fire(result?.message);
        } else {
          // Swal.fire(result?.message);
          Swal.fire({
            title: result?.message,
            customClass: {
              container: "custom-swal-container",

              confirmButton: "custom-swal-confirm-button",
            },
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const AddItem = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    var raw = JSON.stringify({
      name: typedText,
      productCategoryId: 1,
      description: descriptionPop ? descriptionPop : "",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddItemApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllItem();
          setSelectedCateId(result?.data?.id);
          setSearchTermCategory("");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllItem = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllItemCategory?productCategoryId=1`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllItems(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const AddTags = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    var raw = JSON.stringify({
      name: typedText,
      productCategoryId: "1",
      description: descriptionPop ? descriptionPop : "",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddTagsApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setSelectedTags([...selectedTags, result.data]);
          GetAllTags();
          setsearchTermTags("");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllTags = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllTag?productCategoryId=1`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllTags(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const [selectedOption, setSelectedOption] = useState(null);
  const [customValue, setCustomValue] = useState("");

  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedBrand1, setSelectedBrand1] = useState(null);

  const handleImages = (e) => {
    var file = e.target.files[0];

    if (file) {
      const minSizeInBytes = 5000; // 5 KB
      const maxSizeInBytes = 1024 * 1024; // 1 MB

      if (file.size < minSizeInBytes) {
        setImageMsg("Image size is below the minimum allowed size (5 KB)");
        e.target.value = null;
        setTimeout(() => {
          setImageMsg("");
        }, 2000);
        return;
      }

      // Check if the file name contains only one dot before the extension
      const fileName = file.name;
      const lastDotIndex = fileName.lastIndexOf(".");
      const baseName = fileName.substring(0, lastDotIndex);
      const extension = fileName.substring(lastDotIndex + 1);

      // Only allow files with a single dot in the base name
      if (baseName.includes(".")) {
        setImageMsg(
          "File name is not valid. Only one dot is allowed before the extension."
        );
        e.target.value = null;
        setTimeout(() => {
          setImageMsg("");
        }, 2000);

        return;
      }

      let size = file.type.replace("/", ".");
      var blob = file.slice(0, file.size, file.type);
      const newName = new Date().getTime() + size;
      var newFile = new File([blob], newName, { type: file.type });

      UploadImage(newFile);
    }
  };

  const handleImagesDevice = (e) => {
    var file = e.target.files[0];

    if (file) {
      const minSizeInBytes = 5000; // 5 KB
      const maxSizeInBytes = 1024 * 1024; // 1 MB

      if (file.size < minSizeInBytes) {
        setImageMsg("Image size is below the minimum allowed size (5 KB)");
        e.target.value = null;
        setTimeout(() => {
          setImageMsg("");
        }, 2000);
        return;
      }

      // Check if the file name contains only one dot before the extension
      const fileName = file.name;
      const lastDotIndex = fileName.lastIndexOf(".");
      const baseName = fileName.substring(0, lastDotIndex);
      const extension = fileName.substring(lastDotIndex + 1);

      // Only allow files with a single dot in the base name
      if (baseName.includes(".")) {
        setImageMsg(
          "File name is not valid. Only one dot is allowed before the extension."
        );
        e.target.value = null;
        setTimeout(() => {
          setImageMsg("");
        }, 2000);

        return;
      }

      let size = file.type.replace("/", ".");
      var blob = file.slice(0, file.size, file.type);
      const newName = new Date().getTime() + size;
      var newFile = new File([blob], newName, { type: file.type });

      UploadImageDevice(newFile);
    }
  };

  const UploadImage = async (newFile) => {
    setLoading(true);
    var formdata = new FormData();
    formdata.append("file", newFile);
    formdata.append("type", "product-images");

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(uploadImageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setImages(result?.data);
        setImageMsg("");
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  const UploadImageDevice = async (newFile) => {
    setLoading(true);
    var formdata = new FormData();
    formdata.append("file", newFile);
    formdata.append("type", "device-images");

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(uploadImageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setImages(result?.data);
        setImageMsg("");
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  const maxDescriptionLength = 200;
  const handleDescriptionChange = (e) => {
    const newDescription = e.target.value;

    if (newDescription.length <= maxDescriptionLength) {
      setDescription(newDescription);
      setErrormsg("");
    } else if (newDescription.length > maxDescriptionLength) {
      setTimeout(() => {
        setErrormsg("Allow Maximum 200 Characters");
      }, 2000);
    }
  };

  const handleSelectChange = (event) => {
    const selectedValues = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    setSelectedOptions(selectedValues);
  };

  const handleInputChange = (event) => {
    setNewOption(event.target.value);
  };

  const handleAddOption = () => {
    if (newOption && !options.includes(newOption)) {
      setOptions([...options, newOption]);
      setSelectedOptions([...selectedOptions, newOption]);
      setNewOption("");
    }
  };

  return (
    <>
      {loading ? (
        <div className="landing_loader">
          <div className="innerloader">
            <img src="img/spin-1s-156px.gif" alt="" />
          </div>
        </div>
      ) : null}
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="createproductModalLabel">
              Create New Product
            </h5>

            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setShowModal4(false);
                document
                  .querySelectorAll(".modal-backdrop")
                  .forEach((el) => el.classList.remove("modal-backdrop"));
                setTitle("");
                setImages("");
                setDescription("");
                setPrice("");
                setUnitCost("");
                setAverageCost("");
                setMinimunPrice("");
                setSKUFill("");
                setSelectedBrand("");
                setSelectedCategory("");
                setSelectedTags([]);
                setSelectedSuppiler("");
              }}
            >
              <span className="close_icon"></span>
            </button>
          </div>
          <div className="modal-body">
            <div className="custom_form createproduct_form">
              <form action="" method="" className="cus_form">
                <div className="form_row product_radio_row">
                  <div className="form_column col_full">
                    <div className="form-group">
                      {modalState === 0 ? (
                        <div
                          className="custom_radio"
                          onClick={() => {
                            setModalState(0);
                          }}
                        >
                          <label className="active">
                            <img
                              className="uncheck"
                              src="assets/img/unchecked-checkbox.webp"
                              alt=""
                            />
                            <img
                              className="check"
                              src="assets/img/checked-checkbox.webp"
                              alt=""
                            />
                            <input
                              type="radio"
                              name="prod_type"
                              value="Standard"
                              defaultChecked
                            />
                            <span className="prodlabel">Standard Product</span>
                          </label>
                        </div>
                      ) : (
                        <div
                          className="custom_radio"
                          onClick={() => {
                            // setShowModal(true);
                            setModalState(0);
                          }}
                        >
                          <label>
                            <img
                              className="uncheck"
                              src="assets/img/unchecked-checkbox.webp"
                              alt=""
                            />
                            <img
                              className="check"
                              src="assets/img/checked-checkbox.webp"
                              alt=""
                            />
                            <input
                              type="radio"
                              name="prod_type"
                              value="Standard"
                              //defaultChecked
                            />
                            <span className="prodlabel">Standard Product</span>
                          </label>
                        </div>
                      )}

                      {modalState === 1 ? (
                        <div
                          className="custom_radio"
                          onClick={() => {
                            setModalState(1);
                          }}
                        >
                          <label className="active">
                            <img
                              className="uncheck"
                              src="assets/img/unchecked-checkbox.webp"
                              alt=""
                            />
                            <img
                              className="check"
                              src="assets/img/checked-checkbox.webp"
                              alt=""
                            />
                            <input
                              type="radio"
                              name="prod_type"
                              value="Device"
                              defaultChecked
                            />
                            <span className="prodlabel">Device</span>
                          </label>
                        </div>
                      ) : (
                        <div
                          className="custom_radio"
                          onClick={() => {
                            setModalState(1);
                          }}
                        >
                          <label>
                            <img
                              className="uncheck"
                              src="assets/img/unchecked-checkbox.webp"
                              alt=""
                            />
                            <img
                              className="check"
                              src="assets/img/checked-checkbox.webp"
                              alt=""
                            />
                            <input
                              type="radio"
                              name="prod_type"
                              value="Device"
                            />
                            <span className="prodlabel">Device</span>
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {modalState === 0 ? (
                  <>
                    <div className="product_row">
                      <div className="product_col70 cus_prod_col">
                        <h4>
                          General information<span>*</span>
                        </h4>
                        <p>
                          To start selling, all you need is a name and a price.
                        </p>
                        <div className="form_row">
                          <div className="form_column col_full">
                            <div className="form-group">
                              <label className="cus_label label_red">
                                Title<span>*</span>
                              </label>
                              <div className="formfield field_wd100">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="title"
                                  placeholder="Enter Title"
                                  value={title}
                                  onChange={(e) => {
                                    setTitle(e.target.value);
                                    if (error.title) {
                                      setError({ ...error, title: "" });
                                    }
                                  }}
                                />{" "}
                              </div>{" "}
                              {error.title && (
                                <span
                                  style={{
                                    fontSize: "12px",
                                    color: "red",
                                    marginLeft: "110px",
                                  }}
                                >
                                  {error.title}
                                </span>
                              )}
                            </div>
                          </div>

                          {/* <div className="form_column col_full">
                            <div className="form-group">
                              <label className="cus_label">Brand</label>
                              <div
                                className="formfield field_wd100 selectcategoryformbox"
                                ref={dropdownRef}
                              >
                                <div className="tagsscustomdiv">
                                  <input
                                    ref={inputRef}
                                    type="text"
                                    className="customsearchinputboxsetting tagsinputcstmwit form-control"
                                    placeholder={`Select Brand`}
                                    value={selectedBrand || searchTerm}
                                    onChange={handleSearchChange}
                                    onClick={() => setShowList(!showList)}
                                  />

                                  <img
                                    src="assets/img/select-arrow.webp"
                                    alt="Handy Icon"
                                    onClick={handleButtonClick}
                                    style={{ cursor: "pointer" }}
                                  />

                                  {showList && (
                                    <div className="addselectbox">
                                      <ul>
                                        {filteredBrands.map((brand) => (
                                          <li
                                            key={brand.id}
                                            onClick={() =>
                                              handleBrandSelect(brand)
                                            }
                                            style={{
                                              cursor: "pointer",
                                            }}
                                          >
                                            {brand.name}
                                          </li>
                                        ))}
                                      </ul>

                                      <button
                                        onClick={() => {
                                          handleAddNewClick();
                                          if (inputRef.current) {
                                            inputRef.current.focus();
                                          }
                                        }}
                                        style={{ backgroundColor: "#c9f4fe" }}
                                        type="button"
                                        className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                      >
                                        <span>+</span> Add New Brand
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <div className="form_column col_full cus_searchdropdown">
                            <div className="form-group">
                              <label className="cus_label">Brand</label>

                              <div
                                className="formfield field_wd100 selectcategoryformbox"
                                ref={dropdownRef}
                              >
                                <div
                                  class="form-control"
                                  bis_skin_checked="1"
                                  onClick={() => setShowList(!showList)}
                                >
                                  {selectedBrand || "Select Brand"}
                                  <img
                                    src="assets/img/select-arrow.webp"
                                    alt="Handy Icon"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                                {showList && (
                                  <>
                                    {" "}
                                    <div className="tagsscustomdiv">
                                      <input
                                        ref={inputRef}
                                        type="text"
                                        className="customsearchinputboxsetting tagsinputcstmwit form-control"
                                        placeholder={`Enter a Brand`}
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                        //onClick={() => setShowList(!showList)}
                                      />
                                      <div className="addselectbox">
                                        <ul>
                                          {filteredBrands.length > 0 ? (
                                            filteredBrands.map((brand) => (
                                              <li
                                                key={brand.id}
                                                onClick={() =>
                                                  handleBrandSelect(brand)
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                {brand.name}
                                              </li>
                                            ))
                                          ) : (
                                            <div
                                              style={{
                                                padding: "8px",
                                                color: "#999",
                                                textAlign: "center",
                                              }}
                                            >
                                              No results matching "{searchTerm}"
                                            </div>
                                          )}
                                        </ul>
                                        {typedText &&
                                          !filteredBrands.includes(
                                            typedText
                                          ) && (
                                            <button
                                              type="button"
                                              className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                              data-toggle="modal"
                                              data-target="#addcustomerModal"
                                              onClick={() => {
                                                openModal("Brand");
                                              }}
                                            >
                                              {" "}
                                              <span>+</span> Add "{typedText}"
                                              as a new brand
                                            </button>
                                          )}
                                      </div>{" "}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="form_column col_full cus_searchdropdown">
                            <div className="form-group">
                              <label className="cus_label">Category</label>

                              <div
                                className="formfield field_wd100  selectcategoryformbox"
                                ref={dropdownRef1}
                              >
                                <div
                                  class="form-control"
                                  bis_skin_checked="1"
                                  onClick={() =>
                                    setShowListCategory(!showListCategory)
                                  }
                                >
                                  {selectedCategory || "Select Category"}
                                  <img
                                    src="assets/img/select-arrow.webp"
                                    alt="Handy Icon"
                                    onClick={handleButtonClickCategory}
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                                {showListCategory && (
                                  <>
                                    <div className="tagsscustomdiv">
                                      <input
                                        ref={inputRef3}
                                        type="text"
                                        className="customsearchinputboxsetting tagsinputcstmwit form-control"
                                        placeholder={`Enter a Category`}
                                        value={searchTermCategory}
                                        onChange={handleSearchChangeCategory}
                                      />
                                      <div className="addselectbox">
                                        <ul>
                                          {" "}
                                          {filteredCategory.length > 0 ? (
                                            filteredCategory.map((brand) => (
                                              <li
                                                key={brand.id}
                                                onClick={() =>
                                                  handleCategorySelect(brand)
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                aria-selected="true"
                                              >
                                                {brand.name}
                                              </li>
                                            ))
                                          ) : (
                                            <div
                                              style={{
                                                padding: "8px",
                                                color: "#999",
                                                textAlign: "center",
                                              }}
                                            >
                                              No results matching "
                                              {searchTermCategory}"
                                            </div>
                                          )}
                                        </ul>
                                        {typedText &&
                                          !filteredCategory.includes(
                                            typedText
                                          ) && (
                                            <button
                                              // className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                              data-toggle="modal"
                                              data-target="#addcustomerModal"
                                              onClick={() => {
                                                openModal("Category");
                                                // handleAddNewClickCategory();
                                                // if (inputRef3.current) {
                                                //   inputRef3.current.focus();
                                                // }
                                              }}
                                              style={{
                                                backgroundColor: "#c9f4fe",
                                              }}
                                              type="button"
                                              className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                            >
                                              <span>+</span> Add "{typedText}"
                                              as a new Category
                                            </button>
                                          )}
                                      </div>{" "}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="form_column col_full cus_searchdropdown">
                            <div className="form-group">
                              <label className="cus_label">Manufacturer</label>

                              <div
                                className="formfield field_wd100 selectcategoryformbox"
                                ref={dropdownRef2}
                              >
                                <div
                                  class="form-control"
                                  bis_skin_checked="1"
                                  onClick={() =>
                                    setShowListSuppiler(!showListSuppiler)
                                  }
                                >
                                  {selectedSuppiler || "Select Manufacturer"}
                                  <img
                                    src="assets/img/select-arrow.webp"
                                    alt="Handy Icon"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                                {showListSuppiler && (
                                  <>
                                    {" "}
                                    <div className="tagsscustomdiv">
                                      <input
                                        ref={inputRef2}
                                        type="text"
                                        className="customsearchinputboxsetting tagsinputcstmwit form-control"
                                        placeholder={`Enter a Manufacturer`}
                                        value={searchTermSuppiler}
                                        onChange={handleSearchChangeSupplier}
                                      />
                                      <div className="addselectbox">
                                        <ul>
                                          {filteredSuppiler.length > 0 ? (
                                            filteredSuppiler.map((brand) => (
                                              <li
                                                key={brand.id}
                                                onClick={() => {
                                                  handleSupplierSelect(brand);
                                                }}
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                {brand.name}
                                              </li>
                                            ))
                                          ) : (
                                            <div
                                              style={{
                                                padding: "8px",
                                                color: "#999",
                                                textAlign: "center",
                                              }}
                                            >
                                              No results matching "
                                              {searchTermSuppiler}"
                                            </div>
                                          )}
                                        </ul>
                                        {typedText &&
                                          !filteredSuppiler.includes(
                                            typedText
                                          ) && (
                                            <button
                                              data-toggle="modal"
                                              data-target="#addcustomerModal"
                                              onClick={() => {
                                                openModal("Manufacturer");
                                                // onClick={() => {
                                                //   handleAddNewClickSupplier();
                                                //   if (inputRef2.current) {
                                                //     inputRef2.current.focus();
                                                //   }
                                              }}
                                              style={{
                                                backgroundColor: "#c9f4fe",
                                              }}
                                              type="button"
                                              className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                            >
                                              <span>+</span> Add "{typedText}"
                                              as a new Manufacturer
                                            </button>
                                          )}
                                      </div>{" "}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          {/* <div className="form_column col_full">
                            <div className="form-group">
                              <label className="cus_label">Manufacturer</label>
                              <div
                                className="formfield field_wd100 selectcategoryformbox"
                                ref={dropdownRef2}
                              >
                                <div className="tagsscustomdiv">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <input
                                      ref={inputRef2}
                                      type="text"
                                      className="customsearchinputboxsetting tagsinputcstmwit form-control"
                                      placeholder={`Select Manufacturer`}
                                      value={
                                        selectedSuppiler || searchTermSuppiler
                                      }
                                      onChange={handleSearchChangeSupplier}
                                      onClick={() =>
                                        setShowListSuppiler(!showListSuppiler)
                                      }
                                    />

                                    <img
                                      src="assets/img/select-arrow.webp"
                                      alt="Handy Icon"
                                      onClick={handleButtonClickSupplier}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>

                                  {showListSuppiler && (
                                    <div className="addselectbox">
                                      <ul>
                                        {filteredSuppiler.map((brand) => (
                                          <li
                                            key={brand.id}
                                            onClick={() => {
                                              handleSupplierSelect(brand);
                                            }}
                                            style={{
                                              cursor: "pointer",
                                            }}
                                          >
                                            {brand.name}
                                          </li>
                                        ))}
                                      </ul>

                                      <button
                                        onClick={() => {
                                          handleAddNewClickSupplier();
                                          if (inputRef2.current) {
                                            inputRef2.current.focus();
                                          }
                                        }}
                                        style={{ backgroundColor: "#c9f4fe" }}
                                        type="button"
                                        className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                      >
                                        <span>+</span> Add New Manufacturer
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <div className="form_column col_full">
                            <div className="form-group">
                              <label className="cus_label">
                                SKU Code type:{" "}
                                <img src="assets/img/info-icon.webp" alt="" />
                              </label>
                              <div
                                className="formfield field_wd100 formfield_col2"
                                ref={dropdownRef11}
                              >
                                <div className="field_wd50">
                                  <div
                                    className="selectcategoryformbox1"
                                    onClick={() =>
                                      setShowListAuto(!showListAuto)
                                    }
                                  >
                                    <div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                        onClick={() =>
                                          setShowListAuto(!showListAuto)
                                        }
                                      >
                                        <span className="customsearchinputboxsetting">
                                          {autogenText
                                            ? autogenText
                                            : "Auto Generated"}
                                        </span>
                                        <button
                                          onClick={handleButtonClickAuto}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <img
                                            src="assets/img/select-arrow.webp"
                                            alt="Handy Icon"
                                          />
                                        </button>
                                      </div>
                                      {showListAuto && (
                                        <div className="addselectbox">
                                          <ul>
                                            <li
                                              onClick={() => {
                                                setShowListAuto(false);
                                                setAutoGenrate(0);
                                                generateSKU();
                                                setAutoGenTex("Auto Generated");
                                              }}
                                              style={{
                                                cursor: "pointer",
                                              }}
                                            >
                                              Auto Generated
                                            </li>
                                            <li
                                              onClick={() => {
                                                setShowListAuto(false);
                                                setAutoGenrate(1);
                                                setSKU("");
                                                setAutoGenTex(
                                                  "Manually Filled"
                                                );
                                              }}
                                              style={{
                                                cursor: "pointer",
                                              }}
                                            >
                                              Manually Filled
                                            </li>
                                          </ul>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {autogenrate === 1 ? (
                                  <div className="field_wd50">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="sku_code"
                                      value={SKU}
                                      placeholder="Enter SKU Code"
                                      onChange={(e) => {
                                        setSKU(e.target.value);
                                        if (error.SKU) {
                                          setError({ ...error, SKU: "" });
                                        }
                                      }}
                                    />
                                    {error.SKU && (
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          color: "red",
                                        }}
                                      >
                                        {error.SKU}
                                      </span>
                                    )}
                                  </div>
                                ) : null}

                                {autogenrate === 0 ? (
                                  <div className="field_wd50">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="sku_code"
                                      placeholder={SKU}
                                      readOnly
                                    />
                                    {error.SKU && (
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          color: "red",
                                        }}
                                      >
                                        {error.SKU}
                                      </span>
                                    )}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="product_col30 cus_prod_col">
                        <h4>Thumbnail</h4>
                        <p>Upload image of product</p>

                        <div className="form_row">
                          <div className="form_column col_full">
                            <div className="form-group">
                              <div className="thumbnail_img">
                                {images ? (
                                  <i
                                    className="fa-regular fa-circle-xmark deletimgcustm1"
                                    onClick={() => HandleDelete()}
                                  ></i>
                                ) : null}
                                {images ? (
                                  <>
                                    <img
                                      src={images}
                                      className="imgescstmdisply"
                                    />
                                  </>
                                ) : (
                                  <>
                                    <input
                                      type="file"
                                      className="form-control"
                                      name="prod_thumnail"
                                      accept="image/jpeg, image/jpg, image/png"
                                      onChange={handleImages}
                                    />
                                    <div className="file_upload">
                                      <img
                                        src="assets/img/thumbnail_img.webp"
                                        alt=""
                                      />
                                      <div className="file_txt">
                                        Drop your file here, or{" "}
                                        <span>click to browse</span>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                              {imageMsg && (
                                <span
                                  style={{
                                    fontSize: "12px",
                                    color: "red",
                                  }}
                                >
                                  {imageMsg}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="product_row">
                      <div className="product_full cus_prod_col">
                        <div className="form_row">
                          <div className="form_column col_full">
                            <div className="form-group tsgddisplycstmmm1">
                              <label className="cus_label label_red">
                                Pricing<span>*</span>{" "}
                                <img src="assets/img/info-icon.webp" alt="" />
                              </label>
                              <div className="formfield pricefield field_wd100">
                                <div className="price_col">
                                  <label className="cus_label label_red">
                                    Selling Price *
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control no-arrow-input"
                                    name="selling_price"
                                    value={price}
                                    onChange={(e) => {
                                      setPrice(e.target.value.trim());

                                      if (error.price) {
                                        setError({ ...error, price: "" });
                                      }
                                    }}
                                  />
                                  {error.price && (
                                    <span
                                      style={{ fontSize: "12px", color: "red" }}
                                    >
                                      {error.price}
                                    </span>
                                  )}
                                  <div className="doller_sign">
                                    <span>$</span>
                                  </div>
                                </div>
                                {/* <div className="price_col">
                                  <label className="cus_label">Unit Cost</label>
                                  <input
                                    type="number"
                                    className="form-control no-arrow-input"
                                    name="unit_cost"
                                    value={unitCost}
                                    onChange={(e) =>
                                      setUnitCost(e.target.value)
                                    }
                                  />
                                  <div className="doller_sign">
                                    <span>$</span>
                                  </div>
                                </div> */}
                                <div className="price_col">
                                  <label className="cus_label">
                                    Minimum Price
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control no-arrow-input"
                                    name="minimum_price"
                                    value={minimumPrice}
                                    onChange={(e) =>
                                      setMinimunPrice(e.target.value)
                                    }
                                  />
                                  <div className="doller_sign">
                                    <span>$</span>
                                  </div>
                                </div>
                                {/* <div className="price_col">
                                  <label className="cus_label">Average Cost</label>
                                  <input
                                    type="number"
                                    className="form-control no-arrow-input"
                                    name="average_cost"
                                    value={averageCost}
                                    onChange={(e) =>
                                      setAverageCost(e.target.value)
                                    }
                                  />
                                  <div className="doller_sign">
                                    <span>$</span>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form_column col_full">
                        <div className="form-group tsgddisplycstmmm">
                          <label className="cus_label"></label>
                          <div className="formfield field_wd100">
                            <div className="tagsdisplaydivcstm">
                              {selectedTags.map((tag) => (
                                <div key={tag.id} className="selected-tag">
                                  <span style={{ marginRight: "5px" }}>
                                    {tag.name}
                                  </span>
                                  <button
                                    className="delete-tag-button"
                                    onClick={() => handleTagDelete(tag.id)}
                                  >
                                    &#x2715;
                                  </button>
                                </div>
                              ))}
                            </div>
                          </div>{" "}
                        </div>
                      </div>
                      <div className="form_column col_full cus_searchdropdown">
                        <div className="form-group">
                          <label className="cus_label">Tags</label>

                          <div
                            className="formfield field_wd100 selectcategoryformbox"
                            ref={dropdownRef3}
                          >
                            <div
                              class="form-control"
                              bis_skin_checked="1"
                              onClick={() => setShowListTags(!showListTags)}
                            >
                              {searchTermTags || "Select Tags"}
                              <img
                                src="assets/img/select-arrow.webp"
                                alt="Handy Icon"
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                            {showListTags && (
                              <>
                                {" "}
                                <div className="tagsscustomdiv">
                                  <input
                                    ref={inputRef1}
                                    type="text"
                                    className="customsearchinputboxsetting tagsinputcstmwit form-control"
                                    placeholder={`Enter a Tags`}
                                    value={searchTermTags}
                                    onChange={handleSearchChangeTags}
                                    //onClick={() => setShowList(!showList)}
                                  />
                                  <div className="addselectbox">
                                    <ul>
                                      {filteredTags.length > 0 ? (
                                        filteredTags.map((brand) => (
                                          <li
                                            key={brand.id}
                                            onClick={() => {
                                              handleTagsSelect(brand);
                                            }}
                                            style={{ cursor: "pointer" }}
                                          >
                                            {brand.name}
                                          </li>
                                        ))
                                      ) : (
                                        <div
                                          style={{
                                            padding: "8px",
                                            color: "#999",
                                            textAlign: "center",
                                          }}
                                        >
                                          No results matching "{searchTermTags}"
                                        </div>
                                      )}
                                    </ul>
                                    {typedText &&
                                      !filteredTags.includes(typedText) && (
                                        <button
                                          data-toggle="modal"
                                          data-target="#addcustomerModal"
                                          onClick={() => {
                                            openModal("Tags");
                                            // onClick={() => {
                                            //   handleAddNewClickTags();
                                            //   if (inputRef1.current) {
                                            //     inputRef1.current.focus();
                                            //   }
                                          }}
                                          style={{ backgroundColor: "#c9f4fe" }}
                                          type="button"
                                          className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                        >
                                          <span>+</span> Add "{typedText}" as a
                                          new Tag
                                        </button>
                                      )}
                                  </div>{" "}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* <div className="form_column col_full">
                        <div className="form-group">
                          <label className="cus_label">Tags</label>
                          <div
                            className="formfield field_wd100 selectcategoryformbox"
                            ref={dropdownRef3}
                          >
                            <div className="tagsscustomdiv">
                           
                              <input
                                ref={inputRef1}
                                type="text"
                                className="customsearchinputboxsetting tagsinputcstmwit form-control"
                                placeholder={`Search Tags`}
                                value={searchTermTags}
                                onChange={handleSearchChangeTags}
                                onClick={handleInputClick}
                              />

                              <img
                                src="assets/img/select-arrow.webp"
                                alt="Handy Icon"
                                onClick={handleButtonClickTags}
                                style={{ cursor: "pointer" }}
                              />

                            
                              {showListTags && (
                                <div className="addselectbox">
                                  <ul>
                                    {filteredTags.map((brand) => (
                                      <li
                                        key={brand.id}
                                        onClick={() => {
                                          handleTagsSelect(brand);
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {brand.name}
                                      </li>
                                    ))}
                                  </ul>

                                  <button
                                    onClick={() => {
                                      handleAddNewClickTags();
                                      if (inputRef1.current) {
                                        inputRef1.current.focus();
                                      }
                                    }}
                                    style={{ backgroundColor: "#c9f4fe" }}
                                    type="button"
                                    className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                  >
                                    <span>+</span> Add New Tag
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div> */}

                      <div className="form_column col_full">
                        <div className="form-group">
                          <label className="cus_label">
                            Description (optional)
                          </label>
                          <div className="formfield field_wd100">
                            <textarea
                              className="form-control"
                              name="description"
                              placeholder="Eneter Description"
                              value={description}
                              onChange={handleDescriptionChange}
                            ></textarea>
                          </div>{" "}
                          {errormsg && (
                            <span
                              style={{
                                fontSize: "12px",
                                color: "red",
                                marginLeft: "110px",
                              }}
                            >
                              {errormsg}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="form_column col_full">
                        <div className="form-group">
                          <div className="taxfield switch_field">
                            <label className="custom-switch">
                              <span className="switch_txt">Default Tax</span>
                              <input
                                type="checkbox"
                                name="custom-switch-checkbox"
                                className="custom-switch-input"
                                checked
                              />
                              <span className="custom-switch-indicator"></span>
                            </label>
                            <label className="custom-switch">
                              <span className="switch_txt">Tax Inclusive</span>
                              <input
                                type="checkbox"
                                name="custom-switch-checkbox"
                                className="custom-switch-input"
                                checked
                              />
                              <span className="custom-switch-indicator"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="product_row">
                      <div className="product_col70 cus_prod_col">
                        <h4>
                          General information<span>*</span>
                        </h4>
                        <p>
                          To start selling, all you need is a name and a price.
                        </p>
                        <div className="form_row">
                          {/* <div className="form_column col_full">
                            <div className="form-group">
                              <label className="cus_label">Brand</label>
                              <div
                                className="formfield field_wd100 selectcategoryformbox"
                                ref={dropdownRef5}
                              >
                                <div className="tagsscustomdiv">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() => setShowList1(!showList1)}
                                  >
                                    <input
                                      ref={inputRef5}
                                      type="text"
                                      className="customsearchinputboxsetting inputdeviceformcustmbox form-control"
                                      placeholder={`Select Brand`}
                                      value={selectedBrand1 || searchTerm1}
                                      onChange={handleSearchChange1}
                                      onClick={() => setShowList1(!showList1)}
                                    />

                                    <img
                                      src="assets/img/select-arrow.webp"
                                      alt="Handy Icon"
                                      onClick={handleButtonClick1}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>

                                  {showList1 && (
                                    <div className="addselectbox">
                                      <ul>
                                        {filteredBrands1.map((brand) => (
                                          <li
                                            key={brand.id}
                                            onClick={() =>
                                              handleBrandSelect1(brand)
                                            }
                                            style={{
                                              cursor: "pointer",
                                            }}
                                          >
                                            {brand.name}
                                          </li>
                                        ))}
                                      </ul>

                                      <button
                                        onClick={() => {
                                          handleAddNewClickDevice();
                                          if (inputRef5.current) {
                                            inputRef5.current.focus();
                                          }
                                        }}
                                        style={{ backgroundColor: "#c9f4fe" }}
                                        type="button"
                                        className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                      >
                                        <span>+</span> Add New Brand
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <div className="form_column col_full cus_searchdropdown">
                            <div className="form-group">
                              <label className="cus_label">Brand</label>

                              <div
                                className="formfield field_wd100 selectcategoryformbox"
                                ref={dropdownRef5}
                              >
                                <div
                                  class="form-control"
                                  bis_skin_checked="1"
                                  onClick={() => setShowList1(!showList1)}
                                >
                                  {selectedBrand1 || "Select Brand"}
                                  <img
                                    src="assets/img/select-arrow.webp"
                                    alt="Handy Icon"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                                {showList1 && (
                                  <>
                                    {" "}
                                    <div className="tagsscustomdiv">
                                      <input
                                        ref={inputRef5}
                                        type="text"
                                        className="customsearchinputboxsetting inputdeviceformcustmbox form-control"
                                        placeholder={`Enter a Brand`}
                                        value={searchTerm1}
                                        onChange={handleSearchChange1}
                                        //onClick={() => setShowList(!showList)}
                                      />
                                      <div className="addselectbox">
                                        <ul>
                                          {filteredBrands1.length > 0 ? (
                                            filteredBrands1.map((brand) => (
                                              <li
                                                key={brand.id}
                                                onClick={() =>
                                                  handleBrandSelect1(brand)
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                {brand.name}
                                              </li>
                                            ))
                                          ) : (
                                            <div
                                              style={{
                                                padding: "8px",
                                                color: "#999",
                                                textAlign: "center",
                                              }}
                                            >
                                              No results matching "{searchTerm1}
                                              "
                                            </div>
                                          )}
                                        </ul>
                                        {typedText &&
                                          !filteredBrands1.includes(
                                            typedText
                                          ) && (
                                            <button
                                              data-toggle="modal"
                                              data-target="#addcustomerModal"
                                              onClick={() => {
                                                openModal("brand");
                                                // onClick={() => {
                                                //   handleAddNewClick();
                                                //   if (inputRef5.current) {
                                                //     inputRef5.current.focus();
                                                //   }
                                              }}
                                              style={{
                                                backgroundColor: "#c9f4fe",
                                              }}
                                              type="button"
                                              className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                            >
                                              <span>+</span> Add "{typedText}"
                                              as a new brand
                                            </button>
                                          )}
                                      </div>{" "}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="form_column col_full">
                            <div className="form-group">
                              <label className="cus_label label_red">
                                Model Name<span>*</span>
                              </label>
                              <div className="formfield field_wd100">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="title"
                                  placeholder="Enter Model Name"
                                  value={title}
                                  onChange={(e) => {
                                    setTitle(e.target.value);
                                    if (error.title) {
                                      setError({ ...error, title: "" });
                                    }
                                  }}
                                />{" "}
                              </div>{" "}
                              {error.title && (
                                <span
                                  style={{
                                    fontSize: "12px",
                                    color: "red",
                                    marginLeft: "110px",
                                  }}
                                >
                                  {error.title}
                                </span>
                              )}
                            </div>
                          </div>
                          {/* <div className="form_column col_full">
                            <div className="form-group">
                              <label className="cus_label">Storage</label>
                              <div
                                className="formfield field_wd100  selectcategoryformbox"
                                ref={dropdownRef6}
                              >
                                <div className="tagsscustomdiv">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() =>
                                      setShowListStorage(!showListStorage)
                                    }
                                  >
                                    <input
                                      ref={inputRef6}
                                      type="text"
                                      className="customsearchinputboxsetting inputdeviceformcustmbox form-control"
                                      placeholder={`Select Storage`}
                                      value={
                                        selectedStorage || searchTermStorage
                                      }
                                      onChange={handleSearchChangeStorage}
                                      onClick={() =>
                                        setShowListStorage(!showListStorage)
                                      }
                                    />

                                    <img
                                      src="assets/img/select-arrow.webp"
                                      alt="Handy Icon"
                                      onClick={handleButtonClickStorage}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>

                                  {showListStorage && (
                                    <div className="addselectbox">
                                      <ul>
                                        {filteredStorage.map((item) => (
                                          <li
                                            key={item.id}
                                            onClick={() =>
                                              handleStorageSelect(item)
                                            }
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            aria-selected="true"
                                          >
                                            {item.size}
                                          </li>
                                        ))}
                                      </ul>

                                      <button
                                        onClick={() => {
                                          handleAddNewClickStorage();
                                          if (inputRef6.current) {
                                            inputRef6.current.focus();
                                          }
                                        }}
                                        style={{ backgroundColor: "#c9f4fe" }}
                                        type="button"
                                        className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                      >
                                        <span>+</span> Add New Storage
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <div className="form_column col_full  cus_searchdropdown">
                            <div className="form-group">
                              <label className="cus_label">Storage</label>

                              <div
                                className="formfield field_wd100 selectcategoryformbox"
                                ref={dropdownRef6}
                              >
                                <div
                                  class="form-control"
                                  bis_skin_checked="1"
                                  onClick={() =>
                                    setShowListStorage(!showListStorage)
                                  }
                                >
                                  {selectedStorage || "Select Storage"}
                                  <img
                                    src="assets/img/select-arrow.webp"
                                    alt="Handy Icon"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                                {showListStorage && (
                                  <>
                                    {" "}
                                    <div className="tagsscustomdiv">
                                      <input
                                        ref={inputRef6}
                                        type="text"
                                        className="customsearchinputboxsetting inputdeviceformcustmbox form-control"
                                        placeholder={`Enter a Storage`}
                                        value={searchTermStorage}
                                        onChange={handleSearchChangeStorage}
                                      />
                                      <div className="addselectbox">
                                        <ul>
                                          {filteredStorage.length > 0 ? (
                                            filteredStorage.map((item) => (
                                              <li
                                                key={item.id}
                                                onClick={() =>
                                                  handleStorageSelect(item)
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                aria-selected="true"
                                              >
                                                {item.size}
                                              </li>
                                            ))
                                          ) : (
                                            <div
                                              style={{
                                                padding: "8px",
                                                color: "#999",
                                                textAlign: "center",
                                              }}
                                            >
                                              No results matching "
                                              {searchTermStorage}"
                                            </div>
                                          )}
                                        </ul>
                                        {typedText &&
                                          !filteredStorage.includes(
                                            typedText
                                          ) && (
                                            <button
                                              data-toggle="modal"
                                              data-target="#addcustomerModal"
                                              onClick={() => {
                                                openModal("Storage");
                                                // onClick={() => {
                                                //   handleAddNewClickStorage();
                                                //   if (inputRef6.current) {
                                                //     inputRef6.current.focus();
                                                //   }
                                              }}
                                              style={{
                                                backgroundColor: "#c9f4fe",
                                              }}
                                              type="button"
                                              className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                            >
                                              <span>+</span> Add "{typedText}"
                                              as a new Storage
                                            </button>
                                          )}
                                      </div>{" "}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>

                          {/* <div className="form_column col_full">
                            <div className="form-group">
                              <label className="cus_label">Colour</label>
                              <div
                                className="formfield field_wd100 selectcategoryformbox"
                                ref={dropdownRef7}
                              >
                                <div className="tagsscustomdiv">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() =>
                                      setShowListColor(!showListColor)
                                    }
                                  >
                                    <input
                                      ref={inputRef7}
                                      type="text"
                                      className="customsearchinputboxsetting inputdeviceformcustmbox form-control"
                                      placeholder={`Select Colour`}
                                      value={selectedColor || searchTermColor}
                                      onChange={handleSearchChangeColor}
                                      onClick={() =>
                                        setShowListColor(!showListColor)
                                      }
                                    />

                                    <img
                                      src="assets/img/select-arrow.webp"
                                      alt="Handy Icon"
                                      onClick={handleButtonClickColor}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>

                                  {showListColor && (
                                    <div className="addselectbox">
                                      <ul>
                                        {filteredColor.map((item) => (
                                          <li
                                            key={item.id}
                                            onClick={() => {
                                              handleColorSelect(item);
                                            }}
                                            style={{
                                              cursor: "pointer",
                                            }}
                                          >
                                            {item.name}
                                          </li>
                                        ))}
                                      </ul>

                                      <button
                                        onClick={() => {
                                          handleAddNewClickColor();
                                          if (inputRef7.current) {
                                            inputRef7.current.focus();
                                          }
                                        }}
                                        style={{ backgroundColor: "#c9f4fe" }}
                                        type="button"
                                        className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                      >
                                        <span>+</span> Add New Colour
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <div className="form_column col_full cus_searchdropdown">
                            <div className="form-group">
                              <label className="cus_label">Colour</label>

                              <div
                                className="formfield field_wd100 selectcategoryformbox"
                                ref={dropdownRef7}
                              >
                                <div
                                  class="form-control"
                                  bis_skin_checked="1"
                                  onClick={() =>
                                    setShowListColor(!showListColor)
                                  }
                                >
                                  {selectedColor || "Select Colour"}
                                  <img
                                    src="assets/img/select-arrow.webp"
                                    alt="Handy Icon"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                                {showListColor && (
                                  <>
                                    {" "}
                                    <div className="tagsscustomdiv">
                                      <input
                                        ref={inputRef7}
                                        type="text"
                                        className="customsearchinputboxsetting inputdeviceformcustmbox form-control"
                                        placeholder={`Enter a Colour`}
                                        value={searchTermColor}
                                        onChange={handleSearchChangeColor}
                                      />
                                      <div className="addselectbox">
                                        <ul>
                                          {filteredColor.length > 0 ? (
                                            filteredColor.map((item) => (
                                              <li
                                                key={item.id}
                                                onClick={() => {
                                                  handleColorSelect(item);
                                                }}
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                {item.name}
                                              </li>
                                            ))
                                          ) : (
                                            <div
                                              style={{
                                                padding: "8px",
                                                color: "#999",
                                                textAlign: "center",
                                              }}
                                            >
                                              No results matching "
                                              {searchTermColor}"
                                            </div>
                                          )}
                                        </ul>
                                        {typedText &&
                                          !filteredColor.includes(
                                            typedText
                                          ) && (
                                            <button
                                              data-toggle="modal"
                                              data-target="#addcustomerModal"
                                              onClick={() => {
                                                openModal("Colour");
                                                // onClick={() => {
                                                //   handleAddNewClickColor();
                                                //   if (inputRef7.current) {
                                                //     inputRef7.current.focus();
                                                //   }
                                              }}
                                              style={{
                                                backgroundColor: "#c9f4fe",
                                              }}
                                              type="button"
                                              className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                            >
                                              <span>+</span> Add "{typedText}"
                                              as a new Colour
                                            </button>
                                          )}
                                      </div>{" "}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          {/* <div className="form_column col_full">
                            <div className="form-group">
                              <label className="cus_label">Condition</label>
                              <div
                                className="formfield field_wd100 selectcategoryformbox"
                                ref={dropdownRef8}
                              >
                                <div className="tagsscustomdiv">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() =>
                                      setShowListCondition(!showListCondition)
                                    }
                                  >
                                    <input
                                      ref={inputRef8}
                                      type="text"
                                      className="customsearchinputboxsetting inputdeviceformcustmbox form-control"
                                      placeholder={`Select Condition`}
                                      value={
                                        selectedCondition || searchTermCondition
                                      }
                                      onChange={handleSearchChangeCondition}
                                      onClick={() =>
                                        setShowListCondition(!showListCondition)
                                      }
                                    />

                                    <img
                                      src="assets/img/select-arrow.webp"
                                      alt="Handy Icon"
                                      onClick={handleButtonClickCondition}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>

                                  {showListCondition && (
                                    <div className="addselectbox">
                                      <ul>
                                        {filteredCondition.map((item) => (
                                          <li
                                            key={item.id}
                                            onClick={() => {
                                              handleConditionSelect(item);
                                            }}
                                            style={{
                                              cursor: "pointer",
                                            }}
                                          >
                                            {item.condition}
                                          </li>
                                        ))}
                                      </ul>

                                      <button
                                        onClick={() => {
                                          handleAddNewClickCondition();
                                          if (inputRef8.current) {
                                            inputRef8.current.focus();
                                          }
                                        }}
                                        style={{ backgroundColor: "#c9f4fe" }}
                                        type="button"
                                        className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                      >
                                        <span>+</span> Add New Condition
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <div className="form_column col_full cus_searchdropdown">
                            <div className="form-group">
                              <label className="cus_label">Condition</label>

                              <div
                                className="formfield field_wd100 selectcategoryformbox"
                                ref={dropdownRef8}
                              >
                                <div
                                  class="form-control"
                                  bis_skin_checked="1"
                                  onClick={() =>
                                    setShowListCondition(!showListCondition)
                                  }
                                >
                                  {selectedCondition || "Select Condition"}
                                  <img
                                    src="assets/img/select-arrow.webp"
                                    alt="Handy Icon"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                                {showListCondition && (
                                  <>
                                    {" "}
                                    <div className="tagsscustomdiv">
                                      <input
                                        ref={inputRef8}
                                        type="text"
                                        className="customsearchinputboxsetting inputdeviceformcustmbox form-control"
                                        placeholder={`Enter a Condition`}
                                        value={searchTermCondition}
                                        onChange={handleSearchChangeCondition}
                                      />
                                      <div className="addselectbox">
                                        <ul>
                                          {filteredCondition.length > 0 ? (
                                            filteredCondition.map((item) => (
                                              <li
                                                key={item.id}
                                                onClick={() => {
                                                  handleConditionSelect(item);
                                                }}
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                {item.condition}
                                              </li>
                                            ))
                                          ) : (
                                            <div
                                              style={{
                                                padding: "8px",
                                                color: "#999",
                                                textAlign: "center",
                                              }}
                                            >
                                              No results matching "
                                              {searchTermCondition}"
                                            </div>
                                          )}
                                        </ul>
                                        {typedText &&
                                          !filteredCondition.includes(
                                            typedText
                                          ) && (
                                            <button
                                              data-toggle="modal"
                                              data-target="#addcustomerModal"
                                              onClick={() => {
                                                openModal("Condition");
                                                // onClick={() => {
                                                //   handleAddNewClickCondition();
                                                //   if (inputRef8.current) {
                                                //     inputRef8.current.focus();
                                                //   }
                                              }}
                                              style={{
                                                backgroundColor: "#c9f4fe",
                                              }}
                                              type="button"
                                              className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                            >
                                              <span>+</span> Add "{typedText}"
                                              as a new Condition
                                            </button>
                                          )}
                                      </div>{" "}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          {/* <div className="form_column col_full">
                            <div className="form-group">
                              <label className="cus_label">Carrier</label>
                              <div
                                className="formfield field_wd100 selectcategoryformbox"
                                ref={dropdownRef9}
                              >
                                <div className="tagsscustomdiv">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() =>
                                      setShowListCarrier(!showListCarrier)
                                    }
                                  >
                                    <input
                                      ref={inputRef9}
                                      type="text"
                                      className="customsearchinputboxsetting inputdeviceformcustmbox form-control"
                                      placeholder={`Select Carrier`}
                                      value={
                                        selectedCarrier || searchTermCarrier
                                      }
                                      onChange={handleSearchChangeCarrier}
                                      onClick={() =>
                                        setShowListCarrier(!showListCarrier)
                                      }
                                    />

                                    <img
                                      src="assets/img/select-arrow.webp"
                                      alt="Handy Icon"
                                      onClick={handleButtonClickCarrier}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>

                                  {showListCarrier && (
                                    <div className="addselectbox">
                                      <ul>
                                        {filteredCarrier.map((item) => (
                                          <li
                                            key={item.id}
                                            onClick={() => {
                                              handleCarrierSelect(item);
                                            }}
                                            style={{
                                              cursor: "pointer",
                                            }}
                                          >
                                            {item.name}
                                          </li>
                                        ))}
                                      </ul>

                                      <button
                                        onClick={() => {
                                          handleAddNewClickCarrier();
                                          if (inputRef9.current) {
                                            inputRef9.current.focus();
                                          }
                                        }}
                                        style={{ backgroundColor: "#c9f4fe" }}
                                        type="button"
                                        className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                      >
                                        <span>+</span> Add New Carrier
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <div className="form_column col_full cus_searchdropdown">
                            <div className="form-group">
                              <label className="cus_label">Carrier</label>

                              <div
                                className="formfield field_wd100 selectcategoryformbox"
                                ref={dropdownRef9}
                              >
                                <div
                                  class="form-control"
                                  bis_skin_checked="1"
                                  onClick={() =>
                                    setShowListCarrier(!showListCarrier)
                                  }
                                >
                                  {selectedCarrier || "Select Carrier"}
                                  <img
                                    src="assets/img/select-arrow.webp"
                                    alt="Handy Icon"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                                {showListCarrier && (
                                  <>
                                    {" "}
                                    <div className="tagsscustomdiv">
                                      <input
                                        ref={inputRef9}
                                        type="text"
                                        className="customsearchinputboxsetting inputdeviceformcustmbox form-control"
                                        placeholder={`Enter a Carrier`}
                                        value={searchTermCarrier}
                                        onChange={handleSearchChangeCarrier}
                                      />
                                      <div className="addselectbox">
                                        <ul>
                                          {filteredCarrier.length > 0 ? (
                                            filteredCarrier.map((item) => (
                                              <li
                                                key={item.id}
                                                onClick={() => {
                                                  handleCarrierSelect(item);
                                                }}
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                {item.name}
                                              </li>
                                            ))
                                          ) : (
                                            <div
                                              style={{
                                                padding: "8px",
                                                color: "#999",
                                                textAlign: "center",
                                              }}
                                            >
                                              No results matching "
                                              {searchTermCarrier}"
                                            </div>
                                          )}
                                        </ul>
                                        {typedText &&
                                          !filteredCarrier.includes(
                                            typedText
                                          ) && (
                                            <button
                                              data-toggle="modal"
                                              data-target="#addcustomerModal"
                                              onClick={() => {
                                                openModal("Carrier");
                                                // onClick={() => {
                                                //   handleAddNewClickCarrier();
                                                //   if (inputRef9.current) {
                                                //     inputRef9.current.focus();
                                                //   }
                                              }}
                                              style={{
                                                backgroundColor: "#c9f4fe",
                                              }}
                                              type="button"
                                              className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                            >
                                              <span>+</span> Add "{typedText}"
                                              as a new Carrier
                                            </button>
                                          )}
                                      </div>{" "}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          {/* <div className="form_column col_full">
                            <div className="form-group">
                              <label className="cus_label">Manufacturer</label>
                              <div
                                className="formfield field_wd100 selectcategoryformbox"
                                ref={dropdownRef10}
                              >
                                <div className="tagsscustomdiv">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() =>
                                      setShowListSuppiler(!showListSuppiler)
                                    }
                                  >
                                    <input
                                      ref={inputRef10}
                                      type="text"
                                      className="customsearchinputboxsetting inputdeviceformcustmbox form-control"
                                      placeholder={`Select Manufacturer`}
                                      value={
                                        selectedSuppiler || searchTermSuppiler
                                      }
                                      onChange={handleSearchChangeSupplier}
                                      onClick={() => setShowListSuppiler(true)}
                                    />

                                    <img
                                      src="assets/img/select-arrow.webp"
                                      alt="Handy Icon"
                                      onClick={handleButtonClickSupplier}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>

                                  {showListSuppiler && (
                                    <div className="addselectbox">
                                      <ul>
                                        {filteredSuppiler.map((brand) => (
                                          <li
                                            key={brand.id}
                                            onClick={() => {
                                              handleSupplierSelect(brand);
                                            }}
                                            style={{
                                              cursor: "pointer",
                                            }}
                                          >
                                            {brand.name}
                                          </li>
                                        ))}
                                      </ul>

                                      <button
                                        onClick={() => {
                                          handleAddNewClickSupplier();
                                          if (inputRef10.current) {
                                            inputRef10.current.focus();
                                          }
                                        }}
                                        style={{ backgroundColor: "#c9f4fe" }}
                                        type="button"
                                        className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                      >
                                        <span>+</span> Add New Manufacturer
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <div className="form_column col_full cus_searchdropdown">
                            <div className="form-group">
                              <label className="cus_label">Manufacturer</label>

                              <div
                                className="formfield field_wd100 selectcategoryformbox"
                                ref={dropdownRef10}
                              >
                                <div
                                  class="form-control"
                                  bis_skin_checked="1"
                                  onClick={() =>
                                    setShowListSuppiler(!showListSuppiler)
                                  }
                                >
                                  {selectedSuppiler || "Select Manufacturer"}
                                  <img
                                    src="assets/img/select-arrow.webp"
                                    alt="Handy Icon"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                                {showListSuppiler && (
                                  <>
                                    {" "}
                                    <div className="tagsscustomdiv">
                                      <input
                                        ref={inputRef10}
                                        type="text"
                                        className="customsearchinputboxsetting inputdeviceformcustmbox form-control"
                                        placeholder={`Enter a Manufacturer`}
                                        value={searchTermSuppiler}
                                        onChange={handleSearchChangeSupplier}
                                      />
                                      <div className="addselectbox">
                                        <ul>
                                          {filteredSuppiler.length > 0 ? (
                                            filteredSuppiler.map((brand) => (
                                              <li
                                                key={brand.id}
                                                onClick={() => {
                                                  handleSupplierSelect(brand);
                                                }}
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                {brand.name}
                                              </li>
                                            ))
                                          ) : (
                                            <div
                                              style={{
                                                padding: "8px",
                                                color: "#999",
                                                textAlign: "center",
                                              }}
                                            >
                                              No results matching "
                                              {searchTermSuppiler}"
                                            </div>
                                          )}
                                        </ul>
                                        {typedText &&
                                          !filteredSuppiler.includes(
                                            typedText
                                          ) && (
                                            <button
                                              data-toggle="modal"
                                              data-target="#addcustomerModal"
                                              onClick={() => {
                                                openModal("Manufacturer");
                                                // onClick={() => {
                                                //   handleAddNewClickSupplier();
                                                //   if (inputRef10.current) {
                                                //     inputRef10.current.focus();
                                                //   }
                                              }}
                                              style={{
                                                backgroundColor: "#c9f4fe",
                                              }}
                                              type="button"
                                              className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                            >
                                              <span>+</span> Add "{typedText}"
                                              as a new Manufacturer
                                            </button>
                                          )}
                                      </div>{" "}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          {/* <div className="form_column col_full">
                            <div className="form-group">
                              <label className="cus_label">IMEI/Serial</label>
                              <div className="formfield field_wd100">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="serialno"
                                  placeholder="Enter IMEI/Serial"
                                  value={imeiNumber}
                                  onChange={(e) =>
                                    setIMEINumber(e.target.value.trim())
                                  }
                                />
                              </div>
                            </div>
                          </div> */}
                          <div className="form_column col_full">
                            <div className="form-group">
                              <label className="cus_label">
                                SKU Code type:{" "}
                                <img src="assets/img/info-icon.webp" alt="" />
                              </label>
                              <div
                                className="formfield field_wd100 formfield_col2"
                                ref={dropdownRef11}
                              >
                                <div
                                  className="field_wd50"
                                  onClick={() => setShowListAuto(!showListAuto)}
                                >
                                  <div
                                    className="selectcategoryformbox1"
                                    onClick={() =>
                                      setShowListAuto(!showListAuto)
                                    }
                                  >
                                    <div
                                      onClick={() =>
                                        setShowListAuto(!showListAuto)
                                      }
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                        onClick={() =>
                                          setShowListAuto(!showListAuto)
                                        }
                                      >
                                        <span className="customsearchinputboxsetting">
                                          {autogenText
                                            ? autogenText
                                            : "Auto Generated"}
                                        </span>
                                        <button
                                          onClick={handleButtonClickAuto}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <img
                                            src="assets/img/select-arrow.webp"
                                            alt="Handy Icon"
                                          />
                                        </button>
                                      </div>
                                      {showListAuto && (
                                        <div className="addselectbox">
                                          <ul>
                                            <li
                                              onClick={() => {
                                                setShowListAuto(false);
                                                setAutoGenrate(0);
                                                generateSKU();
                                                setAutoGenTex("Auto Generated");
                                              }}
                                              style={{
                                                cursor: "pointer",
                                              }}
                                            >
                                              Auto Generated
                                            </li>
                                            <li
                                              onClick={() => {
                                                setShowListAuto(false);
                                                setAutoGenrate(1);
                                                setAutoGenTex(
                                                  "Manually Filled"
                                                );
                                              }}
                                              style={{
                                                cursor: "pointer",
                                              }}
                                            >
                                              Manually Filled
                                            </li>
                                          </ul>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {autogenrate === 1 ? (
                                  <div className="field_wd50">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="sku_code"
                                      value={SKU}
                                      placeholder="Enter SKU Code"
                                      onChange={(e) => {
                                        setSKU(e.target.value);
                                        if (error.SKU) {
                                          setError({ ...error, SKU: "" });
                                        }
                                      }}
                                    />
                                    {error.SKU && (
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          color: "red",
                                        }}
                                      >
                                        {error.SKU}
                                      </span>
                                    )}
                                  </div>
                                ) : null}

                                {autogenrate === 0 ? (
                                  <div className="field_wd50">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="sku_code"
                                      placeholder={SKU}
                                      readOnly
                                    />
                                    {error.SKU && (
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          color: "red",
                                        }}
                                      >
                                        {error.SKU}
                                      </span>
                                    )}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="product_col30 cus_prod_col">
                        <h4>Thumbnail</h4>
                        <p>Upload image of product</p>

                        <div className="form_row">
                          <div className="form_column col_full">
                            <div className="form-group">
                              <div className="thumbnail_img">
                                {images ? (
                                  <i
                                    className="fa-regular fa-circle-xmark deletimgcustm1"
                                    onClick={() => HandleDelete()}
                                  ></i>
                                ) : null}
                                {images ? (
                                  <>
                                    <img
                                      src={images}
                                      className="imgescstmdisply"
                                    />
                                  </>
                                ) : (
                                  <>
                                    <input
                                      type="file"
                                      className="form-control"
                                      name="prod_thumnail"
                                      accept="image/jpeg, image/jpg, image/png"
                                      onChange={handleImagesDevice}
                                    />
                                    <div className="file_upload">
                                      <img
                                        src="assets/img/thumbnail_img.webp"
                                        alt=""
                                      />
                                      <div className="file_txt">
                                        Drop your file here, or{" "}
                                        <span>click to browse</span>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                              {imageMsg && (
                                <span
                                  style={{
                                    fontSize: "12px",
                                    color: "red",
                                  }}
                                >
                                  {imageMsg}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="product_row">
                      <div className="product_full cus_prod_col">
                        <div className="form_row">
                          <div className="form_column col_full">
                            <div className="form-group tsgddisplycstmmm1">
                              <label className="cus_label label_red">
                                Pricing<span>*</span>{" "}
                                <img src="assets/img/info-icon.webp" alt="" />
                              </label>
                              <div className="formfield pricefield field_wd100">
                                <div className="price_col">
                                  <label className="cus_label label_red">
                                    Selling Price *
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control no-arrow-input"
                                    name="selling_price"
                                    value={price}
                                    onChange={(e) => {
                                      setPrice(e.target.value);

                                      if (error.price) {
                                        setError({ ...error, price: "" });
                                      }
                                    }}
                                  />
                                  {error.price && (
                                    <span
                                      style={{ fontSize: "12px", color: "red" }}
                                    >
                                      {error.price}
                                    </span>
                                  )}
                                  <div className="doller_sign">
                                    <span>$</span>
                                  </div>
                                </div>
                                {/* <div className="price_col">
                                  <label className="cus_label">Unit Cost</label>
                                  <input
                                    type="number"
                                    className="form-control no-arrow-input"
                                    name="unit_cost"
                                    value={unitCost}
                                    onChange={(e) =>
                                      setUnitCost(e.target.value)
                                    }
                                  />
                                  <div className="doller_sign">
                                    <span>$</span>
                                  </div>
                                </div> */}
                                <div className="price_col">
                                  <label className="cus_label">
                                    Minimum Price
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control no-arrow-input"
                                    name="minimum_price"
                                    value={minimumPrice}
                                    onChange={(e) =>
                                      setMinimunPrice(e.target.value)
                                    }
                                  />
                                  <div className="doller_sign">
                                    <span>$</span>
                                  </div>
                                </div>
                                {/* <div className="price_col">
                                  <label className="cus_label">Average Cost</label>
                                  <input
                                    type="number"
                                    className="form-control no-arrow-input"
                                    name="average_cost"
                                    value={averageCost}
                                    onChange={(e) =>
                                      setAverageCost(e.target.value)
                                    }
                                  />
                                  <div className="doller_sign">
                                    <span>$</span>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form_column col_full">
                        <div className="form-group">
                          <label className="cus_label">
                            Description (optional)
                          </label>
                          <div className="formfield field_wd100">
                            <textarea
                              className="form-control"
                              name="description"
                              placeholder="Enter Description"
                              value={description}
                              onChange={handleDescriptionChange}
                            ></textarea>
                          </div>{" "}
                          {errormsg && (
                            <span
                              style={{
                                fontSize: "12px",
                                color: "red",
                                marginLeft: "110px",
                              }}
                            >
                              {errormsg}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="form_column col_full">
                        <div className="form-group">
                          <div className="taxfield switch_field">
                            <label className="custom-switch">
                              <span className="switch_txt">Default Tax</span>
                              <input
                                type="checkbox"
                                name="custom-switch-checkbox"
                                className="custom-switch-input"
                                checked
                              />
                              <span className="custom-switch-indicator"></span>
                            </label>
                            <label className="custom-switch">
                              <span className="switch_txt">Tax Inclusive</span>
                              <input
                                type="checkbox"
                                name="custom-switch-checkbox"
                                className="custom-switch-input"
                                checked
                              />
                              <span className="custom-switch-indicator"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="clearfix"></div>
              </form>
            </div>
          </div>
          {modalState === 0 ? (
            <>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary default_btn"
                  //data-dismiss="modal"
                  onClick={() => {
                    //setDraftSave(1);
                    SubmitProductdraft();
                  }}
                >
                  Save as draft
                </button>
                <button
                  type="button"
                  className="btn btn-primary theme_btn"
                  onClick={() => {
                    SubmitProduct();
                    //setDraftSave(0);
                  }}
                >
                  Publish Product
                </button>
              </div>
            </>
          ) : (
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary default_btn"
                //data-dismiss="modal"
                onClick={() => {
                  SubmitDevice1();
                }}
              >
                Save as draft
              </button>
              <button
                type="button"
                className="btn btn-primary theme_btn"
                onClick={() => SubmitDevice()}
              >
                Publish Product
              </button>
            </div>
          )}
        </div>
      </div>
      {showModal9 === true ? (
        <div
          className="modal fade common_modal addcustomer_modal"
          id="addcustomerModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="addcustomerModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="addcustomerModalLabel">
                  Add {modalType}
                </h5>
                <button
                  type="button"
                  className="close"
                  //data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setShowModal9(false);
                    document
                      .querySelectorAll(".modal-backdrop")
                      .forEach((el) => el.classList.remove("modal-backdrop"));
                  }}
                >
                  <span className="close_icon" />
                </button>
              </div>
              <div className="modal-body">
                <div className="custom_form customer_form">
                  <form action="" method="" className="cus_form">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form_column col_full">
                          <label className="cus_label">{modalType} Name</label>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={`Enter a ${modalType}`}
                              value={typedText}
                              onChange={(e) => {
                                setTypedText(e.target.value);
                                // if (error.firstName) {
                                //   setError({ ...error, firstName: "" });
                                // }
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form_column col_full">
                        <div className="form-group">
                          <label className="cus_label">
                            Description (optional)
                          </label>
                          <div className="formfield field_wd100">
                            <textarea
                              className="form-control"
                              name="description"
                              placeholder="Enter Description"
                              value={descriptionPop}
                              onChange={(e) =>
                                setDescriptionPop(e.target.value)
                              }
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary default_btn"
                  //data-dismiss="modal"
                  onClick={() => {
                    setShowModal9(false);

                    document
                      .querySelectorAll(".modal-backdrop")
                      .forEach((el) => el.classList.remove("modal-backdrop"));
                  }}
                >
                  Cancel
                </button>

                <button
                  type="button"
                  className="btn btn-primary theme_btn"
                  onClick={() => {
                    handleAdd();
                  }}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default ProductModal;
