import React, { useEffect, useState } from "react";
import MainSidebar from "./MainSidebar";
import SideUl from "./SideUl";
import { Helmet } from "react-helmet";
import Footer from "../Footer";
import {
  baseurl,
  GetInvoices,
  GetMessagesApi,
  getProfileApi,
  GetRecieptApi,
  GetSubUserProfileApi,
} from "../Utility/Api";
import moment from "moment";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import FreeTrail from "./FreeTrail";
import BannerMessage from "./BannerMessage";

const InvoiceList = () => {
  const [selectedFilter, setSelectedFilter] = useState("Filter Table");
  const [InVoiceData, setInVoiceData] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermProduct, setSearchTermProduct] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSortAsc, setIsSortAsc] = useState(false);
  const [filteredDataa, setFilteredData] = useState([]);
  const [selectedCategory4, setSelectedCategory4] = useState("default");
  const [showModal7, setShowModal7] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState({});
  const [data, setData] = useState({});
  const [printType, setPrintType] = useState();
  const [title, setTitle] = useState("");
  const [logo, setLogo] = useState("");
  const [isLogo, setIsLogo] = useState(false);
  const [logoSize, setLogoSize] = useState(0);
  const [logoPosition, setLogoPosition] = useState(0);
  const [header, setHeader] = useState("");
  const [displySales, setDisplaySales] = useState(false);
  const [footer, setFooter] = useState("");
  const [note, setNote] = useState(false);
  const [displayBarcode, setDisplayBarcode] = useState(false);
  const [displayNote, setDisplayNote] = useState(false);
  const [states, setStates] = useState(false);
  const [reciept, setReciept] = useState(false);
  // const [leftMargin, setLeftMargin] = useState("");
  // const [rightMargin, setRightMargin] = useState("");
  const [leftMargin, setLeftMargin] = useState("");
  const [rightMargin, setRightMargin] = useState("");

  const [selectedOption, setSelectedOption] = useState({
    prodname: 0,
    prodname_sku: 0,
    sku: 0,
  });
  const [customerDetails, setCustomerDetails] = useState({
    fullname: 0,
    email: 0,
    phone: 0,
    address: 0,
  });
  const [recieptId, setRecieptId] = useState("");
  const [data1, setData1] = useState([]);
  const [dataUser, setDataUser] = useState([]);
  const [adminState, setAdminState] = useState("");
  const [permission, setPermission] = useState([]);
  const [gstShow, setGstShow] = useState();
  const [messageData, setMessageData] = useState();

  const GetProfile = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(getProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setData1(result?.data);
          // setStatus(
          //   result?.data?.comapany?.subscriptionStatus === "canceled" ||
          //     result?.data?.comapany?.subscriptionStatus === null ||
          //     result?.data?.comapany?.subscriptionStatus === "expired"
          // );

          // if (
          //   result?.data?.comapany?.subscriptionStatus === "canceled" ||
          //   result?.data?.comapany?.subscriptionStatus === null
          // ) {
          //   navigate("/settings", { state: { activeState: 1 } });
          // } else if (
          //   result?.data?.comapany?.subscriptionStatus === "complete"
          // ) {
          //   setSub(2);
          // } else {
          //   setSub(1);
          // }
        } else {
          // console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetSubUserProfile = () => {
    let local = localStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetSubUserProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setDataUser(result?.data);
        } else {
          //console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const isDisposableEmail = (email) => {
    const domain = email.split("@")[1];
    return disposableEmailDomains.includes(domain);
  };

  const disposableEmailDomains = [
    "mailinator.com",
    "guerrillamail.com",
    "10minutemail.com",
    "temp-mail.org",
    "yopmail.com",
    "getnada.com",
    "trashmail.com",
    "fakeinbox.com",
    "throwawaymail.com",
    "maildrop.cc",
    "mytemp.email",
    "tempmailo.com",
    "dispostable.com",
    "mintemail.com",
    "airmail.com",
  ];
  const Validation = () => {
    let error = {};
    let reg = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    // let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (!email) {
      error.email = "Email is required.";
    } else if (!reg.test(email)) {
      error.email = "Invalid email .";
    } else if (email && isDisposableEmail(email)) {
      error.email =
        "Please use a valid email address. Temporary email addresses are not allowed.";
    }
    if (Object.keys(error).length === 0) {
      EmailSend1();
    }
    return error;
  };

  const OnSubmit = () => {
    setError(Validation());
  };

  const handlePageClick = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleSearchTermChangeP = (e) => {
    setSearchTermProduct(e.target.value);
  };

  const getStatusName = (value) => {
    switch (value) {
      case 1:
        return "Sold";
      case 0:
        return "Parked";
      default:
        return "Select a status";
    }
  };

  // const filteredData =
  //   selectedCategory4 === "default"
  //     ? InVoiceData
  //     : InVoiceData.filter(
  //         (item) =>
  //           selectedCategory4 === "default" ||
  //           item?.activeInactive === parseInt(selectedCategory4) ||
  //           selectedCategory4 === "Refund" ||
  //           item?.isReturn === 1
  //       );
  const filteredData =
    selectedCategory4 === "default"
      ? InVoiceData
      : InVoiceData.filter((item) => {
          if (selectedCategory4 === "Refund") {
            return item?.isReturn === 1;
          } else {
            return item?.activeInactive === parseInt(selectedCategory4);
          }
        });

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const filteredByCustomer = filteredData.filter((item) => {
    const customerFirstName = item?.customer?.firstName || "";
    const customerLastName = item?.customer?.lastName || "";

    return (
      customerFirstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      customerLastName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const currentData = filteredByCustomer
    .slice(startIndex, endIndex)
    .filter((item) => {
      return (
        item.itemIds &&
        item.itemIds.some((itemId) =>
          itemId.title.toLowerCase().includes(searchTermProduct.toLowerCase())
        )
      );
    });

  const handleGetInVoices = () => {
    setLoading(true);
    let local = localStorage.getItem("token");

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetInvoices, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          console.log("invoicedata", result?.data);

          const sortedData = result?.data.sort(
            (a, b) => new Date(a.timeZone) - new Date(b.timeZone)
          );

          console.log(sortedData);

          setInVoiceData(result?.data.reverse());
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const toggleRow = (invoiceId) => {
    setExpandedRows((prevExpandedRows) => {
      // Close all other rows except the one clicked
      const newExpandedRows = {};
      newExpandedRows[invoiceId] = !prevExpandedRows[invoiceId]; // Toggle the clicked row
      return newExpandedRows;
    });
  };

  // const toggleRow = (invoiceId) => {
  //   setExpandedRows((prevExpandedRows) => ({
  //     ...prevExpandedRows,
  //     [invoiceId]: !prevExpandedRows[invoiceId],
  //   }));
  // };
  useEffect(() => {
    GetMessages();
    let localAdmin = localStorage.getItem("admin");
    let localPerminssion = JSON.parse(localStorage.getItem("permission"));

    if (localAdmin && localPerminssion.length) {
      setAdminState(localAdmin);
      setPermission(localPerminssion);

      GetSubUserProfile();
    }
    GetProfile();

    handleGetInVoices();
    GetReciept();
  }, []);
  const printSetup = () => {
    Swal.fire("You have not  Setup Receipt Configuration ");
  };
  const printTable = () => {
    const printContents = document.querySelector(".reciepttable").innerHTML;
    const originalContents = document.body.innerHTML;
    // Calculate the position to center the new window
    const width = 800;
    const height = 600;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    // Open a new window with centered position
    const printWindow = window.open(
      "",
      "_blank",
      `width=${width},height=${height},top=${top},left=${left}`
    );

    if (!printWindow) {
      Swal.fire(
        "Unable to open print window. Please check your pop-up settings."
      );
      return;
    }

    // const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write(`
    <html>
      <head>
     <style>
     @media print {
  @page {
    margin-top: 0px; /* Set top margin to 0px */
  margin-bottom: 0px; /* Set bottom margin to 0px *
        margin-left: ${leftMargin}px;
    margin-right: ${rightMargin}px;
   
  }
  body {
 /* Remove margins on body as well */
     margin-left: ${leftMargin}px;
    margin-right: ${rightMargin}px;
     height:auto
  }
  /* Hide browser print headers and footers */
  header,
  footer {
    display: none;
  }
}
     </style>

     </head>
      <body>${printContents}</body>
    </html>
  `);
    printWindow.document.close();
    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 200); // Adjust the delay as needed
    // printWindow.print();
    // printWindow.close();
  };

  // const printTable = () => {
  //   const printContents = document.querySelector(".reciepttable").innerHTML;
  //   const originalContents = document.body.innerHTML;
  //   document.body.innerHTML = printContents;
  //   window.print();
  //   document.body.innerHTML = originalContents;
  // };

  const handleFilterChange1 = (event) => {
    const selectedValue = event.currentTarget.textContent.trim();

    if (selectedValue === "Filter By ID") {
      const sortedData = [...InVoiceData].sort((a, b) => {
        const nameA = `${a.id}`.toLowerCase();
        const nameB = `${b.id}`.toLowerCase();

        if (isSortAsc) {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });

      setInVoiceData(sortedData);
      setIsSortAsc(!isSortAsc);
      setSelectedFilter("Filter By ID");
    } else if (selectedValue === "Filter By Date") {
      const sortedData = [...InVoiceData].sort((a, b) => {
        const nameA = `${a.createdAt}`.toLowerCase();
        const nameB = `${b.createdAt}`.toLowerCase();

        if (isSortAsc) {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });

      setInVoiceData(sortedData);
      setIsSortAsc(!isSortAsc);
      setSelectedFilter("Filter By Date");
    } else {
      setSelectedFilter("Filter Table");
    }
  };

  const handleStatusChange = (value) => {
    setSelectedCategory4(value);

    //setCurrentPage(1);
    if (value === "default") {
      setFilteredData(filteredData);
    } else if (value === "Refund") {
      setFilteredData(filteredData.filter((item) => item?.isReturn === 1));
    } else {
      setFilteredData(
        filteredData.filter((item) => item?.activeInactive === parseInt(value))
      );
    }
  };

  const EmailSend = (item) => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: item?.customer?.firstName,
      email: item?.customer?.email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("dgahdahg", raw);

    fetch(`${baseurl}sendInvoiceMail/${item?.id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("emaill", result);
        if (result?.status === true) {
          setLoading(false);
          // Swal.fire("Receipt sent to email successfully! ");
          Swal.fire({
            title: "Receipt sent to email successfully!!",
            customClass: {
              container: "custom-swal-container",

              confirmButton: "custom-swal-confirm-button",
            },
          });
        } else {
          setLoading(false);
          Swal.fire({
            title: result.message,
            customClass: {
              container: "custom-swal-container",

              confirmButton: "custom-swal-confirm-button",
            },
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const EmailSend1 = () => {
    setLoading(true);
    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));

    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: data?.customer?.firstName ? data?.customer?.firstName : "user",
      email: email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseurl}sendInvoiceMail/${data?.id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setLoading(false);
          Swal.fire("Receipt sent to email successfully!! ");
          // Swal.fire({
          //   title: "Receipt sent to email successfully!!",
          //   customClass: {
          //     container: "custom-swal-container",
          //     confirmButton: "custom-swal-confirm-button",
          //   },
          // });
          setShowModal7(false);
          document
            .querySelectorAll(".modal-backdrop")
            .forEach((el) => el.classList.remove("modal-backdrop"));
          setEmail("");
        } else {
          setLoading(false);
          Swal.fire(result.message);
          // Swal.fire({
          //   title: result.message,
          //   customClass: {
          //     container: "custom-swal-container",
          //     confirmButton: "custom-swal-confirm-button",
          //   },
          // });
          setEmail("");
          setShowModal7(false);
          document
            .querySelectorAll(".modal-backdrop")
            .forEach((el) => el.classList.remove("modal-backdrop"));
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };
  const [gstNumber, setSGTNumber] = useState("");
  const GetReciept = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetRecieptApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("reciept", result?.data);
        if (result?.status === true) {
          if (!result.data) {
            setReciept(true);
          } else {
            setStates(true);
            const data = result?.data;
            setRecieptId(data?.id);
            setPrintType(data.type);
            setTitle(data.receiptTitle);
            setLogo(data?.logo);
            setIsLogo(data.isLogo === 1);
            setLogoSize(data.logoSize);
            setLogoPosition(data.logoPosition);
            setHeader(data.headerInfo);
            setDisplaySales(data.displaySalesPersonName === 1);
            setFooter(data.footerInfo);
            setNote(data.displayPublicNote === 1);
            setDisplayBarcode(data.displayBarcode === 1);
            setDisplayNote(data.note === 1);
            setSGTNumber(data?.gst);
            setLeftMargin(Number(data?.leftMargin) || 5);
            setRightMargin(Number(data?.rightMargin) || 5);
            console.log("left margin", data?.leftMargin);
            console.log("right margin", data?.rightMargin);

            setGstShow(data?.gstButton);
            // setImageMsg(""); // Assuming image message is not returned from API
            //setStates(data.activeInactive === 1);

            // Set selected options based on API response
            setSelectedOption({
              prodname: data.items.name === 1 ? 1 : 0,
              prodname_sku:
                data.items.name === 1 && data.items.sku === 1 ? 1 : 0,
              sku: data.items.sku === 1 ? 1 : 0,
            });

            // Set customer details based on API response
            setCustomerDetails({
              fullname: data.customer.name === 1 ? 1 : 0,
              email: data.customer.email === 1 ? 1 : 0,
              phone: data.customer.phoneNumber === 1 ? 1 : 0,
              address: data.customer.address === 1 ? 1 : 0,
            });
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const startPage = Math.max(currentPage - 2, 1);
  const endPage = Math.min(startPage + 3, totalPages);

  const pages = Array.from({ length: totalPages }, (_, index) => index + 1);
  const [isFreeTrial, setIsFreeTrial] = useState(false);

  useEffect(() => {
    const substatus = localStorage.getItem("substatus");
    const planDate = localStorage.getItem("planDate");

    if (
      (substatus === "null" ||
        substatus === "canceled" ||
        substatus === "expired") &&
      new Date(localStorage.getItem("planDate")) > new Date()
    ) {
      setIsFreeTrial(true);
    }
  }, []);

  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
    localStorage.setItem("sidebar", !isSidebarVisible);
  };

  useEffect(() => {
    const savedSidebarState = localStorage.getItem("sidebar");
    if (savedSidebarState) {
      setSidebarVisible(JSON.parse(savedSidebarState));
    }
  }, []);

  const [isFreeTrialVisible, setFreeTrialVisible] = useState(true);

  const toggleFreeTrial = () => {
    setFreeTrialVisible(!isFreeTrialVisible);
  };

  const sidebarStyleone = {
    overflow: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    // position: 'sticky',

    // marginTop: isFreeTrialVisible ? "0px" : "0px",
  };

  const navbarStyle = {
    // top: isFreeTrialVisible ? "-4px" : "-50px",
  };
  const mainContentStyle = {
    // marginTop: isFreeTrialVisible ? "132px" : "92px",
  };

  useEffect(() => {
    if (isFreeTrial) {
      // toggleSidebar(false);
      toggleFreeTrial(false);
    } else {
      // toggleSidebar(true);
      toggleFreeTrial(true);
    }
  }, [isFreeTrial]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      const sidebarElement = document.querySelector(".main-sidebar");
      const toggleButtonElement = document.querySelector(".sidebar-arrow a");

      if (
        sidebarElement &&
        !sidebarElement.contains(event.target) &&
        toggleButtonElement &&
        !toggleButtonElement.contains(event.target)
      ) {
        const mediaQuery = window.matchMedia("(max-width: 1024px)");
        if (mediaQuery.matches) {
          setSidebarVisible(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const GetMessages = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(GetMessagesApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        if (result?.status === true) {
          const bannerData = result?.data?.bannerText;
          setMessageData(bannerData);
        } else {
          // Swal.fire(
          //   "To ensure you see the most recent changes, please clear your browser's cache"
          // );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div className={isSidebarVisible ? "sidebar-mini" : ""}>
        <div
          className={`${
            isFreeTrial || messageData
              ? "main-wrapper devicelistcstmtableres customclswrapper"
              : "main-wrapper devicelistcstmtableres "
          } `}
        >
          <Helmet>
            <title>Streamline Invoicing Process | Toolbox Invoice List</title>
            <meta
              name="description"
              content="Enhance your invoicing process with our Invoice List tool. Streamline transactions, track payments, and maintain financial clarity for your business."
            />
          </Helmet>

          {loading ? (
            <div className="landing_loader">
              <div className="innerloader">
                <img src="img/spin-1s-156px.gif" alt="" />
              </div>
            </div>
          ) : null}
          {isFreeTrial ? (
            <div className="app-container-trial">
              <FreeTrail />
            </div>
          ) : messageData ? (
            <div className="app-container-trial bannercssmsg">
              <BannerMessage />
            </div>
          ) : null}
          <div style={sidebarStyleone}>
            <nav className="navbar navbar-expand-lg main-navbar sticky">
              <div className="sidebar-arrow">
                <a
                  href="#"
                  data-toggle="sidebar"
                  onClick={(e) => {
                    toggleSidebar();

                    e.preventDefault();
                  }}
                >
                  <img src="assets/img/sidebar-toggle.webp" alt="" />
                </a>
              </div>
              <div className="header-title mr-auto">
                <h4>
                  Invoices{" "}
                  <a
                    className="notes_icon"
                    href="#"
                    data-toggle="modal"
                    data-target="#noteinfoModal"
                  >
                    <img src="assets/img/notes-icon.svg" alt="" />
                  </a>
                </h4>
              </div>
              <SideUl />
            </nav>
            <MainSidebar />
            <div className="main-content tableback">
              <div className="common_filter_sec invoice_filter">
                <div className="filter_col">
                  <div className="filter_tble_btn">
                    <div className="dropdown customerlistfiltercstmresp">
                      <button
                        type="button"
                        className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting"
                        href="#"
                        role="button"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        //onClick={handleFilterChange1}
                      >
                        {selectedFilter === "default"
                          ? "Filter Table"
                          : selectedFilter}
                      </button>
                      <img
                        src="assets/img/filter-icon.webp"
                        alt=""
                        className="filtericonimgboxsetting"
                      />
                      <ul
                        className="dropdown-menu customdropdownulboxsetting"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={handleFilterChange1}
                          >
                            Filter Table
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={handleFilterChange1}
                          >
                            Filter By ID
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={handleFilterChange1}
                          >
                            Filter By Date
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="search_field">
                    <div className="search_icon">
                      <img src="assets/img/search-icon.webp" alt="" />
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Find Products"
                      value={searchTermProduct}
                      onChange={handleSearchTermChangeP}
                    />
                  </div>
                  <div className="search_field">
                    <div className="search_icon">
                      <img src="assets/img/cust-profile-icon.webp" alt="" />
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Customer"
                      value={searchTerm}
                      onChange={handleSearchTermChange}
                    />
                  </div>
                  <div className="ft_select_option status_option">
                    <div className=" option_20 customdropdownulboxsuppl">
                      <div className="dropdown customtabledropdownboxsuppl">
                        <button
                          type="button"
                          className="btn btn-secondary dropdown-toggle customdropdowntablebtnsettinginvoice textaligncustmtext"
                          role="button"
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {getStatusName(selectedCategory4)}
                        </button>
                        <ul
                          className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => handleStatusChange("default")}
                            >
                              Select a status
                            </a>
                          </li>

                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => handleStatusChange(1)}
                            >
                              Sold
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => handleStatusChange(0)}
                            >
                              Parked
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => handleStatusChange("Refund")}
                            >
                              Refund
                            </a>
                          </li>
                        </ul>
                        <img
                          className="invoicearrowicon"
                          src="assets/img/select-arrow.webp"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="imp_exp_btn export_btn">
                    <button type="button">
                      <img src="assets/img/export-icon.webp" alt="" /> Export
                    </button>
                  </div>
                </div>
              </div>
              <div className="clearfix" />
              <div className="row">
                <div className="col-lg-12 col-md-12 ">
                  <div className="customertablemain">
                    <table
                      className="table table-striped common_datatable invoicedata_table"
                      id="customerdata"
                    >
                      <thead>
                        <tr>
                          <th style={{ width: "60px" }}>Ref#</th>
                          <th>Date</th>
                          <th>Time</th>
                          <th>Customer</th>
                          <th>Sold by:</th>
                          <th>Note</th>
                          <th>Sales Total</th>
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentData.length ? (
                          <>
                            {currentData.map((invoice) => {
                              const subtotal = parseFloat(invoice.subTotal);
                              const discountPercentage = parseFloat(
                                invoice.discount
                              );
                              const taxRate = parseFloat(invoice.tax);

                              const discountedSubtotal =
                                subtotal * (1 - discountPercentage / 100);
                              const taxAmount =
                                discountedSubtotal * (taxRate / 100);
                              const overallTotal1 =
                                discountedSubtotal + taxAmount;
                              let time = new Date(invoice?.createdAt);
                              return (
                                <>
                                  <tr
                                    className="tr_view"
                                    key={invoice?.id}
                                    onClick={() => toggleRow(invoice?.id)}
                                  >
                                    <td className="text-center">
                                      <img
                                        className="arrow_down"
                                        src="assets/img/table-tr-arrow.webp"
                                        alt=""
                                      />{" "}
                                      {invoice?.id}
                                    </td>
                                    <td>
                                      {" "}
                                      {moment(
                                        invoice?.timeZone,
                                        "YYYY/MM/DD"
                                      ).format("DD/MM/YYYY")}
                                    </td>
                                    <td>
                                      {/* {time.toLocaleTimeString([], {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })} */}
                                      {/* {moment(invoice.createdAt, ["HH.mm"]).format(
                                    "hh:mm a"
                                  )}{" "} */}
                                      {/* {moment
                                        .utc(invoice?.timeZone)
                                        .local()
                                        .format("LT")} */}
                                      {moment(invoice?.timeZone).format("LT")}
                                    </td>
                                    <td>
                                      {invoice?.customer?.firstName}{" "}
                                      {invoice?.customer?.lastName}
                                    </td>
                                    {invoice?.isAdmin === 0 ? (
                                      <td>{invoice?.subuser?.name}</td>
                                    ) : (
                                      <td>{invoice?.user?.userName}</td>
                                    )}
                                    {invoice?.activeInactive === 1 ? (
                                      <td title={invoice?.note}>
                                        {invoice?.note}
                                      </td>
                                    ) : (
                                      <td title={JSON.parse(invoice?.parkNote)}>
                                        {JSON.parse(invoice?.parkNote)}
                                      </td>
                                    )}

                                    {/* <td>${invoice.grandTotal.toFixed(2)}</td> */}
                                    <td>
                                      {invoice?.isReturn === 1 ? (
                                        <span>
                                          - ${invoice?.grandTotal.toFixed(2)}
                                        </span>
                                      ) : (
                                        <span>
                                          ${invoice?.grandTotal.toFixed(2)}
                                        </span>
                                      )}
                                    </td>

                                    <td className="status_td st_active">
                                      {invoice?.activeInactive === 1 ? (
                                        <span>
                                          {invoice?.isReturn === 1
                                            ? "Refund"
                                            : "Sold"}
                                        </span>
                                      ) : (
                                        <span>
                                          {invoice?.isReturn === 1
                                            ? "Refund"
                                            : "Parked"}
                                        </span>
                                      )}
                                    </td>
                                    {/* <td className="status_td st_active">
                                  {invoice?.activeInactive === 1 ? (
                                    <span>Sold</span>
                                  ) : (
                                    <span>Parked</span>
                                  )}
                                </td> */}
                                    {/* <td>
                                  {invoice?.activeInactive === 1 ? (
                                    <a href="#" className="refresh_btn">
                                      <img
                                        src="assets/img/refresh-icon.webp"
                                        alt=""
                                      />
                                    </a>
                                  ) : (
                                    <a href="#" className="refresh_btn">
                                      <img
                                        src="assets/img/share-icon.webp"
                                        alt=""
                                      />
                                    </a>
                                  )}
                                </td> */}
                                    <td>
                                      {invoice?.activeInactive === 1 ? (
                                        <a href="#" className="refresh_btn">
                                          {invoice?.isReturn === 1 ? (
                                            <img
                                              src="assets/img/refundicon.webp"
                                              alt="Refund"
                                              className="refundiconimagecss"
                                            />
                                          ) : (
                                            <img
                                              src="assets/img/refresh-icon.webp"
                                              alt="Refresh"
                                            />
                                          )}
                                        </a>
                                      ) : (
                                        <a href="#" className="refresh_btn">
                                          {invoice?.isReturn === 1 ? (
                                            <img
                                              src="assets/img/refundicon.webp"
                                              alt="Refund"
                                              className="refundiconimagecss"
                                            />
                                          ) : (
                                            <img
                                              src="assets/img/share-icon.webp"
                                              alt="Share"
                                            />
                                          )}
                                        </a>
                                      )}
                                    </td>
                                  </tr>

                                  {expandedRows[invoice?.id] && (
                                    <>
                                      <tr className="tr_fold">
                                        <td className="inner_td" colspan="9">
                                          <div className="arrow_up">
                                            <img
                                              src="assets/img/table-tr-arrow.webp"
                                              alt=""
                                            />
                                          </div>
                                          <div className="invoice_detail">
                                            <div className="invoice_tabledata">
                                              <h5>Detail</h5>
                                              <table className="table">
                                                <thead>
                                                  <tr>
                                                    <th></th>
                                                    <th>ITEM(s)</th>
                                                    <th>Qty.</th>
                                                    <th>Discount</th>
                                                    <th>Total Price</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {invoice?.items?.map(
                                                    (item, index) => {
                                                      const correspondingItem =
                                                        invoice?.itemIds?.find(
                                                          (itemId) =>
                                                            itemId?.id ===
                                                            item?.itemId
                                                        );
                                                      // console.log(
                                                      //   "parked data",
                                                      //   correspondingItem?.title
                                                      // );
                                                      return (
                                                        <tr key={index}>
                                                          <td>
                                                            #{item?.itemId}
                                                          </td>
                                                          <td>
                                                            <a href="#">
                                                              {correspondingItem
                                                                ? correspondingItem?.title
                                                                : "Title Not Found"}{" "}
                                                              <span>
                                                                (
                                                                {
                                                                  correspondingItem?.skuCode
                                                                }
                                                                )
                                                              </span>
                                                            </a>
                                                            <p>{item?.note}</p>
                                                          </td>
                                                          <td className="invoicerefunfcss">
                                                            {item?.quantity}{" "}
                                                            {invoice?.isReturn ===
                                                            1 ? null : (
                                                              <>
                                                                <div
                                                                  className="disvinvoicecustomrefund"
                                                                  // style={{
                                                                  //   width: "20px",
                                                                  //   display: "flex",
                                                                  // }}
                                                                >
                                                                  <small
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                      backgroundColor:
                                                                        "grey",
                                                                      fontSize:
                                                                        "12px",
                                                                      width:
                                                                        "20px",
                                                                      position:
                                                                        "relative",
                                                                    }}
                                                                  >
                                                                    {item?.isRefundQuantity ? (
                                                                      <>
                                                                        <span>
                                                                          -
                                                                        </span>{" "}
                                                                        {
                                                                          item?.isRefundQuantity
                                                                        }
                                                                      </>
                                                                    ) : null}
                                                                  </small>{" "}
                                                                </div>
                                                              </>
                                                            )}
                                                          </td>
                                                          <td>
                                                            {item?.discount}
                                                          </td>
                                                          <td>{item?.total}</td>
                                                        </tr>
                                                      );
                                                    }
                                                  )}
                                                </tbody>
                                              </table>
                                              <div className="invoice_note">
                                                <span>
                                                  <b>Note:</b>
                                                </span>
                                                <small>{invoice?.note}</small>
                                              </div>
                                            </div>
                                            <div className="invoice_subtotal">
                                              <table>
                                                <tbody>
                                                  <tr>
                                                    <td>Subtotal:</td>
                                                    <td></td>
                                                    <td>
                                                      ${invoice?.subTotal}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>Discount:</td>
                                                    <td>
                                                      {invoice?.discount.toFixed(
                                                        2
                                                      )}
                                                      %
                                                    </td>
                                                    <td>
                                                      $
                                                      {(
                                                        subtotal -
                                                        parseFloat(
                                                          discountedSubtotal
                                                        )
                                                      ).toFixed(2)}
                                                      {/* {discountedSubtotal.toFixed(
                                                    2
                                                  )} */}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>Tax:</td>
                                                    <td>{invoice?.tax}%</td>
                                                    <td>
                                                      {taxAmount.toFixed(2)}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>Grand Total:</td>
                                                    <td></td>
                                                    <td>
                                                      $
                                                      {overallTotal1.toFixed(2)}
                                                    </td>
                                                  </tr>

                                                  {invoice?.payment?.map(
                                                    (item) => (
                                                      <>
                                                        <tr>
                                                          <td>
                                                            {item?.paymentType}
                                                          </td>
                                                          <td className="td_date">
                                                            {item.date},{" "}
                                                            {moment(
                                                              item?.time,
                                                              "HH:mm:ss"
                                                            ).format(
                                                              "hh:mm A"
                                                            )}{" "}
                                                          </td>
                                                          <td>
                                                            $
                                                            {item?.payAmount.toFixed(
                                                              2
                                                            )}
                                                          </td>
                                                        </tr>
                                                      </>
                                                    )
                                                  )}

                                                  <tr>
                                                    <td>Balance</td>
                                                    <td></td>
                                                    <td>$0.00</td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                          <div className="invoice_receipt_btn">
                                            <ul>
                                              {invoice?.isReturn ===
                                              1 ? null : (
                                                <>
                                                  <Link
                                                    to="/refund"
                                                    state={{
                                                      data: invoice,
                                                    }}
                                                  >
                                                    <li>
                                                      <a href="#">
                                                        <img
                                                          src="assets/img/refund-icon.webp"
                                                          alt=""
                                                        />
                                                        Refund
                                                      </a>
                                                    </li>
                                                  </Link>
                                                </>
                                              )}
                                              <Link
                                                to="/editinvoice"
                                                state={{
                                                  data: invoice,
                                                }}
                                              >
                                                <li>
                                                  <a href="#">
                                                    <img
                                                      src="assets/img/edit-invoice-icon.webp"
                                                      alt=""
                                                    />
                                                    Edit
                                                  </a>
                                                </li>
                                              </Link>

                                              {printType === 2 ||
                                              reciept === true ? (
                                                <li
                                                  //onClick={printTable}
                                                  onClick={printSetup}
                                                >
                                                  <a href="#">
                                                    <img
                                                      src="assets/img/print-invoice-icon.webp"
                                                      alt=""
                                                    />
                                                    Print Thermal Receipt
                                                  </a>
                                                </li>
                                              ) : (
                                                <li onClick={printTable}>
                                                  <a href="#">
                                                    <img
                                                      src="assets/img/print-invoice-icon.webp"
                                                      alt=""
                                                    />
                                                    Print Thermal Receipt
                                                  </a>
                                                </li>
                                              )}

                                              {invoice?.customer?.firstName &&
                                              invoice?.customer?.email ? (
                                                <li
                                                  onClick={() =>
                                                    EmailSend(invoice)
                                                  }
                                                >
                                                  <a href="#">
                                                    <img
                                                      src="assets/img/email-receipt-icon.webp"
                                                      alt=""
                                                    />
                                                    Email Receipt
                                                  </a>
                                                </li>
                                              ) : (
                                                <li
                                                  onClick={() => {
                                                    setShowModal7(true);
                                                    setEmail("");
                                                    setData(invoice);
                                                  }}
                                                >
                                                  <a
                                                    href="#"
                                                    data-toggle="modal"
                                                    data-target="#parksaleModal"
                                                  >
                                                    <img
                                                      src="assets/img/email-receipt-icon.webp"
                                                      alt=""
                                                    />
                                                    Email Receipt
                                                  </a>
                                                </li>
                                              )}
                                              <li>
                                                <a href="#">Add Note</a>
                                              </li>
                                            </ul>
                                          </div>
                                          <div className="clearfix"></div>
                                        </td>
                                      </tr>
                                      <div style={{ display: "none" }}>
                                        <div className="reciepttable">
                                          <div
                                            style={{
                                              //width: "100%",
                                              width:
                                                printType === 0
                                                  ? "80mm"
                                                  : "400px",

                                              fontFamily: "Arial, sans-serif",
                                              height: "auto",
                                              fontSize: 12,
                                            }}
                                            border={0}
                                          >
                                            <div>
                                              <div>
                                                <div>
                                                  <div
                                                    style={{
                                                      width: "100%",
                                                      textAlign: "center",
                                                    }}
                                                    border={0}
                                                  >
                                                    <div>
                                                      <div>
                                                        {isLogo && logo ? ( // Check if logo is available and should be displayed
                                                          <div
                                                            style={{
                                                              textAlign:
                                                                logoPosition ===
                                                                0
                                                                  ? "left"
                                                                  : "center",
                                                              marginBottom: 25,
                                                            }}
                                                          >
                                                            <img
                                                              src={logo}
                                                              alt="Logo"
                                                              style={{
                                                                width:
                                                                  logoSize === 1
                                                                    ? 300
                                                                    : 50,
                                                                height:
                                                                  logoSize === 1
                                                                    ? 110
                                                                    : 55,
                                                                // objectFit: "cover",
                                                                // display: "block",
                                                                margin:
                                                                  "0 auto",
                                                              }}
                                                            />
                                                          </div>
                                                        ) : null}
                                                      </div>
                                                      <div>
                                                        <div
                                                          style={{
                                                            fontSize: 12,
                                                            fontWeight: 700,

                                                            color: "#000",
                                                          }}
                                                        >
                                                          {title ? title : "--"}
                                                        </div>
                                                      </div>
                                                      <div>
                                                        <div
                                                          style={{
                                                            fontSize: 12,
                                                            fontWeight: 500,
                                                            color: "#000",
                                                            marginTop: 20,
                                                          }}
                                                        >
                                                          <div
                                                            dangerouslySetInnerHTML={{
                                                              __html: header
                                                                ? header
                                                                    .trim()
                                                                    .replace(
                                                                      /\n/g,
                                                                      "<br>"
                                                                    )
                                                                : "--",
                                                            }}
                                                          ></div>
                                                          {/* <pre
                                                            style={{
                                                              fontSize: 12,
                                                              fontWeight: 500,
                                                              color: "#000",
                                                              //marginTop: 20,
                                                            }}
                                                          >
                                                            {header
                                                              ? header
                                                              : "--"}
                                                          </pre> */}
                                                        </div>
                                                      </div>
                                                      <div>
                                                        {" "}
                                                        <div
                                                          style={{
                                                            padding: "8px 0px",
                                                          }}
                                                        />
                                                      </div>
                                                      <div></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div>
                                                <div>
                                                  <div
                                                    style={{
                                                      width: "100%",
                                                    }}
                                                    border={0}
                                                  >
                                                    <div>
                                                      <div>
                                                        <div
                                                          style={{
                                                            fontSize: 12,
                                                            fontWeight: 400,
                                                            color: "#000",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontWeight: 700,
                                                            }}
                                                          >
                                                            Sales Ref:
                                                          </span>
                                                          #{invoice?.id}
                                                        </div>
                                                      </div>
                                                      <div>
                                                        <div
                                                          style={{
                                                            fontSize: 12,
                                                            fontWeight: 400,
                                                            color: "#000",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontWeight: 700,
                                                            }}
                                                          >
                                                            Customer:
                                                          </span>{" "}
                                                          {(customerDetails?.fullname ===
                                                            1 &&
                                                            invoice?.customer
                                                              ?.firstName) ||
                                                          invoice?.customer
                                                            ?.lastName
                                                            ? `${invoice?.customer?.firstName} ${invoice?.customer?.lastName}`
                                                            : "unassigned "}
                                                          {customerDetails.email ===
                                                            1 &&
                                                          invoice?.customer
                                                            ?.email ? (
                                                            <>
                                                              {" "}
                                                              <br />
                                                              <span
                                                                style={{
                                                                  fontWeight: 700,
                                                                  marginTop: 35,
                                                                }}
                                                              >
                                                                Email:
                                                              </span>
                                                              {
                                                                invoice
                                                                  ?.customer
                                                                  ?.email
                                                              }
                                                            </>
                                                          ) : null}
                                                          {customerDetails.phone ===
                                                            1 &&
                                                          invoice?.customer
                                                            ?.phoneNumber ? (
                                                            <>
                                                              {" "}
                                                              <br />
                                                              <span
                                                                style={{
                                                                  fontWeight: 700,
                                                                  marginTop: 35,
                                                                }}
                                                              >
                                                                Phone No.:
                                                              </span>
                                                              {
                                                                invoice
                                                                  ?.customer
                                                                  ?.phoneNumber
                                                              }
                                                            </>
                                                          ) : null}
                                                          {customerDetails?.address ===
                                                            1 &&
                                                          invoice?.customer
                                                            ?.streetAddress ? (
                                                            <>
                                                              {" "}
                                                              <br />
                                                              <span
                                                                style={{
                                                                  fontWeight: 700,
                                                                  marginTop: 42,
                                                                }}
                                                              >
                                                                Address:
                                                              </span>
                                                              {
                                                                invoice
                                                                  ?.customer
                                                                  ?.streetAddress
                                                              }
                                                            </>
                                                          ) : null}
                                                        </div>
                                                      </div>
                                                      <div>
                                                        <div
                                                          style={{
                                                            fontSize: 12,
                                                            fontWeight: 400,
                                                            color: "#000",
                                                            marginTop: 0,
                                                          }}
                                                        >
                                                          <span>
                                                            {moment(
                                                              invoice?.timeZone,
                                                              "YYYY/MM/DD"
                                                            ).format(
                                                              "DD/MM/YYYY"
                                                            )}
                                                            {"  "}
                                                            {moment(
                                                              invoice?.timeZone
                                                            ).format("LT")}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div>
                                                {" "}
                                                <div
                                                  style={{
                                                    padding: "4px 0px",
                                                  }}
                                                />
                                              </div>
                                              <div>
                                                <div>
                                                  <table
                                                    className="tabledata"
                                                    style={{
                                                      width: "100%",
                                                    }}
                                                    border={0}
                                                  >
                                                    <thead>
                                                      <tr>
                                                        <th
                                                          style={{
                                                            fontSize: 12,
                                                            fontWeight: 700,
                                                            color: "#000",
                                                            textAlign: "center",
                                                            borderBottom:
                                                              "2px dashed #000",
                                                            padding: 4,
                                                            marginTop: 20,
                                                          }}
                                                        >
                                                          QTY
                                                        </th>
                                                        <th
                                                          style={{
                                                            fontSize: 12,
                                                            fontWeight: 700,
                                                            color: "#000",
                                                            textAlign: "left",
                                                            borderBottom:
                                                              "2px dashed #000",
                                                            padding: 4,
                                                            marginTop: 20,
                                                          }}
                                                        >
                                                          Item Name
                                                        </th>
                                                        <th
                                                          style={{
                                                            fontSize: 12,
                                                            fontWeight: 700,
                                                            color: "#000",
                                                            textAlign: "right",
                                                            borderBottom:
                                                              "2px dashed #000",
                                                            padding: 4,
                                                            marginTop: 20,
                                                          }}
                                                        >
                                                          Discount
                                                        </th>
                                                        <th
                                                          style={{
                                                            fontSize: 12,
                                                            fontWeight: 700,
                                                            color: "#000",
                                                            textAlign: "right",
                                                            borderBottom:
                                                              "2px dashed #000",
                                                            padding: 4,
                                                            marginTop: 20,
                                                          }}
                                                        >
                                                          Amount
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {invoice?.items?.map(
                                                        (item, index) => {
                                                          const correspondingItem =
                                                            invoice?.itemIds?.find(
                                                              (itemId) =>
                                                                itemId?.id ===
                                                                item?.itemId
                                                            );

                                                          return (
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontSize: 12,
                                                                  fontWeight: 500,
                                                                  color: "#000",
                                                                  textAlign:
                                                                    "center",
                                                                  padding: 4,
                                                                  marginTop: 20,
                                                                }}
                                                              >
                                                                {invoice?.isReturn ===
                                                                1
                                                                  ? `-${item?.quantity}`
                                                                  : `${item?.quantity}`}
                                                              </td>
                                                              <td
                                                                style={{
                                                                  fontSize: 12,
                                                                  fontWeight: 500,
                                                                  color: "#000",
                                                                  textAlign:
                                                                    "left",
                                                                  padding: 4,
                                                                  marginTop: 20,
                                                                }}
                                                              >
                                                                {selectedOption?.prodname ===
                                                                  1 &&
                                                                selectedOption?.sku ===
                                                                  0 &&
                                                                selectedOption?.prodname_sku ===
                                                                  0
                                                                  ? correspondingItem?.title
                                                                  : ""}
                                                                {selectedOption?.sku ===
                                                                  1 &&
                                                                selectedOption?.prodname ===
                                                                  0 &&
                                                                selectedOption?.prodname_sku ===
                                                                  0
                                                                  ? correspondingItem?.skuCode
                                                                  : ""}
                                                                {selectedOption?.prodname_sku ===
                                                                  1 &&
                                                                selectedOption?.prodname ===
                                                                  1 &&
                                                                selectedOption?.sku ===
                                                                  1
                                                                  ? `${correspondingItem?.title}(${correspondingItem?.skuCode})`
                                                                  : ""}

                                                                {item?.note ? (
                                                                  <p
                                                                    style={{
                                                                      fontWeight:
                                                                        "bold",
                                                                      fontSize:
                                                                        "11px",
                                                                    }}
                                                                  >
                                                                    Note:{"  "}
                                                                    <span
                                                                      style={{
                                                                        fontWeight:
                                                                          "200",
                                                                        fontSize:
                                                                          "10px",
                                                                      }}
                                                                    >
                                                                      {
                                                                        item?.note
                                                                      }
                                                                    </span>
                                                                  </p>
                                                                ) : null}
                                                              </td>
                                                              <td
                                                                style={{
                                                                  fontSize: 12,
                                                                  fontWeight: 500,
                                                                  color: "#000",
                                                                  textAlign:
                                                                    "right",
                                                                  padding: 4,
                                                                  marginTop: 20,
                                                                }}
                                                              >
                                                                {item?.discount}
                                                              </td>
                                                              <td
                                                                style={{
                                                                  fontSize: 12,
                                                                  fontWeight: 500,
                                                                  color: "#000",
                                                                  textAlign:
                                                                    "right",
                                                                  padding: 4,
                                                                  marginTop: 20,
                                                                }}
                                                              >
                                                                {item?.total}
                                                              </td>
                                                            </tr>
                                                          );
                                                        }
                                                      )}
                                                      <tr>
                                                        <td
                                                          style={{
                                                            fontSize: 12,
                                                            fontWeight: 500,
                                                            color: "#000",
                                                            textAlign: "center",
                                                            padding: 4,
                                                            marginTop: 20,
                                                            borderBottom:
                                                              "2px dashed #000",
                                                          }}
                                                        ></td>
                                                        <td
                                                          style={{
                                                            fontSize: 12,
                                                            fontWeight: 500,
                                                            color: "#000",
                                                            textAlign: "left",
                                                            padding: 4,
                                                            marginTop: 20,
                                                            borderBottom:
                                                              "2px dashed #000",
                                                          }}
                                                        ></td>
                                                        <td
                                                          style={{
                                                            fontSize: 12,
                                                            fontWeight: 500,
                                                            color: "#000",
                                                            textAlign: "right",
                                                            padding: 4,
                                                            marginTop: 20,
                                                            borderBottom:
                                                              "2px dashed #000",
                                                          }}
                                                        ></td>
                                                        <td
                                                          style={{
                                                            fontSize: 12,
                                                            fontWeight: 500,
                                                            color: "#000",
                                                            textAlign: "right",
                                                            padding: 4,
                                                            marginTop: 20,
                                                            borderBottom:
                                                              "2px dashed #000",
                                                          }}
                                                        ></td>
                                                      </tr>
                                                    </tbody>
                                                    <tfoot>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            fontSize: 12,
                                                            fontWeight: 500,
                                                            color: "#000",
                                                            textAlign: "left",
                                                            padding: 4,
                                                            marginTop: 20,
                                                          }}
                                                          colSpan={2}
                                                        >
                                                          Subtotal
                                                        </td>
                                                        <td
                                                          style={{
                                                            fontSize: 12,
                                                            fontWeight: 500,
                                                            color: "#000",
                                                            textAlign: "right",
                                                            padding: 4,
                                                            marginTop: 20,
                                                          }}
                                                          colSpan={2}
                                                        >
                                                          ${invoice?.subTotal}
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            fontSize: 12,
                                                            fontWeight: 400,
                                                            color: "#000",
                                                            padding: 4,
                                                            marginTop: 20,
                                                          }}
                                                          colSpan={2}
                                                        >
                                                          Discounts (
                                                          {invoice?.discount ||
                                                            0}
                                                          %)
                                                        </td>
                                                        <td
                                                          style={{
                                                            fontSize: 12,
                                                            fontWeight: 500,
                                                            color: "#000",
                                                            textAlign: "right",
                                                            padding: 4,
                                                            marginTop: 20,
                                                          }}
                                                          colSpan={2}
                                                        >
                                                          ${" "}
                                                          {(
                                                            parseFloat(
                                                              invoice?.subTotal
                                                            ) -
                                                            parseFloat(
                                                              discountedSubtotal
                                                            )
                                                          ).toFixed(2) || 0}
                                                          {/* {discountedSubtotal.toFixed(
                                                        2
                                                      )} */}
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            fontSize: 12,
                                                            fontWeight: 500,
                                                            color: "#000",
                                                            textAlign: "left",
                                                            padding: 4,
                                                            marginTop: 20,
                                                          }}
                                                          colSpan={2}
                                                        >
                                                          Tax (
                                                          {invoice?.tax || 0}
                                                          %)
                                                        </td>
                                                        <td
                                                          style={{
                                                            fontSize: 12,
                                                            fontWeight: 500,
                                                            color: "#000",
                                                            textAlign: "right",
                                                            padding: 4,
                                                            marginTop: 20,
                                                          }}
                                                          colSpan={2}
                                                        >
                                                          $
                                                          {taxAmount.toFixed(
                                                            2
                                                          ) || 0}
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            fontSize: 12,
                                                            fontWeight: 700,
                                                            color: "#000",
                                                            textAlign: "left",
                                                            borderBottom:
                                                              "2px dashed #000",
                                                            padding: 4,
                                                            marginTop: 20,
                                                          }}
                                                          colSpan={2}
                                                        >
                                                          Grand Total
                                                        </td>
                                                        <td
                                                          style={{
                                                            fontSize: 12,
                                                            fontWeight: 700,
                                                            color: "#000",
                                                            textAlign: "right",
                                                            borderBottom:
                                                              "2px dashed #000",
                                                            padding: 4,
                                                            marginTop: 20,
                                                          }}
                                                          colSpan={2}
                                                        >
                                                          $
                                                          {overallTotal1.toFixed(
                                                            2
                                                          )}
                                                        </td>
                                                      </tr>
                                                    </tfoot>
                                                  </table>
                                                </div>
                                              </div>
                                              <div>
                                                {" "}
                                                <div
                                                  style={{
                                                    padding: "8px 0px",
                                                  }}
                                                />
                                              </div>
                                              <div>
                                                <div>
                                                  <table
                                                    style={{
                                                      width: "100%",
                                                    }}
                                                    border={0}
                                                  >
                                                    <tbody>
                                                      {invoice?.payment?.map(
                                                        (item) => (
                                                          <>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontSize: 12,
                                                                  fontWeight: 400,
                                                                  color: "#000",
                                                                  marginTop: 20,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontWeight: 600,
                                                                  }}
                                                                >
                                                                  Received:
                                                                </span>{" "}
                                                                $
                                                                {item?.payAmount.toFixed(
                                                                  2
                                                                )}
                                                              </td>
                                                              <td
                                                                style={{
                                                                  fontSize: 12,
                                                                  fontWeight: 400,
                                                                  color: "#000",
                                                                  marginTop: 20,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontWeight: 700,
                                                                  }}
                                                                >
                                                                  {
                                                                    item?.paymentType
                                                                  }
                                                                  {"  "}
                                                                </span>
                                                                {item?.date}{" "}
                                                                {moment(
                                                                  item?.time,
                                                                  "HH:mm:ss"
                                                                ).format(
                                                                  "hh:mm A"
                                                                )}{" "}
                                                              </td>
                                                            </tr>{" "}
                                                          </>
                                                        )
                                                      )}

                                                      <tr
                                                        style={{
                                                          marginTop: 20,
                                                        }}
                                                      >
                                                        <td
                                                          style={{
                                                            fontSize: 12,
                                                            fontWeight: 400,
                                                            color: "#000",
                                                            marginTop: 20,
                                                          }}
                                                          colSpan={2}
                                                        >
                                                          Change Given:{" "}
                                                          <span
                                                            style={{
                                                              fontWeight: 700,
                                                            }}
                                                          >
                                                            $
                                                            {invoice?.due
                                                              ? invoice?.due
                                                              : 0}
                                                          </span>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            fontSize: 12,
                                                            fontWeight: 400,
                                                            color: "#000",
                                                            // backgroundColor:
                                                            //   "#fff",
                                                            marginTop: 20,
                                                          }}
                                                          colSpan={2}
                                                        >
                                                          <span
                                                            style={{
                                                              fontWeight: 700,
                                                            }}
                                                          >
                                                            Cashier:
                                                          </span>{" "}
                                                          {invoice?.isAdmin ===
                                                          0
                                                            ? invoice?.subuser
                                                                ?.name
                                                            : invoice?.user
                                                                ?.userName}
                                                          {/* //                         //   {invoice?.isAdmin === 0 ? (
                                    //   <td>{invoice?.subuser?.name}</td>
                                    // ) : (
                                    //   <td>{invoice?.user?.userName}</td>
                                   // )} */}
                                                          {/* {(adminState ===
                                                            "0" ||
                                                            permission?.length >
                                                              0) &&
                                                            dataUser
                                                              ?.userDetails
                                                              ?.name}
                                                          {(adminState === 1 ||
                                                            permission?.length ===
                                                              0) &&
                                                            data1?.userDetails
                                                              ?.userName} */}
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                              <div>
                                                {" "}
                                                <div
                                                  style={{
                                                    padding: "12px 0px",
                                                  }}
                                                />
                                              </div>
                                              <div>
                                                <div>
                                                  <div
                                                    style={{
                                                      width: "100%",
                                                      textAlign: "center",
                                                    }}
                                                    border={0}
                                                  >
                                                    <div>
                                                      <div>
                                                        <div
                                                          style={{
                                                            textAlign:
                                                              "justify",
                                                            fontSize: "12px",
                                                            fontWeight: "400",
                                                            color: "#000",
                                                            whiteSpace:
                                                              "pre-wrap",
                                                            // Preserve whitespace and allow wrapping
                                                            wordWrap:
                                                              "break-word", // Allow long words to break
                                                            maxWidth: "100%",
                                                          }}
                                                          // style={{
                                                          //   textAlign: "center",
                                                          //   fontSize: "12px",
                                                          //   fontWeight: "400",
                                                          //   color: "#000",
                                                          // }}
                                                        >
                                                          <pre
                                                            style={{
                                                              textAlign:
                                                                "justify",
                                                              fontSize: "12px",
                                                              fontWeight: 400,
                                                              color: " #000",
                                                              whiteSpace:
                                                                "pre-wrap",
                                                              wordWrap:
                                                                "break-word",
                                                              maxWidth: "100%",
                                                            }}
                                                          >
                                                            {footer
                                                              ? footer
                                                              : "--"}
                                                          </pre>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div>
                                                <div>
                                                  <div
                                                    style={{
                                                      width: "100%",
                                                      textAlign: "center",
                                                    }}
                                                    border={0}
                                                  >
                                                    <div>
                                                      {gstShow === 1 ? (
                                                        <div>
                                                          <div
                                                            style={{
                                                              fontSize: 12,
                                                              fontWeight: 400,
                                                              color: "#000",
                                                              marginTop: 10,
                                                            }}
                                                          >
                                                            Hst #:{" "}
                                                            {gstNumber
                                                              ? gstNumber
                                                              : "--"}
                                                          </div>
                                                        </div>
                                                      ) : null}
                                                      {/* <div>
                                                    <div>
                                                      <img
                                                        src="img/receipt-barcode.jpg"
                                                        alt=""
                                                      />
                                                    </div>
                                                  </div> */}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            {" "}
                            <tr id="noDataMessage">
                              <td colSpan="10" className="nodataav">
                                No data available
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                    {InVoiceData.length > 0 ? (
                      <div className="row">
                        <div className="col-md-12">
                          <div className="custombottompaginationbox">
                            <nav aria-label="Page navigation example">
                              <ul className="pagination">
                                <li
                                  className="page-item custompaginationli  paginationleftbtnbox"
                                  style={{ cursor: "pointer" }}
                                >
                                  <button
                                    type="button"
                                    className="page-link"
                                    onClick={() =>
                                      handlePageClick(currentPage - 1)
                                    }
                                    disabled={currentPage === 1}
                                  >
                                    <img src="assets/img/previous.webp" />
                                  </button>
                                </li>

                                {pages.map((page, key) => {
                                  if (
                                    page === 1 ||
                                    page === totalPages ||
                                    (page >= startPage && page <= endPage)
                                  ) {
                                    return (
                                      <li
                                        className="page-item custompaginationli"
                                        key={key}
                                      >
                                        <a
                                          className={
                                            currentPage === page
                                              ? "page-link active customcountpagination"
                                              : "page-link customcountpagination"
                                          }
                                          onClick={() => handlePageClick(page)}
                                          disabled={currentPage === page}
                                          style={{ cursor: "pointer" }}
                                        >
                                          {page}
                                        </a>
                                      </li>
                                    );
                                  } else if (
                                    page === startPage - 1 ||
                                    page === endPage + 1
                                  ) {
                                    return (
                                      <li
                                        className="page-item custompaginationli"
                                        key={key}
                                      >
                                        <a
                                          className="page-link customcountpagination"
                                          style={{ cursor: "pointer" }}
                                        >
                                          ...
                                        </a>
                                      </li>
                                    );
                                  } else {
                                    return null;
                                  }
                                })}

                                <li
                                  className="page-item custompaginationli paginationrightbtnbox"
                                  style={{ cursor: "pointer" }}
                                >
                                  <button
                                    type="button"
                                    className="page-link"
                                    href="#"
                                    onClick={() =>
                                      handlePageClick(currentPage + 1)
                                    }
                                    disabled={currentPage === totalPages}
                                  >
                                    <img src="assets/img/next.webp" />
                                  </button>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
        {showModal7 === true ? (
          <>
            <div
              className="modal common_modal parksale_modal"
              id="parksaleModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="parksaleModalLabel"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="parksaleModalLabel">
                      Email
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setShowModal7(false);
                        setEmail("");

                        document
                          .querySelectorAll(".modal-backdrop")
                          .forEach((el) =>
                            el.classList.remove("modal-backdrop")
                          );
                      }}
                    >
                      <span className="close_icon"></span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="custom_form parksale_form">
                      <form className="cus_form">
                        <div className="form_row">
                          <div className="form_column col_full">
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control"
                                name="address"
                                placeholder="Enter Email"
                                value={email}
                                onChange={(e) => {
                                  setEmail(e.target.value.trim());
                                  if (error.email) {
                                    setError({ ...error, email: "" });
                                  }
                                }}
                              />
                              {error.email && (
                                <span className="customvalidationdesignlogin">
                                  {error.email}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary theme_btn"
                      onClick={() => {
                        OnSubmit();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
      <div
        className="modal common_modal noteinfo_modal"
        id="noteinfoModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="noteinfoModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="noteclose"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src="assets/img/notes-close-icon.svg" alt="" />
              </button>
            </div>
            <div className="modal-body">
              <div className="notes_tabs">
                <ul
                  className="nav nav-pills notetabs"
                  id="notetabs"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="video_demo-tab"
                      data-toggle="tab"
                      href="#video_demo"
                      role="tab"
                      aria-controls="video_demo"
                      aria-selected="true"
                    >
                      Video Demo
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="page_info-tab"
                      data-toggle="tab"
                      href="#page_info"
                      role="tab"
                      aria-controls="page_info"
                      aria-selected="false"
                    >
                      Page Information
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="settingContent">
                  <div
                    className="tab-pane fade active show"
                    id="video_demo"
                    role="tabpanel"
                    aria-labelledby="video_demo-tab"
                  >
                    <div className="notetabs_content">
                      <h4>Video Demo</h4>
                      <p>
                        In this short tutorial, we’ll walk you through the key
                        features and functions of the Dashboard page.
                      </p>
                      <div className="notes_videos">
                        <img
                          src="assets/img/notes-demo-videos-img.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="page_info"
                    role="tabpanel"
                    aria-labelledby="page_info-tab"
                  >
                    <div className="notetabs_content">
                      <h4>Page Information</h4>
                      <p>
                        This page provides a comprehensive view of all the tools
                        and features available to you. Each section is designed
                        to help you manage your business more efficiently,
                        whether you’re tracking sales, monitoring inventory, or
                        analyzing reports.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default InvoiceList;
