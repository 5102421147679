import React, { useEffect, useState } from "react";
import {
  AddStatusApi,
  baseurl,
  getProfileApi,
  GetStatusApi,
} from "../Utility/Api";
import { HexColorPicker } from "react-colorful";
import Swal from "sweetalert2";
import { hsvaToHex, getContrastingColor } from "@uiw/color-convert";
import Swatch from "@uiw/react-color-swatch";

const StatusOption = () => {
  const [Status, setStatus] = useState([]);
  const [statusColors, setStatusColors] = useState({});
  const [statusOption, setStatusOption] = useState("");
  const [optionState, setOptionState] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [id, setId] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [showColorPicker, setShowColorPicker] = useState(false);

  const handleToggleColorPicker = () => {
    setShowColorPicker(!showColorPicker);
  };
  const handleChangeComplete = (color) => {
    setSelectedColor(color);
    // if (editState === true) {
    //   // handleProductSelect(selectedProduct, color);
    // } else {
    //   //handleProductSelectOne(selectedProduct, color);
    // }
  };

  useEffect(() => {
    GetAllStatus();
  }, []);

  const GetAllStatus = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetStatusApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("status options", result);
        const sortedStatusList = result?.data.sort((a, b) => {
          if (a?.name === "New") return -1;
          if (b?.name === "New") return 1;

          if (a?.name === "Finished") return 1;
          if (b?.name === "Finished") return -1;
          return a.name.localeCompare(b.name);
        });
        setStatus(sortedStatusList);
        const defaultColors = result?.data.reduce((acc, curr) => {
          acc[curr.id] = curr.color ? curr.color : "#000000";
          return acc;
        }, {});
        setStatusColors(defaultColors);
        // setSelectedColor(defaultColors);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleColorChange = (color) => {
    setStatusColors(color);
  };

  const AddStatusOption = () => {
    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      name: statusOption,
      color: selectedColor,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddStatusApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllStatus();
          setShowModal(false);
          setStatusOption("");
          setStatusColors("");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const UpdateStatus = () => {
    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      name: statusOption,
      color: selectedColor,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseurl}updateStatus/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllStatus();
          setShowModal1(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const HandleUpdateStatus = (a) => {
    setStatusOption(a?.name);
    setStatusColors(a?.color || "#000000");
    setSelectedColor(a?.color || "#000000");
    setId(a?.id);
  };

  const DeleteStatus = (id) => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}/deleteStatus/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllStatus();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const HandleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete Status Option",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c76cff",
      cancelButtonColor: "#efefef",
      confirmButtonText: "Yes, delete it!",
      confirmButtonText: "<span style='color: white;'>Yes, delete it!</span>",
      cancelButtonText: "<span style='color: black;'>cancel!</span>",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "sweet-alert2-container-zindex",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your data has been deleted.",
          icon: "success",
          confirmButtonColor: "#c76cff",
          customClass: {
            container: "sweet-alert2-container-zindex",
          },
        }).then(() => {
          DeleteStatus(id);
        });
      }
    });
  };

  const handleStatusChange = (selectedStatus) => {
    // EditTicket(selectedStatus.id, ticket);
    setStatusOption(selectedStatus?.name);
    // setSelectedStatusId(selectedStatus.id);
    setSelectedColor(selectedStatus?.color);
  };

  return (
    <>
      <div className="setting_content">
        <h4>Status Options</h4>
        <div className="inner_block plan_billing_setting">
          <form className="setting_form" action="" method="post">
            <div className="block_column wd100">
              <h5>Types of Status Options</h5>
              <p>Manage the markets that you will operate within.</p>
              <div className="inner_field">
                <div className="tax_label_block">
                  <div className="tax_label">
                    <h6>Default Status Options</h6>
                    <span>This is the currency your prices are shown in.</span>
                    <div className="storecurrency_btn">
                      {Status?.filter((item) =>
                        ["New", "Finished"].includes(item.name)
                      ).map((item) => (
                        <button
                          key={item.id}
                          type="button"
                          className="cus_btn"
                          style={{ background: statusColors[item.id] }}
                        >
                          {item.name}
                        </button>
                      ))}
                    </div>

                    {/* <div className="storecurrency_btn">
                      {Status.filter(
                        (item) =>
                          item.name === "New" || item.name === "Finished"
                      ).map((item) => (
                        <button
                          key={item.id}
                          type="button"
                          className="cus_btn"
                          style={{ background: statusColors[item.id] }}
                        >
                          {item.name}
                        </button>
                      ))}
                    </div> */}
                  </div>
                </div>
                <div className="tax_label_block">
                  <div className="tax_label">
                    <h6>Store Status Options</h6>
                    <span>All the Status available in your store.</span>
                  </div>
                  {/* <div className="edit_tax_btn">
                    <button type="button" className="cus_btn">
                      Edit currencies
                    </button>
                  </div> */}
                </div>
                <div className="status_table">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Store Status Options</th>
                          <th>Colour</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Status?.map((item, index) => (
                          <tr key={item.id}>
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            <td>
                              <div className="colour_field">
                                <span
                                  className="input-group-append"
                                  style={{
                                    background:
                                      statusColors[item.id] || "#000000",
                                  }}
                                ></span>
                              </div>
                            </td>
                            <td>
                              <div className="status_edit_btn ">
                                <a
                                  className="editbtn"
                                  data-toggle="modal"
                                  data-target="#editstatusoptionModal"
                                  href="#"
                                  onClick={() => {
                                    setShowModal1(true);
                                    HandleUpdateStatus(item);
                                    console.log("edtt", item);
                                  }}
                                >
                                  Edit
                                </a>
                                {item?.name === "New" ||
                                item?.name === "Finished" ? (
                                  <a
                                    className="deletebtn"
                                    //onClick={() => HandleDelete(item.id)}
                                  >
                                    Delete
                                  </a>
                                ) : (
                                  <a
                                    className="deletebtn"
                                    onClick={() => HandleDelete(item.id)}
                                  >
                                    Delete
                                  </a>
                                )}
                                {/* <a
                                    className="deletebtn"
                                    onClick={() => HandleDelete(item.id)}
                                  >
                                    Delete
                                  </a> */}
                              </div>
                            </td>
                            {/* <td className="colorstatusresponsivecustm">
                              <div className="status_edit_btn editbtncustmstatus">
                                <div className="layoutactioncustm editstatusresponsivecustm">
                                  {item?.name === "New" ||
                                  item?.name === "Finished" ? (
                                    <span className="disabled">
                                      {" "}
                                    
                                    </span>
                                  ) : (
                                    <a
                                      className="editbtn"
                                      data-toggle="modal"
                                      data-target="#editstatusoptionModal"
                                      href="#"
                                      onClick={() => {
                                        setShowModal1(true);
                                        HandleUpdateStatus(item);
                                      }}
                                    >
                                      Edit
                                    
                                    </a>
                                  )}

                                  {item?.name === "New" ||
                                  item?.name === "Finished" ? (
                                    <span className="disabled"> </span>
                                  ) : (
                                    <a
                                      className="deletebtn"
                                      onClick={() => HandleDelete(item.id)}
                                    >
                                      Delete
                                     
                                    </a>
                                  )}
                                </div>
                              </div>
                            </td> */}
                            {/* <td>
                              <div className="status_edit_btn">
                                <a
                                  className="editbtn"
                                  data-toggle="modal"
                                  data-target="#editstatusoptionModal"
                                  href="#"
                                  onClick={() => {
                                    setShowModal1(true);

                                    HandleUpdateStatus(item);
                                  }}
                                >
                                  <img
                                    src="assets/img/layout-edit-icon.svg"
                                    alt=""
                                  />
                                </a>
                                <a className="deletebtn" href="#">
                                  <img
                                    src="assets/img/delete-icon.svg"
                                    alt=""
                                  />
                                </a>
                              </div>
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr className="add_row">
                          <td colspan="4" className="add_btn">
                            <a
                              data-toggle="modal"
                              data-target="#addstatusoptionModal"
                              href="javascript:;"
                              onClick={() => setShowModal(true)}
                            >
                              <span>+</span>
                              <span>Add New</span>
                            </a>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className="clearfix"></div>
        </div>
      </div>
      {showModal1 === true ? (
        <>
          <div
            className="modal common_modal status_option_modal"
            id="editstatusoptionModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="editstatusoptionModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="editstatusoptionModalLabel">
                    Edit Status Option
                  </h5>
                  <p>Edit Status dropdown options for your repairs</p>
                </div>
                <div className="modal-body">
                  <div className="prod_status_sec">
                    <form method="" action="">
                      <div className="status_name">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Status Option Name"
                          name="statusOptions"
                          value={statusOption}
                          style={{ textTransform: "capitalize" }}
                          onChange={(e) => setStatusOption(e.target.value)}
                          disabled={
                            statusOption === "New" ||
                            statusOption === "Finished" ||
                            statusOption === "Cancelled" ||
                            statusOption === "Invoiced"
                          }
                        />
                      </div>
                      <div
                        className="prod_colors"
                        style={{
                          display: " flex",
                          marginLeft: " 110px",
                          marginTop: "20px",
                        }}
                      >
                        <Swatch
                          colors={[
                            "#000",
                            "#E20000",
                            "#9664FF",
                            "#28DA2E",
                            "#FFC700",
                            "#FF00E5",
                            "#0500FF",
                            "#D0D0D0",
                            "#00D1FF",
                          ]}
                          color={selectedColor || "#000000"}
                          rectProps={{
                            //children: <Point />,
                            style: {
                              display: " block",
                              marginLeft: "10px",
                              alignItems: "center",
                              justifyContent: "center",
                              width: " 28px",
                              height: "23px",
                              borderRadius: "5px",
                            },
                          }}
                          onChange={(hsvColor) => {
                            handleChangeComplete(hsvaToHex(hsvColor));
                          }}
                        />
                        <div
                          className="disvcolorcustmslect"
                          onClick={handleToggleColorPicker}
                        >
                          <span>+</span>
                        </div>

                        {showColorPicker && (
                          <HexColorPicker
                            color={selectedColor}
                            onChange={(color) => setSelectedColor(color)}
                          />
                        )}
                      </div>
                      <div className="status_preview">
                        <div className="form-group">
                          <label>Preview</label>
                          <div className="employeeroledropdown">
                            <div className="dropdown">
                              <button
                                className="btn btn-secondary dropdown-toggle  customemployeetabledropdownsettting"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <span
                                  className="customspanlistsatutus"
                                  style={{
                                    background: selectedColor,
                                  }}
                                ></span>
                                {statusOption}
                              </button>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                {Status.map((status) => (
                                  <>
                                    <li key={status?.id}>
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        onClick={() =>
                                          handleStatusChange(status)
                                        }
                                      >
                                        <span
                                          className="customspanlistsatutus"
                                          style={{
                                            background: status?.color,
                                          }}
                                        ></span>{" "}
                                        {status?.name}{" "}
                                      </a>
                                    </li>
                                  </>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="status_colors">
                        <ul>
                          <li className="multi_clr">
                            <div className="colour_field colorpickerinput">
                              <span className="input-group-append">+</span>
                              <input
                                type="color"
                                value={statusColors}
                                onChange={(e) =>
                                  handleColorChange(e.target.value)
                                }
                              />
                            </div>
                          </li>
                        </ul>
                      </div> */}
                      {/* <div className="status_preview">
                        <div className="form-group">
                          <label onClick={() => setOptionState(true)}>
                            Preview
                          </label>
                          {optionState === true ? (
                            <div className="preview_field">
                           

                              <span className="statusfinishedcustm">
                                <span
                                  style={{
                                    content: "",
                                    width: " 7px",
                                    height: "7px",
                                    borderRadius: "50%",
                                    display: "inline - block",
                                    background: statusColors,
                                  }}
                                ></span>
                                {statusOption}
                              </span>
                            </div>
                          ) : null}
                        </div>
                      </div> */}
                    </form>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary default_btn"
                    data-dismiss="modal"
                    onClick={() => {
                      setShowModal1(false);
                      GetAllStatus();
                      document
                        .querySelectorAll(".modal-backdrop")
                        .forEach((el) => el.classList.remove("modal-backdrop"));
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary theme_btn"
                    onClick={UpdateStatus}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {showModal === true ? (
        <>
          <div
            className="modal common_modal status_option_modal"
            id="addstatusoptionModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="addstatusoptionModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="addstatusoptionModalLabel">
                    Add Status Option
                  </h5>
                  <p>Add Status dropdown options for your repairs</p>
                </div>
                <div className="modal-body">
                  <div className="prod_status_sec">
                    <form method="" action="">
                      <div className="status_name">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Status Option Name"
                          name="statusOption"
                          value={statusOption}
                          style={{ textTransform: "capitalize" }}
                          onChange={(e) => setStatusOption(e.target.value)}
                        />
                      </div>
                      <div
                        className="prod_colors"
                        style={{
                          display: " flex",
                          marginLeft: " 110px",
                          marginTop: "20px",
                        }}
                      >
                        <Swatch
                          colors={[
                            "#000",
                            "#E20000",
                            "#9664FF",
                            "#28DA2E",
                            "#FFC700",
                            "#FF00E5",
                            "#0500FF",
                            "#D0D0D0",
                            "#00D1FF",
                          ]}
                          color={selectedColor}
                          rectProps={{
                            //children: <Point />,
                            style: {
                              display: " block",
                              marginLeft: "10px",
                              alignItems: "center",
                              justifyContent: "center",
                              width: " 28px",
                              height: "23px",
                              borderRadius: "5px",
                            },
                          }}
                          onChange={(hsvColor) => {
                            handleChangeComplete(hsvaToHex(hsvColor));
                          }}
                        />
                        <div
                          className="disvcolorcustmslect"
                          onClick={handleToggleColorPicker}
                        >
                          <span>+</span>
                        </div>

                        {showColorPicker && (
                          <HexColorPicker
                            color={selectedColor}
                            onChange={(color) => setSelectedColor(color)}
                          />
                        )}
                      </div>
                      {/* <div className="status_colors">
                        <ul>
                          <li className="multi_clr">
                            <div className="colour_field colorpickerinput">
                              <span className="input-group-append">+</span>
                              <input
                                type="color"
                                value={statusColors}
                                onChange={(e) =>
                                  handleColorChange(e.target.value)
                                }
                              />
                            </div>
                          </li>
                        </ul>
                      </div> */}
                      {/* <div className="status_preview">
                        <div className="form-group">
                          <label>Preview</label>
                          <div className="preview_field">
                            <select className="form-control" name="statuspreview">
                              <option value="New">New</option>
                              <option value="Finsihed">Finsihed</option>
                              <option value="Assinged">Assinged</option>
                            </select>
                            <img src="assets/img/select-arrow.svg" alt="" />
                          </div>
                        </div>
                      </div> */}
                      <div className="status_preview">
                        <div className="form-group">
                          <label>Preview</label>
                          <div className="employeeroledropdown">
                            <div className="dropdown">
                              <button
                                className="btn btn-secondary dropdown-toggle  customemployeetabledropdownsettting"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <span
                                  className="customspanlistsatutus"
                                  style={{
                                    background: selectedColor,
                                  }}
                                ></span>
                                {statusOption}
                                {/* {item?.ticket?.statusOption?.name
                              ? item?.ticket?.statusOption?.name
                              : item?.ticket?.statusOption?.name} */}
                              </button>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                {Status.map((status) => (
                                  <>
                                    <li key={status?.id}>
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        onClick={() =>
                                          handleStatusChange(status)
                                        }
                                      >
                                        <span
                                          className="customspanlistsatutus"
                                          style={{
                                            background: status?.color,
                                          }}
                                        ></span>{" "}
                                        {status?.name}{" "}
                                      </a>
                                    </li>
                                  </>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="status_preview">
                        <div className="form-group">
                          <label onClick={() => setOptionState(true)}>
                            Preview
                          </label>
                          {optionState === true ? (
                            <div className="preview_field customemployeetabledropdownsettting">
                         

                              <span className="statusfinishedcustm">
                                <span
                                  style={{
                                    content: "",
                                    width: " 7px",
                                    height: "7px",
                                    borderRadius: "50%",
                                    display: "inline - block",
                                    background: selectedColor,
                                  }}
                                ></span>
                                {statusOption}
                              </span>
                            </div>
                          ) : null}
                        </div>
                      </div> */}
                    </form>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary default_btn"
                    //data-dismiss="modal"
                    onClick={() => {
                      GetAllStatus();
                      setShowModal(false);
                      setStatusOption("");
                      setStatusColors("");
                      document
                        .querySelectorAll(".modal-backdrop")
                        .forEach((el) => el.classList.remove("modal-backdrop"));
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary theme_btn"
                    onClick={AddStatusOption}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default StatusOption;
