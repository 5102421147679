import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const FreeTrail = () => {
  const [days, setDays] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    let plan = localStorage.getItem("planDate");

    if (plan === null || new Date(plan) > new Date()) {
      const date1 = new Date(plan);
      const date2 = new Date();

      const timeDifference = date1.getTime() - date2.getTime();
      const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      setDays(daysDifference);
    } else {
      setDays(0);
    }
  }, []);

  const Subscribe = () => {
    navigate("/settings", { state: { activeState: 1 } });
  };

  return (
    <>
      <div className="marquee">
        <span>
          Your trial Period will expire in {days} days. complete your billing
          details to make the transition.
        </span>
        <button className="custom-button" onClick={Subscribe}>
          Subscribe Now
        </button>
      </div>
    </>
  );
};
export default FreeTrail;
