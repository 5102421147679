import React, { useEffect, useState } from "react";
import $ from "jquery";
import Footer from "../Footer";
import SideUl from "./SideUl";
import MainSidebar from "./MainSidebar";
import { getProfileApi, GetSalesApi } from "../Utility/Api";
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { flatMap } from "lodash";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ReactApexChart from "react-apexcharts";
import CanvasJSReact from "@canvasjs/react-charts";
import FreeTrail from "./FreeTrail";
import { Link } from "react-router-dom";
//var CanvasJSReact = require('@canvasjs/react-charts');

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const SaleDashboard = () => {
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf("day"));
  const [endDate, setEndDate] = useState(moment().startOf("day"));
  // const [startDate, setStartDate] = useState(
  //   moment(new Date()).startOf("day").subtract(0, "days")
  // );
  // const [endDate, setEndDate] = useState(
  //   moment(new Date()).startOf("day").add(0, "days")
  // );
  const [customerCount, setCustomerCount] = useState("");
  const [serviceCount, setServiceCount] = useState("");
  const [invoiceCount, setInvoiceCount] = useState("");
  const [totalsum, setTotalSum] = useState("");
  const [salesByCategory, setsalesByCategory] = useState([]);
  const [salesByProduct, setsalesByProduct] = useState([]);
  const [activeState, setActiveState] = useState(0);
  const [productSoldCount, setProductSoldCount] = useState("");
  const [percentService, setPercentService] = useState("");
  const [percentProductsold, setPercentProductsold] = useState("");
  const [percentCustomer, setPercentCustomer] = useState("");
  const [percentInvoice, setPercentInvoice] = useState("");
  const [percent, setPercent] = useState("");
  const [paymentarr, setPaymentArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [todayProduct, setTodayProduct] = useState("");
  const [todaySales, setTodaySales] = useState("");
  const [todaysalepercent, setTodaysalepercent] = useState("");
  const [revenue, setRevenue] = useState("");
  const [revenuePer, setRevenuePer] = useState("");
  const [salesDate, setSalesDate] = useState([]);

  const [areaChartData, setAreaChartData] = useState({
    series: [
      {
        name: "Sales",
        data: [],
      },
    ],
    options: {
      chart: {
        type: "area",
        height: 350,
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "dd/MM/yy HH:mm",
        categories: [],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
      // colors: ["#008FFB"],
      // theme: {
      //   mode: "dark",
      //   palette: "palette10",
      // },
    },
  });

  // const handleStartDate = (date) => {
  //   if (!date) return;

  //   setStartDate(moment(date).startOf("day"));
  //   setEndDate((prevEndDate) =>
  //     moment(date).startOf("day").isAfter(prevEndDate)
  //       ? moment(date).startOf("day").add(1, "days")
  //       : prevEndDate
  //   );
  // };

  // const handleEndDate = (date) => {
  //   if (!date) return;

  //   setEndDate(moment(date).startOf("day").add(0, "days"));
  //   SalesData();
  // };
  const handleStartDate = (date) => {
    if (!date) return;

    const newStartDate = moment(date).startOf("day");
    const currentEndDate = moment(endDate).startOf("day");

    setStartDate(newStartDate);

    // Only update endDate if new startDate is after the current endDate
    if (newStartDate.isAfter(currentEndDate)) {
      setEndDate(newStartDate.clone());
    }
  };

  const handleEndDate = (date) => {
    if (!date) return;

    const newEndDate = moment(date).startOf("day");

    // Set endDate to the selected date
    setEndDate(newEndDate);
    SalesData(startDate, newEndDate); // Pass newStartDate and newEndDate to SalesData
  };
  useEffect(() => {
    GetProfile();
    TodayApi();
    setActiveState(0);
  }, []);

  const GetProfile = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(getProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setData(result?.data);
        } else {
          // console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const SalesData = (start, end) => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      // startDate: "2024-01-01",
      // endDate: "2024-04-05",
      startDate: moment(start).format("YYYY-MM-DD"),
      endDate: moment(end).format("YYYY-MM-DD"),
      status: 8,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // console.log("dataess", raw);
    fetch(GetSalesApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setLoading(false);
          setCustomerCount(result?.data?.CustomerCount);
          setServiceCount(result?.data?.ServiceCount);
          setInvoiceCount(result?.data?.InvoiceCount);
          setsalesByCategory(result?.data?.salesCategoryArr);
          setsalesByProduct(result?.data?.salesByProduct);
          setTotalSum(result?.data?.totalSalesSum);
          setProductSoldCount(result?.data?.productSoldCount);
          setPercentService(result?.data?.percentChangeService.toFixed(2));
          setPercentCustomer(result?.data?.percentChangeCustomer.toFixed(2));
          setPercentInvoice(result?.data?.percentChangeInvoice.toFixed(2));
          setPercentProductsold(
            result?.data?.percentChangeProductSold.toFixed(2)
          );
          setPaymentArr(result?.data?.paymentArr);
          setTodayProduct(result?.data?.productSoldCount);
          setTodaySales(result?.data?.totalSalesAmountGrandTotal.toFixed(2));
          setTodaysalepercent(result?.data?.percentChangeSalesSum.toFixed(2));
          setRevenue(result?.data?.checkRevenue.toFixed(2));
          setRevenuePer(result?.data?.percentChangeRevenueValue.toFixed(2));
          setSalesDate(result?.data?.salesData);

          const chartSeries = [
            {
              name: "Sales",
              data: result?.data?.salesData.map((item) =>
                item?.totalSalesAmount.toFixed(2)
              ),
            },
          ];

          const chartOptions = {
            ...areaChartData.options,
            xaxis: {
              ...areaChartData.options.xaxis,
              categories: result?.data?.salesData.map((item) => item.date),
            },
          };

          // Update the state with new data
          setAreaChartData({
            series: chartSeries,
            options: chartOptions,
          });
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const TodayApi = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    const raw = JSON.stringify({
      startDate: moment(new Date()).format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),
      status: 1,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(GetSalesApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          // console.log("resultt", result.data);
          setLoading(false);
          setCustomerCount(result?.data?.CustomerCount);
          setServiceCount(result?.data?.ServiceCount);
          setInvoiceCount(result?.data?.InvoiceCount);
          setsalesByCategory(result?.data?.salesCategoryArr);
          setsalesByProduct(result?.data?.salesByProduct);
          setTotalSum(result?.data?.totalSalesSum);
          setProductSoldCount(result?.data?.productSoldCount);
          setTodayProduct(result?.data?.productSoldCount);
          setPercentService(result?.data?.percentChangeService.toFixed(2));
          setPercentCustomer(result?.data?.percentChangeCustomer.toFixed(2));
          setPercentInvoice(result?.data?.percentChangeInvoice.toFixed(2));
          setTodaysalepercent(result?.data?.percentChangeSalesSum.toFixed(2));
          setPercentProductsold(
            result?.data?.percentChangeProductSold.toFixed(2)
          );
          setTodaySales(result?.data?.totalSalesAmountGrandTotal.toFixed(2));
          setPaymentArr(result?.data?.paymentArr);
          setRevenue(result?.data?.checkRevenue.toFixed(2));
          setRevenuePer(result?.data?.percentChangeRevenueValue.toFixed(2));
          setSalesDate(result?.data?.salesData);

          const chartSeries = [
            {
              name: "Sales",
              data: result?.data?.salesData.map((item) =>
                item?.totalSalesAmount.toFixed(2)
              ),
            },
          ];
          const chartOptions = {
            ...areaChartData.options,
            xaxis: {
              ...areaChartData.options.xaxis,
              categories: result?.data?.salesData
                .map((item) => {
                  const date = new Date(item.date);
                  return date.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  });
                })
                .reverse(), // Reverse the order of categories
            },
          };

          // Update the state with new data
          setAreaChartData({
            series: chartSeries,
            options: chartOptions,
          });
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const YesterdayApi = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    const yesterday = moment().subtract(1, "days");
    const raw = JSON.stringify({
      startDate: yesterday.format("YYYY-MM-DD"),
      endDate: yesterday.format("YYYY-MM-DD"),
      status: 2,
      // startDate: moment(new Date()).format("YYYY-MM-DD"),
      // endDate: moment(new Date()).format("YYYY-MM-DD"),
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // console.log("yesterday", raw);
    fetch(GetSalesApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setLoading(false);
          setCustomerCount(result?.data?.CustomerCount);
          setServiceCount(result?.data?.ServiceCount);
          setInvoiceCount(result?.data?.InvoiceCount);
          setsalesByCategory(result?.data?.salesCategoryArr);
          setsalesByProduct(result?.data?.salesByProduct);
          setTotalSum(result?.data?.totalSalesSum);
          setProductSoldCount(result?.data?.productSoldCount);
          setPercentService(result?.data?.percentChangeService.toFixed(2));
          setPercentCustomer(result?.data?.percentChangeCustomer.toFixed(2));
          setPercentInvoice(result?.data?.percentChangeInvoice.toFixed(2));
          setPercentProductsold(
            result?.data?.percentChangeProductSold.toFixed(2)
          );
          setPaymentArr(result?.data?.paymentArr);
          setTodayProduct(result?.data?.productSoldCount);
          setTodaySales(result?.data?.totalSalesAmountGrandTotal.toFixed(2));
          setTodaysalepercent(result?.data?.percentChangeSalesSum.toFixed(2));
          setRevenue(result?.data?.checkRevenue.toFixed(2));
          setRevenuePer(result?.data?.percentChangeRevenueValue.toFixed(2));
          setSalesDate(result?.data?.salesData);

          const tim = result?.data?.salesData?.map((item) =>
            moment(item.date).format("LT")
          );
          const chartSeries = [
            {
              name: "Sales",
              data: result?.data?.salesData.map(
                (item) => item?.totalSalesAmount
              ),
            },
          ];

          const chartOptions = {
            ...areaChartData.options,
            xaxis: {
              ...areaChartData.options.xaxis,
              categories: result?.data?.salesData
                .map((item) => {
                  const date = new Date(item.date);
                  return date.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  });
                })
                .reverse(), // Reverse the order of categories
            },
          };

          // Update the state with new data
          setAreaChartData({
            series: chartSeries,
            options: chartOptions,
          });
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const ThisWeek = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const startOfWeek = moment().startOf("week").format("YYYY-MM-DD");
    const endOfWeek = moment().endOf("week").format("YYYY-MM-DD");

    const raw = JSON.stringify({
      startDate: startOfWeek,
      endDate: endOfWeek,
      status: 3,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(GetSalesApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setLoading(false);
          setCustomerCount(result?.data?.CustomerCount);
          setServiceCount(result?.data?.ServiceCount);
          setInvoiceCount(result?.data?.InvoiceCount);
          setsalesByCategory(result?.data?.salesCategoryArr);
          setsalesByProduct(result?.data?.salesByProduct);
          setTotalSum(result?.data?.totalSalesSum);
          setProductSoldCount(result?.data?.productSoldCount);
          setPercentService(result?.data?.percentChangeService.toFixed(2));
          setPercentCustomer(result?.data?.percentChangeCustomer.toFixed(2));
          setPercentInvoice(result?.data?.percentChangeInvoice.toFixed(2));
          setPercentProductsold(
            result?.data?.percentChangeProductSold.toFixed(2)
          );
          setPaymentArr(result?.data?.paymentArr);
          setTodayProduct(result?.data?.productSoldCount);
          setTodaySales(result?.data?.totalSalesAmountGrandTotal.toFixed(2));
          setTodaysalepercent(result?.data?.percentChangeSalesSum.toFixed(2));
          setRevenue(result?.data?.checkRevenue.toFixed(2));
          setRevenuePer(result?.data?.percentChangeRevenueValue.toFixed(2));
          setSalesDate(result?.data?.salesData);

          // Aggregate sales data by day of the week
          const salesDataByDay = result?.data?.salesData.reduce((acc, item) => {
            const date = new Date(item.date);
            const dayOfWeek = date.toLocaleDateString("en-US", {
              weekday: "short",
            });
            if (!acc[dayOfWeek]) {
              acc[dayOfWeek] = 0;
            }
            acc[dayOfWeek] += item.totalSalesAmount;
            return acc;
          }, {});

          const chartOptions = {
            ...areaChartData.options,
            xaxis: {
              ...areaChartData.options.xaxis,
              categories: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            },
          };

          const chartSeries = [
            {
              name: "Sales",
              data: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
                .map((day) => salesDataByDay[day] || 0)
                .reverse(),
            },
          ];

          // Update the state with new data
          setAreaChartData({
            series: chartSeries,
            options: chartOptions,
          });
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const LastWeek = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    const startDate = moment().subtract(1, "weeks").startOf("week");
    const endDate = moment().subtract(1, "weeks").endOf("week");

    const raw = JSON.stringify({
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
      status: 4,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(GetSalesApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setLoading(false);
          setCustomerCount(result?.data?.CustomerCount);
          setServiceCount(result?.data?.ServiceCount);
          setInvoiceCount(result?.data?.InvoiceCount);
          setsalesByCategory(result?.data?.salesCategoryArr);
          setsalesByProduct(result?.data?.salesByProduct);
          setTotalSum(result?.data?.totalSalesSum);
          setProductSoldCount(result?.data?.productSoldCount);
          setPercentService(result?.data?.percentChangeService.toFixed(2));
          setPercentCustomer(result?.data?.percentChangeCustomer.toFixed(2));
          setPercentInvoice(result?.data?.percentChangeInvoice.toFixed(2));
          setPercentProductsold(
            result?.data?.percentChangeProductSold.toFixed(2)
          );
          setPaymentArr(result?.data?.paymentArr);
          setTodayProduct(result?.data?.productSoldCount);
          setTodaySales(result?.data?.totalSalesAmountGrandTotal.toFixed(2));
          setTodaysalepercent(result?.data?.percentChangeSalesSum.toFixed(2));
          setRevenue(result?.data?.checkRevenue.toFixed(2));
          setRevenuePer(result?.data?.percentChangeRevenueValue.toFixed(2));
          setSalesDate(result?.data?.salesData);

          // Aggregate sales data by day of the week
          const salesDataByDay = result?.data?.salesData.reduce((acc, item) => {
            const date = new Date(item.date);
            const dayOfWeek = date.toLocaleDateString("en-US", {
              weekday: "short",
            });
            if (!acc[dayOfWeek]) {
              acc[dayOfWeek] = 0;
            }
            acc[dayOfWeek] += item.totalSalesAmount;
            return acc;
          }, {});

          const chartOptions = {
            ...areaChartData.options,
            xaxis: {
              ...areaChartData.options.xaxis,
              categories: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            },
          };

          const chartSeries = [
            {
              name: "Sales",
              data: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
                .map((day) => salesDataByDay[day] || 0)
                .reverse(),
            },
          ];

          // Update the state with new data
          setAreaChartData({
            series: chartSeries,
            options: chartOptions,
          });
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };
  const ThisYear = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    const startDate = moment().startOf("year");
    const endDate = moment().endOf("year");

    const raw = JSON.stringify({
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
      status: 7,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(GetSalesApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setLoading(false);
          setCustomerCount(result?.data?.CustomerCount);
          setServiceCount(result?.data?.ServiceCount);
          setInvoiceCount(result?.data?.InvoiceCount);
          setsalesByCategory(result?.data?.salesCategoryArr);
          setsalesByProduct(result?.data?.salesByProduct);
          setTotalSum(result?.data?.totalSalesSum);
          setProductSoldCount(result?.data?.productSoldCount);
          setPercentService(result?.data?.percentChangeService.toFixed(2));
          setPercentCustomer(result?.data?.percentChangeCustomer.toFixed(2));
          setPercentInvoice(result?.data?.percentChangeInvoice.toFixed(2));
          setPercentProductsold(
            result?.data?.percentChangeProductSold.toFixed(2)
          );
          setPaymentArr(result?.data?.paymentArr);
          setTodayProduct(result?.data?.productSoldCount);
          setTodaySales(result?.data?.totalSalesAmountGrandTotal.toFixed(2));
          setTodaysalepercent(result?.data?.percentChangeSalesSum.toFixed(2));
          setRevenue(result?.data?.checkRevenue.toFixed(2));
          setRevenuePer(result?.data?.percentChangeRevenueValue.toFixed(2));
          setSalesDate(result?.data?.salesData);
          // Aggregate sales data by month
          const salesDataByMonth = result?.data?.salesData.reduce(
            (acc, item) => {
              const date = moment(item.date);
              const monthStart = date.startOf("month").format("MMM YYYY");
              if (!acc[monthStart]) {
                acc[monthStart] = 0;
              }
              acc[monthStart] += item.totalSalesAmount;
              return acc;
            },
            {}
          );

          const chartOptions = {
            ...areaChartData.options,
            xaxis: {
              ...areaChartData.options.xaxis,
              categories: Object.keys(salesDataByMonth).reverse(),
            },
          };

          const chartSeries = [
            {
              name: "Sales",
              data: Object.values(salesDataByMonth),
            },
          ];

          // Update the state with new data
          setAreaChartData({
            series: chartSeries,
            options: chartOptions,
          });
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const ThisMonth = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    const startDate = moment().startOf("month");
    const endDate = moment().endOf("month");

    const raw = JSON.stringify({
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
      status: 5,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(GetSalesApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setLoading(false);
          setCustomerCount(result?.data?.CustomerCount);
          setServiceCount(result?.data?.ServiceCount);
          setInvoiceCount(result?.data?.InvoiceCount);
          setsalesByCategory(result?.data?.salesCategoryArr);
          setsalesByProduct(result?.data?.salesByProduct);
          setTotalSum(result?.data?.totalSalesSum);
          setProductSoldCount(result?.data?.productSoldCount);
          setPercentService(result?.data?.percentChangeService.toFixed(2));
          setPercentCustomer(result?.data?.percentChangeCustomer.toFixed(2));
          setPercentInvoice(result?.data?.percentChangeInvoice.toFixed(2));
          setPercentProductsold(
            result?.data?.percentChangeProductSold.toFixed(2)
          );
          setPaymentArr(result?.data?.paymentArr);
          setTodayProduct(result?.data?.productSoldCount);
          setTodaySales(result?.data?.totalSalesAmountGrandTotal.toFixed(2));
          setTodaysalepercent(result?.data?.percentChangeSalesSum.toFixed(2));
          setRevenue(result?.data?.checkRevenue.toFixed(2));
          setRevenuePer(result?.data?.percentChangeRevenueValue.toFixed(2));
          setSalesDate(result?.data?.salesData);
          // Aggregate sales data by day
          const salesDataByDay = result?.data?.salesData.reduce((acc, item) => {
            const date = moment(item.date);
            const dayOfMonth = date.format("MMM DD");
            if (!acc[dayOfMonth]) {
              acc[dayOfMonth] = 0;
            }
            acc[dayOfMonth] += item.totalSalesAmount;
            return acc;
          }, {});

          const chartOptions = {
            ...areaChartData.options,
            xaxis: {
              ...areaChartData.options.xaxis,
              categories: Object.keys(salesDataByDay).reverse(),
            },
          };

          const chartSeries = [
            {
              name: "Sales",
              data: Object.values(salesDataByDay),
            },
          ];

          // Update the state with new data
          setAreaChartData({
            series: chartSeries,
            options: chartOptions,
          });
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const LastMonth = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    const startDate = moment().subtract(1, "months").startOf("month");
    const endDate = moment().subtract(1, "months").endOf("month");

    const raw = JSON.stringify({
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
      status: 6,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(GetSalesApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setLoading(false);
          setCustomerCount(result?.data?.CustomerCount);
          setServiceCount(result?.data?.ServiceCount);
          setInvoiceCount(result?.data?.InvoiceCount);
          setsalesByCategory(result?.data?.salesCategoryArr);
          setsalesByProduct(result?.data?.salesByProduct);
          setTotalSum(result?.data?.totalSalesSum);
          setProductSoldCount(result?.data?.productSoldCount);
          setPercentService(result?.data?.percentChangeService.toFixed(2));
          setPercentCustomer(result?.data?.percentChangeCustomer.toFixed(2));
          setPercentInvoice(result?.data?.percentChangeInvoice.toFixed(2));
          setPercentProductsold(
            result?.data?.percentChangeProductSold.toFixed(2)
          );
          setPaymentArr(result?.data?.paymentArr);
          setTodayProduct(result?.data?.productSoldCount);
          setTodaySales(result?.data?.totalSalesAmountGrandTotal.toFixed(2));
          setTodaysalepercent(result?.data?.percentChangeSalesSum.toFixed(2));
          setRevenue(result?.data?.checkRevenue.toFixed(2));
          setRevenuePer(result?.data?.percentChangeRevenueValue.toFixed(2));
          setSalesDate(result?.data?.salesData);
          // Aggregate sales data by day
          const salesDataByDay = result?.data?.salesData.reduce((acc, item) => {
            const date = moment(item.date);
            const dayOfMonth = date.format("MMM DD");
            if (!acc[dayOfMonth]) {
              acc[dayOfMonth] = 0;
            }
            acc[dayOfMonth] += item.totalSalesAmount;
            return acc;
          }, {});

          const chartOptions = {
            ...areaChartData.options,
            xaxis: {
              ...areaChartData.options.xaxis,
              categories: Object.keys(salesDataByDay).reverse(),
            },
          };

          const chartSeries = [
            {
              name: "Sales",
              data: Object.values(salesDataByDay),
            },
          ];

          // Update the state with new data
          setAreaChartData({
            series: chartSeries,
            options: chartOptions,
          });
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const handleTimeRangeChange = (selectedOption) => {
    switch (selectedOption) {
      case "today":
        TodayApi();
        break;
      case "yesterday":
        YesterdayApi();
        break;
      case "week":
        ThisWeek();
        break;
      case "month":
        ThisMonth();
        break;
      default:
        break;
    }
  };
  const [isFreeTrial, setIsFreeTrial] = useState(false);

  useEffect(() => {
    const substatus = localStorage.getItem("substatus");
    const planDate = localStorage.getItem("planDate");

    if (
      (substatus === "null" ||
        substatus === "canceled" ||
        substatus === "expired") &&
      new Date(localStorage.getItem("planDate")) > new Date()
    ) {
      setIsFreeTrial(true);
    }
  }, []);
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
    localStorage.setItem("sidebar", !isSidebarVisible);
  };

  useEffect(() => {
    const savedSidebarState = localStorage.getItem("sidebar");
    if (savedSidebarState) {
      setSidebarVisible(JSON.parse(savedSidebarState));
    }
  }, []);
  const [isFreeTrialVisible, setFreeTrialVisible] = useState(true);

  const toggleFreeTrial = () => {
    setFreeTrialVisible(!isFreeTrialVisible);
  };

  const sidebarStyleone = {
    overflow: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    // position: 'sticky',

    // marginTop: isFreeTrialVisible ? "0px" : "0px",
  };

  const navbarStyle = {
    // top: isFreeTrialVisible ? "-4px" : "-50px",
  };
  const mainContentStyle = {
    // marginTop: isFreeTrialVisible ? "132px" : "92px",
  };
  const mainwrapper = {
    // marginTop: isSidebarVisible ? "0" : "0",
  };
  useEffect(() => {
    if (isFreeTrial) {
      // toggleSidebar(false);
      toggleFreeTrial(false);
    } else {
      // toggleSidebar(true);
      toggleFreeTrial(true);
    }
  }, [isFreeTrial]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      const sidebarElement = document.querySelector(".main-sidebar");
      const toggleButtonElement = document.querySelector(".sidebar-arrow a");

      if (
        sidebarElement &&
        !sidebarElement.contains(event.target) &&
        toggleButtonElement &&
        !toggleButtonElement.contains(event.target)
      ) {
        const mediaQuery = window.matchMedia("(max-width: 1024px)");
        if (mediaQuery.matches) {
          setSidebarVisible(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const formatNumber = (num) => {
    // Ensure num is a number
    if (typeof num !== "number") {
      num = Number(num);
    }

    // Check if num is a valid number after conversion
    if (isNaN(num)) {
      return "Invalid number";
    }

    const options = { minimumFractionDigits: 0, maximumFractionDigits: 2 };

    if (num >= 1e7) {
      return (num / 1e7).toLocaleString(undefined, options) + " Cr"; // Crore
    } else if (num >= 1e5) {
      return (num / 1e5).toLocaleString(undefined, options) + " Lac"; // Lakh
    } else if (num >= 1e3) {
      return (num / 1e3).toLocaleString(undefined, options) + " K"; // Thousand
    } else {
      return num.toLocaleString(undefined, options); // Less than thousand
    }
  };
  return (
    <>
      <div className={isSidebarVisible ? "sidebar-mini" : ""}>
        <div
          className={`${
            isFreeTrial
              ? "main-wrapper devicelistcstmtableres customclswrapper"
              : "main-wrapper devicelistcstmtableres "
          } `}
        >
          <Helmet>
            <title>Insights and Analysis | Toolbox Sale Dashboard</title>
            <meta
              name="description"
              content="Gain valuable insights into your sales performance. The Sale Dashboard provides real-time analytics to optimize your business strategies."
            />
          </Helmet>
          {loading ? (
            <div className="landing_loader">
              <div className="innerloader">
                <img src="img/spin-1s-156px.gif" alt="" />
              </div>
            </div>
          ) : null}

          {isFreeTrial ? (
            <>
              <div className="app-container-trial">
                <FreeTrail />
              </div>
            </>
          ) : null}
          <div style={sidebarStyleone}>
            <nav className="navbar navbar-expand-lg main-navbar sticky">
              <div className="sidebar-arrow">
                <a
                  href="#"
                  data-toggle="sidebar"
                  onClick={(e) => {
                    toggleSidebar();

                    e.preventDefault();
                  }}
                >
                  <img src="assets/img/sidebar-toggle.webp" alt="" />
                </a>
              </div>
              <div className="header-title mr-auto">
                <h4>
                  Welcome Back, {data?.userDetails?.userName}
                  <img src="assets/img/Waving-Hand.webp" alt="" />{" "}
                  <a
                    className="notes_icon"
                    href="#"
                    data-toggle="modal"
                    data-target="#noteinfoModal"
                  >
                    <img src="assets/img/notes-icon.svg" alt="" />
                  </a>
                </h4>
                <p>Here’s what’s happening with your store today.</p>
              </div>
              <SideUl />
            </nav>
            <MainSidebar />{" "}
            <div className="main-content">
              <div className="dashboard_inner">
                <div className="inner_dash_block">
                  <div className="dash_dateselecter">
                    <ul>
                      {activeState === 0 ? (
                        <li
                          className="active"
                          onClick={() => {
                            TodayApi();
                            setActiveState(0);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <a>Today</a>
                        </li>
                      ) : (
                        <li
                          //className="active"
                          onClick={() => {
                            TodayApi();
                            setActiveState(0);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <a>Today</a>
                        </li>
                      )}
                      {activeState === 1 ? (
                        <li
                          className="active"
                          onClick={() => {
                            YesterdayApi();
                            setActiveState(1);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <a href="#">Yesterday</a>
                        </li>
                      ) : (
                        <li
                          onClick={() => {
                            YesterdayApi();
                            setActiveState(1);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <a href="#">Yesterday</a>
                        </li>
                      )}
                      {activeState === 2 ? (
                        <li
                          className="active"
                          onClick={() => {
                            ThisWeek();
                            setActiveState(2);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <a href="#">This Week</a>
                        </li>
                      ) : (
                        <li
                          onClick={() => {
                            ThisWeek();
                            setActiveState(2);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <a href="#">This Week</a>
                        </li>
                      )}
                      {activeState === 3 ? (
                        <li
                          className="active"
                          onClick={() => {
                            LastWeek();
                            setActiveState(3);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <a href="#">Last Week</a>
                        </li>
                      ) : (
                        <li
                          // className="active"
                          onClick={() => {
                            LastWeek();
                            setActiveState(3);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <a href="#">Last Week</a>
                        </li>
                      )}
                      {activeState === 4 ? (
                        <li
                          className="active"
                          onClick={() => {
                            ThisMonth();
                            setActiveState(4);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <a href="#">This Month</a>
                        </li>
                      ) : (
                        <li
                          //className="active"
                          onClick={() => {
                            ThisMonth();
                            setActiveState(4);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <a href="#">This Month</a>
                        </li>
                      )}
                      {activeState === 5 ? (
                        <li
                          className="active"
                          onClick={() => {
                            LastMonth();
                            setActiveState(5);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <a href="#">Last Month</a>
                        </li>
                      ) : (
                        <li
                          // className="active"
                          onClick={() => {
                            LastMonth();
                            setActiveState(5);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <a href="#">Last Month</a>
                        </li>
                      )}
                      {activeState === 6 ? (
                        <li
                          className="active"
                          onClick={() => {
                            ThisYear();
                            setActiveState(6);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <a href="#">This Year</a>
                        </li>
                      ) : (
                        <li
                          onClick={() => {
                            ThisYear();
                            setActiveState(6);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <a href="#">This Year</a>
                        </li>
                      )}
                      <li className="custom_date">
                        <a href="#" className="customsaledashboardfield">
                          <img src="assets/img/date-calendar.webp" alt="" />
                          <DatePicker
                            id="startDate"
                            className="saledashstartdatepicker"
                            dateFormat="MMMM dd"
                            selected={startDate.toDate()}
                            onChange={handleStartDate}
                            // showMonthYearPicker
                          />
                          <span>-</span>
                          <DatePicker
                            id="endDate"
                            className="saledashenddatepicker"
                            dateFormat="MMMM dd"
                            minDate={startDate.toDate()}
                            selected={endDate.toDate()}
                            onChange={handleEndDate}
                            // showMonthYearPicker
                          />
                        </a>
                      </li>
                    </ul>
                    <div className="dash_download">
                      <a href="#">
                        <img src="assets/img/Download-icon.webp" alt="" />{" "}
                        Download
                      </a>
                    </div>
                  </div>
                  <div className="dash_sale_block">
                    <div className="dash_sale_box">
                      <div className="box_cont_top">
                        <div className="box_cont">
                          <h5>Today Sales</h5>
                          <span>${todaySales}</span>
                        </div>
                        <div className="box_icons blue_border">
                          <div style={{ width: 80, height: 80 }}>
                            <CircularProgressbar
                              value={todaysalepercent}
                              maxValue={100}
                              strokeWidth={20}
                              styles={{
                                path: {
                                  stroke: "green",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="box_cont_btm">
                        <span className="count">{todayProduct} items Sold</span>
                        <span
                          className={`count_val ${
                            todaysalepercent >= 0 ? "val_green" : "val_red"
                          }`}
                        >
                          {todaysalepercent}%
                        </span>
                      </div>
                    </div>
                    <div className="dash_sale_box">
                      <div className="box_cont_top">
                        <div className="box_cont">
                          <h5>Today Profit</h5>
                          <span>${revenue}</span>
                        </div>
                        <div className="box_icons green_border">
                          <div style={{ width: 80, height: 80 }}>
                            <CircularProgressbar
                              value={revenuePer}
                              strokeWidth={20}
                              maxValue={100}
                              styles={{
                                path: {
                                  stroke: "blue",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="box_cont_btm">
                        <span className="count"></span>
                        <span
                          className={`count_val ${
                            revenuePer >= 0 ? "val_green" : "val_red"
                          }`}
                        >
                          {revenuePer}%
                        </span>
                      </div>
                    </div>
                    <div className="dash_sale_box">
                      <div className="box_cont_top">
                        <div className="box_cont">
                          <h5>Product Sold</h5>
                          <span>{productSoldCount}</span>
                        </div>
                        <div className="box_icons purple_border">
                          <div style={{ width: 80, height: 80 }}>
                            <CircularProgressbar
                              value={percentProductsold}
                              maxValue={100}
                              strokeWidth={20}
                              styles={{
                                path: {
                                  stroke: "red",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="box_cont_btm">
                        <span className="count"></span>
                        <span
                          className={`count_val ${
                            percentProductsold >= 0 ? "val_green" : "val_red"
                          }`}
                        >
                          {percentProductsold}%
                        </span>
                      </div>
                    </div>
                    <div className="dash_sale_box">
                      <div className="box_cont_top">
                        <div className="box_cont">
                          <h5>New Customers</h5>
                          <span>{customerCount ? customerCount : 0}</span>
                        </div>
                        <div className="box_icons">
                          {" "}
                          <div style={{ width: 80, height: 80 }}>
                            <CircularProgressbar
                              value={percentCustomer}
                              maxValue={100}
                              strokeWidth={20}
                              styles={{
                                path: {
                                  stroke: "black",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="box_cont_btm">
                        <span className="count"></span>
                        <span
                          className={`count_val ${
                            percentCustomer >= 0 ? "val_green" : "val_red"
                          }`}
                        >
                          {percentCustomer}%
                        </span>
                      </div>
                    </div>
                    <div className="dash_sale_box">
                      <div className="box_cont_top">
                        <div className="box_cont">
                          <h5>New Services</h5>
                          <span>{serviceCount ? serviceCount : 0}</span>
                        </div>
                        <div className="box_icons blue_border">
                          <div style={{ width: 80, height: 80 }}>
                            <CircularProgressbar
                              value={percentService}
                              maxValue={100}
                              strokeWidth={20}
                              styles={{
                                path: {
                                  stroke: "purple",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="box_cont_btm">
                        <span className="count"></span>
                        <span
                          className={`count_val ${
                            percentService >= 0 ? "val_green" : "val_red"
                          }`}
                        >
                          {percentService}%
                        </span>
                      </div>
                    </div>
                    <div className="dash_sale_box">
                      <div className="box_cont_top">
                        <div className="box_cont">
                          <h5>New Invoices</h5>
                          <span>{invoiceCount ? invoiceCount : 0}</span>
                        </div>
                        <div className="box_icons blue_border">
                          <div style={{ width: 80, height: 80 }}>
                            <CircularProgressbar
                              value={percentInvoice}
                              maxValue={100}
                              strokeWidth={20}
                              styles={{
                                path: {
                                  stroke: "darkred",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="box_cont_btm">
                        <span className="count"></span>
                        {/* <span className="count_val val_green"> */}
                        <span
                          className={`count_val ${
                            percentInvoice >= 0 ? "val_green" : "val_red"
                          }`}
                        >
                          {percentInvoice}%
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="sales_chart_sec">
                    <div className="sales_chart">
                      <div className="row">
                        <div className="col-md-10">
                          <div className="title">
                            <h4>Sales Chart</h4>
                          </div>
                        </div>

                        <div className="col-md-2 ">
                          <div className="title text-md-right">
                            <div className="chartcustmcsschanges">
                              <select
                                onChange={(e) =>
                                  handleTimeRangeChange(e.target.value)
                                }
                                className="form-control"
                              >
                                <option value=""> Select</option>
                                <option value="week">This Week</option>
                                <option value="today">Today</option>
                                <option value="yesterday">Yesterday</option>
                                <option value="month">This Month</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="saleschart" className="chartsh">
                        <ReactApexChart
                          options={areaChartData.options}
                          series={areaChartData.series}
                          type="area"
                          height={350}
                        />
                      </div>
                    </div>
                    <div className="sales_progress sales_payment_type basic_sale_column">
                      <div className="title">
                        <h4>Sales By Payment Type</h4>
                      </div>
                      <div className="sales_lists">
                        {paymentarr?.length > 0 ? (
                          <>
                            {paymentarr.map((item, index) => {
                              const percentage =
                                (item?.paymentTypeCount / invoiceCount) * 100;

                              let progressBarColor;
                              if (percentage >= 70) {
                                progressBarColor = "skyblue";
                              } else if (percentage >= 50) {
                                progressBarColor = "red";
                              } else if (percentage >= 30) {
                                progressBarColor = "blue";
                              } else {
                                progressBarColor = "purple";
                              }

                              return (
                                <div key={index} className="sales_column">
                                  <h5>{item?.paymentType}</h5>
                                  <div
                                    className={`progress_strip ${progressBarColor}_progress`}
                                  >
                                    <div className="progress">
                                      <div
                                        className="progress_bar"
                                        role="progressbar"
                                        aria-valuenow={percentage}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        style={{
                                          width: `${percentage}%`,
                                        }}
                                      ></div>
                                    </div>
                                    <span className="progress_value">{`${percentage.toFixed(
                                      2
                                    )}%`}</span>
                                    <span className="price_val">
                                      ${item?.totalGrandTotal.toFixed(2)}
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            <div className="sales_column">
                              <h5>Payment Type</h5>
                              <div className="progress_strip blue_progress">
                                <div className="progress purple_progress">
                                  <div
                                    className="progress_bar"
                                    role="progressbar"
                                    aria-valuenow="5"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{ width: "5%" }}
                                  ></div>
                                </div>
                                <span className="progress_value">0%</span>
                                <span className="price_val">$0</span>
                              </div>
                            </div>
                            <div className="sales_column">
                              <h5>Payment Type</h5>
                              <div className="progress_strip blue_progress">
                                <div className="progress purple_progress">
                                  <div
                                    className="progress_bar"
                                    role="progressbar"
                                    aria-valuenow="5"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{ width: "5%" }}
                                  ></div>
                                </div>
                                <span className="progress_value">0%</span>
                                <span className="price_val">$0</span>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                  <div className="sales_table_sec">
                    <div className="sales_category_data basic_sale_column">
                      <div className="title">
                        <h4>Sales By Category</h4>
                      </div>
                      <div className="sales_table_data">
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Category Name</th>
                                <th>Sales</th>
                                <th>Stock</th>
                                <th>Total Sales</th>
                                <th>Total Costs</th>
                              </tr>
                            </thead>
                            <tbody>
                              {salesByCategory?.length > 0 ? (
                                <>
                                  {salesByCategory?.map((item, index) => (
                                    <>
                                      <tr key={index}>
                                        <td>
                                          {item?.itemCategory?.name || "--"}
                                        </td>
                                        <td>{item?.totalSales}</td>
                                        <td>{item?.StockOfCategory}</td>
                                        <td>${item?.totalSalesAmount}</td>
                                        <td>
                                          ${item?.categoryCost.toFixed(2)}
                                        </td>
                                      </tr>
                                    </>
                                  ))}
                                </>
                              ) : (
                                <>
                                  <tr>
                                    <td>--</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>$0</td>
                                    <td>$0</td>
                                  </tr>
                                  <tr>
                                    <td>--</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>$0</td>
                                    <td>$0</td>
                                  </tr>
                                  <tr>
                                    <td>--</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>$0</td>
                                    <td>$0</td>
                                  </tr>
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="sales_product_data basic_sale_column">
                      <div className="title">
                        <h4>Sales By Products</h4>
                      </div>
                      <div className="sales_table_data">
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Popularity</th>
                                <th>Qty</th>
                                <th>Sales</th>
                              </tr>
                            </thead>
                            <tbody>
                              {salesByProduct?.length > 0 ? (
                                <>
                                  {salesByProduct?.map((item, index) => {
                                    const totalSales = parseInt(
                                      item.totalSales,
                                      10
                                    );
                                    const totalSum = parseInt(totalsum, 10);

                                    let percentage = 0;

                                    if (totalSum !== 0) {
                                      percentage = (
                                        (totalSales / totalSum) *
                                        100
                                      ).toFixed(2);
                                    } else {
                                      percentage = "0.00";
                                    }
                                    let colorClass;
                                    if (percentage === 100) {
                                      colorClass = "blue_progress";
                                    } else if (percentage >= 90) {
                                      colorClass = "skyblue_progress";
                                    } else if (percentage >= 80) {
                                      colorClass = "purple_progress";
                                    } else if (percentage >= 70) {
                                      colorClass = "green_progress";
                                    } else if (percentage >= 60) {
                                      colorClass = "yellow_progress";
                                    } else if (percentage >= 50) {
                                      colorClass = "orange_progress";
                                    } else if (percentage >= 40) {
                                      colorClass = "pink_progress";
                                    } else if (percentage >= 30) {
                                      colorClass = "purple_progress";
                                    } else if (percentage >= 20) {
                                      colorClass = "green_progress";
                                    } else if (percentage >= 10) {
                                      colorClass = "red_progress";
                                    } else {
                                      colorClass = "black_progress";
                                    }

                                    return (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item?.item?.title}</td>
                                        <td>
                                          <div
                                            className={`progress_strip ${colorClass}`}
                                          >
                                            <div className="progress green_progress">
                                              <div
                                                className="progress_bar"
                                                role="progressbar"
                                                aria-valuenow={
                                                  percentage ? percentage : 0
                                                }
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                                style={{
                                                  width: `${
                                                    percentage ? percentage : 0
                                                  }%`,
                                                }}
                                              ></div>
                                            </div>
                                            <span className="progress_value">
                                              {percentage ? percentage : 0}%
                                            </span>
                                          </div>
                                        </td>
                                        <td>{item?.totalSales}</td>
                                        <td>
                                          ${item?.totalSalesAmount.toFixed(2)}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  <tr>
                                    <td>1</td>
                                    <td>--</td>
                                    <td>
                                      <div className="progress_strip blue_progress">
                                        <div className="progress green_progress">
                                          <div
                                            className="progress_bar"
                                            role="progressbar"
                                            aria-valuenow="29"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                            style={{ width: "19%" }}
                                          ></div>
                                        </div>
                                        <span className="progress_value">
                                          0%
                                        </span>
                                      </div>
                                    </td>
                                    <td>0</td>
                                    <td>$0</td>
                                  </tr>
                                  <tr>
                                    <td>2</td>
                                    <td>--</td>
                                    <td>
                                      <div className="progress_strip skyblue_progress">
                                        <div className="progress">
                                          <div
                                            className="progress_bar"
                                            role="progressbar"
                                            aria-valuenow="19"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                            style={{ width: "19%" }}
                                          ></div>
                                        </div>
                                        <span className="progress_value">
                                          0%
                                        </span>
                                      </div>
                                    </td>
                                    <td>0</td>
                                    <td>$0</td>
                                  </tr>
                                  <tr>
                                    <td>3</td>
                                    <td>--</td>
                                    <td>
                                      <div className="progress_strip purple_progress">
                                        <div className="progress">
                                          <div
                                            className="progress_bar"
                                            role="progressbar"
                                            aria-valuenow="19"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                            style={{ width: "19%" }}
                                          ></div>
                                        </div>
                                        <span className="progress_value">
                                          0%
                                        </span>
                                      </div>
                                    </td>
                                    <td>0</td>
                                    <td>$0</td>
                                  </tr>
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                </div>
                <div className="dash_reports">
                  <div className="report_title">
                    <h4>Reports</h4>
                  </div>
                  <div className="report_list">
                    <ul>
                      <li>
                        <Link to="/reports">Sales Reports</Link>
                      </li>
                      <li>
                        <a href="#">Inventory Reports</a>
                      </li>
                      <li>
                        <a href="#">Service Reports</a>
                      </li>
                      <li>
                        <a href="#">Commissions</a>
                      </li>
                      <li>
                        <a href="#">Accounting</a>
                      </li>
                    </ul>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <div
        className="modal common_modal noteinfo_modal"
        id="noteinfoModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="noteinfoModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="noteclose"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src="assets/img/notes-close-icon.svg" alt="" />
              </button>
            </div>
            <div className="modal-body">
              <div className="notes_tabs">
                <ul
                  className="nav nav-pills notetabs"
                  id="notetabs"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="video_demo-tab"
                      data-toggle="tab"
                      href="#video_demo"
                      role="tab"
                      aria-controls="video_demo"
                      aria-selected="true"
                    >
                      Video Demo
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="page_info-tab"
                      data-toggle="tab"
                      href="#page_info"
                      role="tab"
                      aria-controls="page_info"
                      aria-selected="false"
                    >
                      Page Information
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="settingContent">
                  <div
                    className="tab-pane fade active show"
                    id="video_demo"
                    role="tabpanel"
                    aria-labelledby="video_demo-tab"
                  >
                    <div className="notetabs_content">
                      <h4>Video Demo</h4>
                      <p>
                        In this short tutorial, we’ll walk you through the key
                        features and functions of the Dashboard page.
                      </p>
                      <div className="notes_videos">
                        <img
                          src="assets/img/notes-demo-videos-img.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="page_info"
                    role="tabpanel"
                    aria-labelledby="page_info-tab"
                  >
                    <div className="notetabs_content">
                      <h4>Page Information</h4>
                      <p>
                        This page provides a comprehensive view of all the tools
                        and features available to you. Each section is designed
                        to help you manage your business more efficiently,
                        whether you’re tracking sales, monitoring inventory, or
                        analyzing reports.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SaleDashboard;
